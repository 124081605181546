import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRouter } from "utils";
import PageHeaderTabs from "../page-header-tabs";
import { Grid } from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
} from "frame/components";
import {
	strip,
	DateForm,
	TrainingTopicForm,
	EmailRecipientsForm,
} from "features/forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
	},
	actions: {
		marginTop: theme.spacing(2),
		paddingBottom: theme.spacing(8),
	},
	fieldLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(-1.2),
	},
	label: {
		margin: 0,
	},
}));

const Form = ({ values, onChange, onReset, onSave }) => {
	const classes = useStyles();
	const { history } = useRouter();

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
	};

	const onCancel = () => {
		onReset();
		history.push(fromHealthAndSafety.INFO_ROUTE);
	};

	const training = values.training || {};

	const isValid = (value) =>
		value && typeof value === "string" && value.trim() !== "";
	const validated =
		isValid(training.name) &&
		((isValid(training.validMeasurement) &&
			training.validDuration &&
			training.validDuration !== "") ||
			training.validMeasurement === "Unlimited/Indefinite");

	return (
		<Page title="Add Staff Training Topic">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Staff Training Topics"
				title="Add Staff Training Topic"
				// addTabTitle="Add training topic"
				// listTabTitle="View library of training topics"
				listTabPath={
					fromHealthAndSafety.INFO_STAFF_TRAINING_TOPIC_ROUTE
				}
				backActionPath={fromHealthAndSafety.INFO_ROUTE}
			/>
			<PageContent padSides padBottom padTop>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Grid container spacing={3} className={classes.body}>
						<Grid item lg={6} md={12} sm={12} xs={12}>
							<div className={classes.body}>
								<DateForm values={values} onChange={onChange} />
								<TrainingTopicForm
									useTrainingTopics
									values={values}
									onChange={onChange}
								/>
								<EmailRecipientsForm
									values={values}
									onChange={onChange}
								/>
							</div>
						</Grid>
					</Grid>

					<FormActions className={classes.actions}>
						<FormSubmitButton
							disabled={!validated || values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
						<FormButton
							disabled={values.save.saving}
							onClick={onCancel}
						>
							Cancel
						</FormButton>
					</FormActions>
				</ValidatorForm>
			</PageContent>
		</Page>
	);
};

Form.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

export default Form;
