import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		padding: theme.spacing(0, 0, 3, 0),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(3),
		},
	},
	pad: {
		padding: theme.spacing(0, 3, 3, 3),
	},
	padSides: {
		padding: theme.spacing(0, 3),
	},
	padTop: {
		paddingTop: theme.spacing(3),
		[theme.breakpoints.up("sm")]: {
			paddingTop: theme.spacing(3),
		},
		[theme.breakpoints.down("xs")]: {
			paddingTop: theme.spacing(3),
		},
	},
	padBottom: {
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.up("sm")]: {
			paddingTop: theme.spacing(3),
		},
	},
}));

const PageContent = ({ children, pad, padSides, padTop, padBottom }) => {
	const classes = useStyles();

	const styles = clsx(
		classes.root,
		pad ? classes.pad : "",
		padSides ? classes.padSides : "",
		padTop ? classes.padTop : "",
		padBottom ? classes.padBottom : ""
	);

	return <div className={styles}>{children}</div>;
};

PageContent.propTypes = {
	children: PropTypes.node.isRequired,
	pad: PropTypes.bool,
	padSides: PropTypes.bool,
	padTop: PropTypes.bool,
	padBottom: PropTypes.bool,
};

export default PageContent;
