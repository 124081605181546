import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { StyledIconButton } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		// justifyContent: 'space-between',
		alignItems: "center",
		// width: '250px',
	},
	file: {
		margin: theme.spacing(1, 0),
		marginRight: theme.spacing(1),
	},
	icon: {
		width: 20,
		height: 20,
	},
}));

const File = ({ file, onDelete }) => {
	const classes = useStyles();

	// const byteArrayToBase64 = (byteArray, fileExtension) => {
	//   let binary = ''
	//   let length = byteArray && byteArray.byteLength
	//   for (let i = 0; i < length; i++) {
	//       binary += String.fromCharCode(byteArray[i])
	//   }
	//   return `data:image/${fileExtension};base64,${window.btoa(binary)}`;
	// }

	return (
		<div className={classes.root}>
			<Typography className={classes.file} variant="body1">
				{/* <a href={byteArrayToBase64(file.dataURL, file.fileExtension)} download={file.fileName} className={classes.downloadLink}> */}
				<a
					href={file.dataURL}
					download={file.fileName}
					className={classes.downloadLink}
				>
					{file.fileName}
				</a>
			</Typography>
			<StyledIconButton onClick={onDelete}>
				<DeleteIcon className={classes.icon} />
			</StyledIconButton>
		</div>
	);
};

File.propTypes = {
	file: PropTypes.object.isRequired,
	onDelete: PropTypes.func,
};

export default File;
