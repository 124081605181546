import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
	formatDate,
	isDefaultGuid,
	isValidContentState,
	isNotNullOrEmpty,
} from "utils";
import MUIRichTextEditor from "mui-rte";

const useStyles = makeStyles((theme) => ({
	guidelines: {
		margin: theme.spacing(0, 3),
	},
}));

const StaffTrainingTopicLookup = ({
	id,
	attribute = "name",
	validUntil,
	duration,
}) => {
	const classes = useStyles();

	const resourceList = useSelector(
		(state) => state.healthSafety.staffTrainingTopics?.results
	);

	if ((id && isDefaultGuid(id)) || !id || !resourceList) {
		return "-";
	}

	const data =
		id &&
		isNotNullOrEmpty(resourceList) &&
		resourceList.filter((r) => r.id === id)[0];

	const trainingTopicExpiryDate = (value) => {
		if (validUntil && value) {
			if (value.validMeasurement === "Unlimited/Indefinite") {
				return value.validMeasurement;
			}
			const today = moment();
			const duration = parseInt(value.validDuration, 10);
			const measurement =
				value.validMeasurement &&
				String(value.validMeasurement).toLowerCase();
			const expiryDate = today.add(duration, measurement);
			return formatDate(expiryDate);
		}
	};

	const trainingTopicDuration = (value) => {
		let duration = "";
		const isIndefinite = value.validMeasurement === "Unlimited/Indefinite";
		if (isIndefinite) {
			return value.validMeasurement;
		}
		duration += value.validDuration ? `${value.validDuration}` : "";
		duration += value.validMeasurement ? ` ${value.validMeasurement}` : "";

		return duration !== "" ? duration : "";
	};

	const renderTopicGuidelines = (value) =>
		value && isValidContentState(value) ? (
			<div className={classes.guidelines}>
				<MUIRichTextEditor readOnly controls={[]} value={value} />
			</div>
		) : (
			""
		);

	// calculate & display validUntil value if validUntil param is provided, else displays topic attributes
	const displayContent = (data) =>
		validUntil && data
			? trainingTopicExpiryDate(data)
			: duration && data
			? trainingTopicDuration(data)
			: attribute === "guidelines"
			? renderTopicGuidelines(data[attribute])
			: data[attribute] || "-";

	return <>{id && !isDefaultGuid(id) ? displayContent(data) : "-"}</>;
};

StaffTrainingTopicLookup.propTypes = {
	id: PropTypes.string.isRequired,
	attribute: PropTypes.string,
	validUntil: PropTypes.bool,
	duration: PropTypes.bool,
};

export default StaffTrainingTopicLookup;
