import { ofType } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError } from "rxjs/operators";
import fromTypes from "../actions/types";
import { passwordSaveSuccess, passwordSaveFailed } from "../actions";
import { getBaseUrl, authHeaders } from "frame";

export const passwordSaveEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.PASSWORD_SAVE),
		mergeMap(({ payload }) => {
			return ajax
				.post(
					`${getBaseUrl()}/api/password/update`,
					{
						email: state$.value.profile.email,
						password: payload.password,
						newPassword: payload.newPassword,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(passwordSaveSuccess(ajaxResponse.response))
					),
					catchError((error) =>
						of(passwordSaveFailed(error.response.error))
					)
				);
		})
	);
