import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	CardContent,
	CardHeader,
	Divider,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";

import { RadioListInput } from "frame/components/input";
import UploadFile from "../upload-form/upload-file";
import { TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(1, 0, 3, 0),
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0),
		height: 2,
	},
}));

const EmergenciesForm = ({
	className,
	disabled,
	values,
	onInputChange,
	onSelectChange,
	onFileChange,
}) => {
	const classes = useStyles();

	const options = [
		{
			label: "Yes",
			value: "yes",
		},
		{
			label: "NA",
			value: "na",
		},
	];

	return (
		<CardContent className={classes.root}>
			<Divider className={classes.divider} />
			<CardHeader
				className={classes.heading}
				title="Emergencies"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						id="safety_plan_emergencies_location"
						disabled={disabled}
						fullWidth
						label="Our First aid kid is located at"
						value={values.firstAidKitLocation || ""}
						variant="outlined"
						inputProps={{ maxLength: 50 }}
						onChange={onInputChange("firstAidKitLocation")}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="safety_plan_emergencies_person"
						disabled={disabled}
						fullWidth
						label="Our First aid person is"
						value={values.firstAidPerson || ""}
						variant="outlined"
						inputProps={{ maxLength: 50 }}
						onChange={onInputChange("firstAidPerson")}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextValidator
						id="safety_plan_emergencies_phone"
						disabled={disabled}
						fullWidth
						label="Phone"
						inputProps={{ maxLength: 50 }}
						validators={["isNumber"]}
						errorMessages={["Must be a valid phone number"]}
						value={values.emergencyPhone || ""}
						variant="outlined"
						onChange={onInputChange("emergencyPhone")}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						We have trained First aid Personnel and Procedures in
						place on site to render assistance in the event of an
						incident / emergency.
					</Typography>
					<RadioListInput
						data-cy={"haveTrainedPersonnelProceduresOnSite"}
						disabled={disabled}
						initialValue={
							values.haveTrainedPersonnelProceduresOnSite
						}
						options={options}
						onChange={onSelectChange(
							"haveTrainedPersonnelProceduresOnSite"
						)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						In the event of a site emergency or evacuation our
						personnel will report to our safety representative and
						assemble at the evacuation area.
					</Typography>
					<RadioListInput
						data-cy={"willReportToSafetyRepresentative"}
						disabled={disabled}
						initialValue={values.willReportToSafetyRepresentative}
						options={options}
						onChange={onSelectChange(
							"willReportToSafetyRepresentative"
						)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						We have attached an Emergency plan for all hazardous
						products and processes we have said yes to.
					</Typography>
					<RadioListInput
						data-cy={"haveAttachedEmergencyPlan"}
						disabled={disabled}
						initialValue={values.haveAttachedEmergencyPlan}
						options={options}
						onChange={onSelectChange("haveAttachedEmergencyPlan")}
					/>
					<UploadFile
						disabled={
							disabled ||
							values.haveAttachedEmergencyPlan !== "yes"
						}
						onFileChange={onFileChange}
						values={values}
						reference="emergencyPlanAttachments"
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

EmergenciesForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onInputChange: PropTypes.func.isRequired,
	onSelectChange: PropTypes.func.isRequired,
	onFileChange: PropTypes.func.isRequired,
};

export default EmergenciesForm;
