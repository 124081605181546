import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Typography, Grid, Button } from "@material-ui/core";
import { ScrollToTopOnMount, Lookup, FormButton } from "frame/components";
import CreateHealthSafetyLinks from "../config/health-safety-links-container";
import { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ADD_TIMESHEET_ROUTE } from "features/timesheet/timesheet-routes";
import { useRouter } from "utils";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	root: {},
	container: {
		margin: theme.spacing(3, 2)
	},
	content: {
		backgroundColor: theme.palette.white,
		overflowX: "hidden"
	},
	label: {
		color: "#999999"
	},
	primaryButton: {
		"background": theme.palette.highlight,
		"borderColor": theme.palette.highlight,
		"&:hover": {
			background: "#c1c72c",
			borderColor: "#c1c72c"
		}
	},
	button: {
		padding: theme.spacing(1, 4),
		color: theme.palette.brand,
		marginLeft: "16px",
		fontWeight: 600,
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(1, 3)
		}
	},
	addIcon: {
		marginRight: theme.spacing(1)
	}
}));

const Summary = ({ site }) => {
	const theme = useTheme();
	const classes = useStyles();
	const [isContentHidden, setIsContentHidden] = useState(true);
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
	const { history } = useRouter();

	const siteDetails = [
		{ label: "Site Address", value: site.address },
		{ label: "Suburb", value: site.suburb },
		{ label: "Town/City", value: site.townCity },
		{ label: "Site Contact Person", value: site.siteContactPerson },
		{ label: "Site Contact Phone", value: site.siteContactPhone },
		{ label: "Site Contact Email", value: site.siteContactEmail }
	];

	return (
		<div className={classes.content}>
			<ScrollToTopOnMount />
			<Grid className={classes.container} container spacing={2}>
				<Grid item md={6} xs={12}>
					<Grid container spacing={2}>
						<Grid item md={12} xs={12}>
							<Typography variant="h2">Site Details</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography className={classes.label} variant="body2">
								Site Name
							</Typography>
							<Typography variant="h5">{site.siteName}</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography className={classes.label} variant="body2">
								Customer Name
							</Typography>
							<Typography variant="h5">
								<Lookup id={site.customerId} resource="customers" attribute="customerName" />
							</Typography>
						</Grid>
						{(!mobileView || !isContentHidden) &&
							siteDetails.map((siteDetail, index) => (
								<Grid item md={12} xs={12} key={index}>
									<Typography className={classes.label} variant="body2">
										{siteDetail.label}
									</Typography>
									<Typography variant="h5">{siteDetail.value || "-"}</Typography>
								</Grid>
							))}
						{mobileView && (
							<Grid item md={12} xs={12}>
								<FormButton
									//disabled={values.save.saving}
									onClick={() => setIsContentHidden(!isContentHidden)}
								>
									{isContentHidden ? "Show more" : "Show less"}
								</FormButton>
							</Grid>
						)}
					</Grid>
				</Grid>

				<Grid item md={6} xs={12}>
					<Grid container>
						{!mobileView && (
							<Grid item xl={6} lg={8} md={10} sm={10} xs={10}>
								<CreateHealthSafetyLinks siteId={site.id}/>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

Summary.propTypes = {
	site: PropTypes.object.isRequired
};

export default Summary;
