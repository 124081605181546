import { connect } from "react-redux";
import CloseClicked from "../actions/close-clicked";
import SimpleSnackbar from "./simple-snackbar";

const mapStateToProps = (state) => {
	return {
		errorList: state.notifications.errorList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onCloseClicked: (id) => {
			dispatch(CloseClicked(id));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleSnackbar);
