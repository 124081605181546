import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { RemoveWarning, TextClip, Lookup } from "frame/components";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useRemover, useRouter, isStaffUser } from "utils";
import { SITES_PATH } from "../actions/types";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		marginBottom: theme.spacing(2),
		cursor: "pointer"
	},
	inactive: {
		// background: theme.palette.background.tertiary,
	},
	content: {
		"padding": theme.spacing(2, 4),
		"flexGrow": 1,
		"display": "flex",
		"alignItems": "center",
		"justifyContent": "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap"
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2)
		}
	},
	label: {
		padding: theme.spacing(0, 0, 0.5, 0),
		color: theme.palette.text.dark
	},
	value: {
		padding: theme.spacing(0, 1, 0.5, 0)
	},
	valueInactive: {
		color: theme.palette.text.tertiary
	},
	sub: {
		fontSize: "85%",
		fontStyle: "italic"
	},
	button: {
		"display": "flex",
		"justifyContent": "center",
		"alignItems": "center",
		"position": "relative",
		"width": 40,
		"height": 40,
		"minWidth": 30,
		"borderRadius": 20,
		"overflow": "hidden",
		"&::after": {
			display: "flex",
			content: '" "',
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			background: "transparent"
		}
	},
	grid: {
		display: "grid",
		gridTemplateColumns: "auto 100px",
		gridGap: 0,
		width: "100%"
	},
	body: {
		"display": "grid",
		"gridTemplateColumns": "100%",
		"gridGap": 0,
		"width": "100%",
		[theme.breakpoints.up("sm")]: {
			gridTemplateColumns: "50% 50%"
		},
		[theme.breakpoints.up("lg")]: {
			gridTemplateColumns: "20% 20% 20% 20% 20%"
		},
		"& > div": {
			padding: theme.spacing(1, 0)
		}
	},
	options: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "flex-start",
		paddingTop: theme.spacing(1)
	}
}));

const SiteListing = ({ site, className, role, onEdit, onDelete, onSelectSite, ...rest }) => {
	const classes = useStyles();
	const { history } = useRouter();
	const { remove, onPossiblyRemove, onCancelRemove } = useRemover();
	const { id } = site;

	// const customer = customers.filter(node => node.id === site.customerId)[0]
	const archived = false; //customer && String(customer.status).toLowerCase() === 'inactive'
	const inactive = String(site.status).toLowerCase() === "inactive";

	const onSelect = (event) => {
		if (event.target.type !== "button") {
			onSelectSite(id);
			history.push(`${SITES_PATH}/${id}`); // todo: dispatch push()
		}
	};

	const rootStyle = clsx(classes.root, archived ? classes.inactive : "", className);

	const valueStyle = clsx(classes.value, archived || inactive ? classes.valueInactive : "");

	return (
		<>
			<RemoveWarning open={remove} onCancel={onCancelRemove} onConfirm={() => onDelete(id)}>
				Warning, deleting the site {'"'}
				{site.siteName}
				{'"'} is permanent and cannot be undone.
			</RemoveWarning>

			<Card {...rest} data-cy={`site-${site.siteName}`} className={rootStyle} onClick={onSelect}>
				<CardContent className={classes.content}>
					<div className={classes.grid}>
						<div className={classes.body}>
							<div>
								<Typography className={classes.label} variant="body2">
									Site Name
								</Typography>
								<Typography className={valueStyle} variant="h5">
									<TextClip hideSeeMore text={site.siteName} numberOfCharacters={30} />
								</Typography>
							</div>
							<div>
								<Typography className={classes.label} variant="body2">
									Customer Name
								</Typography>
								<Typography className={valueStyle} variant="h5">
									<Lookup id={site.customerId} resource="customers" attribute="customerName" />
								</Typography>
							</div>
							<div>
								<Typography className={classes.label} variant="body2">
									Address
								</Typography>
								{site.address && (
									<Typography className={valueStyle} variant="h5">
										<TextClip hideSeeMore text={site.address} numberOfCharacters={30} />
									</Typography>
								)}
								{site.suburb && (
									<Typography className={valueStyle} variant="h5">
										<TextClip hideSeeMore text={site.suburb} numberOfCharacters={30} />
									</Typography>
								)}
								{site.townCity && (
									<Typography className={valueStyle} variant="h5">
										<TextClip hideSeeMore text={site.townCity} numberOfCharacters={30} />
									</Typography>
								)}
								{!site.address && !site.suburb && !site.townCity && "-"}
							</div>
							<div>
								<Typography className={classes.label} variant="body2">
									Contact Person
								</Typography>
								{site.siteContactPerson && (
									<Typography className={valueStyle} variant="h5">
										<TextClip hideSeeMore text={site.siteContactPerson} numberOfCharacters={30} />
									</Typography>
								)}
								{site.siteContactPhone && (
									<Typography className={valueStyle} variant="h5">
										<TextClip hideSeeMore text={site.siteContactPhone} numberOfCharacters={30} />
									</Typography>
								)}
								{site.siteContactEmail && (
									<Typography className={valueStyle} variant="h5">
										<TextClip hideSeeMore text={site.siteContactEmail} numberOfCharacters={30} />
									</Typography>
								)}
								{!site.siteContactPerson && !site.siteContactPhone && !site.siteContactEmail && "-"}
							</div>
							<div>
								<Typography className={classes.label} variant="body2">
									Status
								</Typography>
								<Typography className={valueStyle} variant="h5">
									{site.status}
								</Typography>
							</div>
						</div>
						<div className={classes.options}>
							{!isStaffUser(role) && (
								<>
									<Button
										data-cy={`edit-btn-${site.siteName}`}
										className={classes.button}
										color="primary"
										onClick={() => onEdit(id)}
										size="small"
									>
										<EditIcon className={classes.icon} />
									</Button>
									<Button
										data-cy={`delete-btn-${site.siteName}`}
										className={classes.button}
										color="primary"
										onClick={onPossiblyRemove}
										size="small"
									>
										<DeleteIcon className={classes.icon} />
									</Button>
								</>
							)}
						</div>
					</div>
				</CardContent>
			</Card>
		</>
	);
};

SiteListing.propTypes = {
	role: PropTypes.string.isRequired,
	className: PropTypes.string,
	site: PropTypes.object.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onSelectSite: PropTypes.func.isRequired
};

export default SiteListing;
