import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import { Card } from "@material-ui/core";
import { SearchDropdown } from "frame/components";
import moment from "moment";
import { isNotNullOrEmpty } from "utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(2, 3)
	},
	heading: {
		margin: 0
	},
	formGroup: {
		margin: theme.spacing(0),
		padding: theme.spacing(1, 0),
		"& >*": {
			margin: theme.spacing(2, 0)
		}
	}
}));

const SiteForm = ({ values, onChange, disableSite, disableDate, hideDate, disableLeaveTypes = true, searchDropdownReadOnly = false, isAdmin = false, hideTaskTypes = true }) => {
	const classes = useStyles();
	const siteList = useSelector(({ sites }) => sites.pagination?.results || []);
	const taskTypeList = useSelector(({ taskTypes }) => taskTypes.data || []);
	const disabled = values.save ? values.save.saving : values.isLoading;
	const siteOptions = isNotNullOrEmpty(siteList)
		? siteList
			.filter((site) => {
				return (site.status && site.status.toLowerCase() !== "active") ||
						(site.siteName.includes("Leave - ") == true && disableLeaveTypes)  // Filter out any leave types
					? false
					: true;
			})
			.map((site) => ({
				label: site.siteName,
				value: site.id
			}))
			.sort((a, b) => (a.label > b.label ? 1 : -1))
		: [];
	const taskTypeOptions = isNotNullOrEmpty(siteList)
		? taskTypeList
			.filter((tt) => tt.active
			)
			.map((tt) => ({
				label: tt.taskType,
				value: tt.id
			}))
			.sort((a, b) => (a.label > b.label ? 1 : -1))
		: [];

	const onSelectOption = useCallback(
		(key) => (value) => {
			onChange({
				...values,
				[key]: value
			});
		},
		[values, onChange]
	);

	const onSelectDate = useCallback(
		(key) => (value) => {
			onChange({
				...values,
				[key]: moment(value).format()
			});
		},
		[values, onChange]
	);

	return (
		<Card className={classes.root}>
			<div className={classes.formGroup}>
				<SearchDropdown
					id="site"
					data-cy="site"
					label="Select Site *"
					disabled={disabled || disableSite}
					defaultOptions={siteOptions}
					value={values.siteId !== "NO-ID" ? values.siteId : ""}
					onChange={onSelectOption("siteId")}
					readOnly={searchDropdownReadOnly}
				/>
				{
					taskTypeOptions.length === 0 || hideTaskTypes
						? null
						: <SearchDropdown
							id="taskType"
							data-cy="taskType"
							label="(optional) Select Task Type"
							disabled={disabled || disableSite}
							defaultOptions={taskTypeOptions}
							value={values.organisationTaskTypeId || ""}
							onChange={onSelectOption("organisationTaskTypeId")}
						/>
				}

			</div>
			{!hideDate && (
				<div className={classes.formGroup}>
					<DatePicker
						data-cy="date"
						disabled={disabled || disableDate}
						format="Do MMMM YYYY"
						fullWidth
						inputVariant="outlined"
						label="Select Date"
						value={values.createdAt}
						variant="outlined"
						disableFuture={true}
						onChange={onSelectDate("createdAt")}
					/>
				</div>
			)}
		</Card>
	);
};

SiteForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	disableSite: PropTypes.bool,
	disableDate: PropTypes.bool,
	hideDate: PropTypes.bool
};

export default SiteForm;
