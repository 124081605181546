import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRouter } from "utils";
import PageHeaderTabs from "../page-header-tabs";
import { Grid } from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
} from "frame/components";
import {
	strip,
	HazardousSubstanceForm,
	DateForm,
	UploadFormCard,
} from "features/forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
	menu: {
		padding: theme.spacing(3, 0, 0, 0),
		"& > *": {
			marginRight: theme.spacing(3),
		},
	},
}));

const Form = ({ values, onChange, onReset, onSave }) => {
	const classes = useStyles();
	const { history } = useRouter();

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
	};

	const onCancel = () => {
		onReset();
		history.push(fromHealthAndSafety.INFO_ROUTE);
	};

	const isValid = (substances) => {
		const { name, substanceType, volumeMeasurement, actionRecommended } =
			substances;
		const valid = (value) => value && value !== "";
		return (
			valid(name) &&
			valid(substanceType) &&
			valid(volumeMeasurement) &&
			valid(actionRecommended)
		);
	};

	return (
		<Page title="Add Hazardous Substances to Library">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Hazardous Substances"
				title="Add Hazardous Substances to Library"
				// addTabTitle="Add substance"
				// listTabTitle="View library of hazardous substances"
				listTabPath={
					fromHealthAndSafety.INFO_HAZARDOUS_SUBSTANCES_ROUTE
				}
				backActionPath={fromHealthAndSafety.INFO_ROUTE}
			/>
			<PageContent padSides padBottom padTop>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Grid container spacing={3}>
						<Grid item lg={6} md={12} sm={12} xs={12}>
							<div className={classes.body}>
								<DateForm values={values} onChange={onChange} />
								<HazardousSubstanceForm
									values={values}
									onChange={onChange}
								/>
								<UploadFormCard
									title={"Add Attachments"}
									values={values}
									onChange={onChange}
								/>
							</div>
						</Grid>
					</Grid>

					<FormActions className={classes.actions}>
						<FormSubmitButton
							disabled={
								!isValid(values.substances) || values.locked
							}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
						<FormButton
							disabled={values.save.saving}
							onClick={onCancel}
						>
							Cancel
						</FormButton>
					</FormActions>
				</ValidatorForm>
			</PageContent>
		</Page>
	);
};

Form.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

export default Form;
