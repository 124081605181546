import { connect } from "react-redux";
import Register from "./register";

import {
	onChangeEmail,
	onOrganisationChange,
	onChangeFirstName,
	onChangeLastName,
	onChangePassword,
	onChangePhone,
	onRegister,
	onRegisterErrorClear,
} from "../actions";

const mapStateToProps = ({ authorisation, router }) => ({
	organisation: authorisation.register.organisation,
	firstName: authorisation.register.firstName,
	lastName: authorisation.register.lastName,
	emailAddress: authorisation.register.emailAddress,
	password: authorisation.register.password,
	phone: authorisation.register.phone,
	registerErrorMessage: authorisation.register.registerErrorMessage,
	save: authorisation.register.save,
	params: router.location.search,
});

const mapDispatchToProps = {
	onChangeEmail,
	onOrganisationChange,
	onChangeFirstName,
	onChangeLastName,
	onChangePassword,
	onChangePhone,
	onRegister,
	onRegisterErrorClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
