import * as R from "ramda";
import fromAuth from "../actions/types";

import {
	defaultSaveState,
	saveRequested,
	saveSuccess,
	saveFailed,
} from "utils";

const defaultState = {
	...defaultSaveState,
};

const forgotPasswordReducer = (state = defaultState, action) => {
	switch (action.type) {
		// Forgot Password
		case fromAuth.AUTHORISATION_FORGOT_PASSWORD_REQUESTED: {
			const nextState = forgotPasswordRequested(R.clone(state), action);
			return saveRequested(nextState);
		}
		case fromAuth.AUTHORISATION_FORGOT_PASSWORD_FULFILLED: {
			const nextState = forgotPasswordFulfilled(R.clone(state), action);
			return saveSuccess(nextState);
		}
		case fromAuth.AUTHORISATION_FORGOT_PASSWORD_FAILED: {
			const nextState = forgotPasswordFailed(R.clone(state), action);
			return saveFailed(nextState, action.payload);
		}
		default:
			return state;
	}
};

const forgotPasswordRequested = (state, action) => {
	return state;
};

const forgotPasswordFulfilled = (state, action) => {
	state.errorMessage = action.payload;
	return state;
};
const forgotPasswordFailed = (state, action) => {
	state.errorMessage = action.payload;
	return state;
};

export default forgotPasswordReducer;
