import { connect } from "react-redux";
import EditOrganisationForm from "./edit-organisation-form";
import { onEditClose, onEditSave, onEditNameChange } from "../actions";

const mapStateToProps = ({ organisations }) => ({
	organisation: organisations.editOrganisationForm,
	isEditModalShowing: organisations.isEditModalShowing,
});

const mapDispatchToProps = {
	onCloseClicked: onEditClose,
	onSaveClicked: onEditSave,
	onNameChange: onEditNameChange,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EditOrganisationForm);
