import { connect } from "react-redux";
import {
	onOrganisationRefetchList,
	onSearchChanged,
	onEdit,
	onDelete,
	onCurrentOrganisationChange,
	onOrganisationsStatusFilter,
} from "../../actions";
import OrganisationList from "./organisation-list";

const mapStateToProps = ({ organisations }) => ({
	organisations: organisations.filteredOrganisationList,
	flag: organisations.fetch,
	filter: organisations.filter,
});

const mapDispatchToProps = {
	onEdit,
	onDelete,
	onSearchChanged,
	onFetchOrganisations: onOrganisationRefetchList,
	onChangeOrganisation: onCurrentOrganisationChange,
	onStatusFilterChange: onOrganisationsStatusFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationList);
