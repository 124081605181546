import uuid from "uuid";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError, flatMap } from "rxjs/operators";
import { onSafetyMinutesListRequest } from "features/health-and-safety/actions";
import { TASK_SAFETY_MINUTES_ROUTE } from "features/health-and-safety";
import { ADD_SAFETY_MINUTES_FORM_SUBMIT } from "../actions/types";
import { profileFetch } from "features/account";
import {
	DBService,
	SAFETY_MINUTES_TABLE,
	replaceDataURLs,
	authHeaders,
	getOrganisationBaseUrl,
} from "frame";
import { filterPeople, getCurrentTimestamp, isProd } from "utils";
import * as R from "ramda";
import ReactGA from "react-ga";
import {
	onAddSafetyMinutesReset,
	onAddSafetyMinutesSaveFailed,
	onAddSafetyMinutesSaveSuccess,
} from "../actions";

const fetchAttachments = () => async () => {
	const db = new DBService();
	var files = await db.getAll(SAFETY_MINUTES_TABLE);
	return files;
};

const addSafetyMinutesEffect = (action$, state$) =>
	action$.pipe(
		ofType(ADD_SAFETY_MINUTES_FORM_SUBMIT),
		flatMap(fetchAttachments()),
		mergeMap((result) => {
			let attachments = R.clone(
				state$.value.addSafetyMinutesFormReducer.attachments
			);
			attachments = replaceDataURLs(attachments, result);

			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/safety-minutes/createorupdate`,
					{
						id:
							state$.value.addSafetyMinutesFormReducer.id ||
							uuid.v4(),
						title: state$.value.addSafetyMinutesFormReducer.title,
						siteId: state$.value.addSafetyMinutesFormReducer.siteId,
						createdAt:
							state$.value.addSafetyMinutesFormReducer.createdAt,
						people: filterPeople(
							state$.value.addSafetyMinutesFormReducer.people
						),
						otherPeople:
							state$.value.addSafetyMinutesFormReducer
								.otherPeople,
						hazards:
							state$.value.addSafetyMinutesFormReducer.hazards,
						operationalIssues:
							state$.value.addSafetyMinutesFormReducer.notes
								.operationalIssues,
						generalIssuesDiscussed:
							state$.value.addSafetyMinutesFormReducer.notes
								.generalIssuesDiscussed,
						recipients:
							state$.value.addSafetyMinutesFormReducer.recipients,
						recipientsOther:
							state$.value.addSafetyMinutesFormReducer
								.recipientsOther,
						sendEmail:
							state$.value.addSafetyMinutesFormReducer
								.sendToRecipientsOnSave,
						attachments: attachments,
						latitude: state$.value.geolocation.latitude,
						longitude: state$.value.geolocation.longitude,
						timestamp: getCurrentTimestamp(),
						organisationId:
							state$.value.organisations.currentOrganisation,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						isProd &&
							ReactGA.event({
								category: "Tasks",
								action: "Save Clicked",
								label: "Add Safety Minutes",
							});
						return of(
							profileFetch(),
							onSafetyMinutesListRequest(),
							onAddSafetyMinutesSaveSuccess(),
							onAddSafetyMinutesReset(),
							push(TASK_SAFETY_MINUTES_ROUTE)
						);
					}),
					/* eslint-disable */
					catchError((error) =>
						of(onAddSafetyMinutesSaveFailed(error.message))
					)
					/* eslint-enable */
				);
		})
	);

export default addSafetyMinutesEffect;
