import Types from "../actions/types";
import * as fromOrganisations from "features/organisations";
import {
	onStaffTrainingListReceive,
	onStaffTrainingListFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { authHeaders, tokenExists, getOrganisationBaseUrl } from "frame";

export const staffTrainingListEffect = (action$, state$) =>
	action$.pipe(
		ofType(
			Types.STAFF_TRAINING_LIST_REQUESTED,
			// fromOrganisations.ORGANISATION_FETCH_SUCCESS,
			fromOrganisations.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED
		),
		filter(() => tokenExists()),
		filter(() => !!state$.value.organisations.currentOrganisation),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/staff-training/paged`,
					{
						page: 1,
						pageSize: 5,
						orderBy: "createdAt",
						ascending: false,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onStaffTrainingListReceive(ajaxResponse.response))
					),
					catchError((error) => of(onStaffTrainingListFailed(error)))
				)
		)
	);

export const staffTrainingPagedListEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.STAFF_TRAINING_PAGED_LIST_REQUESTED),
		filter(() => state$.value.organisations.currentOrganisation),
		filter(() => tokenExists()),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(({ payload }) => {
			const pagingParams = payload;
			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/staff-training/paged`,
					pagingParams,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onStaffTrainingListReceive(ajaxResponse.response))
					),
					catchError((error) => of(onStaffTrainingListFailed(error)))
				);
		})
	);
