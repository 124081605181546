import uuid from "uuid";
import * as R from "ramda";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError, flatMap } from "rxjs/operators";
import { TASK_INCIDENTS_ROUTE } from "features/health-and-safety";
import { ADD_INCIDENT_FORM_SUBMIT } from "../actions/types";
import {
	authHeaders,
	getOrganisationBaseUrl,
	DBService,
	INCIDENTS_TABLE,
	replaceDataURLs,
} from "frame";
import { filterPeople, getCurrentTimestamp, isProd } from "utils";
import * as fromActions from "../actions";
import { onIncidentListRequest } from "features/health-and-safety";
import ReactGA from "react-ga";

const fetchAttachments = () => async () => {
	const db = new DBService();
	var files = await db.getAll(INCIDENTS_TABLE);
	return files;
};

const addAccidentEffect = (action$, state$) =>
	action$.pipe(
		ofType(ADD_INCIDENT_FORM_SUBMIT),
		flatMap(fetchAttachments()),
		mergeMap((result) => {
			let attachments = R.clone(
				state$.value.addIncidentFormReducer.attachments
			);
			attachments = replaceDataURLs(attachments, result);

			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/accident/createorupdate`,
					{
						id: state$.value.addIncidentFormReducer.id || uuid.v4(),
						siteId: state$.value.addIncidentFormReducer.siteId,
						createdAt:
							state$.value.addIncidentFormReducer.createdAt,
						people: filterPeople(
							state$.value.addIncidentFormReducer.people
						),
						otherPeople:
							state$.value.addIncidentFormReducer.otherPeople,
						incidentDetails:
							state$.value.addIncidentFormReducer.accident
								.incidentDetails,
						action: state$.value.addIncidentFormReducer.accident
							.action,
						category:
							state$.value.addIncidentFormReducer.accident
								.category,
						relatedHazardOrSubstance:
							state$.value.addIncidentFormReducer.accident
								.relatedHazardOrSubstance,
						seriousHarm:
							state$.value.addIncidentFormReducer.accident
								.seriousHarm,
						investigationOutcomes:
							state$.value.addIncidentFormReducer.accident
								.investigationOutcomes,
						appendNotes:
							state$.value.addIncidentFormReducer.appendNotes,
						recipients:
							state$.value.addIncidentFormReducer.recipients,
						recipientsOther:
							state$.value.addIncidentFormReducer.recipientsOther,
						sendEmail:
							state$.value.addIncidentFormReducer
								.sendToRecipientsOnSave,
						attachments: attachments,
						latitude: state$.value.geolocation.latitude,
						longitude: state$.value.geolocation.longitude,
						timestamp: getCurrentTimestamp(),
						organisationId:
							state$.value.organisations.currentOrganisation,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						// const { siteId } = getResponseBody(ajaxResponse.request)

						// const path = siteId
						//   ? createSitePath(siteId, SITE_INCIDENTS_PATH) // broken
						//   : TASK_HOME_ROUTE

						isProd &&
							ReactGA.event({
								category: "Tasks",
								action: "Save Clicked",
								label: "Add Accident",
							});

						return of(
							onIncidentListRequest(),
							fromActions.onAddAccidentSaveSuccess(),
							fromActions.onAddAccidentReset(),
							push(TASK_INCIDENTS_ROUTE)
						);
					}),
					/* eslint-disable */
					catchError((error) =>
						of(fromActions.onAddAccidentSaveFailed(error.message))
					)
					/* eslint-enable */
				);
		})
	);

export default addAccidentEffect;
