import { connect } from "react-redux";
import Summary from "./summary";
// import { getSiteById } from '../utils'

const mapStateToProps = ({ router, sites }) => ({
	// site: getSiteById(state)
	site: sites.currentSite,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
