import { connect } from "react-redux";
import FabDrawer from "./fab-drawer";

const mapStateToProps = ({ profile }) => ({
	role: profile.role || "Unknown",
});

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FabDrawer);
