import { safetyWiseApi } from "utils/safetyWiseApi";

export function getAllDocumentPacks(organisationGUID, pagination) {
	return safetyWiseApi.post(`${organisationGUID}/document-pack/paged`, {
		...pagination,
	});
}

export function createDocumentPack(organisationGUID, documentPack) {
	return safetyWiseApi.post(`${organisationGUID}/document-pack/create`, {
		...documentPack,
	});
}

export function updateDocumentPack(organisationGUID, documentPack) {
	return safetyWiseApi.post(
		`${organisationGUID}/document-pack/update/${documentPack.documentPackId}`,
		{
			name: documentPack.name,
			notes: documentPack.notes,
		}
	);
}

export function archiveDocumentPack(organisationGUID, documentPackId) {
	return safetyWiseApi.post(
		`${organisationGUID}/document-pack/archive/${documentPackId}`,
		{}
	);
}

export function unarchiveDocumentPack(organisationGUID, documentPackId) {
	return safetyWiseApi.post(
		`${organisationGUID}/document-pack/unarchive/${documentPackId}`,
		{}
	);
}

export function emailDocumentPack(
	organisationGUID,
	documentPackId,
	toInternalAddresses,
	toExternalAddresses
) {
	return safetyWiseApi.post(
		`${organisationGUID}/document-pack/email/${documentPackId}`,
		{
			toInternalAddresses: toInternalAddresses,
			toExternalAddresses: toExternalAddresses,
		}
	);
}

export function deleteDocumentPack(organisationGUID, documentPackId) {
	return safetyWiseApi.delete(
		`${organisationGUID}/document-pack/${documentPackId}`
	);
}
