import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { formatDate } from "utils";
import { TextClip, CrudOptions, Lookup } from "frame/components";
import { isStaffUser, createRowSelector } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
		"&:hover": {
			cursor: "pointer",
		},
	},
	container: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(3),
		},
	},
	label: {
		color: "#999999",
	},
}));

const AccidentCard = ({
	accident,
	onAppend,
	onEdit,
	onView,
	onDelete,
	role,
	onEmail,
}) => {
	const classes = useStyles();
	const onRowSelect = createRowSelector(onView);

	const id = {
		id: accident.id,
		form: "accident",
	};

	return (
		<Card className={classes.root} onClick={onRowSelect}>
			<CardContent className={classes.content}>
				<Grid className={classes.container} container spacing={2}>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Site Name
						</Typography>
						<Typography variant="h5">
							<Lookup
								id={accident.siteId}
								resource="sites"
								attribute="siteName"
							/>
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Created
						</Typography>
						<Typography variant="h5">
							{formatDate(accident.createdAt)}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Incident Details
						</Typography>
						<Typography variant="h5">
							<TextClip
								text={accident.incidentDetails}
								numberOfCharacters={140}
							/>
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Category
						</Typography>
						<Typography variant="h5">
							{accident.category || "-"}
						</Typography>
					</Grid>
					<div className="ignore">
						{!isStaffUser(role) && (
							<CrudOptions
								onView={onView}
								onAppend={onAppend}
								onDelete={() => onDelete(id)}
								onEmail={onEmail}
							/>
						)}
					</div>
				</Grid>
			</CardContent>
		</Card>
	);
};

AccidentCard.propTypes = {
	accident: PropTypes.object.isRequired,
	onAppend: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onView: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
};

export default AccidentCard;
