import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	SearchBar,
	Pagination,
	PaginationHeader,
	ListFilterOptions,
} from "frame/components";

// import { sortByPropertyDate } from 'utils'
import * as fromHealthSafetyForm from "features/health-safety-forms";
import HazardCard from "../cards/hazard-card";
import { EditHazardForm, PageHeaderTabs } from "features/health-safety-forms";
import { getPageResults } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {},
	filter: { display: "flex" },
}));

const HazardList = ({ role, hazards, onDelete, onGetHazardList }) => {
	const classes = useStyles();
	const [viewId, setViewId] = useState();
	const [editId, setEditId] = useState();
	const [searchFilter, setSearchFilter] = useState("");

	// const isDoneFetching = hazards.flag === 'done'
	const hazardList = getPageResults(hazards);
	const empty = hazardList && hazardList.length === 0;

	const onView = (id) => () => setViewId(id);

	const onEdit = (id) => () => setEditId(id);

	const onCancelEdit = () => {
		setEditId(null);
		setViewId(null);
	};

	return (
		<Page className={classes.root} title="Hazard Library">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Hazards"
				title="Hazard Library"
				// addTabTitle="New"
				// listTabTitle="See All"
				addTabPath={fromHealthSafetyForm.ADD_HAZARD_PATH}
				backActionPath={fromHealthSafetyForm.INFO_ROUTE}
			/>
			<div className={classes.filter}>
				<SearchBar onSearch={setSearchFilter} />
				<ListFilterOptions
					useArchive
					filterKey="status"
					onGetList={onGetHazardList}
					pagination={hazards}
					orderBy="createdAt"
					ascending={false}
				/>
			</div>

			<PageContent pad>
				<PaginationHeader hidePagination pagination={hazards} />

				{!empty &&
					hazardList.map((hazard, index) => (
						<HazardCard
							key={index}
							hazard={hazard}
							role={role}
							onView={onView(hazard.id)}
							onEdit={onEdit(hazard.id)}
							onDelete={onDelete}
						/>
					))}

				{empty && (
					<Typography variant="body1">
						There are currently no Hazards listed.
					</Typography>
				)}

				{/* {!isDoneFetching && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <CircularProgress />
          </div>
        )} */}

				<Pagination
					hidePagination
					onGetList={onGetHazardList}
					pagination={hazards}
					orderBy="createdAt"
					ascending={false}
					searchKey={searchFilter}
				/>
			</PageContent>
			{viewId && (
				<EditHazardForm id={viewId} readOnly onClose={onCancelEdit} />
			)}
			{editId && <EditHazardForm id={editId} onClose={onCancelEdit} />}
		</Page>
	);
};

HazardList.propTypes = {
	role: PropTypes.string.isRequired,
	hazards: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	onGetHazardList: PropTypes.func.isRequired,
};

export default HazardList;
