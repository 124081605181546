import { connect } from "react-redux";
import Login from "./login";

import {
	onLogin,
	onChangeEmail,
	onChangePassword,
	onChangeRememberMe,
} from "../actions";

const mapStateToProps = ({ authorisation }) => ({
	emailAddress: authorisation.login.emailAddress,
	password: authorisation.login.password,
	isValid: authorisation.login.isValid,
	loginAttemptError: authorisation.login.errorMessage,
	load: authorisation.login.fetch,
});

const mapDispatchToProps = {
	onLogin,
	onChangeEmail,
	onChangePassword,
	onChangeRememberMe,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
