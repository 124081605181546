import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.text.link,
	},
}));

const TextLink = ({ children, to, onClick }) => {
	const classes = useStyles();

	return (
		<>
			{to && (
				<RouterLink className={classes.root} to={to}>
					{children}
				</RouterLink>
			)}
			{onClick && ( // eslint-disable-next-line
				<a href="#" className={classes.root} onClick={onClick}>
					{children}
				</a>
			)}
		</>
	);
};

TextLink.propTypes = {
	children: PropTypes.string.isRequired,
	to: PropTypes.string,
	onClick: PropTypes.func,
};

export default TextLink;
