import React, { useState } from "react";
// import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { ScrollToTopOnMount } from "frame/components";
import { SiteForm } from "features/sites";
// import Skeleton from '@material-ui/lab/Skeleton'
import {
	sortByPropertyAlphabetically,
	useRouter,
	isNotNullOrEmpty,
} from "utils";
import TrainingListing from "./training-listing";
import { EditStaffTrainingForm } from "features/health-safety-forms";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {},
	container: {
		margin: theme.spacing(4, 3),
	},
	content: {
		position: "relative",
		paddingBottom: theme.spacing(6),
		backgroundColor: theme.palette.white,
		overflowX: "hidden",
	},
	list: {
		marginTop: theme.spacing(2),
	},
}));

const StaffTrainings = () => {
	const classes = useStyles();

	const [editId, setEditId] = useState();

	const staffTrainingList = useSelector(
		({ healthSafety }) => healthSafety.staffTrainings?.results
	);

	const onEdit = (id) => () => setEditId(id);

	const onCancelEdit = () => setEditId(null);

	const { match } = useRouter();
	const { staffId } = match.params;
	const nodes =
		staffTrainingList &&
		staffTrainingList
			.filter((node) => JSON.stringify(node.people).includes(staffId))
			.sort(sortByPropertyAlphabetically("name"));

	const empty = nodes && nodes.length <= 0;

	return (
		<div className={classes.content}>
			<ScrollToTopOnMount />
			<SiteForm />
			<div className={classes.container}>
				<Typography variant="h2">Training</Typography>
				<div className={classes.list}>
					{!empty &&
						isNotNullOrEmpty(nodes) &&
						nodes.map((node, index) => (
							<TrainingListing
								key={index}
								item={node}
								onEdit={onEdit(node.id)}
							/>
						))}
				</div>

				{empty && (
					<Typography variant="body1">
						There are currently no trainings for this staff member.
					</Typography>
				)}
			</div>
			{editId && (
				<EditStaffTrainingForm
					readOnly
					id={editId}
					onClose={onCancelEdit}
				/>
			)}
		</div>
	);
};

// StaffTrainings.propTypes = {
// }

export default StaffTrainings;
