import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Divider,
	FormControlLabel,
} from "@material-ui/core";

import InputEmailForm from "./input-email-form";
import SelectEmailForm from "./select-email-form";
import { onList } from "features/staff";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	heading: {
		marginBottom: theme.spacing(-3),
	},
	divider: {
		display: "flex",
		margin: theme.spacing(2, 0),
		width: "100%",
		color: theme.palette.divider,
	},
	fieldLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginLeft: theme.spacing(-1.2),
	},
	label: {
		margin: 0,
	},
	formControlLabel: {
		userSelect: "none",
		marginLeft: theme.spacing(1),
	},
}));

const EmailRecipientsForm = ({
	disabled,
	values,
	onChange,
	allowExternalNotifications,
	hideSendEmailCheckBox,
	hideCardLayout,
	title,
	getStaffList,
}) => {
	const classes = useStyles();

	useEffect(() => {
		getStaffList();
	}, []);

	disabled = (values.save && values.save.saving) || disabled;

	const onCheckChange = ({ target: { checked } }) =>
		onChange({
			...values,
			sendToRecipientsOnSave: checked ? true : false,
		});

	const formBody = () => (
		<>
			<CardHeader
				className={classes.heading}
				title={title || "Send Email"}
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent>
				<SelectEmailForm
					disabled={disabled}
					values={values}
					onChange={onChange}
				/>
				{allowExternalNotifications && (
					<>
						<Divider className={classes.divider} />
						<InputEmailForm
							disabled={disabled}
							values={values}
							onChange={onChange}
						/>
					</>
				)}
				{!hideSendEmailCheckBox && (
					<div className={classes.fieldLabel}>
						<FormControlLabel
							className={classes.formControlLabel}
							control={
								<Checkbox
									checked={values.sendToRecipientsOnSave}
									disabled={disabled}
									onChange={onCheckChange}
									value="yes"
									inputProps={{
										"aria-label": `Send emails on save checkbox`,
									}}
								/>
							}
							label={`Send email(s) when I click save`}
						/>
					</div>
				)}
			</CardContent>
		</>
	);

	return !hideCardLayout ? (
		<Card className={classes.root}>{formBody()}</Card>
	) : (
		<>
			<Divider className={classes.divider} />
			{formBody()}
		</>
	);
};

EmailRecipientsForm.propTypes = {
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	allowExternalNotifications: PropTypes.bool.isRequired,
	hideSendEmailCheckBox: PropTypes.bool,
	hideCardLayout: PropTypes.bool,
};

const mapStateToProps = ({ profile }) => ({
	allowExternalNotifications: profile.allowExternalNotifications,
});

const mapDispatchToProps = {
	getStaffList: onList,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EmailRecipientsForm);
