import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { authHeaders } from "frame/auth-headers";
import { tokenExists } from "frame/access-token";
import { getOrganisationBaseUrl } from "frame";
import fromTypes from "../actions/types";
import ReactGA from "react-ga";
import { isProd } from "utils";
import { reportDownloadSuccess, reportDownloadFailed } from "../actions";
import moment from "moment";

export const reportDownloadEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.REPORT_DOWNLOAD),
		filter(() => tokenExists()),
		mergeMap(({ payload }) => {
			const { report, staffId, siteId } = payload;
			const id = staffId || siteId || 0;
			return ajax
				.get(
					`${getOrganisationBaseUrl(state$.value)}/${requestUrl(
						report
					)}/export${requestDateRange(payload)}${requestId(
						report,
						id
					)}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						isProd &&
							ReactGA.event({
								category: "My Account",
								action: "Download Clicked",
								label: `Report ${report}`,
							});
						return of(exportCSVFile(ajaxResponse.response, report));
					}),
					catchError((error) =>
						of(reportDownloadFailed(error.response?.error))
					)
				);
		})
	);

const requestId = (report, id) => {
	switch (report) {
		case "staff":
			return `&staffId=${id}`;
		case "hazards":
		case "hazardous_substances":
			return `&siteId=${id}`;
		default:
			return "";
	}
};

const requestUrl = (value) => {
	switch (value) {
		case "accidents":
		case "timesheets":
		case "hazards":
			return value.slice(0, -1); // take out 's'
		case "inductions":
			return "induction-minutes";

		case "safety_minutes":
			return "safety-minutes";
		case "hazardous_substances":
			return "hazardous-substance";
		case "staff_training":
			return "staff-training";
		default:
			return value;
	}
};

const requestDateRange = (payload) => {
	let defaultFrom = moment().subtract(10, "years").utc().format();
	let defaultTo = moment().utc().format();

	return payload.from !== undefined && payload.to !== undefined
		? `?from=${moment.utc(payload.from).format()}&to=${moment
				.utc(payload.to)
				.format()}`
		: `?from=${defaultFrom}&to=${defaultTo}`;
};

const exportCSVFile = (csv, fileTitle) => {
	let exportedFilenmae = fileTitle + ".csv" || "export.csv";

	let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, exportedFilenmae);
	} else {
		// force download link
		let link = document.createElement("a");
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			let url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", exportedFilenmae);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	return reportDownloadSuccess();
};
