import { connect } from "react-redux";
import SitesRoutes from "./sites-routes";
import { onListRequested, onSearchClear } from "../actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	onGetSitesList: onListRequested,
	onSearchClear: onSearchClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(SitesRoutes);
