import React, { useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Paper, /*Button,*/ Input } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		display: "flex",
		alignItems: "center",
	},
	search: {
		flex: 1,
		display: "flex",
		alignItems: "center",
		height: 42,
		padding: theme.spacing(0, 2),
	},
	searchIcon: {
		marginRight: theme.spacing(2),
		color: theme.palette.icon,
	},
	searchInput: {
		flex: 1,
	},
	searchButton: {
		marginLeft: theme.spacing(2),
	},
}));

const Search = (props) => {
	const { onSearch, className, ...rest } = props;
	const classes = useStyles();
	const field = useRef();

	const onBlur = () => {};

	const onFocus = () => field.current && field.current.focus();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
			onClick={onFocus}
		>
			<Paper className={classes.search} elevation={1}>
				<SearchIcon className={classes.searchIcon} />
				<Input
					className={classes.searchInput}
					disableUnderline
					inputRef={field}
					placeholder="Search"
					onBlur={onBlur}
					onChange={(e) => {
						onSearch(e.target.value);
					}}
				/>
			</Paper>
		</div>
	);
};

Search.propTypes = {
	className: PropTypes.string,
	onSearch: PropTypes.func,
};

export default Search;
