import uuid from "uuid";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError, flatMap } from "rxjs/operators";
import { authHeaders } from "frame/auth-headers";
import { getOrganisationBaseUrl } from "frame";
import { onHazardousSubstanceListRequest } from "features/health-and-safety/actions";
import { ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT } from "../actions/types";
import { INFO_HAZARDOUS_SUBSTANCES_ROUTE } from "features/health-and-safety";
import {
	DBService,
	HAZARDOUS_SUBSTANCES_TABLE,
	replaceDataURLs,
} from "frame/indexedDBService";
import * as R from "ramda";
import { getCurrentTimestamp } from "utils";

import {
	onAddHazardousSubstanceReset,
	onAddHazardousSubstanceSaveFailed,
	onAddHazardousSubstanceSaveSuccess,
} from "../actions";

const fetchAttachments = () => async () => {
	const db = new DBService();
	var files = await db.getAll(HAZARDOUS_SUBSTANCES_TABLE);
	return files;
};

const addHazardousSubstanceEffect = (action$, state$) =>
	action$.pipe(
		ofType(ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT),
		flatMap(fetchAttachments()),
		mergeMap((result) => {
			let attachments = R.clone(
				state$.value.addHazardousSubstanceFormReducer.attachments
			);
			attachments = replaceDataURLs(attachments, result);

			const form = state$.value.addHazardousSubstanceFormReducer;

			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/hazardous-substance/createorupdate`,
					{
						// form.substances.id - for archive , form.id - for edit substances  TODO: refactor both to use one
						id: form.substances.id || form.id || uuid.v4(),
						createdAt: form.createdAt,
						name: form.substances.name,
						substanceType: form.substances.substanceType,
						supplierSDSReportHeld:
							form.substances.supplierSDSReportHeld,
						volumeMeasurement: form.substances.volumeMeasurement,
						protectiveClothingRequired:
							form.substances.protectiveClothingRequired,
						actionRecommended: form.substances.actionRecommended,
						status: form.substances.status || "Active",
						attachments: attachments || [],
						latitude: state$.value.geolocation.latitude,
						longitude: state$.value.geolocation.longitude,
						timestamp: getCurrentTimestamp(),
						organisationId:
							state$.value.organisations.currentOrganisation,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return of(
							onHazardousSubstanceListRequest(),
							onAddHazardousSubstanceSaveSuccess(),
							onAddHazardousSubstanceReset(),
							push(INFO_HAZARDOUS_SUBSTANCES_ROUTE)
						);
					}),
					/* eslint-disable */
					catchError((error) =>
						of(onAddHazardousSubstanceSaveFailed(error.message))
					)
					/* eslint-enable */
				);
		})
	);

export default addHazardousSubstanceEffect;
