import fromTypes from "./types";

export const onChangeOrganisationRole = (payload) => ({
	type: fromTypes.STAFF_ORGANISATION_ROLE_CHANGED,
	payload,
});

export const onChangeEmail = (payload) => ({
	type: fromTypes.STAFF_EMAIL_CHANGED,
	payload,
});

export const onChangeFirstName = (payload) => ({
	type: fromTypes.STAFF_FIRSTNAME_CHANGED,
	payload,
});

export const onChangeLastName = (payload) => ({
	type: fromTypes.STAFF_LASTNAME_CHANGED,
	payload,
});

export const onChangePassword = (payload) => ({
	type: fromTypes.STAFF_PASSWORD_CHANGED,
	payload,
});

export const onChangePhone = (payload) => ({
	type: fromTypes.STAFF_PHONE_CHANGED,
	payload,
});

export const onToggleEmailInvite = (payload) => ({
	type: fromTypes.STAFF_EMAIL_INVITE_TOGGLED,
	payload,
});

export const onChangeStatus = (payload) => ({
	type: fromTypes.STAFF_STATUS_CHANGED,
	payload,
});

export const onChangeStaffType = (payload) => ({
	type: fromTypes.STAFF_TYPE_CHANGED,
	payload,
});

export const onChangeStaffIdNumber = (payload) => ({
	type: fromTypes.STAFF_STAFF_ID_NUMBER_CHANGED,
	payload,
});

export const onChangeSupervisor = (payload) => ({
	type: fromTypes.STAFF_SUPERVISOR_CHANGED,
	payload,
});

export const onFileChanged = (payload) => ({
	type: fromTypes.STAFF_FILE_CHANGED,
	payload,
});

export const onNotesChanged = (payload) => ({
	type: fromTypes.STAFF_NOTES_CHANGED,
	payload,
});

// CRUD

export const onEdit = (payload) => ({
	type: fromTypes.STAFF_EDIT_CLICKED,
	payload,
});

// Fetching

export const onSave = () => ({
	type: fromTypes.STAFF_SAVE_CLICKED,
});

export const onPagedListRequested = (payload) => ({
	type: fromTypes.STAFF_PAGED_LIST_REQUESTED,
	payload,
});

export const onList = () => ({
	type: fromTypes.STAFF_LIST_REQUESTED,
});

export const onListFailed = (payload) => ({
	type: fromTypes.STAFF_LIST_FAILED,
	payload,
});

export const onListSuccess = (payload) => ({
	type: fromTypes.STAFF_LIST_RECEIVED,
	payload,
});

export const onListLoaded = () => ({
	type: fromTypes.STAFF_LIST_ONLOADED,
});

export const onAdd = () => ({
	type: fromTypes.STAFF_ADD_CLICKED,
});

export const onAddFailed = (payload) => ({
	type: fromTypes.STAFF_ADD_FAILED,
	payload,
});

export const onAddSuccess = (payload) => ({
	type: fromTypes.STAFF_ADD_SUCCEEDED,
	payload,
});

export const onImpersonate = (payload) => ({
	type: fromTypes.STAFF_IMPERSONATE_CLICKED,
	payload,
});

export const onImpersonateFailed = (payload) => ({
	type: fromTypes.STAFF_IMPERSONATE_FAILED,
	payload,
});

// Misc

export const onClose = () => ({
	type: fromTypes.STAFF_CLOSE_CLICKED,
});

export const onEditRequested = () => ({
	type: fromTypes.STAFF_EDIT_REQUESTED,
});

export const onSearchChanged = (payload) => ({
	type: fromTypes.STAFF_SEARCH_CHANGED,
	payload,
});

export const onSearchClear = (payload) => ({
	type: fromTypes.STAFF_SEARCH_CLEAR,
	payload,
});

export const onLookup = (payload) => ({
	type: fromTypes.STAFF_LOOKUP,
	payload,
});

export const onLookupSuccess = (payload) => ({
	type: fromTypes.STAFF_LOOKUP_SUCCESS,
	payload,
});

export const onLookupFailed = (payload) => ({
	type: fromTypes.STAFF_LOOKUP_FAILED,
	payload,
});

// batch add staff
export const onBatchAdd = (payload, index) => ({
	type: fromTypes.STAFF_BATCH_ADD,
	payload,
	index,
});

export const onBatchAddSuccess = (payload) => ({
	type: fromTypes.STAFF_BATCH_ADD_SUCCEEDED,
	payload,
});

export const onBatchAddFailed = (payload) => ({
	type: fromTypes.STAFF_BATCH_ADD_FAILED,
	payload,
});
