import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { CardContent, Grid, TextField, Typography } from "@material-ui/core";
import { RadioListInput } from "frame/components";
import { TextValidator } from "react-material-ui-form-validator";
// import { hasValue } from 'utils'

import Options from "./options";
import JSAHazardForm from "../jsa-hazard-form";

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.background.secondary,
	},
	header: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	button: {
		margin: theme.spacing(0),
	},
}));

const StepForm = ({
	className,
	disabled,
	step,
	taskIndex,
	onChangeStep,
	onDeleteStep,
}) => {
	const classes = useStyles();

	const onDelete = () => onDeleteStep(step.index);

	const levels = [
		{
			label: "Low",
			value: "low",
		},
		{
			label: "Moderate",
			value: "moderate",
		},
		{
			label: "High",
			value: "high",
		},
	];

	// step name becomes mandatory if any step field is filled
	// const isMandatoryStep = hasValue(step.hazards) || hasValue(step.initialRiskAssessment) || hasValue(step.levelOfControlMethods) || hasValue(step.residualRiskAssessment)

	const onTextInputChange =
		(key) =>
		({ target: { value } }) => {
			onChangeStep(step.index, key)(value);
		};

	return (
		<CardContent className={clsx(step.index % 2 ? "" : classes.root)}>
			<Grid container spacing={2}>
				<Grid className={classes.header} item xs={12}>
					<Typography variant="h5">Step {step.index + 1}</Typography>
					<Options label="Delete Step" onDelete={onDelete} />
				</Grid>
				<Grid item xs={12}>
					<TextValidator
						id={`task_${taskIndex}_name_${step.index}`}
						disabled={disabled}
						fullWidth
						label="Step Name"
						value={step.name || ""}
						variant="outlined"
						onChange={onTextInputChange("name")}
						// validators={isMandatoryStep ? ['required'] : null}
						// errorMessages={isMandatoryStep ? ['A Step must have a task name']:  null}
					/>
				</Grid>
				<Grid item xs={12}>
					<JSAHazardForm
						values={step}
						disabled={disabled}
						onChange={onChangeStep(step.index, "hazards")}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="h6">
						Initial Risk Assessment
					</Typography>
					<RadioListInput
						data-cy={"initialRiskAssessment"}
						disabled={disabled}
						initialValue={step.initialRiskAssessment}
						options={levels}
						onChange={onChangeStep(
							step.index,
							"initialRiskAssessment"
						)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id={`task_${taskIndex}_levelOfControlMethods_${step.index}`}
						disabled={disabled}
						fullWidth
						label="Level of Control/Methods"
						multiline
						rows={3}
						value={step.levelOfControlMethods || ""}
						variant="outlined"
						onChange={onTextInputChange("levelOfControlMethods")}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="h6">
						Residual Risk Assessment
					</Typography>
					<RadioListInput
						data-cy={"residualRiskAssessment"}
						disabled={disabled}
						initialValue={step.residualRiskAssessment}
						options={levels}
						onChange={onChangeStep(
							step.index,
							"residualRiskAssessment"
						)}
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

StepForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	step: PropTypes.object.isRequired,
	taskIndex: PropTypes.number.isRequired,
	onChangeStep: PropTypes.func.isRequired,
	onDeleteStep: PropTypes.func.isRequired,
};

export default StepForm;
