import React from "react";
import LandingPageFrame from "./landing-page-frame";
import ConfirmationForm from "./forms/confirmation-form";

const Confirmation = (props) => {
	return (
		<LandingPageFrame
			title={"Account Confirmation"}
			hideImage={props.token === ""}
			form={<ConfirmationForm {...props} />}
		/>
	);
};

export default Confirmation;
