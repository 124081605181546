import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import PageHeaderTabs from "../page-header-tabs";
import { Grid } from "@material-ui/core";
import ReactGA from "react-ga";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
} from "frame/components";
import {
	strip,
	SelectHazardsForm,
	PeopleForm,
	SiteForm,
	UploadFormCard,
	ExtraNotesForm,
	EmailRecipientsForm,
} from "features/forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";
import {
	AddHazardToSiteForm,
	AddHazardousSubstanceToSiteForm,
} from "features/health-safety-forms";
import {
	useRouter,
	isSameLength,
	isNotNullOrEmpty,
	isDefaultGuid,
	validateAndScrollTo,
	isProd,
} from "utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
}));

const Form = ({ values, onChange, onReset, onSave, onLookup, currentSite }) => {
	const classes = useStyles();
	const { history } = useRouter();

	const guidelinesBlockRef = useRef();

	const [selectedSite, setSelectedSite] = useState(null);

	const [showReadGuidelinesPrompt, setShowReadGuidelinesPrompt] =
		useState(false);

	const hazardList = useSelector(
		({ healthSafety }) => healthSafety.hazards?.results
	);
	const hazardousSubstanceList = useSelector(
		({ healthSafety }) => healthSafety.hazardousSubstances?.results
	);
	const siteList = useSelector(({ sites }) => sites.pagination?.results);

	useEffect(() => {
		// called on every site change in currentSite.hazards & currentSite.substances
		async function fetchSiteData(id) {
			const siteData = siteList && siteList.filter((s) => s.id === id)[0];
			let siteResponseData = siteData ? siteData : null;

			// to fix the order of site hazards when returned from api, push the newly added hazard to the end of array
			if (
				siteResponseData &&
				values.hazards &&
				values.hazards.length > 0
			) {
				// to ignore on load run, triggered after adding new hazard

				const currentlyReadHazards = values.hazards || [];
				const newlyAddedHazard = siteResponseData.hazards.filter(
					(h) => !currentlyReadHazards.includes(h.hazardId)
				)[0];

				// removed newlyAddedHazard from array
				if (newlyAddedHazard) {
					let siteResponseHazardsData =
						siteResponseData.hazards.filter(
							(h) => h.hazardId !== newlyAddedHazard.hazardId
						);

					siteResponseHazardsData.push(newlyAddedHazard); // push newlyAddedHazard to end of array
					siteResponseData.hazards = siteResponseHazardsData; // update site hazards
				}
			}

			// to fix the order of site substances when returned from api, push the newly added substance to the bottom
			if (
				siteResponseData &&
				values.substances &&
				values.substances.length > 0
			) {
				// to ignore on load run, triggered after adding new substance

				const currentlyReadSubstances = values.substances || [];
				const newlyAddedSubstance = siteResponseData.substances.filter(
					(hs) =>
						!currentlyReadSubstances.includes(
							hs.hazardousSubstanceId
						)
				)[0];

				// removed newlyAddedSubstance from array
				if (newlyAddedSubstance) {
					let siteResponseSubstancesData =
						siteResponseData.substances.filter(
							(hs) =>
								hs.hazardousSubstanceId !==
								newlyAddedSubstance.hazardousSubstanceId
						);

					siteResponseSubstancesData.push(newlyAddedSubstance); // push newlyAddedSubstance to end of array
					siteResponseData.substances = siteResponseSubstancesData; // update site substances
				}
			}

			setSelectedSite(siteResponseData);
		}
		if (values.siteId && !isDefaultGuid(values.siteId)) {
			fetchSiteData(values.siteId);
		} // eslint-disable-next-line
	}, [currentSite.hazards, currentSite.substances, values.siteId]);

	useEffect(() => {
		// lookup site by id and set result as currentSite
		if (values.siteId) {
			onLookup(values.siteId);
		}
	}, [onLookup, values.siteId]);

	const onSubmit = (event) => {
		event.preventDefault();

		if (
			validateAndScrollTo(
				validGuidelinesRule,
				guidelinesBlockRef,
				setShowReadGuidelinesPrompt
			)
		) {
			isProd &&
				ReactGA.event({
					category: "Tasks",
					action: "Save Clicked",
					label: "Add Induction Meeting",
				});
			onSave(strip(values));
		}
	};

	const onCancel = () => {
		onReset();
		history.push(fromHealthAndSafety.TASK_ROUTE);
	};

	const siteHazards =
		selectedSite &&
		selectedSite.hazards &&
		selectedSite.hazards.filter((node) => node.status !== "Archived");

	const siteSubstances =
		selectedSite &&
		selectedSite.substances &&
		selectedSite.substances.filter((node) => node.status !== "Archived");

	const validGuidelinesRule =
		isSameLength(values.hazards, siteHazards) &&
		isSameLength(values.substances, siteSubstances);

	useEffect(() => {
		if (validGuidelinesRule) {
			setShowReadGuidelinesPrompt(false);
		}
		// eslint-disable-next-line
	}, [values.hazards, values.substances]);

	// use to compare which entries are preExisting to know which to hide on "add hazard to site" form load
	const preExistingSiteHazards = isNotNullOrEmpty(siteHazards)
		? siteHazards.map((s) => s.hazardId)
		: [];
	const preExistingSiteSubstances = isNotNullOrEmpty(siteSubstances)
		? siteSubstances.map((s) => s.hazardousSubstanceId)
		: [];

	return (
		<Page title="Add Induction Meeting">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Induction"
				title="Add Induction Meeting"
				// addTabTitle="Add induction meeting"
				// listTabTitle="View library of induction meetings"
				listTabPath={fromHealthAndSafety.TASK_INDUCTION_MINUTES_ROUTE}
				backActionPath={fromHealthAndSafety.TASK_ROUTE}
			/>
			<PageContent padSides padBottom padTop>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Grid container spacing={3}>
						<Grid item lg={6} md={12} sm={12} xs={12}>
							<div className={classes.body}>
								<SiteForm values={values} onChange={onChange} />
								{values.siteId && (
									<>
										<PeopleForm
											recordName="induction"
											defaultStaffRole="Staff"
											values={values}
											onChange={onChange}
										/>
										<div ref={guidelinesBlockRef} />
										<SelectHazardsForm
											showNotesReminderCheckbox
											values={values}
											onChange={onChange}
											siteHazards={siteHazards}
											siteSubstances={siteSubstances}
											hazardList={hazardList}
											hazardousSubstanceList={
												hazardousSubstanceList
											}
											showError={showReadGuidelinesPrompt}
										/>
										<UploadFormCard
											title={"Add Attachments"}
											values={values}
											onChange={onChange}
										/>
										<ExtraNotesForm
											values={values}
											onChange={onChange}
										/>
										<EmailRecipientsForm
											values={values}
											onChange={onChange}
										/>
									</>
								)}
							</div>
						</Grid>
					</Grid>

					{/* debugging purposes:
            <pre>
            {JSON.stringify({
              "read all hazards guidelines" : isSameLength(values.hazards, siteHazards),
              "read all substances guidelines": isSameLength(values.substances, siteSubstances),
              'values.substances': values.substances,
              siteSubstances,
              "hazards checked": values.hazards && values.hazards.length,
              "total hazards": siteHazards && siteHazards.length,
              "substances checked": values.substances && values.substances.length,
              "total substances": siteSubstances && siteSubstances.length,
            }, null, 2)}
          </pre> */}
					<FormActions className={classes.actions}>
						<FormSubmitButton
							disabled={values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
						<FormButton
							disabled={values.save.saving}
							onClick={onCancel}
						>
							Cancel
						</FormButton>
					</FormActions>
				</ValidatorForm>
			</PageContent>
			{values.siteId && (
				<AddHazardToSiteForm
					siteId={values.siteId}
					preExistingSiteHazards={preExistingSiteHazards}
				/>
			)}
			{values.siteId && (
				<AddHazardousSubstanceToSiteForm
					siteId={values.siteId}
					preExistingSiteSubstances={preExistingSiteSubstances}
				/>
			)}
		</Page>
	);
};

Form.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onLookup: PropTypes.func.isRequired,
	currentSite: PropTypes.object.isRequired,
};

export default Form;
