import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { formatDate, isNotNullOrEmpty } from "utils";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { PeopleListing } from "frame/components";
import { StaffTrainingLookup } from "features/health-and-safety";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
		cursor: "pointer",
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	label: {
		padding: theme.spacing(0, 0, 0.5, 0),
		color: theme.palette.text.tertiary,
	},
	value: {
		padding: theme.spacing(0, 0, 0.5, 0),
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
}));

const TrainingListing = ({ item, onEdit }) => {
	const classes = useStyles();

	const trainers =
		isNotNullOrEmpty(item.people) &&
		item.people.filter(({ role }) => role === "Trainer");

	return (
		<Card className={classes.root} onClick={() => onEdit(item.id)}>
			<CardContent className={classes.content}>
				<Grid className={classes.container} container spacing={2}>
					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Training Topic
						</Typography>
						<Typography variant="h6" className={classes.value}>
							<StaffTrainingLookup
								id={item.staffTrainingTopicId}
							/>
						</Typography>
					</Grid>
					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Date Completed
						</Typography>
						<Typography variant="h6" className={classes.value}>
							{formatDate(item.createdAt)}
						</Typography>
					</Grid>
					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Valid until
						</Typography>
						<Typography variant="h6" className={classes.value}>
							<StaffTrainingLookup
								id={item.staffTrainingTopicId}
								validUntil
							/>
						</Typography>
					</Grid>
					<Grid item md={3} xs={12}>
						<PeopleListing
							hideRole
							title="Trainer(s)"
							people={trainers}
						/>
					</Grid>
				</Grid>
			</CardContent>
			{/* <CrudOptions
        id={item.id}
        onEdit={onEdit} /> */}
		</Card>
	);
};

TrainingListing.propTypes = {
	item: PropTypes.object.isRequired,
	onEdit: PropTypes.func.isRequired,
};

export default TrainingListing;
