import frameTypes from "frame/actions/types";
import { ofType } from "redux-observable";
import { filter, mergeMap, catchError } from "rxjs/operators";
import { of, EMPTY } from "rxjs";
import { ajax } from "rxjs/ajax";
import { getBaseUrl } from "frame/base-url";
import {
	networkChanged,
	checkNetworkConnection,
	sendRequested,
} from "../actions";
import fromTypes from "../actions/types";

/* triggers on user activity */
export const onlineStatusPollEffect = (action$, state$) => {
	return action$.pipe(
		filter(
			(action) =>
				action.type.indexOf("_CLICKED") !== -1 || // on any form save
				action.type === "@@router/LOCATION_CHANGE" // on user navigation
		),
		mergeMap(() => of(checkNetworkConnection()))
	);
};

export const checkNetworkEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.CHECK_NETWORK_CONNECTION),
		mergeMap(() =>
			ajax({
				url: `${getBaseUrl()}/api/online`,
				method: "HEAD",
			}).pipe(
				mergeMap(() => {
					if (!state$.value.queue.hasNetwork) {
						return of(networkChanged(true), sendRequested());
					} else {
						return EMPTY;
					}
				}),
				catchError(() => {
					if (state$.value.queue.hasNetwork) {
						return of(networkChanged(false));
					} else {
						return EMPTY;
					}
				})
			)
		)
	);
