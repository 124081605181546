import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	SearchBar,
	Pagination,
	PaginationHeader,
	SendEmailModal,
	PopupSnackbar
} from "frame/components";
import * as fromHealthSafetyForm from "features/health-safety-forms";
import SafetyPlanChecklistCard from "../cards/safety-plan-checklist-card";
import {
	EditSafetyPlanChecklistForm,
	PageHeaderTabs,
} from "features/health-safety-forms";
import { getPageResults } from "utils";
import { safetyWiseApi } from "utils/safetyWiseApi";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "none",
	},
	filter: { display: "flex" },
}));

const SafetyPlanChecklistList = ({
	safetyPlanChecklists,
	onDelete,
	role,
	onSearchChanged,
	searchFilter,
	onGetSafetyPlanChecklistsList,
	currentOrganisationGUID,
}) => {
	const classes = useStyles();
	const [viewId, setViewId] = useState(null);
	const [editId, setEditId] = useState(null);
	const [isClone, setIsClone] = useState(false);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");
	const [isSendingEmail, setIsSendingEmail] = useState(false);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [siteId, setSiteId] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");

	let safetyPlanChecklistList = getPageResults(safetyPlanChecklists);
	const empty =
		safetyPlanChecklistList && safetyPlanChecklistList.length === 0;

	const onView = (id) => () => setViewId(id);

	const onEdit = (id) => () => setEditId(id);

	const onClone = (id) => () => {
		setIsClone(true);
		setEditId(id);
	};

	const onCancelEdit = () => {
		setViewId(null);
		setEditId(null);
		setIsClone(false);
	};

	const onEmail = (id, siteId) => () => {
		setSelectedItemId(id);
		setSiteId(siteId)
		setShowEmailModal(true);
	};
	
	const handleApiError = (error) => {
		if (error.response && error.response.status === "401") {
			setErrorMessage("You are not authorized to perform this action.");
		} else if (error.response && error.response.status === "404") {
			setErrorMessage("The record no longer exists.");
		} else {
			setErrorMessage(
				"Something went wrong, please refresh the page and try again."
			);
		}
	};

	return (
		<Page
			className={classes.root}
			title="Site Safety Plan (Checklist) Library"
		>
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Safety Plan Checklist"
				title="Site Safety Plan (Checklist) Library"
				// addTabTitle="Add safety plan"
				// listTabTitle="View library of safety plans"
				addTabPath={fromHealthSafetyForm.ADD_SAFETY_PLAN_CHECKLIST_PATH}
				backActionPath={fromHealthSafetyForm.INFO_ROUTE}
			/>
			<div className={classes.filter}>
				<SearchBar onSearch={onSearchChanged} />
				{/* <ListFilterOptions
            filterKey="createdAt"
            onGetList={onGetSafetyPlanChecklistsList}
            pagination={safetyPlanChecklists}
            orderBy="createdAt"
            ascending={false}
          /> */}
			</div>

			<PageContent pad>
				<PaginationHeader
					hidePagination
					pagination={safetyPlanChecklists}
				/>

				{!empty &&
					safetyPlanChecklistList.map((item, index) => (
						<SafetyPlanChecklistCard
							key={index}
							safetyPlanChecklist={item}
							onView={onView(item.id)}
							onEdit={onEdit(item.id)}
							onClone={onClone(item.id)}
							onDelete={onDelete}
							role={role}
							onEmail={onEmail(item.id, item.siteId)}
						/>
					))}

				{empty && (
					<Typography className={classes.empty} variant="body1">
						There are currently no Safety Plan Checklists listed.
					</Typography>
				)}

				<Pagination
					hidePagination
					onGetList={onGetSafetyPlanChecklistsList}
					pagination={safetyPlanChecklists}
					orderBy="createdAt"
					ascending={false}
					searchKey={searchFilter}
				/>
			</PageContent>
			{viewId && (
				<EditSafetyPlanChecklistForm
					id={viewId}
					readOnly
					onClose={onCancelEdit}
				/>
			)}
			{editId && (
				<EditSafetyPlanChecklistForm
					id={editId}
					isClone={isClone}
					onClose={onCancelEdit}
				/>
			)}
			<SendEmailModal
				isOpen={showEmailModal}
				title={"Email Safety Plan Checklist"}
				onClose={() => setShowEmailModal(false)}
				onSend={(selectedRecipients) => {
					setIsSendingEmail(true);
					safetyWiseApi
						.post(
							`${currentOrganisationGUID}/safety-plan-checklist/email/${selectedItemId}`,
							{
								toInternalAddresses:
									selectedRecipients.recipients,
								toExternalAddresses: [
									...selectedRecipients.recipientsOther,
									...selectedRecipients.recipientsCustomerSites,
								],
							}
						)
						.then(() => {
							setSuccessMessage("Emails sent successfully.");
						})
						.catch(handleApiError)
						.finally(() => {
							setIsSendingEmail(false);
							setShowEmailModal(false);
						});
				}}
				isSaving={isSendingEmail}
				selectedItemId={selectedItemId}
				siteId={siteId}
				emailListType={"allCustomerSiteRecord"}
			/>
			<PopupSnackbar
				message={errorMessage}
				coloured
				error
				onExited={() => setErrorMessage("")}
			/>
			<PopupSnackbar
				message={successMessage}
				coloured
				success
				onExited={() => setSuccessMessage("")}
			/>
		</Page>
	);
};

SafetyPlanChecklistList.propTypes = {
	safetyPlanChecklists: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
	onSearchChanged: PropTypes.isRequired,
	onGetSafetyPlanChecklistsList: PropTypes.func.isRequired,
};

export default SafetyPlanChecklistList;
