import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	PageHeaderAddButton,
	SearchBar,
	Pagination,
	PaginationHeader,
	ListFilterOptions,
	PopupSnackbar,
} from "frame/components";

import StaffListing from "./staff-listing";
import StaffAddEditForm from "./staff-add-edit-container";
import {
	isConsultantUser,
	isStaffUser,
	getPageResults,
	useIsBornAccount,
} from "utils";
import { INFO_HOME_ROUTE } from "features/home/home-routes";
import BatchCreateStaffForm from "./staff-batch-create-form";

const useStyles = makeStyles((theme) => ({
	root: {},
	body: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(3),
		},
	},
	header: {
		margin: theme.spacing(0, 0, 2, 0),
	},
	divider: {
		marginTop: theme.spacing(1),
		height: 1,
	},
	filter: { display: "flex" },
}));

const StaffList = ({
	profile,
	organisations,
	onEdit,
	onDelete,
	onImpersonate,
	onAdd,
	onSendResetEmail,
	onSendConfirmationEmail,
	onGetStaffList,
	pagination,
	apiError,
	onListLoaded,
}) => {
	const classes = useStyles();
	const isBornAccount = useIsBornAccount();
	const [showError, setShowError] = useState(false);
	const [showImpersonationLoading, setImpersonationLoading] = useState(false);
	const [showBatchCreateModal, setShowBatchCreateModal] = useState(false);
	const staffList = getPageResults(pagination);
	const currentStaffId = profile.staffId;
	const [searchFilter, setSearchFilter] = useState("");

	useEffect(() => {
		onListLoaded();
		setShowError(false);
	}, [onListLoaded, setShowError]);

	useEffect(() => {
		setShowError(apiError && apiError !== "");
		setImpersonationLoading(false);
	}, [apiError, setShowError]);

	const isCreator = (staff) => {
		const organisation = organisations.filter(
			(node) => node.creatorId === staff.id
		);
		return !!organisation.length;
	};

	if (isStaffUser(profile.role)) {
		return null;
	}

	return (
		<Page className={classes.root} title="Staff">
			<ScrollToTopOnMount />
			<PageHeaderAddButton
				title="Staff"
				addTabTitle="Add Staff"
				onAdd={onAdd}
				staffUserHide={false}
				backActionPath={INFO_HOME_ROUTE}
				secondaryTabTitle="Batch Create Staff"
				onSecondaryAction={
					isBornAccount ? () => setShowBatchCreateModal(true) : null
				}
			/>

			<div className={classes.filter}>
				<SearchBar onSearch={setSearchFilter} />

				<ListFilterOptions
					filterKey="Status"
					onGetList={(pagination) => {
						// temporary fix to patch an incorrect pageSize caused by buggy pagination logic
						onGetStaffList({
							...pagination,
							pageSize: 5000,
						});
					}}
					pagination={pagination}
					orderBy="status,createdAt"
				/>
			</div>

			<PageContent pad>
				<div className={classes.body}>
					<PaginationHeader hidePagination pagination={pagination} />
					{staffList.map((staff, index) => (
						<StaffListing
							editable={
								isCreator(staff) ||
								isConsultantUser(staff.organisationRole)
									? staff.id === currentStaffId
									: true
							}
							key={index}
							staff={staff}
							staffList={staffList}
							organisations={organisations}
							onEdit={onEdit}
							onDelete={onDelete}
							onImpersonate={(id) => {
								onImpersonate(id);
								setImpersonationLoading(true);
							}}
							onSendResetEmail={onSendResetEmail}
							onSendConfirmationEmail={onSendConfirmationEmail}
							profile={profile}
						/>
					))}
					{showImpersonationLoading && (
						<PopupSnackbar
							message={
								"Requesting impersonation...please do not refresh the page."
							}
							coloured
						/>
					)}

					{showError && (
						<PopupSnackbar message={apiError} coloured error />
					)}
				</div>

				<Pagination
					hidePagination
					onGetList={onGetStaffList}
					pagination={pagination}
					orderBy="status,createdAt"
					searchKey={searchFilter}
				/>
			</PageContent>

			<StaffAddEditForm />

			{showBatchCreateModal && (
				<BatchCreateStaffForm
					open={true}
					onClose={() => setShowBatchCreateModal(false)}
				/>
			)}
		</Page>
	);
};

StaffList.propTypes = {
	profile: PropTypes.object.isRequired,
	organisations: PropTypes.array.isRequired,
	onEdit: PropTypes.func.isRequired,
	onAdd: PropTypes.func,
	onSendResetEmail: PropTypes.func.isRequired,
	onSendConfirmationEmail: PropTypes.func.isRequired,
	onGetStaffList: PropTypes.func.isRequired,
	pagination: PropTypes.object.isRequired,
	apiError: PropTypes.string,
	onListLoaded: PropTypes.func.isRequired,
};

export default StaffList;
