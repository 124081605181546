import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		"& > *": {
			marginBottom: theme.spacing(2),
		},
		[theme.breakpoints.up("sm")]: {
			flexDirection: "row",
			minWidth: 140,
			"& > *": {
				marginRight: theme.spacing(2),
				marginBottom: theme.spacing(0),
			},
		},
	},
}));

const FormActions = ({ children, className }) => {
	const classes = useStyles();

	return <div className={clsx(classes.root, className)}>{children}</div>;
};

FormActions.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
};

export default FormActions;
