import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		marginBottom: theme.spacing(1),
		backgroundColor: theme.palette.highlight,
		backgroundImage: 'url("/images/healthAndSafetyCardBG.svg")',
		backgroundRepeat: "no-repeat",
		backgroundPosition: "right",
		backgroundSize: "auto 100%",
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "left",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	Icon: {
		borderRadius: 25,
		padding: 5,
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.primary.main,
	},
	label: {
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(1),
		color: theme.palette.primary.main,
		fontWeight: "bold",
		letterSpacing: 0.1,
		[theme.breakpoints.down("xs")]: {
			fontSize: "0.9rem",
		},
	},
}));

const HomeCard = ({ text, link, icon: Icon, number }) => {
	const classes = useStyles();

	return (
		<Card className={classes.root} component={RouterLink} to={link}>
			<CardContent className={classes.content}>
				{Icon && <Icon className={classes.Icon} fontSize="large" />}
				<Typography className={classes.label} variant="h5">
					{text}
					{number !== undefined && <>{` (${number})`}</>}
				</Typography>
			</CardContent>
		</Card>
	);
};

HomeCard.propTypes = {
	text: PropTypes.string,
	link: PropTypes.string,
	icon: PropTypes.object,
	number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default HomeCard;
