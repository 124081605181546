import { combineReducers } from "redux";
import tokenReducer from "./token-reducer";
import loginReducer from "./login-reducer";
import registerReducer from "./register-reducer";
import forgotPasswordReducer from "./forgot-password-reducer";
import setPasswordReducer from "./set-password-reducer";
import resetPasswordReducer from "./reset-password-reducer";
import confirmationReducer from "./confirmation-reducer";

export default combineReducers({
	token: tokenReducer,
	login: loginReducer,
	register: registerReducer,
	forgotPassword: forgotPasswordReducer,
	setPassword: setPasswordReducer,
	resetPassword: resetPasswordReducer,
	confirmation: confirmationReducer,
});
