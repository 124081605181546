import Types from "../actions/types";
import { ofType } from "redux-observable";
import { mergeMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import { onListRequested, onDeleteSuccess, onDeleteFailed } from "../actions";

const customerRemoveEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.CUSTOMERS_REMOVE_CLICKED),
		mergeMap(({ payload }) =>
			ajax
				.delete(
					`${getOrganisationBaseUrl(
						state$.value
					)}/customer/delete/${payload}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							onDeleteSuccess(ajaxResponse.response),
							onListRequested()
						)
					),
					catchError((error) => of(onDeleteFailed(error)))
				)
		)
	);

export default customerRemoveEffect;
