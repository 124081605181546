import { connect } from "react-redux";
import StaffList from "./staff-list";

import {
	onAdd,
	onEdit,
	onImpersonate,
	onSearchChanged,
	onPagedListRequested,
	onListLoaded,
} from "../actions";

import {
	onSendResetEmail,
	onSendConfirmationEmail,
} from "features/authorisation";

const mapStateToProps = ({ staff, organisations, profile }) => ({
	organisations: organisations.data,
	isAddModalShowing: staff.isAddModalShowing,
	pagination: staff.pagination,
	searchFilter: staff.searchFilter,
	profile: profile,
	apiError: staff.apiError,
});

const mapDispatchToProps = {
	onAdd,
	onEdit,
	onImpersonate,
	onSendResetEmail,
	onSendConfirmationEmail,
	onSearchChanged,
	onGetStaffList: onPagedListRequested,
	onListLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffList);
