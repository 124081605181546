import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import * as fromRoutes from "../actions/types";
import Sites from "./sites-container";
import Site from "./site-container";
import { JobSiteList } from "features/jobs";
import Summary from "./summary-container";

import {
	IncidentSiteList,
	HazardSiteList,
	HazardousSubstancesSiteList,
	SafetyPlanChecklistSiteList,
	// StaffList,
	// JobSafetyAnalysisList,
	// TimesheetList,
	InductionMinutesSiteList,
	SafetyMinutesSiteList,
} from "features/health-and-safety";

const SitesRoutes = ({ onGetSitesList, onSearchClear }) => {
	useEffect(() => {
		onSearchClear();
		onGetSitesList();
	}, [onGetSitesList, onSearchClear]);

	return (
		<Switch>
			<Route path={fromRoutes.SITE_TAB_ROUTE} component={Site} />
			<Route path={fromRoutes.SITES_ROUTE} component={Sites} />
		</Switch>
	);
};

SitesRoutes.propTypes = {
	onGetSitesList: PropTypes.func.isRequired,
	onSearchClear: PropTypes.func.isRequired,
};

export const ChildRoutes = () => (
	<Switch>
		<Route path={fromRoutes.SITE_NOTES_ROUTE} component={JobSiteList} />
		{/* <Route
      path={fromRoutes.SITE_STAFF_ROUTE}
      component={StaffList} /> */}
		<Route path={fromRoutes.SITE_SUMMARY_ROUTE} component={Summary} />
		{/* <Route
      path={fromRoutes.SITE_TIMESHEETS_ROUTE}
      component={TimesheetList} /> */}
		{/* Health & Safety sub routes */}
		<Route
			path={fromRoutes.SITE_INCIDENTS_ROUTE}
			component={IncidentSiteList}
		/>
		<Route
			path={fromRoutes.SITE_HAZARDS_ROUTE}
			component={HazardSiteList}
		/>
		<Route
			path={fromRoutes.SITE_HAZARDOUS_SUBSTANCES_ROUTE}
			component={HazardousSubstancesSiteList}
		/>
		<Route
			path={fromRoutes.SITE_INDUCTION_MINUTES_ROUTE}
			component={InductionMinutesSiteList}
		/>
		<Route
			path={fromRoutes.SITE_SAFETY_MEETING_MINUTES_ROUTE}
			component={SafetyMinutesSiteList}
		/>
		<Route
			path={fromRoutes.SITE_SAFETY_PLAN_CHECKLIST_ROUTE}
			component={SafetyPlanChecklistSiteList}
		/>
		{/* component={SafetyPlanChecklistSiteList} /> */}
		{/* <Route
      path={fromRoutes.SITE_JOB_SAFETY_ANALYSIS_ROUTE}
      component={() => <JobSafetyAnalysisList isSummaryList />} /> */}

		{/* Main/primary/fallback route */}
		<Route path={fromRoutes.SITES_ROUTE} component={Summary} />
	</Switch>
);

export default SitesRoutes;
