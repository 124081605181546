const getApiKey = () => {
	let value = "";
	if (window.location.href.includes("://localhost:")) {
		value = "pk_test_IdSP8eR6tpEKx28oPtePT4oE00wOR19Ewv";
	} else if (
		window.location.href.includes("://app-uat.safetywise.co.nz") ||
		window.location.href.includes("://safetywiseappuat.azurewebsites.net") ||
		window.location.href.includes("://app-uat.fortherecord.pro")
	) {
		value = "pk_test_IdSP8eR6tpEKx28oPtePT4oE00wOR19Ewv";
	} else if (
		window.location.href.includes("://app.safetywise.co.nz") ||
		window.location.href.includes("://app-prod.safetywise.co.nz") ||
		window.location.href.includes("://app.fortherecord.pro") ||
		window.location.href.includes("://app-prod.fortherecord.pro")
	) {
		value = "pk_live_31XWLYyOQYSJ3p4hU3NDBvnl000v1qtZT6";
	}

	return value;
};

export const stripeHelper = {
	getApiKey
};