// used for updating action status flags in reducers eg. used in staff reducer
// flags states: none, processing, done, error

export const defaultFlagState = {
	fetch: "none",
	create: "none",
	update: "none",
	delete: "none",
};

export const resetFlags = (state, flags) => ({
	...state,
	apiError: null,
	flags: {
		...state.flags,
		fetch: "none",
		create: "none",
		update: "none",
		delete: "none",
		...flags,
	},
});

export const updateFlags = (state, flags) => ({
	...state,
	flags: {
		...state.flags,
		...flags,
	},
});
