import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { isNotNullOrEmpty } from "utils";
import { TextValidator } from "react-material-ui-form-validator";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(3, 0, 1, 0),
	},
	errorMessage: {
		marginTop: theme.spacing(1),
		color: theme.palette.error.main,
	},
}));

const UndertakeForm = ({ disabled, values, onInputChange }) => {
	const classes = useStyles();
	const safetyPlan = values.safetyPlan || {};

	const [selectedSite, setSelectedSite] = useState();
	const [error, setError] = useState();

	const customerList = useSelector(
		({ customers }) => customers.pagination?.results
	);
	const siteList = useSelector(({ sites }) => sites.pagination?.results);
	const apiError = useSelector(
		({ addSafetyPlanChecklistFormReducer }) =>
			addSafetyPlanChecklistFormReducer.responseMessage
	);

	useEffect(() => {
		// runs on siteid change
		async function fetchSiteData() {
			const siteData =
				siteList && siteList.filter((s) => s.id === values.siteId)[0];
			setSelectedSite(siteData ? siteData : null);
		}
		fetchSiteData();
		// eslint-disable-next-line
	}, [values.siteId]);

	useEffect(() => {
		apiError && apiError !== "" && setError(apiError);
	}, [apiError]);

	const customer =
		selectedSite &&
		isNotNullOrEmpty(customerList) &&
		customerList.filter((c) => c.id === selectedSite.customerId)[0];
	safetyPlan.client = (customer && customer.customerName) || "";

	return (
		<CardContent className={classes.root}>
			<TextValidator
				fullWidth
				id="name"
				label="Name"
				disabled={disabled}
				value={safetyPlan.name || ""}
				variant="outlined"
				validators={["required"]}
				errorMessages={["This field is required"]}
				error={!!error}
				onChange={(e) => {
					onInputChange("name")(e);
					setError(null);
				}}
			/>
			{error && (
				<Typography variant="body1" className={classes.errorMessage}>
					{error}
				</Typography>
			)}

			<CardHeader
				className={classes.heading}
				title="We undertake as follows:"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						id="safety_plan_undertake_representative"
						disabled={disabled}
						fullWidth
						inputProps={{ maxLength: 50 }}
						label="On-site safety representative"
						value={safetyPlan.onSiteSafetyRepresentative || ""}
						variant="outlined"
						onChange={onInputChange("onSiteSafetyRepresentative")}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="safety_plan_undertake_principal"
						disabled={disabled}
						fullWidth
						inputProps={{ maxLength: 50 }}
						label="Person in control of site: principal"
						value={safetyPlan.personInControlOfSite || ""}
						variant="outlined"
						onChange={onInputChange("personInControlOfSite")}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="safety_plan_undertake_primary_contact"
						disabled={disabled}
						fullWidth
						inputProps={{ maxLength: 50 }}
						label="Main person in charge"
						value={safetyPlan.mainPersonInCharge || ""}
						variant="outlined"
						onChange={onInputChange("mainPersonInCharge")}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextValidator
						id="safety_plan_undertake_primary_contact_phone"
						disabled={disabled}
						fullWidth
						label="Phone"
						inputProps={{ maxLength: 50 }}
						validators={["isNumber"]}
						errorMessages={["Must be a valid phone number"]}
						value={safetyPlan.phone || ""}
						variant="outlined"
						onChange={onInputChange("phone")}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="safety_plan_undertake_client"
						disabled
						fullWidth
						label="Customer"
						value={safetyPlan.client || ""}
						variant="outlined"
						onChange={onInputChange("client")}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="safety_plan_undertake_other"
						disabled={disabled}
						fullWidth
						label="Notes"
						inputProps={{ maxLength: 50 }}
						value={safetyPlan.notes || ""} // TODO: changd all value.other to notes
						variant="outlined"
						onChange={onInputChange("notes")}
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

UndertakeForm.propTypes = {
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onInputChange: PropTypes.func.isRequired,
	apiError: PropTypes.string,
};

export default UndertakeForm;
