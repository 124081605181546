import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, filter, mergeMap, debounceTime } from "rxjs/operators";
import { ofType } from "redux-observable";
import { tokenExists, getOrganisationBaseUrl, authHeaders } from "frame";
import * as fromOrganisations from "features/organisations";
import { isSuperAdminUser, isStaffUser } from "utils";
import fromTypes from "../actions/types";
import * as fromActions from "../actions";

export const subscriptionEffect = (action$, state$) =>
	action$.pipe(
		ofType(
			fromTypes.SUBSCRIPTION_FETCH,
		),
		filter(() => tokenExists()),
		debounceTime(3000), // todo: change to use combineLatest on profile data received (has profile data)
		filter(() => !isStaffUser(state$.value.profile.role) || !isSuperAdminUser(state$.value.profile.role)),
		mergeMap(() =>
			ajax
				.get(
					`${getOrganisationBaseUrl(
						state$.value
					)}/organisation/subscription`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							fromActions.subscriptionFetchSuccess(
								ajaxResponse.response
							)
						)
					),
					catchError((error) =>
						of(fromActions.subscriptionFetchFailed(error.message))
					)
				)
		)
	);
