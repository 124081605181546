import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import { ScrollToTopOnMount } from "frame/components";
import AlertEmailForm from "./alert-email-form";
import { isStaffUser } from "utils";
import { useRouter } from "utils";
import * as fromRoutes from "../../actions/types";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 3, 10, 3),
		backgroundColor: theme.palette.white,
		[theme.breakpoints.down("xs")]: {
			paddingRight: theme.spacing(5),
		},
	},
}));

const Notifications = ({
	alertEmails = [],
	notificationeEmails,
	loading,
	onFetch,
	onSaveAlertEmails,
	role,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
	const { history } = useRouter();

	if (isStaffUser(role)) {
		history.push(fromRoutes.ACCOUNT_PROFILE_PATH);
	}

	// notificationeEmails && notificationeEmails.map(({ email, allowNotifications }) =>
	//   allowNotifications && !alertEmails.includes(email) && alertEmails.push(email))

	const emailsToSync = notificationeEmails
		? notificationeEmails.map((n) => {
				return n.allowNotifications && n.email;
		  })
		: [];

	alertEmails = [...alertEmails, ...emailsToSync];
	const mergedArray = Array.from(new Set([...alertEmails]));

	useEffect(() => {
		if (onFetch) {
			onFetch();
		}
	}, [onFetch]);

	return (
		<div
			className={classes.root}
			style={{ paddingTop: mobileView ? 0 : 30 }}
		>
			<ScrollToTopOnMount />
			{!mobileView && <Typography variant="h2">Notification</Typography>}

			<AlertEmailForm
				emails={mergedArray}
				loading={loading}
				onSave={onSaveAlertEmails}
			/>
		</div>
	);
};

Notifications.propTypes = {
	alertEmails: PropTypes.array,
	notificationeEmails: PropTypes.array,
	loading: PropTypes.bool,
	onFetch: PropTypes.func.isRequired,
	onSaveAlertEmails: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
};

export default Notifications;
