import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from "@material-ui/core";

// import InfoIcon from '@material-ui/icons/Info'
import ListIcon from "@material-ui/icons/FormatListBulleted";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "flex-end",
	},
	helpText: {
		margin: theme.spacing(-1),
		marginTop: theme.spacing(-2),
	},
	icon: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(1),
		borderRadius: 25,
		padding: 5,
		backgroundColor: "#546E7A",
		color: theme.palette.white,
		[theme.breakpoints.down("xs")]: {
			marginLeft: theme.spacing(0),
		},
	},
}));

const TitleWithHelperText = ({ className, title, icon, helperText }) => {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)}>
			<Typography align="left" gutterBottom variant="h5">
				{title ? title : ""}
			</Typography>

			<List dense={true} className={classes.helpText}>
				<ListItem>
					<ListItemIcon>
						{icon ? icon : <ListIcon className={classes.icon} />}
						{/* <InfoIcon /> */}
					</ListItemIcon>
					<ListItemText
						primary={
							helperText ||
							"Click on icon inside textbox to enable bullet points"
						}
					/>
				</ListItem>
			</List>
		</div>
	);
};

TitleWithHelperText.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	icon: PropTypes.any,
	helperText: PropTypes.string,
};

export default TitleWithHelperText;
