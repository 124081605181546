import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";
import { onLookup } from "features/sites";

const mapStateToProps = ({ addInductionMinutesFormReducer, sites }) => ({
	values: addInductionMinutesFormReducer,
	currentSite: sites.currentSite,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddInductionMinutesValueChange,
	onReset: fromActions.onAddInductionMinutesReset,
	onSave: fromActions.onAddInductionMinutesSave,
	onLookup: onLookup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
