export default {
	id: "",
	siteName: "",
	customerId: "",
	address: "",
	suburb: "",
	townCity: "",
	siteContactPerson: "",
	siteContactPhone: "",
	siteContactPhoneErrorMessage: "",
	siteContactEmail: "",
	siteContactEmailErrorMessage: "",
	status: "Active",
	hazards: [],
	substances: [],
	isValid: false,
};
