import { connect } from "react-redux";
import Site from "./site";
// import { onHydrateSite } from '../actions'
// import { getSiteById } from '../utils'

const mapStateToProps = ({ router, sites, customers }) => ({
	// customers: customers.customerList,
	// sites: sites.siteList,
	// site: getSiteById(state),
	site: sites.currentSite,
	// siteId: router.location.pathname.replace('/sites/', '')
});

const mapDispatchToProps = {
	// onHydrateSite
};

export default connect(mapStateToProps, mapDispatchToProps)(Site);
