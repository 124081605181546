import React from "react";
import PropTypes from "prop-types";
import {
	SectionCard,
	PeopleSection,
	AppendNotesListing,
	AttachmentsSectionCard,
	ValueText,
} from "frame/components";
import { Typography, Grid, Divider } from "@material-ui/core";
import { formatDate } from "utils";
import { StaffTrainingLookup } from "features/health-and-safety";

const ReadOnlyForm = ({ values }) => {
	const training = values.training || {};

	return (
		<>
			<SectionCard>
				<Grid container spacing={2}>
					<Grid item sm={12} xs={12}>
						<ValueText
							label="Date"
							value={formatDate(values.createdAt)}
							options={{ bold: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			<SectionCard title="Training Topic">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Topic Name"
							value={
								<StaffTrainingLookup
									id={training.staffTrainingTopicId}
								/>
							}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Valid until"
							value={
								<StaffTrainingLookup
									id={training.staffTrainingTopicId}
									validUntil
								/>
							}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography align="left" gutterBottom variant="body1">
							<strong>Training Guidelines:</strong>{" "}
							<StaffTrainingLookup
								id={training.staffTrainingTopicId}
								attribute="guidelines"
							/>
							<Divider style={{ marginTop: "5px" }} />
						</Typography>
					</Grid>
				</Grid>
			</SectionCard>

			<SectionCard title="People">
				<PeopleSection
					people={values.people}
					otherPeople={values.otherPeople}
				/>
			</SectionCard>

			<AttachmentsSectionCard values={values} />

			<SectionCard title="Appended Notes">
				<AppendNotesListing
					noTitle
					appendNotes={training.appendNotes}
				/>
			</SectionCard>
		</>
	);
};

ReadOnlyForm.propTypes = {
	values: PropTypes.object.isRequired,
};

export default ReadOnlyForm;
