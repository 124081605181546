import fromTypes from "./types";

export const onUploadCompressionRequested = (payload) => ({
	type: fromTypes.UPLOAD_COMPRESSION_REQUESTED,
	payload,
});

export const onUploadCompressionFinished = (payload) => ({
	type: fromTypes.UPLOAD_COMPRESSION_FINISHED,
	payload,
});

export const onUploadCompressionFailed = (payload) => ({
	type: fromTypes.UPLOAD_COMPRESSION_ERRORED,
	payload,
});

export const onUploadLoadAttachments = (payload) => ({
	type: fromTypes.UPLOAD_LOAD_ATTACHMENTS,
	payload,
});

export const onUploadRemoveItem = (payload) => ({
	type: fromTypes.UPLOAD_REMOVE_ITEM,
	payload,
});

export const onUploadRemove = () => ({
	type: fromTypes.UPLOAD_REMOVE,
});

export const onUploadReset = () => ({
	type: fromTypes.UPLOAD_RESET,
});
