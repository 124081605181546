import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onConfirmAttendance } from "../actions";
import InductionMinutesConfirmAttendance from "./induction-minutes-confirm-attendance";

const mapStateToProps = ({ addInductionMinutesFormReducer, profile }) => ({
	organisationName: profile.organisation,
});

const mapDispatchToProps = {
	onConfirm: onConfirmAttendance,
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(InductionMinutesConfirmAttendance)
);
