import { connect } from "react-redux";
import { profileSave } from "../actions";
import Profile from "./profile";

const mapStateToProps = ({ profile }) => ({
	profile: profile,
	save: profile.save,
});

const mapDispatchToProps = {
	onSave: profileSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
