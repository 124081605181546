import uuid from "uuid";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, tap, catchError } from "rxjs/operators";
import { clearImpersonationToken, clearToken, getBaseUrl } from "frame";
import fromTypes from "../actions/types";

import {
	// onLogin,
	onRegisterFailed,
	onRegisterFulfilled,
} from "../actions";

const registerEffect = (action$, state$) => 
	action$.pipe(
	ofType(fromTypes.AUTHORISATION_REGISTER_REQUESTED),
	mergeMap(({ payload }) => {
		const cardToken = payload.token;
		let consultant = state$.value.router.location.search;
		return ajax
			.post(
				`${getBaseUrl()}/api/register${consultant}`,
				{
					id: uuid.v4(),
					organisation:
						state$.value.authorisation.register.organisation,
					firstName:
						state$.value.authorisation.register.firstName,
					lastName: state$.value.authorisation.register.lastName,
					phoneNumber: state$.value.authorisation.register.phone,
					email: state$.value.authorisation.register.emailAddress,
					// password: state$.value.authorisation.register.password,
					creditCardToken: {
						id: cardToken.id,
						client_ip: cardToken.client_ip,
						created: cardToken.created,
						livemode: cardToken.livemode,
						type: cardToken.type,
						used: cardToken.used,
						card: {
							id: cardToken.card.id,
							address_city: cardToken.card.address_city,
							address_country: cardToken.card.address_country,
							address_line1: cardToken.card.address_line1,
							address_line1_check:
								cardToken.card.address_line1_check,
							address_line2: cardToken.card.address_line2,
							address_state: cardToken.card.address_state,
							address_zip: cardToken.card.address_zip,
							address_zip_check:
								cardToken.card.address_zip_check,
							brand: cardToken.card.brand,
							country: cardToken.card.country,
							cvc_check: cardToken.card.cvc_check,
							exp_month: cardToken.card.exp_month,
							exp_year: cardToken.card.exp_year,
							fingerprint: cardToken.card.fingerprint,
							funding: cardToken.card.funding,
							last4: cardToken.card.last4,
							name: cardToken.card.name,
							tokenization_method:
								cardToken.card.tokenization_method,
							},
						}
					},
					{
						"Content-Type": "application/json",
					}
			)
			.pipe(
				tap((action) => {
					clearToken();
					clearImpersonationToken();
				}),
				mergeMap((ajaxResponse) =>
					of(
						// displayErrorMessage(ajaxResponse.response),
						// onLogin(),
						onRegisterFulfilled()
					)
				),
				catchError((error) => of(onRegisterFailed(error.response)))
			);
		})
	);

export default registerEffect;
