import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Modal,
	Card,
	CardContent,
	CardActions,
	Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FormSubmitButton from "./form-submit-button";
import FormButton from "./form-button";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 500,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	body: {
		paddingTop: theme.spacing(1),
		marginBottom: theme.spacing(-1),
	},
	actions: {
		justifyContent: "center",
	},
	closeIcon: {
		position: "absolute",
		top: 10,
		right: 10,
		"&:hover": {
			cursor: "pointer",
			opacity: "80%",
		},
	},
	animatedIcon: {
		margin: theme.spacing(2, 0, 0, 0),
	},
	loader: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		left: 0,
		right: 0,
		bottom: 0,
		width: "100%",
		height: "100%",
		background: "rgba(244, 246, 248, 0.8)",
		zIndex: 9999,
	},
}));

const StyledModal = ({
	className,
	open,
	title,
	children,
	onClose,
	onConfirm,
	onConfirmText,
	options = [],
	...rest
}) => {
	const classes = useStyles();
	const noButtons = options.includes("noButtons");

	const onContinue = () => {
		onConfirm();
		onClose();
	};

	return (
		<Modal open={open ? true : false} onClose={onClose}>
			<Card {...rest} className={clsx(classes.root, className)}>
				<CloseIcon className={classes.closeIcon} onClick={onClose} />
				<CardContent>
					{title && (
						<Typography variant="h4" align="center">
							{title}
						</Typography>
					)}

					<Typography
						className={classes.body}
						variant="body1"
						align="center"
					>
						{children}
					</Typography>
				</CardContent>

				{!noButtons && (
					<CardActions className={classes.actions}>
						<FormSubmitButton onClick={onContinue}>
							{onConfirmText ? onConfirmText : "Continue Save"}
						</FormSubmitButton>
						<FormButton onClick={onClose}>Cancel</FormButton>
					</CardActions>
				)}
			</Card>
		</Modal>
	);
};

StyledModal.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	open: PropTypes.bool,
	title: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func,
	onConfirmText: PropTypes.string,
	options: PropTypes.array,
	flag: PropTypes.string,
};

export default StyledModal;
