import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const HealthSafetyHome = ({ role }) => {
	const classes = useStyles();

	return <div className={classes.root}></div>;
};

HealthSafetyHome.propTypes = {
	role: PropTypes.string.isRequired,
};

export default HealthSafetyHome;
