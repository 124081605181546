import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Grid, Button, colors } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import File from "./file";
import { isUnsupportedFileExtension, isNotNullOrEmpty } from "utils";
// import imageCompression from 'browser-image-compression'
// import { resizeImage } from './compress-image'
// import { createResizedImage, compressImage } from './resizer'
// import { writeImage } from 'frame/fileSystem'
// import { DBService, getTableName } from 'frame/indexedDBService'
import { PopupSnackbar, Loader } from "frame/components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import {
	onUploadCompressionRequested,
	onUploadLoadAttachments,
	onUploadRemove,
	onUploadRemoveItem,
} from "features/forms/actions";
import uuid from "uuid";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	uploadButton: {
		margin: theme.spacing(2, 0),
	},
	clearButton: {
		display: "flex",
		color: theme.palette.white,
		backgroundColor: colors.red[600],
		"&:hover": {
			backgroundColor: colors.red[900],
		},
	},
	uploadArea: {
		textAlign: "left",
		borderRadius: "20px",
		border: "3px dashed",
		color: "#CEE0EB",
		borderColor: "#CEE0EB",
		padding: "0px 30px 30px 30px",
		margin: "10px",
		[theme.breakpoints.down("xs")]: {
			padding: "30px 30px 30px 30px",
		},
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	uploaderArea: {
		marginTop: theme.spacing(-1),
	},
	uploadLabel: {
		color: "#1c2b4a",
		font: "400 13.3333px Arial",
		letterSpacing: "normal",
		"&:hover": {
			cursor: "pointer",
		},
	},
	browseLink: {
		color: "#6688E5",
	},
	uploadFab: {
		float: "right",
		position: "relative",
		width: 40,
		height: 40,
		background: theme.palette.highlight,
		top: -25,
		right: -10,
		"@media (min-width: 960px)": {
			bottom: 20,
		},
		[theme.breakpoints.down("xs")]: {
			marginRight: theme.spacing(2),
			marginTop: theme.spacing(-3),
		},
		"&:hover": {
			background: "#c1c72c",
		},
	},
	fileinfo: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	error: {
		color: theme.palette.error.main,
	},
	errorMessageArea: {
		display: "absolute",
		textAlign: "right",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(-5),
	},
	previewArea: {
		marginTop: theme.spacing(-3),
		marginBottom: theme.spacing(2),
	},
	date: {
		paddingTop: "10px",
		color: "#000000",
		[theme.breakpoints.down("xs")]: {
			paddingTop: 0,
			paddingBottom: 10,
		},
	},
}));

const Upload = ({
	values,
	onChange,
	showDate,
	disabled,
	ignoreDataUrl,
	onCompressing,
}) => {
	const theme = useTheme();
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

	const dispatch = useDispatch();
	const classes = useStyles();
	const fileUpload = useRef(null);
	const [error, setError] = useState("");

	const fileList = useSelector(
		(state) => state.uploadFormReducer.compressedFiles
	);
	const isCompressing = useSelector(
		(state) => state.uploadFormReducer.isCompressing
	);
	const compressionError = useSelector(
		(state) => state.uploadFormReducer.error
	);

	useEffect(() => {
		values.attachments &&
			dispatch(onUploadLoadAttachments(values.attachments));

		return function cleanup() {
			// clears the file list on component unmount
			dispatch(onUploadRemove());
		};
		// eslint-disable-next-line
	}, []);

	const encodeImageFileAsURL = (event) => {
		const files = Array.from(event.target.files);
		let valid = true;
		const MAX_FILE_NUMBER = 5;

		if (files.length + fileList.length > MAX_FILE_NUMBER) {
			setError(
				`You can only select ${MAX_FILE_NUMBER} files to upload at time. Please try again.`
			);
			valid = false;
		}

		if (valid) {
			files.forEach((file) => {
				let fileName = file.name;
				const lastDot = file.name.lastIndexOf(".");
				let fileExtension = fileName
					.substring(lastDot + 1)
					.toLowerCase();

				if (isUnsupportedFileExtension(fileExtension)) {
					setError(`${fileExtension} files are not allowed.`);
					valid = false;
				}

				const MAX_SIZE = 50 * 1024 * 1024; // 50 MB
				if (file.size >= MAX_SIZE) {
					setError(
						"Upload size exceeded (Limit: 50 MB). Please try again with smaller file."
					);
					valid = false;
				}
			});
		}

		if (valid) {
			files.forEach((file) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);

				reader.onloadend = () => {
					const lastDot = file.name.lastIndexOf(".");
					const fileExtension = file.name
						.substring(lastDot + 1)
						.toLowerCase();

					file.id = uuid.v4();
					file.fileName = file.name;
					file.fileSize = file.size;
					file.fileExtension = fileExtension;
					file.dataURL = reader.result; // for document files
					file.createdAt = moment().format("D MMM YYYY h:mm A");

					dispatch(
						onUploadCompressionRequested({ file, ignoreDataUrl })
					);
				};
			});
		}

		fileUpload.current.value = null;
	};

	const clearFiles = () => {
		// TODO: clean up indexDB on load, check & delete files that are not used in list
		// atm it never gets deleted unless user clears cache
		// for(let i in base64List) {
		//   db.delete(getTableName(), base64List[i].id)
		// }

		dispatch(onUploadRemove());
	};

	useEffect(() => {
		setTimeout(() => {
			setError("");
		}, 3000);
		// eslint-disable-next-line
	}, [error]);

	useEffect(() => {
		setError(compressionError);
	}, [compressionError]);

	useEffect(() => {
		if (typeof onCompressing === "function")
			onCompressing(isCompressing !== 0);
		// eslint-disable-next-line
	}, [isCompressing]);

	const onDelete = (file) => {
		dispatch(onUploadRemoveItem(file));
	};

	useEffect(() => {
		onChange({ attachments: fileList });
		// eslint-disable-next-line
	}, [fileList]);

	return (
		<div className={classes.uploadArea}>
			<Fab
				color="primary"
				aria-label="add"
				className={classes.uploadFab}
				onClick={() => fileUpload.current.click()}
			>
				<AddIcon />
			</Fab>
			<Grid container>
				<Grid item xs={12} className={classes.uploaderArea}>
					{isCompressing !== 0 && <Loader />}
					<input
						id="fileUpload"
						disabled={disabled}
						type="file"
						accept="*"
						onChange={encodeImageFileAsURL}
						ref={fileUpload}
						style={{ display: "none" }}
						multiple
					/>
					{!fileList.length > 0 && (
						<label
							htmlFor="fileUpload"
							className={classes.uploadLabel}
						>
							Drag files here or{" "}
							<span className={classes.browseLink}>browse</span>
						</label>
					)}
				</Grid>

				<Grid item xs={12} className={classes.previewArea}>
					{isNotNullOrEmpty(fileList) &&
						fileList.map((file, index) => (
							<Grid container key={`file-${index}`}>
								<Grid item sm={9} xs={12}>
									<File
										file={file}
										onDelete={() => onDelete(file)}
									/>
								</Grid>
								<Grid
									item
									sm={3}
									xs={12}
									className={classes.date}
								>
									{file.createdAt}
								</Grid>
							</Grid>
						))}

					{!mobileView && error && (
						<div className={classes.errorMessageArea}>
							<span className={classes.error}>{error}</span>
						</div>
					)}

					{mobileView && error && (
						<PopupSnackbar message={error} coloured error />
					)}
				</Grid>

				<Grid item xs={12}>
					{fileList.length > 0 && (
						<Button
							className={classes.clearButton}
							variant="contained"
							size="small"
							onClick={clearFiles}
						>
							Clear
						</Button>
					)}
				</Grid>
			</Grid>
		</div>
	);
};

Upload.propTypes = {
	values: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	showDate: PropTypes.bool,
	ignoreDataUrl: PropTypes.bool,
	onCompressing: PropTypes.func,
};

export default Upload;
