import { ofType } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { getBaseUrl, tokenExists, authHeaders } from "frame";
import fromTypes from "../actions/types";
import {
	onCancelSubscriptionFailed,
	onCancelSubscriptionSuccess,
	subscriptionFetch,
} from "../actions";

export const cancelSubscriptionEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.SUBSCRIPTION_CANCEL_SUBSCRIPTION),
		filter(() => tokenExists()),
		mergeMap(({ payload }) =>
			ajax
				.delete(
					`${getBaseUrl()}/api/cancel-subscription/${payload}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							onCancelSubscriptionSuccess(ajaxResponse.response),
							subscriptionFetch()
						)
					),
					catchError((error) =>
						of(onCancelSubscriptionFailed(error.response))
					)
				)
		)
	);
