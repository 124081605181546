import uuid from "uuid";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError, flatMap } from "rxjs/operators";
import { onJobSafetyAnalysisListRequest } from "features/health-and-safety";
import { HEALTH_SAFETY_JOB_SAFETY_ANALYSIS_PATH } from "features/health-and-safety";
import { ADD_JSA_FORM_SUBMIT } from "../actions/types";
import {
	DBService,
	JOB_SAFETY_ANALYSIS_TABLE,
	replaceDataURLs,
	authHeaders,
	getOrganisationBaseUrl,
} from "frame";
import * as R from "ramda";
import ReactGA from "react-ga";
import { getCurrentTimestamp, isProd } from "utils";
import {
	onAddTasksReset,
	onAddTasksSaveFailed,
	onAddTasksSaveSuccess,
} from "../actions";

const fetchAttachments = () => async () => {
	const db = new DBService();
	var files = await db.getAll(JOB_SAFETY_ANALYSIS_TABLE);
	return files;
};

const addJobSafetyAnalysisEffect = (action$, state$) =>
	action$.pipe(
		ofType(ADD_JSA_FORM_SUBMIT),
		flatMap(fetchAttachments()),
		mergeMap((result) => {
			let attachments = R.clone(
				state$.value.addJobSafetyAnalysisFormReducer.attachments
			);
			attachments = replaceDataURLs(attachments, result);

			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/job-safety-analysis/createorupdate`,
					{
						id:
							state$.value.addJobSafetyAnalysisFormReducer.id ||
							uuid.v4(),
						name: state$.value.addJobSafetyAnalysisFormReducer.name,
						createdAt:
							state$.value.addJobSafetyAnalysisFormReducer
								.createdAt,
						tasks: state$.value.addJobSafetyAnalysisFormReducer
							.tasks,
						recipients:
							state$.value.addJobSafetyAnalysisFormReducer
								.recipients,
						recipientsOther:
							state$.value.addJobSafetyAnalysisFormReducer
								.recipientsOther,
						sendEmail:
							state$.value.addJobSafetyAnalysisFormReducer
								.sendToRecipientsOnSave,
						attachments: attachments,
						latitude: state$.value.geolocation.latitude,
						longitude: state$.value.geolocation.longitude,
						timestamp: getCurrentTimestamp(),
						organisationId:
							state$.value.organisations.currentOrganisation,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						isProd &&
							ReactGA.event({
								category: "Tasks",
								action: "Save Clicked",
								label: "Add Job Safety Analysis",
							});
						return of(
							onJobSafetyAnalysisListRequest(),
							onAddTasksSaveSuccess(),
							onAddTasksReset(),
							push(HEALTH_SAFETY_JOB_SAFETY_ANALYSIS_PATH)
						);
					}),
					catchError((error) =>
						of(
							onAddTasksSaveFailed(
								(error.response && error.response.error) ||
									error.message
							)
						)
					)
				);
		})
	);

export default addJobSafetyAnalysisEffect;
