import { combineEpics } from "redux-observable";
import {
	organisationListEffect,
	organisationRefetchListEffect,
	triggerFetchOrganisationEffect,
} from "./organisation-list-effect";
import { organisationEditEffect } from "./organisation-edit-effect";
import { organisationDeleteEffect } from "./organisation-remove-effect";
import { organisationUpdatePreferenceEffect } from "./organisation-update-preference-effect";
import { organisationCleanupEffect } from "./organisation-cleanup-effect";

const organisationEffects = combineEpics(
	organisationListEffect,
	organisationRefetchListEffect,
	triggerFetchOrganisationEffect,
	organisationEditEffect,
	organisationDeleteEffect,
	organisationUpdatePreferenceEffect,
	organisationCleanupEffect
);

export default organisationEffects;
