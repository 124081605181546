import { combineEpics } from "redux-observable";
import customerListEffect, {
	customerPagedListEffect,
} from "./customer-list-effect";
import customerAddEffect from "./customer-add-effect";
import customerRemoveEffect from "./customer-remove-effect";

const customersEffects = combineEpics(
	customerListEffect,
	customerPagedListEffect,
	customerAddEffect,
	customerRemoveEffect
);

export default customersEffects;
