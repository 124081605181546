import * as R from "ramda";
/******************************************************************************************** */
/* Pagination helper functions                                                                */
/******************************************************************************************** */

export const defaultPaginationState = {
	flag: null,
	pageNumber: 1,
	pageSize: 1,
	totalNumberOfPages: 1,
	totalNumberOfRecords: 0,
	nextPageUrl: "/paged?page=1&pageSize=1&orderBy=id&ascending=True",
	results: [],
	listData: [],
};

// get results from pagination object
export const getPageResults = (pagination) =>
	(pagination && pagination.results && pagination.results) || [];

// for updating paginaion state after create/edit, used in reducers on add/edit Succeeded Action
export const updatePaginationState = (paginationState, newItem, isNew) => {
	const paginationSize = 5;

	if (isNew) {
		// after create

		if (paginationState.results.length === paginationSize) {
			// increase pagination number by 1 if already at page size
			paginationState.totalNumberOfPages++;
		}

		if (paginationState.results.length >= paginationSize) {
			paginationState.results.pop(); // remove last item if exceed paginated number
		}
		paginationState.results.unshift(newItem);
	} else {
		// after edit
		const index = R.findIndex(R.propEq("id", newItem.id))(
			paginationState.results
		);
		paginationState.results[index] = newItem;
	}

	return paginationState;
};
