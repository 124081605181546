import Types from "./types";

export const sendSucceeded = (queueId, entityId) => ({
	type: Types.QUEUE_SEND_SUCCEEDED,
	payload: {
		queueId,
		entityId,
	},
});

export const sendFailed = (id) => ({
	type: Types.QUEUE_SEND_FAILED,
	payload: id,
});

export const sendRequested = () => ({
	type: Types.QUEUE_SEND_REQUESTED,
});

export const addQueueItem = (item) => ({
	type: Types.QUEUE_ITEM_ADDED,
	payload: item,
});

export const checkNetworkConnection = () => ({
	type: Types.CHECK_NETWORK_CONNECTION,
});

export const networkChanged = (online) => ({
	type: Types.QUEUE_NETWORK_CHANGED,
	payload: online,
});
