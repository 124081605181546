import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { ScrollToTopOnMount } from "frame/components";
import { SiteForm } from "features/sites";
import { useRouter, isNotNullOrEmpty } from "utils";
import SiteListing from "./site-listing";

const useStyles = makeStyles((theme) => ({
	root: {},
	container: {
		margin: theme.spacing(4, 3),
	},
	content: {
		position: "relative",
		paddingBottom: theme.spacing(6),
		backgroundColor: theme.palette.white,
		overflowX: "hidden",
	},
	list: {
		marginTop: theme.spacing(2),
	},
}));

const Sites = ({ siteList, onEdit, onDelete }) => {
	const classes = useStyles();
	const { match } = useRouter();
	const { customerId } = match.params;

	const customerFilteredSiteList =
		siteList && siteList.filter((site) => site.customerId === customerId);

	const empty =
		customerFilteredSiteList && customerFilteredSiteList.length <= 0;

	return (
		<div className={classes.content}>
			<ScrollToTopOnMount />
			<SiteForm />
			<div className={classes.container}>
				<Typography variant="h2">Sites</Typography>
				<div className={classes.list}>
					{!empty &&
						isNotNullOrEmpty(customerFilteredSiteList) &&
						customerFilteredSiteList.map((node, index) => (
							<SiteListing
								key={index}
								site={node}
								onEdit={onEdit}
								onDelete={onDelete}
							/>
						))}
				</div>

				{empty && (
					<Typography variant="body1">
						There are currently no sites listed for this customer.
					</Typography>
				)}
			</div>
		</div>
	);
};

Sites.propTypes = {
	siteList: PropTypes.array.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default Sites;
