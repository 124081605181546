import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
} from "frame/components";
import { useSelector } from "react-redux";

import {
	strip,
	TitleSiteForm,
	PeopleForm,
	EditNotesForm,
	UploadFormCard,
	EmailRecipientsForm,
} from "features/forms";

import ReadOnlyForm from "./read-only-form";

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		flexDirection: "row",
		padding: theme.spacing(3, 3, 10, 3),
	},
	actions: {
		paddingBottom: theme.spacing(2),
	},
	body: {
		// marginTop: theme.spacing(-3),
	},
}));

const hydrate = ({
	id,
	title,
	siteId,
	createdAt,
	people,
	otherPeople,
	operationalIssues,
	generalIssuesDiscussed,
	attachments,
	recipients,
	recipientsOther,
	organisationId,
}) => ({
	id,
	title,
	siteId,
	createdAt,
	people,
	otherPeople,
	notes: {
		operationalIssues,
		generalIssuesDiscussed,
	},
	recipients,
	recipientsOther,
	attachments,
	currentOrganisation: organisationId,
});

const Form = ({ id, values, onClose, onChange, onReset, onSave, readOnly }) => {
	const classes = useStyles();
	const safetyMinutesList = useSelector(
		({ healthSafety }) => healthSafety.safetyMinutes?.results
	);

	const [loaded, setLoaded] = useState(false);
	const open = values && values.id;

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchData() {
			setLoaded(true);
			const safetyMinutes =
				safetyMinutesList &&
				safetyMinutesList.filter((s) => s.id === id)[0];

			if (safetyMinutes) {
				onChange(hydrate(safetyMinutes));
			}
		}
		if (id) {
			setLoaded(true);
			fetchData();
		} // eslint-disable-next-line
	}, [id, loaded, onChange]);

	useEffect(() => {
		values.save.success && onClose();
	}, [values.save.success, onClose]);

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
	};

	const onCancel = () => {
		onReset();
		onClose();
	};

	if (!open) {
		return null;
	}

	return (
		<Dialog
			open={open ? true : false}
			aria-labelledby="Safety Minutes"
			// breadcrumbs="Safety Minutes"
			title={readOnly ? "View Safety Minutes" : "Edit Safety Minutes"}
			onClose={onCancel}
		>
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				<div className={classes.body}>
					{readOnly ? (
						<ReadOnlyForm values={values} />
					) : (
						<>
							<TitleSiteForm
								disableDate
								values={values}
								onChange={onChange}
							/>
							<PeopleForm
								defaultStaffRole="Staff"
								values={values}
								onChange={onChange}
							/>
							<EditNotesForm
								values={values}
								onChange={onChange}
							/>
							<UploadFormCard
								title={"Edit Attachments"}
								values={values}
								onChange={onChange}
							/>
							<EmailRecipientsForm
								values={values}
								onChange={onChange}
							/>
						</>
					)}
				</div>
				<FormActions className={classes.actions}>
					{!readOnly && (
						<FormSubmitButton
							disabled={values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
					)}
					<FormButton
						disabled={values.save.saving}
						onClick={onCancel}
					>
						{readOnly ? "Close" : "Cancel"}
					</FormButton>
				</FormActions>
			</ValidatorForm>
		</Dialog>
	);
};

Form.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
};

export default Form;
