import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography, Divider } from "@material-ui/core";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	AddButton,
} from "frame/components";
import { sortByPropertyDate, useRouter } from "utils";
import SiteHazardousSubstanceCard from "../cards/hazardous-substance-site-card";
import {
	AddHazardousSubstanceToSiteForm,
	EditHazardousSubstanceToSiteForm,
	EditHazardousSubstanceForm,
} from "features/health-safety-forms";
import { isNotNullOrEmpty, isDefaultGuid } from "utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.white,
		position: "relative",
	},
	body: {
		marginTop: theme.spacing(3),
	},
	heading: {
		marginTop: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0),
		},
	},
	addButton: {
		position: "absolute",
		top: 15,
		right: 0,
		[theme.breakpoints.down("xs")]: {
			position: "relative",
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(3),
			top: 0,
		},
	},
	empty: {
		marginTop: theme.spacing(3),
	},
	divider: {
		marginTop: theme.spacing(2),
		height: 1,
	},
}));

const HazardousSubstanceSiteList = ({
	values,
	onAdd,
	onEdit,
	onDelete,
	role,
}) => {
	const classes = useStyles();
	const { match } = useRouter();
	const { siteId } = match.params;

	const [editId, setEditId] = useState(null);
	const [siteSubstances, setSiteSubstances] = useState([]);

	const [currentSite, setCurrentSite] = useState(null);
	const [viewId, setViewId] = useState();

	const siteList = useSelector(({ sites }) => sites.pagination?.results);

	useEffect(() => {
		// fetches site by id on load
		async function fetchSiteData() {
			const site = siteList && siteList.filter((s) => s.id === siteId)[0];
			setSiteSubstances(site ? site.substances : []);
			setCurrentSite(site ? site : null);
		}

		if (siteId && !isDefaultGuid(siteId)) {
			fetchSiteData();
		} // eslint-disable-next-line
	}, [siteId, setSiteSubstances, values.substances]);

	const isArchived = (
		item // function to check if substance is archived
	) => String(item.status).toLowerCase() === "archived";

	const archivedSiteSubstances = [...siteSubstances]
		.filter((node) => isArchived(node))
		.sort(sortByPropertyDate("createdAt"));

	const activeSiteSubstances = [...siteSubstances]
		.filter((node) => !isArchived(node))
		.sort(sortByPropertyDate("createdAt"));

	const empty = siteSubstances && siteSubstances.length <= 0;
	const hasArchivedSiteSubstances = archivedSiteSubstances.length > 0;
	const hasActiveSiteSubstances = activeSiteSubstances.length > 0;

	const onEditHandler = (substanceId) => () => {
		onEdit(); // opens the editSiteHazard modal
		setEditId(substanceId);
	};

	const onView = (substanceId) => () => setViewId(substanceId);

	const onCancelView = () => setViewId(null);

	const renderSiteSubstances = (list) =>
		list &&
		list.map((data, index) => (
			<SiteHazardousSubstanceCard
				key={index}
				currentSite={currentSite}
				onView={onView(data.hazardousSubstanceId)}
				onEdit={onEditHandler(data.hazardousSubstanceId)}
				onDelete={onDelete}
				hazardousSubstance={data}
				role={role}
			/>
		));

	// use to compare which entries are preExisting to know which to hide on "add substance to site" form load
	const preExistingSiteSubstances = isNotNullOrEmpty(siteSubstances)
		? siteSubstances.map((s) => s.hazardousSubstanceId)
		: [];

	return (
		<Page className={classes.root} title="Hazardous Substances">
			<ScrollToTopOnMount />
			<PageContent pad>
				<Typography variant="h2" className={classes.heading}>
					Hazardous Substances
				</Typography>
				{/* <pre>
          {JSON.stringify({            
            substances: values.substances.length,
            siteSubstances: siteSubstances.length
          }, null, 2)}
        </pre> */}

				<div className={classes.addButton}>
					<AddButton
						// staffUserHide
						addTabTitle="Add hazardous substance to site"
						onAdd={onAdd}
					/>
				</div>
				{empty && hasActiveSiteSubstances && (
					<Typography className={classes.empty} variant="body1">
						There are currently no Hazardous Substances listed.
					</Typography>
				)}
				<div className={classes.body}>
					{!empty && hasActiveSiteSubstances && (
						<>
							<div className={classes.header}>
								<Typography variant="h4">Active</Typography>
								<Divider className={classes.divider} />
							</div>
							{renderSiteSubstances(activeSiteSubstances)}
						</>
					)}

					{!empty && hasArchivedSiteSubstances && (
						<>
							<div className={classes.header}>
								<Typography variant="h4">Archived</Typography>
								<Divider className={classes.divider} />
							</div>
							{renderSiteSubstances(archivedSiteSubstances)}
						</>
					)}
				</div>
			</PageContent>

			<AddHazardousSubstanceToSiteForm
				siteId={siteId}
				preExistingSiteSubstances={preExistingSiteSubstances}
			/>

			{editId && (
				<EditHazardousSubstanceToSiteForm
					siteId={siteId}
					substanceId={editId}
				/>
			)}
			{viewId && (
				<EditHazardousSubstanceForm
					readOnly
					id={viewId}
					onClose={onCancelView}
				/>
			)}
		</Page>
	);
};

HazardousSubstanceSiteList.propTypes = {
	values: PropTypes.object.isRequired,
	role: PropTypes.string.isRequired,
	onAdd: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default HazardousSubstanceSiteList;
