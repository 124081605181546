import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
	isNotNullOrEmpty,
	sortByPropertyAlphabetically,
	isDefaultGuid,
} from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	label: {
		color: "#999999",
	},
	attendanceIcon: {
		color: theme.palette.success.main,
		paddingTop: "5px",
		position: "absolute",
		top: -5,
	},
}));

// used in view only Edit forms
/*
  - maps staff people and other people separately if otherPeople is provided
*/
const PeopleListing = ({
	people = [],
	otherPeople,
	title,
	hideRole,
	displayAttendanceConfirmed,
}) => {
	const classes = useStyles();

	const peopleList =
		isNotNullOrEmpty(people) &&
		people
			.filter((p) => !isDefaultGuid(p.id) && p.role)
			.sort(sortByPropertyAlphabetically("name"));

	const otherPeopleList =
		isNotNullOrEmpty(people) &&
		people
			.filter((p) => isDefaultGuid(p.id))
			.map((p) => p.name)
			.sort();

	return (
		<>
			<Typography variant="body2" className={classes.label}>
				{title || "People"}
			</Typography>

			{/* Staff People */}
			{isNotNullOrEmpty(peopleList) &&
				peopleList.map(({ name, role, attendanceConfirmed }, key) => (
					<div className={classes.root} key={`${name}-${key}`}>
						<Typography variant="h5">
							{name}

							{!hideRole && role && ` (${role}) `}

							{displayAttendanceConfirmed &&
								attendanceConfirmed && (
									<CheckCircleIcon
										className={classes.attendanceIcon}
									/>
								)}
						</Typography>
					</div>
				))}

			{/* Other People */}
			{!otherPeople &&
				isNotNullOrEmpty(otherPeopleList) &&
				otherPeopleList.map((person, key) => (
					<div className={classes.root} key={`${person}-${key}`}>
						<Typography variant="h5">{person}</Typography>
					</div>
				))}

			{isNotNullOrEmpty(otherPeople) &&
				otherPeople.map((person, key) => (
					<div className={classes.root} key={`${person}-${key}`}>
						<Typography variant="h5">{person}</Typography>
					</div>
				))}
			{people && people.length === 0 && "-"}
		</>
	);
};

PeopleListing.propTypes = {
	people: PropTypes.array.isRequired,
	title: PropTypes.string,
	hideRole: PropTypes.bool,
	displayAttendanceConfirmed: PropTypes.bool,
};

export default PeopleListing;
