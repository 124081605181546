export default {
	id: "",
	customerName: "",
	contactPerson: "",
	emailAddress: "",
	emailAddressErrorMessage: "",
	status: "Active",
	consultant: "",
	isValid: false,
};
