import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Modal, Card, CardContent, Typography, Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
} from "frame/components";
import { CardElement, injectStripe } from "react-stripe-elements";
import { getWebsiteBaseUrl } from "frame";
import {
	subscriptionAddPaymentSave,
	subscriptionUpdatePaymentSave,
	onAddPaymentClose,
	onStripeError,
	subscriptionModalSubmit,
	subscriptionUpdatePaymentSaveFail
} from "../../actions";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		marginTop: theme.spacing(5),
		justifyContent: "flex-end",
	},
	stripeWrapper: {
		border: "solid 1px #D9D9D9",
		borderRadius: "5px",
		padding: "20px 10px 20px 10px",
		marginTop: "5px",
	},
	stripeWrapperRed: {
		border: "solid 1px",
		borderColor: theme.palette.error.main,
		borderRadius: "5px",
		padding: "20px 10px 20px 10px",
		marginTop: "5px",
	},
	stripeErrorLabel: {
		margin: "5px 20px",
		color: theme.palette.error.main,
	},
}));

const AddPaymemntForm = ({
	numberOfStaff,
	onSubmit,
	onSave,
	onClose,
	onUpdate,
	isAddPaymentModalShowing,
	onStripeResponseError,
	onSaveFail,
	stripe,
	isUpdate,
	currentOrganisationGUID,
	setSuccessMessage,
	setErrorMessage,
	organisationName,
	submitStatus
}) => {
	const classes = useStyles();
	const [error, setError] = useState();

	if (!isAddPaymentModalShowing) {
		return null;
	}

	const onSubmitEffect = (event) => {
		event.preventDefault();

		if (error) {
			onSaveFail(error);
			return;
		}

		onSubmit();
		// todo: remove promise, convert to effect
		stripe
			.createToken({ name: organisationName })
			.then((res) => {
				if (res.error) {
					onStripeResponseError(
						res.error.message ||
							"Invalid credit card. Please try another card."
					);
					setError(
						res.error.message ||
							"Invalid credit card. Please try another card."
					);
				} else if (isUpdate) {
					onUpdate( { token: res.token });
				} else {
					onSave(res);
				}
			})
			.catch(() => {
				onSaveFail();
				onStripeResponseError("Invalid credit card. Please try another card.");
				setError("Invalid credit card. Please try another card.");
			});
	};

	return (
		<Modal open={true} onClose={onClose}>
			<Card className={classes.root}>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmitEffect}
				>
					<CardContent>
						<Typography align="center" gutterBottom variant="h3">
							{isUpdate
								? "Update Credit Card"
								: "Add Payment Method"}
						</Typography>
						<Grid
							className={classes.container}
							container
							spacing={3}
						>
							<Grid item xs={12}>
								<div>
									<span>
										I agree to subscribe for the number of active users at the cost described at{" "}
									</span>
									<a
										href={`${getWebsiteBaseUrl()}/try-buy/`}
										title="Try Buy"
										target="_blank"
										rel="noopener noreferrer"
									>
										{getWebsiteBaseUrl()}/try-buy/.{" "}
									</a>
									<span>
										I agree to the terms and conditions{" "}
									</span>
									<a
										href={`${getWebsiteBaseUrl()}/terms-of-use/`}
										title="Terms of Use"
										target="_blank"
										rel="noopener noreferrer"
									>
										{getWebsiteBaseUrl()}/terms-of-use/
									</a>
									.
								</div>

								<div
									className={
										error
											? classes.stripeWrapperRed
											: classes.stripeWrapper
									}
								>
									<CardElement
										hidePostalCode={true}
										onChange={(e) => {
											if (e.error && e.error.message) {
												setError(e.error.message);
											}
											else {
												setError(null);
											}
										}}
									/>
								</div>

								{error && (
									<span className={classes.stripeErrorLabel}>
										{error}
									</span>
								)}
								{submitStatus === "error" ? (
									<span className={classes.stripeErrorLabel}>
										There was a problem updating your credit card. Please contact support for more information.
									</span>
								) : null}
							</Grid>
						</Grid>

						<FormActions className={classes.actions}>
							<FormSubmitButton
								loading={ submitStatus === "processing"}
								disabled={ submitStatus === "processing"}
							>
								Save
							</FormSubmitButton>
							<FormButton onClick={onClose}>Cancel</FormButton>
						</FormActions>
					</CardContent>
				</ValidatorForm>
			</Card>
		</Modal>
	);
};

AddPaymemntForm.propTypes = {
	numberOfStaff: PropTypes.number.isRequired,
	stripe: PropTypes.object,
	onSubmit: PropTypes.func,
	onSave: PropTypes.func,
	onClose: PropTypes.func,
	onUpdate: PropTypes.func,
	onStripeResponseError: PropTypes.func,
	onSaveFail: PropTypes.func,
	isAddPaymentModalShowing: PropTypes.bool,
	isUpdate: PropTypes.bool,
	setSuccessMessage: PropTypes.func,
	setErrorMessage: PropTypes.func,
	organisationName: PropTypes.string,
	currentOrganisationGUID: PropTypes.string,
	submitStatus: PropTypes.string
};

const mapStateToProps = ({ subscription, staff, organisations, profile }) => ({
	numberOfStaff: staff.pagination.results.length,
	isAddPaymentModalShowing: subscription.isAddPaymentModalShowing,
	currentOrganisationGUID: organisations.currentOrganisation,
	organisationName: profile.organisation,
	submitStatus: subscription.flags.updateStatus
});

const mapDispatchToProps = {
	onStripeResponseError: onStripeError,
	onSubmit: subscriptionModalSubmit,
	onSave: subscriptionAddPaymentSave,
	onClose: onAddPaymentClose,
	onUpdate: subscriptionUpdatePaymentSave,
	onSaveFail: subscriptionUpdatePaymentSaveFail
};

export default injectStripe(
	connect(mapStateToProps, mapDispatchToProps)(AddPaymemntForm)
);
