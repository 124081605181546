import React, { useContext } from "react";
import PropTypes from "prop-types";
import { __RouterContext } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import BackIcon from "@material-ui/icons/KeyboardBackspace";

const useRouter = () => useContext(__RouterContext);

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		marginBottom: theme.spacing(1),
		color: theme.palette.text.primary,
		background: "none",
		border: "none",
		outline: "none",
		cursor: "pointer",
		"&:hover": {
			background: "none",
			border: "none",
			outline: "none",
		},
		"&:focus": {
			background: "none",
			border: "none",
			outline: "none",
		},
	},
	icon: {
		marginRight: theme.spacing(1),
	},
}));

const BreadcrumbBack = ({ backActionPath }) => {
	const classes = useStyles();
	const { history } = useRouter();

	if (!history.length) {
		return null;
	}

	const redirect = () =>
		backActionPath ? history.push(backActionPath) : history.goBack;

	return (
		<button className={classes.root} onClick={redirect}>
			<BackIcon className={classes.icon} /> Go Back
		</button>
	);
};

BreadcrumbBack.propTypes = {
	backActionPath: PropTypes.string,
};

export default BreadcrumbBack;
