import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";
import { onJobSafetyAnalysisListRequest } from "features/health-and-safety";

const mapStateToProps = ({ addJobSafetyAnalysisFormReducer }) => ({
	values: addJobSafetyAnalysisFormReducer,
	apiError: addJobSafetyAnalysisFormReducer.save.error,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddTasksValueChange,
	onReset: fromActions.onAddTasksReset,
	onSave: fromActions.onAddTasksSave,
	onGetJobSafetyAnalysisList: onJobSafetyAnalysisListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
