import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { isNotNullOrEmpty } from "utils";
import clip from "text-clipper";
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
} from "@material-ui/core";
import StaffRoleSelectTableSelectRole from "./staff-role-select-table-select-role";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		position: "relative",
	},
	listItem: {
		borderBottom: "1px solid #eeeeee",
	},
	row: { cursor: "pointer" },
	rowLabelContainer: {
		display: "flex",
		width: "90%",
		[theme.breakpoints.down("sm")]: {
			width: "75%",
		},
	},
	rowLabel: {
		marginTop: "10px",
		marginLeft: "10px",
	},
	rowAction: {
		maxWidth: "100px",
	},
}));

const StaffRoleSelectTable = ({
	people,
	onChange,
	customOptions,
	showInvalidPeopleError,
}) => {
	const classes = useStyles();
	const [checked, setChecked] = useState([]);

	useEffect(() => {
		if (people) {
			const selectedPeople = people.filter((c) => c.checked);
			setChecked(
				selectedPeople ? selectedPeople.map((c) => c.value) : []
			);
		}
	}, [people]);

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		let updatedPeople = people;
		let existngPeopleIndex = updatedPeople.findIndex(
			(c) => c.value === value
		);

		if (currentIndex === -1) {
			newChecked.push(value);
			updatedPeople[existngPeopleIndex].checked = true;
		} else {
			newChecked.splice(currentIndex, 1);
			updatedPeople[existngPeopleIndex].checked = false;
		}
		onChange(updatedPeople);
		setChecked(newChecked);
	};

	const onSelectRole = (index) => (role) => {
		let updatedPeople = people;
		updatedPeople[index].role = role;
		onChange(updatedPeople);
	};

	return (
		<List className={classes.root}>
			{isNotNullOrEmpty(people) &&
				people.map(({ label, value, role }, index) => (
					<ListItem
						key={`${label}-${index}`}
						button
						className={classes.listItem}
					>
						<div
							className={classes.rowLabelContainer}
							onClick={handleToggle(value)}
						>
							<ListItemIcon>
								<Checkbox
									edge="start"
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{ "aria-labelledby": label }}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.rowLabel}
								id={label}
								primary={clip(label, 30)}
							/>
						</div>
						<div className={classes.rowAction}>
							<StaffRoleSelectTableSelectRole
								showInvalidPeopleError={showInvalidPeopleError}
								onSelectRole={onSelectRole(index)}
								customOptions={customOptions}
								role={role}
							/>
						</div>
					</ListItem>
				))}
		</List>
	);
};

StaffRoleSelectTable.propTypes = {
	disabled: PropTypes.bool,
	people: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	customOptions: PropTypes.array,
	showInvalidPeopleError: PropTypes.bool,
};

export default StaffRoleSelectTable;
