import { combineEpics } from "redux-observable";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError } from "rxjs/operators";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import {
	timesheetAdminActionTypes,
	onAddTimesheetSaveFailed,
	onAddTimesheetSaveSuccess,
} from "./timesheet-admin-actions";
import { LIST_TIMESHEETS_ROUTE } from "./timesheet-routes";
import moment from "moment";
import { GENERIC_ERROR_MESSAGE } from "utils";

const addTimesheetEffect = (action$, state$) =>
	action$.pipe(
		ofType(timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_SUBMIT),
		mergeMap(() => {
			return ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/timesheet/create`,
					{
						siteId: state$.value.timesheetAdminFormReducer.siteId,
						staffId:
							state$.value.timesheetAdminFormReducer.timesheet
								.staffId,
						startDateTimeUTC: moment(
							state$.value.timesheetAdminFormReducer.timesheet
								.startDate +
								" " +
								state$.value.timesheetAdminFormReducer.timesheet
									.startTime,
							"YYYY-MM-DD HH:mm"
						).toISOString(),
						endDateTimeUTC: moment(
							state$.value.timesheetAdminFormReducer.timesheet
								.endDate +
								" " +
								state$.value.timesheetAdminFormReducer.timesheet
									.endTime,
							"YYYY-MM-DD HH:mm"
						).toISOString(),
						lunchTimeMinutes: parseInt(
							state$.value.timesheetAdminFormReducer.timesheet
								.lunchTime,
							10
						),
						notes: state$.value.timesheetAdminFormReducer.timesheet
							.notes,
						startLatitude:
							state$.value.timesheetAdminFormReducer.timesheet
								.startLatitude,
						startLongitude:
							state$.value.timesheetAdminFormReducer.timesheet
								.startLongitude,
						endLatitude:
							state$.value.timesheetAdminFormReducer.timesheet
								.endLatitude,
						endLongitude:
							state$.value.timesheetAdminFormReducer.timesheet
								.endLongitude,
						organisationTaskTypeId:
							state$.value.timesheetAdminFormReducer.timesheet
								.organisationTaskTypeId
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return of(
							onAddTimesheetSaveSuccess(),
							push(LIST_TIMESHEETS_ROUTE)
						);
					}),
					catchError((error) =>
						of(onAddTimesheetSaveFailed(GENERIC_ERROR_MESSAGE))
					)
				);
		})
	);

export const timesheetAdminEffects = combineEpics(addTimesheetEffect);
