import { defaultPaginationState } from "utils";

export const initialHealthSafetyState = {
	// pagination
	hazards: defaultPaginationState,
	incidents: defaultPaginationState,
	hazardousSubstances: defaultPaginationState,
	inductions: defaultPaginationState,
	safetyMinutes: defaultPaginationState,
	jobSafetyAnalysis: defaultPaginationState,
	safetyPlanChecklists: defaultPaginationState,
	staffTrainings: defaultPaginationState,
	staffTrainingTopics: defaultPaginationState,
};
