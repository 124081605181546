import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Page, PageContent, PageHeader } from "frame/components";
import MenuTabs from "./menu-tabs";
import NotFound from "./not-found";
import { ChildRoutes } from "./customer-routes";
import { CUSTOMERS_PATH } from "../actions/types";

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const Customer = ({ customer }) => {
	const classes = useStyles();
	const name = (customer && customer.customerName) || "Not Found";
	const path =
		customer && customer.id
			? `${CUSTOMERS_PATH}/${customer.id}`
			: CUSTOMERS_PATH;

	const breadcrumbs = [
		{
			label: `Customers`,
			value: CUSTOMERS_PATH,
		},
		{
			label: `${name}`,
			value: path,
		},
	];

	return (
		<Page className={classes.root} title="Customers">
			<PageHeader breadcrumbs={breadcrumbs} title={name} />
			{customer ? (
				<PageContent>
					<MenuTabs />
					<ChildRoutes />
				</PageContent>
			) : (
				<PageContent pad>
					<NotFound />
				</PageContent>
			)}
		</Page>
	);
};

Customer.propTypes = {
	customer: PropTypes.object,
};

export default Customer;
