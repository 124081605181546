import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Link, Typography, CircularProgress } from "@material-ui/core";
import * as fromAuth from "features/authorisation";
import { FormSubmitButton } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: theme.spacing(8),
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	loader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		marginTop: theme.spacing(3),
	},
}));

const ConfirmationForm = ({ token, responseMessage, onConfirmation }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [passwordLink, setPasswordLink] = useState(null);
	const [showSetPasswordLink, setShowSetPasswordLink] = useState(false);

	useEffect(() => {
		if (token) {
			const tokenArray = token.split("&");
			const tokenEmail = tokenArray[0].split("=")[1];

			let authToken = "";
			if (tokenArray[2]) {
				authToken = tokenArray[2].split("=")[1];
				setShowSetPasswordLink(true);
			} else {
				authToken = tokenArray[1].split("=")[1];
			}

			setPasswordLink(
				`/set-password?email=${encodeURIComponent(tokenEmail)}&token=${authToken}`
			);

			onConfirmation(token.replace(tokenEmail, encodeURIComponent(tokenEmail)));
			setLoading(false);
		}
	}, []);

	if (token === "") {
		return (
			<Typography variant="body1">
				Invalid confirmation password link, please try again or login{" "}
				<Link
					color="secondary"
					component={RouterLink}
					to={fromAuth.LOGIN_PATH}
					underline="always"
				>
					here
				</Link>
				.
			</Typography>
		);
	}

	return (
		<div className={classes.root}>
			{!loading && responseMessage ? (
				<Typography color="inherit" variant="subtitle1">
					{responseMessage}

					{!responseMessage.includes("Invalid") && (
						<div style={{ margin: "20px 0" }}>
							{showSetPasswordLink && passwordLink && (
								<FormSubmitButton
									data-cy={`set-password`}
									component={RouterLink}
									to={passwordLink}
								>
									Set Password
								</FormSubmitButton>
							)}
						</div>
					)}
				</Typography>
			) : (
				<div className={classes.loader}>
					<CircularProgress />
				</div>
			)}
		</div>
	);
};

ConfirmationForm.propTypes = {
	token: PropTypes.string.isRequired,
	responseMessage: PropTypes.string,
	onConfirmation: PropTypes.func.isRequired,
};

export default ConfirmationForm;
