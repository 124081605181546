import React from "react";
import LandingPageFrame from "./landing-page-frame";
import RegisterForm from "./forms/register-form";
import { Elements, StripeProvider } from "react-stripe-elements";
import { stripeHelper } from "utils/stripeHelper";

const Register = (props) => {
	const registrationDone = props.save.done && props.save.success;

	const apiKey = stripeHelper.getApiKey();

	return (
		<StripeProvider apiKey={apiKey}>
			<Elements>
				<LandingPageFrame
					title={registrationDone ? "Registration Successful" : "Register a New Organisation"}
					form={<RegisterForm {...props} />}
				/>
			</Elements>
		</StripeProvider>

	);
};

export default Register;
