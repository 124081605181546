export default {
	id: "",
	organisation: "",
	firstName: "",
	lastName: "",
	email: "",
	emailErrorMessage: "",
	phoneNumber: "",
	phoneNumberErrorMessage: "",
	password: "",
	emailInvitation: true,
	status: "Active",
	staffIdNumber: "",
	supervisor: "",
	notes: "",
	attachments: [],
	organisationRole: "Staff User",
	organisationStaffTypeId: "",
	organisationStaffTypesStaffType: "",
	batchCreateFlags: [],
};
