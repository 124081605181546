import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import Trainings from "./trainings-container";
import * as fromRoutes from "../actions/types";

// components
import StaffList from "./staff-list-container";
import Summary from "./summary-container";
import Staff from "./staff-container";
import { JobStaffList } from "features/jobs";

const StaffRoutes = ({ onGetStaffList, onSearchClear }) => {
	useEffect(() => {
		onSearchClear();
		onGetStaffList();
	}, [onGetStaffList, onSearchClear]);

	return (
		<Switch>
			<Route exact path={fromRoutes.STAFF_ROUTE} component={StaffList} />
			<Route path={fromRoutes.STAFF_MEMBER_TAB_ROUTE} component={Staff} />
		</Switch>
	);
};

StaffRoutes.propTypes = {
	onGetStaffList: PropTypes.func.isRequired,
	onSearchClear: PropTypes.func.isRequired,
};

export const ChildRoutes = () => (
	<Switch>
		<Route
			path={fromRoutes.STAFF_MEMBER_TRAINING_ROUTE}
			component={Trainings}
		/>
		<Route
			path={fromRoutes.STAFF_MEMBER_NOTES_ROUTE}
			component={JobStaffList}
		/>
		<Route path={fromRoutes.STAFF_MEMBER_ROUTE} component={Summary} />
	</Switch>
);

export default StaffRoutes;
