import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
	},
	list: {
		margin: theme.spacing(-1, -2),
	},
	success: {
		color: theme.palette.success.main,
	},
}));

const PasswordIndicator = ({
	passwordMatch,
	/*hasNumber,*/ meetsPasswordLength,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<ListItem className={classes.list}>
				<ListItemIcon>
					{passwordMatch ? (
						<CheckCircleIcon
							className={passwordMatch ? classes.success : ""}
						/>
					) : (
						<CancelIcon />
					)}
				</ListItemIcon>
				<ListItemText>Password Match</ListItemText>
			</ListItem>
			<ListItem className={classes.list}>
				<ListItemIcon>
					{meetsPasswordLength ? (
						<CheckCircleIcon
							className={
								meetsPasswordLength ? classes.success : ""
							}
						/>
					) : (
						<CancelIcon />
					)}
				</ListItemIcon>
				<ListItemText>At least 9 or more characters</ListItemText>
			</ListItem>
			{/* <ListItem className={classes.list}>
        <ListItemIcon>
          {hasNumber ? (
            <CheckCircleIcon className={hasNumber ? classes.success : ''} />
          ) : (
            <CancelIcon />
          )}
        </ListItemIcon>
        <ListItemText>At least one number</ListItemText>
      </ListItem> */}
		</div>
	);
};

PasswordIndicator.propTypes = {
	password: PropTypes.string,
	confirmPassword: PropTypes.string,
	isValid: PropTypes.bool,
	passwordMatch: PropTypes.bool,
	// hasNumber: PropTypes.bool,
	meetsPasswordLength: PropTypes.bool,
	onChangePassword: PropTypes.func.isRequired,
	onChangeConfirmPassword: PropTypes.func.isRequired,
	onResetPassword: PropTypes.func.isRequired,
	token: PropTypes.string.isRequired,
	save: PropTypes.exact({
		done: PropTypes.bool,
		error: PropTypes.string,
		saving: PropTypes.bool,
		success: PropTypes.bool,
	}),
	responseMessage: PropTypes.string,
};

export default PasswordIndicator;
