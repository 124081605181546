import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { getBaseUrl } from "frame";
import fromTypes from "../actions/types";

import { onSetPasswordFulfilled, onSetPasswordFailed } from "../actions";

const setPasswordEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.AUTHORISATION_SET_PASSWORD_REQUESTED),
		mergeMap(({ payload }) =>
			ajax
				.post(`${getBaseUrl()}/api/reset-password${payload}`, {
					password: state$.value.authorisation.setPassword.password,
					confirmPassword:
						state$.value.authorisation.setPassword.confirmPassword,
				})
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onSetPasswordFulfilled(ajaxResponse.response))
					),
					catchError((error) => onSetPasswordFailed(error))
				)
		)
	);

export default setPasswordEffect;
