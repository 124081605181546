import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	SearchBar,
	Pagination,
	PaginationHeader,
	PaginationLoader,
	PopupSnackbar,
	SendEmailModal,
} from "frame/components";
import * as fromHealthSafetyForm from "features/health-safety-forms";
import StaffTrainingCard from "../cards/staff-training-card";
import {
	AppendStaffTrainingForm,
	EditStaffTrainingForm,
	PageHeaderTabs,
} from "features/health-safety-forms";
import { getPageResults, useIsOnline } from "utils";
import { safetyWiseApi } from "utils/safetyWiseApi";

const useStyles = makeStyles((theme) => ({
	root: {},
	filter: { display: "flex" },
}));

const StaffTrainingList = ({
	role,
	staffTrainings,
	onDelete,
	onGetStaffTrainingList,
	currentOrganisationGUID,
}) => {
	const isOnline = useIsOnline();
	const classes = useStyles();
	const [showEditModal, setShowEditModal] = useState(false);
	const [showViewModal, setShowViewModal] = useState(false);
	const [showAppendModal, setShowAppendModal] = useState(false);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [isSendingEmail, setIsSendingEmail] = useState(false);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [siteId, setSiteId] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
	const [searchFilter, setSearchFilter] = useState("");

	const staffTrainingList = getPageResults(staffTrainings);
	const empty = staffTrainingList && staffTrainingList.length <= 0;

	const onEdit = (id) => () => {
		setSelectedItemId(id);
		setShowEditModal(true);
	};
	const onAppend = (id) => () => {
		setSelectedItemId(id);
		setShowAppendModal(true);
	};
	const onView = (id) => () => {
		setSelectedItemId(id);
		setShowViewModal(true);
	};
	const onEmail = (id, siteId) => () => {
		setSelectedItemId(id);
		setSiteId(siteId);
		setShowEmailModal(true);
	};
	const handleApiError = (error) => {
		if (error.response && error.response.status === "401") {
			setErrorMessage("You are not authorized to perform this action.");
		} else if (error.response && error.response.status === "404") {
			setErrorMessage("The record no longer exists.");
		} else {
			setErrorMessage(
				"Something went wrong, please refresh the page and try again."
			);
		}
	};

	const isFetching = staffTrainings.flag === "processing" && isOnline;
	const isDoneFetching = staffTrainings.flag === "done" || !isOnline;

	return (
		<Page className={classes.root} title="Staff Training">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				title="Staff Training Records"
				addTabPath={fromHealthSafetyForm.ADD_STAFF_TRAINING_PATH}
				backActionPath={fromHealthSafetyForm.TASK_ROUTE}
			/>
			<div className={classes.filter}>
				<SearchBar onSearch={setSearchFilter} />
			</div>

			<PageContent pad>
				<PaginationHeader pagination={staffTrainings} />

				{!empty &&
					isDoneFetching &&
					staffTrainingList.map((item, index) => (
						<StaffTrainingCard
							key={index}
							training={item}
							role={role}
							onAppend={onAppend(item.id)}
							onView={onView(item.id)}
							onEdit={onEdit(item.id)}
							onDelete={onDelete}
							onEmail={onEmail(item.id, item.siteId)}
						/>
					))}

				{isFetching && (
					<PaginationLoader height={90} pagination={staffTrainings} />
				)}

				{empty && isDoneFetching && (
					<Typography variant="body1">
						There are currently no staff training records listed.
					</Typography>
				)}

				<Pagination
					onGetList={onGetStaffTrainingList}
					pagination={staffTrainings}
					orderBy="createdAt"
					ascending={false}
					searchKey={searchFilter}
				/>
			</PageContent>

			{showAppendModal && (
				<AppendStaffTrainingForm
					id={selectedItemId}
					onClose={() => setShowAppendModal(false)}
				/>
			)}
			{showEditModal && (
				<EditStaffTrainingForm
					id={selectedItemId}
					onClose={() => setShowEditModal(false)}
				/>
			)}
			{showViewModal && (
				<EditStaffTrainingForm
					readOnly
					id={selectedItemId}
					onClose={() => setShowViewModal(false)}
				/>
			)}
			<SendEmailModal
				isOpen={showEmailModal}
				title={"Email Staff Training Record"}
				onClose={() => setShowEmailModal(false)}
				onSend={(selectedRecipients) => {
					setIsSendingEmail(true);
					safetyWiseApi
						.post(
							`${currentOrganisationGUID}/staff-training/email/${selectedItemId}`,
							{
								toInternalAddresses:
									selectedRecipients.recipients,
								toExternalAddresses: [
									...selectedRecipients.recipientsOther,
									...selectedRecipients.recipientsCustomerSites,
								],
							}
						)
						.then(() => {
							setSuccessMessage("Emails sent successfully.");
						})
						.catch(handleApiError)
						.finally(() => {
							setIsSendingEmail(false);
							setShowEmailModal(false);
						});
				}}
				isSaving={isSendingEmail}
				selectedItemId={selectedItemId}
				siteId={siteId}
				emailListType={"allCustomerSiteRecord"}
			/>
			<PopupSnackbar
				message={errorMessage}
				coloured
				error
				onExited={() => setErrorMessage("")}
			/>
			<PopupSnackbar
				message={successMessage}
				coloured
				success
				onExited={() => setSuccessMessage("")}
			/>
		</Page>
	);
};

StaffTrainingList.propTypes = {
	role: PropTypes.string.isRequired,
	staffTrainings: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	onGetStaffTrainingList: PropTypes.func.isRequired,
	currentOrganisationGUID: PropTypes.string,
};

export default StaffTrainingList;
