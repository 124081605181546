import fromTypes from "./types";

export const onClearSearch = () => ({
	type: fromTypes.HEALTH_SAFETY_CLEAR_SEARCH,
});

export const onListFailed = (payload) => ({
	type: fromTypes.LIST_FAILED,
	payload,
});

// Accident Actions
export const onIncidentListReceive = (payload) => ({
	type: fromTypes.INCIDENTS_LIST_RECEIVED,
	payload,
});

export const onIncidentListRequest = () => ({
	type: fromTypes.INCIDENTS_LIST_REQUESTED,
});

export const onIncidentPagedListRequest = (payload) => ({
	type: fromTypes.INCIDENTS_PAGED_LIST_REQUESTED,
	payload,
});

export const onIncidentListFailed = (payload) => ({
	type: fromTypes.INCIDENTS_LIST_FAILED,
	payload,
});

export const onIncidentSearchChanged = (payload) => ({
	type: fromTypes.INCIDENTS_SEARCH_CHANGED,
	payload,
});

// Hazard Actions
export const onHazardListReceive = (payload) => ({
	type: fromTypes.HAZARDS_LIST_RECEIVED,
	payload,
});

export const onHazardPagedListRequest = (payload) => ({
	type: fromTypes.HAZARDS_PAGED_LIST_REQUESTED,
	payload,
});

export const onHazardListRequest = () => ({
	type: fromTypes.HAZARDS_LIST_REQUESTED,
});

export const onHazardListFailed = (payload) => ({
	type: fromTypes.HAZARDS_LIST_FAILED,
	payload,
});

export const onHazardSearchChanged = (payload) => ({
	type: fromTypes.HAZARDS_SEARCH_CHANGED,
	payload,
});

// Hazardous Substance Actions
export const onHazardousSubstanceListReceive = (payload) => ({
	type: fromTypes.HAZARDOUS_SUBSTANCES_LIST_RECEIVED,
	payload,
});

export const onHazardousSubstancePagedListRequest = (payload) => ({
	type: fromTypes.HAZARDOUS_SUBSTANCES_PAGED_LIST_REQUESTED,
	payload,
});

export const onHazardousSubstanceListRequest = () => ({
	type: fromTypes.HAZARDOUS_SUBSTANCES_LIST_REQUESTED,
});

export const onHazardousSubstanceListFailed = (payload) => ({
	type: fromTypes.HAZARDOUS_SUBSTANCES_LIST_FAILED,
	payload,
});

export const onHazardousSubstanceSearchChanged = (payload) => ({
	type: fromTypes.HAZARDOUS_SUBSTANCES_SEARCH_CHANGED,
	payload,
});

// Safety Plan Checklist Actions
export const onSafetyPlanChecklistListReceive = (payload) => ({
	type: fromTypes.SAFETY_PLAN_CHECKLIST_LIST_RECEIVED,
	payload,
});

export const onSafetyPlanChecklistPagedListRequest = (payload) => ({
	type: fromTypes.SAFETY_PLAN_CHECKLIST_PAGED_LIST_REQUESTED,
	payload,
});

export const onSafetyPlanChecklistListRequest = () => ({
	type: fromTypes.SAFETY_PLAN_CHECKLIST_LIST_REQUESTED,
});

export const onSafetyPlanChecklistListFailed = (payload) => ({
	type: fromTypes.SAFETY_PLAN_CHECKLIST_LIST_FAILED,
	payload,
});

export const onSafetyPlanChecklistSearchChanged = (payload) => ({
	type: fromTypes.SAFETY_PLAN_CHECKLIST_SEARCH_CHANGED,
	payload,
});

// Job Safety Analysis Actions
export const onJobSafetyAnalysisListReceive = (payload) => ({
	type: fromTypes.JOB_SAFETY_ANALYSIS_LIST_RECEIVED,
	payload,
});

export const onJobSafetyAnalysisListRequest = () => ({
	type: fromTypes.JOB_SAFETY_ANALYSIS_LIST_REQUESTED,
});

export const onJobSafetyAnalysisPagedListRequest = (payload) => ({
	type: fromTypes.JOB_SAFETY_ANALYSIS_PAGED_LIST_REQUESTED,
	payload,
});

export const onJobSafetyAnalysisListFailed = (payload) => ({
	type: fromTypes.JOB_SAFETY_ANALYSIS_LIST_FAILED,
	payload,
});

export const onJobSafetyAnalysisSearchChanged = (payload) => ({
	type: fromTypes.JOB_SAFETY_ANALYSIS_SEARCH_CHANGED,
	payload,
});

// Induction Minutes Actions
export const onInductionMinutesListReceive = (payload) => ({
	type: fromTypes.INDUCTION_MINUTES_LIST_RECEIVED,
	payload,
});

export const onInductionMinutesListRequest = () => ({
	type: fromTypes.INDUCTION_MINUTES_LIST_REQUESTED,
});

export const onInductionMinutesPagedListRequest = (payload) => ({
	type: fromTypes.INDUCTION_MINUTES_PAGED_LIST_REQUESTED,
	payload,
});

export const onInductionMinutesListFailed = (payload) => ({
	type: fromTypes.INDUCTION_MINUTES_LIST_FAILED,
	payload,
});

export const onInductionMinutesSearchChanged = (payload) => ({
	type: fromTypes.INDUCTION_MINUTES_SEARCH_CHANGED,
	payload,
});

// Safety Minutes Actions
export const onSafetyMinutesListReceive = (payload) => ({
	type: fromTypes.SAFETY_MINUTES_LIST_RECEIVED,
	payload,
});

export const onSafetyMinutesPagedListRequest = (payload) => ({
	type: fromTypes.SAFETY_MINUTES_PAGED_LIST_REQUESTED,
	payload,
});

export const onSafetyMinutesListRequest = () => ({
	type: fromTypes.SAFETY_MINUTES_LIST_REQUESTED,
});

export const onSafetyMinutesListFailed = (payload) => ({
	type: fromTypes.SAFETY_MINUTES_LIST_FAILED,
	payload,
});

export const onSafetyMinutesSearchChanged = (payload) => ({
	type: fromTypes.SAFETY_MINUTES_SEARCH_CHANGED,
	payload,
});

// Staff Training Actions
export const onStaffTrainingListReceive = (payload) => ({
	type: fromTypes.STAFF_TRAINING_LIST_RECEIVED,
	payload,
});

export const onStaffTrainingPagedListRequest = (payload) => ({
	type: fromTypes.STAFF_TRAINING_PAGED_LIST_REQUESTED,
	payload,
});

export const onStaffTrainingListRequest = () => ({
	type: fromTypes.STAFF_TRAINING_LIST_REQUESTED,
});

export const onStaffTrainingListFailed = (payload) => ({
	type: fromTypes.STAFF_TRAINING_LIST_FAILED,
	payload,
});

export const onStaffTrainingSearchChanged = (payload) => ({
	type: fromTypes.STAFF_TRAINING_SEARCH_CHANGED,
	payload,
});

// Staff Training Topic Actions
export const onStaffTrainingTopicListReceive = (payload) => ({
	type: fromTypes.STAFF_TRAINING_TOPIC_LIST_RECEIVED,
	payload,
});

export const onStaffTrainingTopicListRequest = () => ({
	type: fromTypes.STAFF_TRAINING_TOPIC_LIST_REQUESTED,
});

export const onStaffTrainingTopicPagedListRequest = (payload) => ({
	type: fromTypes.STAFF_TRAINING_TOPIC_PAGED_LIST_REQUESTED,
	payload,
});

export const onStaffTrainingTopicListFailed = (payload) => ({
	type: fromTypes.STAFF_TRAINING_TOPIC_LIST_FAILED,
	payload,
});

export const onStaffTrainingTopicSearchChanged = (payload) => ({
	type: fromTypes.STAFF_TRAINING_TOPIC_SEARCH_CHANGED,
	payload,
});

// Remove Actions
export const onRemove = (payload) => ({
	type: fromTypes.HEALTH_SAFETY_REMOVE_REQUESTED,
	payload,
});

export const onRemoveSuccess = (payload) => ({
	type: fromTypes.HEALTH_SAFETY_REMOVE_SUCCEEDED,
	payload,
});

export const onRemoveFailed = (payload) => ({
	type: fromTypes.HEALTH_SAFETY_REMOVE_FAILED,
	payload,
});

export const onConfirmAttendance = (payload) => ({
	type: fromTypes.INDUCTION_MINUTES_CONFIRM_CLICKED,
	payload,
});

export const onInductionMinutesConfirmAttendanceSucceeded = (payload) => ({
	type: fromTypes.INDUCTION_MINUTES_CONFIRM_ATTENDANCE_SUCCEEDED,
	payload,
});

export const onInductionMinutesConfirmAttendanceFailed = (payload) => ({
	type: fromTypes.INDUCTION_MINUTES_CONFIRM_ATTENDANCE_FAILED,
	payload,
});
