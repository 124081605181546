import * as R from "ramda";
import initialNavigationState from "../models/navigation-state";
import Types from "../actions/types";

const hamburgerClicked = (state, action) => {
	state.isMobileOpen = !state.isMobileOpen;
	return state;
};

const mobileNavClosed = (state, action) => {
	state.isMobileOpen = false;
	return state;
};

const navigationReducer = (state = initialNavigationState, action) => {
	switch (action.type) {
		case Types.FRAME_HAMBURGER_CLICKED:
			return hamburgerClicked(R.clone(state), action);
		case Types.FRAME_MOBILE_NAV_CLOSED:
			return mobileNavClosed(R.clone(state), action);
		default:
			return state;
	}
};

export default navigationReducer;
