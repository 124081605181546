export const getBaseUrl = () => {
	let baseUrl = localStorage.getItem("baseUrl");
	return baseUrl;
};

export const getOrganisationBaseUrl = (stateValue) => {
	return `${getBaseUrl()}/api/${
		stateValue.organisations.currentOrganisation
	}`;
};

export const getOrganisationBaseUrlByGuid = (organisationGUID) => {
	return `${getBaseUrl()}/api/${organisationGUID}`;
};

export const setBaseUrl = () => {
	if (
		window.location.href.includes("localhost") ||
		window.location.href.includes("dev")
	) {
		localStorage.setItem("baseUrl", "http://app-dev.safetywise.co.nz");
		return;
	} else if (window.location.href.includes("uat")) {
		localStorage.setItem(
			"baseUrl",
			"https://api-uat.fortherecord.pro"
		);
		return;
	} else {
		localStorage.setItem("baseUrl", "https://api.fortherecord.pro");
	}
};

export const getWebsiteBaseUrl = () => {
	let baseUrl = localStorage.getItem("baseWebsiteUrl");
	return baseUrl;
};

export const setWebsiteBaseUrl = () => {
	localStorage.setItem("baseWebsiteUrl", "https://fortherecord.pro"); // This should always point to the fortherecord.pro website
};

export const getReCaptchaSiteKey = () => {
	return "6LcyLnIaAAAAAKMonBLqOqoTTCjnNw0VKQ0MMSo7";
};
