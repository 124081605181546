import moment from "moment";

export const defaultIncidentFormState = {
	accident: {
		incidentDetails: "",
		action: "",
		category: "",
		relatedHazardOrSubstance: [],
		seriousHarm: "",
		investigationOutcomes: "",
	},
};

export const defaultRecipientsFormState = {
	recipients: [],
	recipientsOther: [],
	sendToRecipientsOnSave: true,
};

export const defaultHazardFormState = {
	hazard: {
		name: "",
		description: "",
		initialRiskAssessment: "",
		residualRiskAssessment: "",
		significantRisk: "",
		trainingRequired: "",
		ppeRequirements: "",
		hazardControls: "",
		status: "Active",
	},
};

export const defaultHazardsFormState = {
	hazards: [],
};

export const defaultHazardousSubstanceFormState = {
	substances: {
		name: "",
		substanceType: "",
		supplierSDSReportHeld: "",
		initialRiskAssessment: "",
		residualRiskAssessment: "",
		volumeMeasurement: "",
		protectiveClothingRequired: "",
		actionRecommended: "",
		status: "Active",
	},
};

export const defaultHazardousSubstancesFormState = {
	substances: [],
};

export const defaultNotesFormState = {
	notes: {
		operationalIssues: "",
		generalIssuesDiscussed: "",
	},
};

export const defaultPeopleFormState = {
	people: [],
	otherPeople: [],
};

export const defaultSafetyPlanChecklistFormState = {
	safetyPlan: {},
};

export const defaultSiteFormState = {
	createdAt: moment().format(),
	siteId: "",
	locked: true,
};

export const defaultJobSafetyAnalysisFormState = {
	tasks: [],
};

export const isLocked = (state) => {
	const saving = state.save && state.save.saving;
	let locked = saving || !state.siteId ? true : false;
	return {
		...state,
		locked,
	};
};

export const defaultInductionMeetingsFormState = {
	sendReminder: false,
	notes: "",
	hazardNotes: "",
};
