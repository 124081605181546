import { combineEpics } from "redux-observable";
import initialiseEffect from "./initialise-effect";
import routeChangeCloseMobileNav from "./router-change-close-mobile-nav-effect";
import notLoggingOutEFfect from "./not-logging-out-effect";

const frameEffects = combineEpics(
	initialiseEffect,
	routeChangeCloseMobileNav,
	notLoggingOutEFfect
);

export default frameEffects;
