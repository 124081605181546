import * as R from "ramda";
import { initialHealthSafetyState } from "../models/health-safety-state";
import Types from "../actions/types";
import { defaultPaginationState, sortByPropertyDate } from "utils";


export const initialJobState = {
	...defaultPaginationState,
	refreshList: false,
	pageSize: 5
};

const getStateNameByActionType = (actionType) => {
	// Info
	if (actionType.includes("HAZARDS")) {
		return "hazards";
	} else if (actionType.includes("HAZARDOUS_SUBSTANCES")) {
		return "hazardousSubstances";
	} else if (actionType.includes("TOPIC")) {
		return "staffTrainingTopics";
	} else if (actionType.includes("JOB_SAFETY_ANALYSIS")) {
		return "jobSafetyAnalysis";
	} else if (actionType.includes("SAFETY_PLAN_CHECKLIST")) {
		return "safetyPlanChecklists";
	}
	// Tasks
	if (actionType.includes("SAFETY_MINUTES")) {
		return "safetyMinutes";
	} else if (actionType.includes("INCIDENTS")) {
		return "incidents";
	} else if (actionType.includes("INDUCTION_MINUTES")) {
		return "inductions";
	} else if (actionType.includes("STAFF_TRAINING")) {
		return "staffTrainings";
	} else {
		return "";
	}
};

const healthSafetyReducer = (state = initialHealthSafetyState, action) => {
	switch (action.type) {
		case Types.HEALTH_SAFETY_CLEAR_SEARCH:
			return searchClear(R.clone(state));

		// List Requested
		case Types.HAZARDS_LIST_REQUESTED: // Info
		case Types.HAZARDS_PAGED_LIST_REQUESTED:
		case Types.HAZARDOUS_SUBSTANCES_LIST_REQUESTED:
		case Types.HAZARDOUS_SUBSTANCES_PAGED_LIST_REQUESTED:
		case Types.STAFF_TRAINING_TOPIC_LIST_REQUESTED:
		case Types.JOB_SAFETY_ANALYSIS_LIST_REQUESTED:
		case Types.JOB_SAFETY_ANALYSIS_PAGED_LIST_REQUESTED:
		case Types.SAFETY_PLAN_CHECKLIST_LIST_REQUESTED:
		case Types.SAFETY_PLAN_CHECKLIST_PAGED_LIST_REQUESTED:
		case Types.SAFETY_MINUTES_LIST_REQUESTED:
		case Types.INCIDENTS_LIST_REQUESTED:
		case Types.INDUCTION_MINUTES_LIST_REQUESTED:
		case Types.STAFF_TRAINING_LIST_REQUESTED:
			return listRequested(R.clone(state), action);

		// Paged List Requested
		case Types.SAFETY_MINUTES_PAGED_LIST_REQUESTED:
		case Types.INDUCTION_MINUTES_PAGED_LIST_REQUESTED:
		case Types.INCIDENTS_PAGED_LIST_REQUESTED:
		case Types.STAFF_TRAINING_PAGED_LIST_REQUESTED: {
			const nextState = hydrateList(R.clone(state), action);
			return listRequested(nextState, action);
		}

		// List Requested Error
		case Types.STAFF_TRAINING_TOPIC_LIST_FAILED:
		case Types.HAZARDS_LIST_FAILED: // Info
		case Types.HAZARDOUS_SUBSTANCES_LIST_FAILED:
		case Types.JOB_SAFETY_ANALYSIS_LIST_FAILED:
		case Types.SAFETY_PLAN_CHECKLIST_LIST_FAILED:
		case Types.SAFETY_MINUTES_LIST_FAILED:
		case Types.INCIDENTS_LIST_FAILED:
		case Types.INDUCTION_MINUTES_LIST_FAILED:
		case Types.STAFF_TRAINING_LIST_FAILED:
			return listRequestedFailed(R.clone(state), action);

		// list received success
		case Types.HAZARDS_LIST_RECEIVED:
		case Types.HAZARDOUS_SUBSTANCES_LIST_RECEIVED:
		case Types.STAFF_TRAINING_TOPIC_LIST_RECEIVED:
		case Types.JOB_SAFETY_ANALYSIS_LIST_RECEIVED:
		case Types.SAFETY_PLAN_CHECKLIST_LIST_RECEIVED:
		case Types.SAFETY_MINUTES_LIST_RECEIVED:
		case Types.INCIDENTS_LIST_RECEIVED:
		case Types.INDUCTION_MINUTES_LIST_RECEIVED:
		case Types.STAFF_TRAINING_LIST_RECEIVED:
			return listRequestedSuccess(R.clone(state), action);

		// Search
		case Types.HAZARDS_SEARCH_CHANGED:
		case Types.HAZARDOUS_SUBSTANCES_SEARCH_CHANGED:
		case Types.STAFF_TRAINING_TOPIC_SEARCH_CHANGED:
		case Types.JOB_SAFETY_ANALYSIS_SEARCH_CHANGED:
		case Types.SAFETY_PLAN_CHECKLIST_SEARCH_CHANGED:
		case Types.SAFETY_MINUTES_SEARCH_CHANGED:
		case Types.INCIDENTS_SEARCH_CHANGED:
		case Types.INDUCTION_MINUTES_SEARCH_CHANGED:
		case Types.STAFF_TRAINING_SEARCH_CHANGED:
			return searchChanged(R.clone(state), action);

		default:
			return state;
	}
};

const listRequested = (state, action) => {
	const stateName = getStateNameByActionType(action.type);
	state[stateName] = { ...state[stateName], flag: "processing" };
	return state;
};

const listRequestedSuccess = (state, action) => {
	const stateName = getStateNameByActionType(action.type);
	const payload = action.payload;

	let results = defaultPaginationState;
	let listData =
		state[stateName] && state[stateName].listData
			? state[stateName].listData
			: [];

	if (payload && payload.results && payload.results.length > 0) {
		results = payload;

		if (listData && listData.length > 0) {
			// appends payload to list data if not exists

			payload.results.forEach((newRecord) => {
				// check if data exists in the list already
				const existingDataIndex = listData.findIndex(
					(item) => item.id === newRecord.id
				);

				// replace existing entries (same id) with new data
				if (existingDataIndex === -1) {
					listData.push(newRecord);
				} else {
					listData[existingDataIndex] = newRecord;
				}
			});
		} else {
			// listData is empty, set results directly
			listData = payload.results;
		}
	}

	state[stateName] = {
		...results,
		listData: listData.sort(sortByPropertyDate("createdAt")),
		flag: "done",
	};
	return state;
};

const listRequestedFailed = (state, action) => {
	const stateName = getStateNameByActionType(action.type);
	state[stateName] = {
		...state[stateName],
		flag: "error",
		error: action.payload || "error occurred",
	};
	return state;
};

// generic function to handle offline mode pagination
const hydrateList = (state, action) => {
	const stateName = getStateNameByActionType(action.type);
	const payload = action.payload;
	const stateData = state[stateName];
	const listData = stateData.listData || [];
	const currentPagedData = stateData.results || [];
	const NUMBER_PER_PAGE = 5;

	const pageSize =
		stateData.pageNumber === stateData.totalNumberOfRecords
			? stateData.pageSize
			: NUMBER_PER_PAGE;

	const totalNumberOfPages =
		listData.length % NUMBER_PER_PAGE > 0
			? (listData.length - (listData.length % NUMBER_PER_PAGE)) /
					NUMBER_PER_PAGE +
			  1
			: listData.length % NUMBER_PER_PAGE === 0
			? listData.length / NUMBER_PER_PAGE
			: 1;

	let hydrateData = [];

	// check if listData has more data than currently displayed list
	if (payload && listData.length > currentPagedData.length) {
		let itemsToSkip = (payload.page - 1) * pageSize;
		let itemsToTake = pageSize;

		// work out paginated page to show from listData
		hydrateData = stateData.listData
			.slice(itemsToSkip)
			.slice(0, itemsToTake);
	}

	state[stateName] = {
		...stateData,
		pageNumber: (payload && payload.page) || stateData.page || 1,
		totalNumberOfPages: totalNumberOfPages,
		results: hydrateData.length > 0 ? hydrateData : stateData.results,
		flag: "error",
		error: payload || "error occurred",
	};
	return state;
};

const searchChanged = (state, action) => {
	state.searchFilter = action.payload || "";
	return state;
};

const searchClear = (state) => {
	state.searchFilter = "";
	return state;
};

export default healthSafetyReducer;
