import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { SelectInput } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		right: 25,
		top: 10,
		marginTop: theme.spacing(0.1),
		"& .MuiOutlinedInput-input": {
			padding: theme.spacing(1.7, 2),
		},
		"& .MuiInputLabel-outlined": {
			transform: "translate(14px, 14px) scale(1)",
		},
		"& .MuiInputLabel-shrink": {
			transform: "translate(14px, -6px) scale(0.75)",
		},
		[theme.breakpoints.down("sm")]: {
			right: -100,
		},
		[theme.breakpoints.down("xs")]: {
			top: 50,
			left: 20,
			minWidth: "100px",
		},
		minWidth: "150px",
	},
}));

const ListFilterOptions = ({
	filterKey,
	onGetList,
	pagination,
	orderBy,
	ascending = true,
	useArchive,
	customFilterName,
	customFilterOptions,
}) => {
	const classes = useStyles();
	const [selectValue, setSelectValue] = useState("");

	if (!pagination) {
		return null;
	}

	const onSelectChange = (value) => {
		setSelectValue(value);
		onGetList({
			page: 1,
			pageSize: pagination.pageSize,
			orderBy: orderBy || "id",
			ascending: ascending,
			columnName: filterKey,
			columnValue: value,
		});
	};

	const defaultOptions =
		filterKey === "createdAt"
			? [
					{ label: "Ascending", value: "Ascending" },
					{ label: "Descending", value: "Descending" },
			  ]
			: [
					{ label: "Active", value: "Active" },
					{
						label: useArchive ? "Archived" : "Inactive",
						value: useArchive ? "Archived" : "Inactive",
					},
			  ];

	return (
		<div className={classes.root}>
			{filterKey && (
				<SelectInput
					id="filter"
					label={customFilterName ? customFilterName : "Filter"}
					options={
						customFilterOptions
							? customFilterOptions
							: defaultOptions
					}
					value={selectValue}
					onChange={onSelectChange}
				/>
			)}
		</div>
	);
};

ListFilterOptions.propTypes = {
	filterKey: PropTypes.string,
	pagination: PropTypes.object.isRequired,
	onGetList: PropTypes.func.isRequired,
	orderBy: PropTypes.string,
	ascending: PropTypes.bool,
	useArchive: PropTypes.bool,
	customFilterName: PropTypes.string,
	customFilterOptions: PropTypes.array,
};

export default ListFilterOptions;
