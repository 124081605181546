import customerModel from "./customer-model";
import { defaultPaginationState } from "utils";

export default {
	currentCustomer: customerModel,
	isAddModalShowing: false,
	isEditModalShowing: false,
	isNew: false,
	searchFilter: "",
	pagination: defaultPaginationState,
};
