import * as R from "ramda";
import uuid from "uuid";
import { hasValue, sortByPropertyDate } from "utils";
import initialSitesState from "../models/sites-state";
import SiteModel from "../models/site-model";
import fromTypes from "../actions/types";

const setIsValid = (site) => {
	site.isValid =
		hasValue(site.siteName) &&
		hasValue(site.customerId) &&
		hasValue(site.status);
};

const addClicked = (state, action) => {
	state.isAddModalShowing = true;
	state.isNew = true;
	state.isEditModalShowing = false;
	state.currentSite = SiteModel;
	state.currentSite.id = uuid.v4();
	return state;
};

const editClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isNew = false;
	state.isEditModalShowing = true;
	state.currentSite = R.clone(
		R.find(R.propEq("id", action.payload), state.pagination.results)
	);
	return state;
};

const siteDetails = (state, action) => {
	state.currentSite = R.clone(
		R.find(R.propEq("id", action.payload), state.pagination.results)
	);
	return state;
};

const saveClicked = (state, action) => {
	if (state.isAddModalShowing) {
		state.isAddModalShowing = false;
	} else if (state.isEditModalShowing) {
		state.isEditModalShowing = false;
	}
	return state;
};

const addSucceeded = (state, action) => {
	if (state.isNew) {
		state.pagination.results.push(state.currentSite);
		state.pagination.results = state.pagination.results.sort(
			sortByPropertyDate("createdAt")
		);
	} else {
		const index = R.findIndex(R.propEq("id", state.currentSite.id))(
			state.pagination.results
		);
		state.pagination.results[index] = state.currentSite;
	}
	state.currentSite = SiteModel;
	return state;
};

const closeClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isNew = true;
	state.isEditModalShowing = false;
	state.currentSite = SiteModel;
	state.currentSite.id = uuid.v4();
	return state;
};

const siteNameChanged = (state, action) => {
	state.currentSite.siteName = action.payload;
	setIsValid(state.currentSite);
	return state;
};

const customerNameChanged = (state, action) => {
	state.currentSite.customerId = action.payload;
	setIsValid(state.currentSite);
	return state;
};

const addressChanged = (state, action) => {
	state.currentSite.address = action.payload;
	return state;
};

const suburbChanged = (state, action) => {
	state.currentSite.suburb = action.payload;
	return state;
};

const townCityChanged = (state, action) => {
	state.currentSite.townCity = action.payload;
	return state;
};

const siteContactPersonChanged = (state, action) => {
	state.currentSite.siteContactPerson = action.payload;
	return state;
};

const siteContactPhoneChanged = (state, action) => {
	state.currentSite.siteContactPhone = action.payload;
	return state;
};

const siteContactEmailChanged = (state, action) => {
	state.currentSite.siteContactEmail = action.payload;
	return state;
};

const siteStatusChanged = (state, action) => {
	state.currentSite.status = action.payload;
	setIsValid(state.currentSite);
	return state;
};

const getsiteList = (state, action) => {
	state.isAddModalShowing = false;
	state.isEditModalShowing = false;
	state.isAddHazardToSiteModalShowing = false;
	state.isAddHazardousSubstanceToSiteModalShowing = false;
	return state;
};

const hydrateSite = (state, action) => {
	if (action.payload) {
		const id = action.payload;
		const site = state.pagination.results.filter((s) => s.id === id)[0];
		state.currentSite = site;
	}
	return state;
};

const siteListReceived = (state, action) => {
	// state.siteList = []
	state.pagination = action.payload;
	// action.payload.results.map(function(item) {
	//   state.siteList.push({
	//     id: item.id,
	//     siteName: item.siteName,
	//     customerId: item.customerId,
	//     address: item.address,
	//     suburb: item.suburb,
	//     townCity: item.townCity,
	//     siteContactPerson: item.siteContactPerson,
	//     siteContactPhone: item.siteContactPhone,
	//     siteContactEmail: item.siteContactEmail,
	//     status: item.status,
	//     isArchived: item.isArchived,
	//     hazards: item.hazards,
	//     substances: item.hazardousSubstances
	//   })
	//   return item
	// })
	return state;
};

const removeClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isEditModalShowing = false;
	state.currentSite = R.find(
		R.propEq("id", action.payload),
		state.pagination.results
	);
	return state;
};

const siteRemoveSucceeded = (state, action) => {
	return state;
};

const siteValueChange = (state, action) => {
	state.currentSite = action.payload;
	return state;
};

const addHazardToSiteClicked = (state) => {
	state.isAddHazardToSiteModalShowing = true;
	return state;
};

const addHazardToSiteClose = (state) => {
	state.isAddHazardToSiteModalShowing = false;
	return state;
};

const editHazardToSiteClicked = (state) => {
	state.isEditHazardToSiteModalShowing = true;
	return state;
};

const editHazardToSiteClose = (state) => {
	state.isEditHazardToSiteModalShowing = false;
	return state;
};

const addHazardousSubstanceToSiteClicked = (state) => {
	state.isAddHazardousSubstanceToSiteModalShowing = true;
	return state;
};

const addHazardousSubstanceToSiteClose = (state) => {
	state.isAddHazardousSubstanceToSiteModalShowing = false;
	return state;
};

const editHazardousSubstanceToSiteClicked = (state) => {
	state.isEditHazardousSubstanceToSiteModalShowing = true;
	return state;
};

const editHazardousSubstanceToSiteClose = (state) => {
	state.isEditHazardousSubstanceToSiteModalShowing = false;
	return state;
};

const addHazardToSite = (state, action) => {
	const hazardList = state.currentSite.hazards;
	state.currentSite = R.find(
		R.propEq("id", action.payload),
		state.pagination.results
	);

	// remove duplicate hazards
	const combined =
		hazardList && new Set([...hazardList].map((e) => JSON.stringify(e)));
	state.currentSite.hazards = Array.from(combined).map((e) => JSON.parse(e));
	return state;
};

const addHazardousSubstanceToSite = (state, action) => {
	const hazardousSubstanceList = state.currentSite.substances;
	state.currentSite = R.find(
		R.propEq("id", action.payload),
		state.pagination.results
	);

	// remove duplicate substances
	const combined =
		hazardousSubstanceList &&
		new Set([...hazardousSubstanceList].map((e) => JSON.stringify(e)));
	state.currentSite.substances = Array.from(combined).map((e) =>
		JSON.parse(e)
	);
	return state;
};

const addHazardToSiteSuccess = (state, action) => {
	// if(action.payload) {
	//   const hazardId = action.payload
	//   state.currentSite.hazards.push(hazardId)
	// }
	return state;
};

const addHazardousSubstanceToSiteSuccess = (state, action) => {
	// if(action.payload) {
	//   const hazardousSubstanceId = action.payload
	//   state.currentSite.substances.push(hazardousSubstanceId)
	// }
	return state;
};

const removeHazardFromSite = (state, action) => {
	state.currentSite = action.payload;
	return state;
};

const searchChanged = (state, action) => {
	state.searchFilter = action.payload;
	return state;
};

const siteLookupSuccess = (state, { payload }) => {
	if (payload) {
		state.currentSite = payload;
	}
	return state;
};

const searchClear = (state, action) => {
	state.searchFilter = "";
	return state;
};

const sitesReducer = (state = initialSitesState, action) => {
	switch (action.type) {
		case fromTypes.SITES_LOOKUP_SUCCESS:
			return siteLookupSuccess(R.clone(state), action);
		case fromTypes.SITES_SEARCH_CLEAR:
			return searchClear(R.clone(state), action);
		case fromTypes.SITES_SEARCH_CHANGED:
			return searchChanged(R.clone(state), action);
		case fromTypes.SITES_ADD_CLICKED:
			return addClicked(R.clone(state), action);
		case fromTypes.SITES_EDIT_CLICKED:
			return editClicked(R.clone(state), action);
		case fromTypes.SITES_SAVE_CLICKED:
			return saveClicked(R.clone(state), action);
		case fromTypes.SITES_ADD_SUCCEEDED:
			return addSucceeded(R.clone(state), action);
		case fromTypes.SITES_CLOSE_CLICKED:
			return closeClicked(R.clone(state), action);
		case fromTypes.SITES_SITE_NAME_CHANGED:
			return siteNameChanged(R.clone(state), action);
		case fromTypes.SITES_CUSTOMER_NAME_CHANGED:
			return customerNameChanged(R.clone(state), action);
		case fromTypes.SITES_ADDRESS_CHANGED:
			return addressChanged(R.clone(state), action);
		case fromTypes.SITES_SUBURB_CHANGED:
			return suburbChanged(R.clone(state), action);
		case fromTypes.SITES_TOWN_CITY_CHANGED:
			return townCityChanged(R.clone(state), action);
		case fromTypes.SITES_SITE_CONTACT_PERSON_CHANGED:
			return siteContactPersonChanged(R.clone(state), action);
		case fromTypes.SITES_SITE_CONTACT_PHONE_CHANGED:
			return siteContactPhoneChanged(R.clone(state), action);
		case fromTypes.SITES_SITE_CONTACT_EMAIL_CHANGED:
			return siteContactEmailChanged(R.clone(state), action);
		case fromTypes.SITES_STATUS_CHANGED:
			return siteStatusChanged(R.clone(state), action);
		case fromTypes.SITES_LIST_REQUESTED:
			return getsiteList(R.clone(state), action);
		case fromTypes.SITES_LIST_RECEIVED:
			return siteListReceived(R.clone(state), action);

		case fromTypes.SITES_REMOVE_CLICKED:
			return removeClicked(R.clone(state), action);
		case fromTypes.SITES_REMOVE_SUCCEEDED:
			return siteRemoveSucceeded(R.clone(state), action);

		case fromTypes.SITES_DETAILS: // sets current site state for site summary page
			return siteDetails(R.clone(state), action);
		case fromTypes.SITES_HAZARDS_VALUE_CHANGE:
		case fromTypes.SITES_HAZARDOUS_SUBSTANCE_VALUE_CHANGE:
		case fromTypes.SITES_HAZARD_REMOVE:
		case fromTypes.SITES_HAZARDOUS_SUBSTANCE_REMOVE:
			return siteValueChange(R.clone(state), action);

		case fromTypes.ADD_HAZARD_TO_SITE_CLICKED:
			return addHazardToSiteClicked(R.clone(state));
		case fromTypes.ADD_HAZARD_TO_SITE_CLOSE:
			return addHazardToSiteClose(R.clone(state));

		case fromTypes.EDIT_HAZARD_TO_SITE_CLICKED:
			return editHazardToSiteClicked(R.clone(state));
		case fromTypes.EDIT_HAZARD_TO_SITE_CLOSE:
			return editHazardToSiteClose(R.clone(state));

		case fromTypes.ADD_HAZARDOUS_SUBSTANCE_TO_SITE_CLICKED:
			return addHazardousSubstanceToSiteClicked(R.clone(state));
		case fromTypes.ADD_HAZARDOUS_SUBSTANCE_TO_SITE_CLOSE:
			return addHazardousSubstanceToSiteClose(R.clone(state));

		case fromTypes.EDIT_HAZARDOUS_SUBSTANCE_TO_SITE_CLICKED:
			return editHazardousSubstanceToSiteClicked(R.clone(state));
		case fromTypes.EDIT_HAZARDOUS_SUBSTANCE_TO_SITE_CLOSE:
			return editHazardousSubstanceToSiteClose(R.clone(state));

		case fromTypes.ADD_HAZARD_TO_SITE_FORM_SUBMIT:
			return addHazardToSite(R.clone(state), action);
		case fromTypes.ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT:
			return addHazardousSubstanceToSite(R.clone(state), action);

		case fromTypes.ADD_HAZARD_TO_SITE_FORM_SUBMIT_SUCCESS:
			return addHazardToSiteSuccess(R.clone(state), action);
		case fromTypes.ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT_SUCCESS:
			return addHazardousSubstanceToSiteSuccess(R.clone(state), action);

		case fromTypes.REMOVE_HAZARD_FROM_SITE_REQUESTED:
		case fromTypes.REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_REQUESTED:
			return removeHazardFromSite(R.clone(state), action);
		case fromTypes.HYDRATE_SITE:
			return hydrateSite(R.clone(state), action);
		default:
			return state;
	}
};

export default sitesReducer;
