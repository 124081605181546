import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardHeader } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	content: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		marginTop: theme.spacing(-3),
		background: theme.palette.background.paper,
		borderRadius: "4px",
	},
}));

const SectionCard = ({ title, children }) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			{title ? (
				<CardHeader
					title={title}
					titleTypographyProps={{ variant: "h4" }}
				/>
			) : (
				<div style={{ padding: "15px" }} />
			)}

			<CardContent className={classes.content}>
				{children && children}
			</CardContent>
		</Card>
	);
};

SectionCard.propTypes = {
	title: PropTypes.string,
	children: PropTypes.any,
};

export default SectionCard;
