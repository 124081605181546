import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError } from "rxjs/operators";
import { authHeaders, getOrganisationBaseUrl } from "frame";

import {
	onListRequested,
	onRemoveHazardFromSiteSuccess,
	onRemoveHazardFromSiteFailed,
	REMOVE_HAZARD_FROM_SITE_REQUESTED,
} from "features/sites";

const removeHazardFromSiteEffect = (action$, state$) =>
	action$.pipe(
		ofType(REMOVE_HAZARD_FROM_SITE_REQUESTED),
		mergeMap(() => {
			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/site/createorupdate`,
					{
						id: state$.value.sites.currentSite.id,
						siteName: state$.value.sites.currentSite.siteName,
						customerId: state$.value.sites.currentSite.customerId,
						address: state$.value.sites.currentSite.address,
						suburb: state$.value.sites.currentSite.suburb,
						townCity: state$.value.sites.currentSite.townCity,
						siteContactPerson:
							state$.value.sites.currentSite.siteContactPerson,
						siteContactPhone:
							state$.value.sites.currentSite.siteContactPhone,
						siteContactEmail:
							state$.value.sites.currentSite.siteContactEmail,
						status: state$.value.sites.currentSite.status,
						organisationId:
							state$.value.organisations.currentOrganisation,
						hazards: state$.value.sites.currentSite.hazards,
						substances: state$.value.sites.currentSite.substances,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return of(
							onListRequested(),
							onRemoveHazardFromSiteSuccess()
						);
					}),
					catchError((error) =>
						of(onRemoveHazardFromSiteFailed(error.message))
					)
				);
		})
	);

export default removeHazardFromSiteEffect;
