import taskTypeAddEffect from "./task-type-add-effect";
import taskTypeListEffect from "./task-type-list-effect";
import taskTypeRemoveEffect from "./task-type-remove-effect";
import taskTypeSaveEffect from "./task-type-save-effect";

const { combineEpics } = require("redux-observable");

const taskTypeEffects = combineEpics(
	taskTypeListEffect,
	taskTypeAddEffect,
	taskTypeRemoveEffect,
	taskTypeSaveEffect
);

export default taskTypeEffects;
