import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clip from "text-clipper";
import { makeStyles } from "@material-ui/styles";
import {
	onAddHazardSave,
	onArchiveHazard,
} from "features/health-safety-forms/actions";
import { Card, CardContent, Typography, Grid, Chip } from "@material-ui/core";

// import { AttachmentListing, TextClip } from 'frame/components'
// import { capitalize } from 'utils'
import { CrudOptions } from "frame/components";
import {
	isBornAccount,
	/*isValidContentState,*/ createRowSelector,
	formatDate,
	isStaffUser,
} from "utils";
// import MUIRichTextEditor from 'mui-rte'

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
		"&:hover": {
			cursor: "pointer",
		},
	},
	container: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(3),
		},
	},
	label: { color: "#999999" },
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
}));

const HazardCard = ({
	hazard,
	role,
	onView,
	onEdit,
	onDelete,
	onArchive,
	onSave,
	email,
}) => {
	const classes = useStyles();
	const onRowSelect = createRowSelector(onView);
	const isActive = hazard.status && hazard.status.toLowerCase() === "active";

	const id = {
		id: hazard.id,
		form: "hazard",
	};

	const onToggleStatus = (status) => () => {
		onArchive && onArchive({ ...hazard, status });
		onSave();
	};

	return (
		<Card className={classes.root} onClick={onRowSelect}>
			<CardContent className={classes.content}>
				<Grid className={classes.container} container spacing={2}>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Date
						</Typography>
						<Typography variant="h5">
							{formatDate(hazard.createdAt)}
						</Typography>
					</Grid>

					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Name
						</Typography>
						<Typography variant="h5">
							{clip(hazard.name, 140)}
						</Typography>
					</Grid>

					{/* <Grid item md={3} sm={6} xs={12}>
            <Typography variant="body2" className={classes.label}>
              Initial Risk Assessment
            </Typography>
            <Typography variant="h5">
              <TextClip
                text={hazard.initialRiskAssessment}
                numberOfCharacters={140} />
            </Typography>
          </Grid>

          <Grid item md={3} xs={6}>
            <Typography variant="body2" className={classes.label}>
              Residual Risk Assessment
            </Typography>
            <Typography variant="h5">
              <TextClip
                text={hazard.residualRiskAssessment}
                numberOfCharacters={140} />
            </Typography>
          </Grid>

          <Grid item md={3} xs={6}>
            <Typography variant="body2" className={classes.label}>
              Significant Risk
            </Typography>
            <Typography variant="h5">
              {hazard.significantRisk ? capitalize(hazard.significantRisk) : '-'}
            </Typography>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="body2" className={classes.label}>
              Training Required
            </Typography>
            <Typography variant="h5">
              {hazard.trainingRequired ? capitalize(hazard.trainingRequired) : '-'}
            </Typography>
          </Grid> */}

					{/* <Grid item md={3} sm={6} xs={12}>
            <Typography variant="body2" className={classes.label}>
              PPE Requirements
            </Typography>
            <Typography variant="h5">
            {isValidContentState(hazard.ppeRequirements) ? (
              <MUIRichTextEditor
                readOnly
                controls={[]}
                value={hazard.ppeRequirements}
              />
            ) : '-'}
            </Typography>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <Typography variant="body2" className={classes.label}>
              Hazard Controls
            </Typography>
            <Typography variant="h5">
            {isValidContentState(hazard.hazardControls) ? (
              <MUIRichTextEditor
                readOnly
                controls={[]}
                value={hazard.hazardControls}
              />
            ) : '-'}
            </Typography>
          </Grid> */}

					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Status
						</Typography>
						<Typography variant="h5">
							{hazard.status ? (
								<Chip
									className={
										hazard.status === "Active"
											? classes.success
											: classes.error
									}
									size="small"
									label={hazard.status}
								/>
							) : (
								"-"
							)}
						</Typography>
					</Grid>

					{/* <Grid item md={3} sm={6} xs={12}>
            <Typography variant="body2" className={classes.label}>
              Files
            </Typography>
            <AttachmentListing attachments={hazard.attachments} />
          </Grid> */}

					<div className="ignore">
						{!isStaffUser(role) && (
							<CrudOptions
								onEdit={onEdit}
								onDelete={
									isBornAccount(email)
										? () => onDelete(id)
										: null
								} // enabled in born superadmin only for easy maintenance
								onArchive={
									isActive ? onToggleStatus("Archived") : null
								}
								onMakeActive={
									!isActive ? onToggleStatus("Active") : null
								}
							/>
						)}
					</div>
				</Grid>
			</CardContent>
		</Card>
	);
};

HazardCard.propTypes = {
	hazard: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	onView: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
	onArchive: PropTypes.func,
	onSave: PropTypes.func,
	email: PropTypes.string,
};

const mapStateToProps = ({ profile }) => ({
	email: profile.email,
});

const mapDispatchToProps = {
	onSave: onAddHazardSave,
	onArchive: onArchiveHazard,
};

export default connect(mapStateToProps, mapDispatchToProps)(HazardCard);
