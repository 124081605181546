import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isNotNullOrEmpty } from "utils";

const SiteName = ({ siteList, id }) => {

	const matchSite =
		isNotNullOrEmpty(siteList) &&
		siteList.filter((s) => s.id === id)[0];

	let site = null;

	if (matchSite) {
		site = matchSite.siteName;
	}

	return <>{site}</>;
};

SiteName.propTypes = {
	siteList: PropTypes.array.isRequired,
	id: PropTypes.string.isRequired,
};

const mapStateToProps = ({ sites }) => ({
	siteList: sites.pagination.results,
});

export default connect(mapStateToProps)(SiteName);
