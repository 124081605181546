import moment from "moment";

/******************************************************************************************** */
/* Date/time helper functions                                                                 */
/******************************************************************************************** */
export const isDefaultDate = (date) => moment(date).format("YYYY") === "0001";

export const formatDate = (datetime) => {
	if (
		!datetime ||
		datetime === "" ||
		moment(datetime).format("YYYY") === "0001"
	) {
		return "-";
	}
	if (moment(datetime).isValid()) {
		return moment(datetime).format("Do MMMM YYYY"); // November 13th 2019
	}
	return moment(datetime, "MMM DD YYYY HH:mmAA").format("Do MMMM YYYY"); // November 13th 2019
};

export const formatDateTime = (datetime) => {
	let format = "h:mm a, D MMM YYYY";
	if (!datetime) {
		return moment(datetime).format(format); // November 13th 2019 8:30 AM
	}
	if (moment(datetime).isValid()) {
		return moment(datetime).format(format); // November 13th 2019 8:30 AM
	}
	return moment(datetime, "MMM DD YYYY HH:mmAA").format(
		format
	); // November 13th 2019 8:30 AM
};

export const formatTime = (time) => {
	if (!time || time === "") {
		return "-";
	}
	if (moment(time).isValid()) {
		return moment(time, ["HH:mm"]).format("h:mm a"); // 8:30 AM
	}
	if (/^([01]\d|2[0-3]):?([0-5]\d)$/g.test(time)) {
		//For new entry formatted HH:mm
		return moment(time, "HH:mm").format("h:mm a"); // 8:30 AM
	}
	return moment(time, "MMM DD YYYY HH:mmAA").format("h:mm a"); // 8:30 AM
};

// used in add/edit form post effects for geolocation timestamp
export const getCurrentTimestamp = () => moment().format("D MMM YYYY h:mm A");

export const dayDifference = (startDate, endDate) => {
	let start = moment(startDate);
	let end = moment(endDate);

	return end.diff(start, "days");
};
