import { ofType } from "redux-observable";
import { of } from "rxjs";
import { tap, mergeMap } from "rxjs/operators";
import { push } from "connected-react-router";
import FrameTypes from "frame/actions/types";
import * as fromAuth from "features/authorisation";
import { onLogoutFulfilled } from "../actions";
import { clearImpersonationToken, clearToken } from "frame";

const signoutEffect = (action$) =>
	action$.pipe(
		ofType(FrameTypes.FRAME_SIGNOUT_CLICKED),
		tap((action) => {
			clearToken();
			clearImpersonationToken();
		}),
		mergeMap(() => of(onLogoutFulfilled(), push(fromAuth.LOGIN_PATH)))
	);

export default signoutEffect;
