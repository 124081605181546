import { connect } from "react-redux";
import Form from "./form";

import {
	onCloseAddHazardousSubstanceToSite,
	onAddHazardousSubstanceToSiteSave,
	onAddHazardousSubstanceToSiteValueChange,
} from "features/sites";

const mapStateToProps = ({ sites, profile }) => ({
	values: sites.currentSite,
	isAddHazardousSubstanceToSiteModalShowing:
		sites.isAddHazardousSubstanceToSiteModalShowing,
	email: profile.email,
});

const mapDispatchToProps = {
	onChange: onAddHazardousSubstanceToSiteValueChange,
	onSave: onAddHazardousSubstanceToSiteSave,
	onClose: onCloseAddHazardousSubstanceToSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
