import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	SearchBar,
	Pagination,
	PaginationHeader,
	// ListFilterOptions
} from "frame/components";

// import { sortByPropertyDate } from 'utils'
import * as fromHealthSafetyForm from "features/health-safety-forms";
import StaffTrainingTopicCard from "../cards/staff-training-topic-card";
import {
	EditStaffTrainingTopicForm,
	PageHeaderTabs,
} from "features/health-safety-forms";
import { getPageResults } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {},
	filter: { display: "flex" },
}));

const StaffTrainingTopicList = ({
	role,
	searchFilter,
	staffTrainingTopics,
	onDelete,
	onSearchChanged,
	onGetStaffTrainingTopicList,
}) => {
	const classes = useStyles();
	const [viewId, setViewId] = useState(null);
	const [editId, setEditId] = useState(null);

	const staffTrainingTopicList = getPageResults(staffTrainingTopics);

	const empty = staffTrainingTopicList && staffTrainingTopicList.length <= 0;

	const onView = (id) => () => setViewId(id);

	const onEdit = (id) => () => setEditId(id);

	const onCancelEdit = () => {
		setEditId(null);
		setViewId(null);
	};

	return (
		<Page className={classes.root} title="Staff Training Topic Library">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Staff Training Topics"
				title="Staff Training Topic Library"
				// addTabTitle="Add training topic"
				// listTabTitle="View library of training topics"
				addTabPath={fromHealthSafetyForm.ADD_STAFF_TRAINING_TOPIC_ROUTE}
				backActionPath={fromHealthSafetyForm.INFO_ROUTE}
			/>
			<div className={classes.filter}>
				<SearchBar onSearch={onSearchChanged} />

				{/* <ListFilterOptions
          filterKey="ValidDuration"
          customFilterName="Filter Duration"
          customFilterOptions={['Weeks', 'Months', 'Years', 'Unlimited/Indefinite']}
          onGetList={onGetStaffTrainingTopicList}
          pagination={staffTrainingTopics}
          orderBy="createdAt"
          ascending={false}
        /> */}
			</div>

			<PageContent pad>
				<PaginationHeader
					hidePagination
					pagination={staffTrainingTopics}
				/>

				{!empty &&
					staffTrainingTopicList.map((node, index) => (
						<StaffTrainingTopicCard
							key={index}
							trainingTopic={node}
							role={role}
							onView={onView(node.id)}
							onEdit={onEdit(node.id)}
							onDelete={onDelete}
						/>
					))}

				{empty && (
					<Typography variant="body1">
						There are currently no staff training topics listed.
					</Typography>
				)}

				<Pagination
					hidePagination
					onGetList={onGetStaffTrainingTopicList}
					pagination={staffTrainingTopics}
					orderBy="createdAt"
					ascending={false}
					searchKey={searchFilter}
				/>
			</PageContent>
			{viewId && (
				<EditStaffTrainingTopicForm
					readOnly
					id={viewId}
					onClose={onCancelEdit}
				/>
			)}

			{editId && (
				<EditStaffTrainingTopicForm
					id={editId}
					onClose={onCancelEdit}
				/>
			)}
		</Page>
	);
};

StaffTrainingTopicList.propTypes = {
	role: PropTypes.string.isRequired,
	searchFilter: PropTypes.string,
	staffTrainingTopics: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	onSearchChanged: PropTypes.func.isRequired,
	onGetStaffTrainingTopicList: PropTypes.func.isRequired,
};

export default StaffTrainingTopicList;
