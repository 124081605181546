import * as R from "ramda";
import initialLoginState from "../models/login-state";
import fromAuth from "../actions/types";
import fromFrame from "frame/actions/types";

import {
	defaultFetchState,
	fetchRequested,
	fetchFailed,
	fetchSuccess,
} from "utils";

const defaultState = {
	...initialLoginState,
	...defaultFetchState,
};

const loginReducer = (state = R.clone(defaultState), action) => {
	switch (action.type) {
		case fromFrame.FRAME_SIGNOUT_CLICKED:
			return R.clone(defaultState);
		case fromAuth.AUTHORISATION_EMAIL_ADDRESS_CHANGED:
			return emailAddressChanged(R.clone(state), action);
		case fromAuth.AUTHORISATION_PASSWORD_CHANGED:
			return passwordChanged(R.clone(state), action);
		case fromAuth.AUTHORISATION_REMEMBER_ME_CHANGED:
			return rememberMeChanged(R.clone(state), action);
		// Login
		case fromAuth.AUTHORISATION_LOGIN_REQUESTED: {
			const nextState = loginRequested(R.clone(state), action);
			return fetchRequested(nextState);
		}
		case fromAuth.AUTHORISATION_LOGIN_FAILED: {
			const nextState = loginFailed(R.clone(state), action);
			return fetchFailed(nextState, action.payload);
		}
		case fromAuth.AUTHORISATION_LOGIN_FULFILLED:
			return fetchSuccess(state);
		default:
			return state;
	}
};

const emailAddressChanged = (state, action) => {
	state.errorMessage = "";
	state.emailAddress = action.payload;
	state.isValid = isValid(state.emailAddress, state.password);
	return state;
};

const passwordChanged = (state, action) => {
	state.errorMessage = "";
	state.password = action.payload;
	state.isValid = isValid(state.emailAddress, state.password);
	return state;
};

const loginRequested = (state, action) => {
	return state;
};

const loginFailed = (state, action) => {
	state.errorMessage = "Incorrect username or password";
	return state;
};

const rememberMeChanged = (state, action) => {
	state.rememberMe = action.payload;
	return state;
};

const isValid = (emailAddress, password) => {
	let val = false;
	if (
		emailAddress &&
		emailAddress.length > 1 &&
		password &&
		password.length > 1
	) {
		val = true;
	}
	return val;
};

export default loginReducer;
