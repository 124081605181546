import React from "react";
import PropTypes from "prop-types";
import { colors } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Label from "./label";

const useStyles = makeStyles((theme) => ({
	root: {
		lineHeight: "10px",
		fontSize: "10px",
		height: "auto",
		minWidth: "none",
		padding: theme.spacing(0.4, 1),
	},
}));

const SyncLabel = ({ children }) => {
	const classes = useStyles();

	return (
		<Label className={classes.root} color={colors.blue[600]}>
			{children}
		</Label>
	);
};

SyncLabel.propTypes = {
	children: PropTypes.node,
};

export default SyncLabel;
