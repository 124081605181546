import { connect } from "react-redux";
import CreateHealthSafetyLinks from "./health-safety-links";

const filterBySiteId = (siteId, list) =>
	list && list.filter((x) => x.siteId === siteId);

const mapStateToProps = ({ healthSafety, sites, organisations }, props) => ({
	siteId: props.siteId,
	numberOfRecords: {
		hazards: sites.currentSite.hazards.length,
		hazardousSubstances: sites.currentSite.substances.length,
		incidents: filterBySiteId(props.siteId, healthSafety.incidents.results)
			.length,
		inductions: filterBySiteId(
			props.siteId,
			healthSafety.inductions.results
		).length,
		safetyPlanChecklists: filterBySiteId(
			props.siteId,
			healthSafety.safetyPlanChecklists.results
		).length,
		safetyMinutes: filterBySiteId(
			props.siteId,
			healthSafety.safetyMinutes.results
		).length,
	},
	currentOrganisationGUID: organisations.currentOrganisation,
});

export default connect(mapStateToProps)(CreateHealthSafetyLinks);
