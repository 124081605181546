import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button, colors } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import LightboxWrapper from "frame/components/LightboxWrapper";
// import { writeImage } from './fileSystem'

const useStyles = makeStyles((theme) => ({
	clearButton: {
		color: theme.palette.white,
		backgroundColor: colors.red[600],
		"&:hover": {
			backgroundColor: colors.red[900],
		},
	},
	image: {
		height: "auto",
		width: "20%",
		margin: theme.spacing(2),
	},
	uploadArea: {
		textAlign: "center",
		borderRadius: "20px",
		border: "3px dashed",
		color: "#CEE0EB",
		borderColor: "#CEE0EB",
		padding: "0px 30px 30px 30px",
		margin: "10px",
		[theme.breakpoints.down("xs")]: {
			padding: "30px 30px 30px 30px",
		},
	},
	uploadLabel: {
		color: "#1c2b4a",
		font: "400 13.3333px Arial",
		letterSpacing: "normal",
		"&:hover": {
			cursor: "pointer",
		},
	},
	browseLink: {
		color: "#6688E5",
	},
	uploadFab: {
		float: "right",
		position: "relative",
		width: 40,
		height: 40,
		background: theme.palette.highlight,
		top: -25,
		right: -10,
		"@media (min-width: 960px)": {
			bottom: 20,
		},
		[theme.breakpoints.down("xs")]: {
			marginRight: theme.spacing(2),
			marginBottom: theme.spacing(-5),
		},
	},
	disabledFab: {
		background: theme.palette.background.grey,
	},
}));

const ImageUploadForm = ({ values, onChange }) => {
	const classes = useStyles();
	const fileUpload = useRef(null);
	const [base64List, setBase64List] = useState([]);
	const disabled = values.locked;

	const onFileChange = (attachments) =>
		onChange({
			attachments,
		});

	const encodeImageFileAsURL = (event) => {
		const attachmentList = [];

		for (let i = 0; i < event.target.files.length; i++) {
			let fileName = "";
			let fileExtension = "";
			const file = event.target.files[i];
			fileName = file.name;
			const lastDot = fileName.lastIndexOf(".");
			fileExtension = fileName.substring(lastDot + 1);

			const reader = new FileReader();
			reader.onloadend = () => {
				attachmentList.push({
					fileName,
					fileExtension,
					dataURL: reader.result,
				});
				setBase64List(base64List.concat(attachmentList));
			};
			reader.readAsDataURL(file);
			// write to storage
			// writeImage(file.name, file)
		}
		onFileChange(attachmentList);
	};

	const resetFile = () => {
		onFileChange([]);
		setBase64List([]);
		fileUpload.current.value = null;
	};

	const onSelect = () => {
		if (!disabled && fileUpload.current) {
			fileUpload.current.click();
		}
	};

	return (
		<div className={classes.uploadArea}>
			<Fab
				color="primary"
				aria-label="add"
				className={clsx(
					classes.uploadFab,
					disabled ? classes.disabledFab : ""
				)}
				onClick={onSelect}
			>
				<AddIcon />
			</Fab>
			<Grid container>
				<Grid item md={12} xs={12}>
					{!disabled && (
						<>
							<input
								id="fileUpload"
								type="file"
								accept="*"
								onChange={encodeImageFileAsURL}
								ref={fileUpload}
								style={{ display: "none" }}
								capture
								multiple
							/>
							{!base64List.length > 0 && (
								<label
									htmlFor="fileUpload"
									className={classes.uploadLabel}
								>
									Select a photo from your phone or{" "}
									<span className={classes.browseLink}>
										take a photo
									</span>
								</label>
							)}
						</>
					)}
				</Grid>
				<Grid item md={12} xs={12}>
					{base64List.length > 0 && (
						<LightboxWrapper attachments={base64List} />
					)}
				</Grid>
				<Grid item md={12} xs={12}>
					{base64List.length > 0 && (
						<Button
							className={classes.clearButton}
							variant="contained"
							onClick={resetFile}
						>
							Clear
						</Button>
					)}
				</Grid>
			</Grid>
		</div>
	);
};

ImageUploadForm.propTypes = {
	values: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};

export default ImageUploadForm;
