export const TASK_TYPE_LIST_REQUESTED = "TASK_TYPE_LIST_REQUESTED";
export const TASK_TYPE_LIST_SUCCEEDED = "TASK_TYPE_LIST_SUCCEEDED";
export const TASK_TYPE_LIST_FAILED = "TASK_TYPE_LIST_FAILED";

export const TASK_TYPE_CREATE_REQUESTED = "TASK_TYPE_CREATE_REQUESTED";
export const TASK_TYPE_CREATE_SUCCEEDED = "TASK_TYPE_CREATE_SUCCEEDED";
export const TASK_TYPE_CREATE_FAILED = "TASK_TYPE_CREATE_FAILED";

export const TASK_TYPE_UPDATE_REQUESTED = "TASK_TYPE_UPDATE_REQUESTED";
export const TASK_TYPE_UPDATE_SUCCEEDED = "TASK_TYPE_UPDATE_SUCCEEDED";
export const TASK_TYPE_UPDATE_FAILED = "TASK_TYPE_UPDATE_FAILED";

export const TASK_TYPE_DELETE_REQUESTED = "TASK_TYPE_DELETE_REQUESTED";
export const TASK_TYPE_DELETE_SUCCEEDED = "TASK_TYPE_DELETE_SUCCEEDED";
export const TASK_TYPE_DELETE_FAILED = "TASK_TYPE_DELETE_FAILED";

export default {
	TASK_TYPE_LIST_REQUESTED,
	TASK_TYPE_LIST_SUCCEEDED,
	TASK_TYPE_LIST_FAILED,
	TASK_TYPE_CREATE_REQUESTED,
	TASK_TYPE_CREATE_SUCCEEDED,
	TASK_TYPE_CREATE_FAILED,
	TASK_TYPE_UPDATE_REQUESTED,
	TASK_TYPE_UPDATE_SUCCEEDED,
	TASK_TYPE_UPDATE_FAILED,
	TASK_TYPE_DELETE_REQUESTED,
	TASK_TYPE_DELETE_SUCCEEDED,
	TASK_TYPE_DELETE_FAILED
};