import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Card, CardContent, makeStyles, Grid, Typography, Chip } from "@material-ui/core";
import {
	formatDate,
	isNotNullOrEmpty,
	capitalizeFirstLetter,
	isStaffUser,
	useLookup,
} from "utils";
import {
	CrudOptions,
	FormActions,
	FormButton,
	FormSubmitButton,
} from "frame/components";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(3),
		},
	},
	card: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
	},
	content: {
		padding: theme.spacing(1, 3),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(4),
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
}));

const TaskTypeListing = ({
	taskTypes = [],
	isLoading = false,
	onEdit,
	onDelete
}) => {
	const classes = useStyles();

	return (
		<>
			{
				taskTypes && taskTypes.length > 0 && !isLoading ?
					taskTypes.map((taskType, index) => (
						<Card
							data-cy={`staff-$`}
							className={clsx(classes.card)}
							key={index}
						>
							<CardContent className={classes.content}>
								<Grid
									container
									spacing={2}
									className={classes.container}
								>
									<Grid item lg={8} md={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
										Type Name
										</Typography>
										<Typography variant="h5">
											{taskType.taskType || "-"}
										</Typography>
									</Grid>
									<Grid item lg={4} md={6} xs={12}>
										<Typography variant="body2" className={classes.label}>
										Status
										</Typography>
										<Chip
											className={
												taskType.active
													? classes.success
													: classes.error
											}
											size="small"
											label={
												taskType.active
													? "Active"
													: "Inactive"
											}
										/>
									</Grid>
								</Grid>
								<div className="ignore">
									<CrudOptions
										onDelete={() => onDelete(taskType.id)}
										onEdit={() => onEdit(taskType.id)}
									/>
								</div>
							</CardContent>
						</Card>
					))
					:
					<Card
						data-cy={`staff-$`}
						className={clsx(classes.card, undefined)}
					>
						<CardContent className={classes.content}>
							<Grid
								container
								spacing={2}
								className={clsx(
									classes.container,
									classes.clickable
								)}
							>
								<Grid item lg={3} md={6} xs={12}>
									<Typography
										className={classes.label}
										variant="body2"
									>
										{ isLoading ? "Loading..." : "No task types to show" }
									</Typography>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
			}
		</>
	);
};

TaskTypeListing.propTypes = {
	taskTypes: PropTypes.array,
	isLoading: PropTypes.bool,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func
};

export default TaskTypeListing;
