import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
} from "frame/components";

import {
	strip,
	SelectHazardsForm,
	PeopleForm,
	SiteForm,
	UploadFormCard,
	ExtraNotesForm,
	EmailRecipientsForm,
} from "features/forms";

import ReadOnlyForm from "./read-only-form";

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		flexDirection: "row",
		padding: theme.spacing(3, 3, 10, 3),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
}));

const hydrate = ({
	id,
	siteId,
	createdAt,
	people,
	otherPeople,
	hazards,
	hazardousSubstances,
	attachments,
	recipients,
	recipientsOther,
	hazardNotes,
	notes,
	appendNotes,
	organisationId,
}) => ({
	id,
	siteId,
	createdAt,
	people,
	otherPeople,
	hazards,
	substances: hazardousSubstances,
	hazardNotes,
	notes,
	appendNotes,
	attachments,
	recipients,
	recipientsOther,
	currentOrganisation: organisationId,
});

/* edit induction is only available to born super user account only */
const Form = ({
	id,
	values,
	readOnly,

	// list data
	hazardList,
	hazardousSubstanceList,
	inductionList,
	siteList,

	// functions
	onSave,
	onClose,
	onChange,
	onReset,
}) => {
	const classes = useStyles();

	const [loaded, setLoaded] = useState(false);
	const [siteHazards, setSiteHazards] = useState([]);
	const [siteSubstances, setSiteSubstances] = useState([]);

	const open = values && values.id;

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchData() {
			setLoaded(true);
			const induction =
				inductionList && inductionList.filter((i) => i.id === id)[0];

			if (induction) {
				onChange(hydrate(induction));
				const inductionSite = siteList.filter(
					(s) => s.siteId === induction.siteId
				)[0];

				const siteHazards =
					inductionSite &&
					inductionSite.hazards &&
					inductionSite.hazards.filter(
						(node) => node.status !== "Archived"
					);

				const siteSubstances =
					inductionSite &&
					inductionSite.substances &&
					inductionSite.substances.filter(
						(node) => node.status !== "Archived"
					);

				setSiteHazards(siteHazards || []);
				setSiteSubstances(siteSubstances || []);
			}
		}
		if (id) {
			setLoaded(true);
			fetchData();
		}
	}, [id, loaded, onChange, inductionList, siteList]);

	useEffect(() => {
		values.save.success && onClose();
	}, [values.save.success, onClose]);

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
	};

	const onCancel = () => {
		onReset();
		onClose();
	};

	if (!open) {
		return null;
	}

	const disableSave = false;

	return (
		<Dialog
			open={open ? true : false}
			aria-labelledby="Induction Meetings"
			// breadcrumbs="Induction"
			title={
				readOnly ? "View Induction Meeting" : "Edit Induction Meeting"
			}
			onClose={onCancel}
		>
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				{readOnly ? (
					<ReadOnlyForm
						values={values}
						hazardList={hazardList}
						hazardousSubstanceList={hazardousSubstanceList}
					/>
				) : (
					<>
						<SiteForm values={values} onChange={onChange} />
						<PeopleForm
							defaultStaffRole="Staff"
							values={values}
							onChange={onChange}
						/>
						<SelectHazardsForm
							editMode
							values={values}
							onChange={onChange}
							siteHazards={siteHazards}
							siteSubstances={siteSubstances}
							hazardList={hazardList}
							hazardousSubstanceList={hazardousSubstanceList}
						/>
						<UploadFormCard
							title={"Add Attachments"}
							values={values}
							onChange={onChange}
						/>
						<ExtraNotesForm values={values} onChange={onChange} />
						<EmailRecipientsForm
							values={values}
							onChange={onChange}
						/>
					</>
				)}
				<FormActions className={classes.actions}>
					{!readOnly && (
						<FormSubmitButton
							disabled={values.locked || disableSave}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
					)}
					<FormButton
						disabled={values.save.saving}
						onClick={onCancel}
					>
						{readOnly ? "Close" : "Cancel"}
					</FormButton>
				</FormActions>
			</ValidatorForm>
		</Dialog>
	);
};

Form.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	readOnly: PropTypes.bool,

	// list data
	hazardList: PropTypes.array.isRequired,
	hazardousSubstanceList: PropTypes.array.isRequired,
	inductionList: PropTypes.array.isRequired,
	siteList: PropTypes.array.isRequired,

	// functions
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

export default Form;
