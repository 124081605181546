import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	PageHeaderAddButton,
	SearchBar,
	Pagination,
	PaginationHeader,
	ListFilterOptions,
	PopupSnackbar,
	SendEmailModal,
} from "frame/components";
import { makeStyles } from "@material-ui/styles";
import DocumentPackListItem from "./document-pack-list-item";
import { INFO_HOME_ROUTE } from "features/home/home-routes";
import DocumentPackAddEditModal from "./document-pack-add-edit-modal";
import DocumentPackViewModal from "./document-pack-view-modal";
import {
	createDocumentPack,
	getAllDocumentPacks,
	updateDocumentPack,
	archiveDocumentPack,
	unarchiveDocumentPack,
	deleteDocumentPack,
	emailDocumentPack,
} from "../api";
import { isAccountAdminUser, isSuperAdminUser } from "utils";
import { GENERIC_ERROR_MESSAGE } from "utils";

const mapStateToProps = ({ organisations, profile }) => ({
	currentOrganisationGUID: organisations.currentOrganisation,
	profile,
});

const mapDispatchToProps = {};

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(3),
		},
	},
	filter: {
		display: "flex",
	},
}));

const DocumentPackList = ({ currentOrganisationGUID, profile }) => {
	const classes = useStyles();
	const [documentPackList, setDocumentPackList] = useState([]);
	const [selectedDocumentPackIndex, setSelectedDocumentPackIndex] =
		useState(0);
	const [showAddEditModal, setShowAddEditModal] = useState(false);
	const [addEditModalMode, setAddEditModalMode] = useState("Add");
	const [isSavingAddEdit, setIsSavingAddEdit] = useState(false);
	const [showViewModal, setShowViewModal] = useState(false);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [isSendingEmail, setIsSendingEmail] = useState(false);
	const [paginationState, setPaginationState] = useState({
		ascending: true,
		orderBy: "name",
		page: 1,
		pageSize: 5,
	});
	const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(0);
	const [recordCount, setRecordCount] = useState(0);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	const getDocumentPackList = (pagination) => {
		getAllDocumentPacks(currentOrganisationGUID, pagination)
			.then((response) => {
				setDocumentPackList(response.data.results);
				setTotalNumberOfPages(response.data.totalNumberOfPages);
				setTotalNumberOfRecords(response.data.totalNumberOfRecords);
				setRecordCount(response.data.pageSize);
			})
			.catch((error) => {
				handleApiError(error);
				setDocumentPackList([]);
				setTotalNumberOfPages(1);
				setTotalNumberOfRecords(0);
				setRecordCount(0);
			});
	};

	const handleApiError = (error) => {
		if (error.response && error.response.status === "401") {
			setErrorMessage("You are not authorized to perform this action.");
		} else if (error.response && error.response.status === "404") {
			setErrorMessage("The record no longer exists.");
			getDocumentPackList(paginationState);
		} else {
			setErrorMessage(GENERIC_ERROR_MESSAGE);
		}
	};

	useEffect(() => {
		getDocumentPackList(paginationState);
	}, []);

	return (
		<Page title="Documents">
			<ScrollToTopOnMount />
			<PageHeaderAddButton
				title="Documents"
				addTabTitle="Add Document"
				onAdd={() => {
					setShowAddEditModal(true);
					setAddEditModalMode("Add");
				}}
				staffUserHide={false}
				backActionPath={INFO_HOME_ROUTE}
			/>
			<div className={classes.filter}>
				<SearchBar
					onSearch={(searchString) => {
						const newPaginationState = {
							...paginationState,
							searchKey: searchString,
							page: 1,
						};

						setPaginationState(newPaginationState);
						getDocumentPackList(newPaginationState);
					}}
				/>
				<ListFilterOptions
					useArchive={true}
					filterKey="Status"
					onGetList={(newPaginationState) => {
						const mergedPaginationState = {
							...paginationState,
							...newPaginationState,
						};

						setPaginationState(mergedPaginationState);
						getDocumentPackList(mergedPaginationState);
					}}
					pagination={paginationState}
					orderBy="name"
				/>
			</div>
			<PageContent pad>
				<div className={classes.body}>
					<PaginationHeader
						pagination={{
							...paginationState,
							totalNumberOfPages: totalNumberOfPages,
							totalNumberOfRecords: totalNumberOfRecords,
							pageSize: recordCount,
							pageNumber: paginationState.page,
						}}
					/>
					{documentPackList.map((documentPack, index) => (
						<DocumentPackListItem
							key={documentPack.documentPackId}
							documentPack={documentPack}
							showDeleteOption={
								isAccountAdminUser(profile.role) ||
								isSuperAdminUser(profile.role)
							}
							onView={() => {
								setSelectedDocumentPackIndex(index);
								setShowViewModal(true);
							}}
							onEdit={() => {
								setSelectedDocumentPackIndex(index);
								setShowAddEditModal(true);
								setAddEditModalMode("Edit");
							}}
							onDelete={() => {
								deleteDocumentPack(
									currentOrganisationGUID,
									documentPack.documentPackId
								)
									.then(() => {
										if (
											documentPackList.length <= 1 &&
											paginationState.page > 1
										) {
											getDocumentPackList({
												...paginationState,
												page: paginationState.page - 1,
											});
										} else {
											getDocumentPackList(
												paginationState
											);
										}

										setSuccessMessage(
											"Document deleted successfully."
										);
									})
									.catch(handleApiError);
							}}
							onArchive={() => {
								archiveDocumentPack(
									currentOrganisationGUID,
									documentPack.documentPackId
								)
									.then(() => {
										getDocumentPackList(paginationState);
									})
									.catch(handleApiError);
							}}
							onUnarchive={() => {
								unarchiveDocumentPack(
									currentOrganisationGUID,
									documentPack.documentPackId
								)
									.then(() => {
										getDocumentPackList(paginationState);
									})
									.catch(handleApiError);
							}}
							onEmail={() => {
								setSelectedDocumentPackIndex(index);
								setShowEmailModal(true);
							}}
						/>
					))}
					<PopupSnackbar
						message={errorMessage}
						coloured
						error
						onExited={() => setErrorMessage("")}
					/>
					<PopupSnackbar
						message={successMessage}
						coloured
						success
						onExited={() => setSuccessMessage("")}
					/>
				</div>
				<Pagination
					disabledOnLoadFetch={true}
					onGetList={(newPaginationState) => {
						const mergedPaginationState = {
							...paginationState,
							...newPaginationState,
						};

						setPaginationState(mergedPaginationState);
						getDocumentPackList(mergedPaginationState);
					}}
					pagination={{
						...paginationState,
						totalNumberOfPages: totalNumberOfPages,
						totalNumberOfRecords: totalNumberOfRecords,
						queryPageSize: paginationState.pageSize,
					}}
					orderBy={"name"}
					searchKey={paginationState.searchKey}
				/>
			</PageContent>
			<DocumentPackAddEditModal
				isOpen={showAddEditModal}
				documentPack={
					documentPackList[selectedDocumentPackIndex] || {
						attachments: [],
					}
				}
				onCancel={() => {
					if (!isSavingAddEdit) {
						setShowAddEditModal(false);
					}
				}}
				onSave={(documentPack) => {
					setIsSavingAddEdit(true);

					let promise;

					if (addEditModalMode === "Add") {
						promise = createDocumentPack(
							currentOrganisationGUID,
							documentPack
						);
					} else {
						promise = updateDocumentPack(
							currentOrganisationGUID,
							documentPack
						);
					}

					promise
						.then(() => {
							getDocumentPackList(paginationState);
							setSuccessMessage("Document saved successfully.");
						})
						.catch(handleApiError)
						.finally(() => {
							setIsSavingAddEdit(false);
							setShowAddEditModal(false);
						});
				}}
				isSaving={isSavingAddEdit}
				mode={addEditModalMode}
			/>
			<DocumentPackViewModal
				isOpen={showViewModal}
				documentPack={
					documentPackList[selectedDocumentPackIndex] || {
						attachments: [],
					}
				}
				onClose={() => setShowViewModal(false)}
			/>
			<SendEmailModal
				isOpen={showEmailModal}
				title={
					documentPackList[selectedDocumentPackIndex]
						? "Email Document: " +
						  documentPackList[selectedDocumentPackIndex].name
						: ""
				}
				onClose={() => setShowEmailModal(false)}
				onSend={(selectedRecipients) => {
					setIsSendingEmail(true);
					emailDocumentPack(
						currentOrganisationGUID,
						documentPackList[selectedDocumentPackIndex]
							.documentPackId,
						selectedRecipients.recipients,
						[
							...selectedRecipients.recipientsOther,
							...selectedRecipients.recipientsCustomerSites,
						]
					)
						.then(() => {
							setSuccessMessage("Emails sent successfully.");
						})
						.catch(handleApiError)
						.finally(() => {
							setIsSendingEmail(false);
							setShowEmailModal(false);
						});
				}}
				isSaving={isSendingEmail}
				emailListType={"allCustomerSiteRecord"}
			/>
		</Page>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPackList);
