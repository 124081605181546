import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Grid,
	Checkbox,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	FormControlLabel,
} from "@material-ui/core";

import { Dialog, FormButton, ValueText } from "frame/components";
import { capitalize } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {},
	review: {
		marginBottom: theme.spacing(5),
	},
	fieldLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(-1.2),
	},
	label: {
		margin: 0,
	},
	formControlLabel: {
		userSelect: "none",
		marginLeft: theme.spacing(1),
	},
}));

const HazardousSubstanceDetailsDialog = ({
	data,
	onSelect,
	onClose,
	readOnly,
}) => {
	const classes = useStyles();
	const [agreed, setAgreed] = useState(false);

	const onToggleAgreed = () => setAgreed(!agreed);

	return (
		<Dialog
			open
			aria-labelledby="Hazardous Substance Substance"
			breadcrumbs="Induction Meeting"
			title="Hazardous Substance Review"
			onClose={onClose}
		>
			<Card className={classes.review}>
				<CardHeader
					title={data.name}
					titleTypographyProps={{ variant: "h4" }}
				/>
				<CardContent>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<ValueText
								label="Substance Type"
								value={
									data.substanceType
										? data.substanceType
										: "-"
								}
								options={{ large: true, bold: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ValueText
								label="Supplier SDS Report Held"
								value={
									data.supplierSDSReportHeld
										? capitalize(data.supplierSDSReportHeld)
										: "-"
								}
								options={{ large: true, bold: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ValueText
								label="Initial Risk Assessment"
								value={
									data.initialRiskAssessment
										? capitalize(data.initialRiskAssessment)
										: "-"
								}
								options={{ large: true, bold: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ValueText
								label="Residual Risk Assessment"
								value={
									data.residualRiskAssessment
										? capitalize(
												data.residualRiskAssessment
										  )
										: "-"
								}
								options={{ large: true, bold: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ValueText
								label="Protective Clothing Required"
								value={
									data.protectiveClothingRequired
										? data.protectiveClothingRequired
										: "-"
								}
								options={{ large: true, bold: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ValueText
								label="Action Recommended"
								value={
									data.actionRecommended
										? data.actionRecommended
										: "-"
								}
								options={{ large: true, bold: true }}
							/>
						</Grid>

						{!readOnly && (
							<Grid item xs={12}>
								<div className={classes.fieldLabel}>
									<FormControlLabel
										className={classes.formControlLabel}
										control={
											<Checkbox
												checked={agreed}
												onChange={onToggleAgreed}
												value="yes"
												inputProps={{
													"aria-label": `I've read and understood the hazardous substance checkbox`,
												}}
											/>
										} // eslint-disable-next-line
										label={`I\'ve read and understood the hazardous substance.`}
									/>
								</div>
							</Grid>
						)}
					</Grid>
				</CardContent>
				<CardActions>
					<FormButton
						color="brand"
						disabled={!agreed && !readOnly}
						onClick={onSelect}
					>
						Ok
					</FormButton>
					<FormButton onClick={onClose}>Cancel</FormButton>
				</CardActions>
			</Card>
		</Dialog>
	);
};

HazardousSubstanceDetailsDialog.propTypes = {
	data: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
};

export default HazardousSubstanceDetailsDialog;
