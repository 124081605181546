import { connect } from "react-redux";
import Sites from "./sites";
import { onEdit, onDelete } from "features/sites";

const mapStateToProps = ({ sites }) => ({
	siteList: sites.pagination?.results,
});

const mapDispatchToProps = {
	onEdit,
	onDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sites);
