import Types from "./types";

// on changed actions
export const onContactPersonChanged = (payload) => ({
	type: Types.CUSTOMERS_CONTACT_PERSON_CHANGED,
	payload,
});

export const onCustomerNameChanged = (payload) => ({
	type: Types.CUSTOMERS_CUSTOMER_NAME_CHANGED,
	payload,
});

export const onEmailAddressChanged = (payload) => ({
	type: Types.CUSTOMERS_EMAIL_ADDRESS_CHANGED,
	payload,
});

export const onStatusChanged = (payload) => ({
	type: Types.CUSTOMERS_STATUS_CHANGED,
	payload,
});

export const onConsultantChanged = (payload) => ({
	type: Types.CUSTOMERS_CONSULTANT_CHANGED,
	payload,
});

export const onAdd = () => ({
	type: Types.CUSTOMERS_ADD_CLICKED,
});

export const onAddFailed = (error, displayMessage) => ({
	type: Types.CUSTOMERS_ADD_FAILED,
	payload: { error, displayMessage },
});

export const onAddSuccess = (payload) => ({
	type: Types.CUSTOMERS_ADD_SUCCEEDED,
	payload,
});

export const onClose = () => ({
	type: Types.CUSTOMERS_CLOSE_CLICKED,
});

export const onEdit = (payload) => ({
	type: Types.CUSTOMERS_EDIT_CLICKED,
	payload,
});

export const onGetCustomerDetails = (payload) => ({
	type: Types.CUSTOMERS_DETAILS,
	payload,
});

export const onListRequested = (payload) => ({
	type: Types.CUSTOMERS_LIST_REQUESTED,
	payload,
});

export const onPagedListRequested = (payload) => ({
	type: Types.CUSTOMERS_PAGED_LIST_REQUESTED,
	payload,
});

export const onListRequestedFailed = (payload) => ({
	type: Types.CUSTOMERS_LIST_FAILED,
	payload,
});

export const onListRequestedSuccess = (payload) => ({
	type: Types.CUSTOMERS_LIST_RECEIVED,
	payload,
});

export const onDelete = (payload) => ({
	type: Types.CUSTOMERS_REMOVE_CLICKED,
	payload,
});

export const onDeleteFailed = (payload) => ({
	type: Types.CUSTOMERS_REMOVE_FAILED,
	payload,
});

export const onDeleteSuccess = (payload) => ({
	type: Types.CUSTOMERS_REMOVE_SUCCEEDED,
	payload,
});

export const onSave = (payload) => ({
	type: Types.CUSTOMERS_SAVE_CLICKED,
	payload,
});

export const onSearchChanged = (payload) => ({
	type: Types.CUSTOMERS_SEARCH_CHANGED,
	payload,
});

export const onSearchClear = () => ({
	type: Types.CUSTOMERS_SEARCH_CLEAR,
});
