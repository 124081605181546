export const STAFF_ROUTE = `/staff`;
export const STAFF_MEMBER_ROUTE = `/staff/:staffId`;
export const STAFF_MEMBER_TAB_ROUTE = `${STAFF_MEMBER_ROUTE}/:staffTab?`;

export const STAFF_PATH = `/staff`;
export const STAFF_MEMBER_PATH = `/staff`;

// Tab paths
export const STAFF_MEMBER_TRAINING_PATH = `training`;
export const STAFF_MEMBER_NOTES_PATH = `notes`;
export const STAFF_MEMBER_SUMMARY_PATH = `summary`;

// Tab specifics
export const STAFF_MEMBER_TRAINING_ROUTE = `${STAFF_MEMBER_ROUTE}/${STAFF_MEMBER_TRAINING_PATH}`;
export const STAFF_MEMBER_NOTES_ROUTE = `${STAFF_MEMBER_ROUTE}/${STAFF_MEMBER_NOTES_PATH}`;
export const STAFF_MEMBBER_SUMMARY_ROUTE = `${STAFF_MEMBER_ROUTE}/${STAFF_MEMBER_SUMMARY_PATH}`;

export const STAFF_SEARCH_CLEAR = "STAFF_SEARCH_CLEAR";
export const STAFF_SEARCH_CHANGED = "STAFF_SEARCH_CHANGED";

export const STAFF_ADD_CLICKED = "STAFF_ADD_CLICKED";
export const STAFF_ADD_FAILED = "STAFF_ADD_FAILED";
export const STAFF_ADD_SUCCEEDED = "STAFF_ADD_SUCCEEDED";
export const STAFF_EDIT_CLICKED = "STAFF_EDIT_CLICKED";
export const STAFF_SAVE_CLICKED = "STAFF_SAVE_CLICKED";
export const STAFF_CLOSE_CLICKED = "STAFF_CLOSE_CLICKED";

export const STAFF_ORGANISATION_ROLE_CHANGED =
	"STAFF_ORGANISATION_ROLE_CHANGED";
export const STAFF_ORGANISATION_CHANGED = "STAFF_ORGANISATION_CHANGED";
export const STAFF_FIRSTNAME_CHANGED = "STAFF_FIRSTNAME_CHANGED";
export const STAFF_LASTNAME_CHANGED = "STAFF_LASTNAME_CHANGED";
export const STAFF_PASSWORD_CHANGED = "STAFF_PASSWORD_CHANGED";
export const STAFF_PHONE_CHANGED = "STAFF_PHONE_CHANGED";
export const STAFF_EMAIL_CHANGED = "STAFF_EMAIL_CHANGED";
export const STAFF_EMAIL_INVITE_TOGGLED = "STAFF_EMAIL_INVITE_TOGGLED";
export const STAFF_STATUS_CHANGED = "STAFF_STATUS_CHANGED";
export const STAFF_TYPE_CHANGED = "STAFF_TYPE_CHANGED";
export const STAFF_STAFF_ID_NUMBER_CHANGED = "STAFF_STAFF_ID_NUMBER_CHANGED";
export const STAFF_SUPERVISOR_CHANGED = "STAFF_SUPERVISOR_CHANGED";
export const STAFF_NOTES_CHANGED = "STAFF_NOTES_CHANGED";

export const STAFF_LOOKUP = "STAFF_LOOKUP";
export const STAFF_LOOKUP_FAILED = "STAFF_LOOKUP_FAILED";
export const STAFF_LOOKUP_SUCCESS = "STAFF_LOOKUP_SUCCESS";
export const STAFF_FILE_CHANGED = "STAFF_FILE_CHANGED";

export const STAFF_PAGED_LIST_REQUESTED = "STAFF_PAGED_LIST_REQUESTED";
export const STAFF_LIST_REQUESTED = "STAFF_LIST_REQUESTED";
export const STAFF_LIST_RECEIVED = "STAFF_LIST_RECEIVED";
export const STAFF_LIST_FAILED = "STAFF_LIST_FAILED";
export const STAFF_LIST_ONLOADED = "STAFF_LIST_ONLOADED";

export const STAFF_IMPERSONATE_CLICKED = "STAFF_IMPERSONATE_CLICKED";
export const STAFF_IMPERSONATE_FAILED = "STAFF_IMPERSONATE_FAILED";

export const STAFF_BATCH_ADD = "STAFF_BATCH_ADD";
export const STAFF_BATCH_ADD_FAILED = "STAFF_BATCH_ADD_FAILED";
export const STAFF_BATCH_ADD_SUCCEEDED = "STAFF_BATCH_ADD_SUCCEEDED";

export default {
	// Routes
	STAFF_ROUTE,
	STAFF_MEMBER_ROUTE,
	STAFF_MEMBER_TAB_ROUTE,

	STAFF_MEMBER_TRAINING_ROUTE,
	STAFF_MEMBER_NOTES_ROUTE,
	STAFF_MEMBBER_SUMMARY_ROUTE,

	// Paths
	STAFF_PATH,
	STAFF_MEMBER_PATH,

	STAFF_MEMBER_NOTES_PATH,
	STAFF_MEMBER_SUMMARY_PATH,

	// Actions
	STAFF_LOOKUP,
	STAFF_LOOKUP_FAILED,
	STAFF_LOOKUP_SUCCESS,
	STAFF_SEARCH_CHANGED,
	STAFF_SEARCH_CLEAR,
	STAFF_ADD_CLICKED,
	STAFF_ADD_FAILED,
	STAFF_EDIT_CLICKED,
	STAFF_SAVE_CLICKED,
	STAFF_ADD_SUCCEEDED,
	STAFF_CLOSE_CLICKED,

	STAFF_ORGANISATION_CHANGED,
	STAFF_ORGANISATION_ROLE_CHANGED,
	STAFF_FIRSTNAME_CHANGED,
	STAFF_LASTNAME_CHANGED,
	STAFF_PASSWORD_CHANGED,
	STAFF_PHONE_CHANGED,
	STAFF_EMAIL_CHANGED,
	STAFF_EMAIL_INVITE_TOGGLED,
	STAFF_STATUS_CHANGED,
	STAFF_TYPE_CHANGED,
	STAFF_STAFF_ID_NUMBER_CHANGED,
	STAFF_SUPERVISOR_CHANGED,
	STAFF_NOTES_CHANGED,
	STAFF_FILE_CHANGED,

	STAFF_PAGED_LIST_REQUESTED,
	STAFF_LIST_REQUESTED,
	STAFF_LIST_RECEIVED,
	STAFF_LIST_FAILED,
	STAFF_LIST_ONLOADED,

	STAFF_IMPERSONATE_CLICKED,
	STAFF_IMPERSONATE_FAILED,

	STAFF_BATCH_ADD,
	STAFF_BATCH_ADD_FAILED,
	STAFF_BATCH_ADD_SUCCEEDED,
};
