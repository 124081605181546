import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useContext } from "react";
import { __RouterContext } from "react-router";

const useRouter = () => useContext(__RouterContext);

const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const Page = ({ title, children, ...rest }) => {
	const router = useRouter();
	useEffect(() => {
		if (NODE_ENV !== "production") {
			return;
		}
		if (window.gtag) {
			window.gtag("config", GA_MEASUREMENT_ID, {
				page_path: router.location.pathname,
				page_name: title,
			});
		}
	}, [title, router]);

	return (
		<div {...rest}>
			<Helmet>
				<title>ForTheRecord {title ? "- " + title : "App"}</title>
			</Helmet>
			{children}
		</div>
	);
};

Page.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
};

export default Page;
