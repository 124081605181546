import { connect } from "react-redux";
import IncidentSiteList from "./incident-site-list";
import { onRemove } from "../../actions";

const mapStateToProps = ({ healthSafety, profile }) => {
	return {
		role: profile.role,
	};
};

const mapDispatchToProps = {
	onDelete: onRemove,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentSiteList);
