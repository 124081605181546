import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clip from "text-clipper";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import {
	isStaffUser,
	formatDate,
	isBornAccount,
	createRowSelector,
} from "utils";
import { CrudOptions } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
		"&:hover": {
			cursor: "pointer",
		},
	},
	container: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(3),
		},
	},
	label: {
		color: "#999999",
	},
}));

const StaffTrainingTopicCard = ({
	trainingTopic,
	role,
	onView,
	onEdit,
	onDelete,
	email,
}) => {
	const classes = useStyles();
	const onRowSelect = createRowSelector(onView);

	const id = {
		id: trainingTopic.id,
		form: "staff-training-topic",
	};

	const trainingTopicDuration = () => {
		let duration = "";
		const isIndefinite =
			trainingTopic.validMeasurement === "Unlimited/Indefinite";
		if (isIndefinite) {
			return trainingTopic.validMeasurement;
		}
		duration += trainingTopic.validDuration
			? `${trainingTopic.validDuration}`
			: "";
		duration += trainingTopic.validMeasurement
			? ` ${trainingTopic.validMeasurement}`
			: "";

		return duration !== "" ? duration : "";
	};

	return (
		<Card className={classes.root} onClick={onRowSelect}>
			<CardContent className={classes.content}>
				<Grid className={classes.container} container spacing={2}>
					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Date
						</Typography>
						<Typography variant="h5">
							{formatDate(trainingTopic.createdAt)}
						</Typography>
					</Grid>
					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Training Topic Name
						</Typography>
						<Typography variant="h5">
							{clip(trainingTopic.name, 140)}
						</Typography>
					</Grid>
					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Training Topic Valid Duration
						</Typography>
						<Typography variant="h5">
							{trainingTopicDuration()}
						</Typography>
					</Grid>
					{/* <Grid item md={3} xs={6}>
            <Typography variant="body2" className={classes.label}>
              Training Topic Guidelines
            </Typography>
            <Typography variant="h5">
            {isValidContentState(trainingTopic.guidelines) ? (
              <MUIRichTextEditor
                readOnly
                controls={[]}
                value={trainingTopic.guidelines}
              />
            ) : '-'}
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant="body2" className={classes.label}>
              Emails
            </Typography>
            <EmailListing
              emails={trainingTopic.recipients}
              OtherEmails={trainingTopic.recipientsOther}
            />
          </Grid> */}
					<div className="ignore">
						{!isStaffUser(role) && (
							<CrudOptions
								onEdit={onEdit}
								onDelete={
									isBornAccount(email)
										? () => onDelete(id)
										: null
								}
							/>
						)}
					</div>
				</Grid>
			</CardContent>
		</Card>
	);
};

StaffTrainingTopicCard.propTypes = {
	trainingTopic: PropTypes.object.isRequired,
	role: PropTypes.string.isRequired,
	onView: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

const mapStateToProps = ({ profile }) => ({
	email: profile.email,
});

export default connect(mapStateToProps)(StaffTrainingTopicCard);
