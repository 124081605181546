import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";

import { RadioListInput } from "frame/components/input";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(1, 0, 3, 0),
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0),
		height: 2,
	},
	small: {
		fontSize: "85%",
	},
}));

const HazardManagementForm = ({
	className,
	disabled,
	values,
	onSelectChange,
}) => {
	const classes = useStyles();

	const options = [
		{
			label: "Yes",
			value: "yes",
		},
		{
			label: "NA",
			value: "na",
		},
	];

	return (
		<CardContent className={classes.root}>
			<Divider className={classes.divider} />
			<CardHeader
				className={classes.heading}
				title="Hazard Management"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						We will maintain an on-site hazard Register of all
						existing and new hazards and appropriate control.
					</Typography>
					<RadioListInput
						data-cy={"willMaintainOnSiteHazardRegister"}
						disabled={disabled}
						initialValue={values.willMaintainOnSiteHazardRegister}
						options={options}
						onChange={onSelectChange(
							"willMaintainOnSiteHazardRegister"
						)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						We will prepare an online Task analysis covering all
						significant hazards associated with our works, in
						conjunction with employees, and give it to site
						management before any work involving that hazard
						commences on site.
					</Typography>
					<RadioListInput
						data-cy={"willPrepareTaskAnalysis"}
						disabled={disabled}
						initialValue={values.willPrepareTaskAnalysis}
						options={options}
						onChange={onSelectChange("willPrepareTaskAnalysis")}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						Are there hazardous products / processes associated with
						our other person.
						<br />
						<span className={classes.small}>
							(If yes, please fill out Hazardous/Dangerous
							Substances worksheet).
						</span>
					</Typography>
					<RadioListInput
						data-cy={"assosicatedWithOtherWorks"}
						disabled={disabled}
						initialValue={values.assosicatedWithOtherWorks}
						options={options}
						onChange={onSelectChange("assosicatedWithOtherWorks")}
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

HazardManagementForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onSelectChange: PropTypes.func.isRequired,
};

export default HazardManagementForm;
