import { connect } from "react-redux";
import Form from "./form";

import {
	onCloseAddHazardToSite,
	onAddHazardToSiteValueChange,
	onAddHazardToSiteSave,
} from "features/sites";

const mapStateToProps = ({ sites, profile }, props) => ({
	values: sites.currentSite,
	isAddHazardToSiteModalShowing: sites.isAddHazardToSiteModalShowing,
	email: profile.email,
	siteId: props.siteId,
	preExistingSiteHazards: props.preExistingSiteHazards,
});

const mapDispatchToProps = {
	onChange: onAddHazardToSiteValueChange,
	onSave: onAddHazardToSiteSave,
	onClose: onCloseAddHazardToSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
