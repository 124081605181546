import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(1, 0),
		padding: theme.spacing(1, 2),
		// borderRadius: 4,
		...theme.typography.body1,
	},
	error: {
		color: theme.palette.error.main,
		borderLeft: `3px solid ${theme.palette.error.main}`,
		// color: theme.palette.white,
		// backgroundColor: theme.palette.error.main,
	},
	success: {
		color: theme.palette.success.main,
		borderLeft: `3px solid ${theme.palette.success.main}`,
		// color: theme.palette.primary.main,
		// backgroundColor: theme.palette.highlight,
	},
}));

const Feedback = ({ active, children, color, disableTimeout }) => {
	const classes = useStyles();
	const [visible, setVisible] = useState(active);
	const theme = clsx(
		classes.root,
		color === "error" ? classes.error : "",
		color === "success" ? classes.success : ""
	);

	useEffect(() => {
		if (!disableTimeout) {
			setVisible(active);
			let id;

			if (active) {
				id = setTimeout(() => {
					setVisible(false);
				}, 10 * 1000);
			}

			return () => {
				clearTimeout(id);
			};
		}
	}, [active, disableTimeout]);

	if (!visible) {
		return null;
	}

	return (
		<div className={theme}>
			<span>{children}</span>
		</div>
	);
};

Feedback.propTypes = {
	active: PropTypes.bool,
	children: PropTypes.string,
	color: PropTypes.oneOf(["success", "error"]),
	disableTimeout: PropTypes.bool,
};

export default Feedback;
