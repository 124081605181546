import Types from "../actions/types";
import { ofType } from "redux-observable";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { authHeaders, tokenExists, getOrganisationBaseUrl } from "frame";

import {
	organisationDeleteSuccess,
	organisationDeleteFailed,
	organisationFetch,
} from "../actions";

export const organisationDeleteEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.ORGANISATION_DELETE),
		filter(() => tokenExists()),
		mergeMap(({ payload }) => {
			return ajax
				.delete(
					`${getOrganisationBaseUrl(
						state$.value
					)}/organisation/delete/${payload}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							organisationFetch(),
							organisationDeleteSuccess(payload)
						)
					),
					catchError((error) => of(organisationDeleteFailed(error)))
				);
		})
	);
