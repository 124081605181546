import React, { Fragment, useEffect, useState } from "react";
//import { Link as RouterLink } from 'react-router-dom'
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Drawer, Paper, Typography } from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import Navigation from "../navigation";
import navigationConfig from "./navigation-config";
// import { useContext } from 'react'
// import { __RouterContext } from 'react-router'
import { isStaffUser } from "utils";
import { SelectOrganisationForm } from "features/organisations";
import {Snackbar} from "@material-ui/core";
import { PopupSnackbar } from "frame/components";
import { Alert } from "@material-ui/lab";

// const useRouter = () => useContext(__RouterContext)

const useStyles = makeStyles((theme) => ({
	root: {
		height: "100%",
		overflowY: "auto",
		overflowX: "hidden",
	},
	content: {
		padding: theme.spacing(2),
		backgroundColor: "#1c2b4a",
		height: "100%",
		minHeight: "100%",
		backgroundImage: 'url("/images/mobileNavbarBG.svg")',
		backgroundRepeat: "no-repeat",
		backgroundAttachment: "fixed",
		backgroundPosition: "left bottom",
		[theme.breakpoints.up("xs")]: {
			backgroundSize: "10% 35%",
		},
		[theme.breakpoints.up("sm")]: {
			backgroundSize: "5% 35%",
		},
		[theme.breakpoints.up("md")]: {
			backgroundSize: "5% 30%",
		},
		[theme.breakpoints.up("lg")]: {
			backgroundSize: "2% 30%",
		},
	},
	profile: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		minHeight: "fit-content",
	},
	avatar: {
		width: 60,
		height: 60,
	},
	name: {
		marginTop: theme.spacing(1),
	},
	divider: {
		marginTop: theme.spacing(2),
	},
	navigation: {
		marginTop: theme.spacing(2),
	},
	organisationSelect: {
		margin: theme.spacing(2),
		color: "white",
	},
	selectBorder: {
		// borderBottom: 'solid 1px white',
		borderBottom: "none",
	},
	selectIcon: {
		fill: "white",
	},
}));

const useStylesSnackbar = makeStyles((theme) => ({
	root: {},
	content: {
		maxWidth: "280px",
	},
}));

const NavBar = ({
	openMobile,
	onToggleSideNavBar,
	className,
	role,
	currentOrganisationGUID,
	globalMessage,
}) => {
	const theme = useTheme();
	const classes = useStyles();
	const classesSnackbar = useStylesSnackbar();
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
	// const router = useRouter()
	// const session = useSelector(state => state.session);

	const filteredNavigationPages = isStaffUser(role)
		? navigationConfig[0].pages.filter((page) => page.title !== "Staff")
		: navigationConfig[0].pages;

	const filteredNavigationConfig = [
		{
			title: navigationConfig[0].title,
			pages: filteredNavigationPages,
		},
	];

	const navbarContent = (
		<div className={classes.content}>
			<div className={classes.profile}>
				{/* <Avatar
          alt="Person"
          className={classes.avatar}
          component={RouterLink}
          // src={session.user.avatar}
          to="/profile"
        /> */}
				<Typography className={classes.name} variant="h4">
					{/* {session.user.first_name} {session.user.last_name} */}
				</Typography>
				{/* <Typography variant="body2">{session.user.bio}</Typography> */}
			</div>
			{/* <Divider className={classes.divider} /> */}

			<nav className={classes.navigation}>
				<SelectOrganisationForm />
				{filteredNavigationConfig.map((list, index) => (
					<Navigation
						component="div"
						key={index}
						pages={list.pages}
						title={list.title}
					/>
				))}
			</nav>
		</div>
	);

	const [message, setMessage] = useState([])
	useEffect(() => {
		let globalMessageList = [];
		for(let i =0; i < globalMessage.length; i++){
			globalMessageList.push(globalMessage[i][1]);
		}
		setMessage(globalMessageList);
	}, [globalMessage]);

	return (
		<Fragment>
			{message.length > 0 && (
				<Snackbar
					open={true}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					key={"message"}
				>
					<Alert variant="filled" severity={"success"}>
						{message.map((m, i) => 
						<>
							<p className={classesSnackbar.content} >
								{`${m}`}
							</p>
							{i !== message.length - 1 && <br />}
						</>)}
					</Alert>
				</Snackbar>
			)}

			<Hidden mdUp>
				<Drawer
					anchor="left"
					onClose={onToggleSideNavBar}
					open={openMobile}
					variant="temporary"
				>
					<div className={clsx(classes.root, className)}>
						{navbarContent}
					</div>
				</Drawer>
			</Hidden>
			<Hidden smDown>
				<Paper
					className={clsx(classes.root, className)}
					elevation={1}
					square
				>
					{navbarContent}
				</Paper>
			</Hidden>
		</Fragment>
	);
};

NavBar.propTypes = {
	className: PropTypes.string.isRequired,
	onToggleSideNavBar: PropTypes.func,
	openMobile: PropTypes.bool,
	role: PropTypes.string,
};

export default NavBar;
