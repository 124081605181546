import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";
import { onStaffTrainingListRequest } from "features/health-and-safety";

const mapStateToProps = ({ addStaffTrainingReducer }) => ({
	values: addStaffTrainingReducer,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddStaffTrainingValueChange,
	onReset: fromActions.onAddStaffTrainingReset,
	onSave: fromActions.onAddStaffTrainingSave,
	onGetStaffTrainingList: onStaffTrainingListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
