import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {
	ScrollToTopOnMount,
	AttachmentListing,
	StyledIconButton,
} from "frame/components";
import StaffAddEditForm from "./staff-add-edit-container";
import { isStaffUser, isConsultantUser, isSuperAdminUser } from "utils";
import { isBornAccount } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {},
	heading: {
		margin: theme.spacing(3),
	},
	container: {
		margin: theme.spacing(3, 2),
	},
	content: {
		position: "relative",
		paddingBottom: theme.spacing(6),
		backgroundColor: theme.palette.white,
		overflowX: "hidden",
	},
	label: {
		color: "#999999",
	},
	listItem: {
		marginBottom: theme.spacing(2),
	},
	icon: {
		marginRight: theme.spacing(1),
		color: theme.palette.brand,
	},
	Label: {
		color: theme.palette.brand,
	},
	actions: {
		position: "absolute",
		top: theme.spacing(3),
		right: theme.spacing(3),
	},
	action: {
		padding: theme.spacing(0.5, 4),
	},
}));

const Summary = ({ person, onEdit, staffList, organisations, profile }) => {
	const classes = useStyles();
	const currentStaffId = profile.staffId;

	const isCreator = (staff) => {
		const organisation = organisations.filter(
			(node) => node.creatorId === staff.id
		);
		return !!organisation.length;
	};

	if (!person) {
		return null;
	}

	const editable = (person) =>
		currentStaffId !== person.id ? !isCreator(person) : true;

	let showEdit =
		!isStaffUser(profile.role) &&
		!isConsultantUser(person.organisationRole) &&
		editable(person);

	showEdit = isSuperAdminUser(profile.role) ? true : showEdit; // override for super admins

	const supervisor =
		staffList && staffList.filter((s) => s.id === person.supervisor)[0];

	return (
		<div className={classes.content}>
			<ScrollToTopOnMount />
			<StaffAddEditForm />
			<Grid className={classes.container} container spacing={2}>
				<Grid item md={6} xs={12}>
					<Grid container spacing={2}>
						<Grid item md={12} xs={12}>
							<Typography variant="h2">Staff Summary</Typography>
						</Grid>
						<Grid item md={6} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Name
							</Typography>
							<Typography variant="h5">
								{person.fullName}
							</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Phone
							</Typography>
							<Typography variant="h5">
								{person.phoneNumber || "-"}
							</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Email
							</Typography>
							<Typography variant="h5">{person.email}</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Organisation Role
							</Typography>
							<Typography variant="h5">
								{person.organisationRole}
							</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Staff ID Number
							</Typography>
							<Typography variant="h5">
								{person.staffIdNumber || "-"}
							</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Supervisor
							</Typography>
							<Typography variant="h5">
								{supervisor ? supervisor.fullName : "-"}
							</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Notes
							</Typography>
							<Typography variant="h5">
								{person.notes || "-"}
							</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Files
							</Typography>
							<AttachmentListing
								attachments={person.attachments}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<div className={classes.actions}>
				{showEdit && !isBornAccount(profile.email) && (
					<StyledIconButton onClick={() => onEdit(person.id)}>
						<EditIcon />
					</StyledIconButton>
				)}
			</div>
		</div>
	);
};

Summary.propTypes = {
	person: PropTypes.object,
	onEdit: PropTypes.func.isRequired,
	profile: PropTypes.object.isRequired,
	organisations: PropTypes.array.isRequired,
	staffList: PropTypes.array.isRequired,
};

export default Summary;
