import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import {
	Divider,
	Grid,
	Typography,
	Checkbox,
	FormControlLabel,
} from "@material-ui/core";

import { Loader, FormSubmitButton, Feedback } from "frame/components";
import AlertEmail from "./alert-email";
import { onSaveEmailNotifications } from "../../actions";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(3, 0, 2, 0),
		maxWidth: 540,
		"& > *": {
			paddingLeft: "0 !important",
			paddingRight: "0 !important",
		},
	},
	list: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		minHeight: 50,
	},
	divider: {
		margin: theme.spacing(1.5, 0, 1, 0),
	},
	formControlLabel: {
		userSelect: "none",
	},
	saveMessage: {
		color: theme.palette.success.main,
		margin: theme.spacing(1, 2),
		position: "absolute",
	},
}));

const AlertEmailForm = ({
	emails = [],
	loading,
	onSave,
	allowExternalNotifications,
	onSaveEmailNotifications,
	role,
}) => {
	const classes = useStyles();
	const [email, setEmail] = useState("");
	const [showSaveMessage, setShowSaveMessage] = useState(false);

	const showLoader = loading && !emails.length;
	const showEmpty = !loading && !emails.length;

	const [externalEmailNotifications, setExternalEmailNotifications] =
		useState(!allowExternalNotifications);

	useEffect(() => {
		setExternalEmailNotifications(!allowExternalNotifications);
	}, [allowExternalNotifications]);

	const onCheckboxChange = (event) =>
		setExternalEmailNotifications(!externalEmailNotifications);

	const onSubmit = () => {
		const next = Array.from(new Set([...emails, email]));
		setEmail("");
		onSave(next);
	};

	const onRemove = (index) => {
		const next = [...emails.slice(0, index), ...emails.slice(index + 1)];
		onSave(next);
	};

	const onChange = (event) => setEmail(event.target.value);

	const onSaveChanges = () => {
		onSaveEmailNotifications(!externalEmailNotifications);
		setShowSaveMessage(true);
		setTimeout(() => {
			setShowSaveMessage(false);
		}, 3000);
	};

	return (
		<>
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				<Grid className={classes.root} container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h4">
							Email Alerts for incidents
						</Typography>
						<Typography className={classes.text} variant="body1">
							An email for all new accidents/incidents will be
							sent to the following email addresses.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<div className={classes.list}>
							{showLoader && <Loader />}
							{showEmpty && (
								<Typography variant="body2">
									No emails listed to receive alerts.
								</Typography>
							)}
							{emails.map((node, index) => (
								<AlertEmail
									key={index}
									email={node}
									onRemove={() => onRemove(index)}
								/>
							))}
						</div>
						<Divider className={classes.divider} />
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							id={"email"}
							fullWidth
							label="Email"
							value={email || ""}
							variant="outlined"
							validators={["required", "isEmail"]}
							errorMessages={[
								"This field is required",
								"Must be a valid email",
							]}
							onChange={onChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormSubmitButton>Add Email</FormSubmitButton>
					</Grid>
				</Grid>
			</ValidatorForm>

			<Grid className={classes.root} container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h4">
						Email notifications from forms
					</Typography>
					<Typography className={classes.text} variant="body1">
						Restrict email to users only
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						className={classes.formControlLabel}
						control={
							<Checkbox
								checked={externalEmailNotifications}
								onChange={onCheckboxChange}
							/>
						}
						label="Only send emails to the users of ForTheRecord"
					/>
				</Grid>
				<Grid item xs={12}>
					<FormSubmitButton onClick={onSaveChanges}>
						Save Changes
					</FormSubmitButton>
					{showSaveMessage && (
						<Feedback disableTimeout active color="success">
							Changes saved
						</Feedback>
					)}
				</Grid>
			</Grid>
		</>
	);
};

AlertEmailForm.propTypes = {
	emails: PropTypes.array,
	loading: PropTypes.bool,
	onSave: PropTypes.func,
	allowExternalNotifications: PropTypes.bool.isRequired,
	onSaveEmailNotifications: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
};

const mapStateToProps = ({ profile }) => ({
	allowExternalNotifications: profile.allowExternalNotifications,
	role: profile.role,
});

const mapDispatchToProps = {
	onSaveEmailNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertEmailForm);
