import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Page, PageContent, ScrollToTopOnMount } from "frame/components";
import { useRouter } from "utils";
import SafetyMinutesCard from "../cards/safety-minutes-card";
import { EditSafetyMinutesForm } from "features/health-safety-forms";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.white,
	},
	heading: {
		marginTop: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0),
		},
	},
	body: {
		margin: theme.spacing(3, 0),
	},
	filter: { display: "flex" },
}));

const SafetyMinutesSiteList = ({ role, onDelete }) => {
	const classes = useStyles();
	const { match } = useRouter();
	const { siteId } = match.params;

	const [editId, setEditId] = useState();
	const [siteSafetyMinutes, setSiteSafetyMinutes] = useState(null);

	const safetyMinutesList = useSelector(
		({ healthSafety }) => healthSafety.safetyMinutes?.results
	);

	useEffect(() => {
		async function fetchData() {
			setSiteSafetyMinutes(
				safetyMinutesList
					? safetyMinutesList.filter((node) => node.siteId === siteId)
					: []
			);
		}
		fetchData();
		// eslint-disable-next-line
	}, [setSiteSafetyMinutes, siteId]);

	const empty = siteSafetyMinutes && siteSafetyMinutes.length <= 0;

	const onEdit = (id) => () => setEditId(id);

	const onCancelEdit = () => setEditId(null);

	return (
		<Page className={classes.root} title="Safety Minutes">
			<ScrollToTopOnMount />

			<PageContent pad>
				<Typography variant="h2" className={classes.heading}>
					Safety Minutes
				</Typography>

				{!empty && (
					<div className={classes.body}>
						{siteSafetyMinutes &&
							siteSafetyMinutes.map((safetyMinutes, index) => (
								<SafetyMinutesCard
									key={index}
									role={role}
									safetyMinutes={safetyMinutes}
									onEdit={onEdit(safetyMinutes.id)}
									onDelete={onDelete}
								/>
							))}
					</div>
				)}

				{empty && (
					<Typography variant="body1">
						There are currently no Safety Minutes listed.
					</Typography>
				)}
			</PageContent>
			{editId && (
				<EditSafetyMinutesForm id={editId} onClose={onCancelEdit} />
			)}
		</Page>
	);
};

SafetyMinutesSiteList.propTypes = {
	role: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default SafetyMinutesSiteList;
