import {
	defaultSafetyPlanChecklistFormState,
	defaultSiteFormState,
	isLocked,
} from "features/forms";

import {
	defaultSaveState,
	saveRequested,
	saveFailed,
	saveSuccess,
} from "utils";

import * as fromActions from "../actions/types";
import * as R from "ramda";

export const defaultState = {
	...defaultSaveState,
	...defaultSiteFormState,
	...defaultSafetyPlanChecklistFormState,
};

const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case fromActions.ADD_SAFETY_PLAN_CHECKLIST_FORM_RESET:
			return defaultState;
		case fromActions.ADD_SAFETY_PLAN_CHECKLIST_FORM_VALUE_CHANGE:
			return isLocked({
				...state,
				...action.payload,
			});
		case fromActions.ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT: {
			const nextState = R.clone(state);
			nextState.responseMessage = "";
			return isLocked(saveRequested(nextState));
		}
		case fromActions.ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT_FAILED: {
			const nextState = saveResponseMessage(R.clone(state), action);
			return isLocked(saveFailed(nextState, action.payload));
		}
		case fromActions.ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT_SUCCESS:
			return isLocked(saveSuccess(state));
		default:
			return isLocked(state);
	}
};

const saveResponseMessage = (state, action) => {
	state.responseMessage = action.payload;
	return state;
};

export default reducer;
