import { combineEpics } from "redux-observable";
import authorisationEffects from "features/authorisation/effects";
import { effects as accountEffects } from "features/account";
import { jobsEffects } from "features/jobs/effects";
import frameEffects from "./frame-effects";
import homeEffects from "features/home/home-effects";
import customersEffects from "features/customers/effects";
import { effects as sitesEffects } from "features/sites";
import staffEffects from "features/staff/effects";
import healthSafetyEffects from "features/health-and-safety/effects";
import { notificationsEffects } from "features/notifications/effects/notifications-effects";
import healthSafetyFormEffects from "features/health-safety-forms/effects";
import organisationEffects from "features/organisations/effects";
import queueEffects from "features/queue/effects";
import formEffects from "features/forms/effects";
import { timesheetEffects } from "features/timesheet/timesheet-effects";
import { timesheetAdminEffects } from "features/timesheet/timesheet-admin-effects";
import taskTypeEffects from "features/task-type/effects";

const rootEffect = combineEpics(
	authorisationEffects,
	accountEffects,
	jobsEffects,
	frameEffects,
	homeEffects,
	customersEffects,
	sitesEffects,
	staffEffects,
	healthSafetyEffects,
	healthSafetyFormEffects,
	notificationsEffects,
	organisationEffects,
	queueEffects,
	formEffects,
	timesheetEffects,
	timesheetAdminEffects,
	taskTypeEffects
);

export default rootEffect;
