import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Divider } from "@material-ui/core";
import { capitalize } from "utils";
import { formatDate, isValidContentState, isNotNullOrEmpty } from "utils";
import { ValueText } from "frame/components";
import MUIRichTextEditor from "mui-rte";
import { useSelector } from "react-redux";
import { SectionCard, AttachmentsSectionCard } from "frame/components";
import HazardSiteTable from "./hazard-site-table";

const useStyles = makeStyles((theme) => ({
	root: {},
	status: {
		display: "flex",
	},
	chip: {
		marginLeft: theme.spacing(1),
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
	contentStateArea: {
		marginLeft: theme.spacing(2),
	},
}));

const ReadOnlyForm = ({ values }) => {
	const classes = useStyles();
	const hazard = values && values.hazard;
	const [siteList, setSiteList] = useState(null);
	const sites = useSelector(({ sites }) => sites.pagination?.results);

	useEffect(() => {
		async function fetchData() {
			if (sites) {
				// filter and store sites that includes current hazard
				const filteredList = sites.filter((node) =>
					JSON.stringify(node.hazards).includes(values.id)
				);
				setSiteList(filteredList ? filteredList : []);
			}
		}
		fetchData(); // eslint-disable-next-line
	}, []);

	return (
		<>
			<SectionCard title="Hazard Details">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Date"
							value={formatDate(values.createdAt)}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Name"
							value={hazard.name || "-"}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Initial Risk Assessment"
							value={hazard.initialRiskAssessment || "-"}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Residual Risk Assessment"
							value={hazard.residualRiskAssessment || "-"}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Significant Risk"
							value={
								hazard.significantRisk
									? capitalize(hazard.significantRisk)
									: "-"
							}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Training Required"
							value={
								hazard.trainingRequired
									? capitalize(hazard.trainingRequired)
									: "-"
							}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={12} xs={12}>
						<ValueText
							label="Status"
							value={hazard.status || "-"}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Typography variant="h6">
							<strong>PPE Requirements:</strong>
						</Typography>
						{/* <Typography align="left" gutterBottom variant="body1"> */}
						{hazard.ppeRequirements &&
						isValidContentState(hazard.ppeRequirements) ? (
							<span className={classes.contentStateArea}>
								<MUIRichTextEditor
									readOnly
									controls={[]}
									value={hazard.ppeRequirements}
								/>
							</span>
						) : (
							"-"
						)}
						{/* </Typography> */}
						<Divider />
					</Grid>
					<Grid item sm={6} xs={12}>
						<Typography variant="h6">
							<strong>Hazard Controls:</strong>
						</Typography>
						{/* <Typography align="left" gutterBottom variant="body1"> */}
						{hazard.hazardControls &&
						isValidContentState(hazard.hazardControls) ? (
							<span className={classes.contentStateArea}>
								<MUIRichTextEditor
									readOnly
									controls={[]}
									value={hazard.hazardControls}
								/>
							</span>
						) : (
							"-"
						)}
						{/* </Typography> */}
						<Divider />
					</Grid>
				</Grid>
			</SectionCard>

			{isNotNullOrEmpty(siteList) && (
				<SectionCard title="Active Sites">
					<br />
					<HazardSiteTable siteList={siteList} hazardId={values.id} />
				</SectionCard>
			)}

			<AttachmentsSectionCard values={values} />
		</>
	);
};

ReadOnlyForm.propTypes = {
	values: PropTypes.object.isRequired,
};

export default ReadOnlyForm;
