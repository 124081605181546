import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid /*, Button*/ } from "@material-ui/core";
import Search from "./search";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexWrap: "wrap",
		width: "100%",
		marginTop: "1px",
		paddingLeft: theme.spacing(3),
		[theme.breakpoints.down("sm")]: {
			width: "70%",
		},
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(5),
			width: "65%",
		},
	},
	search: {
		flexGrow: 1,
		maxWidth: 480,
		flexBasis: 480,
		width: "100%",
	},
	filterButton: {
		marginLeft: "auto",
	},
	filterIcon: {
		marginRight: theme.spacing(1),
	},
}));

const SearchBar = ({ className, onSearch, ...rest }) => {
	const classes = useStyles();

	return (
		<Grid
			{...rest}
			className={clsx(classes.root, className)}
			container
			spacing={3}
		>
			<Grid item md={6} xs={12}>
				<Search className={classes.search} onSearch={onSearch} />
			</Grid>
		</Grid>
	);
};

SearchBar.propTypes = {
	className: PropTypes.string,
	onSearch: PropTypes.func,
};

export default SearchBar;
