import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";

const mapStateToProps = ({ addHazardFormReducer }) => ({
	values: addHazardFormReducer,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddHazardValueChange,
	onReset: fromActions.onAddHazardReset,
	onSave: fromActions.onAddHazardSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
