import types from "./types";

export const onList = () => ({
	type: types.TASK_TYPE_LIST_REQUESTED
});

export const onListSuccess = (payload) => ({
	type: types.TASK_TYPE_LIST_SUCCEEDED,
	payload
});

export const onListFailed = (payload) => ({
	type: types.TASK_TYPE_LIST_FAILED,
	payload
});

export const onAdd = (payload) => ({
	type: types.TASK_TYPE_CREATE_REQUESTED,
	payload
});

export const onAddSuccess = (payload) => ({
	type: types.TASK_TYPE_CREATE_SUCCEEDED,
	payload
});

export const onAddFailed = (payload) => ({
	type: types.TASK_TYPE_CREATE_FAILED,
	payload
});

export const onSave = (payload) => ({
	type: types.TASK_TYPE_UPDATE_REQUESTED,
	payload
});

export const onSaveSuccess = (payload) => ({
	type: types.TASK_TYPE_UPDATE_SUCCEEDED,
	payload
});

export const onSaveFailed = (payload) => ({
	type: types.TASK_TYPE_UPDATE_FAILED,
	payload
});

export const onRemove = (payload) => ({
	type: types.TASK_TYPE_DELETE_REQUESTED,
	payload
});

export const onRemoveSuccess = (payload) => ({
	type: types.TASK_TYPE_DELETE_SUCCEEDED,
	payload
});

export const onRemoveFailed = (payload) => ({
	type: types.TASK_TYPE_DELETE_FAILED,
	payload
});
