import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as fromRoutes from "../actions/types";
import Account from "./account-container";
import Invite from "./invite-container";
import Notifications from "./notifications";
import { OrganisationList } from "features/organisations";
import Password from "./password-container";
import Profile from "./profile-container";
import Reports from "./reports-container";
import Subscription from "./subscriptions/subscription-container";
import { useCurrentRole, isConsultantUser, isSuperAdminUser } from "utils";

export const AccountRoutes = () => (
	<Switch>
		<Route path={fromRoutes.ACCOUNT_TAB_ROUTE} component={Account} />
		<Redirect
			from={fromRoutes.ACCOUNT_ROUTE}
			to={fromRoutes.ACCOUNT_PROFILE_ROUTE}
		/>
	</Switch>
);

export const ChildRoutes = () => {
	const currentRole = useCurrentRole();

	return (
		<Switch>
			{(isConsultantUser(currentRole) ||
				isSuperAdminUser(currentRole)) && (
				<Route
					path={fromRoutes.ACCOUNT_INVITE_ROUTE}
					component={Invite}
				/>
			)}
			<Route
				path={fromRoutes.ACCOUNT_NOTIFICATIONS_ROUTE}
				component={Notifications}
			/>
			<Route
				path={fromRoutes.ACCOUNT_ORGANISATIONS_ROUTE}
				component={OrganisationList}
			/>
			<Route
				path={fromRoutes.ACCOUNT_PASSWORD_ROUTE}
				component={Password}
			/>
			<Route
				path={fromRoutes.ACCOUNT_PROFILE_ROUTE}
				component={Profile}
			/>
			<Route
				path={fromRoutes.ACCOUNT_REPORTS_ROUTE}
				component={Reports}
			/>
			<Route
				path={fromRoutes.ACCOUNT_SUBSCRIPTION_ROUTE}
				component={Subscription}
			/>
		</Switch>
	);
};
