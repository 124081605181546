import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Typography, Grid, Divider, useMediaQuery } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
	isNotNullOrEmpty,
	sortByPropertyAlphabetically,
	isDefaultGuid,
} from "utils";
import { TitleWithHelperText } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	heading: {
		padding: theme.spacing(2, 0, 1, 0),
	},
	listItem: {
		padding: theme.spacing(1, 0),
		display: "flex",
		flexDirection: "row",
	},
	attendanceIcon: {
		color: theme.palette.success.main,
		marginTop: "-2px",
		marginLeft: "5px",
	},
	helperTextIcon: {
		color: theme.palette.success.main,
	},
	helperText: {
		display: "relative",
		justifyContent: "flex-end",
		marginTop: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(-1),
		},
	},
}));

// used in view only Edit forms
/*
  - maps staff people and other people separately if otherPeople is provided
*/
const PeopleSection = ({
	people = [],
	otherPeople,
	title,
	hideRole,
	displayAttendanceConfirmed,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

	const peopleList =
		isNotNullOrEmpty(people) &&
		people
			.filter((p) => !isDefaultGuid(p.id) && p.role)
			.sort(sortByPropertyAlphabetically("name"));

	const otherPeopleList =
		isNotNullOrEmpty(people) &&
		people
			.filter((p) => isDefaultGuid(p.id))
			.map((p) => p.name)
			.sort();

	const hasAleastOneConfirmed =
		isNotNullOrEmpty(peopleList) &&
		peopleList.filter((p) => p.attendanceConfirmed).length > 0;

	return (
		<Grid container spacing={2} className={classes.root}>
			{isNotNullOrEmpty(people) && (
				<Grid item sm={6} xs={12}>
					<Grid container>
						<Grid item xs={displayAttendanceConfirmed ? 6 : 12}>
							<Typography
								variant="h5"
								className={classes.heading}
							>
								<strong>Staff People:</strong>
							</Typography>
						</Grid>

						{displayAttendanceConfirmed && hasAleastOneConfirmed && (
							<Grid item xs={6}>
								<TitleWithHelperText
									className={classes.helperText}
									helperText="Attendance confirmed"
									icon={
										<CheckCircleIcon
											className={classes.helperTextIcon}
										/>
									}
								/>
							</Grid>
						)}
					</Grid>

					<Typography align="left" gutterBottom variant="body1">
						{peopleList.map(
							({ name, role, attendanceConfirmed }, index) => (
								<div
									key={`view-induction-staff-people-${index}`}
								>
									<div className={classes.listItem}>
										<span
											style={{
												justifyContent: "flex-start",
											}}
										>
											{name}
										</span>
										{displayAttendanceConfirmed &&
											attendanceConfirmed && (
												<CheckCircleIcon
													className={
														classes.attendanceIcon
													}
												/>
											)}
										<span style={{ flex: 1 }}></span>
										<span
											style={{
												justifyContent: "flex-end",
											}}
										>
											{role}
										</span>
									</div>
									<Divider />
								</div>
							)
						)}
					</Typography>
				</Grid>
			)}

			{(isNotNullOrEmpty(otherPeople) ||
				isNotNullOrEmpty(otherPeopleList)) && (
				<Grid item sm={6} xs={12}>
					<Typography variant="h5" className={classes.heading}>
						<strong>Other People:</strong>
					</Typography>

					<Typography align="left" gutterBottom variant="body1">
						{isNotNullOrEmpty(otherPeople) &&
							otherPeople.map((name, index) => (
								<div
									key={`view-induction-other-people-${index}`}
								>
									<div className={classes.listItem}>
										{/* float left on mobile only, else floats right*/}
										{!mobileView && (
											<span style={{ flex: 1 }}></span>
										)}

										<span
											style={{
												justifyContent: mobileView
													? "flex-start"
													: "flex-end",
											}}
										>
											{name}
										</span>

										{mobileView && (
											<span style={{ flex: 1 }}></span>
										)}
									</div>
									<Divider />
								</div>
							))}

						{isNotNullOrEmpty(otherPeopleList) &&
							otherPeopleList.map((name, index) => (
								<div
									key={`view-induction-other-people-${index}`}
								>
									<div className={classes.listItem}>
										{/* float left on mobile only, else floats right*/}
										{!mobileView && (
											<span style={{ flex: 1 }}></span>
										)}

										<span
											style={{
												justifyContent: mobileView
													? "flex-start"
													: "flex-end",
											}}
										>
											{name}
										</span>

										{mobileView && (
											<span style={{ flex: 1 }}></span>
										)}
									</div>
									<Divider />
								</div>
							))}
					</Typography>
				</Grid>
			)}

			{!isNotNullOrEmpty(people) &&
				!isNotNullOrEmpty(otherPeople) &&
				!isNotNullOrEmpty(otherPeopleList) &&
				"-"}
		</Grid>
	);
};

PeopleSection.propTypes = {
	people: PropTypes.array.isRequired,
	title: PropTypes.string,
	hideRole: PropTypes.bool,
	displayAttendanceConfirmed: PropTypes.bool,
};

export default PeopleSection;
