import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		// marginLeft: theme.spacing(1.5),
		color: theme.palette.text.secondary,
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(15),
		textTransform: "none",
		opacity: 0.7,
		"&:hover": {
			color: theme.palette.brand,
			opacity: 1,
		},
		"&:focus": {
			// color: theme.palette.brand,
		},
	},
	selected: {
		color: theme.palette.brand,
		fontWeight: theme.typography.fontWeightBold,
		opacity: 1,
	},
}));

const TablikeLink = ({ children, selected, to }) => {
	const classes = useStyles();

	return (
		<RouterLink
			to={to}
			className={clsx(classes.root, selected ? classes.selected : "")}
		>
			{children}
		</RouterLink>
	);
};

TablikeLink.propTypes = {
	children: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	to: PropTypes.string.isRequired,
};

export default TablikeLink;
