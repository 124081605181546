import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
} from "frame/components";
import { strip, DateForm, HazardForm, UploadFormCard } from "features/forms";
import ReadOnlyForm from "./read-only-form";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		flexDirection: "row",
		padding: theme.spacing(3, 3, 10, 3),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
}));

const hydrate = ({
	id,
	siteId,
	createdAt,
	attachments,
	organisationId,
	...hazard
}) => ({
	id,
	siteId,
	createdAt,
	hazard,
	attachments,
	currentOrganisation: organisationId,
});

const Form = ({ id, values, onClose, onChange, onReset, onSave, readOnly }) => {
	const classes = useStyles();
	const hazardList = useSelector(
		({ healthSafety }) => healthSafety.hazards?.results
	);

	const [loaded, setLoaded] = useState(false);
	const [originalValues, setOriginalValues] = useState();
	const open = values && values.id;

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchData() {
			setLoaded(true);
			const hazard =
				hazardList && hazardList.filter((h) => h.id === id)[0];

			if (hazard) {
				onChange(hydrate(hazard));
				setOriginalValues(hazard);
			}
		}
		if (id) {
			setLoaded(true);
			fetchData();
		} // eslint-disable-next-line
	}, [id, loaded, onChange]);

	useEffect(() => {
		values.save.success && onClose();
	}, [values.save.success, onClose]);

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
	};

	const onCancel = () => {
		onReset();
		onClose();
	};

	if (!open) {
		return null;
	}

	const title = readOnly
		? `View Hazard: ${originalValues ? originalValues.name : ""}`
		: `Edit Hazard: ${originalValues ? originalValues.name : ""}`;

	return (
		<Dialog
			open={!!open}
			aria-labelledby="Hazards"
			// breadcrumbs="Hazards"
			title={title}
			onClose={onCancel}
		>
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				{readOnly ? (
					<ReadOnlyForm values={values} />
				) : (
					<div className={classes.body}>
						<DateForm values={values} onChange={onChange} />
						<HazardForm
							editMode
							values={values}
							onChange={onChange}
						/>
						<UploadFormCard
							title={"Add Attachments"}
							values={values}
							onChange={onChange}
						/>
					</div>
				)}
				<FormActions className={classes.actions}>
					{!readOnly && (
						<FormSubmitButton loading={values.save.saving}>
							Save
						</FormSubmitButton>
					)}

					<FormButton
						disabled={values.save.saving}
						onClick={onCancel}
					>
						{readOnly ? "Close" : "Cancel"}
					</FormButton>
				</FormActions>
			</ValidatorForm>
		</Dialog>
	);
};

Form.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
};

export default Form;
