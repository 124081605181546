import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { tokenExists, authHeaders, getOrganisationBaseUrl } from "frame";
// import * as fromAccountTypes from 'features/account'
import * as fromTypes from "../actions/types";

import {
	organisationUpdatePreferenceFailed,
	organisationUpdatePreferenceSuccess,
} from "../actions";

// for updating last visited organisation on organisation change
export const organisationUpdatePreferenceEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED),
		filter(() => tokenExists()),
		mergeMap(() => {
			const currentOrg = state$.value.organisations.currentOrganisation;

			return ajax
				.get(
					`${getOrganisationBaseUrl(
						state$.value
					)}/staff/update-preference/${currentOrg}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							organisationUpdatePreferenceSuccess(
								ajaxResponse.response
							)
						)
					),
					catchError((error) => {
						return of(
							organisationUpdatePreferenceFailed(error.message)
						);
					})
				);
		})
	);
