import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
// import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	loader: {
		marginBottom: theme.spacing(2),
		borderRadius: "10px",
	},
}));

const PaginationLoader = ({ height, pagination }) => {
	const classes = useStyles();

	if (!pagination) {
		return null;
	}

	return (
		<div className={classes.root}>
			<Skeleton
				variant="rect"
				animation="wave"
				height={height}
				className={classes.loader}
			/>
			<Skeleton
				variant="rect"
				animation="wave"
				height={height}
				className={classes.loader}
			/>
			<Skeleton
				variant="rect"
				animation="wave"
				height={height}
				className={classes.loader}
			/>
			<Skeleton
				variant="rect"
				animation="wave"
				height={height}
				className={classes.loader}
			/>
			<Skeleton
				variant="rect"
				animation="wave"
				height={height}
				className={classes.loader}
			/>
			{/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', minHeight: 400 }}>
        <CircularProgress />
      </div> */}
		</div>
	);
};

PaginationLoader.propTypes = {
	height: PropTypes.number,
	pagination: PropTypes.object.isRequired,
};

export default PaginationLoader;
