import React, { useState } from "react";
import PropTypes from "prop-types";
import clip from "text-clipper";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		width: "inherit",
		paddingRight: theme.spacing(2),
		display: "inline-block",
	},
	text: {
		textAlign: "justify",
	},
	link: {
		color: theme.palette.text.link,
	},
}));

const TextClip = ({ text, numberOfCharacters, hideSeeMore }) => {
	const classes = useStyles();
	const [seeMore, setSeeMore] = useState(false);

	const showSeeMore = text && text.length > numberOfCharacters;

	/* eslint-disable */
	return (
		<span className={classes.root}>
			{text ? (
				<>
					<span className={classes.text}>
						{seeMore ? text : clip(text, numberOfCharacters)}
					</span>
					<br />
					{!hideSeeMore && showSeeMore && (
						<a
							href="#"
							className={classes.link}
							onClick={() => setSeeMore(!seeMore)}
						>
							{seeMore ? "Hide" : "See More"}
						</a>
					)}
				</>
			) : (
				"-"
			)}
		</span>
	);
};
/* eslint-enable */

TextClip.propTypes = {
	text: PropTypes.string,
	numberOfCharacters: PropTypes.number.isRequired,
	hideSeeMore: PropTypes.bool,
};

export default TextClip;
