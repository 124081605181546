import Types from "../actions/types";
import { of } from "rxjs";
import { ofType } from "redux-observable";
import { tap, mapTo, filter } from "rxjs/operators";
import { push } from "connected-react-router";
import {
	tokenExists,
	routeNotWhitelisted,
	setBaseUrl,
	setWebsiteBaseUrl,
} from "frame";
import * as fromAuth from "features/authorisation";

const initialiseEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.FRAME_INITIALISE_REQUESTED),
		tap((action) => {
			of(setBaseUrl(), setWebsiteBaseUrl());
		}),
		filter(() => !tokenExists()),
		filter(
			() => !routeNotWhitelisted(state$.value.router.location.pathname)
		),
		mapTo(
			push(
				`${fromAuth.LOGIN_PATH}?location=${state$.value.router.location.pathname}`
			)
		)
	);

export default initialiseEffect;
