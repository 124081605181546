import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Modal, Card, CardContent, Typography } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Lookup,
} from "frame/components";
import { HazardousSubstancesForm } from "features/forms";
import { isBornAccount } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		justifyContent: "flex-end",
		marginTop: theme.spacing(3),
	},
	warning: {
		padding: theme.spacing(1, 0),
		color: theme.palette.error.main,
	},
	emptyOption: {
		display: "none",
	},
}));

const AddSiteToHazardForm = ({
	values,
	isAddHazardousSubstanceToSiteModalShowing,
	siteId,
	email,
	onChange,
	onSave,
	onClose,
	preExistingSiteSubstances,
}) => {
	const classes = useStyles();

	// const currentSite = siteList.filter(node => node.id === siteId)[0]
	// const currentSiteName = currentSite && currentSite.siteName
	// const activeHazardousSubstanceList = hazardousSubstanceList && hazardousSubstanceList.filter(node => node.status !== 'Archived') // filter out archived hazardousSubstances

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(siteId);
		onClose();
	};

	const onCancel = () => {
		onClose();
	};

	if (!isAddHazardousSubstanceToSiteModalShowing) {
		return null;
	}

	const disableSave = false; //isNotNullOrEmpty(activeHazardousSubstanceList) && currentSite && isNotNullOrEmpty(currentSite.substances)
	// && activeHazardousSubstanceList.length === currentSite.substances.length

	return (
		<Modal open={true} onClose={onClose}>
			<Card className={classes.root}>
				<CardContent>
					<ValidatorForm
						autoComplete="off"
						noValidate
						onSubmit={onSubmit}
					>
						<Typography align="center" gutterBottom variant="h3">
							<Lookup
								id={siteId}
								resource="sites"
								attribute="siteName"
							/>
						</Typography>

						<HazardousSubstancesForm
							values={values}
							onChange={onChange}
							preExistingSiteSubstances={
								preExistingSiteSubstances
							}
						/>

						<FormActions className={classes.actions}>
							<FormSubmitButton
								disabled={disableSave && !isBornAccount(email)}
							>
								Save
							</FormSubmitButton>
							<FormButton onClick={onCancel}>Cancel</FormButton>
						</FormActions>
					</ValidatorForm>
				</CardContent>
			</Card>
		</Modal>
	);
};

AddSiteToHazardForm.propTypes = {
	siteId: PropTypes.string.isRequired,
	isAddHazardousSubstanceToSiteModalShowing: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	email: PropTypes.string.isRequired,
	preExistingSiteSubstances: PropTypes.array.isRequired,
};

export default AddSiteToHazardForm;
