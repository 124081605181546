import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";

const mapStateToProps = ({ addIncidentFormReducer, profile }) => ({
	values: addIncidentFormReducer,
	allowExternalNotifications: profile.allowExternalNotifications,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddAccidentValueChange,
	onReset: fromActions.onAddAccidentReset,
	onSave: fromActions.onAddAccidentSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
