import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import BreadcrumbBack from "./breadcrumb-back";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 3, 0, 3),
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(4, 3, 4, 3),
		},
	},
	title: {
		paddingBottom: theme.spacing(1),
		fontSize: "24px",
		letterSpacing: "0.06px",
		fontWeight: "bold",
	},
	message: {
		padding: theme.spacing(0, 0),
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(2, 0),
		},
	},
	breadcrumbs: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	breadcrumb: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	breadcrumbBreak: {
		display: "flex",
		padding: theme.spacing(0, 0.5),
	},
}));

/*
  breadcrumbs params example: 
    const breadcrumbs = [{
    label: `Dashboard`,
    value: HOME_ROUTE,
  }]
*/
const PageHeader = ({
	children,
	className,
	canGoBack,
	breadcrumbs,
	title,
	subtitle,
	message,
	action,
	actionColor = "primary",
	onAction,
	backActionPath, // for specifying back button path - default uses router history.back
	...rest
}) => {
	const classes = useStyles();
	const breadcrumbLinks = breadcrumbs && Array.isArray(breadcrumbs);

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Grid
				alignItems="flex-end"
				container
				justify="space-between"
				spacing={3}
			>
				<Grid item>
					{canGoBack && (
						<BreadcrumbBack backActionPath={backActionPath} />
					)}
					{breadcrumbLinks ? (
						<div className={classes.breadcrumbs}>
							{breadcrumbs.map(({ label, value }, index) => (
								<div key={index} className={classes.breadcrumb}>
									<RouterLink to={value}>
										<Typography
											component="h2"
											gutterBottom
											variant="overline"
										>
											{label}
										</Typography>
									</RouterLink>
									{index < breadcrumbs.length - 1 && (
										<Typography
											className={classes.breadcrumbBreak}
											component="h2"
											gutterBottom
											variant="overline"
										>
											/
										</Typography>
									)}
								</div>
							))}
						</div>
					) : (
						<Typography
							component="h2"
							gutterBottom
							variant="overline"
						>
							{breadcrumbs}
						</Typography>
					)}
					<Typography
						component="h1"
						variant="h3"
						className={classes.title}
					>
						{title}
					</Typography>
					{subtitle && (
						<Typography component="h1" variant="h5">
							{subtitle}
						</Typography>
					)}
					{message && (
						<Typography className={classes.message} variant="body1">
							{message}
						</Typography>
					)}
				</Grid>
				<Grid item>
					{action && onAction && (
						<Button
							color={actionColor}
							variant="contained"
							onClick={onAction}
						>
							{action}
						</Button>
					)}
				</Grid>
			</Grid>
			{children}
		</div>
	);
};

PageHeader.propTypes = {
	children: PropTypes.any,
	canGoBack: PropTypes.bool,
	className: PropTypes.string,
	breadcrumbs: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.object),
	]),
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	message: PropTypes.string,
	action: PropTypes.string,
	actionColor: PropTypes.string,
	onAction: PropTypes.func,
	backActionPath: PropTypes.string,
};

export default PageHeader;
