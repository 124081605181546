import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";

const mapStateToProps = ({
	addInductionMinutesFormReducer,
	healthSafety,
	sites,
}) => ({
	values: addInductionMinutesFormReducer,
	hazardList: healthSafety.hazards?.results,
	hazardousSubstanceList: healthSafety.hazardousSubstances?.results,
	inductionList: healthSafety.inductions?.results,
	siteList: sites.pagination?.results,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddInductionMinutesValueChange,
	onReset: fromActions.onAddInductionMinutesReset,
	onSave: fromActions.onAddInductionMinutesSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
