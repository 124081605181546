import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { getBaseUrl } from "frame";
import fromTypes from "../actions/types";

import { onForgotPasswordFulfilled, onForgotPasswordFailed } from "../actions";

const forgotPasswordEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.AUTHORISATION_FORGOT_PASSWORD_REQUESTED),
		mergeMap(() =>
			ajax
				.get(
					`${getBaseUrl()}/api/reset?email=${
						encodeURIComponent(state$.value.authorisation.login.emailAddress)
					}`
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onForgotPasswordFulfilled(ajaxResponse.response))
					),
					catchError((error) => onForgotPasswordFailed(error))
				)
		)
	);

export default forgotPasswordEffect;
