import { ofType } from "redux-observable";
import { mapTo, filter } from "rxjs/operators";
import { mobileNavClosed } from "../actions";

const isMobileOpen = ({ frame }) => {
	return frame.navigation.isMobileOpen;
};

const routeChangeCloseMobileNav = (action$, state$) =>
	action$.pipe(
		ofType("@@router/LOCATION_CHANGE"),
		filter(() => isMobileOpen(state$.value)),
		mapTo(mobileNavClosed())
	);

export default routeChangeCloseMobileNav;
