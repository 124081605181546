import uuid from "uuid";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError, flatMap } from "rxjs/operators";
import { onHazardListRequest } from "features/health-and-safety/actions";
import { INFO_HAZARDS_ROUTE } from "features/health-and-safety";
import { ADD_HAZARD_FORM_SUBMIT } from "../actions/types";
import {
	DBService,
	HAZARDS_TABLE,
	replaceDataURLs,
	authHeaders,
	getOrganisationBaseUrl,
} from "frame";
import { getCurrentTimestamp } from "utils";
import * as R from "ramda";
import {
	onAddHazardReset,
	onAddHazardSaveFailed,
	onAddHazardSaveSuccess,
} from "../actions";

const fetchAttachments = () => async () => {
	const db = new DBService();
	var files = await db.getAll(HAZARDS_TABLE);
	return files;
};

const addHazardEffect = (action$, state$) =>
	action$.pipe(
		ofType(ADD_HAZARD_FORM_SUBMIT),
		flatMap(fetchAttachments()),
		mergeMap((result) => {
			let attachments = R.clone(
				state$.value.addHazardFormReducer.attachments
			);
			attachments = replaceDataURLs(attachments, result);

			const form = state$.value.addHazardFormReducer;

			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/hazard/createorupdate`,
					{
						// form.hazard.id - for archive , form.id - for edit hazard  TODO: refactor both to use one
						id: form.hazard.id || form.id || uuid.v4(),
						createdAt: form.createdAt,
						name: form.hazard.name,
						description: form.hazard.description,
						initialRiskAssessment:
							form.hazard.initialRiskAssessment,
						residualRiskAssessment:
							form.hazard.residualRiskAssessment,
						significantRisk: form.hazard.significantRisk,
						trainingRequired: form.hazard.trainingRequired,
						ppeRequirements: form.hazard.ppeRequirements,
						hazardControls: form.hazard.hazardControls,
						status: form.hazard.status || "Active",
						attachments: attachments || [],
						latitude: state$.value.geolocation.latitude,
						longitude: state$.value.geolocation.longitude,
						timestamp: getCurrentTimestamp(),
						organisationId:
							state$.value.organisations.currentOrganisation,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return of(
							onHazardListRequest(),
							onAddHazardSaveSuccess(),
							onAddHazardReset(),
							push(INFO_HAZARDS_ROUTE)
						);
					}),
					/* eslint-disable */
					catchError((error) =>
						of(onAddHazardSaveFailed(error.message))
					)
					/* eslint-enable */
				);
		})
	);

export default addHazardEffect;
