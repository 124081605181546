import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { Card, CardHeader } from "@material-ui/core";

import HazardousSubstancesForm from "./form";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
}));

const HazardousSubstancesFormCard = ({ values, onChange }) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Hazardous Substances"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<HazardousSubstancesForm values={values} onChange={onChange} />
		</Card>
	);
};

HazardousSubstancesFormCard.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default HazardousSubstancesFormCard;
