export const sortByPropertyAlphabetically = (key) => (a, b) => {
	if (!a || !b) {
		return 0;
	}
	if (!(key in a) || !(key in b)) {
		return 0;
	}
	if (!a[key] || !b[key]) {
		return 0;
	}

	const nodeA = a[key].toLowerCase();
	const nodeB = b[key].toLowerCase();
	return nodeA < nodeB ? -1 : nodeA > nodeB ? 1 : 0;
};

export const sortByPropertyDate = (key) => (a, b) =>
	new Date(b[key]) - new Date(a[key]);

export const sortByPropertyNumber = (key) => (a, b) =>
	parseFloat(a[key]) - parseFloat(b[key]);
