import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ADMIN_HOME_ROUTE } from "features/home/home-routes";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	PageHeaderAddButton
} from "frame/components";
import TaskTypeListing from "./task-type-listing";
import TaskTypeModal from "./task-type-modal";

const useStyles = makeStyles((theme) => ({
	root: {},
	body: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(3),
		},
	},
}));

const TaskType = ({
	taskTypes,
	isLoadingList,
	onList,
	onAdd,
	onSave,
	onRemove
}) => {
	const classes = useStyles();

	const [selectedTaskType, setSelectedTaskType] = useState();
	const [showAddTaskTypeModal, setShowAddTaskTypeModal] = useState(false);

	const onTaskTypeEdit = (taskTypeId) => {
		setSelectedTaskType({
			...taskTypes.find(tt => tt.id === taskTypeId)
		});
		setShowAddTaskTypeModal(true);
	};

	const onTaskTypeDelete = (taskTypeId) => {
		onRemove(taskTypeId);
	};

	const onModalSave = (taskType) => {
		if (taskType.id) {
			onSave(taskType);
		}
		else {
			onAdd(taskType);
		}
		setShowAddTaskTypeModal(false);
	};

	useEffect(() => {
		onList();
	}, [onList]);

	return (
		<Page className={classes.root} title="Task Type">
			<ScrollToTopOnMount />
			<PageHeaderAddButton
				title="Task Type"
				addTabTitle="Add Task Type"
				onAdd={() => {
					setSelectedTaskType(undefined);
					setShowAddTaskTypeModal(true);
				}}
				staffUserHide={false}
				backActionPath={ADMIN_HOME_ROUTE}
			/>
			<PageContent pad>
				<div className={classes.body}>
					<TaskTypeListing
						taskTypes={taskTypes}
						isLoading={isLoadingList}
						onEdit={onTaskTypeEdit}
						onDelete={onTaskTypeDelete}
					/>
				</div>
			</PageContent>
			<TaskTypeModal
				taskType={selectedTaskType}
				isOpen={showAddTaskTypeModal}
				onSubmit={onModalSave}
				onClose={() => setShowAddTaskTypeModal(false)}
			/>
		</Page>
	);
};

TaskType.propTypes = {
	taskTypes: PropTypes.array.isRequired,
	isLoadingList: PropTypes.bool.isRequired,
	onList: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired
};

export default TaskType;
