import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import fromTypes from "../actions/types";
import { onAddFailed, onAddSuccess } from "../actions";

const staffAddEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.STAFF_SAVE_CLICKED),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/staff/createorupdate`,
					{
						id: state$.value.staff.currentStaff.id,
						organisationId:
							state$.value.staff.currentStaff.organisationId,
						firstName: state$.value.staff.currentStaff.firstName,
						lastName: state$.value.staff.currentStaff.lastName,
						email: state$.value.staff.currentStaff.email,
						phoneNumber:
							state$.value.staff.currentStaff.phoneNumber,
						emailInvitation:
							state$.value.staff.currentStaff.emailInvitation,
						status: state$.value.staff.currentStaff.status,
						staffIdNumber:
							state$.value.staff.currentStaff.staffIdNumber,
						supervisor: state$.value.staff.currentStaff.supervisor,
						notes: state$.value.staff.currentStaff.notes,
						organisationRole:
							state$.value.staff.currentStaff.organisationRole,
						attachments:
							state$.value.staff.currentStaff.attachments,
						organisationStaffTypeId:
							state$.value.staff.currentStaff
								.organisationStaffTypeId,
						organisationStaffTypesStaffType:
							state$.value.staff.currentStaff
								.organisationStaffTypesStaffType,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onAddSuccess(ajaxResponse.response))
					),
					catchError((error) => of(onAddFailed(error)))
				)
		)
	);

export default staffAddEffect;
