import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { usePosition } from "./usePosition";
import Ribbon from "./ribbon";
import moment from "moment";

const Geolocation = ({ setGeolocation }) => {
	const { latitude, longitude, timestamp, error } = usePosition();

	useEffect(() => {
		setGeolocation({
			latitude,
			longitude,
			timestamp: moment(timestamp).format("D MMM YYYY h:mm A"),
			error,
		});
		// eslint-disable-next-line
	}, [latitude, longitude]);

	// Hidden Component
	return (
		<div>
			{/* <p>Latitude: {latitude}</p>
      <p>Longitude: {longitude}</p>
      <p>Timestamp: {timestamp}</p>
      <p>error: {error}</p> */}
			{!!error && <Ribbon text={error} />}
		</div>
	);
};

Geolocation.propTypes = {
	setGeolocation: PropTypes.func.isRequired,
};

export default Geolocation;
