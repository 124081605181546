import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Page, PageContent, ScrollToTopOnMount } from "frame/components";
import { useRouter } from "utils";
// import * as fromHealthSafetyForm from 'features/health-safety-forms'
import AccidentCard from "../cards/accident-card";
import { EditIncidentForm } from "features/health-safety-forms";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.white,
	},
	heading: {
		marginTop: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0),
		},
	},
	list: {
		margin: theme.spacing(3, 0),
	},
	filter: { display: "flex" },
}));

const IncidentSiteList = ({ onDelete, role }) => {
	const classes = useStyles();
	const { match } = useRouter();
	const { siteId } = match.params;

	const [editId, setEditId] = useState();
	const [siteIncidents, setSiteIncidents] = useState(null);

	const incidentList = useSelector(
		({ healthSafety }) => healthSafety.incidents?.results
	);

	useEffect(() => {
		async function fetchData() {
			setSiteIncidents(
				incidentList
					? incidentList.filter((node) => node.siteId === siteId)
					: []
			);
		}
		fetchData();
		// eslint-disable-next-line
	}, [setSiteIncidents, siteId]);

	const empty = siteIncidents && siteIncidents.length <= 0;

	const onEdit = (id) => () => setEditId(id);

	const onCancelEdit = () => setEditId(null);

	return (
		<Page className={classes.root} title="Incident Records">
			<ScrollToTopOnMount />

			<PageContent pad>
				<Typography variant="h2" className={classes.heading}>
					Incident Records
				</Typography>

				{!empty && (
					<div className={classes.list}>
						{siteIncidents &&
							siteIncidents.map((node, index) => (
								<AccidentCard
									key={index}
									accident={node}
									role={role}
									onEdit={onEdit(node.id)}
									onDelete={onDelete}
								/>
							))}
					</div>
				)}

				{empty && (
					<Typography variant="body1">
						There are currently no Incidents listed.
					</Typography>
				)}
			</PageContent>
			{editId && <EditIncidentForm id={editId} onClose={onCancelEdit} />}
		</Page>
	);
};

IncidentSiteList.propTypes = {
	role: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default IncidentSiteList;
