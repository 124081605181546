import { connect } from "react-redux";
import Geolocation from "./geolocation";
import { setGeolocation } from "../actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	setGeolocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Geolocation);
