import { useSelector } from "react-redux";

/******************************************************************************************** */
/* Current user helper functions                                                              */
/******************************************************************************************** */
// function to get current selected organsition id
export const useCurrentOrganisation = () =>
	useSelector((state) => state?.organisations?.currentOrganisation);

// function to get current selected organsition Details
export const useCurrentOrganisationDetails = () => {
	const currentOrgId = useSelector(
		(state) => state?.organisations?.currentOrganisation
	);
	const orgList = useSelector((state) => state?.organisations?.data);

	const currentOrgDetails = orgList.filter((o) => o.id === currentOrgId)[0];
	return currentOrgDetails;
};

// function to get user profile
export const useCurrentUser = () => useSelector((state) => state?.profile);

// function to get user email
export const useCurrentUserEmail = () =>
	useSelector((state) => state?.profile?.email);

// function to get user role
export const useCurrentRole = () =>
	useSelector((state) => state?.profile?.role);

// function for check email is born super admin user email
export const isBornAccount = (email) =>
	email === "safetywisenotifications@sandfield.co.nz";

// function for validating is born super admin user
export const useIsBornAccount = () => {
	let userEmail = useSelector((state) => state?.profile?.email);
	return userEmail && isBornAccount(userEmail);
};
