import React from "react";
import { Route, Switch } from "react-router-dom";
import DefaultLayout from "./default-layout-container";
import GoogleAnalytics from "./google-analytics";
import { isProd } from "utils";

import {
	Login,
	Logout,
	Register,
	ForgotPassword,
	SetPassword,
	ResetPassword,
	Confirmation,
	LOGIN_ROUTE,
	LOGOUT_ROUTE,
	REGISTER_ROUTE,
	FORGOT_PASSWORD_ROUTE,
	SET_PASSWORD_ROUTE,
	RESET_PASSWORD_ROUTE,
	CONFIRMATION_ROUTE,
} from "features/authorisation";

const Routes = () => {
	return (
		<>
			{isProd && <GoogleAnalytics />}
			<Switch>
				<Route exact path={LOGIN_ROUTE} component={Login} />
				<Route exact path={REGISTER_ROUTE} component={Register} />
				<Route
					exact
					path={FORGOT_PASSWORD_ROUTE}
					component={ForgotPassword}
				/>
				<Route
					exact
					path={SET_PASSWORD_ROUTE}
					component={SetPassword}
				/>
				<Route
					exact
					path={RESET_PASSWORD_ROUTE}
					component={ResetPassword}
				/>
				<Route
					exact
					path={CONFIRMATION_ROUTE}
					component={Confirmation}
				/>
				<Route path={LOGOUT_ROUTE} component={Logout} />
				<Route path="/" component={DefaultLayout} />
			</Switch>
		</>
	);
};

export default Routes;
