export const ORGANISATIONS_PATH = "/organisations";
export const ORGANISATIONS_ROUTE = "/organisations";

export const ORGANISATION_RESET = "ORGANISATION_RESET";
export const ORGANISATION_FETCH_REQUESTED = "ORGANISATION_FETCH_REQUESTED";
export const ORGANISATION_FETCH_FAILED = "ORGANISATION_FETCH_FAILED";
export const ORGANISATION_FETCH_SUCCESS = "ORGANISATION_FETCH_SUCCESS";
export const ORGANISATIONS_CURRENT_ORGANISATION_CHANGED = `ORGANISATIONS_CURRENT_ORGANISATION_CHANGED`;
export const ORGANISATIONS_CURRENT_ORGANISATION_CHANGED_FAILED = `ORGANISATIONS_CURRENT_ORGANISATION_CHANGED_FAILED`;
export const ORGANISATIONS_CURRENT_ORGANISATION_CHANGED_SUCCESS = `ORGANISATIONS_CURRENT_ORGANISATION_CHANGED_SUCCESS`;

export const ORGANISATIONS_EDIT = `ORGANISATIONS_EDIT`;
export const ORGANISATIONS_EDIT_CLOSE = `ORGANISATIONS_EDIT_CLOSE`;
export const ORGANISATIONS_EDIT_SAVE = `ORGANISATIONS_EDIT_SAVE`;
export const ORGANISATIONS_EDIT_SAVE_SUCCESS = `ORGANISATIONS_EDIT_SAVE_SUCCESS`;
export const ORGANISATIONS_EDIT_SAVE_FAILED = `ORGANISATIONS_EDIT_SAVE_FAILED`;

export const ORGANISATIONS_EDIT_NAME_CHANGED =
	"ORGANISATIONS_EDIT_NAME_CHANGED";
export const ORGANISATION_SEARCH_CHANGED = "ORGANISATION_SEARCH_CHANGED";

export const ORGANISATION_DELETE = "ORGANISATION_DELETE";
export const ORGANISATION_DELETE_FAILED = "ORGANISATION_DELETE_FAILED";
export const ORGANISATION_DELETE_SUCCESS = "ORGANISATION_DELETE_SUCCESS";

export const ORGANISATION_UPDATE_PREFERENCE = "ORGANISATION_UPDATE_PREFERENCE";
export const ORGANISATION_UPDATE_PREFERENCE_FAILED =
	"ORGANISATION_UPDATE_PREFERENCE_FAILED";
export const ORGANISATION_UPDATE_PREFERENCE_SUCCESS =
	"ORGANISATION_UPDATE_PREFERENCE_SUCCESS";

export const ORGANISATION_REFETCH_LIST = "ORGANISATION_REFETCH_LIST";
export const ORGANISATION_REFETCH_LIST_FAILED =
	"ORGANISATION_REFETCH_LIST_FAILED";
export const ORGANISATION_REFETCH_LIST_SUCCESS =
	"ORGANISATION_REFETCH_LIST_SUCCESS";

export const ORGANISATION_CLEANUP = "ORGANISATION_CLEANUP";

export const ORGANISATION_STATUS_FILTER = "ORGANISATION_STATUS_FILTER";

export default {
	ORGANISATION_RESET,
	ORGANISATION_FETCH_REQUESTED,
	ORGANISATION_FETCH_FAILED,
	ORGANISATION_FETCH_SUCCESS,

	ORGANISATIONS_CURRENT_ORGANISATION_CHANGED,
	ORGANISATIONS_CURRENT_ORGANISATION_CHANGED_FAILED,
	ORGANISATIONS_CURRENT_ORGANISATION_CHANGED_SUCCESS,

	ORGANISATIONS_EDIT,
	ORGANISATIONS_EDIT_CLOSE,

	ORGANISATIONS_EDIT_SAVE,
	ORGANISATIONS_EDIT_SAVE_SUCCESS,
	ORGANISATIONS_EDIT_SAVE_FAILED,

	ORGANISATIONS_EDIT_NAME_CHANGED,
	ORGANISATION_SEARCH_CHANGED,

	ORGANISATION_DELETE,
	ORGANISATION_DELETE_FAILED,
	ORGANISATION_DELETE_SUCCESS,

	ORGANISATION_UPDATE_PREFERENCE,
	ORGANISATION_UPDATE_PREFERENCE_FAILED,
	ORGANISATION_UPDATE_PREFERENCE_SUCCESS,

	ORGANISATION_REFETCH_LIST,
	ORGANISATION_REFETCH_LIST_FAILED,
	ORGANISATION_REFETCH_LIST_SUCCESS,
	ORGANISATION_CLEANUP,

	ORGANISATION_STATUS_FILTER,
};
