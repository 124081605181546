import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clip from "text-clipper";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Card, CardContent, Typography, Grid, Chip } from "@material-ui/core";
import { capitalize, formatDate, createRowSelector } from "utils";
import { AttachmentListing, TextClip } from "frame/components";
import SiteCrudOptions from "./site-crud-options";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
		"&:hover": {
			cursor: "pointer",
		},
	},
	container: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(3),
		},
	},
	label: { color: "#999999" },
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
	inactive: {
		background: theme.palette.background.tertiary,
	},
	valueInactive: {
		color: theme.palette.text.tertiary,
	},
}));

const HazardousSubstanceCard = ({
	hazardousSubstance,
	role,
	currentSite,
	onDelete,
	onEdit,
	onView,
}) => {
	const classes = useStyles();
	const onRowSelect = createRowSelector(onView);
	const [currentSubstance, setCurrentSubstance] = useState(null);

	const hazardousSubstanceList = useSelector(
		({ healthSafety }) => healthSafety.hazardousSubstances?.results
	);

	useEffect(() => {
		async function fetchData() {
			const data =
				hazardousSubstanceList &&
				hazardousSubstanceList.filter(
					(hs) => hs.id === hazardousSubstance.hazardousSubstanceId
				)[0];
			setCurrentSubstance(data || null);
		}

		if (hazardousSubstance.hazardousSubstanceId) {
			fetchData();
		} // eslint-disable-next-line
	}, [hazardousSubstance.hazardousSubstanceId, setCurrentSubstance]);

	// const currentSubstance = hazardousSubstance

	const isActive = hazardousSubstance.status.toLowerCase() === "active";

	// const onDeleteHandler = () => {
	//   const substances = currentSite.substances.filter(id => id !== hazardousSubstance.id)
	//   onDelete({ ...currentSite, substances })
	// }

	const onToggleStatus = (status) => () => {
		if (hazardousSubstance && currentSite) {
			const index = currentSite.substances.indexOf(hazardousSubstance);
			currentSite.substances[index].status = status;
			onDelete({ ...currentSite });
		}
	};

	const isArchivedSubstance =
		currentSubstance && currentSubstance.status !== "Active";

	const rootStyle = clsx(
		classes.root,
		isArchivedSubstance ? classes.inactive : ""
	);

	const valueStyle = clsx(
		classes.value,
		isArchivedSubstance ? classes.valueInactive : ""
	);

	return (
		<Card className={rootStyle} onClick={onRowSelect}>
			<CardContent className={classes.content}>
				<Grid className={classes.container} container spacing={2}>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Date
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentSubstance
								? formatDate(currentSubstance.createdAt)
								: "-"}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Name
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentSubstance && currentSubstance.name
								? clip(currentSubstance.name, 140)
								: "-"}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Substance Type
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentSubstance ? (
								<TextClip
									text={currentSubstance.substanceType}
									numberOfCharacters={140}
								/>
							) : (
								"-"
							)}
						</Typography>
					</Grid>
					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Volume
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentSubstance && hazardousSubstance.volume
								? `${hazardousSubstance.volume} ${currentSubstance.volumeMeasurement}`
								: "-"}
						</Typography>
					</Grid>
					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Supplier SDS Report Held
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentSubstance &&
							currentSubstance.supplierSDSReportHeld
								? capitalize(
										currentSubstance.supplierSDSReportHeld
								  )
								: "-"}
						</Typography>
					</Grid>
					{/* <Grid item md={3} xs={6}>
            <Typography variant="body2" className={classes.label}>Initial Risk Assessment</Typography>
            <Typography variant="h5" className={valueStyle}>
              {currentSubstance.initialRiskAssessment ? capitalize(currentSubstance.initialRiskAssessment) : '-'}
            </Typography>
          </Grid>
          <Grid item md={3} xs={6}>
            <Typography variant="body2" className={classes.label}>Residual Risk Assessment</Typography>
            <Typography variant="h5" className={valueStyle}>
              {currentSubstance.residualRiskAssessment ? capitalize(currentSubstance.residualRiskAssessment) : '-'}
            </Typography>
          </Grid> */}
					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Protective Clothing Required
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentSubstance &&
							currentSubstance.protectiveClothingRequired
								? capitalize(
										currentSubstance.protectiveClothingRequired
								  )
								: "-"}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Action Recommended
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentSubstance ? (
								<TextClip
									text={currentSubstance.actionRecommended}
									numberOfCharacters={140}
								/>
							) : (
								"-"
							)}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Location
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{hazardousSubstance.location ? (
								<TextClip
									text={hazardousSubstance.location}
									numberOfCharacters={100}
								/>
							) : (
								"-"
							)}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Date Measured
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{hazardousSubstance.dateMeasured
								? formatDate(hazardousSubstance.dateMeasured)
								: "-"}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Status
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{hazardousSubstance &&
								hazardousSubstance.status &&
								!isArchivedSubstance && (
									<Chip
										className={
											hazardousSubstance.status ===
											"Active"
												? classes.success
												: classes.error
										}
										size="small"
										label={hazardousSubstance.status}
									/>
								)}
							{isArchivedSubstance && (
								<Chip
									className={classes.error}
									size="small"
									label="Archived in substance library"
								/>
							)}
							{!hazardousSubstance.status &&
								!isArchivedSubstance &&
								"-"}
						</Typography>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Files
						</Typography>
						{currentSubstance ? (
							<AttachmentListing
								attachments={currentSubstance.attachments}
							/>
						) : (
							"-"
						)}
					</Grid>

					<div className="ignore">
						{!isArchivedSubstance && (
							<SiteCrudOptions
								onArchive={
									isActive ? onToggleStatus("Archived") : null
								}
								onMakeActive={
									!isActive ? onToggleStatus("Active") : null
								}
								onEdit={onEdit}
								onView={onView}
								// onDelete={onDeleteHandler}
							/>
						)}
					</div>
				</Grid>
			</CardContent>
		</Card>
	);
};

HazardousSubstanceCard.propTypes = {
	hazardousSubstance: PropTypes.object.isRequired,
	currentSite: PropTypes.object,
	onDelete: PropTypes.func.isRequired,
	onView: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
};

export default HazardousSubstanceCard;
