import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography, Divider } from "@material-ui/core";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	AddButton,
} from "frame/components";
import { sortByPropertyDate, useRouter } from "utils";
import {
	AddHazardToSiteForm,
	EditHazardToSiteForm,
	EditHazardForm,
} from "features/health-safety-forms";
import SiteHazardCard from "../cards/hazard-site-card";
import { isNotNullOrEmpty, isDefaultGuid } from "utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.white,
		position: "relative",
	},
	body: {
		marginTop: theme.spacing(3),
	},
	heading: {
		marginTop: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0),
		},
	},
	addButton: {
		position: "absolute",
		top: 15,
		right: 0,
		[theme.breakpoints.down("xs")]: {
			position: "relative",
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(3),
			top: 0,
		},
	},
	empty: {
		marginTop: theme.spacing(3),
	},
	divider: {
		marginTop: theme.spacing(2),
		height: 1,
	},
}));

const HazardSiteList = ({ values, role, onAdd, onEdit, onDelete }) => {
	const classes = useStyles();

	const { match } = useRouter();
	const { siteId } = match.params;

	const [editId, setEditId] = useState(null);
	const [siteHazards, setSiteHazards] = useState([]);
	const [currentSite, setCurrentSite] = useState(null);
	const [viewId, setViewId] = useState();

	const siteList = useSelector(({ sites }) => sites.pagination?.results);

	useEffect(() => {
		async function fetchSiteData() {
			const site = siteList && siteList.filter((s) => s.id === siteId)[0];
			setSiteHazards(site ? site.hazards : []);
			setCurrentSite(site || null);
		}

		if (siteId && !isDefaultGuid(siteId)) {
			fetchSiteData();
		} // eslint-disable-next-line
	}, [siteId, setSiteHazards, values.hazards]);

	// const currentSite = sites.filter(({ id }) => id === siteId)[0]

	// const siteHazards = hazards
	//   .filter(node => currentSite.hazards.includes(currentSite.hazards.find(i => i.hazardId === node.id ))) // removes duplicates
	//   .sort(sortByPropertyDate('createdAt'))

	// const isArchived = (hazard) => {
	//   const siteHazard = currentSite.hazards.filter(node => node.hazardId === hazard.id)[0]
	//   return siteHazard && String(siteHazard.status).toLowerCase() === 'archived'
	// }

	const isArchived = (item) => {
		// function to check if substance is archived
		if (item.status == null) {
			item.status = "Active";
		}
		return String(item.status).toLowerCase() === "archived";
	};

	const archivedSiteHazards = [...siteHazards]
		.filter((node) => isArchived(node))
		.sort(sortByPropertyDate("createdAt"));

	const activeSiteHazards = [...siteHazards]
		.filter((node) => !isArchived(node))
		.sort(sortByPropertyDate("createdAt"));

	const empty = siteHazards.length <= 0;
	const hasArchivedSiteHazards = archivedSiteHazards.length > 0;
	const hasActiveSiteHazards = activeSiteHazards.length > 0;

	const onEditHandler = (hazardId) => () => {
		onEdit(); // opens the editSiteHazard modal
		setEditId(hazardId);
	};

	// use to compare which entries are preExisting to know which to hide on "add hazard to site" form load
	const preExistingSiteHazards = isNotNullOrEmpty(siteHazards)
		? siteHazards.map((s) => s.hazardId)
		: [];

	const onView = (hazardId) => () => setViewId(hazardId);

	const onCancelView = () => setViewId(null);

	const renderSiteHazards = (list) =>
		list &&
		list.map((data, index) => (
			<SiteHazardCard
				key={index}
				currentSite={currentSite}
				onView={onView(data.hazardId)}
				onEdit={onEditHandler(data.hazardId)}
				onDelete={onDelete}
				hazard={data}
				role={role}
			/>
		));

	return (
		<Page className={classes.root} title="Hazards">
			<ScrollToTopOnMount />
			<PageContent pad>
				<Typography variant="h2" className={classes.heading}>
					Hazards
				</Typography>
				{/* <pre>
          {JSON.stringify({
            hazards: values.hazards,
            siteHazards: siteHazards,
          }, null ,2)}
        </pre> */}

				<div className={classes.addButton}>
					<AddButton
						// staffUserHide
						addTabTitle="Add hazard to site"
						onAdd={onAdd}
					/>
				</div>

				{empty && hasActiveSiteHazards && (
					<Typography className={classes.empty} variant="body1">
						There are currently no Hazards listed.
					</Typography>
				)}

				<div className={classes.body}>
					{!empty && hasActiveSiteHazards && (
						<>
							<div className={classes.header}>
								<Typography
									className={classes.title}
									variant="h4"
								>
									Active
								</Typography>
								{/* <Divider className={classes.divider} /> */}
							</div>
							{renderSiteHazards(activeSiteHazards)}
						</>
					)}

					{!empty && hasArchivedSiteHazards && (
						<>
							<div className={classes.header}>
								<Typography
									className={classes.title}
									variant="h4"
								>
									Archived
								</Typography>
								<Divider className={classes.divider} />
							</div>
							{renderSiteHazards(archivedSiteHazards)}
						</>
					)}
				</div>
			</PageContent>

			<AddHazardToSiteForm
				siteId={siteId}
				preExistingSiteHazards={preExistingSiteHazards}
			/>

			{editId && (
				<EditHazardToSiteForm siteId={siteId} hazardId={editId} />
			)}
			{viewId && (
				<EditHazardForm readOnly id={viewId} onClose={onCancelView} />
			)}
		</Page>
	);
};

HazardSiteList.propTypes = {
	values: PropTypes.object.isRequired,
	role: PropTypes.string.isRequired,
	onAdd: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default HazardSiteList;
