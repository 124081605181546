import fromTypes from "../actions/types";
import * as fromOrganisations from "features/organisations";
import { onListFailed, onListReceived } from "../actions";
import { ofType } from "redux-observable";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { authHeaders, tokenExists, getOrganisationBaseUrl } from "frame";
import fromQueue from "features/queue/actions/types";

export const jobListEffect = (action$, state$) =>
	action$.pipe(
		ofType(
			fromTypes.JOBS_LIST_REQUESTED,
			fromOrganisations.ORGANISATION_FETCH_SUCCESS,
			fromOrganisations.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED,
			fromQueue.QUEUE_SEND_SUCCEEDED
		),
		filter(() => tokenExists()),
		filter(() => !!state$.value.organisations.currentOrganisation),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/job/paged`,
					{
						page: 1,
						pageSize: 5,
						orderBy: "createdAt",
						ascending: false,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onListReceived(ajaxResponse.response))
					),
					catchError((error) => of(onListFailed(error)))
				)
		)
	);

export const jobPagedListEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.JOBS_PAGED_LIST_REQUESTED),
		filter(() => state$.value.organisations.currentOrganisation),
		filter(() => tokenExists()),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(({ payload }) => {
			const pagingParams = payload;
			return ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/job/paged`,
					pagingParams,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onListReceived(ajaxResponse.response))
					),
					catchError((error) => of(onListFailed(error)))
				);
		})
	);
