import * as R from "ramda";
import { defaultPaginationState } from "utils";
import { timesheetActionTypes } from "./timesheet-actions";

export const initialTimesheetState = {
	...defaultPaginationState,
	refreshList: false,
	pageSize: 5
};

export const timesheetReducer = (state = initialTimesheetState, action) => {
	switch (action.type) {
		case timesheetActionTypes.TIMESHEETS_SEARCH_CLEARED:
			return searchClear(R.clone(state));

		case timesheetActionTypes.TIMESHEETS_LIST_FAILED:
			return listRequestedFailed(R.clone(state), action);

		case timesheetActionTypes.TIMESHEETS_SEARCH_CHANGED:
			return searchChanged(R.clone(state), action);

		case timesheetActionTypes.REFRESH_TIMESHEET_LIST:
			return triggerTimesheetRefresh(R.clone(state));

		default:
			return state;
	}
};

const listRequestedFailed = (state, action) => {
	return {
		...state,
		flag: "error",
		error: action.payload || "error occurred",
	};
};

const searchChanged = (state, action) => {
	state.searchFilter = action.payload || "";
	return state;
};

const searchClear = (state) => {
	state.searchFilter = "";
	return state;
};

const triggerTimesheetRefresh = (state) => {
	state.refreshList = !state.refreshList;
	return state;
};
