export default {
	organisation: "",
	firstName: "",
	lastName: "",
	phone: "",
	emailAddress: "",
	password: "",
	registerErrorMessage: "",
	isValid: false,
};
