import moment from "moment";

const TOKEN_KEY = "safetywiseapiToken";
const TOKEN_EXPIRY_KEY = "safetywiseapiTokenExpiry";
const IMPERSONATION_TOKEN_KEY = "safetywiseapiImpersonationToken";
const IMPERSONATION_TOKEN_EXPIRY_KEY = "safetywiseapiImpersonationTokenExpiry";

export const tokenExists = () => {
	let token = getToken();
	if (!token) {
		return false;
	}

	let tokenExpiryTimestamp = getTokenExpiry();
	if (!tokenExpiryTimestamp) {
		return false;
	}

	let nowValue = moment().valueOf();
	let expiryValue = parseInt(tokenExpiryTimestamp);
	return expiryValue > nowValue;
};

export const getToken = () => {
	const impersonationToken = localStorage.getItem(IMPERSONATION_TOKEN_KEY);

	if (impersonationToken) {
		return impersonationToken;
	}

	const token = localStorage.getItem(TOKEN_KEY);

	return token || null;
};

export const getTokenExpiry = () => {
	const impersonationTokenExpiry = localStorage.getItem(
		IMPERSONATION_TOKEN_EXPIRY_KEY
	);

	if (impersonationTokenExpiry) {
		return impersonationTokenExpiry;
	}

	const tokenExpiry = localStorage.getItem(TOKEN_EXPIRY_KEY);

	return tokenExpiry || null;
};

export const setToken = (token, expiresInSeconds) => {
	localStorage.setItem(TOKEN_KEY, token);
	localStorage.setItem(
		TOKEN_EXPIRY_KEY,
		moment().add(expiresInSeconds, "seconds").valueOf()
	);
};

export const setImpersonationToken = (token, expiresInSeconds) => {
	localStorage.setItem(IMPERSONATION_TOKEN_KEY, token);
	localStorage.setItem(
		IMPERSONATION_TOKEN_EXPIRY_KEY,
		moment().add(expiresInSeconds, "seconds").valueOf()
	);
};

export const clearToken = () => {
	localStorage.removeItem(TOKEN_KEY);
	localStorage.removeItem(TOKEN_EXPIRY_KEY);
};

export const clearImpersonationToken = () => {
	localStorage.removeItem(IMPERSONATION_TOKEN_KEY);
	localStorage.removeItem(IMPERSONATION_TOKEN_EXPIRY_KEY);
};

export const isInImpersonationMode = () => {
	return !!localStorage.getItem(IMPERSONATION_TOKEN_KEY);
};
