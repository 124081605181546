import * as fromAuth from "features/authorisation";

export const routeNotWhitelisted = (currentPath) => {
	const whitelist = [
		fromAuth.REGISTER_PATH,
		fromAuth.SET_PASSWORD_PATH,
		fromAuth.RESET_PASSWORD_PATH,
		fromAuth.CONFIRMATION_PATH,
		fromAuth.LOGIN_PATH,
	];
	return whitelist.includes(currentPath);
};
