import { connect } from "react-redux";
import {
	onList,
	onAdd,
	onSave,
	onRemove
} from "../actions";
import TaskType from "./task-type";

const mapStateToProps = ({ taskTypes }) => ({
	taskTypes: taskTypes.data,
	isLoadingList: taskTypes.isLoadingList
});

const mapDispatchToProps = {
	onList,
	onAdd,
	onSave,
	onRemove
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskType);
