import { connect } from "react-redux";
import Staff from "./staff";
import { getStaffById } from "../utils";

const mapStateToProps = (state) => ({
	person: getStaffById(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
