import Types from "../actions/types";
import { onSaveQueued } from "../actions";
import { ofType } from "redux-observable";
import { getOrganisationBaseUrl } from "frame";
import { mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { ADD_JOB_PATH, JOBS_PATH } from "../actions/types";
import { push } from "connected-react-router";
import { getCurrentTimestamp } from "utils";
import uuid from "uuid";
import { addQueueItem, sendRequested } from "features/queue";
import { setGlobalMessage } from "../actions";

const jobAddEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.JOBS_SAVE_CLICKED),
		mergeMap(() => {
			let url = `${getOrganisationBaseUrl(
				state$.value
			)}/job/createorupdate`;
			const currentJob = state$.value.jobs.currentJob;

			let body = {
				id: currentJob.id,
				title: currentJob.title,
				createdAt: currentJob.createdAt,
				type: currentJob.type,
				customerId: currentJob.customerId,
				siteId: currentJob.siteId,
				staffId: currentJob.staffId,
				notes: currentJob.notes,
				attachments: currentJob.attachments,
				latitude: state$.value.geolocation.latitude,
				longitude: state$.value.geolocation.longitude,
				timestamp: getCurrentTimestamp(),
				organisationId: state$.value.organisations.currentOrganisation,
			};

			let path = JOBS_PATH;
			if (window.location.pathname !== ADD_JOB_PATH) {
				path = window.location.pathname;
			}

			let itemId = uuid.v4()

			return of(
				addQueueItem({ id: itemId, url, body }),
				onSaveQueued(),
				sendRequested(),
				push(path),
				setGlobalMessage([...state$.value.jobs.globalMessage, [itemId , `Syncing ${currentJob.title}... please do not refresh.`]])
			);
		})
	);

export default jobAddEffect;
