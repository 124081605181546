import { connect } from "react-redux";
import EditJobModal from "./edit-job-modal";

import {
	onTitleChanged,
	onDateChanged,
	onTypeChanged,
	onCustomerIdChanged,
	onSiteIdChanged,
	onStaffIdChanged,
	onNotesChanged,
	onFileChanged,
	onSave,
	onClose,
} from "../../actions";

const mapStateToProps = ({ customers, jobs, sites, staff }) => ({
	job: jobs.currentJob,
	customerList: customers.pagination?.results || [],
	siteList: sites.pagination?.results || [],
	staffList: staff.pagination?.results || [],
	isAddModalShowing: jobs.isAddModalShowing,
	isEditModalShowing: jobs.isEditModalShowing,
});

const mapDispatchToProps = {
	onTitleChanged,
	onDateChanged,
	onTypeChanged,
	onCustomerIdChanged,
	onSiteIdChanged,
	onStaffIdChanged,
	onNotesChanged,
	onFileChanged,
	onSave,
	onClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditJobModal);
