import { connect } from "react-redux";
import ForgotPassword from "./forgot-password";

import { onForgotPassword, onChangeEmail } from "../actions";

const mapStateToProps = ({ authorisation }) => ({
	emailAddress: authorisation.login.emailAddress,
	errorMessage: authorisation.forgotPassword.errorMessage,
	save: authorisation.forgotPassword.save,
});

const mapDispatchToProps = {
	onForgotPassword,
	onChangeEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
