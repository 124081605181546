import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError } from "rxjs/operators";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import { onListRequested, onDeleteSucceeded, onDeleteFailed } from "../actions";
import fromTypes from "../actions/types";

const siteRemoveEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.SITES_REMOVE_CLICKED),
		mergeMap(({ payload }) =>
			ajax
				.delete(
					`${getOrganisationBaseUrl(
						state$.value
					)}/site/delete/${payload}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							onDeleteSucceeded(ajaxResponse.response),
							onListRequested()
						)
					),
					catchError((error) => of(onDeleteFailed(error)))
				)
		)
	);

export default siteRemoveEffect;
