import { connect } from "react-redux";
import SafetyPlanChecklistSiteList from "./safety-plan-checklist-site-list";
import { onRemove } from "../../actions";

const mapStateToProps = ({ profile }) => ({
	role: profile.role,
});

const mapDispatchToProps = {
	onDelete: onRemove,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SafetyPlanChecklistSiteList);
