export const UPLOAD_COMPRESSION_REQUESTED = "UPLOAD_COMPRESSION_REQUESTED";
export const UPLOAD_COMPRESSION_FINISHED = "UPLOAD_COMPRESSION_FINISHED";
export const UPLOAD_COMPRESSION_ERRORED = "UPLOAD_COMPRESSION_ERRORED";

export const UPLOAD_LOAD_ATTACHMENTS = "UPLOAD_LOAD_ATTACHMENTS";

export const UPLOAD_REMOVE_ITEM = "UPLOAD_REMOVE_ITEM";
export const UPLOAD_REMOVE = "UPLOAD_REMOVE";
export const UPLOAD_RESET = "UPLOAD_RESET";

export default {
	UPLOAD_COMPRESSION_REQUESTED,
	UPLOAD_COMPRESSION_FINISHED,
	UPLOAD_COMPRESSION_ERRORED,
	UPLOAD_LOAD_ATTACHMENTS,
	UPLOAD_REMOVE_ITEM,
	UPLOAD_REMOVE,
	UPLOAD_RESET,
};
