import { connect } from "react-redux";
import HealthSafetyHome from "./health-safety-home";

const mapStateToProps = ({ profile }) => ({
	role: profile.role,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HealthSafetyHome);
