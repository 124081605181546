import axios from "axios";
import { getBaseUrl, authHeaders } from "frame";

function get(path) {
	return axios.get(`${getBaseUrl()}/api/${path}`, {
		headers: authHeaders(),
	});
}

function post(path, data) {
	return axios.post(`${getBaseUrl()}/api/${path}`, data, {
		headers: authHeaders(),
	});
}

function del(path) {
	return axios.delete(`${getBaseUrl()}/api/${path}`, {
		headers: authHeaders(),
	});
}

export const safetyWiseApi = {
	get,
	post,
	del,
};
