import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Lookup } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {},
	label: {
		color: "#999999",
	},
}));

const RelatedHazardSubstanceListing = ({
	relatedHazardOrSubstanceList = [],
	noTitle,
	useBulletPoints,
}) => {
	const classes = useStyles();

	const displayValue = (value) =>
		value.key === "hazards" ? (
			<Typography variant="h5">
				{useBulletPoints && `• `}
				{<Lookup id={value.id} resource="hazards" isHealthSafety />}
			</Typography>
		) : (
			<Typography variant="h5">
				{useBulletPoints && `• `}
				{
					<Lookup
						id={value.id}
						resource="hazardousSubstances"
						isHealthSafety
					/>
				}
			</Typography>
		);

	return (
		<>
			{!noTitle && (
				<Typography variant="body2" className={classes.label}>
					Related Hazards or Substances
				</Typography>
			)}

			{relatedHazardOrSubstanceList
				? relatedHazardOrSubstanceList.map((item) => displayValue(item))
				: "-"}
			{relatedHazardOrSubstanceList &&
				relatedHazardOrSubstanceList.length === 0 &&
				"-"}
		</>
	);
};

RelatedHazardSubstanceListing.propTypes = {
	relatedHazardOrSubstanceList: PropTypes.array,
	noTitle: PropTypes.bool,
	useBulletPoints: PropTypes.bool,
};

export default RelatedHazardSubstanceListing;
