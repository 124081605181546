import React, { useCallback, useContext } from "react";
import clsx from "clsx";
import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { __RouterContext } from "react-router";

import {
	HOME_ROUTE,
	INFO_HOME_ROUTE,
	TASK_HOME_ROUTE,
} from "features/home/home-routes";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ViewListIcon from "@material-ui/icons/ViewList";

const useRouter = () => useContext(__RouterContext);

const useStyles = makeStyles({
	root: {},
	stickToBottom: {
		width: "100%",
		position: "relative",
		bottom: 0,
		height: "75px",
	},
	active: {
		color: "#D6DD37",
	},
	label: {
		fontWeight: "bold",
		letterSpacing: 0.5,
	},
});

export default function LabelBottomNavigation() {
	const classes = useStyles();
	const { history, location } = useRouter();
	const [value, setValue] = React.useState("recents");

	const onChange = (event, newValue) => setValue(newValue);

	const onNavigate = useCallback(
		(path) => () => {
			history.push(path); // todo: dispatch push()
		},
		[history]
	);

	const className = (path) =>
		location.pathname === path ? classes.active : "";

	return (
		<div className={classes.root}>
			<Hidden smUp>
				<BottomNavigation
					value={value}
					onChange={onChange}
					className={classes.stickToBottom}
				>
					<BottomNavigationAction
						classes={{
							label: clsx(classes.label, className(HOME_ROUTE)),
						}}
						label="Home"
						value="recents"
						icon={
							<DashboardIcon className={className(HOME_ROUTE)} />
						}
						onClick={onNavigate(HOME_ROUTE)}
					/>
					<BottomNavigationAction
						classes={{
							label: clsx(
								classes.label,
								className(INFO_HOME_ROUTE)
							),
						}}
						label="Info"
						value="recents"
						icon={
							<EqualizerIcon
								className={className(INFO_HOME_ROUTE)}
							/>
						}
						onClick={onNavigate(INFO_HOME_ROUTE)}
					/>
					<BottomNavigationAction
						classes={{
							label: clsx(
								classes.label,
								className(TASK_HOME_ROUTE)
							),
						}}
						label="Tasks"
						value="recents"
						icon={
							<ViewListIcon
								className={className(TASK_HOME_ROUTE)}
							/>
						}
						onClick={onNavigate(TASK_HOME_ROUTE)}
					/>
				</BottomNavigation>
			</Hidden>
		</div>
	);
}
