/******************************************************************************************** */
/* Role Check functions */
/******************************************************************************************** */
export const isNonStaffUser = (role) => {
	return role === "Staff Non User";
};

export const isStaffUser = (role) => {
	return role === "Staff User";
};

export const isAccountAdminUser = (role) => {
	return role === "Account Administrator";
};

export const isSuperAdminUser = (role) => {
	return role === "Super Admin";
};

export const isConsultantUser = (role) => {
	return role === "Consultant";
};
