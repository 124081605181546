import Types from "./types";

export const InitialiseRequested = () => ({
	type: Types.FRAME_INITIALISE_REQUESTED,
});

export const onLogout = () => ({
	type: Types.FRAME_SIGNOUT_CLICKED,
});

export const mobileNavClosed = () => ({
	type: Types.FRAME_MOBILE_NAV_CLOSED,
});

export const onHamburgerClicked = () => ({
	type: Types.FRAME_HAMBURGER_CLICKED,
});
