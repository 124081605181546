import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	Modal,
	Card,
	CardContent,
	CardActions,
	Typography,
} from "@material-ui/core";

import { FormSubmitButton } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	body: {
		padding: theme.spacing(2, 0),
	},
	cancel: {
		[theme.breakpoints.down("xs")]: {
			minWidth: "100%",
		},
	},
}));

const DialogMessage = ({
	message,
	className,
	open,
	title,
	onClose,
	closeLabel,
	...rest
}) => {
	const classes = useStyles();

	return (
		<Modal open={open} onClose={onClose}>
			<Card {...rest} className={clsx(classes.root, className)}>
				<CardContent>
					<Typography variant="h2">{title}</Typography>
					<Typography className={classes.body} variant="body1">
						{message}
					</Typography>
				</CardContent>
				<CardActions>
					<FormSubmitButton
						data-cy={`close-btn`}
						className={classes.cancel}
						variant="contained"
						onClick={onClose}
					>
						{closeLabel}
					</FormSubmitButton>
				</CardActions>
			</Card>
		</Modal>
	);
};

DialogMessage.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	closeLabel: PropTypes.string.isRequired,
	className: PropTypes.string,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default DialogMessage;
