import { connect } from "react-redux";
import { passwordSave } from "../actions";
import Password from "./password";

const mapStateToProps = ({ password }) => ({
	save: password.save,
	responseMessage: password.responseMessage,
});

const mapDispatchToProps = {
	onSave: passwordSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
