import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Divider, Link, Typography } from "@material-ui/core";
import { FormSubmitButton } from "frame/components";
import * as fromAuth from "features/authorisation";
import PasswordIndicator from "./passwordIndicator";

const useStyles = makeStyles((theme) => ({
	divider: {
		margin: theme.spacing(2, 0),
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	error: {
		color: theme.palette.error.main,
	},
	success: {
		fontSize: "16px",
		lineHeight: "24px",
		margin: "24px 0"
	},
}));

const SetPasswordForm = ({
	save,
	isValid,
	passwordMatch,
	meetsPasswordLength,
	token,
	password,
	confirmPassword,
	onChangePassword,
	onChangeConfirmPassword,
	onSetPassword,
	responseMessage,
}) => {
	const classes = useStyles();
	const { saving } = save;

	const onSubmit = (event) => {
		event.preventDefault();
		onSetPassword(token);
	};

	const getValue = (cb) => (event) => cb(event.target.value);

	if (token === "") {
		return (
			<Typography variant="body1">
				Invalid set password link, please try again or login{" "}
				<Link
					color="secondary"
					component={RouterLink}
					to={fromAuth.LOGIN_PATH}
					underline="always"
				>
					here
				</Link>
				.
			</Typography>
		);
	}

	return (
		<div>
			{responseMessage && responseMessage.includes("success") ? (
				<>
					<p
						className={classes.success}
					>
						{responseMessage}
					</p>
					<Divider className={classes.divider} />
					<Link
						align="center"
						color="secondary"
						component={RouterLink}
						to={fromAuth.LOGIN_PATH}
						underline="always"
						variant="subtitle2"
					>
						Take me to login
					</Link>
				</>
			) : (
				<ValidatorForm onSubmit={onSubmit}>
					<div className={classes.fields}>
						<TextValidator
							data-cy={`sw-password`}
							fullWidth
							disabled={saving}
							label="Password *"
							name="password"
							onChange={getValue(onChangePassword)}
							value={password}
							type="password"
							variant="outlined"
							validators={["required", "minStringLength:9"]}
							errorMessages={[
								"This field is required",
								"Must be at least 9 characters",
							]}
						/>
					</div>
					<div className={classes.fields}>
						<TextValidator
							data-cy={`sw-confirm-password`}
							fullWidth
							disabled={saving}
							label="Confirm Password *"
							name="confirmPassword"
							onChange={getValue(onChangeConfirmPassword)}
							value={confirmPassword}
							type="password"
							variant="outlined"
							validators={["required", "minStringLength:9"]}
							errorMessages={[
								"This field is required",
								"Must be at least 9 characters",
							]}
						/>
					</div>
					{responseMessage && (
						<p
							className={classes.error}
						>
							{responseMessage}
						</p>
					)}
					<FormSubmitButton
						data-cy={`set-password`}
						className={classes.submitButton}
						disabled={!isValid}
						loading={saving}
						fullWidth
					>
					Set Password
					</FormSubmitButton>
					<PasswordIndicator
						passwordMatch={passwordMatch}
						meetsPasswordLength={meetsPasswordLength}
					/>
				</ValidatorForm>
			)}
		</div>
	);
};

SetPasswordForm.propTypes = {
	password: PropTypes.string,
	confirmPassword: PropTypes.string,
	isValid: PropTypes.bool,
	passwordMatch: PropTypes.bool,
	meetsPasswordLength: PropTypes.bool,
	onChangePassword: PropTypes.func.isRequired,
	onChangeConfirmPassword: PropTypes.func.isRequired,
	onSetPassword: PropTypes.func.isRequired,
	token: PropTypes.string.isRequired,
	save: PropTypes.exact({
		done: PropTypes.bool,
		error: PropTypes.string,
		saving: PropTypes.bool,
		success: PropTypes.bool,
	}),
	responseMessage: PropTypes.string,
};

export default SetPasswordForm;
