import { combineEpics } from "redux-observable";
import addHazardEffect from "./add-hazard-effect";
import addHazardousSubstanceEffect from "./add-hazardous-substance-effect";
import addAccidentEffect from "./add-accident-effect";
import addJobSafetyAnalysisEffect from "./add-job-safety-analysis-effect";
import addInductionMinutesEffect from "./add-induction-minutes-effect";
import addSafetyMinutesEffect from "./add-safety-minutes-effect";
import addSafetyPlanChecklistEffect from "./add-safety-plan-checklist-effect";
import addStaffTrainingEffect from "./add-staff-training-effect";
import addStaffTrainingTopicEffect from "./add-staff-training-topic-effect";

const healthSafetyEffects = combineEpics(
	addHazardEffect,
	addHazardousSubstanceEffect,
	addAccidentEffect,
	addJobSafetyAnalysisEffect,
	addInductionMinutesEffect,
	addSafetyMinutesEffect,
	addSafetyPlanChecklistEffect,
	addStaffTrainingEffect,
	addStaffTrainingTopicEffect,
);

export default healthSafetyEffects;
