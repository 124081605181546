import { mergeMap, catchError, filter } from "rxjs/operators";
import { ofType } from "redux-observable";
import { EMPTY /*, interval*/ } from "rxjs";
import { ajax } from "rxjs/ajax";
import { authHeaders, tokenExists, getOrganisationBaseUrl } from "frame";
import Types from "../actions/types";

// const CLEANUP_INTERVAL_TIME =  60 * 1000 // 1 minute

export const organisationCleanupEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.ORGANISATION_CLEANUP),
		filter(() => tokenExists()),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(() =>
			ajax
				.get(
					`${getOrganisationBaseUrl(
						state$.value
					)}/organisation/cleanup`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return EMPTY;
					}),
					catchError((error) => {
						console.log(
							"organisationCleanupEffect error",
							error.message
						);
						return EMPTY;
					})
				)
		)
	);

// export const organisationCleanupEffect = (action$, state$) => {
//   return interval(CLEANUP_INTERVAL_TIME).pipe(
//     filter(() => tokenExists()),
//     filter(() => state$.value.queue.hasNetwork),
//     mergeMap(
//       () => ajax.get(
//         `${getOrganisationBaseUrl(state$.value)}/organisation/cleanup`,
//         authHeaders()
//       ).pipe(
//         mergeMap(ajaxResponse => {
//             return EMPTY
//         }),
//         catchError(error => {
//             console.log('working error', error.message)
//             return EMPTY
//         })
//       )
//     )
//   )
// }

export default organisationCleanupEffect;
