export const SITES_ROUTE = `/sites`;
export const SITE_TAB_ROUTE = `${SITES_ROUTE}/:siteId/:siteTab?`;

export const SITES_PATH = `/sites`;

// Tab paths
export const SITE_RECORDS_PATH = `records`;
export const SITE_NOTES_PATH = `notes`;
export const SITE_STAFF_PATH = `staff`;
export const SITE_SUMMARY_PATH = `summary`;
export const SITE_TIMESHEETS_PATH = `timesheets`;

// Tab specifics
export const SITE_NOTES_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_NOTES_PATH}`;
export const SITE_STAFF_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_STAFF_PATH}`;
export const SITE_SUMMARY_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_SUMMARY_PATH}`;
export const SITE_TIMESHEETS_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_TIMESHEETS_PATH}`;

// Sub tab paths
export const SITE_INCIDENTS_PATH = `${SITE_RECORDS_PATH}/accidents`;
export const SITE_HAZARDS_PATH = `${SITE_RECORDS_PATH}/hazards`;
export const SITE_HAZARDOUS_SUBSTANCES_PATH = `${SITE_RECORDS_PATH}/hazardous-substances`;
export const SITE_INDUCTION_MINUTES_PATH = `${SITE_RECORDS_PATH}/induction-minutes`;
export const SITE_SAFETY_MEETING_MINUTES_PATH = `${SITE_RECORDS_PATH}/safety-meeting-minutes`;
export const SITE_SAFETY_PLAN_CHECKLIST_PATH = `${SITE_RECORDS_PATH}/safety-plan-checklists`;
export const SITE_JOB_SAFETY_ANALYSIS_PATH = `${SITE_RECORDS_PATH}/job-safety-analysis`;

// Sub tab specifics
export const SITE_INCIDENTS_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_INCIDENTS_PATH}`;
export const SITE_HAZARDS_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_HAZARDS_PATH}`;
export const SITE_HAZARDOUS_SUBSTANCES_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_HAZARDOUS_SUBSTANCES_PATH}`;
export const SITE_INDUCTION_MINUTES_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_INDUCTION_MINUTES_PATH}`;
export const SITE_SAFETY_MEETING_MINUTES_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_SAFETY_MEETING_MINUTES_PATH}`;
export const SITE_SAFETY_PLAN_CHECKLIST_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_SAFETY_PLAN_CHECKLIST_PATH}`;
export const SITE_JOB_SAFETY_ANALYSIS_ROUTE = `${SITES_ROUTE}/:siteId/${SITE_JOB_SAFETY_ANALYSIS_PATH}`;

// Actions
export const SITES_LOOKUP = "SITES_LOOKUP";
export const SITES_LOOKUP_FAILED = "SITES_LOOKUP_FAILED";
export const SITES_LOOKUP_SUCCESS = "SITES_LOOKUP_SUCCESS";

export const SITES_SEARCH_CHANGED = "SITES_SEARCH_CHANGED";
export const SITES_SEARCH_CLEAR = "SITES_SEARCH_CLEAR";

export const SITES_LIST_REQUESTED = "SITES_LIST_REQUESTED";
export const SITES_PAGED_LIST_REQUESTED = "SITES_PAGED_LIST_REQUESTED";
export const SITES_LIST_RECEIVED = "SITES_LIST_RECEIVED";
export const SITES_LIST_FAILED = "SITES_LIST_FAILED";

export const SITES_EDIT_REQUESTED = "SITES_EDIT_REQUESTED";

export const SITES_SITE_NAME_CHANGED = "SITES_SITE_NAME_CHANGED";
export const SITES_CUSTOMER_NAME_CHANGED = "SITES_CUSTOMER_NAME_CHANGED";
export const SITES_ADDRESS_CHANGED = "SITES_ADDRESS_CHANGED";
export const SITES_SUBURB_CHANGED = "SITES_SUBURB_CHANGED";
export const SITES_TOWN_CITY_CHANGED = "SITES_TOWN_CITY_CHANGED";
export const SITES_POST_CODE_CHANGED = "SITES_POST_CODE_CHANGED";
export const SITES_SITE_CONTACT_PERSON_CHANGED =
	"SITES_SITE_CONTACT_PERSON_CHANGED";
export const SITES_SITE_CONTACT_PHONE_CHANGED =
	"SITES_SITE_CONTACT_PHONE_CHANGED";
export const SITES_SITE_CONTACT_EMAIL_CHANGED =
	"SITES_SITE_CONTACT_EMAIL_CHANGED";
export const SITES_STATUS_CHANGED = "SITES_STATUS_CHANGED";

export const SITES_ADD_CLICKED = "SITES_ADD_CLICKED";
export const SITES_CLOSE_CLICKED = "SITES_CLOSE_CLICKED";
export const SITES_SAVE_CLICKED = "SITES_SAVE_CLICKED";
export const SITES_EDIT_CLICKED = "SITES_EDIT_CLICKED";

export const SITES_REMOVE_CLICKED = "SITES_REMOVE_CLICKED";
export const SITES_REMOVE_SUCCEEDED = "SITES_REMOVE_SUCCEEDED";
export const SITES_REMOVE_FAILED = "SITES_REMOVE_FAILED";

export const SITES_ADD_SUCCEEDED = "SITES_ADD_SUCCEEDED";
export const SITES_ADD_FAILED = "SITES_ADD_FAILED";

export const SITES_DETAILS = "SITE_DETAILS";

export const SITES_HAZARDS_VALUE_CHANGE = "SITES_HAZARDS_VALUE_CHANGE";
export const SITES_HAZARDOUS_SUBSTANCE_VALUE_CHANGE =
	"SITES_HAZARDOUS_SUBSTANCE_VALUE_CHANGE";
export const SITES_HAZARD_REMOVE = "SITES_HAZARD_REMOVE";
export const SITES_HAZARDOUS_SUBSTANCE_REMOVE =
	"SITES_HAZARDOUS_SUBSTANCE_REMOVE";

// Add Hazard to Site
export const ADD_HAZARD_TO_SITE_CLICKED = "ADD_HAZARD_TO_SITE_CLICKED";
export const ADD_HAZARD_TO_SITE_CLOSE = "ADD_HAZARD_TO_SITE_CLOSE";
export const ADD_HAZARD_TO_SITE_FORM_VALUE_CHANGE = `ADD_HAZARD_TO_SITE_FORM_VALUE_CHANGE`;

export const ADD_HAZARD_TO_SITE_FORM_SUBMIT = `ADD_HAZARD_TO_SITE_FORM_SUBMIT`;
export const ADD_HAZARD_TO_SITE_FORM_SUBMIT_FAILED = `ADD_HAZARD_TO_SITE_FORM_SUBMIT_FAILED`;
export const ADD_HAZARD_TO_SITE_FORM_SUBMIT_SUCCESS = `ADD_HAZARD_TO_SITE_FORM_SUBMIT_SUCCESS`;

export const REMOVE_HAZARD_FROM_SITE_REQUESTED =
	"REMOVE_HAZARD_FROM_SITE_REQUESTED";
export const REMOVE_HAZARD_FROM_SITE_SUCCEEDED =
	"REMOVE_HAZARD_FROM_SITE_SUCCEEDED";
export const REMOVE_HAZARD_FROM_SITE_FAILED = "REMOVE_HAZARD_FROM_SITE_FAILED";

export const EDIT_HAZARD_TO_SITE_CLICKED = "EDIT_HAZARD_TO_SITE_CLICKED";
export const EDIT_HAZARD_TO_SITE_CLOSE = "EDIT_HAZARD_TO_SITE_CLOSE";

// Add Hazardous Substance to Site
export const ADD_HAZARDOUS_SUBSTANCE_TO_SITE_CLICKED =
	"ADD_HAZARDOUS_SUBSTANCE_TO_SITE_CLICKED";
export const ADD_HAZARDOUS_SUBSTANCE_TO_SITE_CLOSE =
	"ADD_HAZARDOUS_SUBSTANCE_TO_SITE_CLOSE";
export const ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_VALUE_CHANGE = `ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_VALUE_CHANGE`;

export const ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT = `ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT`;
export const ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT_FAILED = `ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT_FAILED`;
export const ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT_SUCCESS = `ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT_SUCCESS`;

export const REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_REQUESTED =
	"REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_REQUESTED";
export const REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_SUCCEEDED =
	"REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_SUCCEEDED";
export const REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_FAILED =
	"REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_FAILED";

export const EDIT_HAZARDOUS_SUBSTANCE_TO_SITE_CLICKED =
	"EDIT_HAZARDOUS_SUBSTANCE_TO_SITE_CLICKED";
export const EDIT_HAZARDOUS_SUBSTANCE_TO_SITE_CLOSE =
	"EDIT_HAZARDOUS_SUBSTANCE_TO_SITE_CLOSE";
export const HYDRATE_SITE = `HYDRATE_SITE`;

export default {
	SITES_ROUTE,
	// tab routes
	SITE_TAB_ROUTE,
	SITE_NOTES_ROUTE,
	SITE_STAFF_ROUTE,
	SITE_SUMMARY_ROUTE,
	SITE_TIMESHEETS_ROUTE,

	// tab paths
	SITE_RECORDS_PATH,
	SITE_NOTES_PATH,
	SITE_STAFF_PATH,
	SITE_SUMMARY_PATH,
	SITE_TIMESHEETS_PATH,

	// sub tab routes
	SITE_INCIDENTS_ROUTE,
	SITE_HAZARDS_ROUTE,
	SITE_HAZARDOUS_SUBSTANCES_ROUTE,
	SITE_INDUCTION_MINUTES_ROUTE,
	SITE_SAFETY_MEETING_MINUTES_ROUTE,
	SITE_SAFETY_PLAN_CHECKLIST_ROUTE,
	SITE_JOB_SAFETY_ANALYSIS_ROUTE,

	// sub tab paths
	SITE_INCIDENTS_PATH,
	SITE_HAZARDS_PATH,
	SITE_HAZARDOUS_SUBSTANCES_PATH,
	SITE_INDUCTION_MINUTES_PATH,
	SITE_SAFETY_MEETING_MINUTES_PATH,
	SITE_SAFETY_PLAN_CHECKLIST_PATH,
	SITE_JOB_SAFETY_ANALYSIS_PATH,

	// Actions
	SITES_LOOKUP,
	SITES_LOOKUP_SUCCESS,
	SITES_LOOKUP_FAILED,
	SITES_SEARCH_CHANGED,
	SITES_SEARCH_CLEAR,

	SITES_PAGED_LIST_REQUESTED,
	SITES_LIST_REQUESTED,
	SITES_LIST_RECEIVED,
	SITES_EDIT_REQUESTED,
	SITES_SITE_NAME_CHANGED,
	SITES_CUSTOMER_NAME_CHANGED,
	SITES_ADDRESS_CHANGED,
	SITES_SUBURB_CHANGED,
	SITES_TOWN_CITY_CHANGED,
	SITES_POST_CODE_CHANGED,
	SITES_SITE_CONTACT_PERSON_CHANGED,
	SITES_SITE_CONTACT_PHONE_CHANGED,
	SITES_SITE_CONTACT_EMAIL_CHANGED,
	SITES_STATUS_CHANGED,
	SITES_ADD_CLICKED,
	SITES_ADD_SUCCEEDED,
	SITES_CLOSE_CLICKED,
	SITES_SAVE_CLICKED,
	SITES_EDIT_CLICKED,
	SITES_LIST_FAILED,
	SITES_REMOVE_CLICKED,
	SITES_REMOVE_SUCCEEDED,
	SITES_REMOVE_FAILED,
	SITES_ADD_FAILED,
	SITES_DETAILS,
	SITES_HAZARDS_VALUE_CHANGE,
	SITES_HAZARDOUS_SUBSTANCE_VALUE_CHANGE,
	SITES_HAZARD_REMOVE,
	SITES_HAZARDOUS_SUBSTANCE_REMOVE,

	ADD_HAZARD_TO_SITE_CLICKED,
	ADD_HAZARD_TO_SITE_CLOSE,
	ADD_HAZARD_TO_SITE_FORM_VALUE_CHANGE,
	ADD_HAZARD_TO_SITE_FORM_SUBMIT,
	ADD_HAZARD_TO_SITE_FORM_SUBMIT_FAILED,
	ADD_HAZARD_TO_SITE_FORM_SUBMIT_SUCCESS,
	REMOVE_HAZARD_FROM_SITE_REQUESTED,
	REMOVE_HAZARD_FROM_SITE_SUCCEEDED,
	REMOVE_HAZARD_FROM_SITE_FAILED,

	EDIT_HAZARD_TO_SITE_CLICKED,
	EDIT_HAZARD_TO_SITE_CLOSE,

	ADD_HAZARDOUS_SUBSTANCE_TO_SITE_CLICKED,
	ADD_HAZARDOUS_SUBSTANCE_TO_SITE_CLOSE,
	ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_VALUE_CHANGE,
	ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT,
	ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT_FAILED,
	ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT_SUCCESS,
	REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_REQUESTED,
	REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_SUCCEEDED,
	REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_FAILED,

	EDIT_HAZARDOUS_SUBSTANCE_TO_SITE_CLICKED,
	EDIT_HAZARDOUS_SUBSTANCE_TO_SITE_CLOSE,

	HYDRATE_SITE,
};
