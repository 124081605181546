import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid } from "@material-ui/core";
import { useRouter, isStaffUser } from "utils";
import AddIcon from "@material-ui/icons/Add";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { PageHeader } from "frame/components";

const useStyles = makeStyles((theme) => ({
	menu: {
		marginBottom: theme.spacing(-3)
	},
	addIcon: {
		marginRight: theme.spacing(1)
	},
	selectedButton: {
		"padding": theme.spacing(1, 4),
		"color": theme.palette.brand,
		"background": theme.palette.highlight,
		"borderColor": theme.palette.highlight,
		"fontWeight": 600,
		"&:hover": {
			background: "#c1c72c",
			borderColor: "#c1c72c"
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%"
		}
	},
	button: {
		"padding": theme.spacing(1, 4),
		"color": theme.palette.text.secondary,
		"fontWeight": 600,
		"&:hover": {
			color: theme.palette.brand
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%"
		}
	},
	noFormatListTitle: {
		textTransform: "none"
	},
	buttonGrid: {
		[theme.breakpoints.down("xs")]: {
			"flex-grow": 1,
		},
	},
}));

const PageHeaderTabs = (props) => {
	const classes = useStyles();
	const { location } = useRouter();
	const {
		role,
		title,
		breadcrumbs,
		addTabTitle,
		hideAddTabIcon,
		listTabTitle,
		addTabPath = location.pathname,
		listTabPath = location.pathname,
		addAdminPath = location.pathname,
		staffUserHide,
		noFormatListTitle, // used to disable text formating for MUI button (text is always uppercase by default)
		backActionPath, // for specifying back button path - default uses router history.back
		showAddAdminButton,
		showLeaveButton,
		newLeavePath = location.pathname
	} = props;
	const addTabSelected = addTabPath === location.pathname;
	const listTabSelected = listTabPath === location.pathname;
	const adminTabSelected = addAdminPath === location.pathname;
	const leaveTabSelected = newLeavePath === location.pathname;
	const showTabs = staffUserHide ? !isStaffUser(role) : true;
	const tabStyle = (selected) => (selected ? classes.selectedButton : classes.button);
	const listTitleStyle = noFormatListTitle ? classes.noFormatListTitle : "";

	return (
		<PageHeader canGoBack title={title} breadcrumbs={breadcrumbs} backActionPath={backActionPath}>
			{showTabs && (
				<Grid container className={classes.menu} spacing={3} alignItems="center">
					<Grid item className={classes.buttonGrid}
					 >
						<Button
							className={tabStyle(addTabSelected)}
							variant="contained"
							component={RouterLink}
							to={addTabPath}
						>
							{!hideAddTabIcon && <AddIcon className={classes.addIcon} />}
							{addTabTitle ? addTabTitle : "New"}
						</Button>
					</Grid>
					{showAddAdminButton && (
						<Grid item className={classes.buttonGrid}
						 >
							<Button
								className={tabStyle(adminTabSelected)}
								variant="contained"
								component={RouterLink}
								to={addAdminPath}
							>
								ADMIN
							</Button>
						</Grid>
					)}
					{showLeaveButton && (
						<Grid
							item
							className={classes.buttonGrid}
						>
							<Button
								className={tabStyle(leaveTabSelected)}
								variant="contained"
								component={RouterLink}
								to={newLeavePath}
								fontSize={"8px"}
							>
								{!hideAddTabIcon && <AddIcon className={classes.addIcon} />}
								Leave
							</Button>
						</Grid>
					)}
					<Grid item className={classes.buttonGrid}
					xs
					 >
						<Button
							className={clsx(tabStyle(listTabSelected), listTitleStyle)}
							variant="contained"
							component={RouterLink}
							to={listTabPath}
							style={{
								minWidth: "120px"
							}}
						>
							{listTabTitle ? listTabTitle : "See All"}
						</Button>
					</Grid>
					
				</Grid>
			)}
		</PageHeader>
	);
};

PageHeaderTabs.propTypes = {
	role: PropTypes.string,
	title: PropTypes.string.isRequired,
	breadcrumbs: PropTypes.string,
	addTabTitle: PropTypes.string,
	hideAddTabIcon: PropTypes.bool,
	addTabPath: PropTypes.string,
	listTabTitle: PropTypes.string,
	listTabPath: PropTypes.string,
	staffUserHide: PropTypes.bool,
	noFormatListTitle: PropTypes.bool,
	backActionPath: PropTypes.string,
	showLeaveButton: PropTypes.bool,
	newLeavePath: PropTypes.string
};

const mapStateToProps = ({ profile }) => ({
	role: profile.role
});

export default connect(mapStateToProps)(PageHeaderTabs);
