import { connect } from "react-redux";
import StaffTrainingTopicList from "./staff-training-topic-list";
import {
	onRemove,
	onStaffTrainingTopicSearchChanged,
	onStaffTrainingTopicPagedListRequest,
} from "../../actions";

const mapStateToProps = ({ healthSafety, profile }) => ({
	staffTrainingTopics: healthSafety.staffTrainingTopics,
	searchFilter: healthSafety.searchFilter,
	role: profile.role,
});

const mapDispatchToProps = {
	onDelete: onRemove,
	onSearchChanged: onStaffTrainingTopicSearchChanged,
	onGetStaffTrainingTopicList: onStaffTrainingTopicPagedListRequest,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StaffTrainingTopicList);
