import * as fromTypes from "../actions/types";
import * as R from "ramda";
import { defaultFetchState } from "utils";

export const defaultState = {
	...defaultFetchState,
	latitude: null,
	longitude: null,
	timestamp: null,
	error: "",
};

const SetGeolocation = (state, action) => {
	state = action.payload;
	return state;
};

const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case fromTypes.SET_GEOLOCATION:
			return SetGeolocation(R.clone(state), action);
		default:
			return state;
	}
};

export default reducer;
