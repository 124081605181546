import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { PageContent, PageHeader, ScrollToTopOnMount } from "frame/components";
import UncomfirmedAttendanceListing from "./uncomfirmed-attendance-listing";
import { EditInductionMinutesForm } from "features/health-safety-forms";
import { useRouter } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {},
	message: {
		margin: theme.spacing(0, 1),
	},
	link: {
		color: theme.palette.text.link,
		cursor: "pointer",
	},
}));

const UncomfirmedAttendance = ({ unconfirmedInductions }) => {
	const classes = useStyles();
	const { history } = useRouter();
	const [viewId, setViewId] = useState(null);

	const onView = (id) => () => {
		setViewId(id);
	};
	const onCancel = () => setViewId(null);
	const onReturnHome = () => history.push("/");

	return (
		<div>
			<ScrollToTopOnMount />
			<PageHeader
				// breadcrumbs={'Welcome'} //breadcrumbs}
				title={`Uncomfirmed Attendance`}
				// subtitle={profile.organisation}
				// message="Please make a selection to get started"
			/>
			<PageContent pad>
				<Grid spacing={2} container>
					<Grid xs={12}>
						{unconfirmedInductions &&
						unconfirmedInductions.length > 0 ? (
							unconfirmedInductions.map((record, index) => (
								<UncomfirmedAttendanceListing
									key={index}
									record={record}
									onView={onView}
								/>
							))
						) : (
							<div className={classes.message}>
								<Typography component="h1" variant="h5">
									There is currently no uncomfirmed
									inductions.
								</Typography>
								<Typography
									component="h1"
									variant="h5"
									className={classes.link}
									onClick={onReturnHome}
								>
									Click here to return home.
								</Typography>
							</div>
						)}
					</Grid>
				</Grid>
			</PageContent>

			{viewId && (
				<EditInductionMinutesForm
					readOnly
					id={viewId}
					onClose={onCancel}
				/>
			)}
		</div>
	);
};

UncomfirmedAttendance.propTypes = {
	unconfirmedInductions: PropTypes.array,
};

const mapStateToProps = ({ home }) => ({
	unconfirmedInductions: home.unconfirmedInductions,
});

const mapDispatchToProps = {
	// onOrganisationCleanup
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UncomfirmedAttendance);
