import React, { useState, createRef } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, TextField, Divider, Link } from "@material-ui/core";
import { FormSubmitButton } from "frame/components";
import * as fromAuth from "features/authorisation";
import { getWebsiteBaseUrl, getReCaptchaSiteKey } from "frame";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
	divider: {
		margin: theme.spacing(2, 0),
	},
	rememberMe: {
		justifyContent: "flex-start",
		alignItems: "center",
		...theme.typography.body1,
		lineHeight: "14px",
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	error: {
		color: theme.palette.error.main,
	},
	registerLink: {
		marginLeft: "5px",
	},
	termsLink: {
		marginLeft: "5px",
		textDecoration: "underline",
		color: "#2979ff",
		fontSize: "14px",
		align: "center",
	},
	forgotPassword: {
		marginTop: theme.spacing(1),
	},
}));

const LoginForm = ({
	emailAddress,
	password,
	// rememberMe,
	isValid,
	loginAttemptError,
	load,
	onLogin,
	onChangeEmail,
	onChangePassword,
	// onChangeRememberMe
}) => {
	const recaptchaRef = createRef();
	const classes = useStyles();
	const { loading } = load;
	const [loginAttempt, setLoginAttempt] = useState(0);
	const [recaptchaValue, setRecaptchaValue] = useState();

	const onSubmit = (event) => {
		event.preventDefault();
		const updated = loginAttempt + 1;
		setLoginAttempt(updated);
		onLogin();
		if (exceededLoginAttempts) {
			recaptchaRef.current.reset(); // clear out
			setRecaptchaValue(null);
		}
	};

	const getValue = (cb) => (event) => cb(event.target.value);

	// const onToggleRememberMe = (event) =>
	//   onChangeRememberMe(event.target.checked)

	const exceededLoginAttempts = loginAttempt > 5;

	return (
		<div>
			<form onSubmit={onSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							disabled={loading}
							fullWidth
							label="Username or Email"
							name="email"
							onChange={getValue(onChangeEmail)}
							value={emailAddress}
							variant="outlined"
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							disabled={loading}
							fullWidth
							label="Password"
							name="password"
							onChange={getValue(onChangePassword)}
							type="password"
							value={password}
							variant="outlined"
						/>
					</Grid>

					{/* <Grid
            className={classes.rememberMe}
            item
            xs={6}>
            <Checkbox
              checked={rememberMe}
              disabled={loading}
              value="rememberMe"
              inputProps={{
                'aria-label': 'primary checkbox',
              }}
              onChange={onToggleRememberMe} />
            <span>Remember Me</span>
          </Grid> */}

					<Grid className={classes.forgotPassword} item xs={12}>
						<Link
							className={classes.registerLink}
							align="center"
							color="secondary"
							component={RouterLink}
							to={fromAuth.FORGOT_PASSWORD_PATH}
							underline="always"
							variant="subtitle2"
							title="Forgot your password?"
						>
							Forgot your password?
						</Link>
					</Grid>

					<Grid className={classes.forgotPassword} item xs={12}>
						<a
							href={`${getWebsiteBaseUrl()}/terms-of-use/`}
							title="Terms of Use"
							target="_blank"
							rel="noopener noreferrer"
							className={classes.termsLink}
						>
							Terms of Use
						</a>
					</Grid>
				</Grid>

				<FormSubmitButton
					fullWidth
					loading={loading}
					className={classes.submitButton}
					disabled={
						!isValid || (exceededLoginAttempts && !recaptchaValue)
					}
				>
					Sign In
				</FormSubmitButton>
			</form>
			<br />
			{exceededLoginAttempts && (
				<ReCAPTCHA
					ref={recaptchaRef}
					render="explicit"
					sitekey={getReCaptchaSiteKey()}
					onChange={(value) => setRecaptchaValue(value)}
				/>
			)}
			{loginAttemptError && (
				<>
					<Divider className={classes.divider} />
					<span className={classes.error}>{loginAttemptError}</span>
				</>
			)}
			<Divider className={classes.divider} />
			Don&#39;t have an account yet?
			<Link
				className={classes.registerLink}
				align="center"
				color="secondary"
				component={RouterLink}
				to={fromAuth.REGISTER_PATH}
				underline="always"
				variant="subtitle2"
				title="Register here"
			>
				Register here
			</Link>
		</div>
	);
};

LoginForm.propTypes = {
	emailAddress: PropTypes.string,
	password: PropTypes.string,
	// rememberMe: PropTypes.bool,
	isValid: PropTypes.bool,
	loginAttemptError: PropTypes.string,
	load: PropTypes.exact({
		done: PropTypes.bool,
		error: PropTypes.string,
		loading: PropTypes.bool,
		success: PropTypes.bool,
	}),
	onLogin: PropTypes.func.isRequired,
	onChangeEmail: PropTypes.func.isRequired,
	onChangePassword: PropTypes.func.isRequired,
	// onChangeRememberMe: PropTypes.func.isRequired,
};

export default LoginForm;
