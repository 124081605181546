import {
	defaultSiteFormState,
	defaultHazardFormState,
	isLocked,
} from "features/forms";

import {
	defaultSaveState,
	saveRequested,
	saveFailed,
	saveSuccess,
} from "utils";

import * as fromTypes from "../actions/types";
import * as R from "ramda";

export const defaultState = {
	...defaultSaveState,
	...defaultSiteFormState,
	...defaultHazardFormState,
	siteId: "NO-ID",
	locked: false,
};

const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case fromTypes.ADD_HAZARD_FORM_RESET:
			return defaultState;
		case fromTypes.ADD_HAZARD_FORM_VALUE_CHANGE:
			return isLocked({
				...state,
				...action.payload,
			});
		case fromTypes.ADD_HAZARD_FORM_SUBMIT:
			return isLocked(saveRequested(state));
		case fromTypes.ADD_HAZARD_FORM_SUBMIT_FAILED:
			return isLocked(saveFailed(state, action.payload));
		case fromTypes.ADD_HAZARD_FORM_SUBMIT_SUCCESS:
			return isLocked(saveSuccess(state));
		case fromTypes.ARCHIVED_HAZARD:
			return archiveHazard(R.clone(state), action);
		default:
			return isLocked(state);
	}
};

const archiveHazard = (state, action) => {
	state.hazard = action.payload;
	state.attachments = R.clone(action.payload.attachments);
	return state;
};

export default reducer;
