import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";

const mapStateToProps = ({ organisations, profile, subscription }) => ({
	currentOrganisation: organisations.currentOrganisation,
	organisations: organisations.data,
	profileFetchFlag: profile.fetch,
	subscription,
});

const mapDispatchToProps = {
	onChange: fromActions.onCurrentOrganisationChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
