import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { FeatureHeader, ScrollToTopOnMount } from "frame/components";
import { isStaffUser, isSuperAdminUser, isConsultantUser } from "utils";

import PaymentIcon from "@material-ui/icons/Payment";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import DraftsIcon from "@material-ui/icons/Drafts";
import DescriptionIcon from "@material-ui/icons/Description";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import AccountIcon from "@material-ui/icons/AccountCircle";
import People from "@material-ui/icons/People";
import LockIcon from "@material-ui/icons/Lock";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { connect } from "react-redux";
import * as fromAccount from "features/account";
import HomeCard from "./home-card";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
	},
	header: {
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(-3),
			marginBottom: theme.spacing(-3),
		},
	},
}));

const mapStateToProps = ({ profile }) => ({
	role: profile.role,
});

const mapDispatchToProps = {};

export const AdminHome = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ role }) => {
	const classes = useStyles();

	const links = [
		{
			title: "Profile",
			href: fromAccount.ACCOUNT_PROFILE_ROUTE,
			icon: AccountIcon,
		},
		{
			title: "Organisations",
			href: fromAccount.ACCOUNT_ORGANISATIONS_ROUTE,
			icon: PeopleOutlineIcon,
		},
		{
			title: "Subscription",
			href: fromAccount.ACCOUNT_SUBSCRIPTION_ROUTE,
			icon: PaymentIcon,
		},
		{
			title: isSuperAdminUser(role)
				? "Invite Consultant"
				: "Invite Organisation",
			href:
				isConsultantUser(role) || isSuperAdminUser(role)
					? fromAccount.ACCOUNT_INVITE_ROUTE
					: null,
			icon: DraftsIcon,
		},
		{
			title: "Reports",
			href: fromAccount.ACCOUNT_REPORTS_ROUTE,
			icon: DescriptionIcon,
		},
		{
			title: "Notifications",
			href: fromAccount.ACCOUNT_NOTIFICATIONS_ROUTE,
			icon: AnnouncementIcon,
		},
		{
			title: "Change Password",
			href: fromAccount.ACCOUNT_PASSWORD_ROUTE,
			icon: LockIcon,
		},
		{
			title: "Manage Staff Types",
			href: fromAccount.ACCOUNT_MANGE_STAFF_TYPE,
			icon: People,
		},
		{
			title: "Manage Task Types",
			href: fromAccount.ACCOUNT_MANAGE_TASK_TYPE,
			icon: AssignmentIcon,
		},
	];

	const staffLinks = [
		{
			title: "Profile",
			href: fromAccount.ACCOUNT_PROFILE_ROUTE,
			icon: AccountIcon,
		},
		{
			title: "Organisations",
			href: fromAccount.ACCOUNT_ORGANISATIONS_ROUTE,
			icon: PeopleOutlineIcon,
		},
		{
			title: "Change Password",
			href: fromAccount.ACCOUNT_PASSWORD_ROUTE,
			icon: LockIcon,
		},
	];

	const menuLinks = isStaffUser(role) ? staffLinks : links;

	return (
		<div className={classes.root}>
			<ScrollToTopOnMount />
			<FeatureHeader title="Admin" className={classes.header} />

			<Grid container>
				<Grid item lg={6} md={12} sm={12} xs={12}>
					{menuLinks.map(
						({ icon, href, title }, index) =>
							href && (
								<HomeCard
									key={`task-home-card-${index}`}
									text={title}
									icon={icon}
									link={href}
								/>
							)
					)}
				</Grid>
			</Grid>
		</div>
	);
});
