import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { validatePeopleWithoutRoles } from "utils";
import { useSelector } from "react-redux";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
} from "frame/components";
import {
	strip,
	IncidentForm,
	SiteForm,
	PeopleForm,
	EmailRecipientsForm,
	UploadFormCard,
} from "features/forms";
import ReadOnlyForm from "./read-only-form";

const useStyles = makeStyles((theme) => ({
	root: {},
	actions: {
		paddingBottom: theme.spacing(8),
	},
}));

const hydrate = ({
	id,
	siteId,
	createdAt,
	people,
	otherPeople,
	attachments,
	recipients,
	recipientsOther,
	organisationId,
	appendNotes,
	...accident
}) => ({
	id,
	siteId,
	createdAt,
	people,
	otherPeople,
	accident,
	attachments,
	recipients,
	recipientsOther,
	currentOrganisation: organisationId,
	appendNotes,
});

/* edit incident is only available to born super user account only */
const Form = ({
	id,
	values,
	onClose,
	onChange,
	onReset,
	onSave,
	allowExternalNotifications,
	incidentList,
	readOnly,
}) => {
	const classes = useStyles();
	const peopleBlockRef = useRef();
	const [invalidPeople, setInvalidPeople] = useState(false);

	const [loaded, setLoaded] = useState(false);
	const open = values && values.id;

	// const incidentList = useSelector(
	// 	({ healthSafety }) => healthSafety.incidents?.results
	// );

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchData() {
			setLoaded(true);
			const accident =
				incidentList && incidentList.filter((i) => i.id === id)[0];

			if (accident) {
				onChange(hydrate(accident));
			}
		}
		if (id) {
			setLoaded(true);
			fetchData();
		} // eslint-disable-next-line
	}, [id, loaded, onChange]);

	useEffect(() => {
		values.save.success && onClose();
	}, [values.save.success, onClose]);

	const onSubmit = (event) => {
		event.preventDefault();
		if (
			validatePeopleWithoutRoles(
				values.people,
				peopleBlockRef,
				setInvalidPeople
			)
		) {
			onSave(strip(values));
		}
	};

	const onCancel = () => {
		onReset();
		onClose();
	};

	if (!open) {
		return null;
	}

	return (
		<Dialog
			open={!!open}
			aria-labelledby="Incidents"
			// breadcrumbs="Incidents"
			title={readOnly ? "View Incident" : "Edit Incident"}
			onClose={onCancel}
		>
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				{readOnly ? (
					<ReadOnlyForm values={values} />
				) : (
					<>
						<SiteForm values={values} onChange={onChange} />
						{values.siteId && (
							<>
								<IncidentForm
									values={values}
									siteId={values.siteId}
									onChange={onChange}
								/>
								<div ref={peopleBlockRef}>
									<PeopleForm
										values={values}
										roleOptions={[
											"Involved",
											"Reported",
											"Witness",
										]}
										onChange={onChange}
										showInvalidPeopleError={invalidPeople}
									/>
								</div>
								<UploadFormCard
									title={"Add Attachments"}
									values={values}
									onChange={onChange}
								/>
								<EmailRecipientsForm
									values={values}
									onChange={onChange}
								/>
							</>
						)}
					</>
				)}
				<FormActions className={classes.actions}>
					{!readOnly && (
						<FormSubmitButton
							disabled={values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
					)}
					<FormButton
						disabled={values.save.saving}
						onClick={onCancel}
					>
						{readOnly ? "Close" : "Cancel"}
					</FormButton>
				</FormActions>
			</ValidatorForm>
		</Dialog>
	);
};

Form.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
	allowExternalNotifications: PropTypes.bool.isRequired,
};

export default Form;
