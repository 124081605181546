import { connect } from "react-redux";
import ResetPassword from "./reset-password";

import {
	onResetPasswordPasswordChange,
	onResetPasswordConfirmPasswordChange,
	onResetPassword,
} from "../actions";

const mapStateToProps = ({ authorisation, router }) => ({
	password: authorisation.resetPassword.password,
	confirmPassword: authorisation.resetPassword.confirmPassword,
	isValid: authorisation.resetPassword.isValid,
	passwordMatch: authorisation.resetPassword.passwordMatch,
	// hasNumber: authorisation.resetPassword.hasNumber,
	meetsPasswordLength: authorisation.resetPassword.meetsPasswordLength,
	token: router.location.search,
	save: authorisation.resetPassword.save,
	responseMessage: authorisation.resetPassword.responseMessage,
});

const mapDispatchToProps = {
	onChangePassword: onResetPasswordPasswordChange,
	onChangeConfirmPassword: onResetPasswordConfirmPasswordChange,
	onResetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
