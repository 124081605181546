import { connect } from "react-redux";
import SafetyMinutesList from "./safety-minutes-list";
import {
	onRemove,
	onSafetyMinutesSearchChanged,
	onSafetyMinutesPagedListRequest,
} from "../../actions";

const mapStateToProps = ({ healthSafety, profile, organisations }) => ({
	safetyMinutes: healthSafety.safetyMinutes,
	role: profile.role,
	searchFilter: healthSafety.searchFilter,
	currentOrganisationGUID: organisations.currentOrganisation,
});

const mapDispatchToProps = {
	onDelete: onRemove,
	onSearchChanged: onSafetyMinutesSearchChanged,
	onGetSafetyMinutesList: onSafetyMinutesPagedListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SafetyMinutesList);
