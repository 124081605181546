import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Chip,
	CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	center: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
	},
	erroredRow: {
		color: theme.palette.error.main,
	},
}));

const DynamicTable = ({ list, flags }) => {
	const classes = useStyles();

	if (!list || (list && list.length === 0)) return null;

	const headers = list && Object.keys(list[0]).map((key) => key);

	const matchFlag = (id, rowData) => {
		let flag = flags && flags.filter((f) => f.id === id)[0];
		return flag
			? flag.status
			: rowData.valid === "No"
			? "invalid"
			: "ready";
	};

	return (
		<TableContainer component={Paper}>
			<Table
				className={classes.table}
				aria-label="simple table"
				size="small"
			>
				<TableHead>
					<TableRow>
						{headers &&
							headers.map(
								(heading, index) =>
									heading !== "id" &&
									heading !== "valid" && (
										<TableCell key={`${heading}-${index}`}>
											<strong>{heading}</strong>
										</TableCell>
									)
							)}

						<TableCell>
							<strong></strong>
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{list &&
						Object.entries(list).map(([key, value], index) => {
							const rowFlag = matchFlag(value["id"], value);
							const errorRowClassName =
								rowFlag === "error" || rowFlag === "invalid"
									? classes.erroredRow
									: "";
							const statusChipClassName =
								rowFlag === "done"
									? classes.success
									: rowFlag === "error" ||
									  rowFlag === "invalid"
									? classes.error
									: "";

							return (
								<TableRow key={`${key}-row-${index}`}>
									{headers &&
										headers.map(
											(heading) =>
												heading !== "id" &&
												heading !== "valid" && (
													<TableCell
														key={`${heading}-${index}`}
														className={
															errorRowClassName
														}
													>
														{value[heading]}
													</TableCell>
												)
										)}

									<TableCell>
										{rowFlag === "processing" ? (
											<div className={classes.center}>
												<CircularProgress size={20} />
											</div>
										) : (
											<Chip
												className={statusChipClassName}
												size="small"
												label={rowFlag}
											/>
										)}
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

DynamicTable.propTypes = {
	list: PropTypes.array.isRequired,
	flags: PropTypes.array.isRequired,
};

export default DynamicTable;
