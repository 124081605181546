import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
// import { SelectInput } from 'frame/components/input'
import { SearchDropdown } from "frame/components";
import { isNotNullOrEmpty } from "utils";
import { useSelector } from "react-redux";

const SelectEmailForm = ({ disabled, values, onChange }) => {
	const staffList = useSelector(({ staff }) => staff.pagination?.results);

	const recipients =
		values.recipients && values.recipients.length
			? [...values.recipients, ""]
			: [""];

	const emailOptions =
		isNotNullOrEmpty(staffList) &&
		staffList
			.filter(({ email }) => email && !recipients.includes(email))
			.map(({ id, email, firstName, lastName }) => {
				let fullName = `${firstName} ${
					lastName ? lastName : ""
				}`.trim();
				return {
					label: `${fullName} - ${email}`,
					value: email,
				};
			})
			.sort((a, b) => (a.label > b.label ? 1 : -1));

	const onSelectOption = useCallback(
		(index) => (value) => {
			const list = [...recipients];
			list[index] = value;

			const next = Array.from(new Set(list)).filter((x) => x);

			onChange({
				...values,
				recipients: next.length ? next : [""],
			});
		},
		[recipients, values, onChange]
	);

	return (
		<Grid container spacing={2}>
			{emailOptions &&
				recipients.map((recipient, index) => {
					if (
						index === recipients.length - 1 &&
						!emailOptions.length
					) {
						return null;
					}

					const matched = staffList.filter(
						({ email }) => email === recipient
					)[0];

					let fullName =
						matched &&
						matched.firstName &&
						`${matched.firstName} ${
							matched.lastName ? matched.lastName : ""
						}`.trim();

					const label =
						matched && matched.firstName
							? `${fullName} - ${matched.email}`
							: recipient;

					const email = {
						label,
						value: recipient,
					};

					const options = email.label
						? [email, ...emailOptions]
						: emailOptions;

					return (
						<Grid key={`recipient_${index}`} item xs={12}>
							<SearchDropdown
								id={`recipient_email_${index}`}
								data-cy="recipient_email"
								disabled={disabled}
								defaultOptions={options}
								label="Select user email"
								value={recipient || ""}
								onChange={onSelectOption(index)}
							/>
							{/* <SelectInput
              id={`recipient_email_${index}`}
              data-cy="recipient_email"
              disabled={disabled}
              label="Select Email"
              options={options}
              value={recipient}
              onChange={onSelectOption(index)} /> */}
						</Grid>
					);
				})}
		</Grid>
	);
};

SelectEmailForm.propTypes = {
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default SelectEmailForm;
