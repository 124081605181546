import { connect } from "react-redux";
import HazardousSubstanceList from "./hazardous-substance-list";
import {
	onRemove,
	onHazardousSubstanceSearchChanged,
	onHazardousSubstancePagedListRequest,
} from "../../actions";

const mapStateToProps = ({ healthSafety, profile }) => ({
	role: profile.role,
	searchFilter: healthSafety.searchFilter,
	hazardousSubstances: healthSafety.hazardousSubstances,
});

const mapDispatchToProps = {
	onDelete: onRemove,
	onSearchChanged: onHazardousSubstanceSearchChanged,
	onGetHazardousSubstanceList: onHazardousSubstancePagedListRequest,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HazardousSubstanceList);
