import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import JobListContainer from "./jobs-list-container";
import JobAddPage from "./add-job-page";
import PropTypes from "prop-types";

import {
	JOBS_PATH,
	ADD_JOB_PATH,
	OLD_JOBS_PATH,
	OLD_ADD_JOB_PATH,
} from "../actions/types";

const JobsRoutes = ({ onGetJobsList, onSearchClear }) => {
	useEffect(() => {
		onSearchClear();
	}, [onGetJobsList, onSearchClear]);

	return (
		<Switch>
			<Route exact path={ADD_JOB_PATH} component={JobAddPage} />
			<Route exact path={JOBS_PATH} component={JobListContainer} />
			<Route exact path={OLD_ADD_JOB_PATH} component={JobAddPage} />
			<Route exact path={OLD_JOBS_PATH} component={JobListContainer} />
		</Switch>
	);
};

JobsRoutes.propTypes = {
	onGetJobsList: PropTypes.func.isRequired,
	onSearchClear: PropTypes.func.isRequired,
};

export default JobsRoutes;
