import { createMatchSelector } from "connected-react-router";
import { CUSTOMER_PATH, CUSTOMER_ROUTE } from "../actions/types";

export const createSitePath = (id, path) => `${CUSTOMER_PATH}/${id}/${path}`;

// TODO
// This is only a temporary solution
export const getCustomerById = (state) => {
	const match = createMatchSelector(CUSTOMER_ROUTE)(state);

	if (match && match.params && match.params.customerId) {
		const keys = Object.keys(state.customers.pagination?.results);

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			const model = state.customers.pagination?.results[key];

			if (model.id === match.params.customerId) {
				return model;
			}
		}
	}

	return null;
};
