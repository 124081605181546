import { combineEpics } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { authHeaders, tokenExists, getOrganisationBaseUrl } from "frame";
import {
	onGetDocumentPackCountSuccess,
	onGetTimesheetCountSuccess,
	onGetEntityCountsError,
} from "./home-actions";
import { GET_DOCUMENT_PACK_COUNT, GET_TIMESHEET_COUNT } from "./home-actions";

const getDocumentPackCountEffect = (action$, state$) =>
	action$.pipe(
		ofType(GET_DOCUMENT_PACK_COUNT),
		filter(() => state$.value.organisations.currentOrganisation),
		filter(() => tokenExists()),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(() =>
			ajax
				.get(
					`${getOrganisationBaseUrl(
						state$.value
					)}/document-pack/count`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onGetDocumentPackCountSuccess(ajaxResponse.response))
					),
					catchError((error) => of(onGetEntityCountsError(error)))
				)
		)
	);

const getTimesheetCountEffect = (action$, state$) =>
	action$.pipe(
		ofType(GET_TIMESHEET_COUNT),
		filter(() => state$.value.organisations.currentOrganisation),
		filter(() => tokenExists()),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(() =>
			ajax
				.get(
					`${getOrganisationBaseUrl(
						state$.value
					)}/timesheet/count`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onGetTimesheetCountSuccess(ajaxResponse.response))
					),
					catchError((error) => of(onGetEntityCountsError(error)))
				)
		)
	);

const homeEffects = combineEpics(
	getDocumentPackCountEffect,
	getTimesheetCountEffect
);

export default homeEffects;
