import {
	defaultIncidentFormState,
	defaultPeopleFormState,
	defaultRecipientsFormState,
	defaultSiteFormState,
	isLocked,
} from "features/forms";

import {
	defaultSaveState,
	saveRequested,
	saveFailed,
	saveSuccess,
} from "utils";

import * as fromActions from "../actions/types";

export const defaultState = {
	...defaultSaveState,
	...defaultSiteFormState,
	...defaultIncidentFormState,
	...defaultPeopleFormState,
	...defaultRecipientsFormState,
	siteId: "NO-ID",
};

const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case fromActions.ADD_INCIDENT_FORM_RESET:
			return defaultState;
		case fromActions.ADD_INCIDENT_FORM_VALUE_CHANGE:
			return isLocked({
				...state,
				...action.payload,
			});
		case fromActions.ADD_INCIDENT_FORM_SUBMIT:
			return isLocked(saveRequested(state));
		case fromActions.ADD_INCIDENT_FORM_SUBMIT_FAILED:
			return isLocked(saveFailed(state, action.payload));
		case fromActions.ADD_INCIDENT_FORM_SUBMIT_SUCCESS:
			return isLocked(saveSuccess(state));
		default:
			return isLocked(state);
	}
};

export default reducer;
