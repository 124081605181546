import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { safetyWiseApi } from "utils/safetyWiseApi";

import {
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Divider,
} from "@material-ui/core";

import InputEmailForm from "./input-email-form";
import { EmailDropdown } from "./email-dropdown";
import { onList } from "features/staff";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	heading: {
		marginBottom: theme.spacing(-3),
	},
	divider: {
		display: "flex",
		margin: theme.spacing(2, 0),
		width: "100%",
		color: theme.palette.divider,
	},
	fieldLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginLeft: theme.spacing(-1.2),
	},
	label: {
		margin: 0,
	},
	formControlLabel: {
		userSelect: "none",
		marginLeft: theme.spacing(1),
	},
}));

const mapStateToProps = ({ profile, organisations }) => ({
	allowExternalNotifications: profile.allowExternalNotifications,
	currentOrganisationGUID: organisations.currentOrganisation,
});

const mapDispatchToProps = {
	getStaffList: onList,
};

export const EmailRecipientsForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(
	({
		disabled,
		values,
		onChangeRecipients,
		onChangeRecipientsOther,
		onChangeRecipientsCustomerSites,
		allowExternalNotifications,
		hideSendEmailCheckBox,
		hideCardLayout,
		title,
		getStaffList,
		selectedItemId,
		siteId,
		currentOrganisationGUID,
		emailListType,
		customerId,
	}) => {
		const classes = useStyles();
		const [customerSitesEmailRecipients, setCustomerSitesEmailRecipients] =
			useState();
		const [allStaffOfOrganisation, setAllStaffOfOrganisation] = useState();
		const [apiIsLoading, setApiIsLoading] = useState(true);

		useEffect(() => {
			getStaffList();

			const getStaffEmailPromise = safetyWiseApi
				.post(`${currentOrganisationGUID}/GetAllStaffOfOrganisation`)
				.then((response) => {
					setAllStaffOfOrganisation(response.data);
				})
				.catch((error) => console.log(error));
			let getCustomerAndSiteEmailPromise;
				
			if (emailListType === "relatedToParticularSite") {
				getCustomerAndSiteEmailPromise = safetyWiseApi
					.post(
						`${currentOrganisationGUID}/GetCustomersAndSitesEmailsUsingSite/${siteId}`
					)
					.then((response) => {
						setCustomerSitesEmailRecipients(response.data);
					})
					.catch((error) => console.log(error));
			} else if (emailListType === "allCustomerSiteRecord") {
				getCustomerAndSiteEmailPromise = safetyWiseApi
					.get(
						`${currentOrganisationGUID}/GetAllCustomerAndSiteEmails`
					)
					.then((response) => {
						setCustomerSitesEmailRecipients(response.data);
					})
					.catch((error) => console.log(error));
			} else if (emailListType === "relatedToParticularCustomer") {
				getCustomerAndSiteEmailPromise = safetyWiseApi
					.post(
						`${currentOrganisationGUID}/GetCustomersAndSitesEmailsUsingCustomer/${customerId}`
					)
					.then((response) => {
						setCustomerSitesEmailRecipients(response.data);
					})
					.catch((error) => console.log(error));
			}
			
			Promise.all([getStaffEmailPromise, getCustomerAndSiteEmailPromise]).finally(() => setApiIsLoading(false));
		}, []);

		disabled = (values.save && values.save.saving) || disabled;

		const formBody = () => (
			<>
				<Card className={classes.root}>
					<CardHeader
						className={classes.heading}
						title={"Staff user emails"}
						titleTypographyProps={{ variant: "h4" }}
					/>
					<CardContent>
						<EmailDropdown
							onChange={onChangeRecipients}
							disabled={disabled}
							emailOptions={allStaffOfOrganisation}
							emailList={values.recipients}
							placeholder={"Select staff user emails"}
						/>
					</CardContent>
				</Card>
				{allowExternalNotifications && (
					<>
						<Divider className={classes.divider} />
						<Card className={classes.root}>
							<CardHeader
								className={classes.heading}
								title={"Customer and site emails"}
								titleTypographyProps={{ variant: "h4" }}
							/>
							<CardContent>
								<EmailDropdown
									onChange={onChangeRecipientsCustomerSites}
									disabled={disabled}
									emailOptions={customerSitesEmailRecipients}
									emailList={values.recipientsCustomerSites}
									placeholder={
										"Select customer and site emails"
									}
								/>
							</CardContent>
						</Card>
					</>
				)}
				{allowExternalNotifications && (
					<>
						<Divider className={classes.divider} />
						<Card className={classes.root}>
							<CardHeader
								className={classes.heading}
								title={"Other emails"}
								titleTypographyProps={{ variant: "h4" }}
							/>
							<CardContent>
								<InputEmailForm
									disabled={disabled}
									values={values}
									onChange={onChangeRecipientsOther}
								/>
							</CardContent>
						</Card>
					</>
				)}
			</>
		);

		return apiIsLoading ? (
			<div
				style={{
					width: "100%",
					marginTop: "30px",
					marginBottom: "30px",
					alignItems: "center",
					justifyContent: "center",
					position: "relative",
					height: "50px",
				}}
			>
				<CircularProgress
					style={{
						position: "absolute",
						left: "50%",
					}}
				/>
			</div>
		) : !hideCardLayout ? (
			formBody()
		) : (
			<>
				<Divider className={classes.divider} />
				{formBody()}
			</>
		);
	}
);

EmailRecipientsForm.propTypes = {
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onChangeRecipients: PropTypes.func.isRequired,
	onChangeRecipientsCustomerSites: PropTypes.func.isRequired,
	onChangeRecipientsOther: PropTypes.func.isRequired,
	allowExternalNotifications: PropTypes.bool.isRequired,
	hideSendEmailCheckBox: PropTypes.bool,
	hideCardLayout: PropTypes.bool,
};
