import { combineEpics } from "redux-observable";
import { hazardListEffect, hazardPagedListEffect } from "./hazard-list-effect";
import {
	accidentListEffect,
	accidentPagedListEffect,
} from "./accident-list-effect";
import {
	hazardousSubstancesListEffect,
	hazardousSubstancesPagedListEffect,
} from "./hazardous-substances-list-effect";
import {
	inductionMinutesListEffect,
	inductionMinutesPagedListEffect,
} from "./induction-minutes-list-effect";
import {
	safetyMinutesListEffect,
	safetyMinutesPagedListEffect,
} from "./safety-minutes-list-effect";
import {
	jsaListEffect,
	jsaPagedListEffect,
} from "./job-safety-analysis-list-effect";
import {
	safetyPlanChecklistListEffect,
	safetyPlanChecklistPagedListEffect,
} from "./safety-plan-checklist-list-effect";
import {
	staffTrainingListEffect,
	staffTrainingPagedListEffect,
} from "./staff-training-list-effect";
import {
	staffTrainingTopicListEffect,
	staffTrainingTopicPagedListEffect,
} from "./staff-training-topic-list-effect";
import inductionMinutesConfirmAttendanceEffect from "./induction-minutes-confirm-attendance-effect";

import removeEffect from "./remove-effect";

const healthSafetyEffects = combineEpics(
	accidentListEffect,
	accidentPagedListEffect,

	hazardousSubstancesListEffect,
	hazardousSubstancesPagedListEffect,

	hazardListEffect,
	hazardPagedListEffect,

	inductionMinutesListEffect,
	inductionMinutesPagedListEffect,

	safetyMinutesListEffect,
	safetyMinutesPagedListEffect,

	jsaListEffect,
	jsaPagedListEffect,

	safetyPlanChecklistListEffect,
	safetyPlanChecklistPagedListEffect,

	staffTrainingListEffect,
	staffTrainingPagedListEffect,

	staffTrainingTopicListEffect,
	staffTrainingTopicPagedListEffect,

	removeEffect,
	inductionMinutesConfirmAttendanceEffect
);

export default healthSafetyEffects;
