import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";
import { onIncidentListRequest } from "features/health-and-safety";

const mapStateToProps = ({ addIncidentFormReducer }) => ({
	values: addIncidentFormReducer,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddAccidentValueChange,
	onReset: fromActions.onAddAccidentReset,
	onSave: fromActions.onAddAccidentSave,
	onGetIncidentList: onIncidentListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
