import * as R from "ramda";
import initialTokenState from "../models/token-state";
import Types from "../actions/types";

const tokenReceived = (state, action) => {
	state.accessToken = action.payload.access_token;
	state.expiry = action.payload.expires_in;
	return state;
};

const tokenReducer = (state = initialTokenState, action) => {
	switch (action.type) {
		case Types.FRAME_SIGNOUT_CLICKED:
			return initialTokenState;
		case Types.AUTHORISATION_TOKEN_RECEIVED:
			return tokenReceived(R.clone(state), action);
		default:
			return state;
	}
};

export default tokenReducer;
