import { connect } from "react-redux";
import JobsRoutes from "./jobs-routes";
import { onListRequested, onSearchClear } from "../actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	onGetJobsList: onListRequested,
	onSearchClear: onSearchClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsRoutes);
