import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { DOCUMENT_PACK_ROUTE } from "../routes";
import DocumentPackList from "./document-pack-list";

const DocumentPackRoutes = () => {
	return (
		<Switch>
			<Route
				exact
				path={DOCUMENT_PACK_ROUTE}
				component={DocumentPackList}
			/>
			<Route>
				<Redirect to="/" />
			</Route>
		</Switch>
	);
};

export default DocumentPackRoutes;
