import uuid from "uuid";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError, flatMap } from "rxjs/operators";
import {
	onStaffTrainingListRequest,
	TASK_STAFF_TRAINING_ROUTE,
} from "features/health-and-safety";
import { ADD_STAFF_TRAINING_FORM_SUBMIT } from "../actions/types";
import {
	DBService,
	STAFF_TRAINING_TABLE,
	replaceDataURLs,
	authHeaders,
	getOrganisationBaseUrl,
} from "frame";
import { filterPeople, getCurrentTimestamp, isProd } from "utils";
import * as R from "ramda";
import ReactGA from "react-ga";
import {
	onAddStaffTrainingSaveFailed,
	onAddStaffTrainingSaveSuccess,
	onAddStaffTrainingReset,
} from "../actions";

const fetchAttachments = () => async () => {
	const db = new DBService();
	var files = await db.getAll(STAFF_TRAINING_TABLE);
	return files;
};

const addStaffTrainingEffect = (action$, state$) =>
	action$.pipe(
		ofType(ADD_STAFF_TRAINING_FORM_SUBMIT),
		flatMap(fetchAttachments()),
		mergeMap((result) => {
			let attachments = R.clone(
				state$.value.addStaffTrainingReducer.attachments
			);
			attachments = replaceDataURLs(attachments, result);

			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/staff-training/createorupdate`,
					{
						id:
							state$.value.addStaffTrainingReducer.id ||
							uuid.v4(),
						createdAt:
							state$.value.addStaffTrainingReducer.createdAt,
						staffTrainingTopicId:
							state$.value.addStaffTrainingReducer.training
								.staffTrainingTopicId,
						people: filterPeople(
							state$.value.addStaffTrainingReducer.people
						),
						otherPeople:
							state$.value.addStaffTrainingReducer.otherPeople,
						appendNotes:
							state$.value.addStaffTrainingReducer.appendNotes,
						attachments: attachments,
						recipients:
							state$.value.addStaffTrainingReducer.recipients,
						recipientsOther:
							state$.value.addStaffTrainingReducer
								.recipientsOther,
						sendEmail:
							state$.value.addStaffTrainingReducer
								.sendToRecipientsOnSave,
						latitude: state$.value.geolocation.latitude,
						longitude: state$.value.geolocation.longitude,
						timestamp: getCurrentTimestamp(),
						organisationId:
							state$.value.organisations.currentOrganisation,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						isProd &&
							ReactGA.event({
								category: "Task",
								action: "Save Clicked",
								label: "Add Staff Training Record",
							});
						return of(
							onStaffTrainingListRequest(),
							onAddStaffTrainingSaveSuccess(),
							onAddStaffTrainingReset(),
							push(TASK_STAFF_TRAINING_ROUTE)
						);
					}),
					/* eslint-disable */
					catchError((error) =>
						of(onAddStaffTrainingSaveFailed(error.message))
					)
					/* eslint-enable */
				);
		})
	);

export default addStaffTrainingEffect;
