import { useEffect, useState } from "react";

export const useLoadedState = (data, key) => {
	const [values, setValues] = useState(data);
	const [used, setUsed] = useState(false);

	useEffect(() => {
		if (key && !used) {
			setUsed(true);
			setValues(data);
		}
	}, [data, key, used, setValues, setUsed]);

	return [values, setValues];
};
