import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRouter, isStaffUser, isProd } from "utils";
import PageHeaderTabs from "../../health-safety-forms/components/page-header-tabs";
import { Grid, Card } from "@material-ui/core";
import { SearchDropdown } from "frame/components";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
	PopupSnackbar
} from "frame/components";
import { TimesheetForm } from "./timesheet-form";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import {
	LIST_TIMESHEETS_ROUTE,
	ADD_ADMIN_TIMESHEET_ROUTE,
	ADD_TIMESHEET_ROUTE,
	NEW_LEAVE_PATH
} from "../timesheet-routes";
import { TASK_ROUTE } from "features/health-safety-forms";
import {
	onAddAdminTimesheetValueChange,
	onAddAdminTimesheetSave,
	onAddAdminTimesheetReset
} from "../timesheet-admin-actions";
import { SITES_ROUTE } from "../../sites/actions/types";
import { safetyWiseApi } from "utils/safetyWiseApi";
import { useSelector } from "react-redux";
import { isNotNullOrEmpty } from "utils";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(3)
		}
	},
	actions: {
		marginTop: theme.spacing(3),
		paddingBottom: theme.spacing(8)
	},
	menu: {
		"padding": theme.spacing(3, 0, 0, 0),
		"& > *": {
			marginRight: theme.spacing(3)
		}
	},
	siteCardRoot: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(2, 3)
	},
	siteCardFormGroup: {
		margin: theme.spacing(0),
		padding: theme.spacing(1, 0)
	}
}));
const mapStateToProps = ({
	timesheetFormReducer,
	timesheetAdminFormReducer,
	profile,
	organisations
}) => ({
	values: timesheetFormReducer,
	valuesAdmin: timesheetAdminFormReducer,
	isValid: timesheetAdminFormReducer.isValid,
	role: profile.role,
	currentOrganisationGUID: organisations.currentOrganisation,
	currentStaffId: profile.staffId
});

const mapDispatchToProps = {
	onChangeAdmin: onAddAdminTimesheetValueChange,
	onResetAdmin: onAddAdminTimesheetReset,
	onSaveAdmin: onAddAdminTimesheetSave
};

const NewLeaveForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ values, role, currentOrganisationGUID, currentStaffId }) => {
	const classes = useStyles();
	const { match } = useRouter();
	const { siteId } = match.params;
	const theme = useTheme();
	const { history } = useRouter();
	const onSubmit = (event) => {
		event.preventDefault();
		isProd &&
			ReactGA.event({
				category: "Leave",
				action: "Leave Saved",
				label: "New Leave"
			});
	};
	const defaultLeaveValues = {
		siteId: "",
		timesheet: {
			staffId: "",
			startDate: "",
			endDate: "",
			notes: "",
			toggle: false,
			startLatitude: null,
			startLongitude: null,
			endLatitude: null,
			endLongitude: null
		},
		isEndDateTimeInvalid: false
	};
	const [leaveFormValues, setLeaveFormValues] = useState(defaultLeaveValues);
	const siteList = useSelector(({ sites }) => sites.pagination?.results || []);
	const siteOptions = isNotNullOrEmpty(siteList)
		? siteList
				.filter((site) => {
					return site.siteName.includes("Leave - ") == true;
				})
				.map((site) => ({
					label: site.siteName,
					value: site.id
				}))
				.sort((a, b) => (a.label > b.label ? 1 : -1))
		: [];

	siteOptions.filter((s) => s.label.includes("Leave - ") == false);

	return (
		<Page title="Add Timesheet">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				title={"New Leave"}
				staffUserHide={false}
				addTabTitle={values.timesheet.toggle ? "Active Timesheet" : undefined}
				hideAddTabIcon={!!values.timesheet.toggle}
				addTabPath={siteId ? `${ADD_TIMESHEET_ROUTE}/${siteId}` : ADD_TIMESHEET_ROUTE}
				listTabPath={LIST_TIMESHEETS_ROUTE}
				addAdminPath={siteId ? `${ADD_ADMIN_TIMESHEET_ROUTE}/${siteId}` : ADD_ADMIN_TIMESHEET_ROUTE}
				backActionPath={siteId ? `${SITES_ROUTE}/${siteId}` : TASK_ROUTE}
				showAddAdminButton={!isStaffUser(role)}
				showLeaveButton={true}
				newLeavePath={NEW_LEAVE_PATH}
			/>
			<PageContent padSides padBottom>
				<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
					<Grid container spacing={3}>
						<Grid item lg={6} md={12} sm={12} xs={12}>
							<div className={classes.body}>
								<Card className={classes.siteCardRoot}>
									<div className={classes.siteCardFormGroup}>
										{siteOptions.length == 0 ? (
											<p>Loading Leave Types ...</p>
										) : (
											<SearchDropdown
												id="site"
												data-cy="site"
												label="Leave Type *"
												disabled={false}
												defaultOptions={siteOptions}
												value={
													siteOptions.length > 0 && leaveFormValues.siteId
														? siteOptions.find((s) => s.value == leaveFormValues.siteId).value
														: ""
												}
												onChange={(e) => {
													setLeaveFormValues({ ...leaveFormValues, siteId: e });
												}}
											/>
										)}
									</div>
								</Card>

								{leaveFormValues.siteId && (
									<TimesheetForm
										values={leaveFormValues}
										onChange={(e) => {
											setLeaveFormValues(e);
										}}
										leaveForm={true}
										showStaffDropdown={!isStaffUser(role)}
									/>
								)}
							</div>
						</Grid>
					</Grid>

					{leaveFormValues.siteId && (
						<FormActions className={classes.actions}>
							<FormSubmitButton
								onClick={() => {
									safetyWiseApi
										.post(`${currentOrganisationGUID}/timesheet/createLeaves`, {
											startDate: leaveFormValues.timesheet.startDate,
											endDate: leaveFormValues.timesheet.endDate,
											staffId: leaveFormValues.timesheet.staffId || currentStaffId, // If staff role is not admin or superadmin use currentstaffId
											leaveTypeID: leaveFormValues.siteId,
											notes: leaveFormValues.timesheet.notes,
											startLatitude: leaveFormValues.timesheet.startLatitude,
											startLongitude: leaveFormValues.timesheet.startLongitude
										})
										.then((result) => {
											console.log(result);
											if (result.data) {
												alert(result.data);
											} else {
												history.push(LIST_TIMESHEETS_ROUTE);
											}
										});
								}}
								disabled={
									!(
										leaveFormValues.timesheet.startDate &&
										leaveFormValues.timesheet.endDate &&
										(isStaffUser(role) ? true : leaveFormValues.timesheet.staffId) &&
										leaveFormValues.siteId
									)
								}
							>
								Save
							</FormSubmitButton>
							<FormButton
								onClick={() => {
									setLeaveFormValues(defaultLeaveValues);
								}}
								disabled={
									!(
										leaveFormValues.timesheet.startDate ||
										leaveFormValues.timesheet.endDate ||
										leaveFormValues.timesheet.staffId ||
										leaveFormValues.siteId ||
										leaveFormValues.timesheet.notes
									)
								}
							>
								Clear
							</FormButton>
						</FormActions>
					)}
				</ValidatorForm>
				<PopupSnackbar message={values.errorMessage} coloured error onExited={() => {}} />
			</PageContent>
		</Page>
	);
});
export default NewLeaveForm;
