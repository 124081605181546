import { getToken } from "./access-token";

export const authHeaders = () => {
	const token = getToken();

	if (!token) {
		return {};
	}

	return {
		Authorization: `Bearer ${token}`,
		"Content-Type": "application/json",
	};
};
