import React, { useEffect, useState, createRef } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Elements, StripeProvider, CardElement, injectStripe } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import { Divider, Link } from "@material-ui/core";
import { getReCaptchaSiteKey } from "frame";
import ReCAPTCHA from "react-google-recaptcha";
import { FormSubmitButton, LookupValidationTextField } from "frame/components";
import * as fromAuth from "features/authorisation";
import { isProd } from "utils";

const useStyles = makeStyles((theme) => ({
	divider: {
		margin: theme.spacing(2, 0)
	},
	fields: {
		"margin": theme.spacing(-1),
		"display": "flex",
		"flexWrap": "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1)
		}
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%"
	},
	error: {
		color: theme.palette.error.main
	},
	success: {
		fontSize: "16px",
		lineHeight: "24px",
		margin: "24px 0"
	},
	stripeWrapper: {
		border: "solid 1px #D9D9D9",
		borderRadius: "5px",
		padding: "20px 10px 20px 10px",
		marginTop: "5px"
	},
	stripeWrapperRed: {
		border: "solid 1px",
		borderColor: theme.palette.error.main,
		borderRadius: "5px",
		padding: "20px 10px 20px 10px",
		marginTop: "5px"
	},
	captcha: {
		paddingTop: "15px"
	}
}));

const RegisterForm = ({
	organisation,
	firstName,
	lastName,
	phone,
	emailAddress,
	registerErrorMessage,
	stripe,
	save,
	onChangeEmail,
	onOrganisationChange,
	onChangeFirstName,
	onChangeLastName,
	onChangePhone,
	onChangeCreditCard,
	onRegister,
	onRegisterErrorClear
}) => {
	const recaptchaRef = createRef();
	const classes = useStyles();
	const [recaptchaValue, setRecaptchaValue] = useState();
	const { saving } = save;

	useEffect(() => {
		onRegisterErrorClear(); // clear errors on load
	}, [onRegisterErrorClear]);

	useEffect(() => {
		if (!isProd) {
			setRecaptchaValue(true);
		}
	}, []);

	const onSubmit = (event) => {
		setRecaptchaValue(false);
		event.preventDefault();
		stripe
			.createToken({ name: organisation })
			.then((token) => {
				if (token.error) {
					console.log(token.error.message);
				} else {
					onRegister(token);
				}
			})
			.catch((err) => {
				console.log(err);
				setRecaptchaValue(true);
			});
	};

	const getValue = (cb) => (event) => cb(event.target.value);

	return (
		<div>
			{save.done && save.success ? (
				<p className={classes.success}>
					We&apos;ve sent a confirmation email to your email address. Please check the email to
					confirm your account and set your password.
				</p>
			) : (
				<ValidatorForm onSubmit={onSubmit}>
					<div className={classes.fields}>
						<LookupValidationTextField
							disabled={saving}
							fullWidth
							label="Organisation *"
							name="Oorganisation"
							inputProps={{ maxLength: 50 }}
							value={organisation}
							variant="outlined"
							validators={["required", "isAvailable"]}
							onChange={(val) => onOrganisationChange(val)}
							errorMessages={["This field is required", "Organisation already exists"]}
						/>
						<TextValidator
							disabled={saving}
							fullWidth
							label="First name *"
							name="firstName"
							onChange={getValue(onChangeFirstName)}
							inputProps={{ maxLength: 50 }}
							value={firstName}
							variant="outlined"
							validators={["required"]}
							errorMessages={["This field is required"]}
						/>
						<TextValidator
							disabled={saving}
							fullWidth
							label="Last name *"
							name="lastName"
							onChange={getValue(onChangeLastName)}
							inputProps={{ maxLength: 50 }}
							value={lastName}
							variant="outlined"
							validators={["required"]}
							errorMessages={["This field is required"]}
						/>
						<TextValidator
							disabled={saving}
							fullWidth
							label="Phone *"
							name="phone"
							onChange={getValue(onChangePhone)}
							inputProps={{ maxLength: 50 }}
							value={phone}
							variant="outlined"
							validators={["required", "isNumber"]}
							errorMessages={["This field is required", "A valid phone number is required"]}
						/>
						<LookupValidationTextField
							disabled={saving}
							fullWidth
							label="Email *"
							name="email"
							inputProps={{ maxLength: 50 }}
							value={emailAddress}
							variant="outlined"
							validators={["required", "isEmail", "emailExists"]}
							onChange={(val) => onChangeEmail(val)}
							errorMessages={[
								"This field is required",
								"Must be a valid email",
								"Email already exists"
							]}
						/>

						<div className={classes.stripeWrapper}>
							<CardElement hidePostalCode={true} />
						</div>
					</div>
					{isProd && (
						<div className={classes.captcha}>
							<ReCAPTCHA
								ref={recaptchaRef}
								render="explicit"
								sitekey={getReCaptchaSiteKey()}
								onChange={(value) => setRecaptchaValue(value)}
							/>
						</div>
					)}
					<FormSubmitButton
						data-cy="create-account"
						className={classes.submitButton}
						fullWidth
						loading={saving}
						disabled={!recaptchaValue}
					>
						Create Account
					</FormSubmitButton>
				</ValidatorForm>
			)}
			{registerErrorMessage && (
				<>
					<Divider className={classes.divider} />
					<span className={classes.error}>{registerErrorMessage}</span>
				</>
			)}
			<Divider className={classes.divider} />
			<Link
				align="center"
				color="secondary"
				component={RouterLink}
				to={fromAuth.LOGIN_PATH}
				underline="always"
				variant="subtitle2"
			>
				{save.done && save.success ? "Take me back to login" : "Have an account?"}
			</Link>
		</div>
	);
};

RegisterForm.propTypes = {
	organisation: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	phone: PropTypes.string,
	emailAddress: PropTypes.string,
	stripe: PropTypes.object,
	subscription: PropTypes.object.isRequired,
	registerErrorMessage: PropTypes.string,
	save: PropTypes.object.isRequired,
	onStripeError: PropTypes.func,
	onChangeEmail: PropTypes.func.isRequired,
	onOrganisationChange: PropTypes.func.isRequired,
	onChangeFirstName: PropTypes.func.isRequired,
	onChangeLastName: PropTypes.func.isRequired,
	onChangePhone: PropTypes.func.isRequired,
	onRegister: PropTypes.func.isRequired,
	onRegisterErrorClear: PropTypes.func.isRequired,
	params: PropTypes.string
};

const mapStateToProps = ({ subscription, staff }) => ({
	stripeErrorMessage: subscription.creditCardTokenErrorMessage,
	subscription: subscription
});

export default injectStripe(connect(mapStateToProps)(RegisterForm));
