import React, { useEffect, useState } from "react";
import {
	Dialog,
	FormActions,
	FormButton,
	FormSubmitButton,
} from "frame/components";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import {EmailRecipientsForm}  from "./email-recipients-form/email-recipients-form";
import { ValidatorForm } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
	},
	header: {
		marginBottom: theme.spacing(-5),
		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(-2),
		},
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
	hiddenDateForm: {
		display: "none",
	},
}));

const SendEmailModal = ({
	title,
	onClose,
	isOpen,
	onSend,
	isSaving,
	selectedItemId,
	siteId,
	emailListType = null,
	customerId = null,
}) => {
	const classes = useStyles();
	const [selectedRecipients, setSelectedRecipients] = useState([]);
	const [selectedRecipientsOther, setSelectedRecipientsOther] = useState([]);
	const [
		selectedRecipientsCustomerSites,
		setSelectedRecipientsCustomerSites,
	] = useState([]);

	useEffect(() => {
		if (isOpen) {
			setSelectedRecipients([]);
			setSelectedRecipientsOther([]);
			setSelectedRecipientsCustomerSites([]);
		}
	}, [isOpen]);

	return (
		<Dialog title={title} open={isOpen} onClose={onClose}>
			<ValidatorForm
				autoComplete="off"
				noValidate
				onSubmit={() =>
					onSend({
						recipients: selectedRecipients,
						recipientsOther: selectedRecipientsOther,
						recipientsCustomerSites:
							selectedRecipientsCustomerSites,
					})
				}
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<EmailRecipientsForm
							title={"Add Email Addresses"}
							disabled={false}
							values={{
								recipients: selectedRecipients,
								recipientsOther: selectedRecipientsOther,
								recipientsCustomerSites:
									selectedRecipientsCustomerSites,
							}}
							onChangeRecipients={(change) => {
								setSelectedRecipients(change);
							}}
							onChangeRecipientsOther={(change) => {
								setSelectedRecipientsOther(change);
							}}
							onChangeRecipientsCustomerSites={(change) => {
								setSelectedRecipientsCustomerSites(change);
							}}
							hideSendEmailCheckBox={true}
							selectedItemId={selectedItemId}
							siteId={siteId}
							emailListType={emailListType}
							customerId={customerId}
						/>
					</Grid>
				</Grid>
				<FormActions className={classes.actions}>
					<FormSubmitButton
						disabled={
							(selectedRecipients.length === 0 &&
								selectedRecipientsOther.length === 0 &&
								selectedRecipientsCustomerSites.length === 0) ||
							isSaving
						}
						loading={isSaving}
					>
						Send
					</FormSubmitButton>
					<FormButton disabled={isSaving} onClick={onClose}>
						Cancel
					</FormButton>
				</FormActions>
			</ValidatorForm>
		</Dialog>
	);
};

export default SendEmailModal;
