import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { HazardousSubstanceDetailsDialog } from "frame/components";

const RedCheckbox = withStyles((theme) => ({
	root: {
		"&$disabled": {
			color: theme.palette.error.main,
		},
	},
	checked: {},
	disabled: {},
}))(Checkbox);

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0.5, 0),
	},
	label: {
		marginTop: theme.spacing(1.2),
		paddingRight: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			paddingRight: theme.spacing(0),
			minWidth: 140,
		},
	},
	link: {
		marginTop: theme.spacing(1.5),
		color: theme.palette.text.link,
		cursor: "pointer",
	},
	guidelines: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-end",
	},
	error: {
		color: theme.palette.error.main,
	},
}));

const SelectHazardousSubstance = ({
	values,
	onChange,
	disabled,
	hazardousSubstanceId,
	hazardousSubstanceList,
	editMode,
	showError,
}) => {
	const classes = useStyles();
	const [loaded, setLoaded] = useState(false);

	const [checkBoxToggle, setCheckBoxToggle] = useState(false);
	const [data, setData] = useState(false);
	const [seenGuidelines, setSeenGuidelines] = useState(true);

	const selectedSubstance =
		hazardousSubstanceList &&
		hazardousSubstanceList.filter(
			(substance) => substance.id === hazardousSubstanceId
		)[0];

	useEffect(() => {
		// only runs once on load to keep existing substances checked in editMode on load
		// then afterwards uses next useEffect to reset substances on siteId change
		setLoaded(true);

		if (editMode) {
			const isExistingSubstance = JSON.stringify(
				values.substances
			).includes(hazardousSubstanceId);
			if (isExistingSubstance) {
				setCheckBoxToggle(isExistingSubstance);
				setSeenGuidelines(false);
			}
		} else {
			setSeenGuidelines(true);
			setCheckBoxToggle(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// resets substances unchecked on siteId change
		if (loaded && values.siteId) {
			setSeenGuidelines(true);
			setCheckBoxToggle(false);
		}
		// eslint-disable-next-line
	}, [values.siteId]);

	const onCheckChange = ({ target: { checked, value } }) => {
		setCheckBoxToggle(checked);
		let inductionSubstances = [...values.substances];
		if (checked) {
			inductionSubstances.push(value);
		} else {
			inductionSubstances = inductionSubstances.filter(
				(substance) => substance !== value
			);
		}

		inductionSubstances = Array.from(new Set(inductionSubstances));
		onChange({
			...values,
			substances: inductionSubstances,
		});
	};

	const onSelect = () => {
		const inductionSubstances = [...values.substances];
		inductionSubstances.push(selectedSubstance.id);
		onChange({
			...values,
			substances: Array.from(new Set(inductionSubstances)),
		});
		setCheckBoxToggle(true);
		setSeenGuidelines(false);
		onClose();
	};

	const onClose = () => setData(false);

	return (
		<div className={classes.root}>
			{selectedSubstance && (
				<Grid container>
					<Grid item xs={6}>
						<Typography
							className={classes.label}
							align="left"
							gutterBottom
							variant="body1"
						>
							{selectedSubstance.name || ""}
						</Typography>
					</Grid>

					<Grid item xs={6} className={classes.guidelines}>
						<Typography
							className={classes.link}
							align="right"
							gutterBottom
							variant="body1"
							onClick={() => setData(selectedSubstance)}
						>
							{showError && !checkBoxToggle ? (
								<span className={classes.error}>
									Read Guidelines
								</span>
							) : (
								"Read Guidelines"
							)}
						</Typography>

						{showError && !checkBoxToggle ? (
							<RedCheckbox
								disabled={disabled || seenGuidelines}
								checked={checkBoxToggle}
								onChange={onCheckChange}
								value={selectedSubstance.id}
							/>
						) : (
							<Checkbox
								disabled={disabled || seenGuidelines}
								checked={checkBoxToggle}
								onChange={onCheckChange}
								value={selectedSubstance.id}
							/>
						)}
					</Grid>
				</Grid>
			)}
			{data && (
				<HazardousSubstanceDetailsDialog
					data={data}
					onSelect={onSelect}
					onClose={onClose}
				/>
			)}
		</div>
	);
};

SelectHazardousSubstance.propTypes = {
	hazardousSubstanceId: PropTypes.string.isRequired,
	hazardousSubstanceList: PropTypes.array.isRequired,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	editMode: PropTypes.bool,
	showError: PropTypes.bool,
};

export default SelectHazardousSubstance;
