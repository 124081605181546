import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
} from "frame/components";
import {
	strip,
	DateForm,
	TrainingTopicForm,
	EmailRecipientsForm,
} from "features/forms";
import { useSelector } from "react-redux";
import ReadOnlyForm from "./read-only-form";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-3),
	},
	actions: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(8),
	},
	menu: {
		padding: theme.spacing(3, 0, 0, 0),
		"& > *": {
			marginRight: theme.spacing(3),
		},
	},
}));

const hydrate = ({
	id,
	createdAt,
	recipients,
	recipientsOther,
	organisationId,
	...training
}) => ({
	id,
	createdAt,
	training,
	recipients,
	recipientsOther,
	currentOrganisation: organisationId,
});

const Form = ({ id, values, onClose, onChange, onReset, onSave, readOnly }) => {
	const classes = useStyles();
	const topicList = useSelector(
		({ healthSafety }) => healthSafety.staffTrainingTopics?.results
	);

	const [loaded, setLoaded] = useState(false);
	const [title, setTitle] = useState(false);
	const open = values && values.id;

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchData() {
			setLoaded(true);
			const topic = topicList && topicList.filter((t) => t.id === id)[0];

			if (topic) {
				onChange(hydrate(topic));

				if (topic.name) {
					setTitle(topic.name);
				}
			}
		}
		if (id) {
			setLoaded(true);
			fetchData();
		} // eslint-disable-next-line
	}, [id, loaded, onChange]);

	useEffect(() => {
		values.save.success && onClose();
	}, [values.save.success, onClose]);

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
	};

	const onCancel = () => {
		onReset();
		onClose();
	};

	if (!open) {
		return null;
	}

	const topic = values.training || {};

	const trainingTitle = readOnly
		? `View Staff Training Topic: ${title}`
		: `Edit Staff Training Topic: ${title}`;

	const isValid = (value) =>
		value && typeof value === "string" && value.trim() !== "";
	const validated =
		isValid(topic.name) &&
		((isValid(topic.validMeasurement) && topic.validDuration !== "") ||
			topic.validMeasurement === "Unlimited/Indefinite");

	return (
		<Dialog
			open={open ? true : false}
			aria-labelledby="Staff Training Topic"
			// breadcrumbs="Staff Trainings"
			title={trainingTitle}
			onClose={onCancel}
		>
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				{readOnly ? (
					<ReadOnlyForm values={values} />
				) : (
					<div className={classes.body}>
						<DateForm values={values} onChange={onChange} />
						<TrainingTopicForm
							editMode
							values={values}
							onChange={onChange}
						/>
						<EmailRecipientsForm
							values={values}
							onChange={onChange}
						/>
					</div>
				)}

				<FormActions className={classes.actions}>
					{!readOnly && (
						<FormSubmitButton
							disabled={!validated}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
					)}
					<FormButton
						disabled={values.save.saving}
						onClick={onCancel}
					>
						{readOnly ? "Close" : "Cancel"}
					</FormButton>
				</FormActions>
			</ValidatorForm>
		</Dialog>
	);
};

Form.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
};

export default Form;
