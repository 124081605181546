import * as R from "ramda";
import initialCustomersState from "../models/customers-state";
import Types from "../actions/types";
import CustomerModel from "../models/customer-model";
import uuid from "uuid";
import {
	validateEmailAddress,
	hasValue,
	sortByPropertyAlphabetically,
} from "utils";

const setIsValid = (customer) => {
	customer.emailAddressErrorMessage = validateEmailAddress(
		customer.emailAddress
	);
	customer.isValid =
		!customer.emailAddressErrorMessage &&
		hasValue(customer.customerName) &&
		hasValue(customer.contactPerson) &&
		hasValue(customer.emailAddress);
};

const addClicked = (state, action) => {
	state.isAddModalShowing = true;
	state.isNew = true;
	state.isEditModalShowing = false;
	state.currentCustomer = CustomerModel;
	state.currentCustomer.id = uuid.v4();
	return state;
};

const editClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isNew = false;
	state.isEditModalShowing = true;
	state.currentCustomer = R.clone(
		R.find(R.propEq("id", action.payload), state.pagination.results)
	);
	return state;
};

const saveClicked = (state, action) => {
	if (state.isAddModalShowing) {
		state.isAddModalShowing = false;
	} else if (state.isEditModalShowing) {
		state.isEditModalShowing = false;
	}
	return state;
};

const addSucceeded = (state, action) => {
	// state.pagination = updatePaginationState(state.pagination, state.currentCustomer, state.isNew)
	if (state.isNew) {
		state.pagination.results.push(state.currentCustomer);
		state.pagination.results = state.pagination.results.sort(
			sortByPropertyAlphabetically("customerName")
		);
	} else {
		const index = R.findIndex(R.propEq("id", state.currentCustomer.id))(
			state.pagination.results
		);
		state.pagination.results[index] = state.currentCustomer;
	}
	state.currentCustomer = CustomerModel;
	return state;
};

const closeClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isNew = true;
	state.isEditModalShowing = false;
	state.currentCustomer = CustomerModel;
	state.currentCustomer.id = uuid.v4();
	return state;
};

const removeClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isEditModalShowing = false;
	state.currentCustomer = R.find(
		R.propEq("id", action.payload),
		state.pagination.results
	);
	state.currentCustomer = CustomerModel;
	return state;
};

const customerRemoveSucceeded = (state, action) => {
	// state.customerList = state.customerList.filter(c => c.id !== state.currentCustomer.id)
	return state;
};

const contactPersonChanged = (state, action) => {
	state.currentCustomer.contactPerson = action.payload;
	setIsValid(state.currentCustomer);
	return state;
};

const customerNameChanged = (state, action) => {
	state.currentCustomer.customerName = action.payload;
	setIsValid(state.currentCustomer);
	return state;
};

const emailAddressChanged = (state, action) => {
	state.currentCustomer.emailAddress = action.payload;
	setIsValid(state.currentCustomer);
	return state;
};

const statusChanged = (state, action) => {
	state.currentCustomer.status = action.payload;
	setIsValid(state.currentCustomer);
	return state;
};

const consultantChanged = (state, action) => {
	state.currentCustomer.consultant = action.payload;
	setIsValid(state.currentCustomer);
	return state;
};

const getCustomerLists = (state, action) => {
	state.isAddModalShowing = false;
	state.isEditModalShowing = false;
	return state;
};

const customerListReceived = (state, action) => {
	// state.customerList = []
	state.pagination = action.payload;
	// action.payload.results.map(item => {
	//   state.customerList.push({
	//     id: item.id,
	//     customerName: item.customerName,
	//     contactPerson: item.contactPerson,
	//     emailAddress: item.emailAddress,
	//     status: item.status,
	//     consultant: item.consultant
	//   })
	//   return item
	// })
	return state;
};

const customerDetails = (state, action) => {
	state.currentCustomer = R.clone(
		R.find(R.propEq("id", action.payload), state.pagination.results)
	);
	return state;
};

const searchChanged = (state, action) => {
	state.searchFilter = action.payload;
	return state;
};

const searchClear = (state, action) => {
	state.searchFilter = "";
	return state;
};

const customersReducer = (state = initialCustomersState, action) => {
	switch (action.type) {
		case Types.CUSTOMERS_SEARCH_CHANGED:
			return searchChanged(R.clone(state), action);
		case Types.CUSTOMERS_SEARCH_CLEAR:
			return searchClear(R.clone(state), action);
		case Types.CUSTOMERS_ADD_CLICKED:
			return addClicked(R.clone(state), action);
		case Types.CUSTOMERS_EDIT_CLICKED:
			return editClicked(R.clone(state), action);
		case Types.CUSTOMERS_REMOVE_CLICKED:
			return removeClicked(R.clone(state), action);
		case Types.CUSTOMERS_REMOVE_SUCCEEDED:
			return customerRemoveSucceeded(R.clone(state), action);
		case Types.CUSTOMERS_SAVE_CLICKED:
			return saveClicked(R.clone(state), action);
		case Types.CUSTOMERS_ADD_SUCCEEDED:
			return addSucceeded(R.clone(state), action);
		case Types.CUSTOMERS_CLOSE_CLICKED:
			return closeClicked(R.clone(state), action);
		case Types.CUSTOMERS_CUSTOMER_NAME_CHANGED:
			return customerNameChanged(R.clone(state), action);
		case Types.CUSTOMERS_CONTACT_PERSON_CHANGED:
			return contactPersonChanged(R.clone(state), action);
		case Types.CUSTOMERS_EMAIL_ADDRESS_CHANGED:
			return emailAddressChanged(R.clone(state), action);
		case Types.CUSTOMERS_STATUS_CHANGED:
			return statusChanged(R.clone(state), action);
		case Types.CUSTOMERS_CONSULTANT_CHANGED:
			return consultantChanged(R.clone(state), action);
		case Types.CUSTOMERS_LIST_REQUESTED:
			return getCustomerLists(R.clone(state), action);
		case Types.CUSTOMERS_LIST_RECEIVED:
			return customerListReceived(R.clone(state), action);
		case Types.CUSTOMERS_DETAILS:
			return customerDetails(R.clone(state), action);
		default:
			return state;
	}
};

export default customersReducer;
