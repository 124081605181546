import * as R from "ramda";
import fromAuth from "../actions/types";

const defaultState = {};

const confirmationReducer = (state = defaultState, action) => {
	switch (action.type) {
		case fromAuth.AUTHORISATION_CONFIRMATION_FULFILLED: {
			return confirmationFulfilled(R.clone(state), action);
		}
		case fromAuth.AUTHORISATION_CONFIRMATION_FAILED: {
			return confirmationFailed(R.clone(state), action);
		}
		default:
			return state;
	}
};

const confirmationFulfilled = (state, action) => {
	state.responseMessage = action.payload;
	return state;
};
const confirmationFailed = (state, action) => {
	state.responseMessage = action.payload;
	return state;
};

export default confirmationReducer;
