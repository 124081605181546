import { connect } from "react-redux";
import SetPassword from "./set-password";

import {
	onSetPasswordPasswordChange,
	onSetPasswordConfirmPasswordChange,
	onSetPassword,
} from "../actions";

const mapStateToProps = ({ authorisation, router }) => ({
	password: authorisation.setPassword.password,
	confirmPassword: authorisation.setPassword.confirmPassword,
	isValid: authorisation.setPassword.isValid,
	passwordMatch: authorisation.setPassword.passwordMatch,
	// hasNumber: authorisation.setPassword.hasNumber,
	meetsPasswordLength: authorisation.setPassword.meetsPasswordLength,
	token: router.location.search,
	save: authorisation.setPassword.save,
	responseMessage: authorisation.setPassword.responseMessage,
});

const mapDispatchToProps = {
	onChangePassword: onSetPasswordPasswordChange,
	onChangeConfirmPassword: onSetPasswordConfirmPasswordChange,
	onSetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
