import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import {
	invitationsReducer,
	passwordReducer,
	preferencesReducer,
	profileReducer,
	reportReducer,
	subscriptionReducer,
} from "features/account";
import authorisationReducers from "features/authorisation/reducers";
import staffReducer from "features/staff/reducers/staff-reducer";
import { jobsReducer } from "features/jobs/reducers/jobs-reducer";
import customersReducer from "features/customers/reducers/customers-reducer";
import { reducer as siteReducer } from "features/sites";
import healthSafetyReducer from "features/health-and-safety/reducers/health-safety-reducer";
import notificationsReducer from "features/notifications/reducers/notifications-reducer";
import { organisationReducer } from "features/organisations";
import { geolocationReducer } from "features/geolocation";
import frameReducer from "./frame-reducer";
import queueReducer from "features/queue/reducers/queue-reducer";
import homeReducer from "features/home/home-reducer";
import uploadFormReducer from "features/forms/reducers/upload-form-reducer";
import { timesheetReducer } from "features/timesheet/timesheet-reducer";
import { timesheetFormReducer } from "features/timesheet/timesheet-form-reducer";
import {timesheetAdminFormReducer} from "features/timesheet/timesheet-admin-form-reducer";
import { taskTypeReducer } from "features/task-type/reducers";

import {
	addIncidentFormReducer,
	addHazardFormReducer,
	addHazardousSubstanceFormReducer,
	addSafetyPlanChecklistFormReducer,
	addJobSafetyAnalysisFormReducer,
	addInductionMinutesFormReducer,
	addSafetyMinutesFormReducer,
	addStaffTrainingReducer,
	addStaffTrainingTopicReducer,
} from "features/health-safety-forms";

const authPersistConfig = {
	key: "safetywise:authorisation",
	storage,
	blacklist: [
		"login",
		"register",
		"forgotPassword",
		"setPassword",
		"resetPassword",
	],
};

const createsStandardPersisConfig = (name, reducer) =>
	persistReducer(
		{
			key: `safetywise:${name}`,
			storage,
			blacklist: [
				"fetch",
				"locked",
				"save",
				"password",
				"attachments", // avoid persisting attachments - they should be cleaned up for every new form, and they're too big to be put into local storage

				// this is a temporary solution to prevent the persistence of the New Job form - trying to remove the whole reducer from persistence
				// will break the app, so just blacklisting the relevant state instead
				"currentJob",
			],
		},
		reducer
	);

export default (history) =>
	combineReducers({
		router: connectRouter(history),
		queue: createsStandardPersisConfig(
			"queue" + window.versionId,
			queueReducer
		),
		invitations: createsStandardPersisConfig(
			"invitations" + window.versionId,
			invitationsReducer
		),
		password: passwordReducer,
		preferences: createsStandardPersisConfig(
			"preferences" + window.versionId,
			preferencesReducer
		),
		profile: createsStandardPersisConfig(
			"profile" + window.versionId,
			profileReducer
		),
		home: createsStandardPersisConfig(
			"home" + window.versionId,
			homeReducer
		),
		report: createsStandardPersisConfig(
			"report" + window.versionId,
			reportReducer
		),
		subscription: createsStandardPersisConfig(
			"subscription" + window.versionId,
			subscriptionReducer
		),
		organisations: createsStandardPersisConfig(
			"organisations" + window.versionId,
			organisationReducer
		),
		geolocation: createsStandardPersisConfig(
			"geolocation" + window.versionId,
			geolocationReducer
		),
		frame: createsStandardPersisConfig(
			"frame" + window.versionId,
			frameReducer
		),
		authorisation: persistReducer(authPersistConfig, authorisationReducers),
		staff: createsStandardPersisConfig(
			"staff" + window.versionId,
			staffReducer
		),
		jobs: createsStandardPersisConfig(
			"jobs" + window.versionId,
			jobsReducer
		),
		customers: createsStandardPersisConfig(
			"customers" + window.versionId,
			customersReducer
		),
		sites: createsStandardPersisConfig(
			"sites" + window.versionId,
			siteReducer
		),
		healthSafety: createsStandardPersisConfig(
			"healthSafety" + window.versionId,
			healthSafetyReducer
		),
		notifications: createsStandardPersisConfig(
			"notifications" + window.versionId,
			notificationsReducer
		),
		taskTypes: createsStandardPersisConfig(
			"taskTypes" + window.versionId,
			taskTypeReducer
		),
		addIncidentFormReducer,
		addHazardFormReducer,
		addHazardousSubstanceFormReducer,
		addSafetyPlanChecklistFormReducer,
		addJobSafetyAnalysisFormReducer,
		addInductionMinutesFormReducer,
		addSafetyMinutesFormReducer,
		addStaffTrainingReducer,
		addStaffTrainingTopicReducer,
		uploadFormReducer,
		timesheetReducer,
		timesheetFormReducer,
		timesheetAdminFormReducer
	});
