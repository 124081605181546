import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import PeopleForm from "./people-form";
import PeopleOtherForm from "./people-other-form";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	heading: {
		marginBottom: theme.spacing(-3),
	},
	divider: {
		display: "flex",
		margin: theme.spacing(0, 0, 2, 0),
		width: "100%",
		color: theme.palette.divider,
	},
	helperText: {
		marginTop: theme.spacing(-0.5),
		marginBottom: theme.spacing(1),
	},
}));

const AddPeopleForm = ({
	className,
	heading,
	otherHeading,
	values,
	onChange,
	disabled,
	roleOptions,
	customRoleName,
	recordName,
	defaultStaffRole,
	showInvalidPeopleError,
}) => {
	const classes = useStyles();
	disabled = values.save.saving || disabled;

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title={heading ? heading : "Add People"}
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent>
				<Typography variant="body1" className={classes.helperText}>
					{recordName
						? `Add people to this ${recordName} record.`
						: `Add people to this record.`}
				</Typography>
				<PeopleForm
					disabled={disabled}
					values={values}
					onChange={onChange}
					customOptions={roleOptions}
					customRoleName={customRoleName}
					defaultStaffRole={defaultStaffRole}
					showInvalidPeopleError={showInvalidPeopleError}
				/>
				<PeopleOtherForm
					heading={otherHeading}
					disabled={disabled}
					values={values}
					onChange={onChange}
				/>
			</CardContent>
		</Card>
	);
};

AddPeopleForm.propTypes = {
	className: PropTypes.string,
	heading: PropTypes.string,
	otherHeading: PropTypes.string,
	roleOptions: PropTypes.array,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	customRoleName: PropTypes.string,
	recordName: PropTypes.string,
	defaultStaffRole: PropTypes.string,
	showInvalidPeopleError: PropTypes.bool,
};

export default AddPeopleForm;
