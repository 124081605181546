import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, filter, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { tokenExists, getBaseUrl, authHeaders } from "frame";
import fromTypes from "../actions/types";
import ReactGA from "react-ga";
import { isProd } from "utils";
import * as fromActions from "../actions";

export const invitationSaveEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.INVITATION_SAVE),
		filter(() => tokenExists()),
		mergeMap(({ payload }) => {
			return ajax
				.get(
					`${getBaseUrl()}/api/invite?email=${encodeURIComponent(payload)}&consultantId=${
						state$.value.profile.staffId
					}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						isProd &&
							ReactGA.event({
								category: "My Account",
								action: "Save Clicked",
								label: "Notifications",
							});
						return of(
							fromActions.invitationSaveSuccess(
								ajaxResponse.response
							)
						);
					}),
					catchError((error) =>
						of(fromActions.invitationSaveFailed(error.message))
					)
				);
		})
	);
