import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clip from "text-clipper";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Grid, Chip } from "@material-ui/core";
// import { capitalize } from 'utils'
import { CrudOptions } from "frame/components";
// import { AttachmentListing, TextClip } from 'frame/components'
import {
	onAddHazardousSubstanceSave,
	onArchiveHazardousSubstance,
} from "features/health-safety-forms/actions";
import {
	isBornAccount,
	formatDate,
	isStaffUser,
	createRowSelector,
} from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
		"&:hover": {
			cursor: "pointer",
		},
	},
	container: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(3),
		},
	},
	label: { color: "#999999" },
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
}));

const HazardousSubstanceCard = ({
	hazardousSubstance,
	role,
	onView,
	onEdit,
	onDelete,
	onArchive,
	onSave,
	email,
}) => {
	const classes = useStyles();
	const onRowSelect = createRowSelector(onView);
	const isActive =
		hazardousSubstance.status &&
		hazardousSubstance.status.toLowerCase() === "active";

	const id = {
		id: hazardousSubstance.id,
		form: "hazardous-substance",
	};

	const onToggleStatus = (status) => () => {
		onArchive && onArchive({ ...hazardousSubstance, status });
		onSave();
	};

	return (
		<Card className={classes.root} onClick={onRowSelect}>
			<CardContent className={classes.content}>
				<Grid className={classes.container} container spacing={2}>
					<Grid item md={3} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Date
						</Typography>
						<Typography variant="h5">
							{formatDate(hazardousSubstance.createdAt)}
						</Typography>
					</Grid>

					<Grid item md={3} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Name
						</Typography>
						<Typography variant="h5">
							{hazardousSubstance.name
								? clip(hazardousSubstance.name, 140)
								: "-"}
						</Typography>
					</Grid>

					{/* <Grid item md={3} xs={12}>
            <Typography variant="body2" className={classes.label}>Substance Type</Typography>
            <Typography variant="h5">
              <TextClip
                text={hazardousSubstance.substanceType}
                numberOfCharacters={140} />
            </Typography>
          </Grid>
          <Grid item md={3} xs={6}>
            <Typography variant="body2" className={classes.label}>Volume Measurement</Typography>
            <Typography variant="h5">
              {hazardousSubstance.volumeMeasurement || '-'}
            </Typography>
          </Grid>
          <Grid item md={3} xs={6}>
            <Typography variant="body2" className={classes.label}>Supplier SDS Report Held</Typography>
            <Typography variant="h5">
              // {hazardousSubstance.supplierSDSReportHeld ? capitalize(hazardousSubstance.supplierSDSReportHeld) : '-'}
            </Typography>
          // </Grid>

          <Grid item md={3} xs={6}>
            <Typography variant="body2" className={classes.label}>Protective Clothing Required</Typography>
            <Typography variant="h5">
              {hazardousSubstance.protectiveClothingRequired ? capitalize(hazardousSubstance.protectiveClothingRequired) : '-'}
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography variant="body2" className={classes.label}>Action Recommended</Typography>
            <Typography variant="h5">
              <TextClip
                text={hazardousSubstance.actionRecommended}
                numberOfCharacters={140} />
            </Typography>
          </Grid> */}

					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Status
						</Typography>
						<Typography variant="h5">
							{hazardousSubstance.status ? (
								<Chip
									className={
										hazardousSubstance.status === "Active"
											? classes.success
											: classes.error
									}
									size="small"
									label={hazardousSubstance.status}
								/>
							) : (
								"-"
							)}
						</Typography>
					</Grid>

					{/* <Grid item sm={6} xs={12}>
            <Typography variant="body2" className={classes.label}>Files</Typography>
            <AttachmentListing attachments={hazardousSubstance.attachments} />
          </Grid> */}
					<div className="ignore">
						{!isStaffUser(role) ? (
							<CrudOptions
								id={id}
								onEdit={onEdit}
								onView={onView}
								onDelete={
									isBornAccount(email) ? onDelete : null
								} // enabled in born superadmin only for easy maintenance
								onArchive={
									isActive ? onToggleStatus("Archived") : null
								}
								onMakeActive={
									!isActive ? onToggleStatus("Active") : null
								}
							/>
						) : (
							<CrudOptions id={id} onView={onView} />
						)}
					</div>
				</Grid>
			</CardContent>
		</Card>
	);
};

HazardousSubstanceCard.propTypes = {
	hazardousSubstance: PropTypes.object.isRequired,
	onView: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
	onArchive: PropTypes.func,
	onSave: PropTypes.func,
	email: PropTypes.string,
};

const mapStateToProps = ({ profile }) => ({
	email: profile.email,
});

const mapDispatchToProps = {
	onSave: onAddHazardousSubstanceSave,
	onArchive: onArchiveHazardousSubstance,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HazardousSubstanceCard);
