import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	TextField,
	Grid,
	Typography,
} from "@material-ui/core";
import {
	Dialog,
	FormButton,
	TextLink,
	ValueText,
	SelectInput,
} from "frame/components";
import * as fromFormRoutes from "features/health-safety-forms";
import { capitalize } from "utils";
import { isBornAccount, useCurrentUserEmail } from "utils";
import { useSelector } from "react-redux";
import { transduce } from "ramda";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	empty: {
		padding: theme.spacing(2),
		textAlign: "center",
	},
	button: {
		margin: theme.spacing(2, 0, 0, 0),
	},
	headerTitle: {
		padding: theme.spacing(1.6, 1),
		minWidth: 140,
	},
	headerStatus: {
		padding: theme.spacing(1.6, 1),
	},
	headerOptions: {
		padding: `0 !important`,
		width: 30,
	},
	review: {
		marginBottom: theme.spacing(5),
	},
	fieldLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(-1.2),
	},
	label: {
		margin: 0,
	},
	link: {
		color: theme.palette.text.link,
	},
}));

/*
  Add Hazard to Site details Form
*/
const HazardsForm = ({
	className,
	values,
	onChange,
	disableHazards,
	preExistingSiteHazards,
}) => {
	const classes = useStyles();
	const userEmail = useCurrentUserEmail();

	const [data, setData] = useState(false);
	const hazardList = useSelector(
		({ healthSafety }) => healthSafety.hazards?.results
	);

	const disabled = disableHazards; //values.locked

	const hazards =
		values.hazards && values.hazards.length > 0
			? values.hazards.map((node) => {
					return {
						hazardId: node.hazardId || node,
						location: node.location || "",
						status: node.status || "Active",
					};
			  })
			: [];

	const hazardOptions =
		hazardList &&
		hazardList
			.filter(({ id }) => {
				const hasHazardId =
					hazards &&
					hazards.filter((node) => node.hazardId === id).length === 0;
				return hasHazardId;
			})
			.filter((node) => node.status !== "Archived")
			.map(({ id, name }) => ({
				label: name,
				value: id,
			}))
			.sort((a, b) => (a.label > b.label ? 1 : -1));

	const hasHazardList = !!hazardList.length;
	const noHazardsToSelect = !hazardOptions.length;

	if (hazardOptions.length > 0) {
		hazards.push("");
	}

	const onModifyList = (list) => {
		//remove duplicates from list
		const uniq = new Set(list.map((e) => JSON.stringify(e)));
		const next = Array.from(uniq)
			.map((e) => JSON.parse(e))
			.filter((x) => x); // to remove null entries

		onChange({
			...values,
			hazards: next.length > 0 ? next : [],
		});
	};

	const onSelectOption = (index) => (value) => {
		const hazard =
			hazardList && hazardList.filter(({ id }) => id === value)[0];

		if (hazard) {
			setData({
				...hazard,
				location: hazards[index].location || "",
				status: hazards[index].status || "Active",
			});
			return;
		}

		//when none is selected
		const list = [...hazards];
		list[index] = null;

		onModifyList(list);
	};

	const onCloseHazard = () => {
		setData(null);
		// setAgreed(false)
	};

	const onSubmitSelectedHazard = () => {
		const list = [...hazards];
		// check if hazard is already selected
		const existingHazard = hazards.filter(
			(node) => node.hazardId === data.id
		);
		if (existingHazard.length > 0) {
			const index = list.indexOf(existingHazard[0]);
			list[index].location = data.location || "";
		} else {
			list.push({
				hazardId: data.id,
				location: data.location || "",
				status: data.status || "Active",
			});
		}
		onModifyList(list);
		onCloseHazard();
	};

	// const onToggleAgreed = () =>
	//   setAgreed( ! agreed)

	const hideAddHazardsLink = !window.location.pathname.includes("induction");

	return (
		<>
			<CardContent>
				<Grid container spacing={2}>
					{/* <pre>
            {JSON.stringify({
              hazards: values.hazards
            }, null ,2)}
          </pre> */}
					{hazards.map(({ hazardId, status }, index) => {
						if (index === hazards.length && !hazardOptions.length) {
							return null;
						}

						// hides preExisting Entries - uncomment to show
						if (
							preExistingSiteHazards.includes(hazardId) &&
							!isBornAccount(userEmail)
						)
							return null;

						const matched = hazardList.filter(
							({ id }) => id === hazardId
						)[0];

						const label =
							matched && matched.name ? matched.name : hazardId;

						const option = {
							label,
							value: hazardId,
						};

						const options = option.label
							? [option, ...hazardOptions]
							: hazardOptions;

						return (
							<Grid item xs={12} key={`hazard_${index}`}>
								<Grid container>
									{index > 0 &&
										index !==
											preExistingSiteHazards.length && (
											<Grid item xs={12}>
												<Typography variant="body1">
													Add another:
												</Typography>
											</Grid>
										)}
									<Grid item xs={12}>
										<SelectInput
											id={`hazard_${index}`}
											data-cy="hazard_id"
											disabled={
												disabled ||
												status === "Archived"
											}
											label="Select Hazard"
											placeholder="Select hazard"
											options={options}
											value={hazardId}
											onChange={onSelectOption(index)}
										/>
									</Grid>
								</Grid>
							</Grid>
						);
					})}

					{noHazardsToSelect && !hasHazardList && hideAddHazardsLink && (
						<Grid item xs={12}>
							<Typography variant="body2">
								There are no hazards to select from.&nbsp;
								<TextLink
									className={classes.link}
									to={fromFormRoutes.ADD_HAZARD_PATH}
								>
									Please add hazards here.
								</TextLink>
							</Typography>
						</Grid>
					)}

					{noHazardsToSelect && hasHazardList && hideAddHazardsLink && (
						<Grid item xs={12}>
							<Typography variant="body2">
								There are no remaining hazards to select from.{" "}
								<RouterLink
									className={classes.link}
									to={fromFormRoutes.ADD_HAZARD_ROUTE}
								>
									Click Here
								</RouterLink>{" "}
								to add more hazards.
							</Typography>
						</Grid>
					)}

					{(!noHazardsToSelect ||
						hazards.length <= hazardList.length) &&
						preExistingSiteHazards.length !== hazardList.length && (
							<Grid item xs={12}>
								<Typography variant="body1">
									{`Once you have added all the hazards at ${
										values.siteName
											? values.siteName
											: "site"
									} click Save.`}
								</Typography>
							</Grid>
						)}
				</Grid>
			</CardContent>
			{data && (
				<Dialog
					open
					aria-labelledby="Hazard Record"
					breadcrumbs={`Add Hazard to ${
						values.siteName ? values.siteName : "Site"
					}`}
					title="Hazard Record"
					onClose={onCloseHazard}
				>
					<Card className={classes.review}>
						<CardHeader
							title={data.name || data.description}
							titleTypographyProps={{ variant: "h4" }}
						/>
						<CardContent>
							<Grid container spacing={1}>
								{/* <Grid item xs={12}>
                  <ValueText
                    label="Description"
                    value={data.description}
                    options={{ large: true }}
                  />
                </Grid> */}
								<Grid item xs={12}>
									<ValueText
										label="Initial risk assessment"
										value={data.initialRiskAssessment}
										options={{ large: true }}
									/>
								</Grid>
								<Grid item xs={12}>
									<ValueText
										label="Risidual risk assessment"
										value={
											data.residualRiskAssessment
												? capitalize(
														data.residualRiskAssessment
												  )
												: "-"
										}
										options={{ large: true }}
									/>
								</Grid>
								<Grid item xs={12}>
									<ValueText
										label="Significant risk"
										value={
											data.significantRisk
												? capitalize(
														data.significantRisk
												  )
												: "-"
										}
										options={{ large: true }}
									/>
								</Grid>
								<Grid item xs={12}>
									<ValueText
										label="Training required"
										value={
											data.trainingRequired
												? capitalize(
														data.trainingRequired
												  )
												: "-"
										}
										options={{ large: true }}
									/>
								</Grid>
								<Grid item xs={12}>
									<ValueText
										label="PPE Requirements"
										value={data.ppeRequirements}
										options={{
											large: true,
											contentState: transduce,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<ValueText
										label="Hazard controls"
										value={data.hazardControls}
										options={{
											large: true,
											contentState: transduce,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<br />
									<TextField
										id="hazard_location"
										disabled={disabled}
										fullWidth
										label="Location of hazard on this site"
										multiline
										rows="3"
										value={data.location || ""}
										variant="outlined"
										onChange={(e) =>
											setData({
												...data,
												location: e.target.value,
											})
										}
									/>
								</Grid>
								{/* <Grid item xs={12}>
                  <div className={classes.fieldLabel}>
                    <Checkbox
                      checked={agreed}
                      disabled={disabled}
                      onChange={onToggleAgreed}
                      value="yes"
                      inputProps={{
                        'aria-label': `I've read and understood the hazard checkbox`,
                      }} />
                    <Typography
                      className={classes.label}
                      align="left"
                      gutterBottom
                      variant="body1">
                      I&rsquo;ve read and understood the hazard.
                    </Typography>
                  </div>
                </Grid> */}
							</Grid>
						</CardContent>

						<CardActions>
							<FormButton
								color="brand"
								// disabled={ ! agreed}
								onClick={onSubmitSelectedHazard}
							>
								Ok
							</FormButton>
							<FormButton onClick={onCloseHazard}>
								Cancel
							</FormButton>
						</CardActions>
					</Card>
				</Dialog>
			)}
		</>
	);
};

HazardsForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	disableHazards: PropTypes.bool,
	preExistingSiteHazards: PropTypes.array.isRequired,
};

export default HazardsForm;
