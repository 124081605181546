import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, TextField } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => ({
	selectRow: {
		borderBottom: "1px solid #eeeeee",
		width: "100%",
	},
}));

const StaffMultiSelectDropdown = ({
	id,
	options = [],
	disabled,
	label,
	value,
	onChange,
	setSelectedStaff,
}) => {
	const classes = useStyles();

	const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	const checkedIcon = <CheckBoxIcon fontSize="small" />;

	const onSelect = useCallback(
		(_, input) => {
			if (input.length > 0) {
				const selected = input[input.length - 1]; // selected value (always the last item in input array)

				let staffList = input;
				let valueExists = input.filter(
					(i) => i.value === selected.value
				);

				if (valueExists && valueExists.length > 1) {
					// exists in list, remove staff
					staffList = staffList.filter(
						(s) => s.value !== selected.value
					);
				}

				let updatedPeople = staffList.map((c) => ({
					name: c.label,
					id: c.value,
					role: c.role,
					checked: true,
				}));

				setSelectedStaff(staffList);
				onChange(updatedPeople);
			} else {
				// none selected
				setSelectedStaff([]);
				onChange([]);
			} // eslint-disable-next-line
		},
		[onChange, setSelectedStaff]
	);

	return (
		<React.Fragment>
			{options && (
				<Autocomplete
					multiple
					id={id || "multiselect_dropdown"}
					options={options}
					disableCloseOnSelect
					getOptionLabel={(option) => option.label}
					disabled={disabled}
					onChange={onSelect}
					value={value || []}
					renderOption={(option, { selected }) => (
						<div className={classes.selectRow}>
							<Checkbox
								icon={icon}
								disabled={disabled}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={JSON.stringify(value).includes(
									option.value
								)}
							/>
							{option.label}
						</div>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label={label}
						/>
					)}
				/>
			)}
		</React.Fragment>
	);
};

StaffMultiSelectDropdown.propTypes = {
	id: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.array, // selectedStaff
	onChange: PropTypes.func,
	setSelectedStaff: PropTypes.func,
	disabled: PropTypes.bool,
	label: PropTypes.string,
};

export default StaffMultiSelectDropdown;
