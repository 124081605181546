import Types from "../actions/types";
import {
	/*onInductionMinutesConfirmAttendanceSucceeded,*/ onInductionMinutesConfirmAttendanceFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { authHeaders, tokenExists, getOrganisationBaseUrl } from "frame";
import showDialogRequested from "features/notifications/actions/show-dialog-requested";
import { push } from "connected-react-router";
import { onAddInductionMinutesReset } from "features/health-safety-forms";

const inductionMinutesConfirmAttendanceEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.INDUCTION_MINUTES_CONFIRM_CLICKED),
		filter(() => {
			return tokenExists();
		}),
		filter(() => !!state$.value.organisations.currentOrganisation),
		mergeMap((action) => {
			let path = `${getOrganisationBaseUrl(
				state$.value
			)}/induction-minutes/confirm/${action.payload}`;
			let headers = authHeaders();
			return ajax.post(path, {}, headers).pipe(
				mergeMap((ajaxResponse) => {
					return of(
						onAddInductionMinutesReset(),
						showDialogRequested(
							"Confirmed",
							"Your attendance at the induction has been confirmed.",
							"Ok"
						),
						push("/health-safety/induction-minutes")
					);
				}),
				catchError((error) => {
					return of(onInductionMinutesConfirmAttendanceFailed(error));
				})
			);
		})
	);

export default inductionMinutesConfirmAttendanceEffect;
