import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";

import CheckGroup from "./communication-participation-check-group";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(1, 0, 3, 0),
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0),
		height: 2,
	},
}));

const CommunicationParticipationForm = ({
	className,
	disabled,
	values,
	onSelectChange,
}) => {
	const classes = useStyles();

	return (
		<CardContent className={classes.root}>
			<Divider className={classes.divider} />
			<CardHeader
				className={classes.heading}
				title="Communication/Employee Participation"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						The methods we use to communicate safety information to
						our employees are:
					</Typography>
					<CheckGroup
						id="SafetyMinutes"
						disabled={disabled}
						label="Safety minutes" // TODO: update ToolboxTalks state to safety minutes
						values={values}
						onSelectChange={onSelectChange}
					/>
					<CheckGroup
						id="PreTaskPlanningMeeting"
						disabled={disabled}
						label="Pre-task planning meeting"
						values={values}
						onSelectChange={onSelectChange}
					/>
					<CheckGroup
						id="InductionMeeting"
						disabled={disabled}
						label="Induction meetings"
						values={values}
						onSelectChange={onSelectChange}
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

CommunicationParticipationForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onSelectChange: PropTypes.func.isRequired,
};

export default CommunicationParticipationForm;
