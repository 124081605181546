const NOTIFICATIONS_ERROR_CHANGED = "NOTIFICATIONS_ERROR_CHANGED";
const NOTIFICATIONS_BAR_CLOSED = "NOTIFICATIONS_BAR_CLOSED";
const NOTIFICATIONS_CLOSE_CLICKED = "NOTIFICATIONS_CLOSE_CLICKED";

const NOTIFICATIONS_DIALOG_CLOSE_CLICKED = "NOTIFICATIONS_DIALOG_CLOSE_CLICKED";
const NOTIFICATIONS_SHOW_DIALOG_REQUESTED =
	"NOTIFICATIONS_SHOW_DIALOG_REQUESTED";

export default {
	NOTIFICATIONS_ERROR_CHANGED,
	NOTIFICATIONS_BAR_CLOSED,
	NOTIFICATIONS_CLOSE_CLICKED,
	NOTIFICATIONS_DIALOG_CLOSE_CLICKED,
	NOTIFICATIONS_SHOW_DIALOG_REQUESTED,
};
