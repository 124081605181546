import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Grid, Link, Chip } from "@material-ui/core";
import { formatDateTime, isStaffUser } from "utils";
import { CrudOptions, Lookup } from "frame/components";
import { getGoogleMapsLinkByLocationQuery } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2)
	},
	container: {
		margin: theme.spacing(1, 0.5)
	},
	content: {
		"padding": theme.spacing(2),
		"flexGrow": 1,
		"display": "flex",
		"alignItems": "center",
		"justifyContent": "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap"
		},
		"&:last-child": {
			paddingBottom: theme.spacing(3)
		}
	},
	label: {
		color: theme.palette.text.tertiary
	},
	closed: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.white
	},
	active: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white
	}
}));

export const TimesheetCard = ({ timesheet, onDelete, onEdit, role }) => {
	const classes = useStyles();

	const startLocationGoogleMapLink =
		timesheet.startLatitude && timesheet.startLongitude
			? getGoogleMapsLinkByLocationQuery(timesheet.startLatitude, timesheet.startLongitude)
			: null;

	const endLocationGoogleMapLink =
		timesheet.endLatitude && timesheet.endLongitude
			? getGoogleMapsLinkByLocationQuery(timesheet.endLatitude, timesheet.endLongitude)
			: null;

	return (
		<Card className={classes.root}>
			<CardContent className={classes.content}>
				<Grid container spacing={2} className={classes.container}>
					<Grid item md={2} xs={6}>
						<Typography className={classes.label} variant="body2">
							Site Name
						</Typography>
						<Typography variant="h5">
							<Lookup id={timesheet.siteId} resource="sites" attribute="siteName" />
						</Typography>
					</Grid>

					<Grid item md={2} xs={6}>
						<Typography className={classes.label} variant="body2">
							Status
						</Typography>
						<Typography variant="h5">
							<Chip
								className={timesheet.isActive ? classes.active : classes.closed}
								size="small"
								label={timesheet.isActive ? "Active" : "Closed"}
							/>
						</Typography>
					</Grid>

					<Grid item md={2} xs={6}>
						<Typography className={classes.label} variant="body2">
							Staff Name
						</Typography>
						<Typography variant="h5">
							<Lookup id={timesheet.staffId} resource="staff" attribute="fullName" />
						</Typography>
					</Grid>

					<Grid item md={2} xs={6}>
						<Typography className={classes.label} variant="body2">
							Staff Phone Number
						</Typography>
						<Typography variant="h5">
							<Lookup id={timesheet.staffId} resource="staff" attribute="phoneNumber" />
						</Typography>
					</Grid>

					<Grid item md={2} xs={6}>
						<Typography className={classes.label} variant="body2">
							Started At
						</Typography>
						<Typography className={classes.times} variant="h5">
							{formatDateTime(timesheet.startDateTimeUTC)}
						</Typography>
					</Grid>

					<Grid item md={2} xs={6}>
						<Typography className={classes.label} variant="body2">
							Ended At
						</Typography>
						<Typography className={classes.times} variant="h5">
							{timesheet.endDateTimeUTC ? formatDateTime(timesheet.endDateTimeUTC) : "-"}
						</Typography>
					</Grid>

					<Grid item md={2} xs={6}>
						<Typography className={classes.label} variant="body2">
							Start Location
						</Typography>
						<Typography variant="h5">
							{startLocationGoogleMapLink ? (
								<Link
									href={startLocationGoogleMapLink}
									color="secondary"
									variant="inherit"
									target="_blank"
									rel="noreferrer"
								>
									View on Maps
								</Link>
							) : (
								"-"
							)}
						</Typography>
					</Grid>

					<Grid item md={2} xs={6}>
						<Typography className={classes.label} variant="body2">
							End Location
						</Typography>
						<Typography variant="h5">
							{endLocationGoogleMapLink ? (
								<Link
									href={endLocationGoogleMapLink}
									color="secondary"
									variant="inherit"
									target="_blank"
									rel="noreferrer"
								>
									View on Maps
								</Link>
							) : (
								"-"
							)}
						</Typography>
					</Grid>

					<Grid item md={2} xs={6}>
						<Typography className={classes.label} variant="body2">
							Lunch Time
						</Typography>
						<Typography variant="h5">
							{timesheet.lunchTimeMinutes ? timesheet.lunchTimeMinutes + " Minutes" : "None"}
						</Typography>
					</Grid>
					<Grid item md={2} xs={12}>
						<Typography className={classes.label} variant="body2">
							Staff Type
						</Typography>
						<Typography variant="h5">
							{timesheet.organisationStaffType ? timesheet.organisationStaffType : "-"}
						</Typography>
					</Grid>
					<Grid item md={2} xs={12}>
						<Typography className={classes.label} variant="body2">
							Task Type
						</Typography>
						<Typography variant="h5">
							<Lookup id={timesheet.organisationTaskTypeId} resource="taskTypes" attribute="taskType" />
						</Typography>
					</Grid>
					<Grid item md={1} xs={12}>
						<Typography className={classes.label} variant="body2">
							Notes
						</Typography>
						<Typography variant="h5">{timesheet.notes ? timesheet.notes : "-"}</Typography>
					</Grid>
				</Grid>

				<div>
					{!isStaffUser(role) && !timesheet.isActive && (
						<CrudOptions onEdit={onEdit} onDelete={() => onDelete(timesheet.id)} />
					)}
				</div>
			</CardContent>
		</Card>
	);
};
