import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Button,
	CardContent,
	Divider,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import { hasValue } from "utils";

import Options from "./options";
import StepForm from "./step-form";

const useStyles = makeStyles((theme) => ({
	header: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	actions: {
		paddingBottom: theme.spacing(2),
	},
	button: {
		margin: theme.spacing(0),
	},
	divider: {
		display: "flex",
		margin: theme.spacing(3, 1, 1, 1),
		width: "100%",
		color: theme.palette.divider,
	},
}));

const TaskForm = ({
	disabled,
	task,
	onAddStep,
	onChangeStep,
	onChangeTask,
	onDeleteStep,
	onDeleteTask,
}) => {
	const classes = useStyles();
	const disabledFields = disabled || !task.name;

	const current =
		task.steps &&
		task.steps
			.map(({ index, ...step }) => step)
			.filter((step) => Object.keys(step).length);

	const addStepDisabled = task.steps && task.steps.length !== current.length;

	const mandatoryTaskName = hasValue(task.description);

	return (
		<>
			<CardContent>
				<Grid key={`task_${task.index}`} container spacing={2}>
					{task.index > 0 && <Divider className={classes.divider} />}

					<Grid className={classes.header} item xs={12}>
						<Typography variant="h5">
							Task {task.index + 1}
						</Typography>
						<Options onDelete={onDeleteTask} />
					</Grid>

					<Grid item xs={12}>
						<TextValidator
							id={`task_name_${task.index}`}
							disabled={disabled}
							fullWidth
							label="Task Name"
							value={task.name || ""}
							variant="outlined"
							onChange={onChangeTask("name")}
							validators={mandatoryTaskName ? ["required"] : null}
							errorMessages={
								mandatoryTaskName
									? ["A Task must have a task name"]
									: null
							}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							id={`task_description_${task.index}`}
							disabled={disabledFields}
							fullWidth
							label="Task being explained"
							multiline
							rows={3}
							placeholder="Explain task"
							value={task.description || ""}
							variant="outlined"
							onChange={onChangeTask("description")}
						/>
					</Grid>
				</Grid>
			</CardContent>

			{task.steps &&
				task.steps.map((step) => (
					<StepForm
						key={`task_${task.index}_step_${step.index}`}
						disabled={disabledFields}
						step={step}
						taskIndex={task.index}
						onChangeStep={onChangeStep}
						onDeleteStep={onDeleteStep}
					/>
				))}

			<CardContent className={classes.actions}>
				<Button
					color="primary"
					className={classes.button}
					disabled={addStepDisabled || disabled}
					size="small"
					variant="outlined"
					onClick={onAddStep}
				>
					+ Add Step
				</Button>
			</CardContent>
		</>
	);
};

TaskForm.propTypes = {
	disabled: PropTypes.bool,
	task: PropTypes.object.isRequired,
	onAddStep: PropTypes.func.isRequired,
	onChangeStep: PropTypes.func.isRequired,
	onChangeTask: PropTypes.func.isRequired,
	onDeleteStep: PropTypes.func.isRequired,
	onDeleteTask: PropTypes.func.isRequired,
};

export default TaskForm;
