import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { isStaffUser } from "utils";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
	menu: {
		display: "flex",
		paddingTop: theme.spacing(1),
		marginBottom: theme.spacing(-3),
		"& > *": {
			marginRight: theme.spacing(3),
		},
	},
	addIcon: {
		marginRight: theme.spacing(1),
	},
	button: {
		padding: theme.spacing(1, 4),
		color: theme.palette.brand,
		background: theme.palette.highlight,
		borderColor: theme.palette.highlight,
		fontWeight: 600,
		"&:hover": {
			background: "#c1c72c",
			borderColor: "#c1c72c",
		},
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(1, 2),
		},
	},
}));

const AddButton = ({ role, addTabTitle, onAdd, staffUserHide, noIcon }) => {
	const classes = useStyles();

	const show = staffUserHide ? !isStaffUser(role) : true;

	return (
		<>
			{show && (
				<div className={classes.menu}>
					<Button
						className={classes.button}
						variant="contained"
						onClick={() => onAdd()}
					>
						{!noIcon && <AddIcon className={classes.addIcon} />}
						{addTabTitle}
					</Button>
				</div>
			)}
		</>
	);
};

AddButton.propTypes = {
	role: PropTypes.string.isRequired,
	addTabTitle: PropTypes.string.isRequired,
	onAdd: PropTypes.func.isRequired,
	staffUserHide: PropTypes.bool,
	noIcon: PropTypes.bool,
};

const mapStateToProps = ({ profile }) => ({
	role: profile.role,
});

export default connect(mapStateToProps)(AddButton);
