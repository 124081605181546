import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	PageHeaderAddButton,
	SearchBar,
	Pagination,
	PaginationHeader,
	ListFilterOptions,
} from "frame/components";

import { getPageResults } from "utils";
import SiteForm from "./site-form-container";
import SiteListing from "./site-listing";
import { INFO_HOME_ROUTE } from "features/home/home-routes";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0),
		},
	},
	filter: { display: "flex" },
}));

const Sites = ({
	role,
	onAdd,
	onEdit,
	onDelete,
	onSelectSite,
	onGetSitesList,
	pagination,
}) => {
	const classes = useStyles();
	const siteList = getPageResults(pagination);
	const [searchFilter, setSearchFilter] = useState("");

	return (
		<Page className={classes.root} title="Sites">
			<ScrollToTopOnMount />
			<PageHeaderAddButton
				// breadcrumbs="Sites"
				title="Sites"
				addTabTitle="Add Site"
				onAdd={onAdd}
				staffUserHide={false}
				backActionPath={INFO_HOME_ROUTE}
			/>
			<div className={classes.filter}>
				<SearchBar onSearch={setSearchFilter} />

				<ListFilterOptions
					filterKey="Status"
					onGetList={onGetSitesList}
					pagination={pagination}
					orderBy="status,siteName"
				/>
			</div>

			<PageContent pad>
				<div className={classes.body}>
					<PaginationHeader hidePagination pagination={pagination} />

					{siteList.map((node, index) => (
						<SiteListing
							key={index}
							site={node}
							role={role}
							onEdit={onEdit}
							onDelete={onDelete}
							onSelectSite={onSelectSite}
						/>
					))}
				</div>

				<Pagination
					hidePagination
					onGetList={onGetSitesList}
					pagination={pagination}
					orderBy="status,siteName"
					searchKey={searchFilter}
				/>
			</PageContent>

			<SiteForm />
		</Page>
	);
};

Sites.propTypes = {
	role: PropTypes.string.isRequired,
	pagination: PropTypes.object.isRequired,
	onAdd: PropTypes.func,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onSelectSite: PropTypes.func.isRequired,
	onGetSitesList: PropTypes.func.isRequired,
};

export default Sites;
