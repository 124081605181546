import { connect } from "react-redux";
import { onHamburgerClicked } from "../../actions";
import { onLogout } from "../../actions";
import TopBar from "./top-bar";
import { profileFetch } from "../../../features/account/actions";
import { onAddTimesheetReset } from "features/timesheet/timesheet-actions";

const mapStateToProps = ({ profile, queue, timesheetFormReducer }) => ({
	profile,
	hasNetwork: queue.hasNetwork,
	timesheetToggle: timesheetFormReducer.timesheet.toggle,
	requestQueue: queue.list,
});

const mapDispatchToProps = {
	onOpenNav: onHamburgerClicked,
	onSignout: onLogout,
	profileFetch,
	onAddTimesheetReset
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
