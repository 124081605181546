import React from "react";
import { Route, Switch } from "react-router-dom";
import { TasksHome } from "./tasks-home";
import { types as fromHealthAndSafety } from "features/health-and-safety";
import * as fromHealthSafetyForm from "features/health-safety-forms";

// form components
import {
	AddIncidentForm,
	AddSafetyMinutesForm,
	AddInductionMinutesForm,
	AddStaffTrainingForm,
} from "features/health-safety-forms";

// list components
import {
	IncidentList,
	StaffTrainingList,
	SafetyMinutesList,
	InductionMinutesList,
	InductionMinutesConfirmAttendance,
} from "features/health-and-safety/components";
import { connect } from "react-redux";

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export const TaskRoutes = connect(mapStateToProps, mapDispatchToProps)(() => {

	return (
		<Switch>
			<Route
				exact
				path={fromHealthAndSafety.TASK_ROUTE}
				component={TasksHome}
			/>
			<Route
				exact
				path={fromHealthSafetyForm.ADD_SAFETY_MINUTES_ROUTE}
				component={AddSafetyMinutesForm}
			/>
			<Route
				exact
				path={fromHealthAndSafety.TASK_SAFETY_MINUTES_ROUTE}
				component={SafetyMinutesList}
			/>
			<Route
				exact
				path={fromHealthSafetyForm.ADD_INCIDENT_ROUTE}
				component={AddIncidentForm}
			/>
			<Route
				exact
				path={fromHealthAndSafety.TASK_INCIDENTS_ROUTE}
				component={IncidentList}
			/>
			<Route
				exact
				path={fromHealthSafetyForm.ADD_INDUCTION_MINUTES_ROUTE}
				component={AddInductionMinutesForm}
			/>
			<Route
				exact
				path={fromHealthAndSafety.TASK_INDUCTION_MINUTES_ROUTE}
				component={InductionMinutesList}
			/>

			<Route
				exact
				path={fromHealthSafetyForm.ADD_STAFF_TRAINING_ROUTE}
				component={AddStaffTrainingForm}
			/>
			<Route
				exact
				path={fromHealthAndSafety.TASK_STAFF_TRAINING_ROUTE}
				component={StaffTrainingList}
			/>
			<Route
				path={
					fromHealthSafetyForm.INDUCTION_MINUTES_CONFIRM_ATTENDANCE_ROUTE
				}
				component={InductionMinutesConfirmAttendance}
			/>
		</Switch>
	);
});
