import * as R from "ramda";
import initialQueueState from "../models/queue-state";
import Types from "../actions/types";

const sendSucceeded = (state, action) => {
	state.list = R.filter(
		(item) => item.id !== action.payload.queueId,
		state.list
	);
	return state;
};

const sendFailed = (state, action) => {
	state.list.forEach((item) => {
		if (item.id === action.id) {
			item.failedCount += 1;
			item.processedBy = undefined;
		}
	});
	return state;
};

const sendRequested = (state, action) => {
	return state;
};

const itemAdded = (state, action) => {
	state.list.push(action.payload);
	return state;
};

const networkChanged = (state, action) => {
	state.hasNetwork = action.payload;
	return state;
};

const resetProcessing = (state, action) => {
	state.list.forEach((item) => {
		item.processedBy = undefined;
	});
	return state;
};

const statusReducer = (state = initialQueueState, action) => {
	switch (action.type) {
		case Types.QUEUE_NETWORK_CHANGED:
			return networkChanged(R.clone(state), action);
		case Types.QUEUE_ITEM_ADDED:
			return itemAdded(R.clone(state), action);
		case Types.QUEUE_SEND_FAILED:
			return sendFailed(R.clone(state), action);
		case Types.QUEUE_SEND_SUCCEEDED:
			return sendSucceeded(R.clone(state), action);
		case Types.QUEUE_SEND_REQUESTED:
			return sendRequested(R.clone(state), action);
		case Types.FRAME_INITIALISE_REQUESTED:
			return resetProcessing(R.clone(state), action);
		default:
			return state;
	}
};

export default statusReducer;
