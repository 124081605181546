import { connect } from "react-redux";
import DefaultLayout from "./default-layout";
import { onHamburgerClicked } from "../actions";

const mapStateToProps = ({ frame, profile }) => ({
	openMobile: frame.navigation.isMobileOpen,
	role: profile.role,
});

const mapDispatchToProps = {
	onToggleSideNavBar: onHamburgerClicked,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
