import { connect } from "react-redux";
import DialogCloseClicked from "../actions/dialog-close-clicked";
import DialogMessage from "./dialog-message";

const mapStateToProps = (state) => {
	return {
		title: state.notifications.dialog.title,
		open: state.notifications.dialog.open,
		message: state.notifications.dialog.message,
		closeLabel: state.notifications.dialog.closeLabel,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onClose: () => {
			dispatch(DialogCloseClicked());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogMessage);
