import { ofType } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { getBaseUrl, tokenExists, authHeaders } from "frame";
import fromTypes from "../actions/types";
import {
	subscriptionAddPaymentSaveFailed,
	subscriptionAddPaymentSaveSuccess,
	onAddPaymentClose,
	subscriptionFetch,
} from "../actions";

export const addPaymentEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.SUBSCRIPTION_ADD_PAYMENT_SAVE),
		filter(() => tokenExists()),
		mergeMap(({ payload }) => {
			const cardToken = payload.token;

			return ajax
				.post(
					`${getBaseUrl()}/api/add-payment-method`,
					{
						id: state$.value.profile.id,
						organisationName: state$.value.profile.organisation,
						organisationId:
							state$.value.organisations.currentOrganisation,
						firstName: state$.value.profile.firstName,
						lastName: state$.value.profile.lastName,
						phoneNumber: state$.value.profile.phone,
						email: state$.value.profile.email,
						creditCardToken: {
							id: cardToken.id,
							client_ip: cardToken.client_ip,
							created: cardToken.created,
							livemode: cardToken.livemode,
							type: cardToken.type,
							used: cardToken.used,
							card: {
								id: cardToken.card.id,
								address_city: cardToken.card.address_city,
								address_country: cardToken.card.address_country,
								address_line1: cardToken.card.address_line1,
								address_line1_check:
									cardToken.card.address_line1_check,
								address_line2: cardToken.card.address_line2,
								address_state: cardToken.card.address_state,
								address_zip: cardToken.card.address_zip,
								address_zip_check:
									cardToken.card.address_zip_check,
								brand: cardToken.card.brand,
								country: cardToken.card.country,
								cvc_check: cardToken.card.cvc_check,
								exp_month: cardToken.card.exp_month,
								exp_year: cardToken.card.exp_year,
								fingerprint: cardToken.card.fingerprint,
								funding: cardToken.card.funding,
								last4: cardToken.card.last4,
								name: cardToken.card.name,
								tokenization_method:
									cardToken.card.tokenization_method,
							},
						},
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							subscriptionAddPaymentSaveSuccess(
								ajaxResponse.response
							),
							onAddPaymentClose(),
							subscriptionFetch()
						)
					),
					catchError((error) =>
						of(subscriptionAddPaymentSaveFailed(error.response))
					)
				);
		})
	);
