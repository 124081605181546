import Types from "../actions/types";
import { onRemoveFailed, onRemoveSuccess, onListRequested } from "../actions";
import { ofType } from "redux-observable";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { authHeaders, tokenExists, getOrganisationBaseUrl } from "frame";

const jobRemoveEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.JOBS_REMOVE_CLICKED),
		filter(() => tokenExists()),
		filter(() => !!state$.value.organisations.currentOrganisation),
		mergeMap(() =>
			ajax
				.delete(
					`${getOrganisationBaseUrl(state$.value)}/job/delete/${
						state$.value.jobs.currentJob.id
					}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							onRemoveSuccess(ajaxResponse.response),
							onListRequested()
						)
					),
					catchError((error) => of(onRemoveFailed(error)))
				)
		)
	);

export default jobRemoveEffect;
