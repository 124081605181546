import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useLoadedState } from "utils";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { Grid, TextField, Typography } from "@material-ui/core";

import {
	Feedback,
	FormActions,
	FormSubmitButton,
	ScrollToTopOnMount,
} from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 3, 10, 3),
		backgroundColor: theme.palette.white,
	},
	body: {
		margin: theme.spacing(3, 0, 2, 0),
		maxWidth: 540,
		"& > *": {
			paddingLeft: "0 !important",
			paddingRight: "0 !important",
		},
	},
	textField: {
		[theme.breakpoints.down("xs")]: {
			paddingRight: theme.spacing(2),
		},
	},
}));

const Profile = ({ profile, save, onSave }) => {
	const classes = useStyles();
	const [values, setValues] = useLoadedState(profile, profile.id);

	const { done, error, saving } = save;
	const saveFailed = !!(done && error);
	const saveSuccess = !!(done && !error);

	const onSubmit = () => {
		const { password, ...changes } = values;
		onSave(changes);
	};

	const onChange = (key) => (event) => {
		setValues({
			...values,
			[key]: event.target.value,
		});
	};

	return (
		<div className={classes.root}>
			<ScrollToTopOnMount />
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				<Typography variant="h2">Summary</Typography>
				<Grid className={classes.body} container spacing={2}>
					<Grid item xs={12}>
						<Feedback active={saveFailed} color="error">
							Profile not updated
						</Feedback>
						<Feedback active={saveSuccess} color="success">
							Profile updated
						</Feedback>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={"account"}
							disabled
							fullWidth
							className={classes.textField}
							label="My Account"
							value={profile.role}
							variant="outlined"
							onChange={onChange("account")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							id={"firstName"}
							disabled={saving}
							fullWidth
							className={classes.textField}
							label="First Name"
							value={values.firstName || ""}
							variant="outlined"
							validators={["required"]}
							errorMessages={["This field is required"]}
							onChange={onChange("firstName")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id={"lastName"}
							disabled={saving}
							fullWidth
							className={classes.textField}
							label="Last Name"
							value={values.lastName || ""}
							variant="outlined"
							onChange={onChange("lastName")}
						/>
					</Grid>
					<Grid item md={8} xs={12}>
						<TextValidator
							id={"phone"}
							disabled={saving}
							fullWidth
							className={classes.textField}
							label="Phone"
							value={values.phone || ""}
							variant="outlined"
							validators={["isNumber"]}
							errorMessages={["Must be a valid phone number"]}
							onChange={onChange("phone")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							id={"email"}
							disabled={saving}
							fullWidth
							className={classes.textField}
							label="Email"
							value={values.email || ""}
							variant="outlined"
							validators={["required", "isEmail"]}
							errorMessages={[
								"This field is required",
								"Must be a valid email",
							]}
							onChange={onChange("email")}
						/>
					</Grid>
				</Grid>
				<FormActions>
					<FormSubmitButton loading={saving}>
						Save Changes
					</FormSubmitButton>
				</FormActions>
			</ValidatorForm>
		</div>
	);
};

Profile.propTypes = {
	profile: PropTypes.object,
	save: PropTypes.exact({
		done: PropTypes.bool,
		error: PropTypes.string,
		saving: PropTypes.bool,
		success: PropTypes.bool,
	}),
	onSave: PropTypes.func.isRequired,
};

export default Profile;
