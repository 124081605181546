import * as R from "ramda";
import fromAuth from "../actions/types";
import {
	defaultSaveState,
	saveRequested,
	saveSuccess,
	saveFailed,
} from "utils";

const defaultState = {
	...defaultSaveState,
	password: "",
	confirmPassword: "",
	isValid: false,
};

const resetPasswordReducer = (state = defaultState, action) => {
	switch (action.type) {
		// Reset Password
		case fromAuth.AUTHORISATION_RESET_PASSWORD_REQUESTED: {
			const nextState = resetPasswordRequested(R.clone(state), action);
			return saveRequested(nextState);
		}
		case fromAuth.AUTHORISATION_RESET_PASSWORD_PASSWORD_CHANGED:
			return resetPasswordChanged(R.clone(state), action);
		case fromAuth.AUTHORISATION_RESET_PASSWORD_CONFIRM_PASSWORD_CHANGED:
			return setConfirmPasswordChanged(R.clone(state), action);
		case fromAuth.AUTHORISATION_RESET_PASSWORD_FULFILLED: {
			const nextState = resetPasswordFulfilled(R.clone(state), action);
			return saveSuccess(nextState, action.payload);
		}
		case fromAuth.AUTHORISATION_RESET_PASSWORD_FAILED: {
			const nextState = resetPasswordFailed(R.clone(state), action);
			return saveFailed(nextState, action.payload);
		}
		default:
			return state;
	}
};

const resetPasswordRequested = (state, action) => {
	return state;
};

const resetPasswordChanged = (state, action) => {
	state.responseMessage = "";
	state.password = action.payload;

	return isValid(state, state.password, state.confirmPassword);
};

const setConfirmPasswordChanged = (state, action) => {
	state.errorMessage = "";
	state.confirmPassword = action.payload;

	return isValid(state, state.password, state.confirmPassword);
};

const isValid = (state, password, confirmPassword) => {
	// state.hasNumber = hasNumber(password) && hasNumber(confirmPassword)
	state.meetsPasswordLength =
		password.length > 6 && confirmPassword.length > 8;
	state.passwordMatch = password === confirmPassword;
	state.isValid = state.meetsPasswordLength && state.passwordMatch; //&& state.hasNumber

	return state;
};

const resetPasswordFulfilled = (state, action) => {
	state.responseMessage = action.payload;
	return state;
};
const resetPasswordFailed = (state, action) => {
	state.responseMessage = action.payload;
	return state;
};

export default resetPasswordReducer;
