import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Chip, Grid } from "@material-ui/core";
import {
	useIsOnline,
	isStaffUser,
	isConsultantUser,
	useCurrentOrganisation,
	useCurrentRole,
	useIsBornAccount,
	formatDate,
	useRouter,
	isProd,
	dayDifference
} from "utils";
import { CrudOptions } from "frame/components";
import moment from "moment";
import ReactGA from "react-ga";
import { HOME_ROUTE } from "features/home/home-routes";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		marginBottom: theme.spacing(2),
	},
	content: {
		position: "relative",
		padding: theme.spacing(2, 4),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	label: {
		padding: theme.spacing(0, 0, 0.5, 0),
		color: "#999999",
	},
	value: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		padding: theme.spacing(0, 0, 0.5, 0),
	},
	activeOrg: {
		backgroundColor: theme.palette.success.main,
		color: "#FFF",
		fontSize: "14px",
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	warningText: {
		color: theme.palette.error.main,
	},
	success: {
		color: theme.palette.success.main,
	},
	link: {
		color: theme.palette.text.link,
		cursor: "pointer",
	},
}));

const OrganisationListing = ({
	className,
	organisation,
	onEdit,
	onDelete,
	onChangeOrganisation,
}) => {
	const classes = useStyles();
	const { history } = useRouter();

	const isOnline = useIsOnline();
	const currentRole = useCurrentRole();
	const isBornAccount = useIsBornAccount();
	const currentOrganisationId = useCurrentOrganisation();

	const isNotDemoAccount =
		organisation.name !== "Born Digital Demo" &&
		organisation.name !== "SafetyWise Demo";
	const allowedToEdit =
		!isStaffUser(currentRole) &&
		!isStaffUser(organisation.organisationRole) &&
		!isConsultantUser(organisation.organisationRole);
	const isCurrentOrganisation = organisation.id === currentOrganisationId;

	const daysRemaining = moment(organisation.createdAt)
		.add(30, "days")
		.diff(moment(), "days");
	const daysOld = moment().diff(moment(organisation.createdAt), "days");

	const onChangeOrganisationHandler =
		(organisationId, organisationName) => () => {
			onChangeOrganisation(organisationId);
			isProd &&
				ReactGA.event({
					category: "Menu",
					action: "Organization Switched",
					label: organisationName,
				});
			history.push(HOME_ROUTE);
		};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardContent className={classes.content}>
				<Grid container spacing={2}>
					<Grid item md={2} sm={6} xs={12}>
						<Typography className={classes.label} variant="body2">
							Name
						</Typography>
						<Typography variant="h5" className={classes.value}>
							{organisation.name}{" "}
						</Typography>
					</Grid>

					<Grid item md={2} sm={6} xs={12}>
						<Typography className={classes.label} variant="body2">
							Status
						</Typography>
						<Typography className={classes.value} variant="h5">
							<span
								className={
									organisation.status
										? classes.success
										: classes.warningText
								}
							>
								{organisation.status ? "Active" : "Inactive"}
							</span>
						</Typography>
					</Grid>

					<Grid item md={1} sm={6} xs={12}>
						<Typography className={classes.label} variant="body2">
							Number of Users
						</Typography>
						<Typography className={classes.value} variant="h5">
							{organisation.numberOfUsers}
						</Typography>
					</Grid>

					{/* <Grid item md={2} sm={6} xs={12}>
            <Typography className={classes.label} variant="body2">
              Consultant
            </Typography>
            <Typography className={classes.value} variant="h5">
              {organisation.consultant ? organisation.consultant : '-'}
            </Typography>
          </Grid> */}

					<Grid item md={2} sm={6} xs={12}>
						<Typography className={classes.label} variant="body2">
							Date Activated
						</Typography>
						<Typography className={classes.value} variant="h5">
							{formatDate(organisation.createdAt)}
						</Typography>
					</Grid>

					<Grid item md={2} sm={6} xs={12}>
						<Typography className={classes.label} variant="body2">
							Subscription Status
						</Typography>
						<Typography className={classes.value} variant="h5">
							{organisation.subscriptionStatus
								? organisation.subscriptionStatus === "active"
									? "Active"
									: organisation.subscriptionStatus === "trialing"
										? "Free Trial"
										: "Canceled"
								: "-"}
							{organisation.subscriptionEndDate
								? ` (${dayDifference(undefined, organisation.subscriptionEndDate)} Days Remaining)`
								: ""}
							{/* <span className={classes.warningText}>
                {`${daysRemaining} Days Left `}
              </span> */}
							{/* {daysRemaining < 0 && (
                <Chip className={classes.error} size="small" label="Expired" />
              )} */}
						</Typography>
					</Grid>

					{isBornAccount && (
						<Grid item md={2} sm={6} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Creator Email Confirmed
							</Typography>
							<Typography className={classes.value} variant="h5">
								{organisation.createrEmailComfirmed
									? "Yes"
									: "No"}
								{!organisation.createrEmailComfirmed &&
									` (${daysOld} days old)`}
							</Typography>
						</Grid>
					)}

					<div
						className="ignore"
						style={{
							position: "absolute",
							right: 60,
							bottom: 25,
							display: "flex",
						}}
					>
						{isCurrentOrganisation ? (
							<Chip
								className={classes.activeOrg}
								size="small"
								label="Selected"
							/>
						) : (
							isOnline && (
								<Typography
									className={classes.link}
									variant="h5"
									onClick={onChangeOrganisationHandler(
										organisation.id,
										organisation.name
									)}
								>
									Go
								</Typography>
							)
						)}
					</div>

					<div className="ignore">
						<CrudOptions
							customRemoveModelText="Warning, deleting this organisation is permanent and cannot be undone."
							bornOnlyActions={["Delete"]}
							onEdit={() => onEdit(organisation.id)}
							onDelete={
								isBornAccount &&
								isNotDemoAccount &&
								allowedToEdit &&
								!isCurrentOrganisation
									? () => onDelete(organisation.id) // enabled in born superadmin only for easy maintenance
									: null
							}
						/>
					</div>
				</Grid>
			</CardContent>
		</Card>
	);
};

OrganisationListing.propTypes = {
	className: PropTypes.string,
	organisation: PropTypes.object.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onChangeOrganisation: PropTypes.func.isRequired,
};
export default OrganisationListing;
