import React from "react";
import PropTypes from "prop-types";
import {
	SectionCard,
	ValueText,
	AttachmentListing,
	Lookup,
} from "frame/components";
import { Typography, Grid, Divider } from "@material-ui/core";
import { formatDate, isNotNullOrEmpty } from "utils";

const ReadOnlyForm = ({ values }) => {
	const spc = values && values.safetyPlan;

	const renderAttachments = (attachments, reference) => {
		const files =
			attachments && attachments.filter((a) => a.reference === reference);
		return isNotNullOrEmpty(files) ? (
			<AttachmentListing attachments={files} />
		) : (
			"-"
		);
	};

	return (
		<>
			<SectionCard>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Site Name"
							value={
								<Lookup
									id={values.siteId}
									resource="sites"
									attribute="siteName"
								/>
							}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Date"
							value={formatDate(values.createdAt)}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Name"
							value={formatDate(values.name)}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="On Site Safety Representative"
							value={spc.onSiteSafetyRepresentative}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Person In Control Of Site"
							value={spc.personInControlOfSite}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Main Person In Charge"
							value={spc.mainPersonInCharge}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Phone"
							value={spc.phone}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Customer"
							value={spc.client}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<ValueText
							label="Notes"
							value={spc.notes}
							options={{ bold: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			{/* Notifiable Works */}
			<SectionCard title="Notifiable Works">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Have Notifiable Works"
							value={spc.haveNotifiableWorks}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Have Advised Worksafe NZ"
							value={spc.haveAdvisedWorksafeNZ}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Notification Attached"
							value={spc.notificationAttached}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={12} xs={12}>
						<Typography align="left" gutterBottom variant="h6">
							<strong>Notifiable Works Attachments:</strong>
						</Typography>
						<br />
						{renderAttachments(
							spc.notifiableWorksAttachments,
							"Notifiable Works Attachments"
						)}
					</Grid>
				</Grid>
			</SectionCard>

			{/* Hazard Management */}
			<SectionCard title="Hazard Management">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Will Maintain On Site Hazard Register"
							value={spc.willMaintainOnSiteHazardRegister}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Will Prepare Task Analysis"
							value={spc.willPrepareTaskAnalysis}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Assosicated With Other Works"
							value={spc.assosicatedWithOtherWorks}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			{/* Communication Employee Participation Frequency */}
			<SectionCard title="Communication Employee Participation Frequency">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Safety Minutes Frequency"
							value={spc.safetyMinutesFrequency}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Pre Task Planning Meeting Frequency"
							value={spc.preTaskPlanningMeetingFrequency}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Induction Meeting Frequency"
							value={spc.inductionMeetingFrequency}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			{/* Emergencies */}
			<SectionCard title="Emergencies">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="First Aid Kit Location"
							value={spc.firstAidKitLocation}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="First Aid Person"
							value={spc.firstAidPerson}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Emergency Phone"
							value={spc.emergencyPhone}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Have Trained Personnel Procedures On Site"
							value={spc.haveTrainedPersonnelProceduresOnSite}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Will Report To Safety Representative"
							value={spc.willReportToSafetyRepresentative}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Have Attached Emergency Plan"
							value={spc.haveAttachedEmergencyPlan}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography align="left" gutterBottom variant="h6">
							<strong>Emergency Plan Attachments:</strong>
						</Typography>
						<br />
						{renderAttachments(
							spc.emergencyPlanAttachments,
							"Emergency Plan Attachments"
						)}
					</Grid>
				</Grid>
			</SectionCard>

			{/* Incident */}
			<SectionCard title="Incident">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Have Incident System In Place"
							value={spc.haveAccidentSystemInPlace}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Will Immediately Notify All Incidents"
							value={spc.willImmediatelyNotifyAllAccidents}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			{/* Safety Inspections and Safety Reviews */}
			<SectionCard title="Safety Inspections and Safety Reviews">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Under Inspections"
							value={spc.undertakeInspection}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Other Inspections"
							value={spc.otherInspections}
							options={{ bold: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			{/* Training/Induction */}
			<SectionCard title="Training/Induction">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="All HoldHealth Safety Qualifications"
							value={spc.allHoldHealthSafetyQualifications}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="All Given Safety Induction"
							value={spc.allGivenSafetyInduction}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="All Appropriately Qualified"
							value={spc.allAppropriatelyQualified}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			{/* Declaration */}
			<SectionCard title="Declaration">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Declaration Name"
							value={spc.declarationName}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Declaration Date"
							value={formatDate(spc.declarationDate)}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Typography variant="h6">
							<strong>Declaration Other People:</strong>
						</Typography>
						<Typography align="left" gutterBottom variant="body1">
							{spc.declarationOtherPeople
								? spc.declarationOtherPeople.map(
										({ name, date }) => {
											return (
												<>
													<br />
													<Grid container>
														<Grid item xs={6}>
															<Typography
																align="left"
																gutterBottom
																variant="body1"
															>
																{name}
															</Typography>
														</Grid>

														<Grid item xs={6}>
															<Typography
																align="right"
																gutterBottom
																variant="body1"
															>
																{formatDate(
																	date
																)}
															</Typography>
														</Grid>
													</Grid>
													<Divider />
												</>
											);
										}
								  )
								: "-"}
						</Typography>
					</Grid>
				</Grid>
			</SectionCard>

			{/* Approval To Start Work */}
			<SectionCard title="Approval To Start Work">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Approval Name"
							value={spc.approvalName}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Approval Date"
							value={formatDate(spc.approvalDate)}
							options={{ bold: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			{/* Attachments */}
			<SectionCard title="Attachments">
				{renderAttachments(values.attachments, "Attachments")}
			</SectionCard>
		</>
	);
};

ReadOnlyForm.propTypes = {
	values: PropTypes.object.isRequired,
};

export default ReadOnlyForm;
