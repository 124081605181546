import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import SelectHazard from "./select-hazard";
import { isNotNullOrEmpty, isSameLength } from "utils";
import { CardContent, Grid, Divider, Typography } from "@material-ui/core";

import { FormButton } from "frame/components";
import { onAddHazardToSite } from "features/sites";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(-3, 0),
	},
	heading: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		padding: theme.spacing(0.5, 0),
	},
	label: {
		minWidth: 250,
		fontWeight: "bold",
		letterSpacing: "0.6px",
		marginTop: theme.spacing(1),
		paddingRight: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			paddingRight: theme.spacing(0),
			minWidth: 140,
		},
	},
	action: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		padding: theme.spacing(0.5, 0, 4, 0),
	},
}));

const SelectHazardForm = ({
	siteHazards,
	hazardList,
	disabled,
	values,
	onChange,
	editMode,
	onOpenAddHazardToSiteForm,
	showError,
}) => {
	const classes = useStyles();

	const isMaxHazards = isSameLength(hazardList, siteHazards);
	return (
		<CardContent className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Divider />
					<div className={classes.heading}>
						<Typography
							className={classes.label}
							align="left"
							gutterBottom
							variant="body1"
						>
							Hazards
						</Typography>
					</div>

					<Divider />
					{isNotNullOrEmpty(siteHazards) &&
						siteHazards.map((hazard, index) => (
							<div key={`site-hazard-${index}`}>
								<SelectHazard
									editMode={editMode}
									disabled={disabled}
									hazardId={hazard.hazardId}
									hazardList={hazardList}
									values={values}
									onChange={onChange}
									showError={showError}
								/>
								<Divider />
							</div>
						))}
				</Grid>

				<Divider />
				<div className={classes.action}>
					<FormButton
						onClick={onOpenAddHazardToSiteForm}
						disabled={isMaxHazards}
					>
						+ Add from library
					</FormButton>
				</div>
			</Grid>
		</CardContent>
	);
};

SelectHazardForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	siteHazards: PropTypes.array.isRequired,
	hazardList: PropTypes.array.isRequired,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onOpenAddHazardToSiteForm: PropTypes.func.isRequired,
	editMode: PropTypes.bool,
	showError: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	onOpenAddHazardToSiteForm: onAddHazardToSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectHazardForm);
