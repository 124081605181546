import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	Modal,
	Card,
	CardContent,
	Grid,
	Typography,
	TextField,
} from "@material-ui/core";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { FormActions, FormButton, FormSubmitButton } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		justifyContent: "flex-end",
		marginTop: theme.spacing(3),
	},
	inputStatus: {
		maxWidth: 310,
	},
	warning: {
		padding: theme.spacing(1, 0),
		color: theme.palette.error.main,
	},
}));

const CustomerAddEdit = ({
	onCustomerNameChanged,
	onContactPersonChanged,
	onEmailAddressChanged,
	onStatusChanged,
	onConsultantChanged,
	customer,
	isEditModalShowing,
	isAddModalShowing,
	onClose,
	onSave,
	className,
	...rest
}) => {
	const classes = useStyles();

	const onSubmit = () => onSave();

	if (!isEditModalShowing && !isAddModalShowing) {
		return null;
	}

	return (
		<Modal open={true} onClose={onClose}>
			<Card {...rest} className={clsx(classes.root, className)}>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<CardContent>
						<Typography align="center" gutterBottom variant="h3">
							{isAddModalShowing ? "Add" : "Edit"} Customer
						</Typography>

						<Grid
							className={classes.container}
							container
							spacing={3}
						>
							<Grid item xs={12}>
								<TextValidator
									fullWidth
									label="Customer Name *"
									name="customerName"
									value={customer.customerName}
									variant="outlined"
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={(e) =>
										onCustomerNameChanged(e.target.value)
									}
									inputProps={{ maxLength: 100 }}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextValidator
									fullWidth
									label="Contact Person"
									name="contactPerson"
									value={customer.contactPerson}
									variant="outlined"
									onChange={(e) =>
										onContactPersonChanged(e.target.value)
									}
									inputProps={{ maxLength: 100 }}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextValidator
									fullWidth
									label="Email Address"
									name="emailAddress"
									value={customer.emailAddress}
									variant="outlined"
									validators={["isEmail"]}
									errorMessages={["Must be a valid email"]}
									onChange={(e) =>
										onEmailAddressChanged(e.target.value)
									}
									inputProps={{ maxLength: 100 }}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									fullWidth
									className={classes.inputStatus}
									label="Status *"
									name="status"
									onChange={(e) =>
										onStatusChanged(e.target.value)
									}
									select
									SelectProps={{ native: true }}
									value={customer.status}
									variant="outlined"
								>
									<option value="Active">Active</option>
									<option value="Inactive">Inactive</option>
								</TextField>
								{customer.status === "Inactive" && (
									<Typography
										className={classes.warning}
										variant="body1"
									>
										<strong>Please note</strong> that
										changing the customer status to {'"'}
										Inactive{'"'} will also change all the
										customer projects for {'"'}
										{customer.customerName}
										{'"'} to inactive. These can be
										reactivated later when required.
									</Typography>
								)}
							</Grid>
						</Grid>

						<FormActions className={classes.actions}>
							<FormSubmitButton>Save</FormSubmitButton>
							<FormButton onClick={onClose}>Cancel</FormButton>
						</FormActions>
					</CardContent>
				</ValidatorForm>
			</Card>
		</Modal>
	);
};

CustomerAddEdit.propTypes = {
	className: PropTypes.string,
	customer: PropTypes.object,
	isAddModalShowing: PropTypes.bool,
	isEditModalShowing: PropTypes.bool,
	onCustomerNameChanged: PropTypes.func.isRequired,
	onContactPersonChanged: PropTypes.func.isRequired,
	onEmailAddressChanged: PropTypes.func.isRequired,
	onStatusChanged: PropTypes.func.isRequired,
	onConsultantChanged: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onClose: PropTypes.func,
};

export default CustomerAddEdit;
