import React, { forwardRef } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Tab } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		textTransform: "none",
		minWidth: 30,
		overflow: "visible",
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(16),
		[theme.breakpoints.down("xs")]: {
			fontSize: theme.typography.pxToRem(14),
		},
		marginLeft: theme.spacing(1.5),
		"&:hover": {
			color: theme.palette.brand,
			opacity: 1,
		},
		"&:focus": {
			color: theme.palette.brand,
		},
	},
	selected: {
		color: theme.palette.brand,
		fontWeight: theme.typography.fontWeightBold,
	},
}));

const StyledTab = forwardRef(({ selected, ...props }, ref) => {
	// eslint-disable-line

	const classes = useStyles();

	return (
		<Tab
			ref={ref}
			className={clsx(classes.root, selected ? classes.selected : "")}
			selected={selected}
			{...props}
		/>
	);
});

export default StyledTab;
