import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	ListItemIcon,
	ListItemText,
	Tooltip,
	IconButton,
	Menu,
	MenuItem,
} from "@material-ui/core";

import MoreIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

const useStyles = makeStyles(() => ({
	menu: {
		minWidth: 200,
	},
}));

const Options = ({ label, onDelete, ...props }) => {
	const classes = useStyles();
	const moreRef = useRef(null);
	const [openMenu, setOpenMenu] = useState(false);

	const onMenuOpen = () => setOpenMenu(true);

	const onMenuClose = () => setOpenMenu(false);

	const onDeleteAndClose = () => {
		onDelete();
		onMenuClose();
	};

	return (
		<Fragment>
			<Tooltip title="More options">
				<IconButton
					{...props}
					onClick={onMenuOpen}
					ref={moreRef}
					size="small"
				>
					<MoreIcon />
				</IconButton>
			</Tooltip>
			<Menu
				anchorEl={moreRef.current}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				classes={{ paper: classes.menu }}
				onClose={onMenuClose}
				open={openMenu}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<MenuItem onClick={onDeleteAndClose}>
					<ListItemIcon>
						<DeleteIcon />
					</ListItemIcon>
					<ListItemText primary={label ? label : "Delete Task"} />
				</MenuItem>
			</Menu>
		</Fragment>
	);
};

Options.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	onDelete: PropTypes.func.isRequired,
};

export default Options;
