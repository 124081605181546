import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";
import { onSafetyPlanChecklistListRequest } from "features/health-and-safety";

const mapStateToProps = ({ addSafetyPlanChecklistFormReducer }) => ({
	values: addSafetyPlanChecklistFormReducer,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddSafetyPlanChecklistValueChange,
	onReset: fromActions.onAddSafetyPlanChecklistReset,
	onSave: fromActions.onAddSafetyPlanChecklistSave,
	onGetSafetyPlanChecklistList: onSafetyPlanChecklistListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
