import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	heading: {
		marginBottom: theme.spacing(-3),
	},
}));

const NotesForm = ({ className, values, onChange }) => {
	const classes = useStyles();
	const notes = values.notes || {};
	const disabled = values.locked;

	const onInputChange =
		(key) =>
		({ target: { value } }) =>
			onChange({
				...values,
				notes: {
					...notes,
					[key]: value,
				},
			});

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Meeting Notes"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							id="notes_issues"
							disabled={disabled}
							fullWidth
							label="Operational Issues"
							multiline
							value={notes.operationalIssues || ""}
							variant="outlined"
							onChange={onInputChange("operationalIssues")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="notes_general"
							disabled={disabled}
							fullWidth
							label="General Issues Discussed"
							multiline
							value={notes.generalIssuesDiscussed || ""}
							variant="outlined"
							onChange={onInputChange("generalIssuesDiscussed")}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

NotesForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default NotesForm;
