import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import SelectHazardousSubstance from "./select-hazardous-substance";
import { isNotNullOrEmpty, isSameLength } from "utils";
import { CardContent, Grid, Divider, Typography } from "@material-ui/core";

import { FormButton } from "frame/components";
import { onAddHazardousSubstanceToSite } from "features/sites";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(-6, 0),
	},
	heading: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		padding: theme.spacing(0.5, 0),
	},
	label: {
		minWidth: 250,
		fontWeight: "bold",
		letterSpacing: "0.6px",
		marginTop: theme.spacing(1),
		paddingRight: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			paddingRight: theme.spacing(0),
			minWidth: 140,
		},
	},
	action: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		padding: theme.spacing(0.5, 0, 4, 0),
	},
}));

const SelectHazardousSubstanceForm = ({
	disabled,
	siteSubstances,
	hazardousSubstanceList,
	values,
	onChange,
	editMode,
	onOpenAddHazardousSubstanceToSiteForm,
	showError,
}) => {
	const classes = useStyles();
	const isMaxSubstances = isSameLength(
		hazardousSubstanceList,
		siteSubstances
	);

	return (
		<CardContent className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Divider />
					<div className={classes.heading}>
						<Typography
							className={classes.label}
							align="left"
							gutterBottom
							variant="body1"
						>
							Hazardous Substances
						</Typography>
					</div>

					<Divider />
					{isNotNullOrEmpty(siteSubstances) &&
						siteSubstances.map((substance, index) => (
							<div key={`site-substance-${index}`}>
								<SelectHazardousSubstance
									editMode={editMode}
									disabled={disabled}
									hazardousSubstanceId={
										substance.hazardousSubstanceId
									}
									hazardousSubstanceList={
										hazardousSubstanceList
									}
									values={values}
									onChange={onChange}
									showError={showError}
								/>
								<Divider />
							</div>
						))}

					<div className={classes.action}>
						<FormButton
							onClick={onOpenAddHazardousSubstanceToSiteForm}
							disabled={isMaxSubstances}
						>
							+ Add from library
						</FormButton>
					</div>
				</Grid>
			</Grid>
		</CardContent>
	);
};

SelectHazardousSubstanceForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	siteSubstances: PropTypes.array.isRequired,
	hazardousSubstanceList: PropTypes.array.isRequired,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onOpenAddHazardousSubstanceToSiteForm: PropTypes.func.isRequired,
	editMode: PropTypes.bool,
	showError: PropTypes.bool,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	onOpenAddHazardousSubstanceToSiteForm: onAddHazardousSubstanceToSite,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SelectHazardousSubstanceForm);
