import * as R from "ramda";
import initialNotificationsState from "../models/notifications-state";
import Types from "../actions/types";
import uuidv4 from "uuid/v4";

const errorChanged = (state, { payload }) => {
	let message = "An error occurred";
	if (payload) {
		if (payload.response && payload.response.error) {
			message = payload.response.error;
		}
		//else if(payload.message && !payload.message.toLowerCase().includes('ajax error')) {
		else if (payload.message) {
			message = payload.message;
		}
	}

	state.errorList.push({
		error: { message },
		action: payload,
		timestamp: Date.now(),
		id: uuidv4(),
	});
	return state;
};

const closeClicked = (state, action) => {
	state.errorList = state.errorList.filter(
		(errorItem) => errorItem.id !== action.payload
	);
	return state;
};

const dialogCloseClicked = (state, action) => {
	state.dialog.open = false;
	state.dialog.title = "";
	state.dialog.message = "";
	state.dialog.closeLabel = "";
	return state;
};

const showDialogRequested = (state, action) => {
	state.dialog.open = true;
	state.dialog.title = action.payload.title;
	state.dialog.message = action.payload.message;
	state.dialog.closeLabel = action.payload.closeLabel;
	return state;
};

const notificationsReducer = (state = initialNotificationsState, action) => {
	switch (action.type) {
		case Types.NOTIFICATIONS_ERROR_CHANGED:
			return errorChanged(R.clone(state), action);
		case Types.NOTIFICATIONS_CLOSE_CLICKED:
			return closeClicked(R.clone(state), action);
		case Types.NOTIFICATIONS_DIALOG_CLOSE_CLICKED:
			return dialogCloseClicked(R.clone(state), action);
		case Types.NOTIFICATIONS_SHOW_DIALOG_REQUESTED:
			return showDialogRequested(R.clone(state), action);
		default:
			return state;
	}
};

export default notificationsReducer;
