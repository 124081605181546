import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRouter, isStaffUser, isProd } from "utils";
import PageHeaderTabs from "../../health-safety-forms/components/page-header-tabs";
import { Grid } from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
	PopupSnackbar
} from "frame/components";
import { strip, SiteForm } from "features/forms";
import { TimesheetForm } from "./timesheet-form";
import { TimesheetToggleForm } from "./timesheet-toggle-form";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import {
	LIST_TIMESHEETS_ROUTE,
	ADD_ADMIN_TIMESHEET_ROUTE,
	ADD_TIMESHEET_ROUTE,
	NEW_LEAVE_PATH
} from "../timesheet-routes";
import { TASK_ROUTE } from "features/health-safety-forms";
import {
	onAddTimesheetValueChange,
	onAddTimesheetReset,
	onAddTimesheetSave
} from "../timesheet-actions";
import {
	onAddAdminTimesheetValueChange,
	onAddAdminTimesheetSave,
	onAddAdminTimesheetReset
} from "../timesheet-admin-actions";
import moment from "moment";
import { useSelector } from "react-redux";
import { SITES_ROUTE } from "../../sites/actions/types";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(3)
		}
	},
	actions: {
		marginTop: theme.spacing(3),
		paddingBottom: theme.spacing(8)
	},
	menu: {
		"padding": theme.spacing(3, 0, 0, 0),
		"& > *": {
			marginRight: theme.spacing(3)
		}
	}
}));

const mapStateToProps = ({ timesheetFormReducer, timesheetAdminFormReducer, profile }) => ({
	values: timesheetFormReducer,
	valuesAdmin: timesheetAdminFormReducer,
	isValid: timesheetAdminFormReducer.isValid,
	role: profile.role
});

const mapDispatchToProps = {
	onChange: onAddTimesheetValueChange,
	onChangeAdmin: onAddAdminTimesheetValueChange,
	onReset: onAddTimesheetReset,
	onResetAdmin: onAddAdminTimesheetReset,
	onSave: onAddTimesheetSave,
	onSaveAdmin: onAddAdminTimesheetSave
};

export const AddTimesheetForm = connect(
	mapStateToProps,
	mapDispatchToProps
)(
	({
		values,
		valuesAdmin,
		onChange,
		onChangeAdmin,
		onReset,
		onResetAdmin,
		onSave,
		onSaveAdmin,
		isValid,
		role
	}) => {
		const classes = useStyles();
		const { history } = useRouter();
		const staffList = useSelector(({ staff }) => staff.pagination?.results);
		const currentStaffId = useSelector(({ profile }) => profile?.staffId);
		const { location } = useRouter();
		let form = <TimesheetToggleForm />;
		let showAddAdminButton = true;
		let isTimesheetForm = useRef(false);
		const { match } = useRouter();
		const { siteId } = match.params;
		const isAdminTimesheet =
			location.pathname === ADD_ADMIN_TIMESHEET_ROUTE ||
			location.pathname === `${ADD_ADMIN_TIMESHEET_ROUTE}/${siteId}`;

		const onSubmit = (event) => {
			event.preventDefault();
			isProd &&
				ReactGA.event({
					category: "Tasks",
					action: "Save Clicked",
					label: "Add Timesheet"
				});

			if (isTimesheetForm.current) {
				onSaveAdmin(strip(values));
				onResetAdmin();
			} else {
				onSave(strip(values));
			}
		};

		const onCancel = () => {
			onResetAdmin();
			history.push(TASK_ROUTE);
		};

		if (isAdminTimesheet && !isStaffUser(role)) {
			form = <TimesheetForm values={valuesAdmin} onChange={onChangeAdmin} />;
			isTimesheetForm.current = true;
		} else {
			form = <TimesheetToggleForm />;
		}

		if (isStaffUser(role)) {
			showAddAdminButton = false;
		}

		useEffect(() => {
			if (values.timesheet.toggle) {
				return;
			}

			onReset({
				timesheet: {
					staffId: staffList.some((staff) => staff.id === currentStaffId) ? currentStaffId : "",
					startDate: moment().format("YYYY-MM-DD"),
					startTime: moment().format("HH:mm"),
					endTime: moment().format("HH:mm")
				}
			});
			onResetAdmin({
				timesheet: {
					staffId: staffList.some((staff) => staff.id === currentStaffId) ? currentStaffId : "",
					startDate: moment().format("YYYY-MM-DD"),
					startTime: moment().format("HH:mm"),
					endTime: moment().format("HH:mm")
				}
			});
		}, []);

		useEffect(() => {
			if (!siteId) {
				return;
			}

			if (isAdminTimesheet) {
				onChangeAdmin({ ...valuesAdmin, siteId: siteId, timesheet: { ...valuesAdmin.timesheet } });
			} else {
				onChange({ ...values, siteId: siteId, timesheet: { ...values.timesheet } });
			}
		}, [isAdminTimesheet]);

		return (
			<Page title="Add Timesheet">
				<ScrollToTopOnMount />
				<PageHeaderTabs
					title={values.timesheet.toggle ? "Active Timesheet" : "Add Timesheet"}
					staffUserHide={false}
					addTabTitle={values.timesheet.toggle ? "Active Timesheet" : undefined}
					hideAddTabIcon={!!values.timesheet.toggle}
					addTabPath={siteId ? `${ADD_TIMESHEET_ROUTE}/${siteId}` : ADD_TIMESHEET_ROUTE}
					listTabPath={LIST_TIMESHEETS_ROUTE}
					addAdminPath={
						siteId ? `${ADD_ADMIN_TIMESHEET_ROUTE}/${siteId}` : ADD_ADMIN_TIMESHEET_ROUTE
					}
					backActionPath={siteId ? `${SITES_ROUTE}/${siteId}` : TASK_ROUTE}
					showAddAdminButton={showAddAdminButton}
					showLeaveButton={true}
					newLeavePath={NEW_LEAVE_PATH}
				/>
				<PageContent padSides padBottom>
					<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
						<Grid container spacing={3}>
							<Grid item lg={6} md={12} sm={12} xs={12}>
								<div className={classes.body}>
									{isTimesheetForm.current ? (
										<SiteForm
											disableSite={false}
											disableDate={isStaffUser(role)}
											hideDate
											values={valuesAdmin}
											onChange={onChangeAdmin}
											searchDropdownReadOnly={true}
											isAdmin={true}
										/>
									) : (
										<SiteForm
											disableSite={values.timesheet.toggle}
											disableDate={isStaffUser(role)}
											hideDate
											values={values}
											onChange={onChange}
											searchDropdownReadOnly={true}
											hideTaskTypes={false}
										/>
									)}

									{((values.siteId && values.siteId !== "" && !isTimesheetForm.current) ||
										(valuesAdmin.siteId && valuesAdmin.siteId !== "" && isTimesheetForm.current)) &&
										form}
								</div>
							</Grid>
						</Grid>

						{valuesAdmin.siteId && !isStaffUser(role) && isAdminTimesheet && (
							<FormActions className={classes.actions}>
								<FormSubmitButton
									disabled={!isValid || valuesAdmin.isEndDateTimeInvalid}
									loading={valuesAdmin.isLoading}
								>
									Save
								</FormSubmitButton>
								<FormButton disabled={valuesAdmin.isLoading} onClick={onCancel}>
									Cancel
								</FormButton>
							</FormActions>
						)}
					</ValidatorForm>
					<PopupSnackbar message={values.errorMessage} coloured error onExited={() => {}} />
				</PageContent>
			</Page>
		);
	}
);
