import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Typography } from "@material-ui/core";
import { isNotNullOrEmpty } from "utils";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
	StyledModal,
} from "frame/components";
import * as R from "ramda";

import {
	strip,
	DateForm,
	JobSafetyAnalysisForm,
	UploadFormCard,
	EmailRecipientsForm,
} from "features/forms";

import ReadOnlyForm from "./read-only-form";

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		flexDirection: "row",
		padding: theme.spacing(3, 3, 10, 3),
	},
	error: {
		paddingTop: theme.spacing(1),
		color: theme.palette.error.main,
		paddingBottom: theme.spacing(7),
	},
}));

const hydrate = ({
	id,
	name,
	createdAt,
	tasks,
	attachments,
	recipients,
	recipientsOther,
	organisationId,
}) => ({
	id,
	name,
	createdAt,
	tasks,
	attachments,
	recipients,
	recipientsOther,
	currentOrganisation: organisationId,
	responseMessage: "",
});

const Form = ({
	id,
	values,
	jsaList,
	onClose,
	onChange,
	onReset,
	onSave,
	responseMessage,
	isClone,
	isEmailAction,
	readOnly,
}) => {
	const classes = useStyles();

	const [loaded, setLoaded] = useState(false);
	const [originalName, setOriginalName] = useState("");

	const [hasInvalidSteps, setHasInvalidSteps] = useState(false);
	const [openErrorModal, setOpenErrorModal] = useState(false);

	const open = values && values.id;

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchData() {
			setLoaded(true);
			let jsa = R.clone(R.find(R.propEq("id", id), jsaList));

			if (jsa) {
				setOriginalName(jsa.name || "");

				if (isClone) {
					jsa.id = uuid.v4();
					jsa.name = `${jsa.name} (copy)`;
				}
				onChange(hydrate(jsa));
			}
		}
		if (id) {
			setLoaded(true);
			fetchData();
		}
	}, []);

	useEffect(() => {
		if (isNotNullOrEmpty(values.tasks)) {
			const hasValue = (x) => x && x !== "";

			let invalidTaskSteps = values.tasks.map((task) => {
				let steps =
					isNotNullOrEmpty(task.steps) &&
					task.steps
						.map(
							(step) =>
								task.steps.length === 1 // if steps length == 1, check if step is null or empty string with any other step fields are partially filled
									? (!step.name || step.name === "") &&
									  (isNotNullOrEmpty(step.hazards) ||
											hasValue(
												step.initialRiskAssessment
											) ||
											hasValue(
												step.levelOfControlMethods
											) ||
											hasValue(
												step.residualRiskAssessment
											))
									: !step.name // check for steps which null step name when steps > 1
						)
						.filter((x) => x);

				return steps.length;
			});

			const invalid =
				invalidTaskSteps && invalidTaskSteps.filter((x) => x > 0);

			setHasInvalidSteps(invalid && invalid.length > 0);
		}
	}, [values.tasks]);

	useEffect(() => {
		values.save.success && onClose();
	}, [values.save.success, onClose]);

	const onSubmit = (event) => {
		event.preventDefault();
		hasInvalidSteps ? setOpenErrorModal(true) : onSave();
	};

	const onCancel = () => {
		onReset();
		onClose();
	};

	if (!open) {
		return null;
	}

	const action = isClone
		? "Clone"
		: isEmailAction
		? "Email"
		: readOnly
		? "View"
		: "Edit";

	const title = `${action} JSA: ${originalName}`;

	return (
		<Dialog
			open={open ? true : false}
			aria-labelledby="Job Safety Analysis"
			title={title}
			onClose={onCancel}
		>
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				{readOnly ? (
					<ReadOnlyForm values={values} />
				) : (
					<>
						{!isEmailAction && (
							<>
								<DateForm values={values} onChange={onChange} />
								<JobSafetyAnalysisForm
									values={values}
									onChange={onChange}
								/>
								<UploadFormCard
									title={"Add Attachments"}
									values={values}
									onChange={onChange}
								/>
							</>
						)}

						<EmailRecipientsForm
							hideSendEmailCheckBox={isEmailAction}
							values={values}
							onChange={onChange}
						/>
					</>
				)}

				<FormActions className={classes.actions}>
					{!readOnly && (
						<FormSubmitButton loading={values.save.saving}>
							{isEmailAction ? "Send" : "Save"}
						</FormSubmitButton>
					)}
					<FormButton
						disabled={values.save.saving}
						onClick={onCancel}
					>
						{readOnly ? "Close" : "Cancel"}
					</FormButton>
				</FormActions>
				<Typography variant="body1" className={classes.error}>
					{responseMessage &&
						responseMessage.includes("already") &&
						responseMessage}
				</Typography>
			</ValidatorForm>

			<StyledModal
				open={openErrorModal}
				title={`Incomplete Steps Section`}
				onClose={() => setOpenErrorModal(false)}
				onConfirm={() => onSave(strip(values))}
			>
				{`Please note you that have incomplete step fields and any step without step name will be not be saved`}
			</StyledModal>
		</Dialog>
	);
};

Form.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	jsaList: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	responseMessage: PropTypes.string,
	isClone: PropTypes.bool,
	isEmailAction: PropTypes.bool,
	readOnly: PropTypes.bool,
};

export default Form;
