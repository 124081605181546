export const CUSTOMERS_ROUTE = `/customers`;
export const CUSTOMER_ROUTE = `/customers/:customerId`;
export const CUSTOMER_TAB_ROUTE = `${CUSTOMER_ROUTE}/:siteTab?`;

export const CUSTOMERS_PATH = `/customers`;
export const CUSTOMER_PATH = `/customers`;

// Tab paths
export const CUSTOMER_HEALTH_SAFETY_PATH = `health-safety`;
export const CUSTOMER_NOTES_PATH = `notes`;
export const CUSTOMER_SITES_PATH = `sites`;
export const CUSTOMER_STAFF_PATH = `staff`;
export const CUSTOMER_SUMMARY_PATH = `summary`;

// Tab specifics
export const CUSTOMER_NOTES_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_NOTES_PATH}`;
export const CUSTOMER_SITES_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_SITES_PATH}`;
export const CUSTOMER_STAFF_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_STAFF_PATH}`;
export const CUSTOMER_SUMMARY_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_SUMMARY_PATH}`;

// Sub tab paths
export const CUSTOMER_INCIDENTS_PATH = `${CUSTOMER_HEALTH_SAFETY_PATH}/accidents`;
export const CUSTOMER_HAZARDS_PATH = `${CUSTOMER_HEALTH_SAFETY_PATH}/hazards`;
export const CUSTOMER_HAZARDOUS_SUBSTANCES_PATH = `${CUSTOMER_HEALTH_SAFETY_PATH}/hazardous-substances`;
export const CUSTOMER_INDUCTION_MINUTES_PATH = `${CUSTOMER_HEALTH_SAFETY_PATH}/induction-minutes`;
export const CUSTOMER_SAFETY_MEETING_MINUTES_PATH = `${CUSTOMER_HEALTH_SAFETY_PATH}/safety-meeting-minutes`;
export const CUSTOMER_SAFETY_PLAN_CHECKLIST_PATH = `${CUSTOMER_HEALTH_SAFETY_PATH}/safety-plan-checklists`;
export const CUSTOMER_JOB_SAFETY_ANALYSIS_PATH = `${CUSTOMER_HEALTH_SAFETY_PATH}/job-safety-analysis`;

// Sub tab specifics
export const CUSTOMER_INCIDENTS_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_INCIDENTS_PATH}`;
export const CUSTOMER_HAZARDS_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_HAZARDS_PATH}`;
export const CUSTOMER_HAZARDOUS_SUBSTANCES_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_HAZARDOUS_SUBSTANCES_PATH}`;
export const CUSTOMER_INDUCTION_MINUTES_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_INDUCTION_MINUTES_PATH}`;
export const CUSTOMER_SAFETY_MEETING_MINUTES_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_SAFETY_MEETING_MINUTES_PATH}`;
export const CUSTOMER_SAFETY_PLAN_CHECKLIST_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_SAFETY_PLAN_CHECKLIST_PATH}`;
export const CUSTOMER_JOB_SAFETY_ANALYSYS_ROUTE = `${CUSTOMER_ROUTE}/${CUSTOMER_JOB_SAFETY_ANALYSIS_PATH}`;

const CUSTOMERS_LOOKUP = "CUSTOMERS_LOOKUP";
const CUSTOMERS_LOOKUP_SUCCESS = "CUSTOMERS_LOOKUP_SUCCESS";
const CUSTOMERS_LOOKUP_FAILED = "CUSTOMERS_LOOKUP_FAILED";

const CUSTOMERS_SEARCH_CHANGED = "CUSTOMERS_SEARCH_CHANGED";
const CUSTOMERS_SEARCH_CLEAR = "CUSTOMERS_SEARCH_CLEAR";

const CUSTOMERS_LIST_REQUESTED = "CUSTOMERS_LIST_REQUESTED";
const CUSTOMERS_PAGED_LIST_REQUESTED = "CUSTOMERS_PAGED_LIST_REQUESTED";
const CUSTOMERS_ADD_REQUESTED = "CUSTOMERS_ADD_REQUESTED";
const CUSTOMERS_ADD_SUCCEEDED = "CUSTOMERS_ADD_SUCCEEDED";
const CUSTOMERS_ADD_FAILED = "CUSTOMERS_ADD_FAILED";
const CUSTOMERS_EDIT_REQUESTED = "CUSTOMERS_EDIT_REQUESTED";
const CUSTOMERS_CUSTOMER_NAME_CHANGED = "CUSTOMERS_CUSTOMER_NAME_CHANGED";
const CUSTOMERS_CONTACT_PERSON_CHANGED = "CUSTOMERS_CONTACT_PERSON_CHANGED";
const CUSTOMERS_EMAIL_ADDRESS_CHANGED = "CUSTOMERS_EMAIL_ADDRESS_CHANGED";
const CUSTOMERS_STATUS_CHANGED = "CUSTOMERS_STATUS_CHANGED";
const CUSTOMERS_CONSULTANT_CHANGED = "CUSTOMERS_CONSULTANT_CHANGED";
const CUSTOMERS_ADD_CLICKED = "CUSTOMERS_ADD_CLICKED";
const CUSTOMERS_CLOSE_CLICKED = "CUSTOMERS_CLOSE_CLICKED";
const CUSTOMERS_SAVE_CLICKED = "CUSTOMERS_SAVE_CLICKED";
const CUSTOMERS_EDIT_CLICKED = "CUSTOMERS_EDIT_CLICKED";
const CUSTOMERS_REMOVE_CLICKED = "CUSTOMERS_REMOVE_CLICKED";
const CUSTOMERS_REMOVE_SUCCEEDED = "CUSTOMERS_REMOVE_SUCCEEDED";
const CUSTOMERS_REMOVE_FAILED = "CUSTOMERS_REMOVE_FAILED";
const CUSTOMERS_LIST_RECEIVED = "CUSTOMERS_LIST_RECEIVED";
const CUSTOMERS_LIST_FAILED = "CUSTOMERS_LIST_FAILED";
const CUSTOMERS_DETAILS = "CUSTOMERS_DETAILS";

export default {
	// Routes
	CUSTOMERS_ROUTE,
	CUSTOMER_TAB_ROUTE,

	CUSTOMER_NOTES_ROUTE,
	CUSTOMER_SITES_ROUTE,
	CUSTOMER_STAFF_ROUTE,
	CUSTOMER_SUMMARY_ROUTE,

	CUSTOMER_INCIDENTS_ROUTE,
	CUSTOMER_HAZARDS_ROUTE,
	CUSTOMER_HAZARDOUS_SUBSTANCES_ROUTE,
	CUSTOMER_INDUCTION_MINUTES_ROUTE,
	CUSTOMER_SAFETY_MEETING_MINUTES_ROUTE,
	CUSTOMER_SAFETY_PLAN_CHECKLIST_ROUTE,
	CUSTOMER_JOB_SAFETY_ANALYSYS_ROUTE,

	// Paths
	CUSTOMERS_PATH,

	CUSTOMER_HEALTH_SAFETY_PATH,
	CUSTOMER_NOTES_PATH,
	CUSTOMER_SITES_PATH,
	CUSTOMER_STAFF_PATH,
	CUSTOMER_SUMMARY_PATH,

	CUSTOMER_INCIDENTS_PATH,
	CUSTOMER_HAZARDS_PATH,
	CUSTOMER_HAZARDOUS_SUBSTANCES_PATH,
	CUSTOMER_INDUCTION_MINUTES_PATH,
	CUSTOMER_SAFETY_MEETING_MINUTES_PATH,
	CUSTOMER_SAFETY_PLAN_CHECKLIST_PATH,
	CUSTOMER_JOB_SAFETY_ANALYSIS_PATH,

	// Actions
	CUSTOMERS_LOOKUP,
	CUSTOMERS_LOOKUP_SUCCESS,
	CUSTOMERS_LOOKUP_FAILED,
	CUSTOMERS_SEARCH_CHANGED,
	CUSTOMERS_SEARCH_CLEAR,

	CUSTOMERS_LIST_REQUESTED,
	CUSTOMERS_PAGED_LIST_REQUESTED,
	CUSTOMERS_ADD_REQUESTED,
	CUSTOMERS_ADD_SUCCEEDED,
	CUSTOMERS_ADD_FAILED,
	CUSTOMERS_EDIT_REQUESTED,
	CUSTOMERS_CUSTOMER_NAME_CHANGED,
	CUSTOMERS_CONTACT_PERSON_CHANGED,
	CUSTOMERS_EMAIL_ADDRESS_CHANGED,
	CUSTOMERS_STATUS_CHANGED,
	CUSTOMERS_CONSULTANT_CHANGED,
	CUSTOMERS_ADD_CLICKED,
	CUSTOMERS_CLOSE_CLICKED,
	CUSTOMERS_SAVE_CLICKED,
	CUSTOMERS_EDIT_CLICKED,
	CUSTOMERS_LIST_RECEIVED,
	CUSTOMERS_LIST_FAILED,
	CUSTOMERS_REMOVE_CLICKED,
	CUSTOMERS_REMOVE_SUCCEEDED,
	CUSTOMERS_REMOVE_FAILED,
	CUSTOMERS_DETAILS,
};
