import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	SectionCard,
	PeopleSection,
	AppendNotesListing,
	AttachmentsSectionCard,
	HazardSubstanceListItem,
	ValueText,
	Lookup,
} from "frame/components";
import { Typography, Grid, Divider } from "@material-ui/core";
import { formatDate, isNotNullOrEmpty } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const ReadOnlyForm = ({ values, hazardList, hazardousSubstanceList }) => {
	const classes = useStyles();

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<SectionCard>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ValueText
									label="Site"
									value={
										<Lookup
											id={values.siteId}
											resource="sites"
											attribute="siteName"
										/>
									}
									options={{ bold: true }}
								/>
							</Grid>
							<Grid item xs={12}>
								<ValueText
									label="Date"
									value={formatDate(values.createdAt)}
									options={{ bold: true }}
								/>
							</Grid>
						</Grid>
					</SectionCard>

					<SectionCard title="People">
						<PeopleSection
							displayAttendanceConfirmed
							people={values.people}
							otherPeople={values.otherPeople}
						/>
					</SectionCard>

					<SectionCard title="Hazards / Hazardous Substances">
						<Grid container spacing={2}>
							{isNotNullOrEmpty(values.hazards) && (
								<Grid item xs={12}>
									<Typography
										variant="h5"
										className={classes.heading}
									>
										<strong>Hazards:</strong>
									</Typography>
									<Typography
										align="left"
										gutterBottom
										variant="body1"
									>
										{hazardList
											? values.hazards.map((hazardId) => (
													<>
														<HazardSubstanceListItem
															isHazard
															readOnly
															id={hazardId}
															hazardList={
																hazardList
															}
														/>
														<Divider />
													</>
											  ))
											: "loading..."}
									</Typography>
								</Grid>
							)}

							{isNotNullOrEmpty(values.substances) && (
								<Grid item xs={12}>
									<Typography
										variant="h5"
										className={classes.heading}
									>
										<strong>Hazardous Substances:</strong>
									</Typography>
									<Typography
										align="left"
										gutterBottom
										variant="body1"
									>
										{hazardousSubstanceList
											? values.substances.map(
													(substanceId) => (
														<>
															<HazardSubstanceListItem
																readOnly
																id={substanceId}
																hazardousSubstanceList={
																	hazardousSubstanceList
																}
															/>
															<Divider />
														</>
													)
											  )
											: "loading..."}
									</Typography>
								</Grid>
							)}
						</Grid>
						{!isNotNullOrEmpty(values.hazards) &&
							!isNotNullOrEmpty(values.substances) &&
							"-"}
					</SectionCard>

					<AttachmentsSectionCard values={values} />

					<SectionCard title="Notes">
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography
									align="left"
									gutterBottom
									variant="body1"
								>
									{values.notes || "-"}
								</Typography>
							</Grid>
						</Grid>
					</SectionCard>

					<SectionCard title="Appended Notes">
						<AppendNotesListing
							noTitle
							appendNotes={values.appendNotes}
						/>
					</SectionCard>
				</Grid>
			</Grid>
		</>
	);
};

ReadOnlyForm.propTypes = {
	values: PropTypes.object.isRequired,
	hazardList: PropTypes.array.isRequired,
	hazardousSubstanceList: PropTypes.array.isRequired,
};

export default ReadOnlyForm;
