import * as fromTypes from "./types";

// Invite
export const invitationSave = (payload) => ({
	type: fromTypes.INVITATION_SAVE,
	payload,
});

export const invitationSaveFailed = (payload) => ({
	type: fromTypes.INVITATION_SAVE_FAILED,
	payload,
});

export const invitationSaveSuccess = (payload) => ({
	type: fromTypes.INVITATION_SAVE_SUCCESS,
	payload,
});

// Password
export const passwordSave = (payload) => ({
	type: fromTypes.PASSWORD_SAVE,
	payload,
});

export const passwordSaveFailed = (payload) => ({
	type: fromTypes.PASSWORD_SAVE_FAILED,
	payload,
});

export const passwordSaveSuccess = (payload) => ({
	type: fromTypes.PASSWORD_SAVE_SUCCESS,
	payload,
});

// Preferences
export const preferencesFetch = () => ({
	type: fromTypes.PREFERENCES_FETCH,
});

export const preferencesFetchFailed = (payload) => ({
	type: fromTypes.PREFERENCES_FETCH_FAILED,
	payload,
});

export const preferencesFetchSuccess = (payload) => ({
	type: fromTypes.PREFERENCES_FETCH_SUCCESS,
	payload,
});

export const preferencesSave = () => ({
	type: fromTypes.PREFERENCES_SAVE,
});

export const preferencesSaveFailed = (payload) => ({
	type: fromTypes.PREFERENCES_SAVE_FAILED,
	payload,
});

export const preferencesSaveSuccess = (payload) => ({
	type: fromTypes.PREFERENCES_SAVE_SUCCESS,
	payload,
});

export const preferencesReset = () => ({
	type: fromTypes.PREFERENCES_RESET,
});

export const preferencesChangeAlertEmails = (payload) => ({
	type: fromTypes.PREFERENCES_CHANGE_ALERT_EMAILS,
	payload,
});

export const onSaveEmailNotifications = (payload) => ({
	type: fromTypes.PREFERENCES_SAVE_EMAIL_NOTIFICATIONS,
	payload,
});

export const onSaveEmailNotificationsFailed = (payload) => ({
	type: fromTypes.PREFERENCES_SAVE_EMAIL_NOTIFICATIONS_FAILED,
	payload,
});

export const onSaveEmailNotificationsSuccess = (payload) => ({
	type: fromTypes.PREFERENCES_SAVE_EMAIL_NOTIFICATIONS_SUCCESS,
	payload,
});

// Profile
export const profileFetch = (payload) => ({
	type: fromTypes.PROFILE_FETCH,
	payload,
});

export const profileFetchFailed = (payload) => ({
	type: fromTypes.PROFILE_FETCH_FAILED,
	payload,
});

export const profileFetchSuccess = (payload) => ({
	type: fromTypes.PROFILE_FETCH_SUCCESS,
	payload,
});

export const profileSave = (payload) => ({
	type: fromTypes.PROFILE_SAVE,
	payload,
});

export const profileSaveFailed = (payload) => ({
	type: fromTypes.PROFILE_SAVE_FAILED,
	payload,
});

export const profileSaveSuccess = (payload) => ({
	type: fromTypes.PROFILE_SAVE_SUCCESS,
	payload,
});

export const profileReset = () => ({
	type: fromTypes.PROFILE_RESET,
});

export const sendInvite = () => ({
	type: fromTypes.SEND_INVITE,
});

export const reportDownload = (payload) => ({
	type: fromTypes.REPORT_DOWNLOAD,
	payload,
});

export const reportDownloadFailed = (payload) => ({
	type: fromTypes.REPORT_DOWNLOAD_FAILED,
	payload,
});

export const reportDownloadSuccess = (payload) => ({
	type: fromTypes.REPORT_DOWNLOAD_SUCCESS,
	payload,
});

export const reportClearResponseMessage = () => ({
	type: fromTypes.REPORT_DOWNLOAD_CLEAR_MESSAGE,
});

// Subscription
export const subscriptionFetch = (payload) => ({
	type: fromTypes.SUBSCRIPTION_FETCH,
	payload,
});

export const subscriptionFetchFailed = (payload) => ({
	type: fromTypes.SUBSCRIPTION_FETCH_FAILED,
	payload,
});

export const subscriptionFetchSuccess = (payload) => ({
	type: fromTypes.SUBSCRIPTION_FETCH_SUCCESS,
	payload,
});

export const onAddPayment = (payload) => ({
	type: fromTypes.SUBSCRIPTION_ADD_PAYMENT,
	payload,
});

export const onAddPaymentClose = (payload) => ({
	type: fromTypes.SUBSCRIPTION_ADD_PAYMENT_CLOSE,
	payload,
});

export const subscriptionAddPaymentSave = (payload) => ({
	type: fromTypes.SUBSCRIPTION_ADD_PAYMENT_SAVE,
	payload,
});

export const subscriptionAddPaymentSaveFailed = (payload) => ({
	type: fromTypes.SUBSCRIPTION_ADD_PAYMENT_SAVE_FAILED,
	payload,
});

export const subscriptionAddPaymentSaveSuccess = (payload) => ({
	type: fromTypes.SUBSCRIPTION_ADD_PAYMENT_SAVE_SUCCESS,
	payload,
});

export const subscriptionUpdatePaymentSave = (payload) => ({
	type: fromTypes.SUBSCRIPTION_UPDATE_PAYMENT_SAVE,
	payload
});

export const subscriptionUpdatePaymentSaveSuccess = (payload) => ({
	type: fromTypes.SUBSCRIPTION_UPDATE_PAYMENT_SAVE_SUCCESS,
	payload
});

export const subscriptionUpdatePaymentSaveFail = (payload) => ({
	type: fromTypes.SUBSCRIPTION_UPDATE_PAYMENT_SAVE_FAIL,
	payload
});

export const subscriptionModalSubmit = (payload) => ({
	type: fromTypes.SUBSCRIPTION_MODAL_SUBMIT,
	payload
});

// Stripe
export const onStripeError = (message) => ({
	type: fromTypes.SUBSCRIPTION_STRIPE_ERROR_RECEIVED,
	payload: message,
});

export const onCancelSubscription = (payload) => ({
	type: fromTypes.SUBSCRIPTION_CANCEL_SUBSCRIPTION,
	payload,
});

export const onCancelSubscriptionFailed = (payload) => ({
	type: fromTypes.SUBSCRIPTION_CANCEL_SUBSCRIPTION_FAILED,
	payload,
});

export const onCancelSubscriptionSuccess = (payload) => ({
	type: fromTypes.SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESS,
	payload,
});
