import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
} from "@material-ui/core";
import { isNotNullOrEmpty } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const JSAHazardForm = ({ values, disabled, onChange }) => {
	const classes = useStyles();
	const labelRef = useRef();

	const [labelWidth, setLabelWidth] = useState(0);
	const [open, setOpen] = useState(false);

	const hazardList = useSelector(
		({ healthSafety }) => healthSafety.hazards?.results
	);

	const toggleMenu = () => setOpen(!open);

	useEffect(() => {
		// for dropdown label
		setLabelWidth(labelRef.current.offsetWidth);
	}, []);

	const hazardOptions =
		hazardList &&
		hazardList
			.filter((x) => x.status !== "Archived")
			.map(({ id, name }) => ({
				label: name || "",
				value: id || "",
			}))
			.filter((x) => x.name !== "");

	const handleChange = ({ target: { value } }) => {
		let list = value.filter((node) => node && node !== "");
		onChange(list);
	};

	return (
		<FormControl
			variant="outlined"
			fullWidth
			className={classes.root}
			disabled={disabled || !isNotNullOrEmpty(hazardOptions)}
		>
			<InputLabel ref={labelRef} htmlFor="jsa_hazards">
				{!isNotNullOrEmpty(hazardOptions)
					? "No Hazards Available"
					: "Add Hazards"}
			</InputLabel>
			<Select
				multiple
				open={open}
				input={
					<OutlinedInput
						labelWidth={labelWidth}
						name="jsa_hazards"
						id="jsa_hazards"
						onClick={
							!disabled && isNotNullOrEmpty(hazardOptions)
								? toggleMenu
								: null
						}
					/>
				}
				value={isNotNullOrEmpty(values.hazards) ? values.hazards : []}
				onChange={handleChange}
			>
				{isNotNullOrEmpty(hazardOptions) &&
					hazardOptions.map(({ label, value }, key) => (
						<MenuItem key={key} value={value}>
							{label}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
};

JSAHazardForm.propTypes = {
	values: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default JSAHazardForm;
