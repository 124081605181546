import { connect } from "react-redux";
import Reports from "./reports";
import { reportDownload, reportClearResponseMessage } from "../actions";

const mapStateToProps = ({ report, staff, sites, profile }) => ({
	staffList: staff.pagination?.results,
	siteList: sites.pagination?.results,
	responseMessage: report.responseMessage,
	role: profile.role,
	save: report.save,
});

const mapDispatchToProps = {
	onDownload: reportDownload,
	onClearResponseMessage: reportClearResponseMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
