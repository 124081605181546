import {
	defaultFetchState,
	fetchRequested,
	fetchFailed,
	fetchSuccess,
	defaultSaveState,
	saveRequested,
	saveFailed,
	saveSuccess,
} from "utils";

import * as fromTypes from "../actions/types";
import * as fromOrgTypes from "features/organisations";

const defaultState = {
	numberOfRecords: {},
	siteNumberOfRecords: {},
	...defaultFetchState,
	...defaultSaveState,
};

const profile = (state = defaultState, action) => {
	switch (action.type) {
		case fromTypes.PROFILE_RESET:
			return defaultState;
		case fromOrgTypes.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED:
		case fromTypes.PROFILE_FETCH:
			return fetchRequested(state);
		case fromTypes.PROFILE_FETCH_FAILED:
			return fetchFailed(state, action.payload);
		case fromTypes.PROFILE_FETCH_SUCCESS:
			return {
				...fetchSuccess(state),
				...addProfile(state, action),
			};
		case fromTypes.PROFILE_SAVE:
			return {
				...saveRequested(state),
				...addProfile(state, action),
			};
		case fromTypes.PROFILE_SAVE_FAILED:
			return saveFailed(state, action.payload);
		case fromTypes.PROFILE_SAVE_SUCCESS:
			return saveSuccess(state);
		default:
			return state;
	}
};

const addProfile = (state, action) => {
	const getProperty = (key) => action.payload[key] || "";

	const { password, ...rest } = action.payload;
	const email = getProperty("email");
	const phone = getProperty("phone");
	const firstName = getProperty("firstName");
	const lastName = getProperty("lastName");
	const fullName = String(`${firstName} ${lastName}`).trim();
	const staffId = getProperty("staffId");
	let allowExternalNotifications = getProperty("allowExternalNotifications");
	const supervisingStaffList = getProperty("supervisingStaffList");

	allowExternalNotifications =
		allowExternalNotifications === "" ? false : allowExternalNotifications;

	return {
		...rest,
		email,
		phone,
		firstName,
		lastName,
		fullName,
		staffId,
		supervisingStaffList,
		allowExternalNotifications,
	};
};

export default profile;
