import * as R from "ramda";
import initialFrameState from "../models/frame-state";
import Types from "../actions/types";
import * as fromOrganisations from "features/organisations";

const initialiseReceived = (state, action) => {
	state.initialising = true;
	return state;
};

const initialiseFinished = (state, action) => {
	state.initialising = false;
	return state;
};

const updateVersionId = (state, action) => {
	if (state.versionId !== window.versionId) {
		state.versionId = window.versionId;
	}
	return state;
};

const statusReducer = (state = initialFrameState, action) => {
	switch (action.type) {
		case Types.FRAME_INITIALISE_REQUESTED:
			return initialiseReceived(R.clone(state), action);
		case Types.QUEUE_NETWORK_CHANGED:
			return initialiseFinished(R.clone(state), action);
		case fromOrganisations.ORGANISATION_FETCH_SUCCESS:
			return updateVersionId(R.clone(state), action);
		default:
			return state;
	}
};

export default statusReducer;
