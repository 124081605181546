import DOMPurify from "dompurify";

export const capitalize = (value) =>
	String(value).replace(/^\w/, (c) => c.toUpperCase());

export const capitalizeFirstLetter = (s) => {
	if (typeof s !== "string") return "";

	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const sanitize = (dirtyString) => DOMPurify.sanitize(dirtyString);
