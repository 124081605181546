import { createMatchSelector } from "connected-react-router";
import { STAFF_PATH, STAFF_MEMBER_ROUTE } from "../actions/types";

export const createStaffPath = (id, path) => `${STAFF_PATH}/${id}/${path}`;

// TODO
// This is only a temporary solution
export const getStaffById = (state) => {
	const match = createMatchSelector(STAFF_MEMBER_ROUTE)(state);

	if (match && match.params && match.params.staffId) {
		const keys = Object.keys(state.staff.pagination?.results);

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			const model = state.staff.pagination?.results[key];

			if (model.id === match.params.staffId) {
				return model;
			}
		}
	}

	return null;
};
