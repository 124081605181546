import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useRouter } from "utils";
import { makeStyles } from "@material-ui/core/styles";
import {
	Drawer,
	Typography,
	ListItem,
	List,
	ListItemIcon,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import { isStaffUser } from "utils";

// Routes
import * as fromHealthSafetyRoutes from "features/health-safety-forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";
import { CUSTOMERS_ROUTE } from "features/customers";
import { STAFF_ROUTE } from "features/staff";
import { SITES_ROUTE } from "features/sites";
// import { ADD_JOB_PATH } from 'features/jobs'

// Icons
import AddIcon from "@material-ui/icons/Add";
// import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
// import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import PersonIcon from "@material-ui/icons/PersonOutlined";
import WarningIcon from "@material-ui/icons/Warning";
import PeopleIcon from "@material-ui/icons/People";
import RoomIcon from "@material-ui/icons/Room";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ListIcon from "@material-ui/icons/FormatListBulleted";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ForumIcon from "@material-ui/icons/Forum";

const useStyles = makeStyles((theme) => ({
	root: {
		right: 0,
		bottom: 50,
		position: "fixed",
		textAlign: "right",
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down("sm")]: {
			marginBottom: theme.spacing(13),
		},
		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(13),
		},
	},
	listItem: {
		marginBottom: theme.spacing(6),
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	stickToBottomRight: {
		position: "absolute",
		bottom: 90,
		right: 20,
		"@media (min-width: 960px)": {
			bottom: 20,
		},
		[theme.breakpoints.down("xs")]: {
			marginRight: theme.spacing(2),
			marginBottom: theme.spacing(1),
		},
		"&:hover": {
			backgroundColor: "#101c35",
		},
	},
	Icon: {
		right: 0,
		position: "fixed",
		marginRight: theme.spacing(3),
		borderRadius: 25,
		height: 50,
		width: 50,
		padding: 10,
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.primary.main,
		transition: "opacity 250ms ease-in-out",
		[theme.breakpoints.down("xs")]: {
			marginRight: theme.spacing(5),
		},
	},
	Label: {
		right: 0,
		position: "fixed",
		marginTop: theme.spacing(3),
		marginRight: theme.spacing(12),
		borderRadius: 5,
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		color: theme.palette.primary.main,
		fontWeight: "bold",
		letterSpacing: 0.1,
		[theme.breakpoints.down("xs")]: {
			marginRight: theme.spacing(14),
		},
	},
}));

const FabDrawer = ({ onAdd, role }) => {
	const classes = useStyles();
	const { history, location } = useRouter();

	const [state, setState] = React.useState({
		bottom: false,
	});

	const toggleDrawer = (side, open) => (event) => {
		setState({ ...state, [side]: open });
	};

	let iconDrawerConfig = [
		// {
		//   title: 'Quick Record',
		//   href: ADD_JOB_PATH,
		//   icon: PhotoCameraIcon,
		//   display: true
		// },
		// {
		//   title: 'Timesheets',
		//   href: `${fromHealthSafetyRoutes.HEALTH_SAFETY_ROUTE}/add-timesheet`,
		//   icon: AccessAlarmIcon,
		//   display: true
		// },
		// {
		//   title: 'Health and Safety',
		//   href: fromHealthSafetyRoutes.HEALTH_SAFETY_ROUTE,
		//   icon: LocalHospitalIcon,
		//   display: true
		// },
		{
			title: "Add Customer",
			href: location.pathname,
			icon: PeopleIcon,
			display: location.pathname === CUSTOMERS_ROUTE,
		},
		{
			title: "Add Site",
			href: location.pathname,
			icon: RoomIcon,
			display: location.pathname === SITES_ROUTE,
		},
		{
			title: "Add Staff",
			href: location.pathname,
			icon: PersonIcon,
			display: location.pathname === STAFF_ROUTE,
		},
		{
			title: "Add hazard to site from library",
			href: location.pathname,
			icon: WarningIcon,
			display:
				location.pathname.includes("sites") &&
				location.pathname.includes(
					fromHealthAndSafety.HEALTH_SAFETY_HAZARDS_ROUTE
				),
		},
		{
			title: "Add hazard",
			href: fromHealthSafetyRoutes.ADD_HAZARD_ROUTE,
			icon: WarningIcon,
			display: location.pathname.includes(
				fromHealthAndSafety.HEALTH_SAFETY_HAZARDS_ROUTE
			),
		},
		{
			title: "Add hazardous substances to site from library",
			href: location.pathname,
			icon: WarningIcon,
			display:
				location.pathname.includes("sites") &&
				location.pathname.includes(
					fromHealthAndSafety.HEALTH_SAFETY_HAZARDOUS_SUBSTANCES_ROUTE
				),
		},
		{
			title: "Add hazardous substance",
			href: fromHealthSafetyRoutes.ADD_HAZARDOUS_SUBSTANCE_ROUTE,
			icon: WarningIcon,
			display: location.pathname.includes(
				fromHealthAndSafety.HEALTH_SAFETY_HAZARDOUS_SUBSTANCES_ROUTE
			),
		},
		{
			title: "Add incident",
			href: fromHealthSafetyRoutes.ADD_INCIDENT_ROUTE,
			icon: FlashOnIcon,
			display: location.pathname.includes(
				fromHealthAndSafety.HEALTH_SAFETY_INCIDENTS_ROUTE
			),
		},
		{
			title: "Add safety minutes",
			href: fromHealthSafetyRoutes.ADD_SAFETY_MINUTES_ROUTE,
			icon: ForumIcon,
			display: location.pathname.includes(
				fromHealthAndSafety.HEALTH_SAFETY_SAFETY_MINUTES_ROUTE
			),
		},
		{
			title: "Add Job Safety Analysis (JSA)",
			href: fromHealthSafetyRoutes.ADD_JSA_ROUTE,
			icon: ChromeReaderModeIcon,
			display: location.pathname.includes(
				fromHealthAndSafety.HEALTH_SAFETY_JOB_SAFETY_ANALYSIS_ROUTE
			),
		},
		{
			title: "Add induction meeting",
			href: fromHealthSafetyRoutes.ADD_INDUCTION_MINUTES_ROUTE,
			icon: PersonAddIcon,
			display: location.pathname.includes(
				fromHealthAndSafety.HEALTH_SAFETY_INDUCTION_MINUTES_ROUTE
			),
		},
		{
			title: "Add safety minutes checklist",
			href: fromHealthSafetyRoutes.ADD_SAFETY_PLAN_CHECKLIST_ROUTE,
			icon: ListIcon,
			display: location.pathname.includes(
				fromHealthAndSafety.HEALTH_SAFETY_SAFETY_PLAN_CHECKLISTS_ROUTE
			),
		},
		{
			title: "Add staff training",
			href: fromHealthSafetyRoutes.ADD_STAFF_TRAINING_ROUTE,
			icon: PeopleIcon,
			display: location.pathname.includes(
				fromHealthAndSafety.HEALTH_SAFETY_STAFF_TRAINING_ROUTE
			),
		},
	];

	const onListItemSelect = (href) => {
		if (typeof onAdd !== "undefined" && typeof onAdd === "function") {
			onAdd();
		}
		history.push(href); // todo: dispatch push()
	};

	const StaffOnlyFeatures = [
		"Quick Record",
		"Timesheets",
		"Health and Safety",
	];
	iconDrawerConfig = isStaffUser(role)
		? iconDrawerConfig.filter((item) =>
				StaffOnlyFeatures.includes(item.title)
		  )
		: iconDrawerConfig;

	const iconDrawer = (side) => (
		<div onClick={toggleDrawer(side, false)}>
			<List className={classes.root}>
				{iconDrawerConfig
					.filter((x) => x.display === true)
					.map((list, index) => (
						<ListItem
							key={index}
							button
							component={RouterLink}
							to="#"
							onClick={() => onListItemSelect(list.href)}
							className={classes.listItem}
						>
							<Typography variant="h5" className={classes.Label}>
								{list.title}
							</Typography>
							<ListItemIcon>
								<list.icon className={classes.Icon} />
							</ListItemIcon>
						</ListItem>
					))}
			</List>
		</div>
	);

	return (
		<div>
			<Fab
				data-cy="plus-button"
				color="primary"
				aria-label="add"
				className={classes.stickToBottomRight}
				onClick={toggleDrawer("bottom", true)}
			>
				<AddIcon />
			</Fab>
			<Drawer
				anchor="bottom"
				open={state.bottom}
				onClose={toggleDrawer("bottom", false)}
			>
				{iconDrawer("bottom")}
			</Drawer>
		</div>
	);
};

FabDrawer.propTypes = {
	onAdd: PropTypes.func,
	role: PropTypes.string.isRequired,
};

export default FabDrawer;
