import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
	colors,
	Dialog,
	DialogContent,
	useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PageHeader from "./page-header";

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiDialog-paper": {
			background: theme.palette.background.default,
		},
	},
	header: {
		margin: 0,
		padding: theme.spacing(4, 1, 2, 1),
		background: theme.palette.background.default,
		borderBottom: `1px solid ${theme.palette.divider}`,
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(4, 3, 2, 3),
		},
	},
	titles: {
		margin: 0,
		padding: "0 10px",
	},
	content: {
		position: "relative",
		margin: 0,
		padding: theme.spacing(1, 1, 1, 1),
		background: theme.palette.background.default,
		pointerEvents: "all",
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(1, 3, 1, 3),
		},
	},
	close: {
		display: "flex",
		position: "absolute",
		top: theme.spacing(1),
		right: theme.spacing(1),
		margin: 0,
		padding: 0,
		width: 30,
		height: 30,
		background: "transparent",
		borderRadius: 15,
		border: "none",
		transition: "0.3s all ease-out",
		cursor: "pointer",
		"&:hover": {
			background: colors.grey[100],
			border: "none",
			outline: "none",
		},
		"&:focus": {
			border: "none",
			outline: "none",
		},
		[theme.breakpoints.up("sm")]: {
			top: theme.spacing(1.4),
			right: theme.spacing(2.4),
		},
	},
	icon: {
		margin: 0,
		width: 30,
		height: 30,
		color: colors.grey[600],
	},
}));

const PageDialog = ({
	action,
	children,
	open,
	title,
	breadcrumbs,
	onAction,
	onClose,
	modalSize = "md",
	...props
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const scroll = fullScreen ? "body" : "paper";

	return (
		<Dialog
			{...props}
			className={classes.root}
			fullScreen={fullScreen}
			fullWidth
			maxWidth={modalSize}
			open={open}
			scroll={scroll}
			onClose={onClose}
		>
			<div className={classes.header}>
				<PageHeader
					className={classes.titles}
					action={action}
					breadcrumbs={breadcrumbs}
					title={title}
					onAction={onAction}
				/>
				<button className={classes.close} onClick={onClose}>
					<CloseIcon className={classes.icon} />
				</button>
			</div>
			<DialogContent className={classes.content}>
				{children}
			</DialogContent>
		</Dialog>
	);
};

PageDialog.propTypes = {
	action: PropTypes.string,
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	breadcrumbs: PropTypes.string,
	open: PropTypes.bool.isRequired,
	onAction: PropTypes.func,
	onClose: PropTypes.func.isRequired,
	modalSize: PropTypes.string,
};

export default PageDialog;
