import { combineEpics } from "redux-observable";
import loginEffect from "./login-effect";
import signoutEffect from "./signout-effect";
import registerEffect from "./register-effect";
import forgotPasswordEffect from "./forgot-password-effect";
import setPasswordEffect from "./set-password-effect";
import resetPasswordEffect from "./reset-password-effect";
import confirmationEffect from "./confirmation-effect";
import logoutEffect, { resetStateEffect } from "./logout-effect";
import sendResetEmailEffect from "./send-reset-email-effect";
import resendConfirmationEmailEffect from "./resent-confirmation-email-effect";

const authorisationEffects = combineEpics(
	loginEffect,
	signoutEffect,
	registerEffect,
	forgotPasswordEffect,
	setPasswordEffect,
	resetPasswordEffect,
	confirmationEffect,
	logoutEffect,
	sendResetEmailEffect,
	resendConfirmationEmailEffect,
	resetStateEffect
);

export default authorisationEffects;
