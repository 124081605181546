import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import rootEffect from "../effects/root-effect";
import { ConnectedRouter } from "connected-react-router";
import { BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import configureStore, { history, epicMiddleware } from "./configure-store";
import { InitialiseRequested } from "../actions";
import { ThemeProvider } from "@material-ui/styles";
import theme from "theme/index";
import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./routes";
import SimpleSnackbarContainer from "features/notifications/components/simple-snackbar-container";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import DialogMessageContainer from "features/notifications/components/dialog-message-container";
import { Notifications } from "react-push-notification";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import { rollbarConfig } from "../rollbarConfig";

const store = configureStore();

let persistor = persistStore(store);

const epic$ = new BehaviorSubject(rootEffect);

const hotReloadingEpic = (...args) =>
	epic$.pipe(switchMap((epic) => epic(...args)));

epicMiddleware.run(hotReloadingEpic);

if (module.hot) {
	module.hot.accept("../effects/root-effect", () => {
		const nextRootEpic = require("../effects/root-effect").rootEffect;
		epic$.next(nextRootEpic);
	});
}

const App = () => {
	useEffect(() => {
		store.dispatch(InitialiseRequested());
		// eslint-disable-next-line
	}, []);

	return (
		<RollbarProvider config={rollbarConfig}>
			<ErrorBoundary>
				<ThemeProvider theme={theme}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<Provider store={store}>
							<PersistGate loading={null} persistor={persistor}>
								<ConnectedRouter history={history}>
									<Notifications />
									<DialogMessageContainer />
									<Routes />
								</ConnectedRouter>
								<CssBaseline />
								<SimpleSnackbarContainer />
							</PersistGate>
						</Provider>
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			</ErrorBoundary>
		</RollbarProvider>
	);
};

export default App;
