import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Accordion from "./accordion";
import { useRouter } from "utils";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		boxSizing: "border-box",
		margin: 0,
		padding: 0,
	},
	nestedContainer: {
		margin: theme.spacing(-1),
	},
	nestedItem: {
		padding: theme.spacing(1, 4),
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: theme.typography.pxToRem(14),
		"&:hover": {
			color: theme.palette.brand,
			cursor: "pointer",
		},
		"&:focus": {
			color: theme.palette.brand,
		},
	},
}));

const DropdownAccordion = ({ menu }) => {
	const classes = useStyles();
	const { history } = useRouter();

	const onNavigate = (link) => () => history.push(link);

	return (
		<div className={classes.root}>
			{menu.map(({ label, path, nested }) => (
				<Accordion title={label} isNested={!!nested}>
					<div className={classes.nestedContainer}>
						{nested &&
							nested.map((item) => (
								<Typography
									className={classes.nestedItem}
									onClick={onNavigate(item.path)}
								>
									{item.label}
								</Typography>
							))}
					</div>
				</Accordion>
			))}
			{/* <Accordion
        title="How do I track my order?"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Accordion
        title="Can I purchase items again?"
        content="
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </br>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </br>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        "
      /> */}
		</div>
	);
};

DropdownAccordion.propTypes = {
	menu: PropTypes.array.isRequired,
};

export default DropdownAccordion;
