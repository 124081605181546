import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { ClickAwayListener } from "@material-ui/core";
import { FormActions, FormButton, FormSubmitButton, Dialog } from "frame/components";
import { SiteForm } from "features/forms";
import moment from "moment";
import { TimesheetForm } from "./timesheet-form";
import { safetyWiseApi } from "utils/safetyWiseApi";
import { defaultState } from "../timesheet-form-reducer";

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		flexDirection: "row",
		padding: theme.spacing(3, 3, 10, 3)
	},
	actions: {
		marginTop: theme.spacing(3),
		paddingBottom: theme.spacing(8)
	},
	menu: {
		"padding": theme.spacing(3, 0, 0, 0),
		"& > *": {
			marginRight: theme.spacing(3)
		}
	}
}));

export const EditTimesheetForm = ({
	id,
	onClose,
	open,
	timesheetList,
	currentOrganisationGUID
}) => {
	const classes = useStyles();
	const [timesheetState, setTimesheetState] = useState(defaultState);
	const [saveAndCancelDisabled, setSaveAndCancelDisabled] = useState(false);

	useEffect(() => {
		if (!open) {
			return;
		}

		const timesheet = timesheetList && timesheetList.filter((t) => t.id === id)[0];

		if (timesheet) {
			setTimesheetState({
				siteId: timesheet.siteId,
				timesheet: {
					...timesheet,
					startDate: moment(timesheet.startDateTimeUTC).format("YYYY-MM-DD"),
					startTime: moment(timesheet.startDateTimeUTC).format("HH:mm"),
					lunchTime: timesheet.lunchTimeMinutes ? timesheet.lunchTimeMinutes.toString() : "0",
					endDate: timesheet.endDateTimeUTC
						? moment(timesheet.endDateTimeUTC).format("YYYY-MM-DD")
						: "",
					endTime: timesheet.endDateTimeUTC ? moment(timesheet.endDateTimeUTC).format("HH:mm") : ""
				},
				isValid: false,
				isEndDateTimeInvalid: false
			});
		}
	}, [open]);

	useEffect(() => {
		timesheetState.success && onClose();
	}, [timesheetState.success]);

	const onSubmit = (event) => {
		setSaveAndCancelDisabled(true);
		event.preventDefault();
		safetyWiseApi
			.post(currentOrganisationGUID + "/timesheet/update/" + timesheetState.timesheet.id, {
				siteId: timesheetState.siteId,
				staffId: timesheetState.timesheet.staffId,
				startDateTimeUTC: moment(
					timesheetState.timesheet.startDate + " " + timesheetState.timesheet.startTime,
					"YYYY-MM-DD HH:mm"
				).toISOString(),
				endDateTimeUTC: moment(
					timesheetState.timesheet.endDate + " " + timesheetState.timesheet.endTime,
					"YYYY-MM-DD HH:mm"
				).toISOString(),
				lunchTimeMinutes: parseInt(timesheetState.timesheet.lunchTime, 10),
				notes: timesheetState.timesheet.notes,
				startLatitude: timesheetState.timesheet.startLatitude,
				startLongitude: timesheetState.timesheet.startLongitude,
				endLatitude: timesheetState.timesheet.endLatitude,
				endLongitude: timesheetState.timesheet.endLongitude,
				organisationTaskTypeId: timesheetState.timesheet.organisationTaskTypeId
			})
			.then(() => {
				setSaveAndCancelDisabled(false);
				onClose();
			});
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<>
				<Dialog open={open} aria-labelledby="Timesheets" title="Edit Timesheet" onClose={onClose}>
					<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
						<div>
							<SiteForm
								values={timesheetState}
								onChange={setTimesheetState}
								hideDate
								disableLeaveTypes={false}
								isAdmin={true}
							/>
							{timesheetState.siteId && (
								<TimesheetForm
									values={timesheetState}
									onChange={setTimesheetState}
									fromEditTimesheetForm
								/>
							)}
						</div>
						<FormActions className={classes.actions}>
							<FormSubmitButton
								disabled={timesheetState.isEndDateTimeInvalid || saveAndCancelDisabled}
								loading={timesheetState.isLoading}
							>
								Save
							</FormSubmitButton>
							<FormButton
								disabled={timesheetState.isLoading || saveAndCancelDisabled}
								onClick={onClose}
							>
								Cancel
							</FormButton>
						</FormActions>
					</ValidatorForm>
				</Dialog>
			</>
		</ClickAwayListener>
	);
};
