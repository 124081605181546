import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import {
	formatDate,
	isNotNullOrEmpty,
	capitalizeFirstLetter,
	isStaffUser,
	useLookup,
} from "utils";
import {
	AttachmentListing,
	TextClip,
	PopupSnackbar,
	CrudOptions,
} from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
	},
	container: {
		margin: theme.spacing(0.5),
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	label: {
		padding: theme.spacing(0, 0, 0.5, 0),
		color: theme.palette.text.tertiary,
	},
	value: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		padding: theme.spacing(0, 0, 0.5, 0),
	},
}));

const JobCard = ({
	job,
	className,
	onEdit,
	onDelete,
	onEmail,
	currentUserId,
	role,
	hasNetwork,
	disableCrud,
	...rest
}) => {
	const classes = useStyles();

	const editable = isStaffUser(role) ? job.creatorId === currentUserId : true;

	const quickPicCustomer = useLookup(
		job.customerId,
		"customers",
		"customerName"
	);
	const quickPicSite = useLookup(job.siteId, "sites", "siteName");
	const quickPicStaff = useLookup(job.staffId, "staff", "fullName");

	if (!job) {
		return null;
	}

	return (
		<Card
			{...rest}
			data-cy={`job-${job.title}`}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<Grid className={classes.container} container spacing={2}>
					<Grid item md={2} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Title
							<i>{job.syncing ? " (syncing)" : ""}</i>
						</Typography>
						<Typography variant="h6" className={classes.value}>
							{job.title}
						</Typography>
					</Grid>
					<Grid item md={2} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Date
						</Typography>
						<Typography variant="h6" className={classes.value}>
							{formatDate(job.createdAt)}
						</Typography>
					</Grid>
					<Grid item md={1} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Type
						</Typography>
						<Typography variant="h6" className={classes.value}>
							{job.type}
						</Typography>
					</Grid>
					<Grid item md={1} xs={12}>
						<Typography variant="body2" className={classes.label}>
							{capitalizeFirstLetter(job.type)}
						</Typography>
						<Typography variant="h6" className={classes.value}>
							{job.type === "customer" && quickPicCustomer}
							{job.type === "site" && quickPicSite}
							{job.type === "staff" && quickPicStaff}
						</Typography>
					</Grid>
					<Grid item md={3} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Notes
						</Typography>
						<Typography variant="h6" className={classes.value}>
							<TextClip
								text={job.notes}
								numberOfCharacters={140}
							/>
						</Typography>
					</Grid>
					<Grid item md={2} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Attachments
						</Typography>
						{isNotNullOrEmpty(job.attachments) ? (
							<AttachmentListing attachments={job.attachments} />
						) : (
							"-"
						)}
					</Grid>
				</Grid>
			</CardContent>
			{editable && !disableCrud && (
				<CrudOptions
					onDelete={() => onDelete(job.id)}
					onEdit={() => onEdit(job.id)}
					onEmail={onEmail ? () => onEmail(job.id) : undefined}
				/>
			)}
		</Card>
	);
};

JobCard.propTypes = {
	className: PropTypes.string,
	job: PropTypes.object.isRequired,
	type: PropTypes.string,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	currentUserId: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	hasNetwork: PropTypes.bool.isRequired,
	disableCrud: PropTypes.bool,
};

export default JobCard;
