import { connect } from "react-redux";
import JobCustomerList from "./jobs-customer-list";
import { onEdit, onAdd, onDelete } from "../actions";

const mapStateToProps = ({ jobs, profile, queue }) => ({
	quickPicList: jobs.pagination?.results,
	isAddModalShowing: jobs.isAddModalShowing,
	role: profile.role,
	hasNetwork: queue.hasNetwork,
});

const mapDispatchToProps = {
	onAdd,
	onEdit,
	onDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobCustomerList);
