import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, TextField } from "@material-ui/core";
import { isNotNullOrEmpty } from "utils";

const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(2, 0),
	},
	divider: {
		display: "flex",
		margin: theme.spacing(2, 0),
		width: "100%",
		color: theme.palette.divider,
	},
}));

const PeopleOtherForm = ({
	className,
	heading,
	values,
	onChange,
	disabled,
}) => {
	const classes = useStyles();
	const disabledField = disabled || values.locked;
	const [personCount, setPersonCount] = useState(
		(values.otherPeople && values.otherPeople.length) || 1
	);
	const disableAdd =
		isNotNullOrEmpty(values.otherPeople) &&
		values.otherPeople.filter((x) => x).length !== personCount;
	const otherPeople = values.otherPeople || [];

	const cleanResults = () => {
		const next = Array.from(new Set(values.otherPeople)).filter((x) => x);

		if (personCount > next.length + 1) {
			setPersonCount(next.length + 1);
		}

		onChange({
			...values,
			otherPeople: next,
		});
	};

	useEffect(() => {
		const inputs = document.querySelectorAll(`input[id^='person_name_']`);

		inputs.forEach((input) => {
			input.onblur = () => {
				cleanResults();
			};
		});

		return () => {
			inputs.forEach((input) => {
				input.onblur = null;
			});
		};
	});

	const onAddPerson = () => {
		if (otherPeople.length === personCount) {
			setPersonCount(personCount + 1);
		}
	};

	const onInputChange = useCallback(
		(key, index) =>
			({ target: { value } }) => {
				const next = values.otherPeople ? [...values.otherPeople] : [];
				next[index] = value;

				onChange({
					...values,
					otherPeople: next,
				});
			},
		[values, onChange]
	);

	const renderOtherPeople = () => {
		const nodes = [];
		for (let i = 0; i < personCount; i++) {
			nodes.push(
				<Grid key={`person_name_${i}`} item xs={12}>
					<TextField
						data-cy="other_staff"
						id={`person_name_${i}`}
						disabled={disabledField}
						fullWidth
						label={heading ? heading : "Add other people"}
						placeholder={heading ? heading : "Add other people"}
						value={otherPeople[i] || ""}
						variant="outlined"
						onChange={onInputChange("name", i)}
					/>
				</Grid>
			);
		}
		return nodes;
	};

	return (
		<>
			<Grid container spacing={2}>
				{renderOtherPeople()}
			</Grid>
			{!(disableAdd || disabledField) && (
				<Button
					color="primary"
					className={classes.button}
					disabled={disableAdd || disabledField}
					size="small"
					variant="outlined"
					onClick={onAddPerson}
				>
					+ Add another
				</Button>
			)}
		</>
	);
};

PeopleOtherForm.propTypes = {
	className: PropTypes.string,
	heading: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default PeopleOtherForm;
