import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid, Chip } from "@material-ui/core";
import { capitalize } from "utils";
import { formatDate } from "utils";

import {
	SectionCard,
	AttachmentsSectionCard,
	ValueText,
} from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {},
	status: {
		display: "flex",
	},
	chip: {
		marginLeft: theme.spacing(1),
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
}));

const ReadOnlyForm = ({ values }) => {
	const classes = useStyles();
	const substance = values && values.substances;

	return (
		<>
			<SectionCard title="Hazardous Substance Details">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Date"
							value={formatDate(values.createdAt)}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Name"
							value={substance.name ? substance.name : "-"}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Substance Type"
							value={substance.substanceType || "-"}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Volume Measurement"
							value={substance.volumeMeasurement || "-"}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Supplier SDS Report Held"
							value={
								substance.supplierSDSReportHeld
									? capitalize(
											substance.supplierSDSReportHeld
									  )
									: "-"
							}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Protective Clothing Required"
							value={
								substance.protectiveClothingRequired
									? capitalize(
											substance.protectiveClothingRequired
									  )
									: "-"
							}
							options={{ bold: true, isLongLabel: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Action Recommended"
							value={
								substance.actionRecommended
									? substance.actionRecommended
									: "-"
							}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Status"
							value={
								substance.status ? (
									<Chip
										className={clsx(
											classes.chip,
											substance.status === "Active"
												? classes.success
												: classes.error
										)}
										size="small"
										label={substance.status}
									/>
								) : (
									"-"
								)
							}
							options={{ bold: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			<AttachmentsSectionCard values={values} />
		</>
	);
};

ReadOnlyForm.propTypes = {
	values: PropTypes.object.isRequired,
};

export default ReadOnlyForm;
