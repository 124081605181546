import { ofType } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { tokenExists, authHeaders, getOrganisationBaseUrl } from "frame";
import {SUBSCRIPTION_UPDATE_PAYMENT_SAVE } from "../actions/types";
import {
	subscriptionUpdatePaymentSaveSuccess,
	subscriptionUpdatePaymentSaveFail,
	onAddPaymentClose,
	subscriptionFetch,
} from "../actions";

export const updatePaymentEffect = (action$, state$) =>
	action$.pipe(
		ofType(SUBSCRIPTION_UPDATE_PAYMENT_SAVE),
		filter(() => tokenExists()),
		mergeMap(({ payload }) => {
			const cardToken = payload.token;

			return ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/update-payment-method`,
					{
						creditCardToken: {
							id: cardToken.id,
							client_ip: cardToken.client_ip,
							created: cardToken.created,
							livemode: cardToken.livemode,
							type: cardToken.type,
							used: cardToken.used,
							card: {
								id: cardToken.card.id,
								address_city:
									cardToken.card.address_city,
								address_country:
									cardToken.card.address_country,
								address_line1:
									cardToken.card.address_line1,
								address_line1_check:
									cardToken.card
										.address_line1_check,
								address_line2:
									cardToken.card.address_line2,
								address_state:
									cardToken.card.address_state,
								address_zip:
									cardToken.card.address_zip,
								address_zip_check:
									cardToken.card
										.address_zip_check,
								brand: cardToken.card.brand,
								country: cardToken.card.country,
								cvc_check: cardToken.card.cvc_check,
								exp_month: cardToken.card.exp_month,
								exp_year: cardToken.card.exp_year,
								fingerprint:
									cardToken.card.fingerprint,
								funding: cardToken.card.funding,
								last4: cardToken.card.last4,
								name: cardToken.card.name,
								tokenization_method:
									cardToken.card
										.tokenization_method,
							},
						},
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							subscriptionUpdatePaymentSaveSuccess(
								ajaxResponse.response
							),
							onAddPaymentClose(),
							subscriptionFetch()
						)
					),
					catchError((error) => {
						return of(subscriptionUpdatePaymentSaveFail(error.response));
					}
					)
				);
		})
	);
