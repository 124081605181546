import { connect } from "react-redux";
import Account from "./account";

const mapStateToProps = ({ profile }) => ({
	role: profile.role,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
