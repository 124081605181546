import * as fromTypes from "./types";

// Incidents
export const onAddAccidentValueChange = (payload) => ({
	type: fromTypes.ADD_INCIDENT_FORM_VALUE_CHANGE,
	payload,
});

export const onAddAccidentReset = (payload) => ({
	type: fromTypes.ADD_INCIDENT_FORM_RESET,
	payload,
});

export const onAddAccidentSave = (payload) => ({
	type: fromTypes.ADD_INCIDENT_FORM_SUBMIT,
	payload,
});

export const onAddAccidentSaveFailed = (payload) => ({
	type: fromTypes.ADD_INCIDENT_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddAccidentSaveSuccess = (payload) => ({
	type: fromTypes.ADD_INCIDENT_FORM_SUBMIT_SUCCESS,
	payload,
});

// Hazards
export const onAddHazardValueChange = (payload) => ({
	type: fromTypes.ADD_HAZARD_FORM_VALUE_CHANGE,
	payload,
});

export const onAddHazardReset = (payload) => ({
	type: fromTypes.ADD_HAZARD_FORM_RESET,
	payload,
});

export const onAddHazardSave = (payload) => ({
	type: fromTypes.ADD_HAZARD_FORM_SUBMIT,
	payload,
});

export const onAddHazardSaveFailed = (payload) => ({
	type: fromTypes.ADD_HAZARD_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddHazardSaveSuccess = (payload) => ({
	type: fromTypes.ADD_HAZARD_FORM_SUBMIT_SUCCESS,
	payload,
});

export const onArchiveHazard = (payload) => ({
	type: fromTypes.ARCHIVED_HAZARD,
	payload,
});

// Hazardous Substance
export const onAddHazardousSubstanceValueChange = (payload) => ({
	type: fromTypes.ADD_HAZARDOUS_SUBSTANCE_FORM_VALUE_CHANGE,
	payload,
});

export const onAddHazardousSubstanceReset = (payload) => ({
	type: fromTypes.ADD_HAZARDOUS_SUBSTANCE_FORM_RESET,
	payload,
});

export const onAddHazardousSubstanceSave = (payload) => ({
	type: fromTypes.ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT,
	payload,
});

export const onAddHazardousSubstanceSaveFailed = (payload) => ({
	type: fromTypes.ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddHazardousSubstanceSaveSuccess = (payload) => ({
	type: fromTypes.ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT_SUCCESS,
	payload,
});

export const onArchiveHazardousSubstance = (payload) => ({
	type: fromTypes.ARCHIVED_HAZARDOUS_SUBSTANCE,
	payload,
});

// Safety Plan Checklist
export const onAddSafetyPlanChecklistValueChange = (payload) => ({
	type: fromTypes.ADD_SAFETY_PLAN_CHECKLIST_FORM_VALUE_CHANGE,
	payload,
});

export const onAddSafetyPlanChecklistReset = (payload) => ({
	type: fromTypes.ADD_SAFETY_PLAN_CHECKLIST_FORM_RESET,
	payload,
});

export const onAddSafetyPlanChecklistSave = (payload) => ({
	type: fromTypes.ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT,
	payload,
});

export const onAddSafetyPlanChecklistSaveFailed = (payload) => ({
	type: fromTypes.ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddSafetyPlanChecklistSaveSuccess = (payload) => ({
	type: fromTypes.ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT_SUCCESS,
	payload,
});

// JSA
export const onAddTasksValueChange = (payload) => ({
	type: fromTypes.ADD_JSA_FORM_VALUE_CHANGE,
	payload,
});

export const onAddTasksReset = (payload) => ({
	type: fromTypes.ADD_JSA_FORM_RESET,
	payload,
});

export const onAddTasksSave = (payload) => ({
	type: fromTypes.ADD_JSA_FORM_SUBMIT,
	payload,
});

export const onAddTasksSaveFailed = (payload) => ({
	type: fromTypes.ADD_JSA_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddTasksSaveSuccess = (payload) => ({
	type: fromTypes.ADD_JSA_FORM_SUBMIT_SUCCESS,
	payload,
});

// Induction Minutes
export const onAddInductionMinutesValueChange = (payload) => ({
	type: fromTypes.ADD_INDUCTION_MINUTES_FORM_VALUE_CHANGE,
	payload,
});

export const onAddInductionMinutesReset = (payload) => ({
	type: fromTypes.ADD_INDUCTION_MINUTES_FORM_RESET,
	payload,
});

export const onAddInductionMinutesSave = (payload) => ({
	type: fromTypes.ADD_INDUCTION_MINUTES_FORM_SUBMIT,
	payload,
});

export const onAddInductionMinutesSaveFailed = (payload) => ({
	type: fromTypes.ADD_INDUCTION_MINUTES_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddInductionMinutesSaveSuccess = (payload) => ({
	type: fromTypes.ADD_INDUCTION_MINUTES_FORM_SUBMIT_SUCCESS,
	payload,
});

// Safety Minutes
export const onAddSafetyMinutesValueChange = (payload) => ({
	type: fromTypes.ADD_SAFETY_MINUTES_FORM_VALUE_CHANGE,
	payload,
});

export const onAddSafetyMinutesReset = (payload) => ({
	type: fromTypes.ADD_SAFETY_MINUTES_FORM_RESET,
	payload,
});

export const onAddSafetyMinutesSave = (payload) => ({
	type: fromTypes.ADD_SAFETY_MINUTES_FORM_SUBMIT,
	payload,
});

export const onAddSafetyMinutesSaveFailed = (payload) => ({
	type: fromTypes.ADD_SAFETY_MINUTES_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddSafetyMinutesSaveSuccess = (payload) => ({
	type: fromTypes.ADD_SAFETY_MINUTES_FORM_SUBMIT_SUCCESS,
	payload,
});

// Staff Training
export const onAddStaffTrainingValueChange = (payload) => ({
	type: fromTypes.ADD_STAFF_TRAINING_FORM_VALUE_CHANGE,
	payload,
});

export const onAddStaffTrainingReset = (payload) => ({
	type: fromTypes.ADD_STAFF_TRAINING_FORM_RESET,
	payload,
});

export const onAddStaffTrainingSave = (payload) => ({
	type: fromTypes.ADD_STAFF_TRAINING_FORM_SUBMIT,
	payload,
});

export const onAddStaffTrainingSaveFailed = (payload) => ({
	type: fromTypes.ADD_STAFF_TRAINING_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddStaffTrainingSaveSuccess = (payload) => ({
	type: fromTypes.ADD_STAFF_TRAINING_FORM_SUBMIT_SUCCESS,
	payload,
});

// Staff Training Topic
export const onAddStaffTrainingTopicValueChange = (payload) => ({
	type: fromTypes.ADD_STAFF_TRAINING_TOPIC_FORM_VALUE_CHANGE,
	payload,
});

export const onAddStaffTrainingTopicReset = (payload) => ({
	type: fromTypes.ADD_STAFF_TRAINING_TOPIC_FORM_RESET,
	payload,
});

export const onAddStaffTrainingTopicSave = (payload) => ({
	type: fromTypes.ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT,
	payload,
});

export const onAddStaffTrainingTopicSaveFailed = (payload) => ({
	type: fromTypes.ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddStaffTrainingTopicSaveSuccess = (payload) => ({
	type: fromTypes.ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT_SUCCESS,
	payload,
});
