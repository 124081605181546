import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { InfoHome } from "./info-home";
import { types as fromHealthAndSafety } from "features/health-and-safety";
import * as fromHealthSafetyForm from "features/health-safety-forms";
import { connect } from "react-redux";
import {
	onClearSearch,
	onHazardListRequest,
	onHazardousSubstanceListRequest,
	onStaffTrainingTopicListRequest,
	onJobSafetyAnalysisListRequest,
	onSafetyPlanChecklistListRequest,
} from "features/health-and-safety/actions";

// form components
import {
	AddHazardForm,
	AddHazardousSubstanceForm,
	AddSafetyPlanChecklistForm,
	AddJobSafetyAnalysisForm,
	AddStaffTrainingTopicForm,
} from "features/health-safety-forms";

// list components
import {
	StaffTrainingTopicList,
	HazardList,
	HazardousSubstancesList,
	JobSafetyAnalysisList,
	SafetyPlanChecklistList,
} from "features/health-and-safety/components";


const mapStateToProps = () => ({});

const mapDispatchToProps = {
	onClearSearch: onClearSearch,
	onGetHazardsList: onHazardListRequest,
	onGetHazardousSubstanceList: onHazardousSubstanceListRequest,
	onGetStaffTrainingTopicList: onStaffTrainingTopicListRequest,
	onGetJobSafetyAnalysisList: onJobSafetyAnalysisListRequest,
	onGetSafetyPlanChecklistList: onSafetyPlanChecklistListRequest,
};

export const InfoRoutes = connect(mapStateToProps, mapDispatchToProps)((props) => {
	useEffect(() => {
		props.onClearSearch();
		// Info
		props.onGetHazardsList();
		props.onGetHazardousSubstanceList();
		props.onGetStaffTrainingTopicList();
		props.onGetJobSafetyAnalysisList();
		props.onGetSafetyPlanChecklistList();
		// eslint-disable-next-line
	}, []);

	return (
		<Switch>
			<Route exact path="/" component={InfoHome} />
			<Route
				exact
				path={fromHealthAndSafety.INFO_ROUTE}
				component={InfoHome}
			/>

			{/* info routes */}
			<Route
				exact
				path={fromHealthAndSafety.INFO_HAZARDS_ROUTE}
				component={HazardList}
			/>
			<Route
				exact
				path={fromHealthSafetyForm.ADD_HAZARD_ROUTE}
				component={AddHazardForm}
			/>

			<Route
				exact
				path={fromHealthAndSafety.INFO_HAZARDOUS_SUBSTANCES_ROUTE}
				component={HazardousSubstancesList}
			/>
			<Route
				exact
				path={fromHealthSafetyForm.ADD_HAZARDOUS_SUBSTANCE_ROUTE}
				component={AddHazardousSubstanceForm}
			/>

			<Route
				exact
				path={fromHealthAndSafety.INFO_STAFF_TRAINING_TOPIC_ROUTE}
				component={StaffTrainingTopicList}
			/>
			<Route
				exact
				path={fromHealthSafetyForm.ADD_STAFF_TRAINING_TOPIC_ROUTE}
				component={AddStaffTrainingTopicForm}
			/>

			<Route
				exact
				path={fromHealthSafetyForm.ADD_JSA_ROUTE}
				component={AddJobSafetyAnalysisForm}
			/>
			<Route
				exact
				path={fromHealthAndSafety.INFO_JOB_SAFETY_ANALYSIS_ROUTE}
				component={JobSafetyAnalysisList}
			/>

			<Route
				exact
				path={fromHealthSafetyForm.ADD_SAFETY_PLAN_CHECKLIST_ROUTE}
				component={AddSafetyPlanChecklistForm}
			/>
			<Route
				exact
				path={fromHealthAndSafety.INFO_SAFETY_PLAN_CHECKLISTS_ROUTE}
				component={SafetyPlanChecklistList}
			/>
			{/* end of info routes */}
		</Switch>
	);
});