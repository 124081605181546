import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";

const mapStateToProps = ({
	addJobSafetyAnalysisFormReducer,
	healthSafety,
}) => ({
	values: addJobSafetyAnalysisFormReducer,
	responseMessage: addJobSafetyAnalysisFormReducer.responseMessage,
	jsaList: healthSafety.jobSafetyAnalysis?.results,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddTasksValueChange,
	onReset: fromActions.onAddTasksReset,
	onSave: fromActions.onAddTasksSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
