import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextValidator } from "react-material-ui-form-validator";
// import { useTheme } from '@material-ui/styles'
// import { useMediaQuery } from '@material-ui/core'

const SearchDropdown = ({
	id,
	defaultOptions = [],
	disabled,
	label,
	value,
	onChange,
	props,
	readOnly = false
}) => {
	// const theme = useTheme()
	// const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	// const [options, setOptions] = useState(defaultOptions)

	const onSelect = useCallback(
		(_, input) => {
			if (onChange && input) {
				onChange(input.value);
			} else {
				onChange("");
			}
		},
		[onChange]
	);

	// const onCreateOptions = useCallback(async (input) => {
	//   setOptions(defaultOptions)
	// }, [setOptions, defaultOptions])

	// const onInputChange = useCallback(({ target }) => {
	//   const input = target.value || ''

	//   if (input.length > 0) {
	//       onCreateOptions(input)
	//   }
	//   else {
	//     // setOptions([])
	//   }
	// }, [setOptions, onCreateOptions])

	return (
		<div>
			<Autocomplete
				id={id || "search_dropdown"}
				options={defaultOptions}
				disabled={disabled}
				onChange={onSelect}
				getOptionLabel={(option) => option && option.label}
				value={(defaultOptions && defaultOptions.find((o) => o.value === value)) || ""}
				renderInput={(params) => {
					if (readOnly) {
						return (
							<TextValidator
								{...props}
								{...params}
								label={label}
								variant="outlined"
								InputProps={{ ...params.InputProps, readOnly: true }}
								// onChange={onInputChange}
							/>
						);
					} else {
						return (
							<TextValidator
								{...props}
								{...params}
								label={label}
								variant="outlined"
								// onChange={onInputChange}
							/>
						);
					}
				}}
			/>
		</div>
	);
};

SearchDropdown.propTypes = {
	id: PropTypes.string,
	defaultOptions: PropTypes.array,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	value: PropTypes.string,
	label: PropTypes.string
};

export default SearchDropdown;
