import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import * as fromFormRoutes from "features/health-safety-forms";
import HealthSafetyHomeContainer from "./health-safety-home-container";
import IncidentListContainer from "./lists/incident-list-container";
import HazardListContainer from "./lists/hazard-list-container";
import HazardousSubstanceListContainer from "./lists/hazardous-substance-list-container";
import InductionMinutesListContainer from "./lists/induction-minutes-list-container";
import SafetyMinutesListContainer from "./lists/safety-minutes-list-container";
import JobSafetyAnalysisListContainer from "./lists/job-safety-analysis-list-container";
import SafetyPlanChecklistListContainer from "./lists/safety-plan-checklist-list-container";
import StaffTrainingListContainer from "./lists/staff-training-list-container";
import InductionMinutesConfirmAttendanceContainer from "./induction-minutes-confirm-attendance-container";
import StaffTrainingTopicListContainer from "./lists/staff-training-topic-list-container";
import fromRoutes from "../actions/types";

import {
	AddIncidentForm,
	AddHazardForm,
	AddHazardousSubstanceForm,
	AddSafetyPlanChecklistForm,
	AddJobSafetyAnalysisForm,
	AddInductionMinutesForm,
	AddSafetyMinutesForm,
	AddStaffTrainingForm,
	AddStaffTrainingTopicForm,
} from "features/health-safety-forms";

const HealthSafetyRoutes = () => {

	// old health & safety routes
	return (
		<Switch>
			<Route
				exact
				path={fromRoutes.HEALTH_SAFETY_ROUTE}
				component={HealthSafetyHomeContainer}
			/>
			<Route
				path={fromRoutes.HEALTH_SAFETY_INCIDENTS_ROUTE}
				component={IncidentListContainer}
			/>
			<Route
				path={fromRoutes.HEALTH_SAFETY_HAZARDS_ROUTE}
				component={HazardListContainer}
			/>
			<Route
				path={fromRoutes.HEALTH_SAFETY_HAZARDOUS_SUBSTANCES_ROUTE}
				component={HazardousSubstanceListContainer}
			/>
			<Route
				exact
				path={fromRoutes.HEALTH_SAFETY_INDUCTION_MINUTES_ROUTE}
				component={InductionMinutesListContainer}
			/>
			<Route
				path={fromRoutes.HEALTH_SAFETY_SAFETY_MINUTES_ROUTE}
				component={SafetyMinutesListContainer}
			/>
			<Route
				path={fromRoutes.HEALTH_SAFETY_JOB_SAFETY_ANALYSIS_ROUTE}
				component={JobSafetyAnalysisListContainer}
			/>
			<Route
				path={fromRoutes.HEALTH_SAFETY_SAFETY_PLAN_CHECKLISTS_ROUTE}
				component={SafetyPlanChecklistListContainer}
			/>
			<Route
				path={fromRoutes.HEALTH_SAFETY_STAFF_TRAINING_ROUTE}
				component={StaffTrainingListContainer}
			/>
			<Route
				path={fromRoutes.HEALTH_SAFETY_STAFF_TRAINING_TOPIC_ROUTE}
				component={StaffTrainingTopicListContainer}
			/>

			<Route
				path={fromFormRoutes.OLD_ADD_INCIDENT_ROUTE}
				component={AddIncidentForm}
			/>
			<Route
				path={fromFormRoutes.OLD_ADD_HAZARD_ROUTE}
				component={AddHazardForm}
			/>
			<Route
				path={fromFormRoutes.OLD_ADD_HAZARDOUS_SUBSTANCE_ROUTE}
				component={AddHazardousSubstanceForm}
			/>
			<Route
				path={fromFormRoutes.OLD_ADD_SAFETY_PLAN_CHECKLIST_ROUTE}
				component={AddSafetyPlanChecklistForm}
			/>
			<Route
				path={fromFormRoutes.OLD_ADD_JSA_ROUTE}
				component={AddJobSafetyAnalysisForm}
			/>
			<Route
				path={fromFormRoutes.INDUCTION_MINUTES_CONFIRM_ATTENDANCE_ROUTE}
				component={InductionMinutesConfirmAttendanceContainer}
			/>
			<Route
				path={fromFormRoutes.OLD_ADD_INDUCTION_MINUTES_ROUTE}
				component={AddInductionMinutesForm}
			/>
			<Route
				path={fromFormRoutes.OLD_ADD_SAFETY_MINUTES_ROUTE}
				component={AddSafetyMinutesForm}
			/>
			<Route
				path={fromFormRoutes.OLD_ADD_STAFF_TRAINING_ROUTE}
				component={AddStaffTrainingForm}
			/>
			<Route
				path={fromFormRoutes.OLD_ADD_STAFF_TRAINING_TOPIC_ROUTE}
				component={AddStaffTrainingTopicForm}
			/>
		</Switch>
	);
};

HealthSafetyRoutes.propTypes = {
	onGetIncidentList: PropTypes.func,
	onGetHazardsList: PropTypes.func.isRequired,
	onGetTimesheetList: PropTypes.func.isRequired,
	onGetHazardousSubstanceList: PropTypes.func.isRequired,
	onGetInductionMinutesList: PropTypes.func.isRequired,
	onGetSafetyMinutesList: PropTypes.func.isRequired,
	onGetJobSafetyAnalysisList: PropTypes.func.isRequired,
	onGetSafetyPlanChecklistList: PropTypes.func.isRequired,
	onGetStaffTrainingList: PropTypes.func.isRequired,
	onGetStaffTrainingTopicList: PropTypes.func.isRequired,
	onClearSearch: PropTypes.func.isRequired,
};

export default HealthSafetyRoutes;
