import React, { useEffect } from "react";
// import { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";

const Logout = ({ onLogout }) => {
	useEffect(() => {
		onLogout();
	}, [onLogout]);

	return <div>Logout</div>;
};

Logout.propTypes = {
	onLogout: PropTypes.func.isRequired,
};

export default Logout;
