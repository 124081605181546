import jobModel from "./job-model";
import { defaultFlagState, defaultPaginationState } from "utils";

export default {
	currentJob: jobModel,
	isAddModalShowing: false,
	isEditModalShowing: false,
	isNew: true,
	searchFilter: "",
	pagination: defaultPaginationState,
	flags: defaultFlagState,
	globalMessage: [],
	columnName: "siteId",
	columnValue: "",
};