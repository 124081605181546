export const UNCOMFIRMED_ATTENDANCE_ROUTE = `/uncomfirmed-attendance`;
export const UNCOMFIRMED_ATTENDANCE_PATH = `/uncomfirmed-attendance`;

export const FETCH_UNCONFIRMED_INDUCTIONS = `FETCH_UNCONFIRMED_INDUCTIONS`;
export const FETCH_UNCONFIRMED_INDUCTIONS_ERROR = `FETCH_UNCONFIRMED_INDUCTIONS_ERROR`;
export const FETCH_UNCONFIRMED_INDUCTIONS_SUCCESS = `FETCH_UNCONFIRMED_INDUCTIONS_SUCCESS`;

export const GET_DOCUMENT_PACK_COUNT = `GET_DOCUMENT_PACK_COUNT`;
export const GET_DOCUMENT_PACK_COUNT_SUCCESS = `GET_DOCUMENT_PACK_COUNT_SUCCESS`;

export const GET_TIMESHEET_COUNT = `GET_TIMESHEET_COUNT`;
export const GET_TIMESHEET_COUNT_SUCCESS = `GET_TIMESHEET_COUNT_SUCCESS`;

export const GET_ENTITY_COUNTS_ERROR = `GET_ENTITY_COUNTS_ERROR`;

export const onFetchUnconfirmedInductions = (payload) => ({
	type: FETCH_UNCONFIRMED_INDUCTIONS,
	payload,
});

export const onFetchUnconfirmedInductionsErrored = (payload) => ({
	type: FETCH_UNCONFIRMED_INDUCTIONS_ERROR,
	payload,
});

export const onFetchUnconfirmedInductionsSuccess = (payload) => ({
	type: FETCH_UNCONFIRMED_INDUCTIONS_SUCCESS,
	payload,
});

export const onGetDocumentPackCount = (payload) => ({
	type: GET_DOCUMENT_PACK_COUNT,
	payload,
});

export const onGetDocumentPackCountSuccess = (payload) => ({
	type: GET_DOCUMENT_PACK_COUNT_SUCCESS,
	payload,
});

export const onGetTimesheetCount = (payload) => ({
	type: GET_TIMESHEET_COUNT,
	payload,
});

export const onGetTimesheetCountSuccess = (payload) => ({
	type: GET_TIMESHEET_COUNT_SUCCESS,
	payload,
});

export const onGetEntityCountsError = (payload) => ({
	type: GET_ENTITY_COUNTS_ERROR,
	payload,
});

