import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { isNotNullOrEmpty } from "utils";
import {
	Modal,
	Card,
	CardContent,
	Grid,
	Typography,
	TextField,
} from "@material-ui/core";
import {
	ValidatorForm,
	TextValidator,
	SelectValidator,
} from "react-material-ui-form-validator";
import { FormActions, FormButton, FormSubmitButton } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		justifyContent: "flex-end",
		marginTop: theme.spacing(3),
	},
	warning: {
		padding: theme.spacing(1, 0),
		color: theme.palette.error.main,
	},
	emptyOption: {
		display: "none",
	},
}));

const SiteEdit = ({
	onSiteNameChanged,
	onCustomerNameChanged,
	onAddressChanged,
	onSuburbChanged,
	onTownCityChanged,
	onPostCodeChanged,
	onSiteContactPersonChanged,
	onSiteContactPhoneChanged,
	onSiteContactEmailChanged,
	onStatusChanged,
	site,
	isEditModalShowing,
	isAddModalShowing,
	onCloseClicked,
	onSaveClicked,
	className,
	role,
	customerList,
	...rest
}) => {
	const classes = useStyles();

	const onSubmit = () => onSaveClicked();

	const customerOptions =
		customerList &&
		customerList
			.filter((customer) => {
				return customer.status &&
					customer.status.toLowerCase() !== "active"
					? false
					: true;
			})
			.map((customer) => ({
				label: customer.customerName,
				value: customer.id,
			}))
			.sort((a, b) => (a.label > b.label ? 1 : -1));

	if (!isEditModalShowing && !isAddModalShowing) {
		return null;
	}

	return (
		<Modal open={true} onClose={onCloseClicked}>
			<Card {...rest} className={clsx(classes.root, className)}>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<CardContent>
						<Typography align="center" gutterBottom variant="h3">
							{isAddModalShowing ? "Add" : "Edit"} Site
						</Typography>
						<Grid
							className={classes.container}
							container
							spacing={3}
						>
							<Grid item md={12} xs={12}>
								<TextValidator
									fullWidth
									label="Site name *"
									name="siteName"
									value={site.siteName}
									variant="outlined"
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={(e) =>
										onSiteNameChanged(e.target.value)
									}
									inputProps={{ maxLength: 100 }}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<SelectValidator
									fullWidth
									label="Customer *"
									name="customer"
									select
									SelectProps={{ native: true }}
									value={site.customerId || ""}
									variant="outlined"
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={(e) =>
										onCustomerNameChanged(e.target.value)
									}
								>
									<option
										value=""
										className={classes.emptyOption}
										disabled
									/>
									{isNotNullOrEmpty(customerOptions) &&
										customerOptions.map(
											({ label, value }, index) => (
												<option
													key={index}
													value={value}
												>
													{label}
												</option>
											)
										)}
								</SelectValidator>
							</Grid>
							<Grid item md={12} xs={12}>
								<TextField
									fullWidth
									label="Site Address"
									name="address"
									value={site.address}
									variant="outlined"
									onChange={(e) =>
										onAddressChanged(e.target.value)
									}
									inputProps={{ maxLength: 100 }}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Suburb"
									name="suburb"
									value={site.suburb}
									variant="outlined"
									onChange={(e) =>
										onSuburbChanged(e.target.value)
									}
									inputProps={{ maxLength: 50 }}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Town/City"
									name="townCity"
									value={site.townCity}
									variant="outlined"
									onChange={(e) =>
										onTownCityChanged(e.target.value)
									}
									inputProps={{ maxLength: 50 }}
								/>
							</Grid>
							<Grid item md={12} xs={12}>
								<TextField
									fullWidth
									label="Site Contact Person"
									name="siteContactPerson"
									value={site.siteContactPerson}
									variant="outlined"
									onChange={(e) =>
										onSiteContactPersonChanged(
											e.target.value
										)
									}
									inputProps={{ maxLength: 50 }}
								/>
							</Grid>
							<Grid item md={12} xs={12}>
								<TextValidator
									fullWidth
									label="Site Contact Phone"
									name="siteContactPhone"
									value={site.siteContactPhone}
									variant="outlined"
									validators={["isNumber"]}
									errorMessages={[
										"Must be a valid phone number",
									]}
									onChange={(e) =>
										onSiteContactPhoneChanged(
											e.target.value
										)
									}
									inputProps={{ maxLength: 50 }}
								/>
							</Grid>
							<Grid item md={12} xs={12}>
								<TextValidator
									fullWidth
									label="Site Contact Email"
									name="siteContactEmail"
									value={site.siteContactEmail}
									variant="outlined"
									validators={["isEmail"]}
									errorMessages={["Must be a valid email"]}
									onChange={(e) =>
										onSiteContactEmailChanged(
											e.target.value
										)
									}
									inputProps={{ maxLength: 50 }}
								/>
							</Grid>
							<Grid item sm={6} xs={12}>
								<TextField
									fullWidth
									label="Status"
									name="status"
									onChange={(e) =>
										onStatusChanged(e.target.value)
									}
									select
									SelectProps={{ native: true }}
									value={site.status}
									variant="outlined"
								>
									<option value="Active">Active</option>
									<option value="Inactive">Inactive</option>
								</TextField>
								{site.status === "Inactive" && (
									<Typography
										className={classes.warning}
										variant="body1"
									>
										<strong>Please note</strong> that
										changing the site status to {'"'}
										Inactive{'"'} will disable the Site.
										These can be reactivated later when
										required.
									</Typography>
								)}
							</Grid>
						</Grid>
						<FormActions className={classes.actions}>
							<FormSubmitButton>Save</FormSubmitButton>
							<FormButton onClick={onCloseClicked}>
								Cancel
							</FormButton>
						</FormActions>
					</CardContent>
				</ValidatorForm>
			</Card>
		</Modal>
	);
};

SiteEdit.propTypes = {
	className: PropTypes.string,
	site: PropTypes.object,
	onCloseClicked: PropTypes.func,
	isAddModalShowing: PropTypes.bool,
	isEditModalShowing: PropTypes.bool,
	onSiteNameChanged: PropTypes.func.isRequired,
	onCustomerNameChanged: PropTypes.func.isRequired,
	onAddressChanged: PropTypes.func.isRequired,
	onSuburbChanged: PropTypes.func.isRequired,
	onTownCityChanged: PropTypes.func.isRequired,
	onPostCodeChanged: PropTypes.func.isRequired,
	onSiteContactPersonChanged: PropTypes.func.isRequired,
	onSiteContactPhoneChanged: PropTypes.func.isRequired,
	onSiteContactEmailChanged: PropTypes.func.isRequired,
	onStatusChanged: PropTypes.func.isRequired,
	onSaveClicked: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
	customerList: PropTypes.array.isRequired,
};

export default SiteEdit;
