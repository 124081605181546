import React from "react";
import { Route, Switch } from "react-router-dom";
// import PropTypes from 'prop-types'
import * as fromRoutes from "../actions/types";
import Organisations from "./organisation-list";

const OrganisationRoutes = () => {
	return (
		<Switch>
			<Route
				exact
				path={fromRoutes.ORGANISATIONS_ROUTE}
				component={Organisations}
			/>
		</Switch>
	);
};

// OrganisationRoutes.propTypes = {
// }

export default OrganisationRoutes;
