import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { HazardDetailsDialog } from "frame/components";

const RedCheckbox = withStyles((theme) => ({
	root: {
		"&$disabled": {
			color: theme.palette.error.main,
		},
	},
	checked: {},
	disabled: {},
}))(Checkbox);

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0.5, 0),
	},
	label: {
		minWidth: 250,
		marginTop: theme.spacing(1.5),
		paddingRight: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			paddingRight: theme.spacing(0),
			minWidth: 140,
		},
	},
	link: {
		marginTop: theme.spacing(1.5),
		color: theme.palette.text.link,
		cursor: "pointer",
	},
	guidelines: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-end",
	},
	error: {
		color: theme.palette.error.main,
	},
}));

const SelectHazard = ({
	hazardId,
	hazardList,
	disabled,
	values,
	onChange,
	editMode,
	showError,
}) => {
	const classes = useStyles();
	const [loaded, setLoaded] = useState(false);

	const [checkBoxToggle, setCheckBoxToggle] = useState(false);
	const [data, setData] = useState(false);
	const [seenGuidelines, setSeenGuidelines] = useState(true);

	const selectedHazard =
		hazardList && hazardList.filter((hazard) => hazard.id === hazardId)[0];

	useEffect(() => {
		// only runs once on load to keep existing hazards checked in editMode on load
		// then afterwards uses next useEffect to reset hazards on siteId change
		setLoaded(true);

		if (editMode) {
			const isExistingHazard = JSON.stringify(values.hazards).includes(
				hazardId
			);
			if (isExistingHazard) {
				setCheckBoxToggle(isExistingHazard);
				setSeenGuidelines(false);
			}
		} else {
			setSeenGuidelines(true);
			setCheckBoxToggle(false);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// resets hazards unchecked on siteId change
		if (loaded && values.siteId) {
			setSeenGuidelines(true);
			setCheckBoxToggle(false);
			let data = values;
			data.hazards = [];
			data.substances = [];
			onChange(data);
		}
		// eslint-disable-next-line
	}, [values.siteId]);

	const onCheckChange = ({ target: { checked, value } }) => {
		setCheckBoxToggle(checked);
		let inductionHazards = [...values.hazards];
		if (checked) {
			inductionHazards.push(value);
		} else {
			inductionHazards = inductionHazards.filter(
				(hazard) => hazard !== value
			);
		}

		inductionHazards = Array.from(new Set(inductionHazards));

		onChange({
			...values,
			hazards: inductionHazards,
		});
	};

	const onSelect = (selectedHazardId) => () => {
		const inductionHazards = [...values.hazards];
		inductionHazards.push(selectedHazardId);
		onChange({
			...values,
			hazards: Array.from(new Set(inductionHazards)),
		});
		setCheckBoxToggle(true);
		setSeenGuidelines(false);
		onClose();
	};

	const onClose = () => setData(false);

	return (
		<div className={classes.root}>
			{selectedHazard && (
				<Grid container>
					<Grid item xs={6}>
						<Typography
							className={classes.label}
							align="left"
							gutterBottom
							variant="body1"
						>
							{selectedHazard.name ? selectedHazard.name : ""}
						</Typography>
					</Grid>

					<Grid item xs={6} className={classes.guidelines}>
						<Typography
							className={classes.link}
							align="right"
							gutterBottom
							variant="body1"
							onClick={() => setData(selectedHazard)}
						>
							{showError && !checkBoxToggle ? (
								<span className={classes.error}>
									Read Guidelines
								</span>
							) : (
								"Read Guidelines"
							)}
						</Typography>

						{showError && !checkBoxToggle ? (
							<RedCheckbox
								disabled={disabled || seenGuidelines}
								checked={checkBoxToggle}
								onChange={onCheckChange}
								value={selectedHazard.id}
							/>
						) : (
							<Checkbox
								disabled={disabled || seenGuidelines}
								checked={checkBoxToggle}
								onChange={onCheckChange}
								value={selectedHazard.id}
							/>
						)}
					</Grid>
				</Grid>
			)}
			{data && (
				<HazardDetailsDialog
					data={data}
					onSelect={onSelect}
					onClose={onClose}
				/>
			)}
		</div>
	);
};

SelectHazard.propTypes = {
	hazardId: PropTypes.string.isRequired,
	hazardList: PropTypes.array.isRequired,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	editMode: PropTypes.bool,
	showError: PropTypes.bool,
};

export default SelectHazard;
