import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

const ContentBodyContext = createContext(null);

export const useContentBody = () => {
	const context = useContext(ContentBodyContext);

	if (!context) {
		throw new Error(
			`useContentBody must be used within a ContentBodyContext`
		);
	}

	return context;
};

export const ContentBodyProvider = ({ value, ...props }) => {
	return <ContentBodyContext.Provider value={value} {...props} />;
};

ContentBodyProvider.propTypes = {
	value: PropTypes.any.isRequired,
};
