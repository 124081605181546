import { ofType } from "redux-observable";
import fromTypes from "../actions/types";
import frameTypes from "frame/actions/types";
import { reset, onLogoutFulfilled } from "../actions";
import { mergeMap, tap } from "rxjs/operators";
import { of } from "rxjs";
import { push } from "connected-react-router";
import { profileReset } from "features/account";
import { organisationReset } from "features/organisations";
import { clearImpersonationToken, clearToken } from "frame";

const logoutEffect = (action$, state$) =>
	action$.pipe(
		ofType(
			fromTypes.AUTHORISATION_LOGOUT_REQUESTED,
			frameTypes.FRAME_SIGNOUT_CLICKED
		),
		tap(() => {
			var arr = []; // Array to hold the keys
			// Iterate over localStorage and insert the keys that meet the condition into arr
			for (var i = 0; i < localStorage.length; i++) {
				if (
					localStorage.key(i).includes("persist:safetywise:") ||
					localStorage.key(i).includes("currentOrganisation")
				) {
					arr.push(localStorage.key(i));
				}
			}

			// Iterate over arr and remove the items by key
			for (var j = 0; j < arr.length; j++) {
				localStorage.removeItem(arr[j]);
			}

			clearToken();
			clearImpersonationToken();
		}),
		mergeMap(() => of(reset(), onLogoutFulfilled(), push("/login")))
	);

export const resetStateEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.AUTHORISATION_LOGOUT_FULFILLED),
		mergeMap(() => of(profileReset(), organisationReset()))
	);

export default logoutEffect;
