import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
} from "@material-ui/core";
import { isNotNullOrEmpty } from "utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const RelatedHazardSubstanceForm = ({
	className,
	siteId,
	values,
	onChange,
}) => {
	const classes = useStyles();
	const accident = values.accident || {};

	const siteList = useSelector(({ sites }) => sites.pagination?.results);
	const hazardList = useSelector(
		({ healthSafety }) => healthSafety.hazards?.results
	);
	const hazardousSubstanceList = useSelector(
		({ healthSafety }) => healthSafety.hazardousSubstances?.results
	);

	// for site hazards & substances dropdowns
	const createOptions = (siteList, list) => {
		let options = [];
		if (isNotNullOrEmpty(siteList)) {
			options = siteList
				.map((node) => {
					const item = list.filter(
						(h) =>
							h.id === node.hazardId ||
							h.id === node.hazardousSubstanceId
					)[0];
					return {
						label: (item && item.name) || "",
						value: node.hazardId || node.hazardousSubstanceId,
						key: node.hazardId ? "hazards" : "substances",
					};
				})
				.filter((i) => i.label && i.label !== "" && i.value); // clean null values
		}
		return options;
	};

	const selectedSite =
		siteList && siteList.filter((site) => site.id === siteId)[0];
	const siteHazardOptions =
		selectedSite &&
		isNotNullOrEmpty(hazardList) &&
		isNotNullOrEmpty(selectedSite.hazards)
			? createOptions(selectedSite.hazards, hazardList)
			: [];

	const siteSubstanceOptions =
		selectedSite &&
		isNotNullOrEmpty(hazardousSubstanceList) &&
		isNotNullOrEmpty(selectedSite.substances)
			? createOptions(selectedSite.substances, hazardousSubstanceList)
			: [];

	const options = siteHazardOptions.concat(siteSubstanceOptions);

	const labelRef = useRef();
	const [items, setItems] = useState([]);
	const [labelWidth, setLabelWidth] = useState(0);
	const [open, setOpen] = useState(false);

	const toggleMenu = () => setOpen(!open);

	useEffect(() => {
		setLabelWidth(labelRef.current.offsetWidth);
	}, []);

	useEffect(() => {
		// populate select when editing
		if (isNotNullOrEmpty(accident.relatedHazardOrSubstance)) {
			const list = accident.relatedHazardOrSubstance.map(
				(node) => node.id
			);
			setItems(list);
		}
		// eslint-disable-next-line
	}, []);

	const handleChange = ({ target: { value } }) => {
		let list = value.filter((node) => node && node !== "");
		setItems(list);
		let mappedList = list.map((id) => {
			const selected = options.filter((o) => o.value === id)[0];
			return {
				id: selected.value,
				key: selected.key,
			};
		});

		mappedList = Array.from(new Set(mappedList));

		onChange({
			...values,
			accident: {
				...values.accident,
				relatedHazardOrSubstance: mappedList,
			},
		});
	};

	return (
		<FormControl variant="outlined" fullWidth className={classes.root}>
			<InputLabel ref={labelRef} htmlFor="related_hazards_substances">
				Add Related Hazard or Substance
			</InputLabel>
			<Select
				multiple
				open={open}
				input={
					<OutlinedInput
						labelWidth={labelWidth}
						name="related_hazards_substances"
						id="related_hazards_substances"
						onClick={toggleMenu}
					/>
				}
				value={items || []}
				onChange={handleChange}
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{options.length > 0 &&
					options.map(({ label, value }, key) => (
						<MenuItem key={key} value={value}>
							{label}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
};

RelatedHazardSubstanceForm.propTypes = {
	className: PropTypes.string,
	siteId: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};
export default RelatedHazardSubstanceForm;
