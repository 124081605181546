import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
} from "frame/components";
import { strip, SiteForm, ExtraNotesForm } from "features/forms";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		flexDirection: "row",
		padding: theme.spacing(3, 3, 10, 3),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
}));

const hydrate = ({
	id,
	siteId,
	createdAt,
	people,
	otherPeople,
	appendNotes,
	attachments,
	recipients,
	recipientsOther,
	organisationId,
	...accident
}) => ({
	id,
	siteId,
	createdAt,
	people,
	otherPeople,
	accident,
	appendNotes,
	attachments,
	recipients,
	recipientsOther,
	currentOrganisation: organisationId,
});

const Form = ({ id, values, onClose, onChange, onReset, onSave, incidentList }) => {
	const classes = useStyles();

	// const incidentList = useSelector(
	// 	({ healthSafety }) => healthSafety.incidents?.results
	// );

	const [loaded, setLoaded] = useState(false);
	const open = values && values.id;

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchData() {
			setLoaded(true);
			const incident =
				incidentList && incidentList.filter((i) => i.id === id)[0];

			if (incident) {
				onChange(hydrate(incident));
			}
		}
		if (id) {
			setLoaded(true);
			fetchData();
		} // eslint-disable-next-line
	}, [id, loaded, onChange]);

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
		onClose(true);
	};

	const onCancel = () => {
		onReset();
		onClose();
	};

	if (!open) {
		return null;
	}

	const valid =
		values.appendNotes &&
		values.appendNotes.length === 1 &&
		values.appendNotes[0].notes !== "";

	return (
		<Dialog
			open={!!open}
			aria-labelledby="Append Note to Incident"
			title="Append Note to Incident"
			onClose={onCancel}
		>
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				<div>
					<SiteForm
						disableSite
						disableDate
						values={values}
						onChange={onChange}
					/>
					<ExtraNotesForm
						useAppendNotes
						values={values}
						onChange={onChange}
					/>
				</div>
				<FormActions className={classes.actions}>
					<FormSubmitButton
						disabled={values.locked || !valid}
						loading={values.save.saving}
					>
						Save
					</FormSubmitButton>
					<FormButton
						disabled={values.save.saving}
						onClick={onCancel}
					>
						Cancel
					</FormButton>
				</FormActions>
			</ValidatorForm>
		</Dialog>
	);
};

Form.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

export default Form;
