import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1, 0),
		color: theme.palette.brand,
		// fontSize: 20,
		fontWeight: 600,
		background: theme.palette.highlight,
		borderColor: theme.palette.highlight,
		"&:hover": {
			background: "#c1c72c",
			borderColor: "#c1c72c",
		},
		[theme.breakpoints.up("sm")]: {
			minWidth: 140,
		},
	},
	content: {
		margin: `0 32px`,
	},
	loading: {
		position: "relative",
		"&::after": {
			content: '" "',
			display: "block",
			position: "absolute",
			left: -25,
			top: 2,
			width: 12,
			height: 12,
			margin: 3,
			borderRadius: "50%",
			border: `1px solid ${theme.palette.brand}`,
			borderColor: `${theme.palette.brand} transparent ${theme.palette.brand} transparent`,
			animation: `$spinner 1.2s linear infinite`,
		},
	},
	[`@keyframes spinner`]: {
		"0%": {
			transform: "rotate(0deg)",
		},
		"100%": {
			transform: "rotate(360deg)",
		},
	},
}));

const FormSubmitButton = ({ children, className, loading, ...props }) => {
	const classes = useStyles();

	return (
		<Button
			color="primary"
			className={clsx(classes.root, className)}
			size="small"
			type="submit"
			variant="outlined"
			{...props}
		>
			<span
				className={clsx(
					classes.content,
					loading ? classes.loading : ""
				)}
			>
				{children}
			</span>
		</Button>
	);
};

FormSubmitButton.propTypes = {
	children: PropTypes.any.isRequired,
	className: PropTypes.string,
	loading: PropTypes.bool,
};

export default FormSubmitButton;
