import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRouter } from "utils";
import PageHeaderTabs from "../page-header-tabs";
import { Grid } from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
} from "frame/components";
import { strip, DateForm, HazardForm, UploadFormCard } from "features/forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";

const useStyles = makeStyles((theme) => ({
	root: {},
	body: {
		marginTop: theme.spacing(-2),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
	menu: {
		padding: theme.spacing(3, 0, 0, 0),
		"& > *": {
			marginRight: theme.spacing(3),
		},
	},
}));

const Form = ({ values, onChange, onReset, onSave }) => {
	const classes = useStyles();
	const { history } = useRouter();

	const onSubmit = (event) => {
		event.preventDefault();
		if (event.key !== "Enter") {
			const payload = strip(values, [
				"index",
				"locked",
				"save",
				"fetch",
				"siteId",
			]);
			onSave(payload);
		}
	};

	const onCancel = () => {
		onReset();
		history.push(fromHealthAndSafety.INFO_ROUTE);
	};

	return (
		<Page title="Add Hazard to Library">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Hazards"
				title="Add Hazard to Library"
				addTabTitle="New"
				listTabTitle="See All"
				listTabPath={fromHealthAndSafety.INFO_HAZARDS_ROUTE}
				backActionPath={fromHealthAndSafety.INFO_ROUTE}
			/>
			<PageContent padSides padBottom padTop>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Grid container spacing={3}>
						<Grid item lg={6} md={12} sm={12} xs={12}>
							<div className={classes.body}>
								{/* <Typography
                  variant="body1">
                  Please note this form is not site specific
                  and should be used for hazards
                  relating to your business operations.
                </Typography> */}
								<DateForm
									disabled
									values={values}
									onChange={onChange}
								/>
								<HazardForm
									values={values}
									onChange={onChange}
								/>
								<UploadFormCard
									title={"Add Attachments"}
									values={values}
									onChange={onChange}
								/>
							</div>
						</Grid>
						{/* <Hidden smDown>
              <HelpCard />
            </Hidden> */}
					</Grid>

					<FormActions className={classes.actions}>
						<FormSubmitButton
							disabled={values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
						<FormButton
							disabled={values.save.saving}
							onClick={onCancel}
						>
							Cancel
						</FormButton>
					</FormActions>
				</ValidatorForm>
			</PageContent>
		</Page>
	);
};

Form.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

export default Form;
