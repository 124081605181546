import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	heading: {
		margin: 0,
	},
	formGroup: {
		margin: theme.spacing(0),
		padding: theme.spacing(1, 3),
	},
}));

const DateForm = ({ className, values, onChange, disabled }) => {
	const classes = useStyles();
	disabled = values.save.saving || disabled;

	const onSelectDate = useCallback(
		(key) => (value) => {
			onChange({
				...values,
				[key]: moment(value).format(),
			});
		},
		[values, onChange]
	);

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Date"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent className={classes.formGroup}>
				<DatePicker
					data-cy="date"
					className={classes.field}
					disabled={disabled}
					format="Do MMMM YYYY"
					fullWidth
					inputVariant="outlined"
					label="Select Date"
					value={values.createdAt}
					variant="outlined"
					disableFuture={true}
					onChange={onSelectDate("createdAt")}
				/>
			</CardContent>
		</Card>
	);
};

DateForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default DateForm;
