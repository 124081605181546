import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { Modal, Card, CardContent, Typography, Grid } from "@material-ui/core";

import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { FormActions, FormButton, FormSubmitButton } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		marginTop: theme.spacing(5),
		justifyContent: "flex-end",
	},
}));

const EditOrganisationForm = ({
	className,
	organisation,
	onSaveClicked,
	onCloseClicked,
	isEditModalShowing,
	onNameChange,
}) => {
	const classes = useStyles();
	const onSubmit = () => onSaveClicked();

	if (!isEditModalShowing) {
		return null;
	}

	return (
		<Modal open={true} onClose={onCloseClicked}>
			<Card className={clsx(classes.root, className)}>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<CardContent>
						<Typography align="center" gutterBottom variant="h3">
							Edit Organisation Name
						</Typography>
						<Grid
							className={classes.container}
							container
							spacing={3}
						>
							<Grid item xs={12}>
								<TextValidator
									fullWidth
									label="Organisation Name"
									name="Organisation Name"
									value={organisation.name}
									variant="outlined"
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={(e) =>
										onNameChange(e.target.value)
									}
								/>
							</Grid>
						</Grid>
						<FormActions className={classes.actions}>
							<FormSubmitButton>Save</FormSubmitButton>
							<FormButton onClick={onCloseClicked}>
								Cancel
							</FormButton>
						</FormActions>
					</CardContent>
				</ValidatorForm>
			</Card>
		</Modal>
	);
};

EditOrganisationForm.propTypes = {
	className: PropTypes.string,
	organisation: PropTypes.object.isRequired,
	onCloseClicked: PropTypes.func,
	onSaveClicked: PropTypes.func.isRequired,
	isEditModalShowing: PropTypes.bool,
	onNameChange: PropTypes.func.isRequired,
};

export default EditOrganisationForm;
