import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { TextValidator } from "react-material-ui-form-validator";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw } from "draft-js";
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { RadioListInput, TitleWithHelperText } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	heading: {
		marginBottom: theme.spacing(-3),
	},
	rte: {
		padding: theme.spacing(0, 4),
		borderRadius: "5px",
		border: "1px solid",
		borderColor: "#D9D9D9",
		"&:hover": {
			borderColor: "#1C2B4A",
			cursor: "auto",
		},
		"& #ppeRequirements-toolbar": {
			marginLeft: theme.spacing(-3),
		},
		"& #hazardControls-toolbar": {
			marginLeft: theme.spacing(-3),
		},
	},
}));

const HazardForm = ({
	className,
	values,
	onChange,
	editMode,
	disableHazards,
}) => {
	const classes = useStyles();
	const hazard = values.hazard || {};
	const disabled = false; //values.locked

	// used for rich text editer onload edit data, form doesnt work when initialized with stagingData for some reason
	const [initialData] = useState({
		ppeRequirements: hazard.ppeRequirements || "",
		hazardControls: hazard.hazardControls || "",
	});

	// for staging data for onchange function on RTE value changes
	const [stagingData, setStagingData] = useState({
		ppeRequirements: hazard.ppeRequirements || "",
		hazardControls: hazard.hazardControls || "",
	});

	const onInputChange =
		(key) =>
		({ target: { value } }) => {
			onChange({
				...values,
				hazard: {
					...hazard,
					[key]: value,
				},
			});
		};

	const onRadioChange = (key) => (value) =>
		onChange({
			...values,
			hazard: {
				...hazard,
				[key]: value,
			},
		});

	const levels = [
		{
			label: "Low",
			value: "low",
		},
		{
			label: "Moderate",
			value: "moderate",
		},
		{
			label: "High",
			value: "high",
		},
	];

	const yesNo = [
		{
			label: "Yes",
			value: "yes",
		},
		{
			label: "No",
			value: "no",
		},
	];

	// triggers onchange function on RTE values changes
	useEffect(() => {
		onChange({
			...values,
			hazard: {
				...hazard,
				ppeRequirements: stagingData.ppeRequirements || "",
				hazardControls: stagingData.hazardControls || "",
			},
		}); // eslint-disable-next-line
	}, [stagingData]);

	const onRTEChange = (key) => (event) => {
		// const content = event.getCurrentContent() && event.getCurrentContent().getPlainText()
		const content =
			event.getCurrentContent() &&
			JSON.stringify(convertToRaw(event.getCurrentContent()));
		content && setStagingData({ ...stagingData, [key]: content });
	};

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Hazard"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextValidator
							id="hazard_name"
							disabled={disabled}
							fullWidth
							label="Hazard Name *"
							value={hazard.name || ""}
							variant="outlined"
							validators={["required"]}
							errorMessages={["This field is required"]}
							onChange={onInputChange("name")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="hazard_risk_assessment"
							disabled={disabled}
							fullWidth
							label="Initial Risk Assessment"
							multiline
							rows="3"
							value={hazard.initialRiskAssessment || ""}
							variant="outlined"
							onChange={onInputChange("initialRiskAssessment")}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography align="left" gutterBottom variant="h5">
							Residual Risk Assessment
						</Typography>
						<RadioListInput
							data-cy={"residualRiskAssessment"}
							disabled={disabled}
							initialValue={hazard.residualRiskAssessment}
							options={levels}
							onChange={onRadioChange("residualRiskAssessment")}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography align="left" gutterBottom variant="h5">
							Significant Risk
						</Typography>
						<RadioListInput
							data-cy={"significantRisk"}
							disabled={disabled}
							initialValue={hazard.significantRisk}
							options={yesNo}
							onChange={onRadioChange("significantRisk")}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography align="left" gutterBottom variant="h5">
							Training Required
						</Typography>
						<RadioListInput
							data-cy={"trainingRequired"}
							disabled={disabled}
							initialValue={hazard.trainingRequired}
							options={yesNo}
							onChange={onRadioChange("trainingRequired")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TitleWithHelperText title="PPE requirements (if any)" />

						<div className={classes.rte}>
							<MUIRichTextEditor
								id="ppeRequirements"
								label="PPE requirements (if any)"
								controls={["bulletList"]}
								value={initialData.ppeRequirements || ""}
								onChange={onRTEChange("ppeRequirements")}
							/>
							<br />
							<br />
							<br />
						</div>
					</Grid>

					<Grid item xs={12}>
						<TitleWithHelperText title="Hazard Controls" />

						<div className={classes.rte}>
							<MUIRichTextEditor
								id="hazardControls"
								label="Hazard Controls"
								controls={["bulletList"]}
								value={initialData.hazardControls || ""}
								onChange={onRTEChange("hazardControls")}
							/>
							<br />
							<br />
							<br />
						</div>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

HazardForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	editMode: PropTypes.bool,
};

export default HazardForm;
