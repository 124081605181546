import { connect } from "react-redux";
import HealthSafetyRoutes from "./health-safety-routes";
import {
	onIncidentListRequest,
	onHazardListRequest,
	onHazardousSubstanceListRequest,
	onInductionMinutesListRequest,
	onSafetyMinutesListRequest,
	onJobSafetyAnalysisListRequest,
	onSafetyPlanChecklistListRequest,
	onStaffTrainingListRequest,
	onStaffTrainingTopicListRequest,
} from "../actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	onGetIncidentList: onIncidentListRequest,
	onGetHazardsList: onHazardListRequest,
	onGetHazardousSubstanceList: onHazardousSubstanceListRequest,
	onGetInductionMinutesList: onInductionMinutesListRequest,
	onGetSafetyMinutesList: onSafetyMinutesListRequest,
	onGetJobSafetyAnalysisList: onJobSafetyAnalysisListRequest,
	onGetSafetyPlanChecklistList: onSafetyPlanChecklistListRequest,
	onGetStaffTrainingList: onStaffTrainingListRequest,
	onGetStaffTrainingTopicList: onStaffTrainingTopicListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthSafetyRoutes);
