import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(0, 2, 2, 0),
	},
	placeholder: {
		borderRadius: 4,
		objectFit: "cover",
	},
}));

const ImagePlaceholder = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Skeleton
				className={classes.placeholder}
				variant="rect"
				width={60}
				height={60}
			/>
		</div>
	);
};

export default ImagePlaceholder;
