/* eslint-disable react/no-multi-comp */
import React from "react";
import { matchPath } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { List, Typography } from "@material-ui/core";
import { NavigationListItem } from "./components";
import { useContext } from "react";
import { __RouterContext } from "react-router";

const useRouter = () => useContext(__RouterContext);

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(3),
	},
	navigationTitle: {
		color: theme.palette.white,
	},
}));

const NavigationList = (props) => {
	const { pages, ...rest } = props;

	return (
		<List>
			{pages.reduce(
				(items, page, index) =>
					reduceChildRoutes({ index, items, page, ...rest }),
				[]
			)}
		</List>
	);
};

NavigationList.propTypes = {
	depth: PropTypes.number,
	pages: PropTypes.array,
};

const reduceChildRoutes = (props) => {
	const { index, router, items, page, depth } = props;

	if (page.children) {
		const open = matchPath(router.location.pathname, {
			path: page.href,
			exact: false,
		});

		items.push(
			<NavigationListItem
				depth={depth}
				icon={page.icon}
				key={index}
				href={page.href}
				label={page.label}
				open={Boolean(open)}
				title={page.title}
			>
				<NavigationList
					depth={depth + 1}
					pages={page.children}
					router={router}
				/>
			</NavigationListItem>
		);
	} else {
		items.push(
			<NavigationListItem
				depth={depth}
				href={page.href}
				icon={page.icon}
				key={index}
				label={page.label}
				title={page.title}
			/>
		);
	}

	return items;
};

const Navigation = (props) => {
	const { title, pages, className, component: Component, ...rest } = props;

	const classes = useStyles(props);
	const router = useRouter();

	return (
		<Component {...rest} className={clsx(classes.root, className)}>
			{title && (
				<Typography
					variant="overline"
					className={classes.navigationTitle}
				>
					{title}
				</Typography>
			)}
			<NavigationList depth={0} pages={pages} router={router} />
		</Component>
	);
};

Navigation.propTypes = {
	className: PropTypes.string,
	component: PropTypes.any,
	pages: PropTypes.array.isRequired,
	title: PropTypes.string,
};

Navigation.defaultProps = {
	component: "nav",
};

export default Navigation;
