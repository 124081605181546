import { combineEpics } from "redux-observable";
import { jobListEffect, jobPagedListEffect } from "./jobs-list-effect";
import jobAddEffect from "./job-add-effect";
import jobRemoveEffect from "./job-remove-effect";
import {
	downloadNextAttachmentEffect,
	addAttachmentsToIndexedDBEffect,
} from "./add-attachments-to-indexed-db-effect";

export const jobsEffects = combineEpics(
	jobListEffect,
	jobPagedListEffect,
	jobAddEffect,
	jobRemoveEffect,
	addAttachmentsToIndexedDBEffect,
	downloadNextAttachmentEffect
);
