import React from "react";
// import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/styles";
import { ScrollToTopOnMount } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const NotFound = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<ScrollToTopOnMount />
			<h1>Not found</h1>
		</div>
	);
};

NotFound.propTypes = {};

export default NotFound;
