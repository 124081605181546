import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import { RemoveWarning } from "frame/components";
import { useRemover } from "utils";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FolderIcon from "@material-ui/icons/Folder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ViewIcon from "@material-ui/icons/Subject";

const useStyles = makeStyles((theme) => ({
	optionsMenu: {
		position: "absolute",
		right: 0,
		top: 0,
	},
	optionIcon: {
		marginRight: theme.spacing(1),
	},
}));

const SiteCrudOptions = ({
	onArchive,
	onMakeActive,
	onDelete,
	onEdit,
	onView,
}) => {
	const classes = useStyles();
	const [openOptions, setOpenOptions] = React.useState(false);
	const { remove, onPossiblyRemove, onCancelRemove } = useRemover();

	const options = [
		{
			label: "View",
			icon: ViewIcon,
			onClick: onView,
		},
		{
			label: "Edit",
			icon: EditIcon,
			onClick: onEdit,
		},
		{
			label: "Archive",
			icon: FolderIcon,
			onClick: onArchive,
		},
		{
			label: "Make Active",
			icon: CheckCircleIcon,
			onClick: onMakeActive,
		},
		{
			label: "Delete",
			icon: DeleteIcon,
			onClick: onDelete ? onPossiblyRemove : null,
		},
	].filter((x) => x.onClick);

	return (
		<div className={classes.optionsMenu}>
			<RemoveWarning
				open={remove}
				onCancel={onCancelRemove}
				onConfirm={() => onDelete()}
			>
				Please confirm to archive this hazard
			</RemoveWarning>
			<IconButton
				data-cy="options_menu"
				aria-label="options menu"
				aria-controls="options-menu"
				aria-haspopup="true"
				onClick={(e) => setOpenOptions(e.currentTarget)}
			>
				<MoreVertIcon />
			</IconButton>
			{openOptions && (
				<Menu
					id="options-menu"
					anchorEl={openOptions}
					keepMounted
					open={openOptions ? true : false}
					onClose={() => setOpenOptions(false)}
				>
					{options.map(
						({ label, icon: MenuIcon, onClick }, index) => (
							<MenuItem
								key={index}
								id={label}
								data-cy={label.toLowerCase()}
								onClick={() => {
									onClick();
									setOpenOptions(false);
								}}
							>
								<MenuIcon className={classes.optionIcon} />
								{label}
							</MenuItem>
						)
					)}
				</Menu>
			)}
		</div>
	);
};

SiteCrudOptions.propTypes = {
	onArchive: PropTypes.func,
	onMakeActive: PropTypes.func,
	onView: PropTypes.func,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
};

export default SiteCrudOptions;
