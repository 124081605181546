import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";

const mapStateToProps = ({ addHazardousSubstanceFormReducer }) => ({
	values: addHazardousSubstanceFormReducer,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddHazardousSubstanceValueChange,
	onReset: fromActions.onAddHazardousSubstanceReset,
	onSave: fromActions.onAddHazardousSubstanceSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
