import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { SelectInput } from "frame/components/input";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		margin: theme.spacing(1, 0),
		maxWidth: 550,
	},
	fieldLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	fieldSelector: {
		minWidth: 140,
		[theme.breakpoints.up("sm")]: {
			minWidth: 200,
		},
		[theme.breakpoints.up("md")]: {
			minWidth: 240,
		},
	},
	label: {
		margin: 0,
	},
}));

const CommunicationParticipationForm = ({
	className,
	disabled,
	label,
	id,
	values,
	onSelectChange,
}) => {
	const classes = useStyles();

	const checkboxKey = `communicationParticipation${id}`;
	const frequencyKey = `communicationParticipation${id}Frequency`;

	const onCheckChange =
		(key) =>
		({ target: { checked } }) =>
			onSelectChange(key)(checked);

	return (
		<div className={classes.root}>
			<div className={classes.fieldLabel}>
				<FormControlLabel
					className={classes.formControlLabel}
					control={
						<Checkbox
							checked={values[checkboxKey] ? true : false}
							color="default"
							disabled={disabled}
							onChange={onCheckChange(checkboxKey)}
							value="yes"
							inputProps={{
								"aria-label": `${label} checkbox`,
							}}
						/>
					}
					label={label}
				/>
			</div>
			<SelectInput
				disabled={!values[checkboxKey]}
				id={frequencyKey}
				className={classes.fieldSelector}
				label="Frequency"
				options={["daily", "weekly", "fortnightly"]}
				tight
				value={values[frequencyKey] || ""}
				onChange={onSelectChange(frequencyKey)}
			/>
		</div>
	);
};

CommunicationParticipationForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onSelectChange: PropTypes.func.isRequired,
};

export default CommunicationParticipationForm;
