import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError, tap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { authHeaders, getBaseUrl, setImpersonationToken } from "frame";
import fromTypes from "../actions/types";
import { onImpersonateFailed } from "../actions";
import { push } from "connected-react-router";
import { HOME_ROUTE } from "features/home/home-routes";
import { profileFetch } from "../../account/actions";
import { onActiveTimesheetRequest } from "features/timesheet/timesheet-actions";

const staffImpersonateEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.STAFF_IMPERSONATE_CLICKED),
		mergeMap(() =>
			ajax
				.post(
					`${getBaseUrl()}/api/${
						state$.value.organisations.currentOrganisation
					}/${state$.value.staff.currentStaff.id}/impersonate`,
					{},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						const response = ajaxResponse.response;

						setImpersonationToken(
							response.access_token,
							response.expires_in
						);

						return of(push(HOME_ROUTE), profileFetch(), onActiveTimesheetRequest());
					}),
					catchError((error) => of(onImpersonateFailed(error)))
				)
		)
	);

export default staffImpersonateEffect;
