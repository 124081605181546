import { connect } from "react-redux";
import SafetyPlanChecklistList from "./safety-plan-checklist-list";
import {
	onRemove,
	onSafetyPlanChecklistSearchChanged,
	onSafetyPlanChecklistPagedListRequest,
} from "../../actions";

const mapStateToProps = ({ healthSafety, profile, organisations }) => ({
	safetyPlanChecklists: healthSafety.safetyPlanChecklists,
	role: profile.role,
	searchFilter: healthSafety.searchFilter,
	currentOrganisationGUID: organisations.currentOrganisation,
});

const mapDispatchToProps = {
	onDelete: onRemove,
	onSearchChanged: onSafetyPlanChecklistSearchChanged,
	onGetSafetyPlanChecklistsList: onSafetyPlanChecklistPagedListRequest,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SafetyPlanChecklistList);
