import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { Divider, Typography, Grid } from "@material-ui/core";
import MUIRichTextEditor from "mui-rte";
import { isValidContentState, isNotNullOrEmpty } from "utils";
import { TextClip } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
	},
	info: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		padding: theme.spacing(0.5),
	},
	label: {
		paddingRight: theme.spacing(1),
		minWidth: "100px",
		fontWeight: "400",
	},
	large: {
		minWidth: "300px",
	},
	contentStateArea: {
		marginLeft: theme.spacing(2),
	},
	value: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "flex-end",
	},
	greyOut: {
		opacity: "50%",
	},
}));

/* 
options:
  string color, // for specifying value color
  string format, // for specifying value format eg. number, currency, date/time format
  bool contentState, // for display MUIRichText Content values, values goes to next line
  bool inLineContentState, // for display MUIRichText Content values in line with label
  bool bold, // for bolding label
  bool isLongLabel, // changes label and value to 9 : 3 - default is half/half on mobile, 7 : 5 for others
  bool useTextClip, // for clipping overflowing text - default to max 140 characters
  bool isList, // for list data, need to specify listAttribute - default assumes array of string
  string listAttribute, // used together with isList
  bool greyOutArchived, // used together with isList, used in JSA for greying out hazards that are archived in hazard library
*/
const ValueText = ({ label, value, format, options = {} }) => {
	const classes = useStyles();

	const restyle = options.color ? { color: options.color } : {};

	if (format === "number") {
		value = Number(value).toLocaleString("en-NZ");
	} else if (format === "currency") {
		value = Number(value).toLocaleString("en-NZ", {
			style: "currency",
			currency: "NZD",
		});
	} else if (format) {
		value = moment(value).format(format);
	}

	const displayValue = (value) => {
		if (options.inLineContentState) {
			// for display MUIRichText Content values in line
			return isValidContentState(value) ? (
				<MUIRichTextEditor readOnly controls={[]} value={value} />
			) : (
				"-"
			);
		} else if (options.isList) {
			// for list data, need to specify listAttribute - default assumes array of string
			return isNotNullOrEmpty(value) ? (
				<ul>
					{value.map((item) => (
						<li
							className={
								options.greyOutArchived &&
								item.status &&
								item.status === "Archived"
									? classes.greyOut
									: ""
							}
						>
							{item[options.listAttribute] || item}
							{options.greyOutArchived &&
								item.status &&
								item.status === "Archived" &&
								" (Archived)"}
						</li>
					))}
				</ul>
			) : (
				"-"
			);
		} else if (options.useTextClip) {
			// for clipping overflowing text - default to max 140 characters
			return <TextClip text={value} numberOfCharacters={140} />;
		} else {
			return value || "-";
		}
	};

	const renderContentState = (value) => {
		return (
			<>
				<Typography variant="h6">
					{options.bold ? <strong>{label}:</strong> : label}
				</Typography>
				<Typography align="left" gutterBottom variant="body1">
					{value && isValidContentState(value) ? (
						<div className={classes.contentStateArea}>
							<MUIRichTextEditor
								readOnly
								controls={[]}
								value={value}
							/>
						</div>
					) : (
						"-"
					)}
				</Typography>
			</>
		);
	};

	return (
		<div className={classes.root}>
			{options.contentState ? (
				renderContentState(value)
			) : (
				<Grid container className={classes.info}>
					<Grid
						item
						sm={options.isLongLabel ? 9 : 7}
						xs={options.isLongLabel ? 9 : 6}
					>
						<Typography
							className={clsx(
								classes.label,
								options.large ? classes.large : ""
							)}
							variant="h6"
						>
							{options.bold ? <strong>{label}:</strong> : label}
						</Typography>
					</Grid>

					<Grid
						item
						sm={options.isLongLabel ? 3 : 5}
						xs={options.isLongLabel ? 3 : 6}
					>
						<Typography
							className={classes.value}
							style={restyle}
							variant="body1"
						>
							{displayValue(value)}
						</Typography>
					</Grid>
				</Grid>
			)}
			<Divider />
		</div>
	);
};

ValueText.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.object,
	]),
	format: PropTypes.string,
	options: PropTypes.object,
};

export default ValueText;
