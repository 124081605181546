import * as R from "ramda";
import initialRegisterState from "../models/register-state";
import fromTypes from "../actions/types";

import {
	defaultSaveState,
	saveRequested,
	// saveFailed,
	saveSuccess,
} from "utils";

const defaultState = {
	...initialRegisterState,
	...defaultSaveState,
};

const registerReducer = (state = defaultState, action) => {
	switch (action.type) {
		// on change actions
		case fromTypes.AUTHORISATION_EMAIL_ADDRESS_CHANGED:
			return emailAddressChanged(R.clone(state), action);
		case fromTypes.AUTHORISATION_PASSWORD_CHANGED:
			return passwordChanged(R.clone(state), action);
		case fromTypes.AUTHORISATION_ORGANISATION_CHANGED:
			return orgnisationChanged(R.clone(state), action);
		case fromTypes.AUTHORISATION_FIRST_NAME_CHANGED:
			return firstNameChanged(R.clone(state), action);
		case fromTypes.AUTHORISATION_LAST_NAME_CHANGED:
			return lastNameChanged(R.clone(state), action);
		case fromTypes.AUTHORISATION_PHONE_CHANGED:
			return phoneChanged(R.clone(state), action);

		// Register
		case fromTypes.AUTHORISATION_REGISTER_REQUESTED:
			return saveRequested(state);
		case fromTypes.AUTHORISATION_REGISTER_FAILED:
			return registerFailed(R.clone(state), action);
		case fromTypes.AUTHORISATION_REGISTER_FULFILLED:
			return saveSuccess(defaultState);
		case fromTypes.AUTHORISATION_LOGIN_FULFILLED:
			return defaultState;

		case fromTypes.AUTHORISATION_REGISTER_CLEAR:
			return registerClear(R.clone(state), action);
		case fromTypes.AUTHORISATION_REGISTER_ERROR_CLEAR:
			return registerClearError(R.clone(state), action);
		default:
			return state;
	}
};

const emailAddressChanged = (state, action) => {
	state.emailAddress = action.payload;
	return state;
};

const passwordChanged = (state, action) => {
	state.password = action.payload;
	return state;
};

const orgnisationChanged = (state, action) => {
	state.organisation = action.payload;
	return state;
};

const firstNameChanged = (state, action) => {
	state.firstName = action.payload;
	return state;
};

const lastNameChanged = (state, action) => {
	state.lastName = action.payload;
	return state;
};

const phoneChanged = (state, action) => {
	state.phone = action.payload;
	return state;
};

const registerFailed = (state, action) => {
	const errorMessage = action.payload && action.payload.error;
	return {
		...state,
		...defaultSaveState,
		registerErrorMessage: errorMessage,
	};
};

const registerClear = (state, action) => {
	state.organisation = "";
	state.firstName = "";
	state.lastName = "";
	state.phone = "";
	state.password = "";
	return state;
};

const registerClearError = (state, action) => {
	state.registerErrorMessage = "";
	state.save = defaultSaveState;
	return state;
};

export default registerReducer;
