import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import uuid from "uuid";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
} from "frame/components";
import { strip, SafetyPlanChecklistForm, SiteForm } from "features/forms";
import * as R from "ramda";

import ReadOnlyForm from "./read-only-form";

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		flexDirection: "row",
		padding: theme.spacing(3, 3, 10, 3),
	},
	error: {
		paddingTop: theme.spacing(1),
		color: theme.palette.error.main,
		paddingBottom: theme.spacing(7),
	},
}));

const hydrate = ({
	id,
	siteId,
	createdAt,
	attachments,
	recipients,
	recipientsOther,
	organisationId,
	...safetyPlan
}) => ({
	id,
	siteId,
	createdAt,
	safetyPlan,
	attachments,
	recipients,
	recipientsOther,
	currentOrganisation: organisationId,
});

const Form = ({
	id,
	values,
	onClose,
	onChange,
	onReset,
	onSave,
	readOnly,
	isClone,
	responseMessage,
}) => {
	const classes = useStyles();
	const spcList = useSelector(
		({ healthSafety }) => healthSafety.safetyPlanChecklists?.results
	);

	const [originalName, setOriginalName] = useState("");
	const [loaded, setLoaded] = useState(false);
	const open = values && values.id;

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchData() {
			setLoaded(true);
			const checklist =
				spcList && R.clone(R.find(R.propEq("id", id), spcList));

			if (checklist) {
				setOriginalName(checklist.name);

				if (isClone) {
					checklist.id = uuid.v4();
					checklist.name = `${checklist.name} (copy)`;
				}
				onChange(hydrate(checklist));
			}
		}
		if (id) {
			setLoaded(true);
			fetchData();
		} // eslint-disable-next-line
	}, [id, loaded, onChange]);

	useEffect(() => {
		values.save.success && onClose();
	}, [values.save.success, onClose]);

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
	};

	const onCancel = () => {
		onReset();
		onClose();
	};

	if (!open) {
		return null;
	}

	const title = readOnly
		? `View Site Safety Plan (Checklist)`
		: `${isClone ? "Clone" : "Edit"}  SSP: ${originalName}`;

	return (
		<Dialog
			open={open ? true : false}
			aria-labelledby="Site Safety Plan"
			// breadcrumbs="Safety Plan Checklist"
			title={title}
			onClose={onCancel}
		>
			<ValidatorForm autoComplete="off" onSubmit={onSubmit}>
				{readOnly ? (
					<ReadOnlyForm values={values} />
				) : (
					<>
						<SiteForm values={values} onChange={onChange} />
						{values.siteId && (
							<SafetyPlanChecklistForm
								values={values}
								onChange={onChange}
							/>
						)}
					</>
				)}
				<FormActions className={classes.actions}>
					{!readOnly && (
						<FormSubmitButton
							disabled={values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
					)}
					<FormButton
						disabled={values.save.saving}
						onClick={onCancel}
					>
						{readOnly ? "Close" : "Cancel"}
					</FormButton>
				</FormActions>
				<Typography variant="body1" className={classes.error}>
					{responseMessage &&
						responseMessage.includes("already") &&
						responseMessage}
				</Typography>
			</ValidatorForm>
		</Dialog>
	);
};

Form.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
	isClone: PropTypes.bool,
	responseMessage: PropTypes.string,
};

export default Form;
