import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import JobCard from "./job-card";
import EditJobModal from "./edit-job-modal";
import { Typography } from "@material-ui/core";
// import Skeleton from '@material-ui/lab/Skeleton'
// import { Page, PageContent } from "frame/components";
import {
	Page,
	PageContent,
	Pagination,
	PaginationHeader,
} from "frame/components";
import { getPageResults } from "utils";
import { useRouter, isNotNullOrEmpty } from "utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.white,
	},
	heading: {
		marginTop: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0),
		},
	},
	body: {
		margin: theme.spacing(3, 0),
	},
	filter: { display: "flex" },
}));

// used in site summary
// const JobSiteList = ({ onEdit, onDelete, currentUserId, role, hasNetwork }) => {
const JobSiteList = ({
	onEdit,
	onDelete,
	currentUserId,
	role,
	hasNetwork,
	pagination,
	onGetJobList,
	flags,
}) => {
	const classes = useStyles();
	const { match } = useRouter();
	const { siteId } = match.params;

	const jobList = getPageResults(pagination);
	// const jobList = useSelector(({ jobs }) =>
	// 	jobs.pagination?.results.filter((j) => j.siteId === siteId)
	// );

	const empty = jobList && jobList.length <= 0;

	return (
		<Page className={classes.root} title="Quick Pic / Notes">
			<PageContent pad>
				<Typography variant="h2" className={classes.heading}>
					Quick Pic / Notes
				</Typography>

				<div className={classes.body}>
					<PaginationHeader pagination={pagination} />

					{isNotNullOrEmpty(jobList) &&
						jobList.map((job, index) => (
							<JobCard
								key={index}
								job={job}
								role={role}
								currentUserId={currentUserId}
								hasNetwork={hasNetwork}
								onEdit={onEdit}
								onDelete={onDelete}
							/>
						))}
				</div>
					{empty && (
						<Typography variant="body1">
							There are currently no quick pics or notes.
						</Typography>
					)}

				<Pagination
					disabledOnLoadFetch={flags.create === "processing"}
					onGetList={(paginationParams) => onGetJobList({
						...paginationParams,
						columnName: "siteId",
						columnValue: siteId
					})}
					pagination={pagination}
					orderBy="createdAt"
					ascending={false}
				/>
			</PageContent>

			<EditJobModal />
		</Page>
	);
};

JobSiteList.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	onAdd: PropTypes.func,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onSearchChanged: PropTypes.func.isRequired,
	hasNetwork: PropTypes.bool.isRequired,
	pagination: PropTypes.object.isRequired,
	searchFilter: PropTypes.string,
	onGetJobList: PropTypes.func.isRequired,
};

export default JobSiteList;
