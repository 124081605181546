import fromTypes from "./types";

export const onChangeEmail = (payload) => ({
	type: fromTypes.AUTHORISATION_EMAIL_ADDRESS_CHANGED,
	payload,
});

export const onOrganisationChange = (payload) => ({
	type: fromTypes.AUTHORISATION_ORGANISATION_CHANGED,
	payload,
});

export const onChangeFirstName = (payload) => ({
	type: fromTypes.AUTHORISATION_FIRST_NAME_CHANGED,
	payload,
});

export const onChangeLastName = (payload) => ({
	type: fromTypes.AUTHORISATION_LAST_NAME_CHANGED,
	payload,
});

export const onChangePassword = (payload) => ({
	type: fromTypes.AUTHORISATION_PASSWORD_CHANGED,
	payload,
});

export const onChangePhone = (payload) => ({
	type: fromTypes.AUTHORISATION_PHONE_CHANGED,
	payload,
});

export const onChangeCreditCard = (payload) => ({
	type: fromTypes.AUTHORISATION_CREDITCARD_CHANGED,
	payload,
});

export const onChangeRememberMe = (payload) => ({
	type: fromTypes.AUTHORISATION_REMEMBER_ME_CHANGED,
	payload,
});

// Login
export const onLogin = () => ({
	type: fromTypes.AUTHORISATION_LOGIN_REQUESTED,
});

export const onLoginFulfilled = () => ({
	type: fromTypes.AUTHORISATION_LOGIN_FULFILLED,
});

export const onLoginFailed = (payload) => ({
	type: fromTypes.AUTHORISATION_LOGIN_FAILED,
	payload,
});

// Token
export const onTokenReceived = (payload) => ({
	type: fromTypes.AUTHORISATION_TOKEN_RECEIVED,
	payload,
});

// Logout
export const onLogout = () => ({
	type: fromTypes.AUTHORISATION_LOGOUT_REQUESTED,
});

export const reset = () => ({
	type: fromTypes.RESET, //redux-reset middleware
});

export const onLogoutFulfilled = () => ({
	type: fromTypes.AUTHORISATION_LOGOUT_FULFILLED,
});

// Register
export const onRegisterClear = () => ({
	type: fromTypes.AUTHORISATION_REGISTER_CLEAR,
});

export const onRegisterErrorClear = () => ({
	type: fromTypes.AUTHORISATION_REGISTER_ERROR_CLEAR,
});

export const onRegister = (payload) => ({
	type: fromTypes.AUTHORISATION_REGISTER_REQUESTED,
	payload,
});

export const onRegisterFulfilled = () => ({
	type: fromTypes.AUTHORISATION_REGISTER_FULFILLED,
});

export const onRegisterFailed = (payload) => ({
	type: fromTypes.AUTHORISATION_REGISTER_FAILED,
	payload,
});

// Forgot Password
export const onForgotPassword = (payload) => ({
	type: fromTypes.AUTHORISATION_FORGOT_PASSWORD_REQUESTED,
	payload,
});

export const onForgotPasswordChange = (payload) => ({
	type: fromTypes.AUTHORISATION_FORGOT_PASSWORD_CHANGE,
	payload,
});

export const onForgotPasswordFulfilled = (payload) => ({
	type: fromTypes.AUTHORISATION_FORGOT_PASSWORD_FULFILLED,
	payload,
});

export const onForgotPasswordFailed = (payload) => ({
	type: fromTypes.AUTHORISATION_FORGOT_PASSWORD_FAILED,
	payload,
});

// Set Password
export const onSetPassword = (payload) => ({
	type: fromTypes.AUTHORISATION_SET_PASSWORD_REQUESTED,
	payload,
});

export const onSetPasswordPasswordChange = (payload) => ({
	type: fromTypes.AUTHORISATION_SET_PASSWORD_PASSWORD_CHANGED,
	payload,
});

export const onSetPasswordConfirmPasswordChange = (payload) => ({
	type: fromTypes.AUTHORISATION_SET_PASSWORD_CONFIRM_PASSWORD_CHANGED,
	payload,
});

export const onSetPasswordFulfilled = (payload) => ({
	type: fromTypes.AUTHORISATION_SET_PASSWORD_FULFILLED,
	payload,
});

export const onSetPasswordFailed = (payload) => ({
	type: fromTypes.AUTHORISATION_SET_PASSWORD_FAILED,
	payload,
});

// Reset Password
export const onResetPassword = (payload) => ({
	type: fromTypes.AUTHORISATION_RESET_PASSWORD_REQUESTED,
	payload,
});

export const onResetPasswordPasswordChange = (payload) => ({
	type: fromTypes.AUTHORISATION_RESET_PASSWORD_PASSWORD_CHANGED,
	payload,
});

export const onResetPasswordConfirmPasswordChange = (payload) => ({
	type: fromTypes.AUTHORISATION_RESET_PASSWORD_CONFIRM_PASSWORD_CHANGED,
	payload,
});

export const onResetPasswordFulfilled = (payload) => ({
	type: fromTypes.AUTHORISATION_RESET_PASSWORD_FULFILLED,
	payload,
});

export const onResetPasswordFailed = (payload) => ({
	type: fromTypes.AUTHORISATION_RESET_PASSWORD_FAILED,
	payload,
});

// Confirmation
export const onConfirmation = (payload) => ({
	type: fromTypes.AUTHORISATION_CONFIRMATION,
	payload,
});

export const onConfirmationFulfilled = (payload) => ({
	type: fromTypes.AUTHORISATION_CONFIRMATION_FULFILLED,
	payload,
});

export const onConfirmationFailed = (payload) => ({
	type: fromTypes.AUTHORISATION_CONFIRMATION_FAILED,
	payload,
});

// Send Reset Password Email
export const onSendResetEmail = (payload) => ({
	type: fromTypes.AUTHORISATION_SEND_RESET_EMAIL,
	payload,
});

export const onSendResetEmailFulfilled = (payload) => ({
	type: fromTypes.AUTHORISATION_SEND_RESET_EMAIL_FULFILLED,
	payload,
});

export const onSendResetEmailFailed = (payload) => ({
	type: fromTypes.AUTHORISATION_SEND_RESET_EMAIL_FAILED,
	payload,
});

// Send Confirmation Email
export const onSendConfirmationEmail = (payload) => ({
	type: fromTypes.AUTHORISATION_SEND_CONFIRMATION_EMAIL,
	payload,
});

export const onSendConfirmationEmailFulfilled = (payload) => ({
	type: fromTypes.AUTHORISATION_SEND_CONFIRMATION_EMAIL_FULFILLED,
	payload,
});

export const onSendConfirmationEmailFailed = (payload) => ({
	type: fromTypes.AUTHORISATION_SEND_CONFIRMATION_EMAIL_FAILED,
	payload,
});
