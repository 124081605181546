import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import * as fromTypes from "../actions/types";

import {
	editSaveSuccess,
	editSaveFailed,
	onEditClose,
	organisationFetch,
} from "../actions";

export const organisationEditEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.ORGANISATIONS_EDIT_SAVE),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/organisation/update`,
					{
						id: state$.value.organisations.editOrganisationForm.id,
						name: state$.value.organisations.editOrganisationForm
							.name,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							organisationFetch(),
							editSaveSuccess(ajaxResponse.response),
							onEditClose()
						)
					),
					catchError((error) => of(editSaveFailed(error.message)))
				)
		)
	);
