import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";

import { RadioListInput } from "frame/components/input";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(1, 0, 3, 0),
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0),
		height: 2,
	},
}));

const SafetyInspectionReviewForm = ({
	className,
	disabled,
	values,
	onSelectChange,
}) => {
	const classes = useStyles();

	const options = [
		{
			label: "Yes",
			value: "yes",
		},
		{
			label: "NA",
			value: "na",
		},
	];

	return (
		<CardContent className={classes.root}>
			<Divider className={classes.divider} />
			<CardHeader
				className={classes.heading}
				title="Safety Inspections and Safety Reviews"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						We undertake pre-inspection and site inspections.
					</Typography>
					<RadioListInput
						data-cy={"undertakeInspection"}
						disabled={disabled}
						initialValue={values.undertakeInspection}
						options={options}
						onChange={onSelectChange("undertakeInspection")}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						Other inspections such as vehicle, major plan and
						equipment.
					</Typography>
					<RadioListInput
						data-cy={"otherInspections"}
						disabled={disabled}
						initialValue={values.otherInspections}
						options={options}
						onChange={onSelectChange("otherInspections")}
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

SafetyInspectionReviewForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onSelectChange: PropTypes.func.isRequired,
};

export default SafetyInspectionReviewForm;
