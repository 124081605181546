import { connect } from "react-redux";
import Customer from "./customer";
import { getCustomerById } from "../utils";

const mapStateToProps = (state) => ({
	customer: getCustomerById(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
