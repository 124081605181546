const FRAME_INITIALISE_REQUESTED = "FRAME_INITIALISE_REQUESTED";
const FRAME_HAMBURGER_CLICKED = "FRAME_HAMBURGER_CLICKED";
const FRAME_SIGNOUT_CLICKED = "FRAME_SIGNOUT_CLICKED";
const FRAME_MOBILE_NAV_CLOSED = "FRAME_MOBILE_NAV_CLOSED";

export default {
	FRAME_INITIALISE_REQUESTED,
	FRAME_HAMBURGER_CLICKED,
	FRAME_SIGNOUT_CLICKED,
	FRAME_MOBILE_NAV_CLOSED,
};
