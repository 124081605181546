import { connect } from "react-redux";
import InductionMinutesList from "./induction-minutes-list";
import {
	onRemove,
	onInductionMinutesSearchChanged,
	onInductionMinutesPagedListRequest,
} from "../../actions";

const mapStateToProps = ({ healthSafety, profile, organisations }) => ({
	inductions: healthSafety.inductions,
	role: profile.role,
	searchFilter: healthSafety.searchFilter,
	currentOrganisationGUID: organisations.currentOrganisation,
});

const mapDispatchToProps = {
	onDelete: onRemove,
	onSearchChanged: onInductionMinutesSearchChanged,
	onGetInductionList: onInductionMinutesPagedListRequest,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InductionMinutesList);
