import uuid from "uuid";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError } from "rxjs/operators";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import {
	onStaffTrainingTopicListRequest,
	INFO_STAFF_TRAINING_TOPIC_ROUTE,
} from "features/health-and-safety";
import { ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT } from "../actions/types";
import { getCurrentTimestamp, isProd } from "utils";
import ReactGA from "react-ga";
import {
	onAddStaffTrainingTopicSaveFailed,
	onAddStaffTrainingTopicSaveSuccess,
	onAddStaffTrainingTopicReset,
} from "../actions";

const addStaffTrainingTopicEffect = (action$, state$) =>
	action$.pipe(
		ofType(ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT),
		mergeMap(() => {
			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/staff-training-Topic/createorupdate`,
					{
						id:
							state$.value.addStaffTrainingTopicReducer.id ||
							uuid.v4(),
						createdAt:
							state$.value.addStaffTrainingTopicReducer.createdAt,
						name: state$.value.addStaffTrainingTopicReducer.training
							.name,
						guidelines:
							state$.value.addStaffTrainingTopicReducer.training
								.guidelines,
						validMeasurement:
							state$.value.addStaffTrainingTopicReducer.training
								.validMeasurement,
						validDuration:
							state$.value.addStaffTrainingTopicReducer.training
								.validDuration,
						recipients:
							state$.value.addStaffTrainingTopicReducer
								.recipients,
						recipientsOther:
							state$.value.addStaffTrainingTopicReducer
								.recipientsOther,
						sendEmail:
							state$.value.addStaffTrainingTopicReducer
								.sendToRecipientsOnSave,
						latitude: state$.value.geolocation.latitude,
						longitude: state$.value.geolocation.longitude,
						timestamp: getCurrentTimestamp(),
						organisationId:
							state$.value.organisations.currentOrganisation,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						isProd &&
							ReactGA.event({
								category: "Info",
								action: "Save Clicked",
								label: "Add Staff Training Topic",
							});
						return of(
							onStaffTrainingTopicListRequest(),
							onAddStaffTrainingTopicSaveSuccess(),
							onAddStaffTrainingTopicReset(),
							push(INFO_STAFF_TRAINING_TOPIC_ROUTE)
						);
					}),
					/* eslint-disable */
					catchError((error) =>
						of(onAddStaffTrainingTopicSaveFailed(error.message))
					)
					/* eslint-enable */
				);
		})
	);

export default addStaffTrainingTopicEffect;
