import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Menu, MenuItem } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { RemoveWarning } from "frame/components";
import { useRemover, useIsBornAccount } from "utils";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FolderIcon from "@material-ui/icons/Folder";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EmailIcon from "@material-ui/icons/Email";
import ViewIcon from "@material-ui/icons/Subject";

const useStyles = makeStyles((theme) => ({
	optionsIcon: {
		pointerEvents: "none",
	},
	optionsMenu: {
		position: "absolute",
		right: 0,
		top: 0,
	},
	optionIcon: {
		marginRight: theme.spacing(1),
		pointerEvents: "none",
	},
}));

const CrudOptions = ({
	onDelete,
	onView,
	onEdit,
	onArchive,
	onMakeActive,
	onClone,
	onEmail,
	onAppend,
	customOptions = [],
	bornOnlyActions = [],
	customRemoveModelText,
}) => {
	const classes = useStyles();
	const isBornAccount = useIsBornAccount();

	const [openOptions, setOpenOptions] = React.useState(false);
	const { remove, onPossiblyRemove, onCancelRemove } = useRemover();

	const options = [
		{
			label: "View",
			icon: ViewIcon,
			onClick: onView,
		},
		{
			label: "Edit",
			icon: EditIcon,
			onClick: onEdit,
		},
		{
			label: "Append",
			icon: EditIcon,
			onClick: onAppend,
		},
		{
			label: "Email",
			icon: EmailIcon,
			onClick: onEmail,
		},
		{
			label: "Clone",
			icon: FileCopyIcon,
			onClick: onClone,
		},
		{
			label: "Delete",
			icon: DeleteIcon,
			onClick: onDelete ? onPossiblyRemove : null,
		},
		{
			label: "Archive",
			icon: FolderIcon,
			onClick: onArchive,
		},
		{
			label: "Make Active",
			icon: CheckCircleIcon,
			onClick: onMakeActive,
		},
		...customOptions,
	].filter((x) => x.onClick && x.label && x.icon);

	const isBornOnlyAction = (label) =>
		isBornAccount && bornOnlyActions.includes(label);

	return (
		<div
			className={classes.optionsMenu}
			onClick={(e) => e.stopPropagation()}
		>
			<RemoveWarning
				open={remove}
				onCancel={onCancelRemove}
				onConfirm={() => onDelete()}
			>
				{customRemoveModelText
					? customRemoveModelText
					: "Warning, deleting this record is permanent and cannot be undone."}
			</RemoveWarning>

			<IconButton
				data-cy="options_menu"
				aria-label="options menu"
				aria-controls="options-menu"
				aria-haspopup="true"
				onClick={(e) => setOpenOptions(e.currentTarget)}
			>
				<MoreVertIcon className={classes.optionsIcon} />
			</IconButton>

			{openOptions && (
				<Menu
					id="options-menu"
					anchorEl={openOptions}
					keepMounted
					open={openOptions ? true : false}
					onClose={() => setOpenOptions(false)}
				>
					{options.map((option, index) => (
						<MenuItem
							key={index}
							id={option.label}
							data-cy={option.label.toLowerCase()}
							onClick={() => {
								option.onClick();
								setOpenOptions(false);
							}}
						>
							<option.icon className={classes.optionIcon} />
							{option.label}
							{isBornOnlyAction(option.label) && " (Born Only)"}
						</MenuItem>
					))}
				</Menu>
			)}
		</div>
	);
};

CrudOptions.propTypes = {
	onDelete: PropTypes.func,
	onView: PropTypes.func,
	onEdit: PropTypes.func,
	onArchive: PropTypes.func,
	onMakeActive: PropTypes.func,
	onClone: PropTypes.func,
	onEmail: PropTypes.func,
	onAppend: PropTypes.func,
	customOptions: PropTypes.array,
	bornOnlyActions: PropTypes.array,
	customRemoveModelText: PropTypes.string,
};

export default CrudOptions;
