import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Typography,
} from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import TaskForm from "./task-form";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	actions: {
		marginTop: 0,
		paddingTop: 0,
	},
	actionsDivider: {
		display: "flex",
		margin: theme.spacing(0, 0, 2, 0),
		width: "100%",
		color: theme.palette.divider,
	},
	field: {
		margin: theme.spacing(-1, 0),
	},
	heading: {
		marginBottom: theme.spacing(-2),
	},
	errorMessage: {
		marginTop: theme.spacing(1),
		color: theme.palette.error.main,
	},
}));

const createDefaultStep = (index = 0) => ({
	index,
});

const createDefaultTask = (index = 0, stepIndex = 0) => ({
	index,
	steps: [createDefaultStep(stepIndex)],
});

const JobSafetyAnalysisForm = ({ disabled, values, onChange, apiError }) => {
	const classes = useStyles();
	disabled = values.save.saving || disabled;

	const [error, setError] = useState();
	const [addTaskDisabled, setAddTaskDisabled] = useState(true);
	const tasks =
		values.tasks && values.tasks.length
			? values.tasks
			: [createDefaultTask()];

	useEffect(() => {
		apiError && apiError !== "" && setError(apiError);
	}, [apiError]);

	useEffect(() => {
		const current = tasks
			.map(({ index, steps, ...task }) => task)
			.filter((task) => Object.keys(task).length);

		setAddTaskDisabled(tasks.length !== current.length);
	}, [tasks, setAddTaskDisabled]);

	const onAddStep = (taskIndex) => () => {
		const nextTasks = [...tasks];
		const task = nextTasks[taskIndex];
		nextTasks[taskIndex].steps = [
			...task.steps,
			createDefaultStep(task.steps.length),
		];
		// values.tasks[taskIndex] = task
		onChange({
			...values,
			tasks: nextTasks,
		});
	};

	const onAddTask = () => {
		onChange({
			...values,
			tasks: [...tasks, createDefaultTask(tasks.length)],
		});
	};

	const onDeleteStep = (taskIndex) => (stepIndex) => {
		const nextTasks = [...tasks];
		const nextSteps = nextTasks[taskIndex].steps
			.filter((_, index) => index !== stepIndex)
			.map((step, index) => ({ ...step, index }));

		nextTasks[taskIndex].steps = nextSteps.length
			? nextSteps
			: [createDefaultStep()];

		onChange({
			...values,
			tasks: nextTasks,
		});
	};

	const onDeleteTask = (taskIndex) => () => {
		const nextTasks = tasks
			.filter((_, index) => index !== taskIndex)
			.map((task, index) => ({ ...task, index }));

		onChange({
			...values,
			tasks: nextTasks.length ? nextTasks : [createDefaultTask()],
		});
	};

	const onChangeStep = (taskIndex) => (stepIndex, key) => (event) => {
		const value =
			(event.target && event.target.value) ||
			(typeof event === "string" && event) ||
			event ||
			"";
		const nextTasks = [...tasks];
		const task = nextTasks[taskIndex];
		const step = task.steps[stepIndex];
		step[key] = value;
		nextTasks[taskIndex].steps[stepIndex] = step;

		onChange({
			...values,
			tasks: Array.from(new Set(nextTasks)).filter(
				(x) => x.name || x.description
			),
		});
	};

	const onChangeTask =
		(taskIndex) =>
		(key) =>
		({ target: { value } }) => {
			const nextTasks = [...tasks];
			const task = nextTasks[taskIndex] || {};
			task[key] = value;
			nextTasks[taskIndex] = task;

			onChange({
				...values,
				tasks: Array.from(new Set(nextTasks)).filter(
					(x) => x.name || x.description
				),
			});
		};

	const onInputChange =
		(key) =>
		({ target: { value } }) =>
			onChange({
				...values,
				[key]: value,
			});

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Job Safety Analysis (JSA)"
				titleTypographyProps={{ variant: "h4" }}
			/>

			<CardContent>
				<TextValidator
					id="name"
					disabled={disabled}
					className={classes.field}
					fullWidth
					label="Name"
					value={values.name || ""}
					variant="outlined"
					validators={["required"]}
					error={!!error}
					errorMessages={["This field is required"]}
					onChange={(e) => {
						onInputChange("name")(e);
						setError(null);
					}}
				/>
				{error && (
					<Typography
						variant="body1"
						className={classes.errorMessage}
					>
						{error}
					</Typography>
				)}
			</CardContent>

			{tasks.map((task) => (
				<TaskForm
					key={`task_${task.index}`}
					disabled={disabled}
					task={task}
					onAddStep={onAddStep(task.index)}
					onChangeStep={onChangeStep(task.index)}
					onChangeTask={onChangeTask(task.index)}
					onDeleteStep={onDeleteStep(task.index)}
					onDeleteTask={onDeleteTask(task.index)}
				/>
			))}
			<CardContent className={classes.actions}>
				<Divider className={classes.actionsDivider} />
				<Button
					color="primary"
					className={classes.button}
					disabled={addTaskDisabled || disabled}
					size="small"
					variant="outlined"
					onClick={onAddTask}
				>
					+ Add Task
				</Button>
			</CardContent>
		</Card>
	);
};

JobSafetyAnalysisForm.propTypes = {
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	apiError: PropTypes.string,
};

const mapStateToProps = ({ addJobSafetyAnalysisFormReducer }) => ({
	apiError: addJobSafetyAnalysisFormReducer.save.error,
});

export default connect(mapStateToProps)(JobSafetyAnalysisForm);
