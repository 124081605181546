import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { StyledIconButton } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
	},
	email: {
		margin: theme.spacing(1, 0),
	},
	icon: {
		width: 20,
		height: 20,
	},
}));

const AlertEmail = ({ email, onRemove }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography className={classes.email} variant="body1">
				{email}
			</Typography>
			<StyledIconButton onClick={onRemove}>
				<DeleteIcon className={classes.icon} />
			</StyledIconButton>
		</div>
	);
};

AlertEmail.propTypes = {
	email: PropTypes.string,
	onRemove: PropTypes.func,
};

export default AlertEmail;
