import Types from "./types";

export default (title, message, closeLabel) => ({
	type: Types.NOTIFICATIONS_SHOW_DIALOG_REQUESTED,
	payload: {
		title,
		message,
		closeLabel,
	},
});
