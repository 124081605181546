import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextValidator } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(2, 1),
	},
	divider: {
		display: "flex",
		margin: theme.spacing(2, 0),
		width: "100%",
		color: theme.palette.divider,
	},
}));

const mapStateToProps = ({ organisations }) => {
	return {
		currentOrganisationGUID: organisations.currentOrganisation,
	};
};

export const EmailDropdown = connect(mapStateToProps)(
	({ disabled, onChange, emailOptions, emailList, placeholder }) => {
		const classes = useStyles();
		const [personCount, setPersonCount] = useState(
			emailList && emailList.length > 0 ? emailList.length : 1
		);
		const recipients = emailList || [];

		const inputChange = (input, index) => {
			const next =
				emailList && emailList.length > 0 ? [...emailList] : [];
			next[index] = input.value;

			onChange(next);
		};

		const onAddPerson = () => {
			if (recipients.length === personCount) {
				setPersonCount(personCount + 1);
			}
		};

		const renderPeople = () => {
			const nodes = [];

			for (let i = 0; i < personCount; i++) {
				nodes.push(
					<Grid key={`recipient_other_${i}`} item xs={12}>
						<Autocomplete
							id={"search_dropdown"}
							options={emailOptions}
							getOptionLabel={(option) => option && option.label}
							onChange={(_, input) => {
								if (input != null) {
									inputChange(input, i);
								}
							}}
							renderInput={(params) => {
								return (
									<TextValidator
										{...params}
										label={recipients[i] || placeholder}
										variant="outlined"
									/>
								);
							}}
						/>
					</Grid>
				);
			}
			return nodes;
		};

		return (
			<Grid container spacing={2}>
				{emailOptions && <>{renderPeople()}</>}
				<Button
					color="primary"
					className={classes.button}
					disabled={disabled}
					size="small"
					variant="outlined"
					onClick={onAddPerson}
				>
					+ Add
				</Button>
			</Grid>
		);
	}
);
