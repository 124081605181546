import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "utils";
import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";

const GoogleAnalytics = ({ options = {} }) => {
	const [tracking, setTracking] = useState(false);

	const analytics = Analytics({
		app: "SafetyWise app",
		plugins: [
			googleTagManager({
				containerId: "GTM-5XV64SW"
			})
		]
	});

	const {
		location: { pathname, search }
	} = useRouter();

	const trackPage = useCallback(() => {
		const page = pathname + search;
		const { location } = window;

		analytics.page({
			url: page,
			path: location.href
		});
	}, [options, pathname, search]);

	useEffect(() => {
		if (tracking) {
			trackPage();
		}
	}, [pathname, search, tracking, trackPage]);

	useEffect(() => {
		const location = window.location.href || "";
		const isGAEnabled = !(
			location.includes("//localhost:") ||
			location.includes("uat") ||
			location.includes("dev")
		);
		
		if (isGAEnabled) {
			setTracking(true);
		}
	}, [setTracking]);

	return null;
};

GoogleAnalytics.propTypes = {
	options: PropTypes.object
};

export default GoogleAnalytics;
