export const timesheetAdminActionTypes = {
	ADD_ADMIN_TIMESHEET_FORM_VALUE_CHANGE:
		"ADD_ADMIN_TIMESHEET_FORM_VALUE_CHANGE",
	ADD_ADMIN_TIMESHEET_FORM_RESET: "ADD_ADMIN_TIMESHEET_FORM_RESET",
	ADD_ADMIN_TIMESHEET_FORM_SUBMIT: "ADD_ADMIN_TIMESHEET_FORM_SUBMIT",
	ADD_ADMIN_TIMESHEET_FORM_SUBMIT_FAILED:
		"ADD_ADMIN_TIMESHEET_FORM_SUBMIT_FAILED",
	ADD_ADMIN_TIMESHEET_FORM_SUBMIT_SUCCESS: "ADD_ADMIN_TIMESHEET_FORM_SUCCESS",
};

export const onAddAdminTimesheetValueChange = (payload) => ({
	type: timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_VALUE_CHANGE,
	payload,
});

export const onAddAdminTimesheetReset = (payload) => ({
	type: timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_RESET,
	payload,
});

export const onAddAdminTimesheetSave = (payload) => ({
	type: timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_SUBMIT,
	payload,
});

export const onAddTimesheetSaveFailed = (payload) => ({
	type: timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddTimesheetSaveSuccess = (payload) => ({
	type: timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_SUBMIT_SUCCESS,
	payload,
});
