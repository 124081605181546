import { authHeaders } from "frame/auth-headers";
import { getBaseUrl } from "frame/base-url";
import { useSelector } from "react-redux";
import { isNotNullOrEmpty, isDefaultGuid } from "utils";

/******************************************************************************************** */
/* General helper functions                                                                   */
/******************************************************************************************** */

export const isUAT = window.location.href.includes("uat");
export const isDev = window.location.href.includes("dev");
export const isLocal = window.location.href.includes("local");
export const isProd =
	window.location.href.includes("app.safetywise.co.nz") ||
	window.location.href.includes("app-prod.safetywise.co.nz") ||
	window.location.href.includes("app.fortherecord.pro") ||
	window.location.href.includes("app-prod.fortherecord.pro");

export const useIsOnline = () => useSelector((state) => state.queue.hasNetwork);

// used with createRowSelector for ignoring card click on button click in list cards eg. in staff-listings.js
const searchParentNodesForClickIgnore = (node) => {
	if (
		node &&
		node.getAttribute &&
		node.getAttribute("role") === "presentation"
	) {
		return true;
	}
	if (
		node &&
		typeof node.className === "string" &&
		node.className.includes("ignore")
	) {
		return true;
	} else if (node.parentNode) {
		return searchParentNodesForClickIgnore(node.parentNode);
	}
	return false;
};

// use to create row selectors for library list
// add className="ignore" to row component to ignore row click
export const createRowSelector = (onClick) => (event) => {
	if (searchParentNodesForClickIgnore(event.target)) {
		return;
	}
	onClick(event);
};

// validate and scroll to ref
export const validateAndScrollTo = (validRule, scrollToRef, hideErrorFunc) => {
	!validRule &&
		scrollToRef.current &&
		scrollToRef.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	hideErrorFunc && hideErrorFunc(!validRule);
	return validRule;
};

/* 
  DEPRECIATED
  summary: dynamic async look up function using fetch()
  required params: resource - api endpoint name
  optional params: id - specify id for fetch by id, else defaults to 'all' fetching list data  
  returns: desired lookup resource object/list 
  eg. fetchResource(organisationId, customer) for customer list
      fetchResource(organisationId, customer, '[guid]', ) for customer by id 
      
*/
// export const fetchResource = async (organisationId, resource, id = 'all') => {
//   const url = `${getBaseUrl()}/api/${organisationId}/${resource}/${id}`
//   const response = await fetch(url, { headers: authHeaders() })
//   const responseJSON = await response.json()
//   return responseJSON
// }

/*
  lookup request function
  eg. used for register form organisation name field
*/
export const lookupResource = async (resource, search) => {
	const url = `${getBaseUrl()}/api/lookup/${resource}?search=${search}`;
	const response = await fetch(url, { headers: authHeaders() });
	const responseJSON = await response.json();
	return responseJSON;
};

/*
  start timesheet request function
  eg. used in src\features\forms\components\timesheet-staff-form.js
  for persisting the in progress timesheet even if user logs out or restarts phone
*/
export const startTimesheetRequest = async (organisationId, body) => {
	const url = `${getBaseUrl()}/api/${organisationId}/timesheet/save-timesheet-in-progress`;
	const response = await fetch(url, {
		method: "POST",
		headers: authHeaders(),
		body,
	});
	const responseJSON = await response.json();
	return responseJSON;
};

export const useLookup = (
	id,
	resourceName,
	attribute = "name",
	isHealthSafety = false
) => {
	const resourceList = useSelector((state) =>
		isHealthSafety
			? state.healthSafety[resourceName].pagination?.results
			: state[resourceName].pagination?.results
	);

	if ((id && isDefaultGuid(id)) || !resourceName || !id || !resourceList) {
		return "-";
	}

	const resource =
		id &&
		isNotNullOrEmpty(resourceList) &&
		resourceList.filter((r) => r.id === id)[0];
	return resource ? resource[attribute] : "-";
};
