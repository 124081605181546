import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { getBaseUrl } from "frame";
import fromTypes from "../actions/types";

import { onConfirmationFulfilled, onConfirmationFailed } from "../actions";

const confirmationEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.AUTHORISATION_CONFIRMATION),
		mergeMap(({ payload }) =>
			ajax.post(`${getBaseUrl()}/api/confirm${payload}`).pipe(
				mergeMap((ajaxResponse) =>
					of(onConfirmationFulfilled(ajaxResponse.response))
				),
				catchError((error) => onConfirmationFailed(error))
			)
		)
	);

export default confirmationEffect;
