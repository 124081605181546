import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import { Modal, Card, CardContent, Typography, Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	SelectInput,
} from "frame/components";
import { isDefaultDate } from "utils";
import { useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		justifyContent: "flex-end",
		marginTop: theme.spacing(3),
	},
	warning: {
		padding: theme.spacing(1, 0),
		color: theme.palette.error.main,
	},
	emptyOption: {
		display: "none",
	},
}));

const EditHazardousSubstanceToSiteForm = ({
	siteId,
	substanceId,
	values,
	onChange,
	onSave,
	isEditHazardousSubstanceToSiteModalShowing,
	onClose,
}) => {
	const classes = useStyles();

	const [originalData] = useState(values); // original site state - current site
	const [currentSubstances, setCurrentSubstances] = useState(
		values.substances || []
	); // current site substances

	const [currentSiteSubstance, setCurrentSiteSubstance] = useState(null); // Substance details

	const hazardousSubstanceList = useSelector(
		({ healthSafety }) => healthSafety.hazardousSubstances?.results
	);

	useEffect(() => {
		async function fetchSubstanceData() {
			const substance =
				hazardousSubstanceList &&
				hazardousSubstanceList.filter((hs) => hs.id === substanceId)[0];
			setCurrentSiteSubstance(substance || null);
		}

		if (substanceId) {
			fetchSubstanceData();
		} // eslint-disable-next-line
	}, [substanceId]);

	// let currentSiteSubstance = initialData && initialData.substances.filter(node => node.hazardousSubstanceId === substanceId)[0]
	// let siteSubstanceDetails = hazardousSubstanceList.filter(node => node.id === substanceId)[0]
	const currentIndex =
		currentSiteSubstance &&
		currentSubstances &&
		currentSubstances.findIndex(
			(h) => h.hazardousSubstanceId === currentSiteSubstance.id
		);

	if (!isEditHazardousSubstanceToSiteModalShowing || !substanceId) {
		return null;
	}

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(siteId);
		onClose();
	};

	const onExit = () => {
		onChange(originalData); // reset substances states
		onClose();
	};

	const onCancel = () => onExit();
	const onClickAway = () => onExit();

	const onStatusChanged = (value) => {
		if (values && currentSiteSubstance) {
			let substances = values.substances;
			substances[currentIndex].status = value;
			onChange({ ...values, substances });
			setCurrentSubstances(substances);
		}
	};

	const onInputChange =
		(key) =>
		({ target: { value } }) => {
			if (values && currentSiteSubstance) {
				let substances = values.substances;
				substances[currentIndex][key] = value;
				onChange({ ...values, substances });
				setCurrentSubstances(substances);
			}
		};

	const onDateChange = (key) => (value) => {
		if (values && currentSiteSubstance) {
			let substances = values.substances;
			substances[currentIndex][key] = new moment(value).format();
			onChange({ ...values, substances });
			setCurrentSubstances(substances);
		}
	};

	return (
		<Modal open={true} onClose={onClose} onBackdropClick={onClickAway}>
			<Card className={classes.root}>
				<ValidatorForm autoComplete="off" onSubmit={onSubmit}>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography
									align="center"
									gutterBottom
									variant="h3"
								>
									{`Edit ${
										(currentSiteSubstance &&
											currentSiteSubstance.name) ||
										"substance"
									} at ${originalData.siteName || "site"}`}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextValidator
									id="site_substance_location"
									fullWidth
									label="Location"
									value={
										currentSubstances &&
										currentSubstances[currentIndex]
											? currentSubstances[currentIndex]
													.location
											: ""
									}
									variant="outlined"
									inputProps={{ maxLength: 50 }}
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={onInputChange("location")}
								/>
							</Grid>
							<Grid item sm={8} xs={12}>
								<TextValidator
									id="site_substance_volume"
									fullWidth
									label={`Volume ${
										currentSiteSubstance &&
										currentSiteSubstance.volumeMeasurement
											? `(${currentSiteSubstance.volumeMeasurement})`
											: ""
									}`.trim()}
									value={
										currentSubstances &&
										currentSubstances[currentIndex]
											? currentSubstances[currentIndex]
													.volume
											: ""
									}
									variant="outlined"
									type="number"
									onKeyDown={(e) =>
										e.key === "e" && e.preventDefault()
									}
									inputProps={{ maxLength: 10 }}
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={onInputChange("volume")}
								/>
							</Grid>
							<Grid item sm={4} xs={12}>
								<DatePicker
									data-cy="site_substance_date"
									format="D MMMM YYYY"
									fullWidth
									inputVariant="outlined"
									label="Date volume was measured"
									value={
										currentSubstances &&
										currentSubstances[currentIndex] &&
										!isDefaultDate(
											currentSubstances[currentIndex]
												.dateMeasured
										)
											? currentSubstances[currentIndex]
													.dateMeasured
											: null
									}
									variant="outlined"
									disableFuture={true}
									onChange={onDateChange("dateMeasured")}
								/>
							</Grid>
							<Grid item xs={12}>
								<SelectInput
									noNone
									id="site_substance_status"
									label="Status"
									placeholder="Select Status"
									options={["Active", "Archived"]}
									value={
										currentSubstances &&
										currentSubstances[currentIndex]
											? currentSubstances[currentIndex]
													.status
											: "Active"
									}
									onChange={onStatusChanged}
								/>
							</Grid>
						</Grid>
						<FormActions className={classes.actions}>
							<FormSubmitButton>Save</FormSubmitButton>
							<FormButton onClick={onCancel}>Cancel</FormButton>
						</FormActions>
					</CardContent>
				</ValidatorForm>
			</Card>
		</Modal>
	);
};

EditHazardousSubstanceToSiteForm.propTypes = {
	siteId: PropTypes.string.isRequired,
	substanceId: PropTypes.string,
	isEditHazardousSubstanceToSiteModalShowing: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default EditHazardousSubstanceToSiteForm;
