import * as R from "ramda";
import {
	FETCH_UNCONFIRMED_INDUCTIONS_SUCCESS,
	FETCH_UNCONFIRMED_INDUCTIONS_ERROR,
	GET_DOCUMENT_PACK_COUNT_SUCCESS,
	GET_TIMESHEET_COUNT_SUCCESS,
	GET_ENTITY_COUNTS_ERROR,
} from "./home-actions";

const initialState = {
	unconfirmedInductions: null,
	error: null,
	documentPackCount: 0,
	timesheetCount: 0,
};

const homeReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_UNCONFIRMED_INDUCTIONS_SUCCESS:
			return fetchUnconfirmedInductionsSuccess(R.clone(state), action);
		case FETCH_UNCONFIRMED_INDUCTIONS_ERROR:
			return fetchUnconfirmedInductionsError(R.clone(state), action);
		case GET_DOCUMENT_PACK_COUNT_SUCCESS:
			return getDocumentPackCountSuccess(R.clone(state), action);
		case GET_TIMESHEET_COUNT_SUCCESS:
			return getTimesheetCountSuccess(R.clone(state), action);
		case GET_ENTITY_COUNTS_ERROR:
			return getEntityCountsError(R.clone(state), action);
		default:
			return state;
	}
};

const fetchUnconfirmedInductionsSuccess = (state, { payload }) => {
	if (payload) {
		state.unconfirmedInductions = payload.unconfirmedInductions;
	}
	return state;
};

const fetchUnconfirmedInductionsError = (state, { payload }) => {
	if (payload) {
		state.error = payload;
	}
	return state;
};
const getDocumentPackCountSuccess = (state, { payload }) => {
	state.documentPackCount = payload || 0;
	return state;
};

const getTimesheetCountSuccess = (state, { payload }) => {
	state.timesheetCount = payload || 0;
	return state;
};

const getEntityCountsError = (state, { payload }) => {
	return state;
};

export default homeReducer;
