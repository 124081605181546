import uuid from "uuid";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError, flatMap } from "rxjs/operators";
import {
	onSafetyPlanChecklistListRequest,
	INFO_SAFETY_PLAN_CHECKLISTS_ROUTE,
} from "features/health-and-safety";
// import { createSitePath, SITE_SAFETY_PLAN_CHECKLIST_PATH } from 'features/sites'
import { ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT } from "../actions/types";
// import { getResponseBody } from '../utils'
import {
	DBService,
	CHECKLIST_TABLE,
	replaceDataURLs,
	authHeaders,
	getOrganisationBaseUrl,
} from "frame";
import { getCurrentTimestamp, isProd } from "utils";
import * as R from "ramda";
import ReactGA from "react-ga";
import {
	onAddSafetyPlanChecklistReset,
	onAddSafetyPlanChecklistSaveFailed,
	onAddSafetyPlanChecklistSaveSuccess,
} from "../actions";

const fetchAttachments = () => async () => {
	const db = new DBService();
	var files = await db.getAll(CHECKLIST_TABLE);
	return files;
};

const addSafetyPlanChecklistEffect = (action$, state$) =>
	action$.pipe(
		ofType(ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT),
		flatMap(fetchAttachments()),
		mergeMap((result) => {
			const spcForm = R.clone(
				state$.value.addSafetyPlanChecklistFormReducer
			);
			const spc = R.clone(
				state$.value.addSafetyPlanChecklistFormReducer.safetyPlan
			);

			let notifiableWorksAttachments =
				spc.notifiableWorksAttachments &&
				replaceDataURLs(spc.notifiableWorksAttachments, result);
			let emergencyPlanAttachments =
				spc.emergencyPlanAttachments &&
				replaceDataURLs(spc.emergencyPlanAttachments, result);
			let attachments =
				spcForm.attachments &&
				replaceDataURLs(spcForm.attachments, result);

			let body = {
				id: spcForm.id || uuid.v4(),
				siteId: spcForm.siteId,
				createdAt: spcForm.createdAt,
				name: spc.name,

				onSiteSafetyRepresentative: spc.onSiteSafetyRepresentative,
				personInControlOfSite: spc.personInControlOfSite,
				mainPersonInCharge: spc.mainPersonInCharge,
				phone: spc.phone,
				client: spc.client,
				notes: spc.notes,

				// Notifiable Works
				haveNotifiableWorks: spc.haveNotifiableWorks,
				haveAdvisedWorksafeNZ: spc.haveAdvisedWorksafeNZ,
				notificationAttached: spc.notificationAttached,
				notifiableWorksAttachments: notifiableWorksAttachments,

				// Hazard Management
				willMaintainOnSiteHazardRegister:
					spc.willMaintainOnSiteHazardRegister,
				willPrepareTaskAnalysis: spc.willPrepareTaskAnalysis,
				assosicatedWithOtherWorks: spc.assosicatedWithOtherWorks,

				// Communication Employee Participation Frequency
				safetyMinutesFrequency:
					spc.communicationParticipationSafetyMinutesFrequency,
				preTaskPlanningMeetingFrequency:
					spc.communicationParticipationPreTaskPlanningMeetingFrequency,
				inductionMeetingFrequency:
					spc.communicationParticipationInductionMeetingFrequency,

				// Emergencies
				firstAidKitLocation: spc.firstAidKitLocation,
				firstAidPerson: spc.firstAidPerson,
				emergencyPhone: spc.emergencyPhone,
				haveTrainedPersonnelProceduresOnSite:
					spc.haveTrainedPersonnelProceduresOnSite,
				haveAttachedEmergencyPlan: spc.haveAttachedEmergencyPlan,
				willReportToSafetyRepresentative:
					spc.willReportToSafetyRepresentative,
				emergencyPlanAttachments: emergencyPlanAttachments,

				// Accident/Incident
				haveAccidentSystemInPlace: spc.haveAccidentSystemInPlace,
				willImmediatelyNotifyAllAccidents:
					spc.willImmediatelyNotifyAllAccidents,

				// Safety Inspections and Safety Reviews
				undertakeInspection: spc.undertakeInspection,
				otherInspections: spc.otherInspections,

				// Training/Inductions
				allHoldHealthSafetyQualifications:
					spc.allHoldHealthSafetyQualifications,
				allGivenSafetyInduction: spc.allGivenSafetyInduction,
				allAppropriatelyQualified: spc.allAppropriatelyQualified,

				// Declaration
				declarationName: spc.declarationName,
				declarationDate: spc.declarationDate,
				declarationOtherPeople: spc.declarationOtherPeople
					? spc.declarationOtherPeople.filter(
							(p) => p.name && String(p.name).trim() !== ""
					  )
					: [],

				// Approval to start work
				approvalName: spc.approvalName,
				approvalDate: spc.approvalDate,

				recipients: spcForm.recipients,
				recipientsOther: spcForm.recipientsOther,
				sendEmail: spcForm.sendToRecipientsOnSave,

				// Attachments
				attachments: attachments,

				// Geo Location
				latitude: state$.value.geolocation.latitude,
				longitude: state$.value.geolocation.longitude,
				timestamp: getCurrentTimestamp(),
				organisationId: state$.value.organisations.currentOrganisation,
			};

			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/safety-plan-checklist/createorupdate`,
					body,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						isProd &&
							ReactGA.event({
								category: "Tasks",
								action: "Save Clicked",
								label: "Add Site Safety Plan",
							});

						return of(
							onSafetyPlanChecklistListRequest(),
							onAddSafetyPlanChecklistSaveSuccess(),
							onAddSafetyPlanChecklistReset(),
							push(INFO_SAFETY_PLAN_CHECKLISTS_ROUTE)
						);
					}),
					catchError((error) =>
						of(
							onAddSafetyPlanChecklistSaveFailed(
								(error.response && error.response.error) ||
									error.message
							)
						)
					)
				);
		})
	);

export default addSafetyPlanChecklistEffect;
