import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { isStaffUser } from "utils";
import AddIcon from "@material-ui/icons/Add";
import { PageHeader } from "frame/components";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	menu: {
		display: "flex",
		paddingTop: theme.spacing(1),
		marginBottom: theme.spacing(-3),
	},
	addIcon: {
		marginRight: theme.spacing(1),
	},
	primaryButton: {
		background: theme.palette.highlight,
		borderColor: theme.palette.highlight,
		"&:hover": {
			background: "#c1c72c",
			borderColor: "#c1c72c",
		},
	},
	button: {
		padding: theme.spacing(1, 4),
		color: theme.palette.brand,
		fontWeight: 600,
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(1, 3),
		},
	},
}));

const PageHeaderAddButton = ({
	role,
	title,
	breadcrumbs,
	addTabTitle,
	onAdd,
	staffUserHide = true,
	backActionPath,
	secondaryTabTitle,
	onSecondaryAction,
}) => {
	const classes = useStyles();
	const show = staffUserHide ? !isStaffUser(role) : true;

	return (
		<PageHeader
			canGoBack
			title={title}
			breadcrumbs={breadcrumbs}
			backActionPath={backActionPath}
		>
			{show && (
				<div className={classes.menu}>
					<Button
						className={clsx(classes.button, classes.primaryButton)}
						variant="contained"
						onClick={onAdd}
					>
						<AddIcon className={classes.addIcon} />
						{addTabTitle}
					</Button>

					{secondaryTabTitle && onSecondaryAction && (
						<Button
							style={{ marginLeft: 20 }}
							className={classes.button}
							variant="contained"
							onClick={onSecondaryAction}
						>
							{secondaryTabTitle}
						</Button>
					)}
				</div>
			)}
		</PageHeader>
	);
};

PageHeaderAddButton.propTypes = {
	role: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	breadcrumbs: PropTypes.string,
	addTabTitle: PropTypes.string.isRequired,
	onAdd: PropTypes.func.isRequired,
	secondaryTabTitle: PropTypes.string,
	onSecondaryAction: PropTypes.func,
	staffUserHide: PropTypes.bool,
	backActionPath: PropTypes.string,
};

const mapStateToProps = ({ profile }) => ({
	role: profile.role,
});

export default connect(mapStateToProps)(PageHeaderAddButton);
