import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	button: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		margin: 0,
		padding: 0,
		width: 40,
		height: 40,
		minWidth: 30,
		borderRadius: 20,
		overflow: "hidden",
		"&::after": {
			display: "flex",
			content: '" "',
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			background: "transparent",
		},
	},
}));

const StyledIconButton = ({ className, children, onClick, ...props }) => {
	const classes = useStyles();

	return (
		<Button
			{...props}
			size="small"
			color="primary"
			className={clsx(classes.button, className)}
			onClick={onClick}
		>
			{children}
		</Button>
	);
};

StyledIconButton.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default StyledIconButton;
