import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Page, PageContent, PageHeader } from "frame/components";
import MenuTabs from "./menu-tabs";
import NotFound from "./not-found";
import { ChildRoutes } from "./sites-routes";
import { SITES_PATH } from "../actions/types";

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const Site = ({ site }) => {
	const classes = useStyles();

	const name = (site && site.siteName) || "Not Found";
	const path = site && site.id ? `${SITES_PATH}/${site.id}` : SITES_PATH;

	const breadcrumbs = [
		{
			label: `Sites`,
			value: SITES_PATH,
		},
		{
			label: `${name}`,
			value: path,
		},
	];

	return (
		<Page className={classes.root} title="Sites">
			<PageHeader breadcrumbs={breadcrumbs} title={name} />
			{site ? (
				<PageContent>
					<MenuTabs />
					<ChildRoutes />
				</PageContent>
			) : (
				<PageContent pad>
					<NotFound />
				</PageContent>
			)}
		</Page>
	);
};

Site.propTypes = {
	site: PropTypes.object,
};

export default Site;
