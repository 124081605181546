import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError, tap, filter } from "rxjs/operators";
import { ofType } from "redux-observable";
import { push } from "connected-react-router";
import qs from "qs";
import { getBaseUrl, setToken } from "frame";
import { HOME_ROUTE } from "features/home/home-routes";
import fromTypes from "../actions/types";
import { iconDataUrl } from "utils";
import addNotification from "react-push-notification";

import { onLoginFailed, onLoginFulfilled, onTokenReceived } from "../actions";

const sendWelcomePushNotification = () =>
	// to prompt user to allow notifications on login
	addNotification({
		title: "Welcome Back",
		message: `You are now logged into ForTheRecord.`,
		native: true, // when using native, your OS will handle theming.
		duration: 120000, // 2 minutes - optional, default: 5000,
		icon: iconDataUrl,
		onClick: () => {
			window.location = `${window.location.origin}`;
		},
	});

const requestBody = (emailAddress, password) => {
	return {
		username: emailAddress,
		password,
		grant_type: "password",
	};
};

const saveAuthorizationToken = (data) => {
	setToken(data.access_token, data.expires_in);
};

const redirect = (router) => {
	if (
		router &&
		router.location &&
		router.location.search &&
		router.location.search.includes("?location=")
	) {
		if (
			router.location.search.length >
			router.location.search.indexOf("=") + 2
		) {
			let value = router.location.search.substring(
				router.location.search.indexOf("=") + 2
			);
			return value;
		}
	}
	return HOME_ROUTE;
};

const loginEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.AUTHORISATION_LOGIN_REQUESTED),
		filter(() => state$.value.authorisation.login.isValid),
		mergeMap(() =>
			ajax
				.post(
					`${getBaseUrl()}/oauth2/token`,
					qs.stringify(
						requestBody(
							state$.value.authorisation.login.emailAddress,
							state$.value.authorisation.login.password
						)
					)
				)
				.pipe(
					tap((ajaxResponse) =>
						saveAuthorizationToken(ajaxResponse.response)
					),
					mergeMap((ajaxResponse) => {
						const isIOS = /iPad|iPhone|iPod/.test(
							navigator.userAgent
						);
						!isIOS && sendWelcomePushNotification();

						return of(
							onTokenReceived(ajaxResponse.response),
							onLoginFulfilled(),
							push(redirect(state$.value.router))
						);
					}),
					catchError((error) => of(onLoginFailed(error)))
				)
		)
	);

export default loginEffect;
