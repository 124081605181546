import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button, colors } from "@material-ui/core";
import { isUnsupportedFileExtension } from "utils";
import File from "./file";
import moment from "moment";
import uuid from "uuid";
import { DBService, getTableName } from "frame/indexedDBService";

const useStyles = makeStyles((theme) => ({
	uploadButton: {
		margin: theme.spacing(2, 0),
	},
	clearButton: {
		color: theme.palette.white,
		backgroundColor: colors.red[600],
		"&:hover": {
			backgroundColor: colors.red[900],
		},
	},
	error: {
		color: theme.palette.error.main,
	},
}));

const UploadFile = ({ values, reference, onFileChange, disabled }) => {
	const classes = useStyles();
	const fileUploader = useRef();
	const [fileList, setFileList] = useState(values[reference] || []);
	const [error, setError] = useState("");
	const db = new DBService();

	const encodeImageFileAsURL = (event) => {
		const attachmentList = [];
		const files = Array.from(event.target.files);
		let valid = true;

		const MAX_FILE_NUMBER = 10;
		if (files.length > MAX_FILE_NUMBER) {
			setError(
				`You can upload a maximum of ${MAX_FILE_NUMBER} files. Please try again.`
			);
			valid = false;
		}

		if (valid) {
			files.forEach((file) => {
				let fileName = file.name;
				const lastDot = file.name.lastIndexOf(".");
				let fileExtension = fileName
					.substring(lastDot + 1)
					.toLowerCase();

				if (isUnsupportedFileExtension(fileExtension)) {
					setError(`${fileExtension} files are not allowed.`);
					valid = false;
				}

				const MAX_SIZE = 50 * 1024 * 1024; // 50 MB
				if (file.size >= MAX_SIZE) {
					setError(
						"Upload size exceeded (Limit: 50 MB). Please try again with smaller file."
					);
					valid = false;
				}
			});
		}

		if (valid) {
			files.forEach((file) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);

				let fileName = file.name;
				const lastDot = file.name.lastIndexOf(".");
				let fileExtension = fileName
					.substring(lastDot + 1)
					.toLowerCase();

				reader.onloadend = () => {
					const newFileId = uuid.v4();
					attachmentList.push({
						id: newFileId,
						fileName,
						fileExtension,
						dataURL: "",
						CreatedAt: moment().format("D MMM YYYY h:mm A"),
					});

					db.put(getTableName(), {
						id: newFileId,
						fileName,
						fileExtension,
						dataURL: reader.result,
						createdAt: moment().format("D MMM YYYY h:mm A"),
					});

					setFileList(fileList.concat(attachmentList));
					onFileChange(reference, fileList.concat(attachmentList));
				};
			});
		}

		fileUploader.current.value = null;
	};

	const clearFiles = () => {
		for (let i in values[reference]) {
			db.delete(getTableName(), values[reference][i].id);
		}

		setFileList([]);
		onFileChange(reference, []);
		setError("");
	};

	useEffect(() => {
		setTimeout(() => {
			setError("");
		}, 2000);
		// eslint-disable-next-line
	}, [error]);

	const onDelete = (index) => {
		const attachments = fileList.filter(
			(file) => fileList.indexOf(file) !== index
		);
		setFileList(attachments);
		onFileChange(reference, attachments);

		const fileToDelete = values[reference].filter(
			(file) => values[reference].indexOf(file) === index
		)[0];
		db.delete(getTableName(), fileToDelete.id);
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<input
					id="fileUploader"
					type="file"
					accept="application/msword, text/plain, application/pdf, image/*"
					onChange={encodeImageFileAsURL}
					ref={fileUploader}
					style={{ display: "none" }}
					multiple
				/>
				<Button
					onClick={() => fileUploader.current.click()}
					color="primary"
					className={classes.uploadButton}
					disabled={disabled}
					size="small"
					variant="outlined"
				>
					Choose File
				</Button>
			</Grid>
			<Grid item xs={12}>
				{fileList.length > 0 &&
					fileList.map((file, index) => (
						<File
							key={index}
							file={file}
							onDelete={() => onDelete(index)}
						/>
					))}
				{error && <span className={classes.error}>{error}</span>}
			</Grid>
			<Grid item xs={12}>
				{fileList.length > 0 && (
					<Button
						className={classes.clearButton}
						variant="contained"
						size="small"
						onClick={clearFiles}
					>
						Clear
					</Button>
				)}
			</Grid>
		</Grid>
	);
};

UploadFile.propTypes = {
	values: PropTypes.object,
	onFileChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	reference: PropTypes.string,
};

export default UploadFile;
