/* 
  TODO: refactor to use src\utils\flags.js instead for consistency
  modules that use these: 
  - account
  - authorisation
*/
export const defaultFetchState = {
	fetch: {
		done: false,
		error: "",
		success: false,
		loading: false,
	},
};

export const fetchRequested = (state) => ({
	...state,
	fetch: {
		done: false,
		error: "",
		success: false,
		loading: true,
	},
});

export const fetchFailed = (state, error) => ({
	...state,
	fetch: {
		done: true,
		error: error || "",
		success: false,
		loading: false,
	},
});

export const fetchSuccess = (state) => ({
	...state,
	fetch: {
		done: true,
		error: "",
		success: true,
		loading: false,
	},
});

export const defaultSaveState = {
	save: {
		done: false,
		error: "",
		success: false,
		saving: false,
	},
};

export const saveRequested = (state) => ({
	...state,
	save: {
		done: false,
		error: "",
		success: false,
		saving: true,
	},
});

export const saveFailed = (state, error) => ({
	...state,
	save: {
		done: true,
		error: error || "",
		success: false,
		saving: false,
	},
});

export const saveSuccess = (state) => ({
	...state,
	save: {
		done: true,
		error: "",
		success: true,
		saving: false,
	},
});
