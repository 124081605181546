import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ScrollToTopOnMount, StyledIconButton } from "frame/components";
import CustomerForm from "./customer-add-edit-container";
import { isStaffUser } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {},
	heading: {
		margin: theme.spacing(3),
	},
	container: {
		margin: theme.spacing(3, 2),
	},
	content: {
		position: "relative",
		paddingBottom: theme.spacing(6),
		backgroundColor: theme.palette.white,
		overflowX: "hidden",
	},
	label: {
		color: "#999999",
	},
	listItem: {
		marginBottom: theme.spacing(2),
	},
	icon: {
		marginRight: theme.spacing(1),
		color: theme.palette.brand,
	},
	Label: {
		color: theme.palette.brand,
	},
	actions: {
		position: "absolute",
		top: theme.spacing(3),
		right: theme.spacing(3),
	},
	action: {
		padding: theme.spacing(0.5, 4),
	},
}));

const Summary = ({ customer, onEdit, role }) => {
	const classes = useStyles();

	if (!customer) {
		return null;
	}

	return (
		<div className={classes.content}>
			<ScrollToTopOnMount />
			<CustomerForm />
			<Grid className={classes.container} container spacing={2}>
				<Grid item md={6} xs={12}>
					<Grid container spacing={2}>
						<Grid item md={12} xs={12}>
							<Typography variant="h2">
								Customer Summary
							</Typography>
						</Grid>
						<Grid item md={6} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Customer Name
							</Typography>
							<Typography variant="h5">
								{customer.customerName}
							</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Contact Person
							</Typography>
							<Typography variant="h5">
								{customer.contactPerson || "-"}
							</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Email
							</Typography>
							<Typography variant="h5">
								{customer.emailAddress || "-"}
							</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Status
							</Typography>
							<Typography variant="h5">
								{customer.status || "-"}
							</Typography>
						</Grid>
						{/* <Grid item md={12} xs={12}>
              <Typography
                className={classes.label}
                variant="body2">
                Consultant
              </Typography>
              <Typography variant="h5">
                {customer.consultant}
              </Typography>
            </Grid> */}
					</Grid>
				</Grid>
			</Grid>
			<div className={classes.actions}>
				{!isStaffUser(role) && (
					<StyledIconButton onClick={() => onEdit(customer.id)}>
						<EditIcon />
					</StyledIconButton>
				)}
			</div>
		</div>
	);
};

Summary.propTypes = {
	customer: PropTypes.object,
	onEdit: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
};

export default Summary;
