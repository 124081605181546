import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRouter, isNotNullOrEmpty } from "utils";
import PageHeaderTabs from "../page-header-tabs";
import { Grid } from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
	StyledModal,
} from "frame/components";
import {
	strip,
	EmailRecipientsForm,
	DateForm,
	JobSafetyAnalysisForm,
	UploadFormCard,
} from "features/forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
}));

const Form = ({
	values,
	onChange,
	onReset,
	onSave,
	onGetJobSafetyAnalysisList,
	apiError,
}) => {
	const classes = useStyles();
	const formRef = useRef();
	const { history } = useRouter();
	const [hasInvalidSteps, setHasInvalidSteps] = useState(false);
	const [openErrorModal, setOpenErrorModal] = useState(false);

	useEffect(() => {
		onGetJobSafetyAnalysisList();
	}, [onGetJobSafetyAnalysisList]);

	useEffect(() => {
		if (apiError && apiError !== "" && formRef.current) {
			formRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}, [apiError]);

	const onSubmit = (event) => {
		event.preventDefault();
		hasInvalidSteps ? setOpenErrorModal(true) : onSave(strip(values));
	};

	const onCancel = () => {
		onReset();
		history.push(fromHealthAndSafety.INFO_ROUTE);
	};

	useEffect(() => {
		if (isNotNullOrEmpty(values.tasks)) {
			const hasValue = (x) => x && x !== "";

			let invalidTaskSteps = values.tasks.map((task) => {
				let steps =
					isNotNullOrEmpty(task.steps) &&
					task.steps
						.map(
							(step) =>
								task.steps.length === 1 // if steps length == 1, check if step is null or empty string with any other step fields are partially filled
									? (!step.name || step.name === "") &&
									  (isNotNullOrEmpty(step.hazards) ||
											hasValue(
												step.initialRiskAssessment
											) ||
											hasValue(
												step.levelOfControlMethods
											) ||
											hasValue(
												step.residualRiskAssessment
											))
									: !step.name // check for steps which null step name when steps > 1
						)
						.filter((x) => x);

				return steps.length;
			});

			const invalid =
				invalidTaskSteps && invalidTaskSteps.filter((x) => x > 0);

			setHasInvalidSteps(invalid && invalid.length > 0);
		}
	}, [values.tasks]);

	return (
		<Page title="Add Job Safety Analysis">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="JSA"
				title="Add Job Safety Analysis (JSA)"
				// addTabTitle="Add JSA"
				// listTabTitle="SEE ALL JSAs"
				// noFormatListTitle
				listTabPath={fromHealthAndSafety.INFO_JOB_SAFETY_ANALYSIS_ROUTE}
				backActionPath={fromHealthAndSafety.INFO_ROUTE}
			/>
			<PageContent padSides padBottom padTop>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Grid container spacing={3}>
						<Grid item lg={6} md={12} sm={12} xs={12}>
							<div className={classes.body} ref={formRef}>
								<DateForm values={values} onChange={onChange} />
								<JobSafetyAnalysisForm
									values={values}
									onChange={onChange}
								/>
								<UploadFormCard
									title={"Add Attachments"}
									values={values}
									onChange={onChange}
								/>
								<EmailRecipientsForm
									values={values}
									onChange={onChange}
								/>
							</div>
						</Grid>
					</Grid>

					<FormActions className={classes.actions}>
						<FormSubmitButton
							// disabled={values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
						<FormButton
							disabled={values.save.saving}
							onClick={onCancel}
						>
							Cancel
						</FormButton>
					</FormActions>
				</ValidatorForm>
			</PageContent>

			<StyledModal
				open={openErrorModal}
				title={`Incomplete Steps Section`}
				onClose={() => setOpenErrorModal(false)}
				onConfirm={() => onSave(strip(values))}
			>
				{`Please note that you have incomplete step fields. Any step without step name will be not be saved.`}
			</StyledModal>
		</Page>
	);
};

Form.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onGetJobSafetyAnalysisList: PropTypes.func.isRequired,
	apiError: PropTypes.string.isRequired,
};

export default Form;
