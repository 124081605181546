import { connect } from "react-redux";
import Notifications from "./notifications";

import { preferencesFetch, preferencesChangeAlertEmails } from "../../actions";

const mapStateToProps = ({ preferences, profile }) => ({
	alertEmails: preferences.alertEmails,
	notificationeEmails: preferences.data,
	loading: preferences.fetch.loading,
	role: profile.role,
});

const mapDispatchToProps = {
	onFetch: preferencesFetch,
	onSaveAlertEmails: preferencesChangeAlertEmails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
