import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRouter } from "utils";
import PageHeaderTabs from "../page-header-tabs";
import { Grid } from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
} from "frame/components";
import {
	strip,
	DateForm,
	TrainingForm,
	PeopleForm,
	UploadFormCard,
	EmailRecipientsForm,
} from "features/forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";
import { validatePeopleWithoutRoles, validateAndScrollTo } from "utils";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
	},
	actions: {
		marginTop: theme.spacing(2),
		paddingBottom: theme.spacing(8),
	},
	fieldLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(-1.2),
	},
	label: {
		margin: 0,
	},
}));

const Form = ({
	values,
	onChange,
	onReset,
	onSave,
	onGetStaffTrainingList,
}) => {
	const classes = useStyles();
	const { history } = useRouter();
	const peopleBlockRef = useRef();
	const topicBlockRef = useRef();

	const training = values.training || {};

	const [invalidPeople, setInvalidPeople] = useState(false);
	const [invalidTopic, setInvalidTopic] = useState(false);

	useEffect(() => {
		onGetStaffTrainingList();
	}, [onGetStaffTrainingList]);

	useEffect(() => {
		validTopicRule && setInvalidTopic(false); // eslint-disable-next-line
	}, [training?.staffTrainingTopicId]);

	const onSubmit = (event) => {
		event.preventDefault();
		if (
			validatePeopleWithoutRoles(
				values.people,
				peopleBlockRef,
				setInvalidPeople
			) &&
			validateAndScrollTo(validTopicRule, topicBlockRef, setInvalidTopic)
		) {
			onSave(strip(values));
		}
	};

	const onCancel = () => {
		onReset();
		history.push(fromHealthAndSafety.TASK_ROUTE);
	};

	const isValid = (value) => value && value !== "";
	const validTopicRule = isValid(training?.staffTrainingTopicId);

	return (
		<Page title="Staff Training">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Staff Training Records"
				title="Add Staff Training Record"
				// addTabTitle="Add record"
				// listTabTitle="View records"
				listTabPath={fromHealthAndSafety.TASK_STAFF_TRAINING_ROUTE}
				backActionPath={fromHealthAndSafety.TASK_ROUTE}
			/>

			<PageContent padSides padBottom padTop>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Grid container spacing={3} className={classes.body}>
						<Grid item lg={6} md={12} sm={12} xs={12}>
							<div className={classes.body}>
								<DateForm values={values} onChange={onChange} />
								<div ref={peopleBlockRef} />
								<PeopleForm
									recordName="training"
									heading="Add Participants"
									otherHeading="Add other participants"
									roleOptions={["Trainee", "Trainer"]}
									values={values}
									onChange={onChange}
									showInvalidPeopleError={invalidPeople}
								/>

								<div ref={topicBlockRef} />
								<TrainingForm
									values={values}
									onChange={onChange}
									showError={invalidTopic}
								/>
								<UploadFormCard
									title={"Add Attachments"}
									values={values}
									onChange={onChange}
								/>
								<EmailRecipientsForm
									values={values}
									onChange={onChange}
								/>
							</div>
						</Grid>
					</Grid>

					<FormActions className={classes.actions}>
						<FormSubmitButton
							disabled={values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
						<FormButton
							disabled={values.save.saving}
							onClick={onCancel}
						>
							Cancel
						</FormButton>
					</FormActions>
				</ValidatorForm>
			</PageContent>
		</Page>
	);
};

Form.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onGetStaffTrainingList: PropTypes.func.isRequired,
};

export default Form;
