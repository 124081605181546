import ErrorChanged from "../actions/error-changed";
import { mergeMap, filter } from "rxjs/operators";
import { of } from "rxjs";

const handleAllErrorsEffect = (action$, state$) =>
	action$.pipe(
		filter((action) => action.type.includes("_FAILED")),
		mergeMap((action) => of(ErrorChanged(action.payload)))
	);

export default handleAllErrorsEffect;
