import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0),
		},
	},
	helperText: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: theme.spacing(-1),
	},
}));

const PaginationHeader = ({ pagination, helperText, hidePagination }) => {
	const classes = useStyles();

	if (!pagination) {
		return null;
	}

	return (
		<Grid className={classes.root} container>
			<Grid item xs={helperText ? 8 : 12}>
				{!hidePagination &&
					pagination &&
					pagination.totalNumberOfRecords !== 0 && (
						<Typography variant="body2">
							Page {pagination.pageNumber} of{" "}
							{pagination.totalNumberOfPages}, showing{" "}
							{pagination.pageSize} of{" "}
							{pagination.totalNumberOfRecords} results.
						</Typography>
					)}
				{!hidePagination &&
					pagination &&
					pagination.totalNumberOfRecords === 0 && (
						<Typography variant="body2">
							No results found.
						</Typography>
					)}
				{hidePagination &&
					pagination &&
					pagination.totalNumberOfRecords !== 0 && (
						<Typography variant="body2">
							Showing {pagination.totalNumberOfRecords} results.
						</Typography>
					)}
			</Grid>
			{helperText && (
				<Grid item xs={4} className={classes.helperText}>
					{helperText}
				</Grid>
			)}
		</Grid>
	);
};

PaginationHeader.propTypes = {
	pagination: PropTypes.object.isRequired,
	helperText: PropTypes.any,
	hidePagination: PropTypes.bool,
};

export default PaginationHeader;
