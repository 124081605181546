import * as fromTypes from "../actions/types";
import * as R from "ramda";
import moment from "moment";
import { updateFlags } from "utils";

export const defaultState = {
	organisationName: "",
	startDate: new moment(),
	nextBillingDate: new moment(),
	canceledAtDate: new moment(),
	cancelAtDate: new moment(),
	brand: "",
	last4: "",
	expiryYear: null,
	expiryMonth: null,
	isTrial: false,
	isAddPaymentModalShowing: false,
	status: "",
	nextBillingAmount: 0.0,
	creditCardToken: {},
	creditCardTokenErrorMessage: "",
	flags: {
		fetch: "none",
		updateStatus: "none"
	},
};

const subcriptionFetchSuccess = (state, { payload }) => {
	if (payload) {
		state.organisationName = payload.organisationName;
		state.brand = payload.brand;
		state.last4 = payload.last4;
		state.expiryYear = payload.expiryYear;
		state.expiryMonth = payload.expiryMonth;
		state.startDate = payload.startDate;
		state.numberOfUsers = payload.numberOfUsers;
		state.isTrial = payload.status === "trialing";
		state.nextBillingDate = payload.nextBillingDate;
		state.canceledAtDate = payload.canceledAtDate;
		state.cancelAtDate = payload.cancelAtDate;
		state.status = payload.status;
		state.nextBillingAmount = payload.nextBillingAmount;
	}

	return updateFlags(state, { fetch: "done" });
};

const addPaymentModalReset = (state) => {
	state.isAddPaymentModalShowing = true;

	return updateFlags(state, { updateStatus: "none" });
};

const subscriptionReducer = (state = defaultState, action) => {
	switch (action.type) {
		// fetch card details
		case fromTypes.SUBSCRIPTION_FETCH:
			return updateFlags(R.clone(state), { fetch: "processing" });
		case fromTypes.SUBSCRIPTION_FETCH_FAILED:
			return updateFlags(R.clone(state), { fetch: "error" });
		case fromTypes.SUBSCRIPTION_FETCH_SUCCESS:
			return subcriptionFetchSuccess(R.clone(state), action);

		// toggle add payment method modal
		case fromTypes.SUBSCRIPTION_ADD_PAYMENT:
			return addPaymentModalReset(R.clone(state));
		case fromTypes.SUBSCRIPTION_ADD_PAYMENT_CLOSE:
			return { ...state, isAddPaymentModalShowing: false };

		// add card
		case fromTypes.SUBSCRIPTION_ADD_PAYMENT_SAVE:
			return updateFlags(R.clone(state), { create: "processing" });
		case fromTypes.SUBSCRIPTION_ADD_PAYMENT_SAVE_FAILED:
			return subcriptionAddPaymentSaveFailed(R.clone(state), action);
		case fromTypes.SUBSCRIPTION_ADD_PAYMENT_SAVE_SUCCESS:
			return subcriptionAddPaymentSaveSuccess(R.clone(state), action);
		case fromTypes.SUBSCRIPTION_STRIPE_ERROR_RECEIVED:
			return stripeErrorReceived(R.clone(state), action);

		// update card
		case fromTypes.SUBSCRIPTION_UPDATE_PAYMENT_SAVE:
			return updateFlags(R.clone(state), { updateStatus: "processing" });
		case fromTypes.SUBSCRIPTION_UPDATE_PAYMENT_SAVE_SUCCESS:
			return updateFlags(R.clone(state), { updateStatus: "done" });
		case fromTypes.SUBSCRIPTION_UPDATE_PAYMENT_SAVE_FAIL:
			return updateFlags(R.clone(state), { updateStatus: "error" });

		case fromTypes.SUBSCRIPTION_MODAL_SUBMIT:
			return updateFlags(R.clone(state), { updateStatus: "processing" });

		// delete card
		case fromTypes.SUBSCRIPTION_CANCEL_SUBSCRIPTION:
			return updateFlags(R.clone(state), { delete: "processing" });
		case fromTypes.SUBSCRIPTION_CANCEL_SUBSCRIPTION_FAILED:
			return updateFlags(R.clone(state), { delete: "error" });
		case fromTypes.SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESS:
			return updateFlags(R.clone(state), { delete: "done" });

		default:
			return state;
	}
};

const subcriptionAddPaymentSaveFailed = (state, action) => {
	return updateFlags(state, { create: "error" });
};

const subcriptionAddPaymentSaveSuccess = (state, action) => {
	state.isAddPaymentModalShowing = false;
	return updateFlags(state, { create: "done", fetch: "done" });
};

const stripeErrorReceived = (state, action) => {
	state.creditCardTokenErrorMessage = action.payload;
	return state;
};

export default subscriptionReducer;
