import * as R from "ramda";
import fromAuth from "../actions/types";
import {
	defaultSaveState,
	saveRequested,
	saveSuccess,
	saveFailed,
} from "utils";

const defaultState = {
	...defaultSaveState,
	password: "",
	confirmPassword: "",
	isValid: false,
};

const setPasswordReducer = (state = defaultState, action) => {
	switch (action.type) {
		// Set Password
		case fromAuth.AUTHORISATION_SET_PASSWORD_REQUESTED: {
			const nextState = setPasswordRequested(R.clone(state), action);
			return saveRequested(nextState);
		}
		case fromAuth.AUTHORISATION_SET_PASSWORD_PASSWORD_CHANGED:
			return setPasswordChanged(R.clone(state), action);
		case fromAuth.AUTHORISATION_SET_PASSWORD_CONFIRM_PASSWORD_CHANGED:
			return setConfirmPasswordChanged(R.clone(state), action);
		case fromAuth.AUTHORISATION_SET_PASSWORD_FULFILLED: {
			const nextState = setPasswordFulfilled(R.clone(state), action);
			return saveSuccess(nextState, action.payload);
		}
		case fromAuth.AUTHORISATION_SET_PASSWORD_FAILED: {
			const nextState = setPasswordFailed(R.clone(state), action);
			return saveFailed(nextState, action.payload);
		}
		default:
			return state;
	}
};

const setPasswordRequested = (state, action) => {
	return state;
};

const setPasswordChanged = (state, action) => {
	state.responseMessage = "";
	state.password = action.payload;
	return isValid(state, state.password, state.confirmPassword);
};

const setConfirmPasswordChanged = (state, action) => {
	state.errorMessage = "";
	state.confirmPassword = action.payload;
	return isValid(state, state.password, state.confirmPassword);
};

const isValid = (state, password, confirmPassword) => {
	// state.hasNumber = hasNumber(password) && hasNumber(confirmPassword)
	state.meetsPasswordLength =
		password.length > 6 && confirmPassword.length > 8;
	state.passwordMatch = password === confirmPassword;
	state.isValid = state.meetsPasswordLength && state.passwordMatch; //&& state.hasNumber
	return state;
};

const setPasswordFulfilled = (state, action) => {
	state.responseMessage = action.payload;
	return state;
};
const setPasswordFailed = (state, action) => {
	state.responseMessage = action.payload;
	return state;
};

export default setPasswordReducer;
