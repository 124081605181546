import { connect } from "react-redux";
import HazardousSubstanceSiteList from "./hazardous-substance-site-list";
import {
	onAddHazardousSubstanceToSite,
	onEditHazardousSubstanceToSite,
	onRemoveHazardousSubstanceFromSite,
} from "features/sites";

const mapStateToProps = ({ sites, profile }) => ({
	values: sites.currentSite,
	role: profile.role,
});

const mapDispatchToProps = (dispatch) => ({
	onAdd: () => {
		dispatch(onAddHazardousSubstanceToSite());
	},
	onEdit: () => {
		dispatch(onEditHazardousSubstanceToSite());
	},
	onDelete: (payload) => {
		dispatch(onRemoveHazardousSubstanceFromSite(payload));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HazardousSubstanceSiteList);
