import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	SearchBar,
	Pagination,
	PaginationHeader,
	PopupSnackbar,
	SendEmailModal,
} from "frame/components";

import * as fromHealthSafetyForm from "features/health-safety-forms";
import JobSafetyAnalysisCard from "../cards/job-safety-analysis-card";
import {
	EditJobSafetyAnalysisForm,
	PageHeaderTabs,
} from "features/health-safety-forms";
import { getPageResults } from "utils";
import { safetyWiseApi } from "utils/safetyWiseApi";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "none",
	},
	empty: {
		marginTop: theme.spacing(3),
	},
}));

const JobSafetyAnalysisList = ({
	role,
	jobSafetyAnalysis,
	onGetJSAList,
	onDelete,
	apiError,
	currentOrganisationGUID,
}) => {
	const classes = useStyles();
	const [isClone, setIsClone] = useState(false);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showViewModal, setShowViewModal] = useState(false);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [showError, setShowError] = React.useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [siteId, setSiteId] = useState(null);
	const [successMessage, setSuccessMessage] = useState("");
	const [isSendingEmail, setIsSendingEmail] = useState(false);
	const [searchFilter, setSearchFilter] = useState("");

	let jsaList = getPageResults(jobSafetyAnalysis);
	const empty = jsaList && jsaList.length <= 0;

	const onView = (id) => () => {
		setSelectedItemId(id);
		setShowViewModal(true);
	};
	const onEdit = (id) => () => {
		setSelectedItemId(id);
		setShowEditModal(true);
	};
	const onClone = (id) => () => {
		setSelectedItemId(id);
		setIsClone(true);
		setShowEditModal(true);
	};
	const onEmail = (id, siteId) => () => {
		setSelectedItemId(id);
		setSiteId(siteId);
		setShowEmailModal(true);
	};
	const handleApiError = (error) => {
		if (error.response && error.response.status === "401") {
			setErrorMessage("You are not authorized to perform this action.");
		} else if (error.response && error.response.status === "404") {
			setErrorMessage("The record no longer exists.");
		} else {
			setErrorMessage(
				"Something went wrong, please refresh the page and try again."
			);
		}
	};

	// old error handling code - leaving this in for now
	useEffect(() => {
		setShowError(apiError && apiError !== "");
	}, [apiError, setShowError]);

	return (
		<Page
			className={classes.root}
			title="Job Safety Analysis (JSA Library)"
		>
			<ScrollToTopOnMount />

			<PageHeaderTabs
				title="Job Safety Analysis (JSA Library)"
				addTabPath={fromHealthSafetyForm.ADD_JSA_ROUTE}
				backActionPath={fromHealthSafetyForm.INFO_ROUTE}
			/>
			<SearchBar onSearch={setSearchFilter} />

			<PageContent pad>
				<PaginationHeader
					hidePagination
					pagination={jobSafetyAnalysis}
				/>

				{!empty &&
					jsaList.map((jsa, index) => (
						<JobSafetyAnalysisCard
							key={index}
							role={role}
							jobSafetyAnalysis={jsa}
							onView={onView(jsa.id)}
							onEdit={onEdit(jsa.id)}
							onDelete={onDelete}
							onClone={onClone(jsa.id)}
							onEmail={onEmail(jsa.id, jsa.siteId)}
						/>
					))}

				{empty && (
					<Typography className={classes.empty} variant="body1">
						There are currently no Job Safety Analysis Worksheets
						listed.
					</Typography>
				)}

				{/* old error handling code - leaving this in for now*/}
				{showError && (
					<PopupSnackbar message={apiError} coloured error />
				)}

				<Pagination
					hidePagination
					onGetList={onGetJSAList}
					pagination={jobSafetyAnalysis}
					orderBy="createdAt"
					ascending={false}
					searchKey={searchFilter}
				/>
			</PageContent>

			{showViewModal && (
				<EditJobSafetyAnalysisForm
					id={selectedItemId}
					readOnly
					onClose={() => setShowViewModal(false)}
				/>
			)}
			{showEditModal && (
				<EditJobSafetyAnalysisForm
					id={selectedItemId}
					isClone={isClone}
					onClose={() => setShowEditModal(false)}
				/>
			)}
			<SendEmailModal
				isOpen={showEmailModal}
				title={"Email Job Safety Analysis"}
				onClose={() => setShowEmailModal(false)}
				onSend={(selectedRecipients) => {
					setIsSendingEmail(true);
					safetyWiseApi
						.post(
							`${currentOrganisationGUID}/job-safety-analysis/email/${selectedItemId}`,
							{
								toInternalAddresses:
									selectedRecipients.recipients,
								toExternalAddresses: [
									...selectedRecipients.recipientsOther,
									...selectedRecipients.recipientsCustomerSites,
								],
							}
						)
						.then(() => {
							setSuccessMessage("Emails sent successfully.");
						})
						.catch(handleApiError)
						.finally(() => {
							setIsSendingEmail(false);
							setShowEmailModal(false);
						});
				}}
				isSaving={isSendingEmail}
				selectedItemId={selectedItemId}
				siteId={siteId}
				emailListType={"allCustomerSiteRecord"}
			/>
			<PopupSnackbar
				message={errorMessage}
				coloured
				error
				onExited={() => setErrorMessage("")}
			/>
			<PopupSnackbar
				message={successMessage}
				coloured
				success
				onExited={() => setSuccessMessage("")}
			/>
		</Page>
	);
};

JobSafetyAnalysisList.propTypes = {
	role: PropTypes.string.isRequired,
	jobSafetyAnalysis: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	onGetJSAList: PropTypes.func.isRequired,
	saveState: PropTypes.object.isRequired,
	apiError: PropTypes.string,
	currentOrganisationGUID: PropTypes.string,
};

export default JobSafetyAnalysisList;
