import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { formatDate } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 1000,
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		marginBottom: theme.spacing(2),
	},
	content: {
		position: "relative",
		padding: theme.spacing(2, 4),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	label: {
		padding: theme.spacing(0, 0, 0.5, 0),
		color: "#999999",
	},
	value: {
		padding: theme.spacing(0, 0, 0.5, 0),
	},
	link: {
		color: theme.palette.text.link,
		cursor: "pointer",
	},
}));

const UncomfirmedAttendanceListing = ({ record, onView }) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent className={classes.content}>
				<Grid container spacing={2}>
					<Grid item md={3} sm={6} xs={12}>
						<Typography className={classes.label} variant="body2">
							Created At
						</Typography>
						<Typography className={classes.value} variant="h5">
							{formatDate(record.createdAt)}
						</Typography>
					</Grid>

					<Grid item md={3} sm={6} xs={12}>
						<Typography className={classes.label} variant="body2">
							Site Name
						</Typography>
						<Typography className={classes.value} variant="h5">
							{record.siteName || "-"}
						</Typography>
					</Grid>

					<Grid
						item
						md={3}
						sm={6}
						xs={12}
						style={{ marginTop: "20px" }}
					>
						<Typography
							className={classes.link}
							variant="h5"
							onClick={onView(record.inductionId)}
						>
							View induction details
						</Typography>
					</Grid>

					<div
						className="ignore"
						style={{
							position: "absolute",
							right: 60,
							bottom: 22,
							display: "flex",
						}}
					>
						<a href={record.confirmAttendnaceUrl}>
							<Typography
								className={classes.link}
								variant="h5"
								onClick={() => {}}
							>
								Confirm Attendance
							</Typography>
						</a>
					</div>
				</Grid>
			</CardContent>
		</Card>
	);
};

UncomfirmedAttendanceListing.propTypes = {
	record: PropTypes.object.isRequired,
	onView: PropTypes.func.isRequired,
};

export default UncomfirmedAttendanceListing;
