import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError } from "rxjs/operators";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import fromTypes from "../actions/types";
import { onLookupSuccess, onLookupFailed } from "../actions";

const staffLookupEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.STAFF_LOOKUP),
		mergeMap(({ payload }) =>
			ajax
				.get(
					`${getOrganisationBaseUrl(state$.value)}/staff/${payload}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onLookupSuccess(ajaxResponse.response))
					),
					catchError((error) => of(onLookupFailed(error)))
				)
		)
	);

export default staffLookupEffect;
