import Types from "../actions/types";
import * as fromOrganisations from "features/organisations";
import {
	onStaffTrainingTopicListReceive,
	onStaffTrainingTopicListFailed,
} from "../actions";
import { ofType } from "redux-observable";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { authHeaders, tokenExists, getOrganisationBaseUrl } from "frame";

export const staffTrainingTopicListEffect = (action$, state$) =>
	action$.pipe(
		ofType(
			Types.STAFF_TRAINING_TOPIC_LIST_REQUESTED,
			// fromOrganisations.ORGANISATION_FETCH_SUCCESS,
			fromOrganisations.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED
		),
		filter(() => tokenExists()),
		filter(() => !!state$.value.organisations.currentOrganisation),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/staff-training-topic/paged`,
					{
						page: 1,
						pageSize: 5000,
						orderBy: "createdAt",
						ascending: false,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							onStaffTrainingTopicListReceive(
								ajaxResponse.response
							)
						)
					),
					catchError((error) =>
						of(onStaffTrainingTopicListFailed(error))
					)
				)
		)
	);

export const staffTrainingTopicPagedListEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.STAFF_TRAINING_TOPIC_PAGED_LIST_REQUESTED),
		filter(() => state$.value.organisations.currentOrganisation),
		filter(() => tokenExists()),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(({ payload }) => {
			const pagingParams = payload;
			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/staff-training-topic/paged`,
					pagingParams,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							onStaffTrainingTopicListReceive(
								ajaxResponse.response
							)
						)
					),
					catchError((error) =>
						of(onStaffTrainingTopicListFailed(error))
					)
				);
		})
	);
