import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader } from "@material-ui/core";
import IncidentForm from "./accident-form";
import ApprovalToStartWorkForm from "./approval-to-start-work-form";
import CommunicationParticipationForm from "./communication-participation-form";
import DeclarationForm from "./declaration-form";
import EmergenciesForm from "./emergencies-form";
import HazardManagementForm from "./hazard-management-form";
import NotifiableWorksForm from "./notifiable-works-form";
import SafetyInspectionReviewForm from "./safety-inspection-reviews-form";
import TrainingInductionForm from "./training-induction-form";
import UndertakeForm from "./undertake-form";
import UploadSectionForm from "./upload-section-form";
// import EmailRecipientsForm from './send-email-form'

import { EmailRecipientsForm } from "features/forms";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	content: {
		paddingTop: 0,
	},
}));

const SafetyPlanChecklistForm = ({ className, values, onChange }) => {
	const classes = useStyles();
	const safetyPlan = values.safetyPlan || {};
	const disabled = values.locked || values.save.saving;

	// for TextField/TextValidator
	const onInputChange =
		(key) =>
		({ target: { value } }) =>
			onChange({
				...values,
				safetyPlan: {
					...safetyPlan,
					[key]: value,
				},
			});

	// for RadioListInput
	const onSelectChange = (key) => (value) =>
		onChange({
			...values,
			safetyPlan: {
				...safetyPlan,
				[key]: value,
			},
		});

	const onFileChange = (key, attachments) =>
		onChange({
			...values,
			safetyPlan: {
				...safetyPlan,
				[key]: attachments,
			},
		});

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Safety Plan Checklist"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<UndertakeForm
				disabled={disabled}
				values={values}
				onInputChange={onInputChange}
			/>
			<NotifiableWorksForm
				disabled={disabled}
				values={safetyPlan}
				onFileChange={onFileChange}
				onSelectChange={onSelectChange}
			/>
			<HazardManagementForm
				disabled={disabled}
				values={safetyPlan}
				onSelectChange={onSelectChange}
			/>
			<CommunicationParticipationForm
				disabled={disabled}
				values={safetyPlan}
				onSelectChange={onSelectChange}
			/>
			<EmergenciesForm
				disabled={disabled}
				values={safetyPlan}
				onInputChange={onInputChange}
				onSelectChange={onSelectChange}
				onFileChange={onFileChange}
			/>
			<IncidentForm
				disabled={disabled}
				values={safetyPlan}
				onSelectChange={onSelectChange}
			/>
			<SafetyInspectionReviewForm
				disabled={disabled}
				values={safetyPlan}
				onSelectChange={onSelectChange}
			/>
			<TrainingInductionForm
				disabled={disabled}
				values={safetyPlan}
				onSelectChange={onSelectChange}
			/>
			<DeclarationForm
				disabled={disabled}
				values={safetyPlan}
				onInputChange={onInputChange}
				onSelectChange={onSelectChange}
			/>
			<ApprovalToStartWorkForm
				disabled={disabled}
				values={safetyPlan}
				onInputChange={onInputChange}
				onSelectChange={onSelectChange}
			/>
			<UploadSectionForm
				values={values}
				onChange={onChange}
				disabled={disabled}
			/>
			<EmailRecipientsForm
				hideCardLayout
				values={values}
				onChange={onChange}
			/>
		</Card>
	);
};

SafetyPlanChecklistForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default SafetyPlanChecklistForm;
