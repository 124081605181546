import Types from "../actions/types";
import { ofType } from "redux-observable";
import { mergeMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import { onRemoveSuccess, onRemoveFailed } from "../actions";
import * as fromHealthAndSafetyActions from "features/health-and-safety";

const onRemoveSucceeded = (payload) => {
	switch (payload.form) {
		case "accident":
			return fromHealthAndSafetyActions.onIncidentListRequest();
		case "hazard":
			return fromHealthAndSafetyActions.onHazardListRequest();
		case "hazardous-substance":
			return fromHealthAndSafetyActions.onHazardousSubstanceListRequest();
		case "safety-plan-checklist":
			return fromHealthAndSafetyActions.onSafetyPlanChecklistListRequest();
		case "job-safety-analysis":
			return fromHealthAndSafetyActions.onJobSafetyAnalysisListRequest();
		case "induction-minutes":
			return fromHealthAndSafetyActions.onInductionMinutesListRequest();
		case "safety-minutes":
			return fromHealthAndSafetyActions.onSafetyMinutesListRequest();
		case "staff-training":
			return fromHealthAndSafetyActions.onStaffTrainingListRequest();
		case "staff-training-topic":
			return fromHealthAndSafetyActions.onStaffTrainingTopicListRequest();
		default:
			return;
	}
};

const removeEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.HEALTH_SAFETY_REMOVE_REQUESTED),
		mergeMap(({ payload }) => {
			return ajax
				.delete(
					`${getOrganisationBaseUrl(state$.value)}/${
						payload.form
					}/delete/${payload.id}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							onRemoveSucceeded(payload), // calls appropriate list request effect
							onRemoveSuccess(payload)
						)
					),
					catchError((error) => of(onRemoveFailed(error)))
				);
		})
	);

export default removeEffect;
