import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import { Card, CardContent, CardHeader, Grid, TextField } from "@material-ui/core";
import { SelectInput } from "frame/components";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0)
	},
	errorMessage: {
		color: theme.palette.error.main
	}
}));

export const TimesheetForm = ({
	values,
	onChange,
	leaveForm = false,
	showStaffDropdown = true
}) => {
	const classes = useStyles();
	const staffList = useSelector(({ staff }) => staff.pagination?.results);
	const taskTypeList = useSelector(({ taskTypes }) => taskTypes?.data );
	const disabled = !values.siteId;
	const timesheet = values.timesheet || {};

	const calculateDaysTaken = (startDate, endDate) => {
		if (!timesheet.startDate || !timesheet.endDate) {
			return 0;
		}

		let daysDiff = 0;

		while (startDate <= endDate) {
			if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
				daysDiff++;
			}
			startDate.setDate(startDate.getDate() + 1);
		}
		return daysDiff;
	};
	const daysDiff = calculateDaysTaken(new Date(timesheet.startDate), new Date(timesheet.endDate));

	const onInputChange = useCallback(
		(key) => (event) => {
			const value =
				(event.target && event.target.value) || (typeof event === "string" && event) || "";
			onChange({
				...values,
				timesheet: {
					...timesheet,
					[key]: value
				}
			}); // eslint-disable-next-line
		},
		[timesheet, values, onChange]
	);

	const onSelectDate = useCallback(
		(key) => (value) => {
			onChange({
				...values,
				timesheet: {
					...timesheet,
					[key]: value.format("YYYY-MM-DD")
				}
			});
		},
		[timesheet, values, onChange]
	);

	const staffOptions =
		staffList &&
		staffList
			.map(({ id, firstName, lastName }) => ({
				label: `${firstName} ${lastName}`,
				value: id
			}))
			.sort((a, b) => (a.label > b.label ? 1 : -1));

	const taskTypeOptions =
		taskTypeList &&
		taskTypeList
			.map(({ taskType, id }) => ({
				label: taskType,
				value: id
			}));

	const lunchOptions = [
		{
			label: "None",
			value: "0"
		},
		{
			label: "30 mins",
			value: "30"
		},
		{
			label: "60 mins",
			value: "60"
		}
	];

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title={leaveForm ? "New Leave" : "Timesheet"}
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent>
				<Grid container spacing={2}>
					{showStaffDropdown && (
						<Grid item sm={4} xs={12}>
							<SelectInput
								id="timesheet_staff"
								disabled={disabled}
								label="Staff *"
								options={staffOptions}
								value={timesheet.staffId || ""}
								onChange={onInputChange("staffId")}
							/>
						</Grid>
					)}

					<Grid item sm={4} xs={12}>
						<DatePicker
							className={classes.field}
							disabled={disabled}
							format="Do MMMM YYYY"
							fullWidth
							inputVariant="outlined"
							label="Start Date *"
							showTodayButton
							value={timesheet.startDate || null}
							variant="outlined"
							disableFuture={!leaveForm}
							onChange={onSelectDate("startDate")}
							error={values.isEndDateTimeInvalid}
							autoOk
							okLabel=""
						/>
					</Grid>
					{!leaveForm && (
						<>
							<Grid item sm={4} xs={12}>
								<TextField
									disabled={disabled}
									fullWidth
									label="Start Time *"
									type="time"
									variant="outlined"
									value={timesheet.startTime || ""}
									InputLabelProps={{ shrink: true }}
									inputProps={{ step: 300 }} // 5 min
									onChange={onInputChange("startTime")}
									error={values.isEndDateTimeInvalid}
								/>
							</Grid>

							<Grid item sm={4} xs={12}>
								<SelectInput
									id="timesheet_lunch_time"
									disabled={disabled}
									label="Lunch Time"
									options={lunchOptions}
									value={timesheet.lunchTime || "0"}
									onChange={onInputChange("lunchTime")}
									noNone
								/>
							</Grid>
						</>
					)}

					<Grid item sm={4} xs={12}>
						<DatePicker
							className={classes.field}
							disabled={disabled}
							format="Do MMMM YYYY"
							fullWidth
							inputVariant="outlined"
							label="End Date *"
							minDate={timesheet.startDate}
							showTodayButton
							value={timesheet.endDate || null}
							variant="outlined"
							disableFuture={!leaveForm}
							onChange={onSelectDate("endDate")}
							error={values.isEndDateTimeInvalid}
							autoOk
							okLabel=""
						/>
					</Grid>
					{leaveForm && daysDiff > 0 && (
						<Grid item sm={4} xs={12}>
							<TextField
								disabled
								fullWidth
								label="Total Days of Leave"
								variant="outlined"
								value={daysDiff}
							/>
						</Grid>
					)}
					{!leaveForm && (
						<Grid item sm={4} xs={12}>
							<TextField
								disabled={disabled}
								fullWidth
								label="End Time *"
								type="time"
								variant="outlined"
								value={timesheet.endTime || ""}
								InputLabelProps={{
									shrink: true
								}}
								inputProps={{
									step: 300 // 5 min
								}}
								onChange={onInputChange("endTime")}
								error={values.isEndDateTimeInvalid}
							/>
							{values.isEndDateTimeInvalid && (
								<p className={classes.errorMessage}>End date/time must be later than start</p>
							)}
						</Grid>
					)}

					{
						!leaveForm &&
						<Grid item sm={4} xs={12}>
							<SelectInput
								id="timesheet_task_type"
								disabled={disabled}
								label="Task Type"
								options={taskTypeOptions}
								value={timesheet.organisationTaskTypeId || ""}
								onChange={onInputChange("organisationTaskTypeId")}
							/>
						</Grid>
					}

					<Grid item xs={12}>
						<TextField
							id="timesheet_notes"
							disabled={disabled}
							fullWidth
							label="Notes"
							multiline
							rows="3"
							value={timesheet.notes || ""}
							variant="outlined"
							onChange={onInputChange("notes")}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

TimesheetForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired
};
