import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Typography, Grid, Checkbox } from "@material-ui/core";
import {
	HazardDetailsDialog,
	HazardousSubstanceDetailsDialog,
} from "frame/components";

const RedCheckbox = withStyles((theme) => ({
	root: {
		"&$disabled": {
			color: theme.palette.error.main,
		},
	},
	checked: {},
	disabled: {},
}))(Checkbox);

const useStyles = makeStyles((theme) => ({
	root: {},
	label: {
		minWidth: 250,
		marginTop: theme.spacing(1.5),
		paddingRight: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			paddingRight: theme.spacing(0),
			minWidth: 140,
		},
	},
	link: {
		marginTop: theme.spacing(1.5),
		color: theme.palette.text.link,
		cursor: "pointer",
	},
	guidelines: {
		display: "flex",
		justifyContent: "flex-end",
	},
	error: {
		color: theme.palette.error.main,
	},
}));

/* 
  used in edit-induction-minutes-form && view-induction-minutes-form
  params: readOnly - hides checkbox and just let you view guidelines without agree checkbox
*/
const HazardSubstanceListItem = ({
	id,
	isHazard,
	hazardList = [],
	hazardousSubstanceList = [],
	onReadGuidelines,
	readOnly,
	promptUnread,
}) => {
	const classes = useStyles();
	const [checkBoxToggle, setCheckBoxToggle] = useState(false);
	const [data, setData] = useState(null);
	const [notSeenGuidelines, setNotSeenGuidelines] = useState(true);

	if (!id) {
		return null;
	}

	let listData = isHazard ? hazardList : hazardousSubstanceList;
	const selectedData = listData && listData.filter((h) => h.id === id)[0];

	const onGuidelineClick = (id) => () =>
		selectedData && setData(selectedData);

	const onSelect = () => {
		setCheckBoxToggle(true);
		setNotSeenGuidelines(false);
		setData(null);
		onReadGuidelines && onReadGuidelines();
	};

	return (
		<Grid container className={classes.root}>
			<Grid item xs={6}>
				<Typography
					className={classes.label}
					align="left"
					gutterBottom
					variant="body1"
				>
					{selectedData ? selectedData.name : "-"}
				</Typography>
			</Grid>

			<Grid item xs={6} className={classes.guidelines}>
				<Typography
					className={classes.link}
					align="right"
					gutterBottom
					variant="body1"
					onClick={onGuidelineClick(id)}
				>
					{promptUnread && !checkBoxToggle ? (
						<span className={classes.error}>Read Guidelines</span>
					) : (
						"Read Guidelines"
					)}
				</Typography>

				{!readOnly &&
					(promptUnread && !checkBoxToggle ? (
						<RedCheckbox
							disabled={notSeenGuidelines}
							checked={checkBoxToggle}
						/>
					) : (
						<Checkbox
							disabled={notSeenGuidelines}
							checked={checkBoxToggle}
						/>
					))}
			</Grid>

			{data && (
				<>
					{isHazard ? (
						<HazardDetailsDialog
							readOnly={readOnly}
							data={data}
							onSelect={onSelect}
							onClose={() => setData(null)}
						/>
					) : (
						<HazardousSubstanceDetailsDialog
							readOnly={readOnly}
							data={data}
							onSelect={onSelect}
							onClose={() => setData(null)}
						/>
					)}
				</>
			)}
		</Grid>
	);
};

HazardSubstanceListItem.propTypes = {
	id: PropTypes.string.isRequired,
	isHazard: PropTypes.bool,
	hazardList: PropTypes.array,
	hazardousSubstanceList: PropTypes.array,
	onReadGuidelines: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
	promptUnread: PropTypes.bool,
};

export default HazardSubstanceListItem;
