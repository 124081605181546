import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import * as fromRoutes from "../actions/types";
import Customer from "./customer-container";
import Customers from "./customer-list-container";
import Summary from "./summary-container";
import Sites from "./sites-container";
import { JobCustomerList } from "features/jobs";
import NotFound from "./not-found";

import {
	IncidentList,
	HazardList,
	HazardousSubstancesList,
	SafetyPlanChecklistList,
	JobSafetyAnalysisList,
	InductionMinutesList,
	SafetyMinutesList,
} from "features/health-and-safety";

const CustomersRoutes = ({ onGetCustomersList, onSearchClear }) => {
	useEffect(() => {
		onSearchClear();
		onGetCustomersList();
	}, [onGetCustomersList, onSearchClear]);

	return (
		<Switch>
			<Route
				exact
				path={fromRoutes.CUSTOMERS_ROUTE}
				component={Customers}
			/>
			<Route path={fromRoutes.CUSTOMER_TAB_ROUTE} component={Customer} />
		</Switch>
	);
};

CustomersRoutes.propTypes = {
	onGetCustomersList: PropTypes.func.isRequired,
	onSearchClear: PropTypes.func.isRequired,
};

export const ChildRoutes = () => (
	<Switch>
		<Route
			path={fromRoutes.CUSTOMER_NOTES_ROUTE}
			component={JobCustomerList}
		/>
		<Route path={fromRoutes.CUSTOMER_SITES_ROUTE} component={Sites} />
		{/* Health & Safety sub routes */}
		<Route
			path={fromRoutes.CUSTOMER_INCIDENTS_ROUTE}
			component={IncidentList}
		/>
		<Route
			path={fromRoutes.CUSTOMER_HAZARDS_ROUTE}
			component={HazardList}
		/>
		<Route
			path={fromRoutes.CUSTOMER_HAZARDOUS_SUBSTANCES_ROUTE}
			component={HazardousSubstancesList}
		/>
		<Route
			path={fromRoutes.CUSTOMER_INDUCTION_MINUTES_ROUTE}
			component={InductionMinutesList}
		/>
		<Route
			path={fromRoutes.CUSTOMER_SAFETY_MEETING_MINUTES_ROUTE}
			component={SafetyMinutesList}
		/>
		<Route
			path={fromRoutes.CUSTOMER_SAFETY_PLAN_CHECKLIST_ROUTE}
			component={SafetyPlanChecklistList}
		/>
		<Route
			path={fromRoutes.CUSTOMER_JOB_SAFETY_ANALYSYS_ROUTE}
			component={JobSafetyAnalysisList}
		/>
		{/* Main/primary/fallback route */}
		<Route path={fromRoutes.CUSTOMER_ROUTE} component={Summary} />
	</Switch>
);

export default CustomersRoutes;
