import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import {
	useCurrentRole,
	isSuperAdminUser,
	useCurrentOrganisationDetails,
	useRouter,
	isStaffUser,
} from "utils";
import * as fromAccount from "features/account";

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: "12px",
	},
	selectIcon: {
		fill: theme.palette.white,
	},
	roleHeading: {
		color: theme.palette.white,
		textTransform: "uppercase",
		marginBottom: "12px",
		fontSize: "11px",
		fontWeight: "500",
	},
	organisationHeading: {
		color: theme.palette.white,
		textTransform: "uppercase",
		marginTop: "12px",
		marginBottom: "12px",
		fontSize: "11px",
		fontWeight: "500",
	},
	role: {
		display: "relative",
		color: theme.palette.white,
		marginLeft: "10px",
		marginBottom: "20px",
		fontSize: "14px",
		fontWeight: "500",
	},
	organisation: {
		width: "90%",
		display: "relative",
		color: theme.palette.white,
		marginLeft: "10px",
		marginBottom: "20px",
		fontSize: "14px",
		fontWeight: "500",
	},
	trialMessage: {
		position: "absolute",
		left: 63,
		bottom: 10,
		color: "#FFF",
		padding: "10px",
		border: "1px solid #FFF",
		cursor: "pointer",
	},
}));

const Form = ({
	currentOrganisation,
	organisations,
	onChange,
	profileFetchFlag,
	subscription,
}) => {
	const { history } = useRouter();
	const classes = useStyles();
	const currentRole = useCurrentRole();
	const currentOrganisationDetails = useCurrentOrganisationDetails();

	if (organisations.length === 0) {
		return <></>;
		// history.push(fromAuth.LOGIN_PATH)
	}

	// const onChangeHandler = (event) => {
	//   onChange(event.target.value)
	//   const org = organisations.find(org => org.id === currentOrganisation)
	//   isProd && ReactGA.event({ category: 'Menu', action: 'Organization Switched', label: org.name })
	// }

	// const profileFetchSuccess = profileFetchFlag.done && profileFetchFlag.success
	// const profileFetching = profileFetchFlag.loading
	const hideDaysRemaining =
		!isSuperAdminUser(currentRole) &&
		currentOrganisationDetails &&
		currentOrganisationDetails.name === "Born Digital Demo";

	const navigateToSubscriptionPage = () =>
		!isStaffUser(currentRole) &&
		history.push(fromAccount.ACCOUNT_SUBSCRIPTION_ROUTE);

	return (
		<div className={classes.root}>
			{/* <p style={{ color: theme.palette.white }}>
      currentOrganisation: {JSON.stringify(currentOrganisation)}
        <ColorCircularProgress size={30} thickness={5} />
      </p> */}
			{isSuperAdminUser(currentRole) && (
				<>
					<Typography className={classes.roleHeading} variant="body2">
						Account Type
					</Typography>

					<span className={classes.role}>{currentRole}</span>
				</>
			)}

			<Typography className={classes.organisationHeading} variant="body2">
				Organisation Name
			</Typography>

			<span className={classes.organisation}>
				{organisations.map(
					(item) => item.id === currentOrganisation && item.name
				)}
			</span>
			{/* <Select
        id="organisation"
        value={currentOrganisation}
        className={classes.organisation}
        inputProps={{ classes: { icon: classes.selectIcon }}}
        onChange={onChangeHandler}
      >
        {organisations.map((item, key) => (
          <MenuItem key={key} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select> */}

			{!hideDaysRemaining && subscription.daysRemaining && (
				<Typography
					className={classes.trialMessage}
					onClick={navigateToSubscriptionPage}
				>
					Free Trial: {subscription.daysRemaining} Days Left
				</Typography>
			)}
		</div>
	);
};

Form.propTypes = {
	currentOrganisation: PropTypes.string.isRequired,
	organisations: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	profileFetchFlag: PropTypes.object.isRequired,
	subscription: PropTypes.object.isRequired,
};

export default Form;
