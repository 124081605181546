import { connect } from "react-redux";
import StaffAddEdit from "./staff-add-edit";

import {
	onChangeOrganisationRole,
	onChangeFirstName,
	onChangeLastName,
	onChangeEmail,
	onChangePassword,
	onChangePhone,
	onClose,
	onSave,
	onToggleEmailInvite,
	onChangeStatus,
	onChangeStaffType,
	onChangeStaffIdNumber,
	onChangeSupervisor,
	onFileChanged,
	onNotesChanged,
} from "../actions";

const mapStateToProps = ({ staff, organisations, profile }) => ({
	staff: staff.currentStaff,
	staffList: staff.pagination.results,
	organisations: organisations.data,
	isAddModalShowing: staff.isAddModalShowing,
	isEditModalShowing: staff.isEditModalShowing,
	profile: profile,
	saving: staff.saving,
	currentOrganisationGUID: organisations.currentOrganisation,
});

const mapDispatchToProps = {
	// on change functions
	onOrganisationRoleChanged: onChangeOrganisationRole,
	onFirstNameChanged: onChangeFirstName,
	onLastNameChanged: onChangeLastName,
	onPasswordChanged: onChangePassword,
	onPhoneChanged: onChangePhone,
	onEmailChanged: onChangeEmail,
	onEmailToggle: onToggleEmailInvite,
	onStatusChanged: onChangeStatus,
	onStaffTypeChanged: onChangeStaffType,
	onStaffIdNumberChanged: onChangeStaffIdNumber,
	onSupervisorChanged: onChangeSupervisor,
	onFileChanged: onFileChanged,
	onNotesChanged: onNotesChanged,

	// actions
	onCloseClicked: onClose,
	onSaveClicked: onSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffAddEdit);
