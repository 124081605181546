import { combineEpics } from "redux-observable";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { authHeaders, tokenExists, getOrganisationBaseUrl } from "frame";
import {
	timesheetActionTypes,
	onAddTimesheetReset,
	onAddTimesheetSaveFailed,
	onAddTimesheetSaveSuccess,
	onTimesheetListReceive,
	onTimesheetListFailed,
	onTimesheetRemoveSuccess,
	onTimesheetRemoveFail,
	onActiveTimesheetRequest,
	onActiveTimesheetReceive,
	refreshTimesheetList,
	onActiveTimesheetFailed,
	onAddTimesheetStartFailed,
	onAddTimesheetStartSuccess,
	onConfirmActiveTimesheetFailed,
	onConfirmActiveTimesheetSuccess,
	onDiscardActiveTimesheetSuccess,
	onDiscardActiveTimesheetFailed,
} from "./timesheet-actions";
import { LIST_TIMESHEETS_ROUTE } from "./timesheet-routes";
import moment from "moment";
import {
	ORGANISATION_FETCH_SUCCESS,
	ORGANISATIONS_CURRENT_ORGANISATION_CHANGED,
} from "features/organisations";
import { TASK_HOME_ROUTE } from "features/home/home-routes";
import { GENERIC_ERROR_MESSAGE } from "utils";

const getActiveTimesheetEffect = (action$, state$) =>
	action$.pipe(
		ofType(
			ORGANISATION_FETCH_SUCCESS,
			ORGANISATIONS_CURRENT_ORGANISATION_CHANGED,
			timesheetActionTypes.ACTIVE_TIMESHEET_REQUESTED
		),
		filter(() => tokenExists()),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(({ payload }) => {
			return ajax
				.get(
					`${getOrganisationBaseUrl(state$.value)}/timesheet/active`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return of(
							onActiveTimesheetReceive(ajaxResponse.response)
						);
					}),
					catchError((error) => {
						return of(onActiveTimesheetFailed(GENERIC_ERROR_MESSAGE));
					})
				);
		})
	);

const addTimesheetEffect = (action$, state$) =>
	action$.pipe(
		ofType(timesheetActionTypes.ADD_TIMESHEET_FORM_SUBMIT),
		mergeMap(() => {
			return ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/timesheet/create`,
					{
						siteId: state$.value.timesheetFormReducer.siteId,
						staffId:
							state$.value.timesheetFormReducer.timesheet.staffId,
						startDateTimeUTC: moment(
							state$.value.timesheetFormReducer.timesheet
								.startDate +
								" " +
								state$.value.timesheetFormReducer.timesheet
									.startTime,
							"YYYY-MM-DD HH:mm"
						).toISOString(),
						endDateTimeUTC: moment(
							state$.value.timesheetFormReducer.timesheet
								.endDate +
								" " +
								state$.value.timesheetFormReducer.timesheet
									.endTime,
							"YYYY-MM-DD HH:mm"
						).toISOString(),
						lunchTimeMinutes: parseInt(
							state$.value.timesheetFormReducer.timesheet
								.lunchTime,
							10
						),
						notes: state$.value.timesheetFormReducer.timesheet
							.notes,
						startLatitude:
							state$.value.timesheetFormReducer.timesheet
								.startLatitude,
						startLongitude:
							state$.value.timesheetFormReducer.timesheet
								.startLongitude,
						endLatitude:
							state$.value.timesheetFormReducer.timesheet
								.endLatitude,
						endLongitude:
							state$.value.timesheetFormReducer.timesheet
								.endLongitude,
						organisationTaskTypeId:
							state$.value.timesheetFormReducer.timeesheet
								.organisationTaskTypeId
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return of(
							onAddTimesheetSaveSuccess(),
							push(LIST_TIMESHEETS_ROUTE)
						);
					}),
					catchError((error) =>
						of(onAddTimesheetSaveFailed(GENERIC_ERROR_MESSAGE))
					)
				);
		})
	);

const updateTimesheetEffect = (action$, state$) =>
	action$.pipe(
		ofType(timesheetActionTypes.UPDATE_TIMESHEET_FORM_SUBMIT),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/timesheet/update/${
						state$.value.timesheetFormReducer.timesheet.id
					}`,
					{
						siteId: state$.value.timesheetFormReducer.siteId,
						staffId:
							state$.value.timesheetFormReducer.timesheet.staffId,
						startDateTimeUTC: moment(
							state$.value.timesheetFormReducer.timesheet
								.startDate +
								" " +
								state$.value.timesheetFormReducer.timesheet
									.startTime,
							"YYYY-MM-DD HH:mm"
						).toISOString(),
						endDateTimeUTC: moment(
							state$.value.timesheetFormReducer.timesheet
								.endDate +
								" " +
								state$.value.timesheetFormReducer.timesheet
									.endTime,
							"YYYY-MM-DD HH:mm"
						).toISOString(),
						lunchTimeMinutes: parseInt(
							state$.value.timesheetFormReducer.timesheet
								.lunchTime,
							10
						),
						notes: state$.value.timesheetFormReducer.timesheet
							.notes,
						startLatitude:
							state$.value.timesheetFormReducer.timesheet
								.startLatitude,
						startLongitude:
							state$.value.timesheetFormReducer.timesheet
								.startLongitude,
						endLatitude:
							state$.value.timesheetFormReducer.timesheet
								.endLatitude,
						endLongitude:
							state$.value.timesheetFormReducer.timesheet
								.endLongitude,
						Id:
							state$.value.timesheetFormReducer.timeesheet
								.id
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return of(
							onAddTimesheetSaveSuccess(),
							refreshTimesheetList()
						);
					}),
					catchError((error) =>
						of(onAddTimesheetSaveFailed(GENERIC_ERROR_MESSAGE))
					)
				)
		)
	);

const startActiveTimesheetEffect = (action$, state$) =>
	action$.pipe(
		ofType(timesheetActionTypes.ADD_TIMESHEET_FORM_START_TIMER),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/timesheet/start`,
					{
						siteId: state$.value.timesheetFormReducer.siteId,
						organisationTaskTypeId: state$.value.timesheetFormReducer.organisationTaskTypeId,
						startDateTimeUTC: moment(
							state$.value.timesheetFormReducer.timesheet
								.startDate +
								" " +
								state$.value.timesheetFormReducer.timesheet
									.startTime,
							"YYYY-MM-DD HH:mm"
						).toISOString(),
						startLatitude:
							state$.value.timesheetFormReducer.timesheet
								.startLatitude,
						startLongitude:
							state$.value.timesheetFormReducer.timesheet
								.startLongitude,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return of(
							onActiveTimesheetRequest(),
							onAddTimesheetStartSuccess()
						);
					}),
					catchError((error) => {
						return of(onAddTimesheetStartFailed(GENERIC_ERROR_MESSAGE));
					})
				)
		)
	);

const confirmActiveTimesheetEffect = (action$, state$) =>
	action$.pipe(
		ofType(timesheetActionTypes.ADD_TIMESHEET_FORM_CONFIRM),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/timesheet/stop`,
					{
						siteId: state$.value.timesheetFormReducer.siteId,
						endDateTimeUTC: moment(
							state$.value.timesheetFormReducer.timesheet
								.endDate +
								" " +
								state$.value.timesheetFormReducer.timesheet
									.endTime,
							"YYYY-MM-DD HH:mm"
						).toISOString(),
						lunchTimeMinutes: parseInt(
							state$.value.timesheetFormReducer.timesheet
								.lunchTime,
							10
						),
						notes: state$.value.timesheetFormReducer.timesheet
							.notes,
						endLatitude:
							state$.value.timesheetFormReducer.timesheet
								.endLatitude,
						endLongitude:
							state$.value.timesheetFormReducer.timesheet
								.endLongitude,
						organisationTaskTypeId:
							state$.value.timesheetFormReducer.organisationTaskTypeId,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return of(
							onConfirmActiveTimesheetSuccess(),
							onAddTimesheetReset(),
							push(LIST_TIMESHEETS_ROUTE)
						);
					}),
					catchError((error) => {
						return of(
							onConfirmActiveTimesheetFailed(GENERIC_ERROR_MESSAGE)
						);
					})
				)
		)
	);

const discardActiveTimesheetEffect = (action$, state$) =>
	action$.pipe(
		ofType(timesheetActionTypes.ADD_TIMESHEET_FORM_DISCARD),
		mergeMap(({ payload }) => {
			return ajax
				.delete(
					`${getOrganisationBaseUrl(
						state$.value
					)}/timesheet/delete/${payload}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						return of(
							onDiscardActiveTimesheetSuccess(),
							onAddTimesheetReset(),
							push(TASK_HOME_ROUTE)
						);
					}),
					catchError((error) => {
						return of(
							onDiscardActiveTimesheetFailed(GENERIC_ERROR_MESSAGE)
						);
					})
				);
		})
	);

export const timesheetEffects = combineEpics(
	getActiveTimesheetEffect,
	updateTimesheetEffect,
	addTimesheetEffect,
	startActiveTimesheetEffect,
	confirmActiveTimesheetEffect,
	discardActiveTimesheetEffect,
);
