import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { PageHeaderTabs } from "features/health-safety-forms";
import { getPageResults, useIsOnline, isStaffUser, useRouter } from "utils";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	Pagination,
	PaginationHeader,
	PaginationLoader,
	PopupSnackbar,
	SelectInput
} from "frame/components";
import { connect, useSelector } from "react-redux";
import {
	ADD_TIMESHEET_ROUTE,
	ADD_ADMIN_TIMESHEET_ROUTE,
	NEW_LEAVE_PATH
} from "../timesheet-routes";
import { EditTimesheetForm } from "./edit-timesheet-form";
import { TimesheetCard } from "./timesheet-card";
import { TASK_ROUTE } from "features/health-safety-forms";
import { LIST_TIMESHEETS_ROUTE } from "../timesheet-routes";
import {
	Grid,
	TextField,
	InputAdornment,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Card,
	CardContent
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { safetyWiseApi } from "utils/safetyWiseApi";
import { Button } from "@material-ui/core";
import { initialTimesheetState } from "../timesheet-reducer";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import moment from "moment";
import { SITES_ROUTE } from "../../sites/actions/types";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
	filter: {
		"position": "relative",
		"right": 25,
		"top": 10,
		"marginTop": theme.spacing(0.1),
		"& .MuiOutlinedInput-input": {
			padding: theme.spacing(1.7, 2)
		},
		"& .MuiInputLabel-outlined": {
			transform: "translate(14px, 14px) scale(1)"
		},
		"& .MuiInputLabel-shrink": {
			transform: "translate(14px, -6px) scale(0.75)"
		},
		[theme.breakpoints.down("sm")]: {
			right: -100
		},
		[theme.breakpoints.down("xs")]: {
			top: 50,
			left: 20,
			minWidth: "100px"
		},
		"minWidth": "150px"
	},
	search: {
		display: "flex"
	},
	button: {
		"padding": theme.spacing(1.75, 3),
		"color": theme.palette.brand,
		"fontWeight": 600,
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(1, 3)
		},
		"background": theme.palette.highlight,
		"borderColor": theme.palette.highlight,
		"&:hover": {
			background: "#c1c72c",
			borderColor: "#c1c72c"
		},
		"width": "100%"
	},
	buttonSecondary: {
		"padding": theme.spacing(1.75, 3),
		"color": theme.palette.text.secondary,
		"fontWeight": 600,
		"&:hover": {
			color: theme.palette.brand
		},
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(1, 3)
		},
		"width": "100%"
	},
	gridContainer: {
		margin: theme.spacing(3, 3, 0, 3),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(5)
		}
	},
	filtersChip: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.white
	},
	chipsContainer: {
		marginTop: theme.spacing(1)
	},
	chipLabel: {
		color: theme.palette.text.tertiary
	},
	accordionSummarycontainer: {
		width: "100%",
		display: "flex",
		alignItems: "center"
	},
	accordionSummary: {
		marginRight: 10
	}
}));

const mapStateToProps = ({ profile, timesheetFormReducer, organisations }) => ({
	timesheetForm: timesheetFormReducer,
	role: profile.role,
	currentStaffId: profile.staffId,
	currentOrganisationGUID: organisations.currentOrganisation
});

const mapDispatchToProps = {};

export const TimesheetList = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ role, timesheetForm, currentOrganisationGUID }) => {
	const classes = useStyles();
	const { match } = useRouter();
	const { siteId } = match.params;
	let siteName = "";
	if (siteId) {
		const siteList = useSelector(({ sites }) => sites.pagination?.results);
		siteName = siteList.find((site) => site.id == siteId).siteName;
	}
	const [searchFilter, setSearchFilter] = useState(siteName || "");
	const [statusFilter, setStatusFilter] = useState("All");
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const onEdit = (id) => () => {
		setSelectedItemId(id);
		setShowEditModal(true);
	};
	const [dateFromFilter, setDateFromFilter] = useState();
	const [dateToFilter, setDateToFilter] = useState();
	const [staffTypeFilterOptions, setStaffTypeFilterOptions] = useState([]);
	const [staffTypeFilter, setStaffTypeFilter] = useState(["", null]); // Staff Type Name and OrganisationStaffTypeId
	const [timesheetList, setTimesheetList] = useState(getPageResults(initialTimesheetState));
	const [isDoneFetching, setIsDoneFetching] = useState(false);
	const [staffTypesResult, setStaffTypesResult] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [showFilters, setShowFilters] = useState(false);
	const hasActiveTimesheet = timesheetForm.timesheet.toggle;
	const [totalNumberOfRecords, setTotalNumberOfRecords] = useState(1);
	const [hasMoreTimesheets, setHasMoreTimesheets] = useState(true);
	const defaultPageSize = 20;

	useEffect(() => {
		safetyWiseApi.get(`${currentOrganisationGUID}/organisation-staff-type/get`).then((result) => {
			const staffTypeFilterOptionsList = [];

			for (let i = 0; i < result.data.length; i++) {
				staffTypeFilterOptionsList.push({
					label: result.data[i].staffType,
					value: result.data[i].staffType
				});
			}

			setStaffTypeFilterOptions(staffTypeFilterOptionsList);
			setStaffTypesResult(result.data);
		});

		getTimesheets();
	}, []);

	// function to setNewTimesheetState only called from getTimesheets function. Broken down to another func to simplify it.
	const setNewTimesheetState = (timesheetResult, page) => {
		let newState = initialTimesheetState;

		if (timesheetResult && timesheetResult.results && timesheetResult.results.length > 0) {
			newState = {
				...newState,
				...timesheetResult
			};
		}

		newState.refreshList = timesheetResult.refreshList;
		newState.flag = "done";
		// If navigating to first page clear the resuls from timesheetList otherwise add to the end of it
		if (page === 1) {
			setTimesheetList([...getPageResults(newState)]);
		} else {
			setTimesheetList([...timesheetList, ...getPageResults(newState)]);
		}
		setIsDoneFetching(true);
	};

	const getTimesheets = (
		fromPageToRequest = currentPage, // fromPageToRequest is the page to request from the API,
		recordDeletedOrUpdated = false, // recordDeletedOrUpdated is a boolean to determine whether to overwrite or append to timesheetList
		pageSize = defaultPageSize // pageSize is the number of records to load from the API, can be paramaterised to request more data.
	) => {
		if (totalNumberOfRecords !== timesheetList.length || recordDeletedOrUpdated) {
			if (recordDeletedOrUpdated) {
				setHasMoreTimesheets(true); // If record deleted or updated, set HasMoreTimesheets to true to pull latest data
			}

			safetyWiseApi
				.post(`${currentOrganisationGUID}/timesheet/paged`, {
					ascending: false,
					columnName: statusFilter === "All" ? undefined : "isActive",
					columnValue: (() => {
						if (statusFilter === "All") {
							return undefined;
						} else if (statusFilter === "Active") {
							return "true";
						}
						return "false";
					})(),
					orderBy: "isActive,startDateTimeUTC",
					page: fromPageToRequest,
					pageSize,
					searchKey: searchFilter,
					staffTypeFilter: staffTypeFilter[1],
					dateFromFilter,
					dateToFilter
				})
				.then((result) => {
					setNewTimesheetState(result.data, fromPageToRequest);

					// If more then the defaultPageSize data is requested from first page then dynamically set the current page, else set to fromPageToRequest
					if (pageSize > defaultPageSize && fromPageToRequest === 1) {
						setCurrentPage(pageSize / defaultPageSize);
					} else {
						setCurrentPage(fromPageToRequest);
					}

					setTotalNumberOfRecords(result.data.totalNumberOfRecords);
					if (
						result.data.totalNumberOfRecords ==
						timesheetList.length + result.data.results.length
					) {
						setHasMoreTimesheets(false);
					}
				});
		} else {
			setHasMoreTimesheets(false);
		}
	};

	const ref = useRef(null);
	const [height, setHeight] = useState(0);

	// The InfiniteScroll Component requires a height for it to work  effectively and for it resize relative to the parent.
	useEffect(() => {
		const handleResize = () => {
			setHeight(ref.current.offsetHeight - 20); // reduce height by 20px ottherwise the scrollbar flows past the height of screen.
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<Page className={classes.root} title="Timesheet Records" style={{ height: "100%" }}>
			<div ref={ref} style={{ backgroundColor: "", height: "100%", overflow: "hidden" }}>
				{/* <p>
					{totalNumberOfRecords} {timesheetList.length}
				</p> */}
				{/* n.o of records and length of loaded list */}

				<InfiniteScroll
					dataLength={timesheetList.length}
					next={() => {
						getTimesheets(currentPage + 1);
					}}
					hasMore={hasMoreTimesheets}
					loader={<CardText message={"Loading..."} />}
					height={height + "px"}
					scrollThreshold={0.99}
					style={{ paddingBottom: "10px" }}
				>
					<ScrollToTopOnMount />
					<PageHeaderTabs
						title="Timesheet Records"
						staffUserHide={false}
						addTabTitle={hasActiveTimesheet ? "Active Timesheet" : undefined}
						hideAddTabIcon={hasActiveTimesheet}
						addTabPath={ADD_TIMESHEET_ROUTE}
						backActionPath={siteId ? `${SITES_ROUTE}/${siteId}` : TASK_ROUTE}
						showAddAdminButton={!isStaffUser(role)}
						listTabPath={LIST_TIMESHEETS_ROUTE}
						addAdminPath={ADD_ADMIN_TIMESHEET_ROUTE}
						showLeaveButton={true}
						newLeavePath={NEW_LEAVE_PATH}
					/>

					{/* START: Filters */}
					<div className={classes.gridContainer}>
						<Accordion expanded={showFilters} onChange={() => setShowFilters(!showFilters)}>
							<AccordionSummary>
								<div className={classes.accordionSummarycontainer}>
									<Typography variant="h5" className={classes.accordionSummary}>
										{showFilters ? "Hide Filters" : "Show Filters"}
									</Typography>
									{showFilters ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									{/* {!showFilters && showSelectedFilters && (
								<Grow in={!showFilters} timeout={1000}>
								<Grid container className={classes.chipsContainer}>
									<Grid item  xs={12} md={4} lg={3}>
										<Typography className={classes.chipLabel} variant="body2">
											{"Search: " + searchFilter}
										</Typography>
									</Grid>
									<Grid item xs={12} md={4} lg={3}>
										<Typography className={classes.chipLabel} variant="body2">
											From
										</Typography>
									</Grid>
								</Grid>
							 </Grow>
							)} */}
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={2}>
									<Grid item xs={12} md={4} lg={3}>
										<TextField
											className={classes.search}
											id="input-with-icon-textfield"
											variant="outlined"
											label="Search"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<SearchIcon />
													</InputAdornment>
												)
											}}
											value={searchFilter}
											onChange={(val) => {
												const { value } = val.target;
												setSearchFilter(value);
											}}
											style={{ backgroundColor: "white" }}
											placeholder={"e.g Site Name, Notes, Staff Name"}
										/>
									</Grid>
									<Grid item xs={12} md={4} lg={3}>
										<div style={{ display: "flex" }}>
											<DatePicker
												format="DD/MM/YYYY"
												fullWidth
												inputVariant="outlined"
												label="From"
												value={dateFromFilter || null}
												variant="dialog"
												disableFuture={true}
												maxDate={dateToFilter || moment()}
												onChange={(date) => {
													setDateFromFilter(date);
												}}
												autoOk
												style={{ flex: 1 }}
												okLabel=""
												clearable
												showTodayButton
											/>
											<DatePicker
												format="DD/MM/YYYY"
												fullWidth
												inputVariant="outlined"
												label="To"
												value={dateToFilter || null}
												variant="dialog"
												disableFuture={true}
												minDate={dateFromFilter || moment([2000, 1, 1])}
												onChange={(date) => {
													setDateToFilter(date);
												}}
												autoOk
												style={{ flex: 1, marginLeft: "10px" }}
												okLabel=""
												clearable
												showTodayButton
											/>
										</div>
									</Grid>
									<Grid item xs={12} md={4} lg={2}>
										<SelectInput
											id="staffType"
											label={"Staff Type"}
											options={staffTypeFilterOptions}
											value={staffTypeFilter[0]}
											onChange={(val) => {
												if (val === "") {
													setStaffTypeFilter([val, null]);
												} else {
													let staffType = staffTypesResult.find((s) => s.staffType === val);
													setStaffTypeFilter([val, staffType.organisationStaffTypeId]);
												}
											}}
										/>
									</Grid>
									<Grid item xs={12} md={3} lg={2}>
										<SelectInput
											id="status"
											label={"Status"}
											options={[
												{ label: "All", value: "All" },
												{ label: "Active", value: "Active" },
												{ label: "Closed", value: "Closed" }
											]}
											value={statusFilter}
											onChange={(val) => {
												setStatusFilter(val);
											}}
											noNone
										/>
									</Grid>
									<Grid item xs={6} md={2} lg={1}>
										<Button
											className={classes.button}
											variant="contained"
											onClick={async () => {
												getTimesheets(1, true); // Always go to first page if search is pressed
											}}
										>
											Submit
										</Button>
									</Grid>
									<Grid item xs={6} md={2} lg={1}>
										<Button
											className={classes.buttonSecondary}
											variant="contained"
											onClick={() => {
												setSearchFilter("");
												setDateFromFilter(null);
												setDateToFilter(null);
												setStaffTypeFilter(["", null]);
												setStatusFilter("All");
												setCurrentPage(1);
											}}
										>
											Reset
										</Button>
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
					</div>
					{/* END: Filters */}

					<PageContent pad>
						<p style={{ marginTop: 5 }}>
							{timesheetList.length > 0 ? totalNumberOfRecords + " records" : "No records loaded"}
						</p>
						<div>
							{timesheetList.length > 0 &&
								isDoneFetching &&
								timesheetList.map((node, index) => (
									<TimesheetCard
										key={index}
										timesheet={node}
										onEdit={onEdit(node.id)}
										onDelete={(id) => {
											safetyWiseApi
												.del(`${currentOrganisationGUID}/timesheet/delete/${id}`)
												.then(() => {
													getTimesheets(1, true);
												});
										}}
										role={role}
										style={{ marginTop: 50, backgroundColor: "red" }}
									/>
								))}
						</div>
					</PageContent>
					<EditTimesheetForm
						open={showEditModal}
						id={selectedItemId}
						timesheetList={timesheetList}
						currentOrganisationGUID={currentOrganisationGUID}
						onClose={() => {
							const timesheetIndex = timesheetList.findIndex((t) => t.id === selectedItemId); // find timesheetIndex
							//timesheetIndex + 1 to get position in array, then get the next n(pageSize)TH multiple which will be the page size to load
							const pageSizeToLoad =
								Math.ceil((timesheetIndex + 1) / defaultPageSize) * defaultPageSize;

							// Get timesheets first page to the place the user was at before editing (using pageSizetoLoad),
							getTimesheets(1, true, pageSizeToLoad);

							setShowEditModal(false);
						}}
					/>
					{!isDoneFetching && <CardText message={"Loading..."} style={{ marginTop: "20px" }} />}
					{!hasMoreTimesheets && <CardText message={"All timesheets have been loaded."} />}
					{timesheetList.length === 0 && isDoneFetching && (
						<CardText message={"No timesheets to show."} />
					)}
				</InfiniteScroll>
				<PopupSnackbar message={timesheetForm.errorMessage} coloured error onExited={() => {}} />
			</div>
		</Page>
	);
});

export const CardText = ({ message, style }) => {
	const theme = useTheme();
	return (
		<Card
			style={{
				display: "flex",
				alignItems: "center",
				flexWrap: "wrap",
				position: "relative",
				margin: theme.spacing(0, 3, 3, 3),
				...style
			}}
		>
			<CardContent
				style={{
					padding: theme.spacing(2),
					flexGrow: 1,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					[theme.breakpoints.down("sm")]: {
						width: "100%",
						flexWrap: "wrap"
					}
				}}
			>
				<p>{message}</p>
			</CardContent>
		</Card>
	);
};
