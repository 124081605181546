import {
	defaultFetchState,
	fetchRequested,
	fetchFailed,
	fetchSuccess,
	defaultSaveState,
	saveRequested,
	saveFailed,
	saveSuccess,
} from "utils";

import fromTypes from "../actions/types";

const defaultState = {
	alertEmails: [],
	...defaultFetchState,
	...defaultSaveState,
};

const preferences = (state = defaultState, action) => {
	switch (action.type) {
		case fromTypes.PREFERENCES_CHANGE_ALERT_EMAILS:
			return {
				...state,
				alertEmails: action.payload,
			};
		case fromTypes.PREFERENCES_FETCH:
			return fetchRequested(state);
		case fromTypes.PREFERENCES_FETCH_FAILED:
			return fetchFailed(state, action.payload);
		case fromTypes.PREFERENCES_FETCH_SUCCESS:
			return {
				...fetchSuccess(state),
				data: action.payload,
			};
		case fromTypes.PREFERENCES_SAVE:
			return saveRequested(state);
		case fromTypes.PREFERENCES_SAVE_FAILED:
			return saveFailed(state, action.payload);
		case fromTypes.PREFERENCES_SAVE_SUCCESS:
			return saveSuccess(state);
		case fromTypes.PREFERENCES_RESET:
			return defaultState;
		default:
			return state;
	}
};

export default preferences;
