import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	PageHeaderAddButton,
	SelectInput,
} from "frame/components";
import { ADMIN_HOME_ROUTE } from "features/home/home-routes";
import {
	Card,
	CardContent,
	Grid,
	Typography,
	Chip,
	Modal,
} from "@material-ui/core";
import clsx from "clsx";
import { safetyWiseApi } from "utils/safetyWiseApi";
import { connect } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
	CrudOptions,
	FormActions,
	FormButton,
	FormSubmitButton,
} from "frame/components";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
	root: {},
	card: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
	},
	modalCard: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	modalGridContainer: {
		marginTop: theme.spacing(3),
	},
	content: {
		padding: theme.spacing(1, 3),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(4),
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	body: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(3),
		},
	},
	header: {
		margin: theme.spacing(0, 0, 2, 0),
	},
	divider: {
		marginTop: theme.spacing(1),
		height: 1,
	},
	filter: { display: "flex" },
	actions: {
		marginTop: theme.spacing(5),
		justifyContent: "flex-end",
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
}));

const mapStateToProps = ({ organisations }) => ({
	currentOrganisationGUID: organisations.currentOrganisation,
});

const StaffType = connect(mapStateToProps)(({ currentOrganisationGUID }) => {
	const classes = useStyles();
	const [staffTypes, setStaffTypes] = useState([]);
	const [showAddStaffType, setShowAddStaffType] = useState(false);
	const [staffTypeInput, setStaffTypeInput] = useState("");
	const [staffTypeStatusChangeInput, setStaffTypeStatusChangeInput] =
		useState(0);
	const [addingStaffType, setAddingStaffType] = useState(false);
	const [
		selectedOrganisationStaffTypeId,
		setSelectedOrganisationStaffTypeId,
	] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [staffTypeErrorMessage, setStaffTypeErrorMessage] = useState("");

	const getStaffTypeList = () => {
		safetyWiseApi
			.get(`${currentOrganisationGUID}/organisation-staff-type/get`)
			.then((result) => {
				setStaffTypes(result.data);
				setIsLoading(false);
			});
	};
	useEffect(() => {
		dayjs.extend(customParseFormat);
		getStaffTypeList();
	}, [showAddStaffType]);

	return (
		<Page className={classes.root} title="Staff">
			<ScrollToTopOnMount />

			<PageHeaderAddButton
				title="Staff Type"
				addTabTitle="Add Staff Type"
				onAdd={() => {
					setAddingStaffType(true);
					setShowAddStaffType(true);
				}}
				staffUserHide={false}
				backActionPath={ADMIN_HOME_ROUTE}
			/>

			<PageContent pad>
				<div className={classes.body}>
					{staffTypes.length === 0 ? (
						<Card
							data-cy={`staff-$`}
							className={clsx(classes.card, undefined)}
						>
							<CardContent className={classes.content}>
								<Grid
									container
									spacing={2}
									className={clsx(
										classes.container,
										classes.clickable
									)}
								>
									<Grid item lg={3} md={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
											{isLoading
												? "Loading..."
												: "No results found."}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					) : (
						<>
							{staffTypes.map((staffType, index) => (
								<Card
									data-cy={`staff-$`}
									className={clsx(classes.card, undefined)}
									key={index}
								>
									<CardContent className={classes.content}>
										<Grid
											container
											spacing={2}
											className={clsx(
												classes.container,
												classes.clickable
											)}
										>
											<Grid item lg={8} md={6} xs={12}>
												<Typography
													className={classes.label}
													variant="body2"
												>
													Staff Type
												</Typography>
												<Typography variant="h5">
													{staffType.staffType || "-"}
												</Typography>
											</Grid>

											<Grid item lg={4} md={6} xs={12}>
												<Typography
													className={classes.label}
													variant="body2"
												>
													Active
												</Typography>
												<Chip
													className={
														staffType.active
															? classes.success
															: classes.error
													}
													size="small"
													label={
														staffType.active
															? "Active"
															: "Inactive"
													}
												/>
											</Grid>
										</Grid>
										<div className="ignore">
											<CrudOptions
												onEdit={() => {
													setAddingStaffType(false);
													setShowAddStaffType(true);
													setSelectedOrganisationStaffTypeId(
														staffType.organisationStaffTypeId
													);
													setStaffTypeInput(
														staffType.staffType
													);

													if (staffType.active) {
														setStaffTypeStatusChangeInput(
															"Active"
														);
													} else {
														setStaffTypeStatusChangeInput(
															"Inactive"
														);
													}
												}}
												onDelete={() => {
													safetyWiseApi
														.del(
															`${currentOrganisationGUID}/organisation-staff-type/delete/${staffType.organisationStaffTypeId}`
														)
														.then(() => {
															getStaffTypeList();
														});
												}}
												customRemoveModelText={
													'Please note that deleting this will make the staff type "Inactive" You will abe able to make it "Active" again by editing the staff type. '
												}
											/>
										</div>
									</CardContent>
								</Card>
							))}
						</>
					)}

					{showAddStaffType && (
						<Modal
							open={true}
							onClose={() => {
								setShowAddStaffType(false);
							}}
						>
							<Card
								className={clsx(classes.modalCard, undefined)}
							>
								<ValidatorForm
									autoComplete="off"
									noValidate
									onSubmit={() => {
										if (
											staffTypes.filter(
												(s) =>
													s.staffType.toLowerCase() ===
														staffTypeInput.toLowerCase() && //If any matches the staffTypeInput don't duplicate it
													s.organisationStaffTypeId !==
														selectedOrganisationStaffTypeId //To make sure its not comparing to itself
											).length > 0
										) {
											setStaffTypeErrorMessage(
												"This staff type already exists. Please enter a different staff type."
											);
										} else if (addingStaffType) {
											safetyWiseApi
												.post(
													`${currentOrganisationGUID}/organisation-staff-type/add`,
													{
														StaffType:
															staffTypeInput,
													}
												)
												.then(() => {
													setShowAddStaffType(false);
												});
										} else {
											safetyWiseApi
												.post(
													`${currentOrganisationGUID}/organisation-staff-type/update`,
													{
														OrganisationStaffTypeId:
															selectedOrganisationStaffTypeId,
														StaffType:
															staffTypeInput,
														Active:
															staffTypeStatusChangeInput ===
															"Active"
																? true
																: false,
													}
												)
												.then(() => {
													setShowAddStaffType(false);
												});
										}
									}}
								>
									<CardContent>
										<Typography
											align="center"
											gutterBottom
											variant="h3"
										>
											{addingStaffType ? "Add" : "Edit"}{" "}
											Staff Type
										</Typography>
										<Grid
											className={
												classes.modalGridContainer
											}
											container
											spacing={3}
										>
											<Grid item md={6} xs={12}>
												<TextValidator
													fullWidth
													label="Staff Type *"
													name="staffType"
													value={staffTypeInput}
													variant="outlined"
													validators={["required"]}
													errorMessages={[
														"This field is required",
													]}
													onChange={(e) => {
														setStaffTypeInput(
															e.target.value
														);
													}}
												/>
											</Grid>
											<Grid item md={6} xs={12}>
												<SelectInput
													noNone
													id="active"
													name="active"
													label="Active"
													options={[
														"Active",
														"Inactive",
													]}
													value={
														staffTypeStatusChangeInput ||
														"Active"
													}
													disabled={
														addingStaffType
															? true
															: false
													}
													onChange={(value) =>
														setStaffTypeStatusChangeInput(
															value
														)
													}
												/>
											</Grid>
											{staffTypeErrorMessage && (
												<Grid item md={6} xs={12}>
													<Typography variant="h5">
														{staffTypeErrorMessage}
													</Typography>
												</Grid>
											)}
										</Grid>
										<Grid container>
											<Grid item md={6} xs={12}>
												<FormActions
													className={classes.actions}
												>
													<FormSubmitButton
														disabled={false}
													>
														Save
													</FormSubmitButton>
													<FormButton
														onClick={() =>
															setShowAddStaffType(
																false
															)
														}
													>
														Cancel
													</FormButton>
												</FormActions>
											</Grid>
										</Grid>
									</CardContent>
								</ValidatorForm>
							</Card>
						</Modal>
					)}
				</div>
			</PageContent>
		</Page>
	);
});

export default StaffType;
