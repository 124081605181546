import { connect } from "react-redux";
import CustomerList from "./customers";
import {
	onAdd,
	onEdit,
	onDelete,
	onGetCustomerDetails,
	onSearchChanged,
	onPagedListRequested,
} from "../actions";

const mapStateToProps = ({ customers, profile }) => ({
	role: profile.role,
	pagination: customers.pagination,
	searchFilter: customers.searchFilter,
	isAddModalShowing: customers.isAddModalShowing,
});

const mapDispatchToProps = {
	onAdd,
	onEdit,
	onDelete,
	getCustomerDetails: onGetCustomerDetails,
	onSearchChanged,
	onGetCustomersList: onPagedListRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
