import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
} from "frame/components";
import { Card, CardContent, Grid, TextField } from "@material-ui/core";
import { UploadFormCard } from "features/forms";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
}));

const DocumentPackAddEditModal = ({
	documentPack,
	onCancel,
	onSave,
	isOpen,
	isSaving,
	mode,
}) => {
	const classes = useStyles();
	const [updatedDocumentPack, setUpdatedDocumentPack] = useState({
		name: "",
		notes: "",
		attachments: [],
	});

	useEffect(() => {
		if (isOpen) {
			if (mode === "Add") {
				setUpdatedDocumentPack({
					name: "",
					notes: "",
					attachments: [],
				});
			} else {
				setUpdatedDocumentPack({
					...documentPack,
					attachments: documentPack.attachments.map((attachment) => {
						return { ...attachment };
					}),
				});
			}
		}
	}, [isOpen]);

	const onNameChange = (e) => {
		setUpdatedDocumentPack({
			...updatedDocumentPack,
			name: e.target.value,
		});
	};

	const onNotesChange = (e) => {
		setUpdatedDocumentPack({
			...updatedDocumentPack,
			notes: e.target.value,
		});
	};

	return (
		<Dialog
			open={isOpen}
			onClose={onCancel}
			title={
				mode === "Add"
					? "Add Document"
					: "Edit Document: " + documentPack.name
			}
		>
			<ValidatorForm
				autoComplete="off"
				noValidate
				onSubmit={() => {
					onSave(updatedDocumentPack);
				}}
			>
				<Card className={classes.root}>
					<CardContent>
						<Grid
							className={classes.container}
							container
							spacing={3}
						>
							<Grid item md={6} xs={12}>
								<TextValidator
									fullWidth
									label="Name *"
									name="name"
									value={updatedDocumentPack.name}
									variant="outlined"
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={onNameChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id="notes"
									fullWidth
									label="Notes"
									multiline
									rows="5"
									value={updatedDocumentPack.notes || ""}
									variant="outlined"
									onChange={onNotesChange}
								/>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
				{mode === "Add" && (
					<UploadFormCard
						title={"Attachments"}
						values={updatedDocumentPack}
						onChange={(update) => {
							setUpdatedDocumentPack({
								...updatedDocumentPack,
								attachments: update.attachments.map(
									(attachment) => {
										return {
											fileName: attachment.fileName,
											fileExtension:
												attachment.fileExtension,
											dataURL: attachment.dataURL,
										};
									}
								),
							});
						}}
					/>
				)}
				<Grid item md={6} xs={12}>
					<FormActions className={classes.actions}>
						<FormSubmitButton
							loading={isSaving}
							disabled={isSaving}
						>
							Save
						</FormSubmitButton>
						<FormButton onClick={onCancel} disabled={isSaving}>
							Cancel
						</FormButton>
					</FormActions>
				</Grid>
			</ValidatorForm>
		</Dialog>
	);
};

export default DocumentPackAddEditModal;
