import { connect } from "react-redux";
import JobSiteList from "./jobs-staff-list";
import { onEdit, onAdd, onDelete } from "../actions";

const mapStateToProps = ({ jobs, profile, queue }) => ({
	isAddModalShowing: jobs.isAddModalShowing,
	currentUserId: profile.id,
	role: profile.role,
	hasNetwork: queue.hasNetwork,
});

const mapDispatchToProps = {
	onAdd,
	onEdit,
	onDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobSiteList);
