import React from "react";
// import { makeStyles } from '@material-ui/core/styles'
// import Snackbar from '@material-ui/core/Snackbar'
// import IconButton from '@material-ui/core/IconButton'
// import CloseIcon from '@material-ui/icons/Close'
import PropTypes from "prop-types";

// const useStyles = makeStyles(theme => ({
//   close: {
//     padding: theme.spacing(0.5),
//   },
// }))

const SimpleSnackbar = ({ errorList = [], onCloseClicked }) => {
	// const classes = useStyles()

	if (process.env.NODE_ENV === "production") {
		return null;
	}

	// disabled popup black error bubble
	return (
		<>
			{/* {errorList.map((item, index) => {

        if ( ! item
          || ! item.error
          || ! item.error.message) {
          return null
        }

        return (
          <Snackbar
            key={index}
            open={true}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            autoHideDuration={6000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{item.error.message}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={() => onCloseClicked(item.id)}>
                <CloseIcon />
              </IconButton>,
            ]} />
        )
      })} */}
		</>
	);
};

SimpleSnackbar.propTypes = {
	errorList: PropTypes.array.isRequired,
	onCloseClicked: PropTypes.func.isRequired,
};

export default SimpleSnackbar;
