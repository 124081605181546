import {
	defaultSaveState,
	saveRequested,
	saveFailed,
	saveSuccess,
} from "utils";

import * as fromTypes from "../actions/types";

const defaultState = {
	...defaultSaveState,
};

const invitations = (state = defaultState, action) => {
	switch (action.type) {
		case fromTypes.INVITATION_SAVE:
			return saveRequested(state);
		case fromTypes.INVITATION_SAVE_FAILED:
			return saveFailed(state, action.payload);
		case fromTypes.INVITATION_SAVE_SUCCESS:
			return saveSuccess(state);
		default:
			return state;
	}
};

export default invitations;
