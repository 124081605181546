import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";
import Upload from "../upload-form/upload";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(1, 0, 3, 0),
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0),
		height: 2,
	},
}));

const UploadSectionForm = ({ disabled, values, onChange }) => {
	const classes = useStyles();

	return (
		<CardContent className={classes.root}>
			<Divider className={classes.divider} />
			<CardHeader
				className={classes.heading}
				title="Add Attachments"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="body1">
						Attach documents, photos and audio files. <br />
					</Typography>
					<Upload
						values={values}
						onChange={onChange}
						reference="attachments"
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

UploadSectionForm.propTypes = {
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default UploadSectionForm;
