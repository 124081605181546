import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { ofType } from "redux-observable";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import Types from "../actions/types";
import { onRemoveFailed, onRemoveSuccess } from "../actions";

const taskTypeRemoveEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.TASK_TYPE_DELETE_REQUESTED),
		filter(() => state$.value.organisations.currentOrganisation),
		mergeMap(({ payload }) =>
			ajax
				.delete(
					`${getOrganisationBaseUrl(
						state$.value
					)}/organisation-task-type/delete/${payload}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onRemoveSuccess(ajaxResponse.response))
					),
					catchError((error) => of(onRemoveFailed(error)))
				)
		)
	);

export default taskTypeRemoveEffect;
