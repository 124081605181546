import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Modal, Card, CardContent, Typography, Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	SelectInput,
} from "frame/components";
import { isDefaultGuid } from "utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		justifyContent: "flex-end",
		marginTop: theme.spacing(3),
	},
	warning: {
		padding: theme.spacing(1, 0),
		color: theme.palette.error.main,
	},
	emptyOption: {
		display: "none",
	},
}));

const EditHazardToSiteForm = ({
	siteId,
	hazardId,
	values,
	onChange,
	onSave,
	isEditHazardToSiteModalShowing,
	onClose,
}) => {
	const classes = useStyles();

	const [originalData, setOriginalData] = useState(values); // original site state - current site
	const [currentHazards, setCurrentHazards] = useState(values.hazards);

	const [currentSiteHazard, setCurrentSiteHazard] = useState(null); // hazard details

	const siteList = useSelector(({ sites }) => sites.pagination?.results);
	const hazardList = useSelector(
		({ healthSafety }) => healthSafety.hazards?.results
	);

	useEffect(() => {
		// runs only once to set original data for reseting state on exit
		async function fetchSiteData() {
			const site = siteList && siteList.filter((s) => s.id === siteId)[0];
			setOriginalData(site || null);
		}

		if (siteId && !isDefaultGuid(siteId)) {
			fetchSiteData();
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		async function fetchHazardData() {
			const hazard =
				hazardList && hazardList.filter((h) => h.id === hazardId)[0];
			setCurrentSiteHazard(hazard || null);
		}

		if (hazardId) {
			fetchHazardData();
		} // eslint-disable-next-line
	}, [hazardId]);

	// const currentSiteHazard = values && values.hazards.filter(node => node.hazardId === hazardId)[0]
	const currentIndex =
		currentSiteHazard &&
		currentHazards.findIndex((h) => h.hazardId === currentSiteHazard.id);

	if (!isEditHazardToSiteModalShowing || !hazardId) {
		return null;
	}

	const onSubmit = (event) => {
		event.preventDefault();
		if (currentSiteHazard) {
			onSave(siteId);
		}
		onClose();
	};

	const onExit = () => {
		onChange(originalData); // reset hazards state
		onClose();
	};

	const onCancel = () => onExit();
	const onClickAway = () => onExit();

	const onStatusChanged = (value) => {
		if (values && currentSiteHazard) {
			let hazards = values.hazards;
			// const index = hazards.indexOf(currentSiteHazard)
			hazards[currentIndex].status = value;
			onChange({ ...values, hazards });
			setCurrentHazards(hazards);
		}
	};

	const onInputChange =
		(key) =>
		({ target: { value } }) => {
			if (values && currentSiteHazard) {
				let hazards = values.hazards;
				hazards[currentIndex][key] = value;
				onChange({ ...values, hazards });
				setCurrentHazards(hazards);
			}
		};

	return (
		<Modal open={true} onClose={onClose} onBackdropClick={onClickAway}>
			<Card className={classes.root}>
				<ValidatorForm autoComplete="off" onSubmit={onSubmit}>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography
									align="center"
									gutterBottom
									variant="h3"
								>
									{`Edit ${
										(currentSiteHazard &&
											currentSiteHazard.name) ||
										"hazard"
									} at ${originalData.siteName || "site"}`}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextValidator
									id="site_hazard_location"
									fullWidth
									label="Location"
									value={
										currentHazards &&
										currentHazards[currentIndex] &&
										currentHazards[currentIndex].location
											? currentHazards[currentIndex]
													.location
											: ""
									}
									variant="outlined"
									inputProps={{ maxLength: 50 }}
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={onInputChange("location")}
								/>
							</Grid>
							<Grid item xs={12}>
								<SelectInput
									noNone
									id="site_hazard_status"
									label="Status"
									placeholder="Select Status"
									options={["Active", "Archived"]}
									value={
										currentSiteHazard &&
										currentSiteHazard.status
											? currentSiteHazard.status
											: "Active"
									}
									onChange={onStatusChanged}
								/>
							</Grid>
						</Grid>
						<FormActions className={classes.actions}>
							<FormSubmitButton>Save</FormSubmitButton>
							<FormButton onClick={onCancel}>Cancel</FormButton>
						</FormActions>
					</CardContent>
				</ValidatorForm>
			</Card>
		</Modal>
	);
};

EditHazardToSiteForm.propTypes = {
	siteId: PropTypes.string.isRequired,
	hazardId: PropTypes.string,
	isEditHazardToSiteModalShowing: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default EditHazardToSiteForm;
