import React from "react";
import LandingPageFrame from "./landing-page-frame";
import SetPasswordForm from "./forms/set-password-form";

const SetPassword = (props) => {
	return (
		<LandingPageFrame
			title={"Set Password"}
			hideImage={props.token === ""}
			form={<SetPasswordForm {...props} />}
		/>
	);
};

export default SetPassword;
