const QUEUE_SEND_SUCCEEDED = "QUEUE_SEND_SUCCEEDED";
const QUEUE_SEND_FAILED = "QUEUE_SEND_FAILED";
const QUEUE_SEND_REQUESTED = "QUEUE_SEND_REQUESTED";
const QUEUE_ITEM_ADDED = "QUEUE_ITEM_ADDED";
const QUEUE_NETWORK_CHANGED = "QUEUE_NETWORK_CHANGED";
const CHECK_NETWORK_CONNECTION = "CHECK_NETWORK_CONNECTION";

export default {
	QUEUE_SEND_SUCCEEDED,
	QUEUE_SEND_FAILED,
	QUEUE_SEND_REQUESTED,
	QUEUE_ITEM_ADDED,
	QUEUE_NETWORK_CHANGED,
	CHECK_NETWORK_CONNECTION,
};
