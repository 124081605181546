import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";

const mapStateToProps = ({ addStaffTrainingTopicReducer }) => ({
	values: addStaffTrainingTopicReducer,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddStaffTrainingTopicValueChange,
	onReset: fromActions.onAddStaffTrainingTopicReset,
	onSave: fromActions.onAddStaffTrainingTopicSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
