import { connect } from "react-redux";
import IncidentList from "./incident-list";
import {
	onRemove,
	onIncidentSearchChanged,
	onIncidentPagedListRequest,
} from "../../actions";

const mapStateToProps = ({ healthSafety, profile, organisations }) => {
	return {
		incidents: healthSafety.incidents,
		profile: profile,
		searchFilter: healthSafety.searchFilter,
		currentOrganisationGUID: organisations.currentOrganisation,
	};
};

const mapDispatchToProps = {
	onDelete: onRemove,
	onSearchChanged: onIncidentSearchChanged,
	onGetIncidentList: onIncidentPagedListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentList);
