import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
} from "frame/components";
import { strip, DateForm, ExtraNotesForm } from "features/forms";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		flexDirection: "row",
		padding: theme.spacing(3, 3, 10, 3),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
	menu: {
		padding: theme.spacing(3, 0, 0, 0),
		"& > *": {
			marginRight: theme.spacing(3),
		},
	},
	guidelines: {
		margin: theme.spacing(0, 3),
	},
}));

const hydrate = ({
	id,
	createdAt,
	people,
	otherPeople,
	appendNotes,
	attachments,
	recipients,
	recipientsOther,
	organisationId,
	...training
}) => ({
	id,
	createdAt,
	training,
	people,
	otherPeople,
	appendNotes,
	attachments,
	recipients,
	recipientsOther,
	currentOrganisation: organisationId,
});

const Form = ({ id, values, onClose, onChange, onReset, onSave }) => {
	const classes = useStyles();
	const staffTrainingList = useSelector(
		({ healthSafety }) => healthSafety.staffTrainings?.results
	);

	const [loaded, setLoaded] = useState(false);
	const open = values && values.id;

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchTrainingRecords() {
			setLoaded(true);
			const trainingRecord =
				staffTrainingList &&
				staffTrainingList.filter((t) => t.id === id)[0];

			if (trainingRecord) {
				onChange(hydrate(trainingRecord));
			}
		}

		if (id) {
			setLoaded(true);
			fetchTrainingRecords();
		} // eslint-disable-next-line
	}, [id, loaded, onChange]);

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
		onClose();
	};

	const onCancel = () => {
		onReset();
		onClose();
	};

	if (!open) {
		return null;
	}

	const valid =
		values.appendNotes &&
		values.appendNotes.length === 1 &&
		values.appendNotes[0].notes !== "";

	return (
		<Dialog
			open={open ? true : false}
			aria-labelledby="Append Note to Staff Training"
			title="Append Note to Staff Training"
			onClose={onCancel}
		>
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				<div className={classes.body}>
					<DateForm disabled values={values} onChange={onChange} />
					<ExtraNotesForm
						useAppendNotes
						values={values}
						onChange={onChange}
					/>
				</div>
				<FormActions className={classes.actions}>
					<FormSubmitButton
						disabled={values.locked || !valid}
						loading={values.save.saving}
					>
						Save
					</FormSubmitButton>
					<FormButton
						disabled={values.save.saving}
						onClick={onCancel}
					>
						Cancel
					</FormButton>
				</FormActions>
			</ValidatorForm>
		</Dialog>
	);
};

Form.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

export default Form;
