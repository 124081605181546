import { combineEpics } from "redux-observable";
import siteListEffect, { sitePagedListEffect } from "./site-list-effect";
import siteAddEffect from "./site-add-effect";
import siteRemoveEffect from "./site-remove-effect";

import addHazardToSiteEffect from "./add-hazard-to-site-effect";
import addHazardousSubstanceToSiteEffect from "./add-hazardous-substance-to-site-effect";
import removeHazardFromSiteEffect from "./remove-hazard-from-site-effect";
import removeHazardousSubstanceFromSiteEffect from "./remove-hazardous-substance-from-site-effect";
import siteLookupEffect from "./site-lookup-effect";

const sitesEffects = combineEpics(
	siteListEffect,
	sitePagedListEffect,
	siteAddEffect,
	siteRemoveEffect,
	addHazardToSiteEffect,
	addHazardousSubstanceToSiteEffect,
	removeHazardFromSiteEffect,
	removeHazardousSubstanceFromSiteEffect,
	siteLookupEffect
);

export default sitesEffects;
