import { connect } from "react-redux";
import InductionMinutesSiteList from "./induction-minutes-site-list";
import { onRemove } from "../../actions";

const mapStateToProps = ({ healthSafety, profile }) => ({
	role: profile.role,
});

const mapDispatchToProps = {
	onDelete: onRemove,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InductionMinutesSiteList);
