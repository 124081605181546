import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Page, PageContent, ScrollToTopOnMount } from "frame/components";
import { useRouter } from "utils";
import SafetyPlanChecklistCard from "../cards/safety-plan-checklist-card";
import { EditSafetyPlanChecklistForm } from "features/health-safety-forms";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		backgroundColor: theme.palette.white,
	},
	heading: {
		marginTop: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0),
		},
	},
	body: {
		margin: theme.spacing(3, 0),
	},
	empty: {
		marginTop: theme.spacing(3),
	},
	filter: { display: "flex" },
}));

const SafetyPlanChecklistSiteList = ({ role, onDelete, onEmail }) => {
	const classes = useStyles();
	const { match } = useRouter();
	const { siteId } = match.params;

	const [editId, setEditId] = useState();
	const [viewId, setViewId] = useState(null);
	const [siteSPC, setSiteSPC] = useState(null);
	const [isClone, setIsClone] = useState(false);

	const spcList = useSelector(
		({ healthSafety }) => healthSafety.safetyPlanChecklists?.results
	);

	useEffect(() => {
		async function fetchData() {
			setSiteSPC(
				spcList ? spcList.filter((node) => node.siteId === siteId) : []
			);
		}
		fetchData(); // eslint-disable-next-line
	}, [setSiteSPC, siteId]);

	const empty = siteSPC && siteSPC.length <= 0;

	const onView = (id) => () => setViewId(id);

	const onEdit = (id) => () => setEditId(id);

	const onClone = (id) => () => {
		setIsClone(true);
		setEditId(id);
	};

	const onCancelEdit = () => {
		setEditId(null);
		setViewId(null);
		setIsClone(false);
	};

	return (
		<Page className={classes.root} title="Site Safety Plan (Checklist)">
			<ScrollToTopOnMount />

			<PageContent pad>
				<Typography variant="h2" className={classes.heading}>
					Site Safety Plan (Checklist)
				</Typography>

				{!empty && (
					<div className={classes.body}>
						{siteSPC &&
							siteSPC.map((item, index) => (
								<SafetyPlanChecklistCard
									key={index}
									safetyPlanChecklist={item}
									onView={onView(item.id)}
									onEdit={onEdit(item.id)}
									onClone={onClone(item.id)}
									onDelete={onDelete}
									role={role}
									onEmail={onEmail}
								/>
							))}
					</div>
				)}

				{empty && (
					<Typography className={classes.empty} variant="body1">
						There are currently no Safety Plan Checklists listed.
					</Typography>
				)}
			</PageContent>
			{viewId && (
				<EditSafetyPlanChecklistForm
					id={viewId}
					readOnly
					onClose={onCancelEdit}
				/>
			)}
			{editId && (
				<EditSafetyPlanChecklistForm
					id={editId}
					isClone={isClone}
					onClose={onCancelEdit}
				/>
			)}
		</Page>
	);
};

SafetyPlanChecklistSiteList.propTypes = {
	role: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
	onEmail: PropTypes.func.isRequired,
};

export default SafetyPlanChecklistSiteList;
