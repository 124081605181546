// https://material.io/resources/icons/?search=people&style=baseline
// import RoomIcon from '@material-ui/icons/Room'
// import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
// import PersonIcon from '@material-ui/icons/PersonOutlined'
// import PeopleIcon from '@material-ui/icons/People'
// import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
// import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
// import WarningIcon from '@material-ui/icons/Warning'
// import ForumIcon from '@material-ui/icons/Forum'
// import { types as fromHealthSafety } from 'features/health-and-safety'
// import * as fromHealthSafetyFormRoutes from 'features/health-safety-forms'
// import { CUSTOMERS_PATH } from 'features/customers'
// import { STAFF_PATH } from 'features/staff'
// import { SITES_PATH } from 'features/sites'
// import { ADD_JOB_PATH } from 'features/jobs'
import {
	HOME_ROUTE,
	INFO_HOME_ROUTE,
	TASK_HOME_ROUTE,
	ADMIN_HOME_ROUTE,
} from "features/home/home-routes";

import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ViewListIcon from "@material-ui/icons/ViewList";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

export default [
	{
		title: "Pages",
		pages: [
			{
				title: "Home",
				href: HOME_ROUTE,
				icon: DashboardIcon,
			},
			{
				title: "Info",
				href: INFO_HOME_ROUTE,
				icon: EqualizerIcon,
			},
			{
				title: "Tasks",
				href: TASK_HOME_ROUTE,
				icon: ViewListIcon,
			},
			{
				title: "Admin",
				href: ADMIN_HOME_ROUTE,
				icon: AssignmentIndIcon,
			},
			// {
			//   title: 'Sites',
			//   href: SITES_PATH,
			//   icon: RoomIcon,
			// },
			// {
			//   title: 'Health & Safety',
			//   href: fromHealthSafety.HEALTH_SAFETY_ROUTE,
			//   icon: LocalHospitalIcon,
			// },
			// {
			//   title: 'Hazard Library',
			//   href: fromHealthSafety.HEALTH_SAFETY_HAZARDS_ROUTE,
			//   icon: WarningIcon,
			// },
			// {
			//   title: 'Hazardous Substance Library',
			//   href: fromHealthSafety.HEALTH_SAFETY_HAZARDOUS_SUBSTANCES_ROUTE,
			//   icon: WarningIcon,
			// },
			// {
			//   title: 'Safety Minutes',
			//   href: fromHealthSafety.HEALTH_SAFETY_SAFETY_MINUTES_ROUTE,
			//   icon: ForumIcon,
			// },
			// {
			//   title: 'Staff',
			//   href: STAFF_PATH,
			//   icon: PersonIcon,
			// },
			// {
			//   title: 'Timesheet',
			//   href: fromHealthSafetyFormRoutes.ADD_TIMESHEET_PATH,
			//   icon: AccessAlarmIcon,
			// },
			// {
			//   title: 'Staff Training',
			//   href: fromHealthSafety.HEALTH_SAFETY_STAFF_TRAINING_ROUTE,
			//   icon: PeopleIcon,
			// },
		],
	},
];
