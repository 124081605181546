import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Page, PageContent, PageHeader } from "frame/components";
import { useRouter, useCurrentRole } from "utils";
import { ChildRoutes } from "./account-routes";
import MenuTabs from "./menu-tabs";
import * as fromRoutes from "../actions/types";
import { getTabConfig } from "../config";

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const Account = ({ role }) => {
	const classes = useStyles();
	const currentRole = useCurrentRole();
	const theme = useTheme();
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

	const { match } = useRouter();
	const { tabId } = match.params;

	const config = getTabConfig(tabId, currentRole);

	const breadcrumbs = [
		{
			label: `Account`,
			value: fromRoutes.ACCOUNT_PATH,
		},
	];

	return (
		<Page className={classes.root} title={config.label}>
			<PageHeader breadcrumbs={breadcrumbs} title={config.label} />
			<PageContent>
				{mobileView && <br />}
				<MenuTabs role={role} />
				<ChildRoutes />
			</PageContent>
		</Page>
	);
};

Account.propTypes = {
	role: PropTypes.string.isRequired,
};

export default Account;
