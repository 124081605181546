import { connect } from "react-redux";
import SiteForm from "./site-form";

import {
	onSiteNameChanged,
	onCustomerNameChanged,
	onAddressChanged,
	onSuburbChanged,
	onTownCityChanged,
	onPostCodeChanged,
	onSiteContactPersonChanged,
	onSiteContactPhoneChanged,
	onSiteContactEmailChanged,
	onStatusChanged,
	onCloseClicked,
	onSaveClicked,
} from "../actions";

const mapStateToProps = ({ customers, sites, profile, organisations }) => ({
	customerList: customers.pagination.results,
	site: sites.currentSite,
	isAddModalShowing: sites.isAddModalShowing,
	isEditModalShowing: sites.isEditModalShowing,
	role: profile.role,
	organisationId: organisations.currentOrganisation,
});

const mapDispatchToProps = {
	onSiteNameChanged,
	onCustomerNameChanged,
	onAddressChanged,
	onSuburbChanged,
	onTownCityChanged,
	onPostCodeChanged,
	onSiteContactPersonChanged,
	onSiteContactPhoneChanged,
	onSiteContactEmailChanged,
	onStatusChanged,
	onCloseClicked,
	onSaveClicked,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteForm);
