import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
	Menu,
	MenuItem,
	FormControlLabel,
	IconButton,
} from "@material-ui/core";

import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
	root: {},
	label: {
		color: "red",
	},
}));

const StaffRoleSelectTableSelectRole = ({
	onSelectRole,
	role,
	customOptions,
	showInvalidPeopleError,
}) => {
	const classes = useStyles();
	const [openRowOptions, setOpenRowOptions] = useState(null);
	const [selectedRole, setSelectedRole] = useState(role);

	const roleOptions = customOptions
		? customOptions
		: ["Staff", "Manager", "Supervisor"];

	const handleOpenRoleMenu = (event) => {
		setOpenRowOptions(event.currentTarget);
	};

	return (
		<>
			<FormControlLabel
				labelPlacement="start"
				classes={{
					label:
						showInvalidPeopleError && !selectedRole
							? classes.label
							: "",
				}}
				label={selectedRole ? selectedRole : `Select`}
				control={
					<IconButton
						edge="end"
						aria-label="select-role"
						onClick={handleOpenRoleMenu}
					>
						<ExpandMore />
					</IconButton>
				}
			/>
			{openRowOptions && (
				<Menu
					className={classes.roleMenu}
					id="options-menu"
					anchorEl={openRowOptions}
					keepMounted
					open={Boolean(openRowOptions)}
					onClose={() => setOpenRowOptions(false)}
				>
					{roleOptions.map((roleName, key) => (
						<MenuItem
							key={key}
							id={roleName}
							data-cy={roleName.toLowerCase()}
							onClick={() => {
								setSelectedRole(roleName); // update UI
								onSelectRole(roleName); // update people state
								setOpenRowOptions(null); // close modal
							}}
						>
							{roleName}
						</MenuItem>
					))}
				</Menu>
			)}
		</>
	);
};

StaffRoleSelectTableSelectRole.propTypes = {
	onSelectRole: PropTypes.func.isRequired,
	role: PropTypes.string,
	customOptions: PropTypes.array,
	showInvalidPeopleError: PropTypes.bool,
};

export default StaffRoleSelectTableSelectRole;
