import { useEffect } from "react";
import { useContentBody } from "frame/providers";

const ScrollToTopOnMount = () => {
	const bodyRef = useContentBody();

	useEffect(() => {
		if (bodyRef.current) {
			bodyRef.current.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [bodyRef]);

	return null;
};

export default ScrollToTopOnMount;
