import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import Upload from "./upload";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	content: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		paddingTop: theme.spacing(1),
		background: theme.palette.background.paper,
		borderRadius: "4px",
	},
}));

const ImageUploadForm = ({ title, values, onChange }) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardHeader
				title={title || "Add Photo"}
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent className={classes.content}>
				<Upload title={title} values={values} onChange={onChange} />
			</CardContent>
		</Card>
	);
};

ImageUploadForm.propTypes = {
	title: PropTypes.string,
	values: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};

export default ImageUploadForm;
