import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { TextValidator } from "react-material-ui-form-validator";

import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { RelatedHazardSubstanceForm } from "features/forms";
import { RadioListInput, SelectInput } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
}));

const IncidentForm = ({ className, siteId, values, onChange }) => {
	const classes = useStyles();
	const accident = values.accident || {};
	const disabled = values.locked;

	const onInputChange =
		(key) =>
		({ target: { value } }) =>
			onChange({
				...values,
				accident: {
					...accident,
					[key]: value,
				},
			});

	const onRadioChange = (key) => (value) =>
		onChange({
			...values,
			accident: {
				...accident,
				[key]: value,
			},
		});

	const onSelectChange = (key) => (value) =>
		onChange({
			...values,
			accident: {
				...accident,
				[key]: value,
			},
		});

	const yesNo = [
		{
			label: "Yes",
			value: "yes",
		},
		{
			label: "No",
			value: "no",
		},
	];

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Incident"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextValidator
							id="accident_incident"
							data-cy="accident_incident"
							disabled={disabled}
							fullWidth
							label="Incident Details"
							multiline
							rows="3"
							required
							value={accident.incidentDetails || ""}
							variant="outlined"
							validators={["required"]}
							errorMessages={["This field is required"]}
							onChange={onInputChange("incidentDetails")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="accident_action"
							data-cy="accident_action"
							disabled={disabled}
							fullWidth
							label="Action"
							multiline
							rows="3"
							value={accident.action || ""}
							variant="outlined"
							onChange={onInputChange("action")}
						/>
					</Grid>
					<Grid item xs={12}>
						<SelectInput
							noNone
							id="accident_category"
							disabled={disabled}
							label="Category"
							options={[
								"Near miss",
								"MTI",
								"non-MTI",
								"Notifiable",
							]}
							value={accident.category}
							onChange={onSelectChange("category")}
						/>
					</Grid>
					<Grid item xs={12}>
						<RelatedHazardSubstanceForm
							siteId={siteId}
							values={values}
							onChange={onChange}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography align="left" gutterBottom variant="h5">
							Serious Harm
						</Typography>
						<RadioListInput
							id="accident_seriousHarm"
							data-cy="accident_seriousHarm"
							disabled={disabled}
							initialValue={accident.seriousHarm}
							options={yesNo}
							onChange={onRadioChange("seriousHarm")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="accident_outcomes"
							data-cy="accident_outcomes"
							disabled={disabled}
							fullWidth
							label="Investigation Outcomes"
							multiline
							rows="3"
							value={accident.investigationOutcomes || ""}
							variant="outlined"
							onChange={onInputChange("investigationOutcomes")}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

IncidentForm.propTypes = {
	className: PropTypes.string,
	siteId: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default IncidentForm;
