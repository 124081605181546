import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Fade } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ImagePlaceholder } from "frame/components";
import { isNotNullOrEmpty } from "utils";

const useStyles = makeStyles((theme) => ({
	images: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "flex-start",
		alignItems: "flex-start",
	},
	image: {
		margin: theme.spacing(0, 2, 2, 0),
		// width: 60,
		// height: 60,
		objectFit: "cover",
		borderRadius: 4,
		"&:hover": {
			opacity: 0.8,
			cursor: "zoom-in",
		},
	},
	hidden: {
		display: "none",
	},
}));
const reactModalStyles = {
	overlay: {
		zIndex: 1500,
	},
};

const LightboxWrapper = ({ attachments }) => {
	const classes = useStyles();

	const [loaded, setLoaded] = React.useState(
		new Array(attachments.length).fill(false)
	);

	const [photoIndex, setPhotoIndex] = React.useState(0);
	const [lightboxIsOpen, setLightboxIsOpen] = React.useState(false);
	const nextImage = () => {
		setPhotoIndex((photoIndex + 1) % images.length);
	};
	const previousImage = () => {
		setPhotoIndex((photoIndex + images.length - 1) % images.length);
	};
	const images = attachments.map((image) => {
		return image.dataURL;
	});

	const imageLoaded = (key) => {
		setTimeout(() => {
			setLoaded([(loaded[key] = true), ...loaded]);
		}, 1000);
	};
	
	return (
		<div>
			<div className={classes.images}>
				{isNotNullOrEmpty(attachments) &&
					attachments.map(({ fileName, dataURL, cloudinaryURL, cloudinaryPublicId }, key) => (
						<div key={key}>
							{!loaded[key] && <ImagePlaceholder />}
							<Fade in={loaded[key]}>
								<LazyLoadImage
									className={
										loaded[key]
											? classes.image
											: classes.hidden
									}
									alt={fileName}
									onLoad={() => imageLoaded(key)}
									height={60}
									width={60}
									src={cloudinaryURL && cloudinaryPublicId != null ? cloudinaryURL: dataURL}
									onClick={() => {
										setLightboxIsOpen(true);
										setPhotoIndex(key);
									}}
								/>
							</Fade>
						</div>
					))}
			</div>
			{lightboxIsOpen && (
				<Lightbox
					mainSrc={images[photoIndex]}
					nextSrc={images[(photoIndex + 1) % images.length]}
					prevSrc={
						images[(photoIndex + images.length - 1) % images.length]
					}
					onCloseRequest={() => setLightboxIsOpen(!lightboxIsOpen)}
					onMovePrevRequest={previousImage}
					onMoveNextRequest={nextImage}
					reactModalStyle={reactModalStyles}
				/>
			)}
		</div>
	);
};

LightboxWrapper.propTypes = {
	attachments: PropTypes.array.isRequired,
};

export default LightboxWrapper;
