import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import {
	Button,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(1, 0, 3, 0),
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0),
		height: 2,
	},
	button: {
		margin: theme.spacing(2, 0),
	},
}));

const createDefaultOtherPerson = (index = 0) => ({
	index,
	key: `declarationOtherPerson${index}`,
	name: "",
	date: null,
});

const getInputValue = (event) => {
	if (event && event.target) {
		return event.target.value || "";
	} else if (event && event.utc) {
		return event.utc().format();
	}
	return event || "";
};

const DeclarationForm = ({
	className,
	disabled,
	values,
	onInputChange,
	onSelectChange,
}) => {
	const classes = useStyles();
	const declarationOtherPeople =
		values.declarationOtherPeople && values.declarationOtherPeople.length
			? values.declarationOtherPeople
			: [createDefaultOtherPerson()];

	const current = declarationOtherPeople
		.map(({ index, ...node }) => node)
		.filter((x) => x.name)
		.filter((node) => Object.keys(node).length);

	const canAddOther = declarationOtherPeople.length !== current.length;

	const onAddOtherPerson = () =>
		onSelectChange("declarationOtherPeople")([
			...declarationOtherPeople,
			createDefaultOtherPerson(declarationOtherPeople.length),
		]);

	const onSelectDate = (key) => (value) =>
		onSelectChange(key)(value.utc().format());

	const onOtherPersonChange = (key, personIndex) => (event) => {
		const value = getInputValue(event);
		const next = [...declarationOtherPeople];
		const person = next[personIndex] || {};
		person[key] = value;
		next[personIndex] = person;

		const list = Array.from(new Set(next))
			.filter((x) => x.name || x.date)
			.map((node, index) => ({ index, ...node }));

		const nextState = list.length ? list : [createDefaultOtherPerson()];
		onSelectChange("declarationOtherPeople")(nextState);
	};

	const isDefaultDate = (date) => moment(date).format("YYYY") === "0001";
	const dateValue = (date) =>
		date ? (!isDefaultDate(date) ? date : null) : null;

	return (
		<CardContent className={classes.root}>
			<Divider className={classes.divider} />
			<CardHeader
				className={classes.heading}
				title="Declaration"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						Main person (PCBU1)
						<br />
						We have read the SSSP provided by Party 2 and agree to
						it from the duration of this contract.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="safety_plan_declaration_person_1_name"
						disabled={disabled}
						fullWidth
						label="Name"
						value={values.declarationName || ""}
						variant="outlined"
						onChange={onInputChange("declarationName")}
					/>
				</Grid>
				<Grid item xs={12}>
					<DatePicker
						className={classes.field}
						disabled={disabled}
						format="Do MMMM YYYY"
						fullWidth
						inputVariant="outlined"
						label="Date"
						value={dateValue(values.declarationDate)}
						variant="outlined"
						disableFuture
						onChange={onSelectDate("declarationDate")}
					/>
				</Grid>
				{/* <Grid
          item
          xs={12}>
          <UploadFile
            values={values}
            disabled={disabled}
            onFileChange={onFileChange}
            reference="declarationPerson1Attachment"
          />
        </Grid> */}
			</Grid>
			{declarationOtherPeople.map((node, index) => {
				const person = index + 2;

				return (
					<Grid key={index} container spacing={2}>
						<Grid item xs={12}>
							<Typography
								align="left"
								gutterBottom
								variant="body1"
							>
								Other person (PCBU{person})
								<br />
								We have read the SSSP provided by Party {
									person
								}{" "}
								and agree to it from the duration of this
								contract.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id={`safety_plan_declaration_person_${index}_name`}
								disabled={disabled}
								fullWidth
								label="Other Person Name"
								value={node.name || ""}
								variant="outlined"
								onChange={onOtherPersonChange("name", index)}
							/>
						</Grid>
						<Grid item xs={12}>
							<DatePicker
								className={classes.field}
								disabled={disabled}
								format="Do MMMM YYYY"
								fullWidth
								inputVariant="outlined"
								label="Date"
								disableFuture
								value={dateValue(node.date)}
								variant="outlined"
								onChange={onOtherPersonChange("date", index)}
							/>
						</Grid>
					</Grid>
				);
			})}
			<Divider />
			<Button
				color="primary"
				className={classes.button}
				disabled={canAddOther}
				size="small"
				variant="outlined"
				onClick={onAddOtherPerson}
			>
				+ Add More Other Person
			</Button>
		</CardContent>
	);
};

DeclarationForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onInputChange: PropTypes.func.isRequired,
	onSelectChange: PropTypes.func.isRequired,
};

export default DeclarationForm;
