export const ACCOUNT_ROUTE = `/account`;
export const ACCOUNT_TAB_ROUTE = `${ACCOUNT_ROUTE}/:tabId`;

export const ACCOUNT_PATH = `/account`;

export const ACCOUNT_MANGE_STAFF_TYPE = `/staff-type`;

export const ACCOUNT_MANAGE_TASK_TYPE = `/task-type`;

// Tab paths
export const ACCOUNT_INVITE_PATH = `invite`;
export const ACCOUNT_NOTIFICATIONS_PATH = `notifications`;
export const ACCOUNT_ORGANISATIONS_PATH = `organisations`;
export const ACCOUNT_PASSWORD_PATH = `password`;
export const ACCOUNT_PROFILE_PATH = `profile`;
export const ACCOUNT_REPORTS_PATH = `reports`;
export const ACCOUNT_SUBSCRIPTION_PATH = `subscription`;

// Tab routes
export const ACCOUNT_INVITE_ROUTE = `${ACCOUNT_ROUTE}/${ACCOUNT_INVITE_PATH}`;
export const ACCOUNT_NOTIFICATIONS_ROUTE = `${ACCOUNT_ROUTE}/${ACCOUNT_NOTIFICATIONS_PATH}`;
export const ACCOUNT_ORGANISATIONS_ROUTE = `${ACCOUNT_ROUTE}/${ACCOUNT_ORGANISATIONS_PATH}`;
export const ACCOUNT_PASSWORD_ROUTE = `${ACCOUNT_ROUTE}/${ACCOUNT_PASSWORD_PATH}`;
export const ACCOUNT_PROFILE_ROUTE = `${ACCOUNT_ROUTE}/${ACCOUNT_PROFILE_PATH}`;
export const ACCOUNT_REPORTS_ROUTE = `${ACCOUNT_ROUTE}/${ACCOUNT_REPORTS_PATH}`;
export const ACCOUNT_SUBSCRIPTION_ROUTE = `${ACCOUNT_ROUTE}/${ACCOUNT_SUBSCRIPTION_PATH}`;

export const INVITATION_SAVE = "INVITATION_SAVE_REQUESTED";
export const INVITATION_SAVE_FAILED = "INVITATION_SAVE_FAILED";
export const INVITATION_SAVE_SUCCESS = "INVITATION_SAVE_SUCCESS";

export const PASSWORD_SAVE = "PASSWORD_SAVE_REQUESTED";
export const PASSWORD_SAVE_FAILED = "PASSWORD_SAVE_FAILED";
export const PASSWORD_SAVE_SUCCESS = "PASSWORD_SAVE_SUCCESS";
export const PASSWORD_SAVE_RESET = "PASSWORD_SAVE_RESET";

export const PREFERENCES_FETCH = "PREFERENCES_FETCH_REQUESTED";
export const PREFERENCES_FETCH_FAILED = "PREFERENCES_FETCH_FAILED";
export const PREFERENCES_FETCH_SUCCESS = "PREFERENCES_FETCH_SUCCESS";
export const PREFERENCES_SAVE_EMAIL_NOTIFICATIONS =
	"PREFERENCES_SAVE_EMAIL_NOTIFICATIONS";
export const PREFERENCES_SAVE_EMAIL_NOTIFICATIONS_FAILED =
	"PREFERENCES_SAVE_EMAIL_NOTIFICATIONS_FAILED";
export const PREFERENCES_SAVE_EMAIL_NOTIFICATIONS_SUCCESS =
	"PREFERENCES_SAVE_EMAIL_NOTIFICATIONS_SUCCESS";

export const PREFERENCES_SAVE = "PREFERENCES_SAVE_REQUESTED";
export const PREFERENCES_SAVE_FAILED = "PREFERENCES_SAVE_FAILED";
export const PREFERENCES_SAVE_SUCCESS = "PREFERENCES_SAVE_SUCCESS";
export const PREFERENCES_UNSUBSCRIBE_EMAIL = "PREFERENCES_UNSUBSCRIBE_EMAIL";

export const PREFERENCES_RESET = "PREFERENCES_RESET";
export const PREFERENCES_CHANGE_ALERT_EMAILS =
	"PREFERENCES_CHANGE_ALERT_EMAILS";

export const PROFILE_FETCH = "PROFILE_FETCH_REQUESTED";
export const PROFILE_FETCH_FAILED = "PROFILE_FETCH_FAILED";
export const PROFILE_FETCH_SUCCESS = "PROFILE_FETCH_SUCCESS";

export const PROFILE_SAVE = "PROFILE_SAVE_REQUESTED";
export const PROFILE_SAVE_FAILED = "PROFILE_SAVE_FAILED";
export const PROFILE_SAVE_SUCCESS = "PROFILE_SAVE_SUCCESS";

export const PROFILE_FETCH_NUMBER_OF_RECORDS =
	"PROFILE_FETCH_NUMBER_OF_RECORDS";
export const PROFILE_FETCH_NUMBER_OF_RECORDS_FAILED =
	"PROFILE_FETCH_NUMBER_OF_RECORDS_FAILED";
export const PROFILE_FETCH_NUMBER_OF_RECORDS_SUCCESS =
	"PROFILE_FETCH_NUMBER_OF_RECORDS_SUCCESS";

export const PROFILE_FETCH_SITE_NUMBER_OF_RECORDS =
	"PROFILE_FETCH_SITE_NUMBER_OF_RECORDS";
export const PROFILE_FETCH_SITE_NUMBER_OF_RECORDS_FAILED =
	"PROFILE_FETCH_SITE_NUMBER_OF_RECORDS_FAILED";
export const PROFILE_FETCH_SITE_NUMBER_OF_RECORDS_SUCCESS =
	"PROFILE_FETCH_SITE_NUMBER_OF_RECORDS_SUCCESS";

export const PROFILE_RESET = `PROFILE_RESET`;

export const SEND_INVITE = `SEND_INVITE`;
export const NOTIFICATION_SUBSCRIBE = `NOTIFICATION_SUBSCRIBE`;

export const REPORT_DOWNLOAD = "REPORT_DOWNLOAD_REQUESTED";
export const REPORT_DOWNLOAD_FAILED = "REPORT_DOWNLOAD_FAILED";
export const REPORT_DOWNLOAD_SUCCESS = "REPORT_DOWNLOAD_SUCCESS";
export const REPORT_DOWNLOAD_CLEAR_MESSAGE = "REPORT_DOWNLOAD_CLEAR_MESSAGE";

export const SUBSCRIPTION_FETCH = "SUBSCRIPTION_FETCH";
export const SUBSCRIPTION_FETCH_FAILED = "SUBSCRIPTION_FETCH_FAILED";
export const SUBSCRIPTION_FETCH_SUCCESS = "SUBSCRIPTION_FETCH_SUCCESS";
export const SUBSCRIPTION_ADD_PAYMENT = "SUBSCRIPTION_ADD_PAYMENT";
export const SUBSCRIPTION_ADD_PAYMENT_CLOSE = "SUBSCRIPTION_ADD_PAYMENT_CLOSE";

export const SUBSCRIPTION_ADD_PAYMENT_SAVE = "SUBSCRIPTION_ADD_PAYMENT_SAVE";
export const SUBSCRIPTION_ADD_PAYMENT_SAVE_FAILED =
	"SUBSCRIPTION_ADD_PAYMENT_SAVE_FAILED";
export const SUBSCRIPTION_ADD_PAYMENT_SAVE_SUCCESS =
	"SUBSCRIPTION_ADD_PAYMENT_SAVE_SUCCESS";
export const SUBSCRIPTION_STRIPE_ERROR_RECEIVED =
	"SUBSCRIPTION_STRIPE_ERROR_RECEIVED";

export const SUBSCRIPTION_UPDATE_PAYMENT_SAVE =
	"SUBSCRIPTION_UPDATE_PAYMENT_SAVE";
export const SUBSCRIPTION_UPDATE_PAYMENT_SAVE_SUCCESS =
	"SUBSCRIPTION_UPDATE_PAYMENT_SAVE_SUCCESS";
export const SUBSCRIPTION_UPDATE_PAYMENT_SAVE_FAIL =
	"SUBSCRIPTION_UPDATE_PAYMENT_SAVE_FAIL";

export const SUBSCRIPTION_MODAL_SUBMIT = "SUBSCRIPTION_MODAL_SUBMIT";

export const SUBSCRIPTION_CANCEL_SUBSCRIPTION =
	"SUBSCRIPTION_CANCEL_SUBSCRIPTION";
export const SUBSCRIPTION_CANCEL_SUBSCRIPTION_FAILED =
	"SUBSCRIPTION_CANCEL_SUBSCRIPTION_FAILED";
export const SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESS =
	"SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESS";

export default {
	// Routes
	ACCOUNT_ROUTE,
	ACCOUNT_TAB_ROUTE,

	ACCOUNT_INVITE_ROUTE,
	ACCOUNT_NOTIFICATIONS_ROUTE,
	ACCOUNT_ORGANISATIONS_ROUTE,
	ACCOUNT_PASSWORD_ROUTE,
	ACCOUNT_PROFILE_ROUTE,
	ACCOUNT_REPORTS_ROUTE,
	ACCOUNT_SUBSCRIPTION_ROUTE,

	// Paths
	ACCOUNT_PATH,
	ACCOUNT_INVITE_PATH,
	ACCOUNT_NOTIFICATIONS_PATH,
	ACCOUNT_ORGANISATIONS_PATH,
	ACCOUNT_PASSWORD_PATH,
	ACCOUNT_PROFILE_PATH,
	ACCOUNT_REPORTS_PATH,
	ACCOUNT_SUBSCRIPTION_PATH,

	// Action
	INVITATION_SAVE,
	INVITATION_SAVE_FAILED,
	INVITATION_SAVE_SUCCESS,

	PREFERENCES_FETCH,
	PREFERENCES_FETCH_FAILED,
	PREFERENCES_FETCH_SUCCESS,
	PREFERENCES_SAVE,
	PREFERENCES_SAVE_FAILED,
	PREFERENCES_SAVE_SUCCESS,
	PREFERENCES_RESET,
	PREFERENCES_CHANGE_ALERT_EMAILS,
	PREFERENCES_SAVE_EMAIL_NOTIFICATIONS,
	PREFERENCES_SAVE_EMAIL_NOTIFICATIONS_FAILED,
	PREFERENCES_SAVE_EMAIL_NOTIFICATIONS_SUCCESS,

	PASSWORD_SAVE,
	PASSWORD_SAVE_FAILED,
	PASSWORD_SAVE_SUCCESS,

	PROFILE_FETCH,
	PROFILE_FETCH_FAILED,
	PROFILE_FETCH_SUCCESS,
	PROFILE_SAVE,
	PROFILE_SAVE_FAILED,
	PROFILE_SAVE_SUCCESS,
	PROFILE_RESET,

	REPORT_DOWNLOAD,
	REPORT_DOWNLOAD_FAILED,
	REPORT_DOWNLOAD_SUCCESS,
	REPORT_DOWNLOAD_CLEAR_MESSAGE,

	SUBSCRIPTION_FETCH,
	SUBSCRIPTION_FETCH_FAILED,
	SUBSCRIPTION_FETCH_SUCCESS,
	SUBSCRIPTION_ADD_PAYMENT_SAVE,
	SUBSCRIPTION_ADD_PAYMENT,
	SUBSCRIPTION_ADD_PAYMENT_CLOSE,
	SUBSCRIPTION_ADD_PAYMENT_SAVE_FAILED,
	SUBSCRIPTION_ADD_PAYMENT_SAVE_SUCCESS,
	SUBSCRIPTION_STRIPE_ERROR_RECEIVED,

	SUBSCRIPTION_CANCEL_SUBSCRIPTION,
	SUBSCRIPTION_CANCEL_SUBSCRIPTION_FAILED,
	SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESS,
};
