import { connect } from "react-redux";
import HazardList from "./hazard-list";
import {
	onRemove,
	onHazardSearchChanged,
	onHazardPagedListRequest,
} from "../../actions";

const mapStateToProps = ({ healthSafety, profile }) => ({
	role: profile.role,
	hazards: healthSafety.hazards,
	searchFilter: healthSafety.searchFilter,
});

const mapDispatchToProps = {
	onDelete: onRemove,
	onSearchChanged: onHazardSearchChanged,
	onGetHazardList: onHazardPagedListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(HazardList);
