import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import moment from "moment";
import { AppendNotesListing, TextClip } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	container: {
		marginTop: theme.spacing(-3),
	},
	pastNotes: {},
}));

const NotesForm = ({
	className,
	values,
	onChange,
	disableNotes,
	useAppendNotes,
}) => {
	const classes = useStyles();
	const [pastAppendedNotes, setPastAppendedNotes] = useState();
	const disabled = values.locked || disableNotes;
	const notesTitle = disabled
		? "Notes"
		: `${useAppendNotes ? "Append" : "Add"} Notes`;

	useEffect(() => {
		if (values.appendNotes) {
			setPastAppendedNotes(values.appendNotes);
		}
		onChange({
			...values,
			appendNotes: [],
		}); // eslint-disable-next-line
	}, []);

	const onInputChange =
		(key) =>
		({ target: { value } }) =>
			onChange({
				...values,
				[key]: value,
			});

	const onAppendNotesChange = ({ target: { value } }) =>
		onChange({
			...values,
			appendNotes: [
				{
					createdAt: moment().format(),
					notes: value,
				},
			],
		});

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title={notesTitle}
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent className={classes.container}>
				<Grid container spacing={2}>
					{useAppendNotes ? (
						<>
							<Grid item xs={12}>
								{values.notes && (
									<>
										<Typography variant="h6">
											<strong>Past Notes:</strong>
										</Typography>
										<Typography variant="body1">
											<TextClip
												text={values.notes}
												numberOfCharacters={140}
											/>
										</Typography>{" "}
										<br />
									</>
								)}

								{pastAppendedNotes &&
									pastAppendedNotes.length !== 0 && (
										<AppendNotesListing
											noTitle
											customTypography={"body1"}
											appendNotes={pastAppendedNotes}
											numberOfItems={3}
										/>
									)}
							</Grid>
							<Grid item xs={12}>
								<TextField
									id="notes"
									disabled={disabled}
									fullWidth
									label="Notes"
									multiline
									rows="3"
									variant="outlined"
									onChange={onAppendNotesChange}
								/>
							</Grid>
						</>
					) : (
						<Grid item xs={12}>
							<TextField
								id="notes"
								disabled={disabled}
								fullWidth
								label="Notes"
								multiline
								rows="3"
								value={values.notes || ""}
								variant="outlined"
								onChange={onInputChange("notes")}
							/>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

NotesForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	disableNotes: PropTypes.bool,
	useAppendNotes: PropTypes.bool,
};

export default NotesForm;
