import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRouter } from "utils";
import PageHeaderTabs from "../page-header-tabs";
import { Grid } from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
} from "frame/components";
import { strip, SafetyPlanChecklistForm, SiteForm } from "features/forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
}));

const Form = ({
	values,
	onChange,
	onReset,
	onSave,
	onGetSafetyPlanChecklistList,
}) => {
	const classes = useStyles();
	const { history } = useRouter();

	useEffect(() => {
		onGetSafetyPlanChecklistList();
	}, [onGetSafetyPlanChecklistList]);

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(strip(values));
	};

	const onCancel = () => {
		onReset();
		history.push(fromHealthAndSafety.INFO_ROUTE);
	};

	return (
		<Page title="Add Site Safety Plan (Checklist)">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Safety Plan Checklist"
				title="Add Site Safety Plan (Checklist)"
				// addTabTitle="Add safety plan"
				// listTabTitle="View library of safety plans"
				listTabPath={
					fromHealthAndSafety.INFO_SAFETY_PLAN_CHECKLISTS_ROUTE
				}
				backActionPath={fromHealthAndSafety.INFO_ROUTE}
			/>
			<PageContent padSides padBottom padTop>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Grid container spacing={3}>
						<Grid item lg={6} md={12} sm={12} xs={12}>
							<SiteForm values={values} onChange={onChange} />
							{values.siteId && (
								<SafetyPlanChecklistForm
									values={values}
									onChange={onChange}
								/>
							)}
						</Grid>
					</Grid>

					<FormActions className={classes.actions}>
						<FormSubmitButton
							disabled={values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
						<FormButton
							disabled={values.save.saving}
							onClick={onCancel}
						>
							Cancel
						</FormButton>
					</FormActions>
				</ValidatorForm>
			</PageContent>
		</Page>
	);
};

Form.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onGetSafetyPlanChecklistList: PropTypes.func.isRequired,
};

export default Form;
