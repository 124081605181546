import { combineEpics } from "redux-observable";
import staffAddEffect from "./staff-add-effect";
import { staffListEffect, staffPagedListEffect } from "./staff-list-effect";
import staffLookupEffect from "./staff-lookup-effect";
import staffBatchAddEffect from "./staff-batch-add-effect";
import staffImpersonateEffect from "./staff-impersonate-effect";

const staffEffects = combineEpics(
	staffAddEffect,
	staffListEffect,
	staffPagedListEffect,
	staffLookupEffect,
	staffBatchAddEffect,
	staffImpersonateEffect
);

export default staffEffects;
