import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import { Card } from "@material-ui/core";
import { SearchDropdown } from "frame/components";
import { TextValidator } from "react-material-ui-form-validator";
import moment from "moment";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(2, 3),
	},
	heading: {
		margin: 0,
	},
	formGroup: {
		margin: theme.spacing(0),
		padding: theme.spacing(1, 0),
	},
}));

const SiteForm = ({ values, onChange, disableSite, disableDate }) => {
	const classes = useStyles();
	const siteList = useSelector(
		({ sites }) => sites.pagination?.results || []
	);

	const disabled = values.save.saving;

	const siteOptions =
		siteList &&
		siteList
			.filter((site) => {
				return site.status && site.status.toLowerCase() !== "active"
					? false
					: true;
			})
			.map((site) => ({
				label: site.siteName,
				value: site.id,
			}))
			.sort((a, b) => (a.label > b.label ? 1 : -1));

	const onSelectOption = useCallback(
		(key) => (value) => {
			onChange({
				...values,
				[key]: value,
			});
		},
		[values, onChange]
	);

	const onSelectDate = useCallback(
		(key) => (value) => {
			onChange({
				...values,
				[key]: moment(value).format(),
			});
		},
		[values, onChange]
	);

	const onInputChange = useCallback(
		(key) =>
			({ target: { value } }) => {
				onChange({
					...values,
					[key]: value,
				});
			},
		[values, onChange]
	);

	return (
		<Card className={classes.root}>
			<div className={classes.formGroup}>
				<DatePicker
					data-cy="date"
					disabled={disabled || disableDate}
					format="Do MMMM YYYY"
					fullWidth
					inputVariant="outlined"
					label="Select Date"
					value={values.createdAt}
					variant="outlined"
					disableFuture={true}
					onChange={onSelectDate("createdAt")}
				/>
			</div>
			<div className={classes.formGroup}>
				<TextValidator
					id="title"
					data-cy="title"
					disabled={disabled}
					fullWidth
					label="Title *"
					value={values.title || ""}
					variant="outlined"
					validators={["required"]}
					errorMessages={["This field is required"]}
					onChange={onInputChange("title")}
				/>
			</div>
			<div className={classes.formGroup}>
				{/* <SelectInput
          isSite
          id="site"
          disabled={disableSite}
          data-cy="site"
          label="Select Site"
          options={siteOptions || []}
          value={values.siteId || ''}
          onChange={onSelectOption('siteId')}
        /> */}
				<SearchDropdown
					isSite
					id="site"
					disabled={disabled || disableSite}
					data-cy="site"
					label="Select Site"
					defaultOptions={siteOptions || []}
					value={values.siteId !== "NO-ID" ? values.siteId || "" : ""}
					onChange={onSelectOption("siteId")}
				/>
			</div>
		</Card>
	);
};

SiteForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	disableSite: PropTypes.bool,
	disableDate: PropTypes.bool,
};

export default SiteForm;
