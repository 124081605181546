import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { validatePeopleWithoutRoles, validateAndScrollTo } from "utils";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Dialog,
} from "frame/components";
import { useSelector } from "react-redux";

import {
	strip,
	DateForm,
	TrainingForm,
	PeopleForm,
	UploadFormCard,
	EmailRecipientsForm,
} from "features/forms";

import ReadOnlyForm from "./read-only-form";

const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1,
		flexDirection: "row",
		padding: theme.spacing(3, 3, 10, 3),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
	menu: {
		padding: theme.spacing(3, 0, 0, 0),
		"& > *": {
			marginRight: theme.spacing(3),
		},
	},
	guidelines: {
		margin: theme.spacing(0, 3),
	},
}));

const hydrate = ({
	id,
	createdAt,
	people,
	otherPeople,
	attachments,
	recipients,
	recipientsOther,
	organisationId,
	...training
}) => ({
	id,
	createdAt,
	training,
	people,
	otherPeople,
	attachments,
	recipients,
	recipientsOther,
	currentOrganisation: organisationId,
});

/*
  edit staff training is only available to born super user account only
  see src\features\health-and-safety\components\cards\staff-training-card.js
*/
const Form = ({ id, values, onClose, onChange, onReset, onSave, readOnly }) => {
	const classes = useStyles();
	const peopleBlockRef = useRef();
	const topicBlockRef = useRef();

	const [invalidPeople, setInvalidPeople] = useState(false);
	const [invalidTopic, setInvalidTopic] = useState(false);

	const [loaded, setLoaded] = useState(false);
	const [trainingTopic, setTrainingTopic] = useState(null);
	const open = values && values.id;

	const staffTrainingList = useSelector(
		({ healthSafety }) => healthSafety.staffTrainings?.results
	);
	const staffTrainingTopicList = useSelector(
		({ healthSafety }) => healthSafety.staffTrainingTopics?.results
	);

	const training = values.training || {};

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchTrainingRecords() {
			setLoaded(true);
			let trainingRecord =
				staffTrainingList &&
				staffTrainingList.filter((s) => s.id === id)[0];

			if (trainingRecord) {
				onChange(hydrate(trainingRecord));
			}
		}

		if (id) {
			setLoaded(true);
			fetchTrainingRecords();
		} // eslint-disable-next-line
	}, [id, loaded, onChange]);

	useEffect(() => {
		async function fetchTrainingTopic() {
			setLoaded(true);
			const topic =
				staffTrainingTopicList &&
				staffTrainingTopicList.filter(
					(topic) => topic.id === values.staffTrainingTopicId
				)[0];

			if (topic) {
				setTrainingTopic(topic);
			}
		}
		if (loaded && values.staffTrainingTopicId) {
			fetchTrainingTopic();
		} // eslint-disable-next-line
	}, [setTrainingTopic]);

	useEffect(() => {
		validTopicRule && setInvalidTopic(false); // eslint-disable-next-line
	}, [training.staffTrainingTopicId]);

	useEffect(() => {
		values.save.success && onClose();
	}, [values.save.success, onClose]);

	const onSubmit = (event) => {
		event.preventDefault();
		if (
			validatePeopleWithoutRoles(
				values.people,
				peopleBlockRef,
				setInvalidPeople
			) &&
			validateAndScrollTo(validTopicRule, topicBlockRef, setInvalidTopic)
		) {
			onSave(strip(values));
		}
	};

	const onCancel = () => {
		onReset();
		onClose();
	};

	if (!open) {
		return null;
	}

	const title = readOnly
		? `View Staff Training: ${trainingTopic ? trainingTopic.name : ""}`
		: `Edit Staff Training: ${trainingTopic ? trainingTopic.name : ""}`;

	const isValid = (value) => value && value !== "";
	const validTopicRule = isValid(training?.staffTrainingTopicId);

	return (
		<Dialog
			open={open ? true : false}
			aria-labelledby="Staff Trainings"
			// breadcrumbs="Staff Trainings"
			title={title}
			onClose={onCancel}
		>
			<ValidatorForm autoComplete="off" noValidate onSubmit={onSubmit}>
				<div className={classes.body}>
					{readOnly ? (
						<ReadOnlyForm values={values} />
					) : (
						<>
							<DateForm
								values={values}
								onChange={onChange}
								disabled={values.save.saving}
							/>

							<div ref={peopleBlockRef} />
							<PeopleForm
								heading="Participants"
								otherHeading="Other Participants"
								roleOptions={["Trainee", "Trainer"]}
								values={values}
								onChange={onChange}
								showInvalidPeopleError={invalidPeople}
								disabled={values.save.saving}
							/>

							<div ref={topicBlockRef} />
							<TrainingForm
								editMode
								values={values}
								onChange={onChange}
								showError={invalidTopic}
							/>
							<UploadFormCard
								title={"Add Attachments"}
								values={values}
								onChange={onChange}
							/>
							<EmailRecipientsForm
								values={values}
								onChange={onChange}
							/>
						</>
					)}
				</div>
				<FormActions className={classes.actions}>
					{!readOnly && (
						<FormSubmitButton loading={values.save.saving}>
							Save
						</FormSubmitButton>
					)}
					<FormButton
						disabled={values.save.saving}
						onClick={onCancel}
					>
						{readOnly ? "Close" : "Cancel"}
					</FormButton>
				</FormActions>
			</ValidatorForm>
		</Dialog>
	);
};

Form.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
};

export default Form;
