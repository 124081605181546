import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	isConsultantUser,
	isStaffUser,
	isAccountAdminUser,
	isNonStaffUser,
	isSuperAdminUser,
} from "utils";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
	SelectInput,
	FormActions,
	FormButton,
	FormSubmitButton,
} from "frame/components";
import {
	Modal,
	Card,
	CardContent,
	Grid,
	Typography,
	Switch,
	TextField,
} from "@material-ui/core";
import { UploadForm } from "features/forms";
import { safetyWiseApi } from "utils/safetyWiseApi";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		marginTop: theme.spacing(5),
		justifyContent: "flex-end",
	},
	emptyOption: {
		display: "none",
	},
	notes: {
		marginTop: theme.spacing(5),
	},
	emailToggleArea: {
		display: "flex",
		alignItems: "left",
	},
	emailToggle: {
		marginTop: theme.spacing(-2),
		marginBottom: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	gmailSignupLink: {
		width: "100%",
		padding: theme.spacing(1, 0),
	},
	link: {
		color: theme.palette.text.link,
	},
}));

const StaffAddEdit = ({
	// on change functions
	onOrganisationRoleChanged,
	onFirstNameChanged,
	onLastNameChanged,
	onPhoneChanged,
	onEmailChanged,
	onPasswordChanged,
	onEmailToggle,
	onStatusChanged,
	onStaffTypeChanged,
	onStaffIdNumberChanged,
	onSupervisorChanged,
	onNotesChanged,
	onFileChanged,
	// actions
	onCloseClicked,
	onSaveClicked,
	// values
	staff,
	staffList,
	organisations,
	className,
	isEditModalShowing,
	isAddModalShowing,
	profile,
	saving,
	currentOrganisationGUID,
	...rest
}) => {
	const classes = useStyles();

	const [emailInvitationSwitch, setEmailInvitationSwitch] = useState(true);
	const [staffTypes, setStaffTypes] = useState([
		{
			organisationStaffTypeId: "",
			organisationId: "",
			staffType: "",
			active: 1,
			createdUTC: "",
			lastModifiedUTC: "",
			createdBy: "",
			lastModifiedBy: "",
		},
	]);
	const [staffTypeOptions, setStaffTypeOptions] = useState([""]);
	const [selectedStaffType, setSelectedStaffType] = useState("");

	useEffect(() => {
		safetyWiseApi
			.get(`${currentOrganisationGUID}/organisation-staff-type/get`)
			.then((result) => {
				const staffTypeOptionsList = [];

				for (let i = 0; i < result.data.length; i++) {
					if(staff.organisationStaffTypeId ===
						result.data[i].organisationStaffTypeId || result.data.[i].active){
							staffTypeOptionsList.push(result.data[i].staffType);
						}
				}

				// Add None option to staffTypeOptionList
				let currentStaffType = result.data[0].staffType;
				if (staff.organisationStaffTypeId) {
					for (let i = 0; i < result.data.length; i++) {
						if (
							staff.organisationStaffTypeId ===
							result.data[i].organisationStaffTypeId
						) {
							currentStaffType = result.data[i].staffType;
						}
					}
				}
				setSelectedStaffType(currentStaffType);

				setStaffTypes(result.data);
				setStaffTypeOptions(staffTypeOptionsList);
			});
	}, [staff]);

	const handleEmailToggle = () => {
		setEmailInvitationSwitch(!emailInvitationSwitch);
		onEmailToggle(!emailInvitationSwitch);
	};

	const onSubmit = () => onSaveClicked();

	if (!isEditModalShowing && !isAddModalShowing) {
		return null;
	}

	const currentStaffId = profile.staffId; // current user info
	const isCurrentUser = staff.id === currentStaffId;

	const supervisorOptions =
		staffList &&
		staffList
			.filter((node) => node.id !== profile.staffId) // prevent adding currently selected user as supervisor of itself
			.map((staff) => {
				const fullName = `${staff.firstName} ${
					staff.lastName ? staff.lastName : ""
				}`.trim();
				return {
					label: fullName,
					value: staff.id,
				};
			});

	const showEmailInvitaionToggle =
		isStaffUser(staff.organisationRole) ||
		isAccountAdminUser(staff.organisationRole);

	let roleOptions = isConsultantUser(staff.organisationRole)
		? [
				"Account Administrator",
				"Staff User",
				"Staff Non User",
				"Consultant",
		  ]
		: ["Account Administrator", "Staff User", "Staff Non User"];

	return (
		<Modal open={true} onClose={onCloseClicked}>
			<Card {...rest} className={clsx(classes.root, className)}>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<CardContent>
						<Typography align="center" gutterBottom variant="h3">
							{isAddModalShowing ? "Add" : "Edit"} Staff Member
						</Typography>

						{/* Start of outer 1 grid container */}
						<Grid
							className={classes.container}
							container
							spacing={3}
						>
							<Grid item md={6} xs={12}>
								<SelectInput
									noNone
									id="organisationRole"
									name="organisationRole"
									label="User Type *"
									options={roleOptions}
									value={staff.organisationRole}
									disabled={
										isConsultantUser(
											staff.organisationRole
										) || isCurrentUser
									}
									onChange={(value) =>
										onOrganisationRoleChanged(value)
									}
								/>
							</Grid>

							<Grid item md={6} xs={12}>
								<SelectInput
									noNone
									id="status"
									name="status"
									label="Status"
									options={["Active", "Inactive"]}
									value={staff.status}
									disabled={isCurrentUser}
									onChange={(value) => onStatusChanged(value)}
								/>
								{staff.status === "Inactive" && (
									<Typography
										className={classes.warning}
										variant="body1"
									>
										<strong>Please note</strong> that
										changing the staff status to {'"'}
										Inactive{'"'} will disable the staff.
										These can be reactivated later when
										required.
									</Typography>
								)}
							</Grid>

							<Grid item md={6} xs={12}>
								<TextValidator
									fullWidth
									label="First name *"
									name="firstName"
									value={staff.firstName}
									variant="outlined"
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={(e) =>
										onFirstNameChanged(e.target.value)
									}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextValidator
									fullWidth
									label="Last name *"
									name="lastName"
									value={staff.lastName}
									variant="outlined"
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={(e) =>
										onLastNameChanged(e.target.value)
									}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextValidator
									fullWidth
									label="Phone number"
									name="phone"
									onChange={(e) =>
										onPhoneChanged(e.target.value)
									}
									value={staff.phoneNumber}
									validators={["isNumber"]}
									errorMessages={[
										"Must be a valid phone number",
									]}
									variant="outlined"
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextValidator
									fullWidth
									variant="outlined"
									disabled={
										isConsultantUser(
											staff.organisationRole
										) || isCurrentUser
									}
									label={
										isNonStaffUser(staff.organisationRole)
											? "Email address"
											: "Email address *"
									}
									name="email"
									validators={
										isNonStaffUser(staff.organisationRole)
											? ["isEmail"]
											: ["required", "isEmail"]
									}
									errorMessages={
										isNonStaffUser(staff.organisationRole)
											? ["Must be a valid email"]
											: [
													"This field is required",
													"Must be a valid email",
											  ]
									}
									value={staff.email}
									onChange={(e) =>
										onEmailChanged(e.target.value)
									}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Staff ID Number"
									name="staffIdNumber"
									value={staff.staffIdNumber}
									variant="outlined"
									onChange={(e) =>
										onStaffIdNumberChanged(e.target.value)
									}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								{!isNonStaffUser(staff.organisationRole) && (
									<SelectInput
										id="supervisor"
										name="supervisor"
										label="Supervisor"
										options={supervisorOptions}
										value={staff.supervisor}
										onChange={(value) =>
											onSupervisorChanged(value)
										}
									/>
								)}
							</Grid>
							<Grid item md={6} xs={12}>
								<SelectInput
									noNone
									id="staffType"
									name="staffType"
									label="Staff type"
									options={staffTypeOptions}
									value={selectedStaffType}
									onChange={(value) => {
										let organisationStaffTypeId = "";

										// The below for loop finds the corresponding organisationStaffTypeId of the selected value/staff type
										for (let i = 0; i < staffTypes.length; i++) {
											if (staffTypes[i].staffType === value) {
												organisationStaffTypeId =
												staffTypes[i].organisationStaffTypeId;
												}
											}

										onStaffTypeChanged(
											{
												"organisationStaffTypeId": organisationStaffTypeId,
												"organisationStaffTypesStaffType": value
											}
										);
										setSelectedStaffType(value);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									id="notes"
									fullWidth
									label="Notes"
									multiline
									rows="5"
									value={staff.notes || ""}
									variant="outlined"
									onChange={(e) =>
										onNotesChanged(e.target.value)
									}
								/>
							</Grid>
							<Grid item />
						</Grid>
						{/* End of outer 1 grid container */}

						{/* Start of outer 2 grid container */}
						<Grid container>
							<Grid item xs={12}>
								{/* Start of inner grid container */}
								<Grid
									container
									className={classes.emailToggleContainer}
								>
									<Grid item md={6} xs={12}>
										{showEmailInvitaionToggle ? (
											<>
												<Typography variant="h5">
													Email Invitation
												</Typography>
												<div
													className={
														classes.emailToggleArea
													}
												>
													<Typography variant="body2">
														Send invitation to user
														via Email
													</Typography>
													<Switch
														disabled={
															!isAddModalShowing
														}
														className={
															classes.emailToggle
														}
														checked={
															emailInvitationSwitch
														}
														color="secondary"
														edge="start"
														name="verified"
														onChange={
															handleEmailToggle
														}
													/>
												</div>
											</>
										) : (
											<>
												<br />
												<br />
												<br />
											</>
										)}
									</Grid>
								</Grid>
								{/* End of inner grid container */}
							</Grid>

							<Grid item xs={12}>
								<Typography variant="h5">
									Attachments
								</Typography>
								<Typography variant="body2">
									Attach documents, photos and audio files.
								</Typography>
								<UploadForm
									values={staff}
									onChange={onFileChanged}
								/>
							</Grid>

							<Grid className={classes.notes} item md={6} xs={12}>
								<small>
									User Type: A non user can not login and view
									site informatin or create <br />
									timesheets. A User requires a subscription
									so may incur a cost
								</small>
							</Grid>
							<Grid item md={6} xs={12}>
								<FormActions className={classes.actions}>
									<FormSubmitButton disabled={saving}>
										Save
									</FormSubmitButton>
									<FormButton onClick={onCloseClicked}>
										Cancel
									</FormButton>
								</FormActions>
							</Grid>
						</Grid>
						{/* End of outer 2 grid container */}
					</CardContent>
				</ValidatorForm>
			</Card>
		</Modal>
	);
};

StaffAddEdit.propTypes = {
	className: PropTypes.string,
	organisations: PropTypes.array,
	staffList: PropTypes.array.isRequired,
	staff: PropTypes.object,
	profile: PropTypes.object.isRequired,

	// on change functions
	onFirstNameChanged: PropTypes.func.isRequired,
	onLastNameChanged: PropTypes.func.isRequired,
	onPhoneChanged: PropTypes.func.isRequired,
	onEmailChanged: PropTypes.func.isRequired,
	onPasswordChanged: PropTypes.func.isRequired,
	onOrganisationRoleChanged: PropTypes.func.isRequired,
	onEmailToggle: PropTypes.func.isRequired,
	onStatusChanged: PropTypes.func.isRequired,
	onStaffTypeChanged: PropTypes.func,
	onStaffIdNumberChanged: PropTypes.func.isRequired,
	onSupervisorChanged: PropTypes.func.isRequired,
	onNotesChanged: PropTypes.func.isRequired,
	onFileChanged: PropTypes.func.isRequired,

	// Actions
	onSaveClicked: PropTypes.func.isRequired,
	onCloseClicked: PropTypes.func,

	// states
	open: PropTypes.bool,
	isAddModalShowing: PropTypes.bool,
	isEditModalShowing: PropTypes.bool,
	saving: PropTypes.bool.isRequired,
	currentOrganisationGUID: PropTypes.string.isRequired,
};

export default StaffAddEdit;
