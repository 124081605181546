import { connect } from "react-redux";
import Summary from "./summary";
import { onEdit } from "../actions";
import { getStaffById } from "../utils";

const mapStateToProps = (state) => ({
	person: getStaffById(state),
	profile: state.profile,
	organisations: state.organisations.data,
	staffList: state.staff.pagination.results,
});

const mapDispatchToProps = {
	onEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
