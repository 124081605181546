import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import Upload from "./upload";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	content: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		paddingTop: theme.spacing(1),
		background: theme.palette.background.paper,
		borderRadius: "4px",
	},
}));

const UploadForm = ({ title, text, values, onChange, disabled }) => {
	const classes = useStyles();
	disabled = (values.save ? values.save.saving : false) || disabled;

	return (
		<Card className={classes.root}>
			<CardHeader
				title={title || "Add Photos"}
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent className={classes.content}>
				<Typography variant="body1">
					Attach documents, photos and audio files. <br />
				</Typography>
				<Upload
					disabled={disabled}
					values={values}
					onChange={onChange}
				/>
			</CardContent>
		</Card>
	);
};

UploadForm.propTypes = {
	title: PropTypes.string,
	values: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

export default UploadForm;
