import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError, filter } from "rxjs/operators";
import { ofType } from "redux-observable";
import * as fromOrganisations from "features/organisations";
import { authHeaders, tokenExists, getOrganisationBaseUrl } from "frame";
import fromTypes from "../actions/types";
import { onListFailed, onListSuccess } from "../actions";

export const staffListEffect = (action$, state$) =>
	action$.pipe(
		ofType(
			fromTypes.STAFF_LIST_REQUESTED,
			fromOrganisations.ORGANISATION_FETCH_SUCCESS,
			fromOrganisations.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED
		),
		filter(() => state$.value.organisations.currentOrganisation),
		filter(() => tokenExists()),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/staff/paged`,
					{
						page: 1,
						pageSize: 5000,
						orderBy: "status,createdAt",
						ascending: true,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onListSuccess(ajaxResponse.response))
					),
					catchError((error) => of(onListFailed(error)))
				)
		)
	);

export const staffPagedListEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.STAFF_PAGED_LIST_REQUESTED),
		filter(() => state$.value.organisations.currentOrganisation),
		filter(() => tokenExists()),
		filter(() => state$.value.queue.hasNetwork),
		mergeMap(({ payload }) => {
			const pagingParams = payload;
			return ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/staff/paged`,
					pagingParams,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onListSuccess(ajaxResponse.response))
					),
					catchError((error) => of(onListFailed(error)))
				);
		})
	);
