import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isNotNullOrEmpty } from "utils";

const TaskType = ({ taskTypes, id }) => {
	const taskType =
		isNotNullOrEmpty(taskTypes) &&
		taskTypes.find((s) => s.id === id);

	return <>{taskType?.taskType || "-"}</>;
};

TaskType.propTypes = {
	taskTypes: PropTypes.array.isRequired,
	id: PropTypes.string.isRequired,
};

const mapStateToProps = ({ taskTypes }) => ({
	taskTypes: taskTypes.data,
});

export default connect(mapStateToProps)(TaskType);
