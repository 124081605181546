import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { RemoveWarning, StyledIconButton, TextClip } from "frame/components";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useRemover, useRouter, isStaffUser } from "utils";
import { CUSTOMERS_PATH } from "../actions/types";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		marginBottom: theme.spacing(2),
		cursor: "pointer",
	},
	content: {
		padding: theme.spacing(2, 4),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	label: {
		padding: theme.spacing(0, 0, 0.5, 0),
		color: "#999999",
	},
	value: {
		padding: theme.spacing(0, 0, 0.5, 0),
	},
	actions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "flex-start",
	},
	grid: {
		display: "grid",
		gridTemplateColumns: "auto 100px",
		gridGap: 0,
		width: "100%",
	},
	body: {
		display: "grid",
		gridTemplateColumns: "100%",
		gridGap: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			gridTemplateColumns: "50% 50%",
		},
		[theme.breakpoints.up("lg")]: {
			gridTemplateColumns: "25% 25% 25% 25%",
		},
		"& > div": {
			padding: theme.spacing(1, 0),
		},
	},
	options: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "flex-start",
		paddingTop: theme.spacing(1),
	},
	valueInactive: {
		color: theme.palette.text.tertiary,
	},
}));

const CustomerListing = ({
	role,
	customer,
	className,
	onEdit,
	onDelete,
	onSelect,
	...rest
}) => {
	const classes = useStyles();
	const { history } = useRouter();
	const { remove, onPossiblyRemove, onCancelRemove } = useRemover();
	const { id } = customer;

	const onRowSelect = (event) => {
		if (event.target.type !== "button") {
			onSelect(id);
			history.push(`${CUSTOMERS_PATH}/${id}`); // todo: dispatch push()
		}
	};

	const archived = customer.status.toLowerCase() === "inactive";

	const rootStyle = clsx(
		classes.root,
		archived ? classes.inactive : "",
		className
	);

	const valueStyle = clsx(
		classes.value,
		archived ? classes.valueInactive : ""
	);

	return (
		<>
			<RemoveWarning
				open={remove}
				onCancel={onCancelRemove}
				onConfirm={() => onDelete(id)}
			>
				Warning, deleting the customer {'"'}
				{customer.customerName}
				{'"'} will remove all associated projects.
				<br />
				This is permanent and cannot be undone.
			</RemoveWarning>
			<Card
				{...rest}
				className={rootStyle}
				data-cy={`customer-${customer.customerName}`}
				onClick={onRowSelect}
			>
				<CardContent className={classes.content}>
					<div className={classes.grid}>
						<div className={classes.body}>
							<div>
								<Typography
									className={classes.label}
									variant="body2"
								>
									Customer Name
								</Typography>
								<Typography className={valueStyle} variant="h5">
									<TextClip
										hideSeeMore
										text={customer.customerName}
										numberOfCharacters={60}
									/>
								</Typography>
							</div>

							<div>
								<Typography
									className={classes.label}
									variant="body2"
								>
									Contact Person
								</Typography>
								{customer.contactPerson && (
									<Typography
										className={valueStyle}
										variant="h5"
									>
										<TextClip
											hideSeeMore
											text={customer.contactPerson}
											numberOfCharacters={60}
										/>
									</Typography>
								)}
								{customer.emailAddress && (
									<Typography
										className={valueStyle}
										variant="h5"
									>
										<TextClip
											hideSeeMore
											text={customer.emailAddress}
											numberOfCharacters={60}
										/>
									</Typography>
								)}
								{!customer.contactPerson &&
									!customer.emailAddress &&
									"-"}
							</div>

							<div>
								<Typography
									className={classes.label}
									variant="body2"
								>
									Status
								</Typography>
								<Typography className={valueStyle} variant="h5">
									{customer.status || "-"}
								</Typography>
							</div>
						</div>

						<div className={classes.options}>
							{!isStaffUser(role) && (
								<>
									<StyledIconButton
										data-cy={`edit-btn-${customer.customerName}`}
										onClick={() => onEdit(id)}
									>
										<EditIcon />
									</StyledIconButton>
									<StyledIconButton
										data-cy={`delete-btn-${customer.customerName}`}
										onClick={onPossiblyRemove}
									>
										<DeleteIcon />
									</StyledIconButton>
								</>
							)}
						</div>
					</div>
				</CardContent>
			</Card>
		</>
	);
};

CustomerListing.propTypes = {
	role: PropTypes.string.isRequired,
	className: PropTypes.string,
	customer: PropTypes.object.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default CustomerListing;
