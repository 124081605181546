import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isDefaultGuid, isNotNullOrEmpty } from "utils";

/*
  summary: simple generic component that uses fetch() for lookup by id effect on load
  returns: attribute is set to name by default, specify attribute for other attributes
*/
const GenericLookup = ({
	id,
	resource,
	attribute = "name",
	isHealthSafety,
}) => {
	const resourceList = useSelector((state) =>
		isHealthSafety
			? state.healthSafety[resource].results
			: state[resource].pagination
				? state[resource].pagination?.results
				: state[resource].data
	);

	if ((id && isDefaultGuid(id)) || !resource || !id || !resourceList) {
		return "-";
	}

	const data =
		id &&
		isNotNullOrEmpty(resourceList) &&
		resourceList.filter((r) => r.id === id)[0];

	return <>{data ? (data[attribute] || "-") : "-"}</>;
};

GenericLookup.propTypes = {
	id: PropTypes.string.isRequired,
	resource: PropTypes.string.isRequired,
	attribute: PropTypes.string,
	isHealthSafety: PropTypes.bool,
};

export default GenericLookup;
