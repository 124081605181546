export const clean = (nodes, keys = ["index"]) => {
	const keysToStrip = Array.isArray(keys) ? keys : [keys];

	const result = {};
	Object.keys(nodes).forEach((key) => {
		const node = nodes[key];

		if (!node || keysToStrip.includes(key)) {
			return;
		}

		if (Array.isArray(node)) {
			const cleaned = node.map((n) => clean(n)).filter((x) => x);
			if (cleaned.length) {
				result[key] = cleaned;
			}
		} else if (node === Object(node)) {
			if (Object.keys(node).length) {
				result[key] = clean(node);
			}
		} else {
			result[key] = node;
		}
	});

	return Object.keys(result).length ? result : null;
};

export const strip = (nodes, keys = ["index", "locked", "save", "fetch"]) => {
	const keysToStrip = Array.isArray(keys) ? keys : [keys];

	if (Array.isArray(nodes)) {
		return nodes.map((n) => strip(n)).filter((x) => x);
	} else if (nodes === Object(nodes)) {
		const result = {};
		Object.keys(nodes).forEach((key) => {
			const node = nodes[key];

			if (keysToStrip.includes(key)) {
				return null;
			}

			if (Array.isArray(node) && node.length) {
				result[key] = node.map((n) => strip(n)).filter((x) => x);
			} else if (node === Object(node) && Object.keys(node).length) {
				result[key] = strip(node);
			} else {
				result[key] = node;
			}
		});

		return Object.keys(result).length ? result : null;
	} else if (nodes) {
		return nodes;
	}

	return null;
};
