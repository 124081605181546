import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	colors,
	Modal,
	Card,
	CardContent,
	CardActions,
	Typography,
	Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	body: {
		padding: theme.spacing(2, 0),
	},
	actions: {
		marginBottom: theme.spacing(2),
	},
	cancel: {
		padding: theme.spacing(0.6, 3),
		background: colors.grey[300],
		borderColor: colors.grey[300],
		"&:hover": {
			background: colors.grey[400],
			borderColor: colors.grey[400],
		},
	},
	button: {
		padding: theme.spacing(0.6, 3),
		color: theme.palette.white,
		background: theme.palette.error.main,
		borderColor: theme.palette.error.main,
		"&:hover": {
			background: theme.palette.error.dark,
			borderColor: theme.palette.error.dark,
		},
	},
}));

const SubscriptionModal = ({ className, open, title, setOpen, onDelete }) => {
	const classes = useStyles();

	const onContinue = () => {
		onDelete();
		setOpen(false);
	};

	return (
		<Modal open={open} onClose={onContinue}>
			<Card className={clsx(classes.root, className)}>
				<CardContent>
					<Typography variant="h2">
						{title || "Remove Credit Card"}
					</Typography>

					<Typography className={classes.body} variant="body1">
						{/* Please email <a href="mailto:support@safety.wise">support@safety.wise</a> to cancel your subscription */}
						Please confirm to remove credit card.
					</Typography>
				</CardContent>

				<CardActions className={classes.actions}>
					<Button
						data-cy={`delete-btn`}
						className={classes.button}
						variant="contained"
						onClick={onContinue}
					>
						Yes, Remove
					</Button>
					<Button
						data-cy={`cancel-btn`}
						className={classes.cancel}
						variant="contained"
						onClick={() => setOpen(false)}
					>
						No, Cancel
					</Button>
				</CardActions>
			</Card>
		</Modal>
	);
};

SubscriptionModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	title: PropTypes.string,
	setOpen: PropTypes.func,
	onDelete: PropTypes.func,
};

export default SubscriptionModal;
