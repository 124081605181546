import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isNotNullOrEmpty } from "utils";

const StaffName = ({ staffList, id }) => {
	const matchStaff =
		isNotNullOrEmpty(staffList) &&
		staffList.filter((s) => s.id === id)[0];

	let staff = null;

	if (matchStaff) {
		staff = matchStaff.fullName;
	}

	return <>{staff}</>;
};

StaffName.propTypes = {
	staffList: PropTypes.array.isRequired,
	id: PropTypes.string.isRequired,
};

const mapStateToProps = ({ staff }) => ({
	staffList: staff.pagination.results,
});

export default connect(mapStateToProps)(StaffName);
