import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Grid, Chip } from "@material-ui/core";
import { CrudOptions } from "frame/components";
import clip from "text-clipper";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
		"&:hover": {
			cursor: "pointer",
		},
	},
	container: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(3),
		},
	},
	label: { color: "#999999" },
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
}));

const DocumentPackListItem = ({
	documentPack,
	onView,
	onEdit,
	onArchive,
	onUnarchive,
	onDelete,
	onEmail,
	showDeleteOption,
}) => {
	const classes = useStyles();

	return (
		<Card className={classes.root} onClick={onView}>
			<CardContent className={classes.content}>
				<Grid
					container
					spacing={2}
					className={clsx(classes.container, classes.clickable)}
				>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Name
						</Typography>
						<Typography variant="h5">
							{clip(documentPack.name, 140)}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Notes
						</Typography>
						<Typography variant="h5">
							{clip(documentPack.notes, 140)}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Status
						</Typography>
						<Typography variant="h5">
							<Chip
								className={
									!documentPack.isArchived
										? classes.success
										: classes.error
								}
								size="small"
								label={
									documentPack.isArchived
										? "Archived"
										: "Active"
								}
							/>
						</Typography>
					</Grid>
				</Grid>
				<CrudOptions
					onEdit={onEdit}
					onDelete={
						showDeleteOption && documentPack.isArchived
							? onDelete
							: null
					}
					onArchive={!documentPack.isArchived ? onArchive : null}
					onMakeActive={documentPack.isArchived ? onUnarchive : null}
					onEmail={onEmail}
				/>
			</CardContent>
		</Card>
	);
};

export default DocumentPackListItem;
