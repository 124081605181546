import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	table: {
		minWidth: 650,
	},
	inactive: {
		color: theme.palette.error.main,
	},
	active: {
		color: theme.palette.success.main,
	},
}));

const HazardSiteTable = ({ siteList, hazardId }) => {
	const classes = useStyles();

	const tableConfig =
		siteList &&
		siteList.map(({ siteName, hazards }) => {
			let hazard =
				hazards && hazards.filter((h) => h.hazardId === hazardId)[0];
			return {
				siteName,
				location: hazard ? hazard.location : "",
				status: hazard ? hazard.status : "",
			};
		});

	return (
		<TableContainer component={Paper}>
			<Table
				className={classes.table}
				aria-label="simple table"
				size="small"
			>
				<TableHead>
					<TableRow>
						<TableCell>Site</TableCell>
						<TableCell>Location</TableCell>
						<TableCell>Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{tableConfig &&
						tableConfig.map(
							({ siteName, location, status }, index) => (
								<TableRow key={`${siteName}-${index}`}>
									<TableCell>{siteName}</TableCell>
									<TableCell>{location}</TableCell>
									<TableCell
										className={
											status === "Active"
												? classes.active
												: classes.inactive
										}
									>
										{status || "-"}
									</TableCell>
								</TableRow>
							)
						)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

HazardSiteTable.propTypes = {
	siteList: PropTypes.array.isRequired,
	hazardId: PropTypes.string.isRequired,
};

export default HazardSiteTable;
