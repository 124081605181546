import { connect } from "react-redux";
import JobSafetyAnalysisList from "./job-safety-analysis-list";
import {
	onRemove,
	onJobSafetyAnalysisSearchChanged,
	onJobSafetyAnalysisPagedListRequest,
} from "../../actions";

const mapStateToProps = ({
	healthSafety,
	profile,
	addJobSafetyAnalysisFormReducer,
	organisations,
}) => ({
	jobSafetyAnalysis: healthSafety.jobSafetyAnalysis,
	role: profile.role,
	searchFilter: healthSafety.searchFilter,
	saveState: addJobSafetyAnalysisFormReducer.save,
	currentOrganisationGUID: organisations.currentOrganisation,
});

const mapDispatchToProps = {
	onDelete: onRemove,
	onSearchChanged: onJobSafetyAnalysisSearchChanged,
	onGetJSAList: onJobSafetyAnalysisPagedListRequest,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(JobSafetyAnalysisList);
