import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { isSuperAdminUser, isConsultantUser } from "utils";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
	Feedback,
	FormActions,
	FormSubmitButton,
	ScrollToTopOnMount,
} from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 3, 10, 3),
		backgroundColor: theme.palette.white,
		[theme.breakpoints.down("xs")]: {
			paddingRight: theme.spacing(5),
		},
	},
	body: {
		margin: theme.spacing(3, 0, 2, 0),
		maxWidth: 640,
		"& > *": {
			paddingLeft: "0 !important",
			paddingRight: "0 !important",
		},
	},
	pad: {
		paddingTop: theme.spacing(2),
	},
	text: {
		margin: theme.spacing(1, 0),
	},
}));

const Invite = ({ save, onInvite, role, consultantId }) => {
	const classes = useStyles();
	const [value, setValue] = useState("");
	const [copySuccess, setCopySuccess] = useState(false);

	const { done, error, saving } = save;
	const saveFailed = !!(done && error);
	const saveSuccess = !!(done && !error);

	useEffect(() => {
		if (done && !error) {
			setValue("");
		}
	}, [done, error]);

	const onSubmit = () => {
		onInvite(value);
	};

	const onChange = (event) => setValue(event.target.value);

	const inviteLink = `${window.location.origin}/register?consultantId=${consultantId}`;
	const copyToClipboard = () => {
		navigator.clipboard.writeText(inviteLink);
		setCopySuccess(true);
		setTimeout(() => {
			setCopySuccess(false);
		}, 1000);
	};

	return (
		<div className={classes.root}>
			<ScrollToTopOnMount />
			{(isConsultantUser(role) || isSuperAdminUser(role)) && (
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Typography variant="h2">Invite</Typography>

					<Grid className={classes.body} container spacing={2}>
						<Grid item xs={12}>
							<Feedback color="error" active={saveFailed}>
								Invitation failed to send
							</Feedback>
							<Feedback color="success" active={saveSuccess}>
								Invitation successfully sent
							</Feedback>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h4">
								Enter email address to send invite
							</Typography>
						</Grid>

						<Grid item xs={12}>
							<TextValidator
								id={"email"}
								disabled={saving}
								fullWidth
								label="Email"
								value={value}
								variant="outlined"
								validators={["required", "isEmail"]}
								errorMessages={[
									"This field is required",
									"Email is not valid",
								]}
								onChange={onChange}
							/>
						</Grid>

						<Grid item xs={12}>
							<FormActions>
								<FormSubmitButton loading={saving}>
									Send Invite
								</FormSubmitButton>
							</FormActions>
						</Grid>

						{isSuperAdminUser(role) && (
							<Grid item xs={12}>
								<Typography
									className={classes.pad}
									variant="h4"
								>
									Invite perma link
								</Typography>
								<Typography
									className={classes.text}
									variant="body1"
								>
									{inviteLink}
								</Typography>
								<Button
									color="primary"
									className={classes.button}
									size="small"
									variant="outlined"
									onClick={() => copyToClipboard()}
								>
									{copySuccess ? "Copied" : "Copy"}
								</Button>
							</Grid>
						)}
					</Grid>
				</ValidatorForm>
			)}
		</div>
	);
};

Invite.propTypes = {
	save: PropTypes.exact({
		done: PropTypes.bool,
		error: PropTypes.string,
		saving: PropTypes.bool,
		success: PropTypes.bool,
	}),
	onInvite: PropTypes.func,
	role: PropTypes.string.isRequired,
	consultantId: PropTypes.string.isRequired,
};

export default Invite;
