import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2, 0, 4, 0),
	},
	title: {
		fontSize: "24px",
		letterSpacing: "0.06px",
		fontWeight: "bold",
	},
}));

const FeatureHeader = ({
	className,
	breadcrumbs,
	title,
	action,
	actionColor = "primary",
	onAction,
	...rest
}) => {
	const classes = useStyles();

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Grid
				alignItems="flex-end"
				container
				justify="space-between"
				spacing={3}
			>
				<Grid item>
					<Typography component="h2" gutterBottom variant="overline">
						{breadcrumbs}
					</Typography>
					<Typography
						component="h1"
						variant="h3"
						className={clsx(classes.title, "cypress-title")}
					>
						{title}
					</Typography>
				</Grid>
				<Grid item>
					{action && onAction && (
						<Button
							color={actionColor}
							variant="contained"
							onClick={onAction}
						>
							{action}
						</Button>
					)}
				</Grid>
			</Grid>
		</div>
	);
};

FeatureHeader.propTypes = {
	className: PropTypes.string,
	breadcrumbs: PropTypes.string,
	title: PropTypes.string.isRequired,
	action: PropTypes.string,
	actionColor: PropTypes.string,
	onAction: PropTypes.func,
};

export default FeatureHeader;
