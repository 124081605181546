import { connect } from "react-redux";
import JobSiteList from "./jobs-site-list";
import { onEdit, onAdd, onDelete, onPagedListRequested } from "../actions";

const mapStateToProps = ({ jobs, profile, queue }) => ({
	isAddModalShowing: jobs.isAddModalShowing,
	currentUserId: profile.id,
	role: profile.role,
	hasNetwork: queue.hasNetwork,
	pagination: jobs.pagination,
	flags: jobs.flags
});

const mapDispatchToProps = {
	onAdd,
	onEdit,
	onDelete,
	onGetJobList: onPagedListRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobSiteList);
