import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { Card, CardHeader } from "@material-ui/core";

import HazardsForm from "./form";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	empty: {
		padding: theme.spacing(2),
		textAlign: "center",
	},
	button: {
		margin: theme.spacing(2, 0, 0, 0),
	},
	headerTitle: {
		padding: theme.spacing(1.6, 1),
		minWidth: 140,
	},
	headerStatus: {
		padding: theme.spacing(1.6, 1),
	},
	headerOptions: {
		padding: `0 !important`,
		width: 30,
	},
	review: {
		marginBottom: theme.spacing(5),
	},
	fieldLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(-1.2),
	},
	label: {
		margin: 0,
	},
}));

const HazardsFormCard = ({ values, onChange }) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Hazards"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<HazardsForm values={values} onChange={onChange} />
		</Card>
	);
};

HazardsFormCard.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default HazardsFormCard;
