import { ofType } from "redux-observable";
import { map, filter } from "rxjs/operators";
import { push } from "connected-react-router";

// Fixes bug when isFirstRendering is true causing the app to not logout
const notLoggingOutEFfect = (action$, state$) =>
	action$.pipe(
		ofType("@@router/LOCATION_CHANGE"),
		filter((action) => {
			if (
				action.payload.isFirstRendering &&
				action.payload.location.pathname === "/login"
			) {
				return true;
			}

			return false;
		}),
		map((action) =>
			push(
				action.payload.location.pathname +
					action.payload.location.search
			)
		)
	);

export default notLoggingOutEFfect;
