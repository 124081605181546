export const timesheetActionTypes = {
	TIMESHEETS_PAGED_LIST_REQUESTED: "TIMESHEETS_PAGED_LIST_REQUESTED",
	TIMESHEETS_LIST_RECEIVED: "TIMESHEETS_LIST_RECEIVED",
	TIMESHEETS_LIST_FAILED: "TIMESHEETS_LIST_FAILED",
	ACTIVE_TIMESHEET_REQUESTED: "ACTIVE_TIMESHEET_REQUESTED",
	ACTIVE_TIMESHEET_RECEIVED: "ACTIVE_TIMESHEET_RECEIVED",
	ACTIVE_TIMESHEET_FAILED: "ACTIVE_TIMESHEET_FAILED",
	TIMESHEETS_SEARCH_CLEARED: "TIMESHEETS_SEARCH_CLEARED",
	ADD_TIMESHEET_FORM_VALUE_CHANGE: "ADD_TIMESHEET_FORM_VALUE_CHANGE",
	ADD_TIMESHEET_FORM_RESET: "ADD_TIMESHEET_FORM_RESET",
	ADD_TIMESHEET_FORM_SUBMIT: "ADD_TIMESHEET_FORM_SUBMIT",
	ADD_TIMESHEET_FORM_SUBMIT_FAILED: "ADD_TIMESHEET_FORM_SUBMIT_FAILED",
	ADD_TIMESHEET_FORM_SUBMIT_SUCCESS: "ADD_TIMESHEET_FORM_SUCCESS",
	ADD_TIMESHEET_FORM_START_TIMER: "ADD_TIMESHEET_FORM_START_TIMER",
	ADD_TIMESHEET_FORM_START_SUCCESS: "ADD_TIMESHEET_FORM_START_SUCCESS",
	ADD_TIMESHEET_FORM_START_FAILED: "ADD_TIMESHEET_FORM_START_FAILED",
	ADD_TIMESHEET_FORM_STOP_TIMER: "ADD_TIMESHEET_FORM_STOP_TIMER",
	ADD_TIMESHEET_FORM_CONFIRM: "ADD_TIMESHEET_FORM_CONFIRM",
	ADD_TIMESHEET_FORM_CONFIRM_SUCCESS: "ADD_TIMESHEET_FORM_CONFIRM_SUCCESS",
	ADD_TIMESHEET_FORM_CONFIRM_FAILED: "ADD_TIMESHEET_FORM_CONFIRM_FAILED",
	ADD_TIMESHEET_FORM_DISCARD: "ADD_TIMESHEET_FORM_DISCARD",
	ADD_TIMESHEET_FORM_DISCARD_SUCCESS: "ADD_TIMESHEET_FORM_DISCARD_SUCCESS",
	ADD_TIMESHEET_FORM_DISCARD_FAILED: "ADD_TIMESHEET_FORM_DISCARD_FAILED",
	UPDATE_TIMESHEET_FORM_SUBMIT: "UPDATE_TIMESHEET_FORM_SUBMIT",
	TIMESHEET_REMOVE_REQUESTED: "TIMESHEET_REMOVE_REQUESTED",
	TIMESHEET_REMOVE_SUCCEEDED: "TIMESHEET_REMOVE_SUCCEEDED",
	TIMESHEET_REMOVE_FAILED: "TIMESHEET_REMOVE_FAILED",
	REFRESH_TIMESHEET_LIST: "REFRESH_TIMESHEET_LIST"
};

export const onTimesheetListReceive = (payload) => ({
	type: timesheetActionTypes.TIMESHEETS_LIST_RECEIVED,
	payload,
});

export const onActiveTimesheetRequest = (payload) => ({
	type: timesheetActionTypes.ACTIVE_TIMESHEET_REQUESTED,
	payload,
});

export const onActiveTimesheetReceive = (payload) => ({
	type: timesheetActionTypes.ACTIVE_TIMESHEET_RECEIVED,
	payload,
});

export const onActiveTimesheetFailed = (payload) => ({
	type: timesheetActionTypes.ACTIVE_TIMESHEET_FAILED,
	payload,
});

export const onTimesheetListFailed = (payload) => ({
	type: timesheetActionTypes.TIMESHEETS_LIST_FAILED,
	payload,
});

export const onTimesheetSearchCleared = (payload) => ({
	type: timesheetActionTypes.TIMESHEETS_SEARCH_CLEARED,
	payload,
});

export const onAddTimesheetValueChange = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_VALUE_CHANGE,
	payload,
});

export const onAddTimesheetReset = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_RESET,
	payload,
});

export const onAddTimesheetSave = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_SUBMIT,
	payload,
});

export const onAddTimesheetSaveFailed = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddTimesheetSaveSuccess = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_SUBMIT_SUCCESS,
	payload,
});

export const onAddTimesheetStartTimer = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_START_TIMER,
	payload,
});

export const onAddTimesheetStartSuccess = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_START_SUCCESS,
	payload,
});

export const onAddTimesheetStartFailed = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_START_FAILED,
	payload,
});

export const onAddTimesheetStopTimer = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_STOP_TIMER,
	payload,
});

export const onConfirmActiveTimesheet = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_CONFIRM,
	payload,
});

export const onConfirmActiveTimesheetSuccess = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_CONFIRM_SUCCESS,
	payload,
});

export const onConfirmActiveTimesheetFailed = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_CONFIRM_FAILED,
	payload,
});

export const onDiscardActiveTimesheet = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_DISCARD,
	payload,
});

export const onDiscardActiveTimesheetSuccess = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_DISCARD_SUCCESS,
	payload,
});

export const onDiscardActiveTimesheetFailed = (payload) => ({
	type: timesheetActionTypes.ADD_TIMESHEET_FORM_DISCARD_FAILED,
	payload,
});

export const onUpdateTimesheetSave = (payload) => ({
	type: timesheetActionTypes.UPDATE_TIMESHEET_FORM_SUBMIT,
	payload,
});

export const onTimesheetRemoveFail = (payload) => ({
	type: timesheetActionTypes.TIMESHEET_REMOVE_FAILED,
	payload,
});

export const refreshTimesheetList = (payload) => ({
	type: timesheetActionTypes.REFRESH_TIMESHEET_LIST,
	payload,
});
