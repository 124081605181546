import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";

import { RadioListInput } from "frame/components/input";
import UploadFile from "../upload-form/upload-file";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(1, 0, 3, 0),
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0),
		height: 2,
	},
}));

const NotifiableWorksForm = ({
	className,
	disabled,
	values,
	onSelectChange,
	onFileChange,
}) => {
	const classes = useStyles();

	const options = [
		{
			label: "Yes",
			value: "yes",
		},
		{
			label: "NA",
			value: "na",
		},
	];

	return (
		<CardContent className={classes.root}>
			<Divider className={classes.divider} />
			<CardHeader
				className={classes.heading}
				title="Notifiable Works"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						We have Notifiable works associated with our other
						person.
					</Typography>
					<RadioListInput
						data-cy={"haveNotifiableWorks"}
						disabled={disabled}
						initialValue={values.haveNotifiableWorks}
						options={options}
						onChange={onSelectChange("haveNotifiableWorks")}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						Worksafe NZ has been advised of our Notifiable Works.
					</Typography>
					<RadioListInput
						data-cy={"haveAdvisedWorksafeNZ"}
						disabled={disabled}
						initialValue={values.haveAdvisedWorksafeNZ}
						options={options}
						onChange={onSelectChange("haveAdvisedWorksafeNZ")}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						Notification attached.
					</Typography>
					<RadioListInput
						data-cy={"notificationAttached"}
						disabled={disabled}
						initialValue={values.notificationAttached}
						options={options}
						onChange={onSelectChange("notificationAttached")}
					/>
					<UploadFile
						disabled={
							disabled || values.notificationAttached !== "yes"
						}
						onFileChange={onFileChange}
						values={values}
						reference="notifiableWorksAttachments"
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

NotifiableWorksForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onSelectChange: PropTypes.func.isRequired,
	onFileChange: PropTypes.func.isRequired,
};

export default NotifiableWorksForm;
