import { connect } from "react-redux";
import Subscription from "./subscription";
import {
	subscriptionFetch,
	onAddPayment,
	onAddPaymentClose,
	onCancelSubscription,
} from "../../actions";

const mapStateToProps = ({ subscription, profile, staff, organisations }) => ({
	subscription: subscription,
	role: profile.role,
	staffList: staff.pagination.results,
	currentOrganisationGUID: organisations.currentOrganisation,
});

const mapDispatchToProps = {
	onFetchSubscription: subscriptionFetch,
	onOpenAddPaymentModal: onAddPayment,
	onCloseAddPaymentModal: onAddPaymentClose,
	onCancelSubscription: onCancelSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
