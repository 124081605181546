import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { getBaseUrl } from "frame";
import fromTypes from "../actions/types";

import { onSendResetEmailFulfilled, onSendResetEmailFailed } from "../actions";

const sendResetEmailEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.AUTHORISATION_SEND_RESET_EMAIL),
		mergeMap(({ payload }) =>
			ajax.get(`${getBaseUrl()}/api/reset?email=${encodeURIComponent(payload)}`).pipe(
				mergeMap((ajaxResponse) =>
					of(onSendResetEmailFulfilled(ajaxResponse.response))
				),
				catchError((error) => onSendResetEmailFailed(error))
			)
		)
	);

export default sendResetEmailEffect;
