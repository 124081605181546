import { connect } from "react-redux";
import StaffRoutes from "./staff-routes";
import { onList, onSearchClear } from "../actions";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	onGetStaffList: onList,
	onSearchClear: onSearchClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffRoutes);
