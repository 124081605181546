import React, { useRef, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
	colors,
	Hidden,
	IconButton,
	Toolbar,
	Badge,
	Typography,
} from "@material-ui/core";
import { useRouter } from "utils";
import { ACCOUNT_PATH } from "features/account";
import Brand from "../brand";
import AccountPopover from "./account-popover";
import { Geolocation } from "features/geolocation";
import AddToHomescreen from "react-add-to-homescreen";
import SafetyWiseLogo from "../../../images/logo.png";
import { HOME_ROUTE } from "features/home/home-routes";
import PWAPrompt from "react-ios-pwa-prompt";
import { isNotNullOrEmpty } from "utils";
import { StyledModal } from "frame/components";
import { ADD_TIMESHEET_ROUTE } from "features/timesheet/timesheet-routes";

// icons
import AccountIcon from "@material-ui/icons/AccountCircle";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import MenuIcon from "@material-ui/icons/Menu";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import InfoIcon from "@material-ui/icons/Info";
import Chip from "@material-ui/core/Chip";
import { clearImpersonationToken } from "frame/access-token";
import { isInImpersonationMode } from "frame/access-token";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.white,
		color: colors.blueGrey[800],
		boxShadow: `0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)`,
	},
	inner: {
		flex: 1,
		background: theme.palette.dark,
	},
	brand: {
		position: "relative",
		paddingRight: "5px",
		marginTop: "5px",
		marginPadding: "-10px",
		[theme.breakpoints.down("xs")]: {
			maxWidth: "150px",
		},
	},
	filler: {
		flex: 1,
	},
	user: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "spaceBetween",
		alignItems: "center",
		padding: theme.spacing(2),
		minWidth: 260,
	},
	menuIcon: {
		color: theme.palette.light,
		animation: "0.3s all ease-out",
		"&:hover": {
			color: theme.palette.light,
		},
	},
	impersonationIcon: {
		color: theme.palette.error.main,
	},
	impersonationChip: {
		"background-color": theme.palette.error.main,
		color: theme.palette.white,
	},
	userAvatar: {
		width: 40,
		height: 40,
	},
	error: {
		background: theme.palette.error.main,
	},
	success: {
		background: theme.palette.success.main,
	},
	devLabel: {
		color: "#1c2b4a",
		fontSize: 12,
		position: "absolute",
		left: 0,
		top: 33,
		[theme.breakpoints.down("xs")]: {
			fontSize: 10,
		},
	},
}));

const TopBar = ({
	profile,
	onOpenNav,
	onSignout,
	hasNetwork,
	timesheetToggle,
	requestQueue,
	profileFetch,
	onAddTimesheetReset
}) => {
	const classes = useStyles();
	const { history } = useRouter();
	const accountAnchor = useRef();

	const [menu, setMenu] = useState();
	const [loaded, setLoaded] = useState(false);
	const [openWarningPendingQueueModal, setOpenWarningPendingQueueModal] =
		useState(false);

	const isImpersonating = isInImpersonationMode();

	useEffect(() => {
		setLoaded(true);
	}, [setLoaded]);

	const onOpenMenu = (value) => () => setMenu(value);

	const onCloseMenu = () => setMenu();

	const isOpen = (value) => value === menu;

	const onLogout = () =>
		isNotNullOrEmpty(requestQueue)
			? setOpenWarningPendingQueueModal(true)
			: onSignout();

	const onOpenActiveTimesheet = () => {
		history.push(ADD_TIMESHEET_ROUTE);
	};

	const onAccount = () => {
		history.push(ACCOUNT_PATH); // todo: dispatch push()
		setMenu(null);
	};

	const onExitImpersonationMode = () => {
		clearImpersonationToken();
		history.push(HOME_ROUTE);
		profileFetch();
		setMenu(null);
		onAddTimesheetReset();
	};

	const handleAddToHomescreenClick = () => {
		alert(`
      1. Open Share menu
      2. Tap on "Add to Home Screen" button`);
	};

	const isUAT = window.location.href.includes("uat");
	const isDev = window.location.href.includes("dev");
	const isLocal = window.location.href.includes("local");

	const curEnv = isUAT ? "UAT" : isDev ? "Dev" : isLocal ? "Local" : null;

	return (
		<div className={classes.root}>
			<Toolbar className={classes.inner} position="static">
				<RouterLink className={classes.brand} to={HOME_ROUTE}>
					<Brand theme="colored" height={35} />
					<Typography variant="body2">
						{curEnv && (
							<span className={classes.devLabel}>
								({curEnv}) v{window.versionId}
							</span>
						)}
					</Typography>
				</RouterLink>
				<div className={classes.filler} />

				{timesheetToggle && (
					<IconButton
						color="inherit"
						onClick={onOpenActiveTimesheet}
					>
						<AccessAlarmIcon color="error" />
					</IconButton>
				)}

				<Hidden mdUp>
					<IconButton
						data-cy="open-hamburger"
						color="inherit"
						onClick={onOpenNav}
					>
						<MenuIcon />
					</IconButton>
				</Hidden>

				{isImpersonating && (
					<Hidden smDown>
						<Chip
							icon={
								<InfoIcon
									className={classes.impersonationChip}
								/>
							}
							label="Impersonating"
							className={classes.impersonationChip}
						/>
					</Hidden>
				)}

				<IconButton
					id="cypress-my-account"
					aria-label="account of current user"
					aria-controls="menu-account"
					aria-haspopup="true"
					color="inherit"
					ref={accountAnchor}
					onClick={onOpenMenu("account")}
				>
					<Badge
						color="primary"
						variant="dot"
						classes={{
							badge: hasNetwork ? classes.success : classes.error,
						}}
					>
						{isImpersonating ? (
							<PeopleAltIcon
								className={
									classes.menuIcon +
									" " +
									classes.impersonationIcon
								}
							/>
						) : (
							<AccountIcon className={classes.menuIcon} />
						)}
					</Badge>
				</IconButton>

				<AccountPopover
					anchor={accountAnchor}
					open={isOpen("account")}
					profile={profile}
					onClose={onCloseMenu}
					onLogout={onLogout}
					onAccount={onAccount}
					onExitImpersonationMode={onExitImpersonationMode}
				/>
			</Toolbar>
			<Geolocation />

			{loaded && (
				<AddToHomescreen
					onAddToHomescreenClick={handleAddToHomescreenClick}
					title="Add SafetyWise to Home screen"
					icon={SafetyWiseLogo}
				/>
			)}

			{loaded && (
				<PWAPrompt
					promptOnVisit={1}
					timesToShow={3}
					copyClosePrompt="Close"
					permanentlyHideOnDismiss={false}
				/>
			)}

			<StyledModal
				open={openWarningPendingQueueModal}
				title={`Quick pic records syncing in progress`}
				onClose={() => setOpenWarningPendingQueueModal(false)}
				onConfirm={onSignout}
				onConfirmText="Logout Anyway"
			>
				{`Please note that you have quick pic records syncing in progress and will lost if you log out. Do you wish to continue?`}
			</StyledModal>
		</div>
	);
};

TopBar.propTypes = {
	profile: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		email: PropTypes.string,
	}).isRequired,
	onOpenNav: PropTypes.func,
	onSignout: PropTypes.func,
	profileFetch: PropTypes.func,
	onAddTimesheetReset: PropTypes.func,
	hasNetwork: PropTypes.bool.isRequired,
	timesheetToggle: PropTypes.bool,
	requestQueue: PropTypes.array.isRequired,
};

export default TopBar;
