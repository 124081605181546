import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid } from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(2, 0),
	},
	divider: {
		display: "flex",
		margin: theme.spacing(2, 0),
		width: "100%",
		color: theme.palette.divider,
	},
}));

const EmailRecipientsForm = ({ disabled, values, onChange }) => {
	const classes = useStyles();
	const [personCount, setPersonCount] = useState(
		values.recipientsOther && values.recipientsOther.length > 0
			? values.recipientsOther.length
			: 1
	);

	const recipients = values.recipientsOther || [];

	const cleanResults = () => {
		const next = Array.from(new Set(values.recipientsOther)).filter(
			(x) => x
		);

		if (personCount > next.length + 1) {
			setPersonCount(next.length + 1);
		}

		onChange({
			...values,
			recipientsOther: next,
		});
	};

	useEffect(() => {
		const inputs = document.querySelectorAll(
			`input[id^='recipient_other_']`
		);

		inputs.forEach((input) => {
			input.onblur = () => {
				cleanResults();
			};
		});

		return () => {
			inputs.forEach((input) => {
				input.onblur = null;
			});
		};
	});

	const onAddPerson = () => {
		if (recipients.length === personCount) {
			setPersonCount(personCount + 1);
		}
	};

	const onInputChange = useCallback(
		(index) =>
			({ target: { value } }) => {
				const next =
					values.recipientsOther && values.recipientsOther.length > 0
						? [...values.recipientsOther]
						: [];
				next[index] = value;

				onChange({
					...values,
					recipientsOther: next,
				});
			},
		[values, onChange]
	);

	const renderPeople = () => {
		const nodes = [];

		for (let i = 0; i < personCount; i++) {
			nodes.push(
				<Grid key={`recipient_other_${i}`} item xs={12}>
					<TextValidator
						id={`recipient_other_${i}`}
						data-cy="recipient_other"
						disabled={disabled}
						fullWidth
						label="Add non-user email"
						placeholder="Email"
						value={recipients[i] || ""}
						variant="outlined"
						validators={["isEmail"]}
						errorMessages={["Must be a valid email"]}
						onChange={onInputChange(i)}
					/>
				</Grid>
			);
		}
		return nodes;
	};

	return (
		<>
			<Grid container spacing={2}>
				{renderPeople()}
			</Grid>
			<Button
				color="primary"
				className={classes.button}
				disabled={disabled}
				size="small"
				variant="outlined"
				onClick={onAddPerson}
			>
				+ Add
			</Button>
		</>
	);
};

EmailRecipientsForm.propTypes = {
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default EmailRecipientsForm;
