import fromTypes from "./types";

export const onAdd = () => ({
	type: fromTypes.SITES_ADD_CLICKED,
});

export const onAddFailed = (payload) => ({
	type: fromTypes.SITES_ADD_FAILED,
	payload,
});

export const onAddSucceeded = (payload) => ({
	type: fromTypes.SITES_ADD_SUCCEEDED,
	payload,
});

export const onAddressChanged = (payload) => ({
	type: fromTypes.SITES_ADDRESS_CHANGED,
	payload,
});

export const onCloseClicked = () => ({
	type: fromTypes.SITES_CLOSE_CLICKED,
});

export const onCustomerNameChanged = (payload) => ({
	type: fromTypes.SITES_CUSTOMER_NAME_CHANGED,
	payload,
});

export const onEdit = (payload) => ({
	type: fromTypes.SITES_EDIT_CLICKED,
	payload,
});

export const onListFailed = (payload) => ({
	type: fromTypes.SITES_LIST_FAILED,
	payload,
});

export const onListReceived = (payload) => ({
	type: fromTypes.SITES_LIST_RECEIVED,
	payload,
});

export const onListRequested = () => ({
	type: fromTypes.SITES_LIST_REQUESTED,
});

export const onPagedListRequested = (payload) => ({
	type: fromTypes.SITES_PAGED_LIST_REQUESTED,
	payload,
});

export const onPostCodeChanged = (payload) => ({
	type: fromTypes.SITES_POST_CODE_CHANGED,
	payload,
});

export const onDelete = (payload) => ({
	type: fromTypes.SITES_REMOVE_CLICKED,
	payload,
});

export const onDeleteFailed = (payload) => ({
	type: fromTypes.SITES_REMOVE_FAILED,
	payload,
});

export const onDeleteSucceeded = (payload) => ({
	type: fromTypes.SITES_REMOVE_SUCCEEDED,
	payload,
});

export const onSaveClicked = () => ({
	type: fromTypes.SITES_SAVE_CLICKED,
});

export const onSiteContactEmailChanged = (payload) => ({
	type: fromTypes.SITES_SITE_CONTACT_EMAIL_CHANGED,
	payload,
});

export const onSiteContactPersonChanged = (payload) => ({
	type: fromTypes.SITES_SITE_CONTACT_PERSON_CHANGED,
	payload,
});

export const onSiteContactPhoneChanged = (payload) => ({
	type: fromTypes.SITES_SITE_CONTACT_PHONE_CHANGED,
	payload,
});

export const onSelectSite = (payload) => ({
	type: fromTypes.SITES_DETAILS,
	payload,
});

export const onSiteNameChanged = (payload) => ({
	type: fromTypes.SITES_SITE_NAME_CHANGED,
	payload,
});

export const onSuburbChanged = (payload) => ({
	type: fromTypes.SITES_SUBURB_CHANGED,
	payload,
});

export const onTownCityChanged = (payload) => ({
	type: fromTypes.SITES_TOWN_CITY_CHANGED,
	payload,
});

export const onStatusChanged = (status) => ({
	type: fromTypes.SITES_STATUS_CHANGED,
	payload: status,
});

export const onAddHazardToSiteValueChange = (payload) => ({
	type: fromTypes.SITES_HAZARDS_VALUE_CHANGE,
	payload,
});

export const onAddHazardousSubstanceToSiteValueChange = (payload) => ({
	type: fromTypes.SITES_HAZARDOUS_SUBSTANCE_VALUE_CHANGE,
	payload,
});

export const onRemoveHazard = (payload) => ({
	type: fromTypes.SITES_HAZARD_REMOVE,
	payload,
});

export const onRemoveHazardousSubstance = (payload) => ({
	type: fromTypes.SITES_HAZARDOUS_SUBSTANCE_REMOVE,
	payload,
});

// Add Hazard to Sites Actions

export const onAddHazardToSite = () => ({
	type: fromTypes.ADD_HAZARD_TO_SITE_CLICKED,
});

export const onCloseAddHazardToSite = () => ({
	type: fromTypes.ADD_HAZARD_TO_SITE_CLOSE,
});

export const onEditHazardToSite = () => ({
	type: fromTypes.EDIT_HAZARD_TO_SITE_CLICKED,
});

export const onCloseEditHazardToSite = () => ({
	type: fromTypes.EDIT_HAZARD_TO_SITE_CLOSE,
});

export const onAddHazardToSiteSave = (payload) => ({
	type: fromTypes.ADD_HAZARD_TO_SITE_FORM_SUBMIT,
	payload,
});

export const onAddHazardToSiteSaveFailed = (payload) => ({
	type: fromTypes.ADD_HAZARD_TO_SITE_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddHazardToSiteSaveSuccess = (payload) => ({
	type: fromTypes.ADD_HAZARD_TO_SITE_FORM_SUBMIT_SUCCESS,
	payload,
});

export const onRemoveHazardFromSite = (payload) => ({
	type: fromTypes.REMOVE_HAZARD_FROM_SITE_REQUESTED,
	payload,
});

export const onRemoveHazardFromSiteSuccess = (payload) => ({
	type: fromTypes.REMOVE_HAZARD_FROM_SITE_SUCCEEDED,
	payload,
});

export const onRemoveHazardFromSiteFailed = (payload) => ({
	type: fromTypes.REMOVE_HAZARD_FROM_SITE_FAILED,
	payload,
});

// Add Hazardous Substance to Sites Actions

export const onAddHazardousSubstanceToSite = () => ({
	type: fromTypes.ADD_HAZARDOUS_SUBSTANCE_TO_SITE_CLICKED,
});

export const onCloseAddHazardousSubstanceToSite = () => ({
	type: fromTypes.ADD_HAZARDOUS_SUBSTANCE_TO_SITE_CLOSE,
});

export const onEditHazardousSubstanceToSite = () => ({
	type: fromTypes.EDIT_HAZARDOUS_SUBSTANCE_TO_SITE_CLICKED,
});

export const onCloseEditHazardousSubstanceToSite = () => ({
	type: fromTypes.EDIT_HAZARDOUS_SUBSTANCE_TO_SITE_CLOSE,
});

export const onAddHazardousSubstanceToSiteSave = (payload) => ({
	type: fromTypes.ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT,
	payload,
});

export const onAddHazardousSubstanceToSiteSaveFailed = (payload) => ({
	type: fromTypes.ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT_FAILED,
	payload,
});

export const onAddHazardousSubstanceToSiteSaveSuccess = (payload) => ({
	type: fromTypes.ADD_HAZARDOUS_SUBSTANCE_TO_SITE_FORM_SUBMIT_SUCCESS,
	payload,
});

export const onRemoveHazardousSubstanceFromSite = (payload) => ({
	type: fromTypes.REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_REQUESTED,
	payload,
});

export const onRemoveHazardousSubstanceFromSiteSuccess = (payload) => ({
	type: fromTypes.REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_SUCCEEDED,
	payload,
});

export const onRemoveHazardousSubstanceFromSiteFailed = (payload) => ({
	type: fromTypes.REMOVE_HAZARDOUS_SUBSTANCE_FROM_SITE_FAILED,
	payload,
});

export const onSearchChanged = (payload) => ({
	type: fromTypes.SITES_SEARCH_CHANGED,
	payload,
});

export const onSearchClear = () => ({
	type: fromTypes.SITES_SEARCH_CLEAR,
});

export const onLookup = (payload) => ({
	type: fromTypes.SITES_LOOKUP,
	payload,
});

export const onLookupSuccess = (payload) => ({
	type: fromTypes.SITES_LOOKUP_SUCCESS,
	payload,
});

export const onLookupFailed = (payload) => ({
	type: fromTypes.SITES_LOOKUP_FAILED,
	payload,
});

export const onHydrateSite = (payload) => ({
	type: fromTypes.HYDRATE_SITE,
	payload,
});
