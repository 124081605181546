import Types from "../actions/types";
import { ofType } from "redux-observable";
import { filter, catchError, flatMap, mergeMap } from "rxjs/operators";
import { DBService, JOBS_TABLE } from "frame/indexedDBService";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { authHeaders } from "frame/auth-headers";
import * as R from "ramda";

const db = new DBService();
const findMissingAttachments = () => async (action) => {
	const db = new DBService();
	let allKeys = await db.getAllKeys(JOBS_TABLE);

	const jobs = action.payload;
	let missingAttachments = [];

	for (let i in jobs) {
		if (jobs[i] && jobs[i].attachments && jobs[i].attachments.length > 0) {
			let attachments = jobs[i].attachments;

			for (let index in attachments) {
				let attachment = attachments[index];

				if (allKeys && !allKeys.includes(attachment.id)) {
					missingAttachments.push(attachment);
				}
			}
		}
	}

	return missingAttachments;
};

export const downloadNextAttachmentEffect = (action$) =>
	action$.pipe(
		ofType("DOWNLOAD_NEXT_ATTACHMENT"),
		filter((action) => action.payload && action.payload.length > 0),
		mergeMap((action) =>
			ajax.get(`${action.payload[0].dataURL}/json`, authHeaders()).pipe(
				mergeMap((ajaxResponse) => {
					// Replace Data URLs
					let currentAttachment = R.clone(action.payload[0]);
					currentAttachment.dataURL = ajaxResponse.response;
					db.put(JOBS_TABLE, currentAttachment);

					return of({
						type: "DOWNLOAD_NEXT_ATTACHMENT",
						payload: action.payload.slice(1),
					});
				}),
				catchError((error) => of(console.log("error ", error)))
			)
		)
	);

export const addAttachmentsToIndexedDBEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.JOBS_LIST_RECEIVED),
		filter(() => state$.value.queue.hasNetwork),
		flatMap(findMissingAttachments()),
		mergeMap((missingAttachments) => {
			return of({
				type: "DOWNLOAD_NEXT_ATTACHMENT",
				payload: missingAttachments,
			});
		})
	);
