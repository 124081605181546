import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";
import { onSafetyMinutesListRequest } from "features/health-and-safety";

const mapStateToProps = ({ addSafetyMinutesFormReducer, profile }) => ({
	values: addSafetyMinutesFormReducer,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddSafetyMinutesValueChange,
	onReset: fromActions.onAddSafetyMinutesReset,
	onSave: fromActions.onAddSafetyMinutesSave,
	onGetSafetyMinutesList: onSafetyMinutesListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
