import { connect } from "react-redux";
import CustomerRoutes from "./customer-routes";
// import ListRequested from '../actions/list-requested'
import { onListRequested, onSearchClear } from "../actions";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	onGetCustomersList: onListRequested,
	onSearchClear: onSearchClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRoutes);
