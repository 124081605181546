import { connect } from "react-redux";
import StaffTrainingList from "./staff-training-list";
import {
	onRemove,
	onStaffTrainingSearchChanged,
	onStaffTrainingPagedListRequest,
} from "../../actions";

const mapStateToProps = ({ healthSafety, profile, organisations }) => ({
	staffTrainings: healthSafety.staffTrainings,
	searchFilter: healthSafety.searchFilter,
	role: profile.role,
	currentOrganisationGUID: organisations.currentOrganisation,
});

const mapDispatchToProps = {
	onDelete: onRemove,
	onSearchChanged: onStaffTrainingSearchChanged,
	onGetStaffTrainingList: onStaffTrainingPagedListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffTrainingList);
