import fromTypes from "./types";

export const onAdd = () => ({
	type: fromTypes.JOBS_ADD_CLICKED,
});

export const onAddFailed = (payload) => ({
	type: fromTypes.JOBS_ADD_FAILED,
	payload,
});

export const onAddSuccess = (payload) => ({
	type: fromTypes.JOBS_ADD_SUCCEEDED,
	payload,
});

export const onSaveQueued = (payload) => ({
	type: fromTypes.JOBS_SAVE_QUEUED,
	payload,
});
export const onClose = () => ({
	type: fromTypes.JOBS_CLOSE_CLICKED,
});

export const onEdit = (payload) => ({
	type: fromTypes.JOBS_EDIT_CLICKED,
	payload,
});

export const onCustomerIdChanged = (payload) => ({
	type: fromTypes.JOBS_CUSTOMER_ID_CHANGED,
	payload,
});

export const onDateChanged = (payload) => ({
	type: fromTypes.JOBS_DATE_CHANGED,
	payload,
});

export const onNotesChanged = (payload) => ({
	type: fromTypes.JOBS_NOTES_CHANGED,
	payload,
});

export const onSiteIdChanged = (payload) => ({
	type: fromTypes.JOBS_SITE_ID_CHANGED,
	payload,
});

export const onStaffIdChanged = (payload) => ({
	type: fromTypes.JOBS_STAFF_ID_CHANGED,
	payload,
});

export const onTitleChanged = (payload) => ({
	type: fromTypes.JOBS_TITLE_CHANGED,
	payload,
});

export const onTypeChanged = (payload) => ({
	type: fromTypes.JOBS_TYPE_CHANGED,
	payload,
});

export const onListFailed = (payload) => ({
	type: fromTypes.JOBS_LIST_FAILED,
	payload,
});

export const onListReceived = (payload) => ({
	type: fromTypes.JOBS_LIST_RECEIVED,
	payload,
});

export const onListRequested = () => ({
	type: fromTypes.JOBS_LIST_REQUESTED,
});

export const onPagedListRequested = (payload) => ({
	type: fromTypes.JOBS_PAGED_LIST_REQUESTED,
	payload,
});

export const onDelete = (payload) => ({
	type: fromTypes.JOBS_REMOVE_CLICKED,
	payload,
});

export const onRemoveFailed = (payload) => ({
	type: fromTypes.JOBS_REMOVE_FAILED,
	payload,
});

export const onRemoveSuccess = (payload) => ({
	type: fromTypes.JOBS_REMOVE_SUCCEEDED,
	payload,
});

export const onSave = () => ({
	type: fromTypes.JOBS_SAVE_CLICKED,
});

export const onSearchClear = () => ({
	type: fromTypes.JOBS_SEARCH_CLEAR,
});

export const onSearchChanged = (payload) => ({
	type: fromTypes.JOBS_SEARCH_CHANGED,
	payload,
});

export const onFileChanged = (payload) => ({
	type: fromTypes.FILE_CHANGED,
	payload,
});

export const setGlobalMessage = (payload) => ({
	type: fromTypes.SET_GLOBAL_MESSAGE,
	payload,
});