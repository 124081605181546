import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import FeatureHeader from "frame/components/feature-header";
import { ScrollToTopOnMount } from "frame/components";
import PeopleIcon from "@material-ui/icons/People"; // Customers
import RoomIcon from "@material-ui/icons/Room"; // Sites
import PersonIcon from "@material-ui/icons/PersonOutlined"; // Staff
import WarningIcon from "@material-ui/icons/Warning"; // Hazard library, Hazardous substance library
import AssignmentIcon from "@material-ui/icons/Assignment"; // Reports
import NotificationsIcon from "@material-ui/icons/Notifications"; // Notifications
import NoteAddIcon from "@material-ui/icons/NoteAdd"; // Document packs
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode"; // Job Safety Analysis
import ListIcon from "@material-ui/icons/FormatListBulleted"; // Safety Plan Checklists
import { Page } from "frame/components";
import { CUSTOMERS_PATH } from "features/customers";
import { SITES_PATH } from "features/sites";
import { STAFF_PATH } from "features/staff";
import { types as fromHealthSafety } from "features/health-and-safety";
import {
	ACCOUNT_REPORTS_ROUTE,
	ACCOUNT_NOTIFICATIONS_ROUTE,
} from "features/account";
import * as fromHealthSafetyForms from "features/health-safety-forms";
import { isStaffUser } from "utils";

import HomeCard from "./home-card";
import { DOCUMENT_PACK_ROUTE } from "features/document-pack/routes";
import { connect } from "react-redux";
import { safetyWiseApi } from "utils/safetyWiseApi";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
	},
	header: {
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(-3),
			marginBottom: theme.spacing(-3),
		},
	},
}));

const mapStateToProps = ({
	profile,
	customers,
	sites,
	staff,
	healthSafety,
	home,
	organisations,
}) => ({
	role: profile.role,
	numberOfRecords: {
		customers: customers.pagination?.results.length,
		sites: sites.pagination?.results.length,
		staff: staff.pagination?.results.length,
		hazards: healthSafety.hazards.results.length,
		hazardousSubstances: healthSafety.hazardousSubstances.results.length,
		staffTrainingTopics: healthSafety.staffTrainingTopics.results.length,
		jobSafetyAnalysis: healthSafety.jobSafetyAnalysis.results.length,
		safetyPlanChecklists: healthSafety.safetyPlanChecklists.results.length,
		documentPack: home.documentPackCount,
	},
	currentOrganisationGUID: organisations.currentOrganisation,
});

export const InfoHome = connect(mapStateToProps)(
	({ role, numberOfRecords, currentOrganisationGUID }) => {
		const classes = useStyles();
		const [infoItemsCount, setInfoItemsCount] = useState({
			customers: "0",
			sites: "0",
			staff: "0",
			documents: "0",
			hazardLibrary: "0",
			hazardousSubstanceLibrary: "0",
			jobSafetyAnalysis: "0",
			siteSafetyPlan: "0",
			staffTrainingTopics: "0",
		});
		const [isLoaded, setIsLoaded] = useState(false);

		useEffect(() => {
			safetyWiseApi
				.get(`${currentOrganisationGUID}/organisation/get-info-count`)
				.then((result) => {
					setInfoItemsCount(result.data);
					setIsLoaded(true);
				});
		}, []);

		const links = [
			{
				title: "Customers",
				href: CUSTOMERS_PATH,
				icon: PeopleIcon,
				number: isLoaded
					? infoItemsCount.customers
					: numberOfRecords.customers,
			},
			{
				title: "Sites",
				href: SITES_PATH,
				icon: RoomIcon,
				number: isLoaded ? infoItemsCount.sites : numberOfRecords.sites,
			},
			{
				title: "Staff",
				href: STAFF_PATH,
				icon: PersonIcon,
				number: isLoaded ? infoItemsCount.staff : numberOfRecords.staff,
			},
			{
				title: "Hazard Library",
				href: fromHealthSafety.INFO_HAZARDS_ROUTE,
				icon: WarningIcon,
				number: isLoaded
					? infoItemsCount.hazardLibrary
					: numberOfRecords.hazards,
			},
			{
				title: "Hazardous Substance Library",
				href: fromHealthSafety.INFO_HAZARDOUS_SUBSTANCES_ROUTE,
				icon: WarningIcon,
				number: isLoaded
					? infoItemsCount.hazardousSubstanceLibrary
					: numberOfRecords.hazardousSubstances,
			},
			{
				title: "Staff Training Topics",
				href: fromHealthSafety.INFO_STAFF_TRAINING_TOPIC_ROUTE,
				icon: PeopleIcon,
				number: isLoaded
					? infoItemsCount.staffTrainingTopics
					: numberOfRecords.staffTrainingTopics,
			},
			{
				title: "Job Safety Analysis (JSA)",
				href: fromHealthSafetyForms.ADD_JSA_PATH,
				icon: ChromeReaderModeIcon,
				number: isLoaded
					? infoItemsCount.jobSafetyAnalysis
					: numberOfRecords.jobSafetyAnalysis,
			},
			{
				title: "Site Safety Plans (Checklist)",
				href: fromHealthSafetyForms.ADD_SAFETY_PLAN_CHECKLIST_PATH,
				icon: ListIcon,
				number: isLoaded
					? infoItemsCount.siteSafetyPlan
					: numberOfRecords.safetyPlanChecklists,
			},
			{
				title: "Documents",
				href: DOCUMENT_PACK_ROUTE,
				icon: NoteAddIcon,
				number: isLoaded
					? infoItemsCount.documents
					: numberOfRecords.documentPack,
			},
			{
				title: "Reports",
				href: ACCOUNT_REPORTS_ROUTE,
				icon: AssignmentIcon,
			},
			{
				title: "Notifications",
				href: ACCOUNT_NOTIFICATIONS_ROUTE,
				icon: NotificationsIcon,
			},
		];

		const staffLinks = [
			{
				title: "Customer",
				href: CUSTOMERS_PATH,
				icon: PeopleIcon,
				number: numberOfRecords.customers || 0,
			},
			{
				title: "Sites",
				href: SITES_PATH,
				icon: RoomIcon,
				number: numberOfRecords.sites || 0,
			},
			{
				title: "Hazard Library",
				href: fromHealthSafety.HEALTH_SAFETY_HAZARDS_ROUTE,
				icon: WarningIcon,
				number: numberOfRecords.hazards || 0,
			},
			{
				title: "Hazardous Substance Library",
				href: fromHealthSafety.HEALTH_SAFETY_HAZARDOUS_SUBSTANCES_ROUTE,
				icon: WarningIcon,
				number: numberOfRecords.hazardousSubstances || 0,
			},
			{
				title: "Documents",
				href: DOCUMENT_PACK_ROUTE,
				icon: NoteAddIcon,
				number: numberOfRecords.documentPack || 0,
			},
		];

		const menu = isStaffUser(role) ? staffLinks : links;

		return (
			<Page className={classes.root} title="Info">
				<ScrollToTopOnMount />
				<FeatureHeader title="Info" className={classes.header} />
				<Grid container>
					<Grid item lg={6} md={12} sm={12} xs={12}>
						{numberOfRecords &&
							menu.map(({ icon, href, title, number }, index) => (
								<HomeCard
									key={`info-home-card-${index}`}
									text={title}
									icon={icon}
									link={href}
									number={number}
								/>
							))}
					</Grid>
				</Grid>
			</Page>
		);
	}
);
