import Types from "../actions/types";
import { sendSucceeded, sendFailed, sendRequested } from "../actions";
import { ofType } from "redux-observable";
import { authHeaders } from "frame/auth-headers";
import { mergeMap, catchError, flatMap, finalize } from "rxjs/operators";
import { of, EMPTY } from "rxjs";
import { ajax } from "rxjs/ajax";
import { DBService, JOBS_TABLE, replaceDataURLs } from "frame/indexedDBService";
import * as R from "ramda";
import { setGlobalMessage } from "features/jobs";
import { connect } from "react-redux";

const fetchAttachments = () => async () => {
	const db = new DBService();
	var files = await db.getAll(JOBS_TABLE);
	return files;
};


const mapDispatchToProps = {
	setGlobalMessage
};



/*
  request queue poll for quick pic
*/
// var queueId = "";
const queueSendEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.QUEUE_SEND_REQUESTED),
		flatMap(fetchAttachments()),
		mergeMap((result) => {
			if (
				state$.value.queue.list &&
				state$.value.queue.list.length > 0 &&
				state$.value.queue.hasNetwork
			) {
				let body = R.clone(state$.value.queue.list[0].body);

				body.attachments = replaceDataURLs(body.attachments, result);
				
				return ajax
					.post(state$.value.queue.list[0].url, body, authHeaders())
					.pipe(
						mergeMap((ajaxResponse) =>
							of(
								sendSucceeded(
									state$.value.queue.list[0].id,
									state$.value.queue.list[0].body.id
								),
								sendRequested(),
								setGlobalMessage(state$.value.jobs.globalMessage.filter(o => o[0] != state$.value.queue.list[0].id))
							)
						),
						catchError((error) =>
							of(
								sendFailed(state$.value.queue.list[0].id),
								setGlobalMessage(state$.value.jobs.globalMessage.filter(o => o[0] != state$.value.queue.list[0].id))
							)
						)
					);
			} else {
				return EMPTY;
			}
		})
	);

	
connect(mapDispatchToProps)(queueSendEffect);

export default queueSendEffect;
