import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { lookupResource } from "utils";

const LookupValidationTextField = ({
	defaultOptions = [],
	onChange,
	value,
	disabled,
	...props
}) => {
	const [textValue, setTextValue] = useState(value);

	const checkIfAvailable = async (input) => {
		try {
			const response = await lookupResource("organisation", textValue);
			return response || false;
		} catch (err) {
			return false;
		}
	};	

	const checkEmailExists = async (input) => {
		try {
			const response = await lookupResource("email", textValue);
			return response || false;
		} catch (err) {
			return false;
		}
	};	

	const onInputChange = ({ target: { value } }) => {
		setTextValue(value || "");
		onChange(value || "");
	};

	useEffect(() => {
		ValidatorForm.addValidationRule("isAvailable", (value) =>
			checkIfAvailable(value)
		);

		ValidatorForm.addValidationRule("emailExists", (value) =>
			checkEmailExists(value)
		);

		return () => {
			ValidatorForm.removeValidationRule("isAvailable");
			ValidatorForm.removeValidationRule("emailExists");
		}; // eslint-disable-next-line
	}, [textValue]);

	return (
		<>
			<TextValidator
				{...props}
				value={textValue || ""}
				inputProps={{ maxLength: 50 }}
				onChange={onInputChange}
				disabled={disabled}
			/>
		</>
	);
};

LookupValidationTextField.propTypes = {
	defaultOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
};

export default LookupValidationTextField;
