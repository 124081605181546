import types from "../actions/types";
import * as R from "ramda";

const initialState = {
	compressedFiles: [],
	error: undefined,
	isCompressing: 0,
};

const uploadCompressionFinished = (state, action) => {
	state.isCompressing = state.isCompressing - 1;
	state.compressedFiles.push(action.payload);
	return state;
};

const uploadCompressionFailed = (state, action) => {
	state.error = action.payload;
	return state;
};

const uploadCompressionRequested = (state) => {
	state.isCompressing = state.isCompressing + 1;
	return state;
};

const uploadRemoveItem = (state, action) => {
	state.compressedFiles = state.compressedFiles.filter(
		(o) => o.id !== action.payload.id
	);
	return state;
};

const uploadRemove = (state) => {
	state.compressedFiles = [];
	return state;
};

const uploadLoadAttachments = (state, action) => {
	state.compressedFiles = action.payload;
	return state;
};

const uploadFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.UPLOAD_COMPRESSION_REQUESTED:
			return uploadCompressionRequested(R.clone(state));
		case types.UPLOAD_COMPRESSION_FINISHED:
			return uploadCompressionFinished(R.clone(state), action);
		case types.UPLOAD_COMPRESSION_ERRORED:
			return uploadCompressionFailed(R.clone(state), action);
		case types.UPLOAD_REMOVE_ITEM:
			return uploadRemoveItem(R.clone(state), action);
		case types.UPLOAD_LOAD_ATTACHMENTS:
			return uploadLoadAttachments(R.clone(state), action);
		case types.UPLOAD_REMOVE:
			return uploadRemove(R.clone(state));
		case types.UPLOAD_RESET:
			return initialState;
		default:
			return state;
	}
};

export default uploadFormReducer;
