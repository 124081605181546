import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRouter } from "utils";
import PageHeaderTabs from "../page-header-tabs";
import { Grid } from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
} from "frame/components";
import {
	strip,
	IncidentForm,
	EmailRecipientsForm,
	SiteForm,
	PeopleForm,
	UploadFormCard,
} from "features/forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";
import { validatePeopleWithoutRoles } from "utils";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
		[theme.breakpoints.down("xs")]: {
			// marginTop: theme.spacing(1),
		},
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
}));

const Form = ({ values, onChange, onReset, onSave, onGetIncidentList }) => {
	const classes = useStyles();
	const { history } = useRouter();
	const peopleBlockRef = useRef();
	const [invalidPeople, setInvalidPeople] = useState(false);

	useEffect(() => {
		onGetIncidentList();
	}, [onGetIncidentList]);

	const onSubmit = (event) => {
		event.preventDefault();

		if (
			validatePeopleWithoutRoles(
				values.people,
				peopleBlockRef,
				setInvalidPeople
			)
		) {
			onSave(strip(values));
		}
	};

	const onCancel = () => {
		onReset();
		history.push(fromHealthAndSafety.TASK_ROUTE);
	};

	return (
		<Page title="Add Incident Record">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Incidents"
				title="Add Incident Record"
				// addTabTitle="Add incident"
				// listTabTitle="View library of incidents"
				listTabPath={fromHealthAndSafety.TASK_INCIDENTS_ROUTE}
				backActionPath={fromHealthAndSafety.TASK_ROUTE}
			/>
			<PageContent padSides padBottom padTop>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Grid container spacing={3}>
						<Grid item lg={6} md={12} sm={12} xs={12}>
							<div className={classes.body}>
								<SiteForm values={values} onChange={onChange} />
								{values.siteId && values.siteId !== "NO-ID" && (
									<>
										<IncidentForm
											siteId={values.siteId}
											values={values}
											onChange={onChange}
										/>
										<div ref={peopleBlockRef}>
											<PeopleForm
												recordName="incident"
												values={values}
												onChange={onChange}
												customRoleName="Involvement"
												roleOptions={[
													"Involved",
													"Reported",
													"Witness",
												]}
												showInvalidPeopleError={
													invalidPeople
												}
											/>
										</div>
										<UploadFormCard
											title={"Add Attachments"}
											values={values}
											onChange={onChange}
										/>
										<EmailRecipientsForm
											values={values}
											onChange={onChange}
										/>
									</>
								)}
							</div>
						</Grid>
					</Grid>

					<FormActions className={classes.actions}>
						<FormSubmitButton
							disabled={values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
						<FormButton
							disabled={values.save.saving}
							onClick={onCancel}
						>
							Cancel
						</FormButton>
					</FormActions>
				</ValidatorForm>
			</PageContent>
		</Page>
	);
};

Form.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onGetIncidentList: PropTypes.func.isRequired,
};

export default Form;
