import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useRouter } from "utils";
// import NoteAddIcon from '@material-ui/icons/NoteAdd'
// import ForumIcon from '@material-ui/icons/Forum'
import ListIcon from "@material-ui/icons/FormatListBulleted";
import WarningIcon from "@material-ui/icons/Warning";
import FlashOnIcon from "@material-ui/icons/FlashOn";
// import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ForumIcon from "@material-ui/icons/Forum";
import * as fromRoutes from "../actions/types";
import { safetyWiseApi } from "utils/safetyWiseApi";
import HomeCard from "../../home/components/home-card";
import { ADD_TIMESHEET_ROUTE, LIST_TIMESHEETS_ROUTE } from "features/timesheet/timesheet-routes";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm"; // Timesheets

const useStyles = makeStyles((theme) => ({
	root: {},
	listItem: {
		marginBottom: theme.spacing(2)
	},
	icon: {
		marginRight: theme.spacing(1),
		color: theme.palette.brand
	},
	Label: {
		color: theme.palette.brand
	}
}));

const HealthSafetyLinks = ({ siteId, numberOfRecords, currentOrganisationGUID }) => {
	const classes = useStyles();
	const { history } = useRouter();
	const [infoItemsCount, setInfoItemsCount] = useState({
		hazards: "0",
		hazardousSubstances: "0",
		incidentRecords: "0",
		inductionMeetings: "0",
		safetyPlanChecklists: "0",
		safetyMinutes: "0",
		quickPicOrNotes: "0",
		timesheets: "0"
	});

	useEffect(() => {
		safetyWiseApi
			.get(`${currentOrganisationGUID}/site/${siteId}/get-site-info-counts`)
			.then((result) => {
				setInfoItemsCount(result.data);
			});
	}, []);

	const healthSafetyLinks = [
		// {
		// 	title: "Add Timesheet",
		// 	icon: AccessAlarmIcon,
		// 	to: `${ADD_TIMESHEET_ROUTE}/${siteId}`,
		// 	isAddTimesheet: true
		// },
		{
			title: "Timesheets",
			icon: AccessAlarmIcon,
			to: `${LIST_TIMESHEETS_ROUTE}/${siteId}`,
			isAddTimesheet: true,
			number: infoItemsCount.timesheets
		},
		{
			title: "Quickpics",
			icon: ForumIcon,
			to: fromRoutes.SITE_NOTES_PATH,
			number: infoItemsCount.quickPicOrNotes
		},
		{
			title: "Incidents",
			icon: FlashOnIcon,
			to: fromRoutes.SITE_INCIDENTS_PATH,
			number: infoItemsCount.incidentRecords
		},
		{
			title: "Inductions",
			icon: PersonAddIcon,
			to: fromRoutes.SITE_INDUCTION_MINUTES_PATH,
			number: infoItemsCount.inductionMeetings
		},
		{
			title: "Safety Minutes",
			icon: ForumIcon,
			to: fromRoutes.SITE_SAFETY_MEETING_MINUTES_PATH,
			number: infoItemsCount.safetyMinutes
		},
		{
			title: "Site Safety Plans (SSPs)",
			icon: ListIcon,
			to: fromRoutes.SITE_SAFETY_PLAN_CHECKLIST_PATH,
			number: infoItemsCount.safetyPlanChecklists
		},
		{
			title: "Hazards",
			icon: WarningIcon,
			to: fromRoutes.SITE_HAZARDS_PATH,
			number: infoItemsCount.hazards
		},
		{
			title: "Hazardous Substances",
			icon: WarningIcon,
			to: fromRoutes.SITE_HAZARDOUS_SUBSTANCES_PATH,
			number: infoItemsCount.hazardousSubstances
		},
	];

	return healthSafetyLinks.map(({ icon, title, to, number, isAddTimesheet }, index) => (
		<HomeCard
			key={`health-safety-link-card-${index}`}
			text={title}
			icon={icon}
			link={isAddTimesheet ? to : `${fromRoutes.SITES_ROUTE}/${siteId}/${to}`}
			number={number}
		/>
	));
};

HealthSafetyLinks.propTypes = {
	siteId: PropTypes.string.isRequired,
	numberOfRecords: PropTypes.object.isRequired,
	currentOrganisationGUID: PropTypes.string.isRequired
};

export default HealthSafetyLinks;
