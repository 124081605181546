import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { getBaseUrl } from "frame";
import fromTypes from "../actions/types";

import { onResetPasswordFulfilled, onResetPasswordFailed } from "../actions";

const resetPasswordEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.AUTHORISATION_RESET_PASSWORD_REQUESTED),
		mergeMap(({ payload }) =>
			ajax
				.post(`${getBaseUrl()}/api/reset-password${payload}`, {
					password: state$.value.authorisation.resetPassword.password,
					confirmPassword:
						state$.value.authorisation.resetPassword
							.confirmPassword,
				})
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onResetPasswordFulfilled(ajaxResponse.response))
					),
					catchError((error) => onResetPasswordFailed(error))
				)
		)
	);

export default resetPasswordEffect;
