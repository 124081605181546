import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	SearchBar,
	PageHeader,
	SelectInput,
} from "frame/components";
import OrganisationListing from "./organisation-listing";
import EditOrganisationForm from "../edit-organisation-form-container";
import { Typography } from "@material-ui/core";
import { useIsOnline } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: (props) =>
			props.isAccountPage ? theme.palette.white : "transparent",
	},
	heading: {
		padding: theme.spacing(3),
	},
	body: {
		marginTop: theme.spacing(3),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0),
		},
	},
	filter: { display: "flex" },
	filterOptions: {
		position: "relative",
		right: 25,
		top: 10,
		marginTop: theme.spacing(0.1),
		"& .MuiOutlinedInput-input": {
			padding: theme.spacing(1.7, 2),
		},
		"& .MuiInputLabel-outlined": {
			transform: "translate(14px, 14px) scale(1)",
		},
		"& .MuiInputLabel-shrink": {
			transform: "translate(14px, -6px) scale(0.75)",
		},
		[theme.breakpoints.down("sm")]: {
			right: -100,
		},
		[theme.breakpoints.down("xs")]: {
			top: 50,
			left: 20,
			minWidth: "100px",
		},
		minWidth: "150px",
	},
	offlineMessage: {
		color: theme.palette.error.main,
		marginTop: theme.spacing(-2),
		paddingBottom: theme.spacing(1),
	},
}));

const OrganisationList = ({
	flag,
	organisations,
	filter,
	onEdit,
	onDelete,
	onSearchChanged,
	onFetchOrganisations,
	onChangeOrganisation,
	onStatusFilterChange,
}) => {
	const theme = useTheme();
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

	const isAccountPage = window.location.pathname.includes("/account");
	const classes = useStyles({ isAccountPage });

	const isOnline = useIsOnline();

	useEffect(() => {
		onFetchOrganisations();
	}, [onFetchOrganisations]);

	// const fetchSuccess = flag.done && flag.success

	const onFilterChange = (value) => onStatusFilterChange(value);

	return (
		<Page className={classes.root} title="Organisations">
			<ScrollToTopOnMount />

			{!mobileView && (
				<>
					{isAccountPage ? (
						<Typography variant="h2" className={classes.heading}>
							Organisations
						</Typography>
					) : (
						<PageHeader title="Organisations" />
					)}
				</>
			)}

			<div className={classes.filter}>
				<SearchBar onSearch={onSearchChanged} />

				<div className={classes.filterOptions}>
					<SelectInput
						noNone
						id="filter-options"
						label="Filter"
						options={["All", "Active", "Inactive"]}
						value={filter}
						onChange={onFilterChange}
					/>
				</div>
			</div>

			<PageContent pad>
				<div className={classes.body}>
					{!isOnline && (
						<Typography
							className={classes.offlineMessage}
							variant="body1"
							align="right"
						>
							Please connect to internet to switch organisations
						</Typography>
					)}

					{organisations &&
						organisations.map((data, index) => (
							<OrganisationListing
								key={index}
								organisation={data}
								onEdit={onEdit}
								onDelete={onDelete}
								onChangeOrganisation={onChangeOrganisation}
							/>
						))}
				</div>
			</PageContent>

			<EditOrganisationForm />
		</Page>
	);
};

OrganisationList.propTypes = {
	flag: PropTypes.object.isRequired,
	filter: PropTypes.string.isRequired,
	organisations: PropTypes.array.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onSearchChanged: PropTypes.func.isRequired,
	onFetchOrganisations: PropTypes.func.isRequired,
	onChangeOrganisation: PropTypes.func.isRequired,
	onStatusFilterChange: PropTypes.func.isRequired,
};

export default OrganisationList;
