import React from "react";
import LandingPageFrame from "./landing-page-frame";
import ForgotPasswordForm from "./forms/forgot-password-form";

const ForgotPassword = (props) => {
	return (
		<LandingPageFrame
			title={"Forgot your Password?"}
			form={<ForgotPasswordForm {...props} />}
		/>
	);
};

export default ForgotPassword;
