import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	Typography,
} from "@material-ui/core";
import { Dialog, FormButton, TextLink, ValueText } from "frame/components";
import { SelectInput } from "frame/components/input";
import * as fromFormRoutes from "features/health-safety-forms";
import moment from "moment";
import {
	capitalize,
	isNotNullOrEmpty,
	isBornAccount,
	useCurrentUserEmail,
} from "utils";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	empty: {
		padding: theme.spacing(2),
		textAlign: "center",
	},
	button: {
		margin: theme.spacing(2, 0, 0, 0),
	},
	headerTitle: {
		padding: theme.spacing(1.6, 1),
		minWidth: 140,
	},
	headerStatus: {
		padding: theme.spacing(1.6, 1),
	},
	headerOptions: {
		padding: `0 !important`,
		width: 30,
	},
	review: {
		marginBottom: theme.spacing(5),
	},
	fieldLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(-1.2),
	},
	label: {
		margin: 0,
	},
	link: {
		color: theme.palette.text.link,
	},
}));
/*
  Add Hazardous Substance to Site details Form 
*/
const HazardousSubstancesForm = ({
	className,
	values,
	onChange,
	preExistingSiteSubstances,
}) => {
	const classes = useStyles();
	const userEmail = useCurrentUserEmail();

	const [data, setData] = useState(false); // for modal
	const disabled = false; //values.locked

	const hazardousSubstanceList = useSelector(
		({ healthSafety }) => healthSafety.hazardousSubstances?.results
	);

	// pre-populates lists with existing substances added to site
	const substances = isNotNullOrEmpty(values.substances)
		? values.substances.map((node) => {
				return {
					hazardousSubstanceId: node.hazardousSubstanceId || node,
					location: node.location || "",
					volume: node.volume || 0,
					dateMeasured: node.dateMeasured || new moment().format(),
					status: node.status || "Active",
				};
		  })
		: [];

	const hazardousSubstanceOptions =
		hazardousSubstanceList &&
		hazardousSubstanceList
			.filter(({ id }) => {
				const hasHazardousSubstanceId =
					substances &&
					substances.filter(
						(node) => node.hazardousSubstanceId === id
					).length === 0;
				return hasHazardousSubstanceId;
			})
			.filter((node) => node.status !== "Archived")
			.map(({ id, name }) => ({
				label: name,
				value: id,
			}))
			.sort((a, b) => (a.label > b.label ? 1 : -1));

	const hasHazardousSubstanceList = !!hazardousSubstanceList.length;
	const noHazardousSubstancesToSelect = !hazardousSubstanceOptions.length;

	if (isNotNullOrEmpty(hazardousSubstanceOptions)) {
		substances.push("");
	}

	const onModifyList = (list) => {
		//remove duplicates from list
		const uniq = new Set(list.map((e) => JSON.stringify(e)));
		const next = Array.from(uniq)
			.map((e) => JSON.parse(e))
			.filter((x) => x); // to remove null entries

		onChange({
			...values,
			substances: next.length > 0 ? next : [],
		});
	};

	const onSelectOption = (index) => (value) => {
		const hazardousSubstance =
			hazardousSubstanceList &&
			hazardousSubstanceList.filter(({ id }) => id === value)[0];

		if (hazardousSubstance) {
			setData({
				...hazardousSubstance,
				location: substances[index].location || "",
				volume: substances[index].volume || 0,
				dateMeasured:
					substances[index].dateMeasured || new moment().format(),
				status: substances[index].status || "Active",
			});
			return;
		}
		//when none is selected
		const list = [...substances];
		list[index] = null;

		onModifyList(list);
	};

	const onCloseHazardousSubstance = () => setData(null);

	const onSelectHazardousSubstance = () => {
		const list = [...substances];
		// check if substance is already selected
		const existingSubstance = substances.filter(
			(node) => node.hazardousSubstanceId === data.id
		);
		if (existingSubstance.length > 0) {
			const index = list.indexOf(existingSubstance[0]);
			list[index].location = data.location || "";
			list[index].volume = data.volume || 0;
			list[index].dateMeasured =
				data.dateMeasured || new moment().format();
			list[index].status = data.status || "";
		} else {
			list.push({
				hazardousSubstanceId: data.id,
				location: data.location || "",
				volume: data.volume || 0,
				dateMeasured: data.dateMeasured || new moment().format(),
				status: data.status || "Active",
			});
		}
		onModifyList(list);
		onCloseHazardousSubstance();
	};

	const hideAddSubstancesLink =
		!window.location.pathname.includes("induction");

	return (
		<>
			<CardContent>
				<Grid container spacing={2}>
					{/* <pre>
            {JSON.stringify({
              substances: values.substances
            }, null ,2)}
          </pre> */}
					{substances.map(
						({ hazardousSubstanceId, status }, index) => {
							if (
								index === substances.length &&
								!hazardousSubstanceOptions.length
							) {
								return null;
							}

							// hides preExisting Entries - uncomment to show
							if (
								preExistingSiteSubstances.includes(
									hazardousSubstanceId
								) &&
								!isBornAccount(userEmail)
							)
								return null;

							const matched = hazardousSubstanceList.filter(
								({ id }) => id === hazardousSubstanceId
							)[0];

							const label =
								matched && matched.name
									? matched.name
									: hazardousSubstanceId;

							const option = {
								label,
								value: hazardousSubstanceId,
							};

							const options = option.label
								? [option, ...hazardousSubstanceOptions]
								: hazardousSubstanceOptions;

							return (
								<Grid
									item
									xs={12}
									key={`hazardousSubstance_${index}`}
								>
									<Grid container>
										{index > 0 &&
											index !==
												preExistingSiteSubstances.length && (
												<Grid item xs={12}>
													<Typography variant="body1">
														Add another:
													</Typography>
												</Grid>
											)}

										<Grid item xs={12}>
											<SelectInput
												id={`hazard_${index}`}
												data-cy="hazard_id"
												disabled={
													disabled ||
													status === "Archived"
												}
												label="Select Hazardous Substance"
												placeholder="Select Hazardous Substance"
												options={options}
												value={hazardousSubstanceId}
												onChange={onSelectOption(index)}
											/>
										</Grid>
									</Grid>
								</Grid>
							);
						}
					)}

					{noHazardousSubstancesToSelect &&
						!hasHazardousSubstanceList &&
						hideAddSubstancesLink && (
							<Grid item xs={12}>
								<Typography variant="body2">
									There are no hazardousSubstances to select
									from.&nbsp;
									<TextLink
										className={classes.link}
										to={
											fromFormRoutes.ADD_HAZARDOUS_SUBSTANCE_ROUTE
										}
									>
										Please add hazardousSubstances here.
									</TextLink>
								</Typography>
							</Grid>
						)}

					{noHazardousSubstancesToSelect &&
						hasHazardousSubstanceList &&
						hideAddSubstancesLink && (
							<Grid item xs={12}>
								<Typography variant="body2">
									There are no remaining hazardous substances
									to select from.{" "}
									<RouterLink
										className={classes.link}
										to={
											fromFormRoutes.ADD_HAZARDOUS_SUBSTANCE_PATH
										}
									>
										Click Here
									</RouterLink>{" "}
									to add more hazardous substances.
								</Typography>
							</Grid>
						)}

					{(!noHazardousSubstancesToSelect ||
						substances.length <= hazardousSubstanceList.length) &&
						preExistingSiteSubstances.length !==
							hazardousSubstanceList.length && (
							<Grid item xs={12}>
								<Typography variant="body1">
									{`Once you have added all the hazardous substances at ${
										values.siteName
											? values.siteName
											: "site"
									} click Save.`}
								</Typography>
							</Grid>
						)}
				</Grid>
			</CardContent>
			{data && (
				<Dialog
					open
					aria-labelledby="Hazardous Substance Record"
					breadcrumbs={`Add Hazardous Substance to ${
						values.siteName ? values.siteName : "Site"
					}`}
					title="Hazardous Substance Record"
					onClose={onCloseHazardousSubstance}
				>
					<Card className={classes.review}>
						<ValidatorForm
							autoComplete="off"
							noValidate
							// onSubmit={(onSubmit)}
						>
							<CardHeader
								title={data.name}
								titleTypographyProps={{ variant: "h4" }}
							/>
							<CardContent>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<ValueText
											label="Substance Type"
											value={data.substanceType}
											options={{ large: true }}
										/>
									</Grid>
									<Grid item xs={12}>
										<ValueText
											label="Supplier SDS Report Held"
											value={capitalize(
												data.supplierSDSReportHeld
											)}
											options={{ large: true }}
										/>
									</Grid>
									{/* <Grid item xs={12}>
                    <ValueText
                      label="Initial Risk Assessment"
                      value={capitalize(data.initialRiskAssessment)}
                      options={{ large: true }} />
                  </Grid>
                  <Grid item xs={12}>
                    <ValueText
                      label="Residual Risk Assessment"
                      value={data.residualRiskAssessment ? capitalize(data.residualRiskAssessment): '-'}
                      options={{ large: true }} />
                  </Grid> */}
									<Grid item xs={12}>
										<ValueText
											label="Protective Clothing Required"
											value={
												data.protectiveClothingRequired
											}
											options={{ large: true }}
										/>
									</Grid>
									<Grid item xs={12}>
										<ValueText
											label="Action Recommended"
											value={data.actionRecommended}
											options={{ large: true }}
										/>
									</Grid>
									<Grid item sm={9} xs={12}>
										<br />
										<TextValidator
											id="site_substance_volume"
											disabled={disabled}
											fullWidth
											type="number"
											onKeyDown={(e) =>
												e.key === "e" &&
												e.preventDefault()
											}
											label={`Volume held on this site ${
												data.volumeMeasurement
													? `(${data.volumeMeasurement})`
													: ""
											}`}
											value={data.volume || null}
											variant="outlined"
											inputProps={{ maxLength: 50 }}
											// validators={['required', 'isNumber']}
											// errorMessages={['This field is required', 'Must be a number']}
											onChange={(e) =>
												setData({
													...data,
													volume: e.target.value,
												})
											}
										/>
									</Grid>
									<Grid item sm={3} xs={12}>
										<br />
										<DatePicker
											data-cy="site_substance_date"
											disabled={disabled}
											format="D MMMM YYYY"
											fullWidth
											inputVariant="outlined"
											label="Date volume was measured"
											value={
												data.dateMeasured ||
												new moment().format()
											}
											variant="outlined"
											disableFuture={true}
											onChange={(value) =>
												setData({
													...data,
													dateMeasured:
														moment(value).format(),
												})
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<br />
										<TextValidator
											id="site_substance_location"
											disabled={disabled}
											fullWidth
											label="Location of hazardous substance on this site"
											multiline
											rows="3"
											value={data.location || ""}
											variant="outlined"
											// validators={['required']}
											// errorMessages={['This field is required']}
											onChange={(e) =>
												setData({
													...data,
													location: e.target.value,
												})
											}
										/>
									</Grid>
								</Grid>
							</CardContent>
							<CardActions>
								<FormButton
									color="brand"
									// disabled={ ! agreed}
									onClick={onSelectHazardousSubstance}
								>
									Ok
								</FormButton>
								<FormButton onClick={onCloseHazardousSubstance}>
									Cancel
								</FormButton>
							</CardActions>
						</ValidatorForm>
					</Card>
				</Dialog>
			)}
		</>
	);
};

HazardousSubstancesForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	preExistingSiteSubstances: PropTypes.array.isRequired,
};

export default HazardousSubstancesForm;
