import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tabs } from "@material-ui/core";

const StyledTabs = withStyles((theme) => ({
	indicator: {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
		"& > div": {
			// maxWidth: 40,
			width: "100%",
			// backgroundColor: theme.palette.primary.main,
		},
	},
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export default StyledTabs;
