import { connect } from "react-redux";
import Sites from "./sites";

import {
	onAdd,
	onEdit,
	onDelete,
	onSelectSite,
	onSearchChanged,
	onPagedListRequested,
} from "../actions";

const mapStateToProps = ({ sites, profile }) => ({
	role: profile.role,
	pagination: sites.pagination,
	searchFilter: sites.searchFilter,
});

const mapDispatchToProps = {
	onAdd,
	onEdit,
	onDelete,
	onSelectSite,
	onSearchChanged,
	onGetSitesList: onPagedListRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sites);
