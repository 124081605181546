import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		width: "inherit",
		paddingRight: theme.spacing(2),
		display: "inline-block",
	},
	text: {
		textAlign: "justify",
	},
	link: {
		color: theme.palette.text.link,
	},
}));

const TextListClip = ({ text, numberOfItems }) => {
	const classes = useStyles();
	const [seeMore, setSeeMore] = useState(false);

	let list = text && text.split("\n");
	const showSeeMore = list && list.length > numberOfItems;

	const displayList = (list) => {
		return list ? (
			<ul>
				{list.map((item, key) => (
					<li key={key}>{item.trim()}</li>
				))}
			</ul>
		) : (
			"-"
		);
	};

	return (
		<span className={classes.root}>
			{text ? (
				<>
					<span className={classes.text}>
						{seeMore
							? displayList(list)
							: displayList(list.slice(0, numberOfItems))}
					</span>

					{/* eslint-disable-next-line */}
					{showSeeMore && (
						<a
							href="#"
							className={classes.link}
							onClick={() => setSeeMore(!seeMore)}
						>
							{seeMore ? "Hide" : "See More"}
						</a>
					)}
				</>
			) : (
				"-"
			)}
		</span>
	);
};

TextListClip.propTypes = {
	text: PropTypes.string,
	numberOfItems: PropTypes.number.isRequired,
};

export default TextListClip;
