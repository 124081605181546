import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { SelectInput, TextClip } from "frame/components";
import {
	formatDate,
	isDefaultGuid,
	sortByPropertyDate,
	useLookup,
} from "utils";
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	heading: {
		marginBottom: theme.spacing(-3),
	},
	textField: {
		marginBottom: theme.spacing(2),
	},
	lastContent: {
		color: theme.palette.action.active,
	},
}));

const NotesForm = ({ values, onChange }) => {
	const classes = useStyles();

	const [prevGenIssues, setPreGenIssues] = useState("");

	const safetyMinutesList = useSelector(
		({ healthSafety }) => healthSafety.safetyMinutes?.results
	);
	const siteName = useLookup(values.siteId, "sites", "siteName");

	const notes = values.notes || {};
	const disabled = values.locked;

	const onInputChange =
		(key) =>
		({ target: { value } }) =>
			onChange({
				...values,
				notes: {
					...notes,
					[key]: value,
				},
			});

	const onSelectChange = (key) => (value) => {
		const prevSafetyMinutes =
			safetyMinutesList &&
			safetyMinutesList.filter((node) => node.id === value)[0];

		const operationalIssues =
			prevSafetyMinutes && value !== ""
				? prevSafetyMinutes.operationalIssues
				: "";
		const generalIssuesDiscussed =
			prevSafetyMinutes && value !== ""
				? prevSafetyMinutes.generalIssuesDiscussed
				: "";
		setPreGenIssues(generalIssuesDiscussed);
		onChange({
			...values,
			notes: {
				...notes,
				[key]: value,
				operationalIssues,
			},
		});
	};

	const previousNotesOptions =
		safetyMinutesList && safetyMinutesList.length > 0
			? safetyMinutesList
					.filter((node) => node.siteId === values.siteId)
					.map((node) => {
						// const site = sites && sites.filter(s => s.id === values.siteId)[0]
						const site = siteName ? `${siteName} - ` : "";
						const label = node.title
							? `${site}${node.title}`
							: "[No Title]";
						return {
							label: label,
							value: node.id,
						};
					})
					.sort(sortByPropertyDate("createdAt"))
			: [];

	useEffect(() => {
		if (isDefaultGuid(values.siteId)) {
			onChange({
				...values,
				notes: {
					...notes,
					previousNotes: "",
				},
			});
		} // eslint-disable-next-line
	}, [values.siteId]);

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Meeting Notes"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<SelectInput
							id="previous_notes"
							data-cy="previous_notes"
							label="Show previous minutes"
							options={previousNotesOptions}
							disabled={disabled || isDefaultGuid(values.siteId)}
							value={notes.previousNotes || ""}
							onChange={onSelectChange("previousNotes")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="notes_issues"
							disabled={disabled}
							fullWidth
							label="Operational Issues"
							multiline
							value={notes.operationalIssues || ""}
							variant="outlined"
							onChange={onInputChange("operationalIssues")}
						/>
					</Grid>
					{prevGenIssues && prevGenIssues !== "" && (
						<Grid className={classes.lastContentArea} item xs={12}>
							<Typography
								className={classes.lastContent}
								variant="h5"
							>
								General Issues Discussed at the last Safety
								Meeting on {formatDate(values.createdAt)}:
							</Typography>
							<Typography
								className={classes.lastContent}
								variant="body2"
							>
								<TextClip
									text={prevGenIssues}
									numberOfCharacters={140}
								/>
							</Typography>
						</Grid>
					)}
					<Grid item xs={12}>
						<TextField
							id="notes_general"
							disabled={disabled}
							fullWidth
							label="General Issues Discussed"
							multiline
							value={notes.generalIssuesDiscussed || ""}
							variant="outlined"
							onChange={onInputChange("generalIssuesDiscussed")}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

NotesForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default NotesForm;
