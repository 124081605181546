import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { formatDate, isNotNullOrEmpty } from "utils";
import { Typography, Grid, Divider } from "@material-ui/core";
import {
	SectionCard,
	AttachmentsSectionCard,
	HazardSubstanceListItem,
	ValueText,
	Lookup,
} from "frame/components";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {},
	heading: {
		padding: theme.spacing(2, 0, 1, 0),
	},
	listItem: {
		padding: theme.spacing(1, 0),
		display: "flex",
		flexDirection: "row",
	},
	label: {
		minWidth: 250,
		marginTop: theme.spacing(1.5),
		paddingRight: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			paddingRight: theme.spacing(0),
			minWidth: 140,
		},
	},
}));

const hydrate = ({
	id,
	siteId,
	createdAt,
	people,
	otherPeople,
	hazards,
	hazardousSubstances,
	attachments,
	recipients,
	recipientsOther,
	hazardNotes,
	notes,
	organisationId,
}) => ({
	id,
	siteId,
	createdAt,
	people,
	otherPeople,
	hazards,
	substances: hazardousSubstances,
	hazardNotes,
	notes,
	attachments,
	recipients,
	recipientsOther,
	currentOrganisation: organisationId,
});

const ViewInductionMinutesForm = ({
	id,
	values,
	onChange,
	promptUnreadGuidelines,
	setReadAllGuidelines,
	guidelinesBlockRef,
}) => {
	const classes = useStyles();

	const [loaded, setLoaded] = useState(false);

	const hazardList = useSelector(
		({ healthSafety }) => healthSafety.hazards?.results
	);
	const hazardousSubstanceList = useSelector(
		({ healthSafety }) => healthSafety.hazardousSubstances?.results
	);
	const inductionList = useSelector(
		({ healthSafety }) => healthSafety.inductions?.results
	);

	const [readHazardGuidelines, setReadHazardGuidelines] = useState([]);
	const [readSubstanceGuidelines, setReadSubstanceGuidelines] = useState([]);

	useEffect(() => {
		if (loaded) {
			return;
		}
		async function fetchData() {
			setLoaded(true);
			const induction =
				inductionList && inductionList.filter((i) => i.id === id)[0];

			if (induction) {
				onChange(hydrate(induction));

				setReadHazardGuidelines(
					induction.hazards
						? new Array(induction.hazards.length).fill(false)
						: []
				);
				setReadSubstanceGuidelines(
					induction.hazardousSubstances
						? new Array(induction.hazardousSubstances.length).fill(
								false
						  )
						: []
				);

				// passing induction.hazards, induction.hazardousSubstances because setReadHazardGuidelines/setReadSubstanceGuidelines is async
				// and doesnt set in time for validateIfReadAllGuidelines() function on redirect load
				validateIfReadAllGuidelines(
					induction.hazards,
					induction.hazardousSubstances
				);
			}
		}
		if (id) {
			setLoaded(true);
			fetchData();
		} // eslint-disable-next-line
	}, [id, loaded, onChange]);

	const validateIfReadAllGuidelines = (
		inductionHazards,
		inductionSubstances
	) => {
		// optional params
		const numHazards = isNotNullOrEmpty(inductionHazards)
			? inductionHazards.length
			: values.hazards && values.hazards.length;
		const numSubstances = isNotNullOrEmpty(inductionSubstances)
			? inductionSubstances.length
			: values.substances && values.substances.length;

		const checkedHazards =
			readHazardGuidelines && readHazardGuidelines.filter((x) => x);
		const checkedSubstances =
			readSubstanceGuidelines && readSubstanceGuidelines.filter((x) => x);

		const readAllHazardGuidelines =
			numHazards > 0
				? checkedHazards && numHazards === checkedHazards.length
				: true;

		const readAllSubstanceGuidelines =
			numSubstances > 0
				? checkedSubstances &&
				  numSubstances === checkedSubstances.length
				: true;

		setReadAllGuidelines(
			readAllHazardGuidelines && readAllSubstanceGuidelines
		);
	};

	const onReadHazardGuidelines = (key) => () => {
		let updated = readHazardGuidelines;
		updated[key] = true;
		setReadHazardGuidelines(updated);
		validateIfReadAllGuidelines();
	};

	const onReadSubstanceGuidelines = (key) => () => {
		let updated = readSubstanceGuidelines;
		updated[key] = true;
		setReadSubstanceGuidelines(updated);
		validateIfReadAllGuidelines();
	};

	return (
		<ValidatorForm
			className={classes.root}
			autoComplete="off"
			noValidate
			onSubmit={() => {}}
		>
			<Grid container spacing={2}>
				<Grid item sm={6} xs={12}>
					<SectionCard>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ValueText
									label="Site"
									value={
										<Lookup
											id={values.siteId}
											resource="sites"
											attribute="siteName"
										/>
									}
									options={{ bold: true }}
								/>
							</Grid>
							<Grid item xs={12}>
								<ValueText
									label="Date"
									value={formatDate(values.createdAt)}
									options={{ bold: true }}
								/>
							</Grid>
						</Grid>
					</SectionCard>

					<SectionCard title="People">
						<Grid container spacing={2}>
							{isNotNullOrEmpty(values.people) && (
								<Grid item xs={12}>
									<Typography
										variant="h5"
										className={classes.heading}
									>
										<strong>Staff People:</strong>
									</Typography>

									<Typography
										align="left"
										gutterBottom
										variant="body1"
									>
										{values.people.map(
											({ name, role }, index) => (
												<div
													key={`view-induction-staff-people-${index}`}
												>
													<div
														className={
															classes.listItem
														}
													>
														<span
															style={{
																justifyContent:
																	"flex-start",
															}}
														>
															{name}
														</span>
														<span
															style={{ flex: 1 }}
														></span>
														<span
															style={{
																justifyContent:
																	"flex-end",
															}}
														>
															{role}
														</span>
													</div>
													<Divider />
												</div>
											)
										)}
									</Typography>
								</Grid>
							)}

							{isNotNullOrEmpty(values.people) && (
								<Grid item xs={12}>
									<Typography
										variant="h5"
										className={classes.heading}
									>
										<strong>Other People:</strong>
									</Typography>
									<Typography
										align="left"
										gutterBottom
										variant="body1"
									>
										{values.people.map((name, index) => (
											<div
												key={`view-induction-other-people-${index}`}
											>
												<div
													className={classes.listItem}
												>
													<span
														style={{
															justifyContent:
																"flex-start",
														}}
													>
														{name}
													</span>
													<span
														style={{ flex: 1 }}
													></span>
												</div>
												<Divider />
											</div>
										))}
									</Typography>
								</Grid>
							)}

							{!isNotNullOrEmpty(values.people) &&
								!isNotNullOrEmpty(values.otherPeople) &&
								"-"}
						</Grid>
					</SectionCard>

					<div ref={guidelinesBlockRef} />
					<SectionCard title="Hazards / Hazardous Substances">
						<Grid container spacing={2}>
							{isNotNullOrEmpty(values.hazards) && (
								<Grid item xs={12}>
									<Typography
										variant="h5"
										className={classes.heading}
									>
										<strong>Hazards:</strong>
									</Typography>
									<Typography
										align="left"
										gutterBottom
										variant="body1"
									>
										{hazardList
											? values.hazards.map(
													(hazardId, index) => (
														<>
															<HazardSubstanceListItem
																isHazard
																id={hazardId}
																hazardList={
																	hazardList
																}
																onReadGuidelines={onReadHazardGuidelines(
																	index
																)}
																promptUnread={
																	promptUnreadGuidelines
																}
															/>
															<Divider />
														</>
													)
											  )
											: "loading..."}
									</Typography>
								</Grid>
							)}

							{isNotNullOrEmpty(values.substances) && (
								<Grid item xs={12}>
									<Typography
										variant="h5"
										className={classes.heading}
									>
										<strong>Hazardous Substances:</strong>
									</Typography>
									<Typography
										align="left"
										gutterBottom
										variant="body1"
									>
										{hazardousSubstanceList
											? values.substances.map(
													(substanceId, index) => (
														<>
															<HazardSubstanceListItem
																id={substanceId}
																hazardousSubstanceList={
																	hazardousSubstanceList
																}
																onReadGuidelines={onReadSubstanceGuidelines(
																	index
																)}
																promptUnread={
																	promptUnreadGuidelines
																}
															/>
															<Divider />
														</>
													)
											  )
											: "loading..."}
									</Typography>
								</Grid>
							)}
						</Grid>
						{!isNotNullOrEmpty(values.hazards) &&
							!isNotNullOrEmpty(values.substances) &&
							"-"}
					</SectionCard>

					<AttachmentsSectionCard values={values} />

					<SectionCard title="Notes">
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography
									align="left"
									gutterBottom
									variant="body1"
								>
									{values.notes || "-"}
								</Typography>
							</Grid>
						</Grid>
					</SectionCard>
				</Grid>
			</Grid>
		</ValidatorForm>
	);
};

ViewInductionMinutesForm.propTypes = {
	id: PropTypes.string.isRequired,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	promptUnreadGuidelines: PropTypes.bool,
	setReadAllGuidelines: PropTypes.func.isRequired,
	guidelinesBlockRef: PropTypes.object.isRequired,
};

export default ViewInductionMinutesForm;
