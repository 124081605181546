import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";

const mapStateToProps = ({ addStaffTrainingReducer }) => ({
	values: addStaffTrainingReducer,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddStaffTrainingValueChange,
	onReset: fromActions.onAddStaffTrainingReset,
	onSave: fromActions.onAddStaffTrainingSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
