import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, CardHeader } from "@material-ui/core";

import AttachmentListing from "./attachment-listing";
import { isNotNullOrEmpty } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	content: {
		display: "flex",
		flexDirection: "column",
		width: "100%",
		paddingTop: theme.spacing(1),
		background: theme.palette.background.paper,
		borderRadius: "4px",
	},
}));

const AttachmentsSectionCard = ({ values, title }) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardHeader
				title={title ? title : "Attachments"}
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent className={classes.content}>
				{isNotNullOrEmpty(values.attachments) ? (
					<AttachmentListing attachments={values.attachments} />
				) : (
					"No attachments available."
				)}
			</CardContent>
		</Card>
	);
};

AttachmentsSectionCard.propTypes = {
	values: PropTypes.object,
	title: PropTypes.string,
};

export default AttachmentsSectionCard;
