import uuid from "uuid";
import { push } from "connected-react-router";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError, flatMap } from "rxjs/operators";
import {
	onInductionMinutesListRequest,
	TASK_INDUCTION_MINUTES_ROUTE,
} from "features/health-and-safety";
// import { createSitePath, SITE_INDUCTION_MINUTES_PATH } from 'features/sites'
import { ADD_INDUCTION_MINUTES_FORM_SUBMIT } from "../actions/types";
// import { getResponseBody } from '../utils'
import {
	DBService,
	INDUCTION_MEETINGS_TABLE,
	replaceDataURLs,
	authHeaders,
	getOrganisationBaseUrl,
} from "frame";
import { filterPeople, getCurrentTimestamp } from "utils";
import * as R from "ramda";

import {
	onAddInductionMinutesReset,
	onAddInductionMinutesSaveFailed,
	onAddInductionMinutesSaveSuccess,
} from "../actions";

const fetchAttachments = () => async () => {
	const db = new DBService();
	var files = await db.getAll(INDUCTION_MEETINGS_TABLE);
	return files;
};

const addInductionMinutesEffect = (action$, state$) =>
	action$.pipe(
		ofType(ADD_INDUCTION_MINUTES_FORM_SUBMIT),
		flatMap(fetchAttachments()),
		mergeMap((result) => {
			let attachments = R.clone(
				state$.value.addInductionMinutesFormReducer.attachments
			);
			attachments = replaceDataURLs(attachments, result);

			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/induction-minutes/createorupdate`,
					{
						id:
							state$.value.addInductionMinutesFormReducer.id ||
							uuid.v4(),
						siteId: state$.value.addInductionMinutesFormReducer
							.siteId,
						createdAt:
							state$.value.addInductionMinutesFormReducer
								.createdAt,
						people: filterPeople(
							state$.value.addInductionMinutesFormReducer.people
						),
						otherPeople:
							state$.value.addInductionMinutesFormReducer
								.otherPeople,
						hazards:
							state$.value.addInductionMinutesFormReducer.hazards,
						hazardousSubstances:
							state$.value.addInductionMinutesFormReducer
								.substances,
						attachments: attachments,
						notes: state$.value.addInductionMinutesFormReducer
							.notes,
						hazardNotes:
							state$.value.addInductionMinutesFormReducer
								.hazardNotes,
						appendNotes:
							state$.value.addInductionMinutesFormReducer
								.appendNotes,
						sendReminder:
							state$.value.addInductionMinutesFormReducer
								.sendReminder,
						recipients:
							state$.value.addInductionMinutesFormReducer
								.recipients,
						recipientsOther:
							state$.value.addInductionMinutesFormReducer
								.recipientsOther,
						sendEmail:
							state$.value.addInductionMinutesFormReducer
								.sendToRecipientsOnSave,
						latitude: state$.value.geolocation.latitude,
						longitude: state$.value.geolocation.longitude,
						timestamp: getCurrentTimestamp(),
						organisationId:
							state$.value.organisations.currentOrganisation,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							onInductionMinutesListRequest(),
							onAddInductionMinutesSaveSuccess(),
							onAddInductionMinutesReset(),
							push(TASK_INDUCTION_MINUTES_ROUTE)
						)
					),
					catchError((error) => of(onAddInductionMinutesSaveFailed()))
				);
		})
	);

export default addInductionMinutesEffect;
