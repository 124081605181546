import { connect } from "react-redux";
import Form from "./form";

import {
	onCloseEditHazardousSubstanceToSite,
	onAddHazardousSubstanceToSiteSave,
	onAddHazardousSubstanceToSiteValueChange,
} from "features/sites";

const mapStateToProps = ({ sites }, props) => ({
	siteId: props.siteId,
	values: sites.currentSite,
	isEditHazardousSubstanceToSiteModalShowing:
		sites.isEditHazardousSubstanceToSiteModalShowing,
});

const mapDispatchToProps = {
	onChange: onAddHazardousSubstanceToSiteValueChange,
	onSave: onAddHazardousSubstanceToSiteSave,
	onClose: onCloseEditHazardousSubstanceToSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
