import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { isNotNullOrEmpty, formatDateTime } from "utils";
import { TextClip } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	label: {
		color: "#999999",
	},
	link: {
		color: theme.palette.text.link,
	},
}));

const AppendNotesListing = ({
	appendNotes = [],
	title,
	numberOfItems,
	noTitle,
	customTypography,
}) => {
	const classes = useStyles();
	const [seeMore, setSeeMore] = useState(false);

	const showNumberOfRecords = numberOfItems ? numberOfItems : 1; // take first 1 if not specified in props
	const showSeeMore =
		isNotNullOrEmpty(appendNotes) &&
		appendNotes.length > showNumberOfRecords;

	const displayList = (list) => {
		return isNotNullOrEmpty(list)
			? list.map(({ createdAt, notes }, index) => (
					<Typography
						variant={customTypography ? customTypography : "h5"}
						key={`append-notes-listing-${index}`}
					>
						{index !== 0 && <br />}
						{formatDateTime(createdAt)}
						<br />
						<TextClip text={notes} numberOfCharacters={140} />
					</Typography>
			  ))
			: "-";
	};

	return (
		<div className={classes.root}>
			{!noTitle && (
				<Typography variant="body2" className={classes.label}>
					{title || "Appended Notes"}
				</Typography>
			)}
			{seeMore
				? displayList(appendNotes)
				: displayList(appendNotes.slice(0, showNumberOfRecords))}
			{/* eslint-disable-next-line */}
			{showSeeMore && (
				<a
					href="#"
					className={classes.link}
					onClick={() => setSeeMore(!seeMore)}
				>
					{seeMore ? "Show less notes" : "Show all notes"}
				</a>
			)}
		</div>
	);
};

AppendNotesListing.propTypes = {
	appendNotes: PropTypes.array.isRequired,
	title: PropTypes.string,
	numberOfItems: PropTypes.number,
	noTitle: PropTypes.bool,
	customTypography: PropTypes.string,
};

export default AppendNotesListing;
