import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clip from "text-clipper";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Card, CardContent, Typography, Grid, Chip } from "@material-ui/core";
import { formatDate, isValidContentState, createRowSelector } from "utils";
import { AttachmentListing, TextClip } from "frame/components";
import { capitalize } from "utils";
import SiteCrudOptions from "./site-crud-options";
import MUIRichTextEditor from "mui-rte";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
		"&:hover": {
			cursor: "pointer",
		},
	},
	container: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(3),
		},
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
	inactive: {
		background: theme.palette.background.tertiary,
	},
	valueInactive: {
		color: theme.palette.text.tertiary,
	},
}));

const SiteHazardCard = ({ hazard, currentSite, onDelete, onEdit, onView }) => {
	const classes = useStyles();
	const onRowSelect = createRowSelector(onView);
	const [currentHazard, setCurrentHazard] = useState(null);

	const hazardList = useSelector(
		({ healthSafety }) => healthSafety.hazards?.results
	);

	useEffect(() => {
		async function fetchData() {
			const data =
				hazardList &&
				hazardList.filter((h) => h.id === hazard.hazardId)[0];
			setCurrentHazard(data || null);
		}

		if (hazard.hazardId) {
			fetchData();
		} // eslint-disable-next-line
	}, [hazard, setCurrentHazard]);

	const isActive = String(hazard.status).toLowerCase() === "active";

	// const onDeleteHandler = () => {
	//   const hazards = currentSite.hazards.filter(hazard => hazard.hazardId !== hazard.id)
	//   onDelete({ ...currentSite, hazards })
	// }

	const onToggleStatus = (status) => () => {
		if (hazard) {
			const index = currentSite.hazards.indexOf(hazard);
			currentSite.hazards[index].status = status;
			onDelete({ ...currentSite });
		}
	};

	const isArchivedHazard = currentHazard && currentHazard.status !== "Active";

	const rootStyle = clsx(
		classes.root,
		isArchivedHazard ? classes.inactive : ""
	);

	const valueStyle = clsx(
		classes.value,
		isArchivedHazard ? classes.valueInactive : ""
	);

	return (
		<Card className={rootStyle} onClick={onRowSelect}>
			<CardContent className={classes.content}>
				<Grid className={classes.container} container spacing={2}>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Date
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentHazard
								? formatDate(currentHazard.createdAt)
								: "-"}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Name
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentHazard
								? clip(currentHazard.name, 140)
								: "-"}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Initial Risk Assessment
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentHazard ? (
								<TextClip
									text={currentHazard.initialRiskAssessment}
									numberOfCharacters={140}
								/>
							) : (
								"-"
							)}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Residual Risk Assessment
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentHazard ? (
								<TextClip
									text={currentHazard.residualRiskAssessment}
									numberOfCharacters={140}
								/>
							) : (
								"-"
							)}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Significant Risk
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentHazard && currentHazard.significantRisk
								? capitalize(currentHazard.significantRisk)
								: "-"}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Training Required
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentHazard && currentHazard.trainingRequired
								? capitalize(currentHazard.trainingRequired)
								: "-"}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							PPE Requirements
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentHazard &&
							isValidContentState(
								currentHazard.ppeRequirements
							) ? (
								<MUIRichTextEditor
									readOnly
									controls={[]}
									value={currentHazard.ppeRequirements}
								/>
							) : (
								"-"
							)}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Hazard Controls
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{currentHazard &&
							isValidContentState(
								currentHazard.hazardControls
							) ? (
								<MUIRichTextEditor
									readOnly
									controls={[]}
									value={currentHazard.hazardControls}
								/>
							) : (
								"-"
							)}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Location
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{hazard.location ? clip(hazard.location, 140) : "-"}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Status
						</Typography>
						<Typography variant="h5" className={valueStyle}>
							{hazard.status && !isArchivedHazard && (
								<Chip
									className={
										hazard.status === "Active"
											? classes.success
											: classes.error
									}
									size="small"
									label={hazard.status}
								/>
							)}
							{isArchivedHazard && (
								<Chip
									className={classes.error}
									size="small"
									label="Archived in hazard library"
								/>
							)}
							{!hazard.status && !isArchivedHazard && "-"}
						</Typography>
					</Grid>
					<Grid item md={3} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Files
						</Typography>
						{currentHazard ? (
							<AttachmentListing
								attachments={currentHazard.attachments}
							/>
						) : (
							"-"
						)}
					</Grid>

					<div className="ignore">
						{/* { ! isStaffUser(role) && ( */}
						{!isArchivedHazard && (
							<SiteCrudOptions
								onArchive={
									isActive ? onToggleStatus("Archived") : null
								}
								onMakeActive={
									!isActive ? onToggleStatus("Active") : null
								}
								onEdit={onEdit}
								onView={onView}
								// onDelete={onDeleteHandler}
							/>
						)}
					</div>
				</Grid>
			</CardContent>
		</Card>
	);
};

SiteHazardCard.propTypes = {
	hazard: PropTypes.object.isRequired,
	currentSite: PropTypes.object,
	onDelete: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	onView: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
};

export default SiteHazardCard;
