import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	formControl: {
		width: "100%",
	},
	formControlTight: {
		width: "100%",
		borderRadius: 4,
		border: "1px solid #ced4da",
		"&:focus": {
			borderRadius: 4,
			borderColor: "#80bdff",
			boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			border: "none !important",
			"& > div": {
				background: theme.palette.background.paper,
			},
		},
		"& label": {
			minWidth: "100px !important",
			maxWidth: "calc(100% - 20px)",
			background: theme.palette.background.paper,
		},
		"& .MuiInputBase-input": {
			background: "none !important",
		},
		"& .MuiOutlinedInput-input": {
			padding: theme.spacing(1.35, 2),
		},
		"& .MuiInputLabel-outlined": {
			transform: "translate(14px, 14px) scale(1)",
		},
		"& .MuiInputLabel-shrink": {
			transform: "translate(14px, -6px) scale(0.75)",
		},
	},
	break: {
		paddingTop: "2px !important",
		paddingBottom: "2px !important",
		minHeight: "0 !important",
		background: "transparent !important",
	},
	errorText: {
		color: theme.palette.error.main,
	},
}));

const getStyles = (name, selected, theme) => ({
	fontWeight:
		(selected === name) === -1
			? theme.typography.fontWeightRegular
			: theme.typography.fontWeightMedium,
});

const InputSelectSingle = ({
	id,
	label,
	options = [],
	tight,
	value = "",
	onChange,
	isSite,
	noNone,
	showError,
	...rest
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const [selected, setSelected] = useState(value);
	const [labelWidth, setLabelWidth] = useState(0);
	const labelRef = useRef();

	const onChangeSelection = (event) => onChange(event.target.value);

	useEffect(() => {
		setLabelWidth(labelRef.current.offsetWidth);
	}, []);

	useEffect(() => {
		setSelected(value);
	}, [value]);

	return (
		<div className={classes.root}>
			<FormControl
				className={clsx(
					classes.formControl,
					tight ? classes.formControlTight : ""
				)}
				variant="outlined"
			>
				<InputLabel
					ref={labelRef}
					htmlFor={`select-multiple-${id}`}
					className={showError ? classes.errorText : ""}
					{...rest}
				>
					{label}
				</InputLabel>
				<Select
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					error={showError}
					value={selected}
					onChange={onChangeSelection}
					labelWidth={labelWidth}
					inputProps={{
						id,
						name: label,
					}}
					{...rest}
				>
					{!noNone && (
						<MenuItem
							value={
								isSite
									? "00000000-0000-0000-0000-000000000000"
									: ""
							}
						>
							<em>None</em>
						</MenuItem>
					)}
					{options &&
						options.map((option, index) => {
							const name = option.label || option;
							const code = option.value || option;

							if (option.label && !option.value) {
								return (
									<MenuItem
										key={index}
										className={classes.break}
										disabled
										value=""
									>
										<em>{name}</em>
									</MenuItem>
								);
							}

							return (
								<MenuItem
									key={index}
									value={code}
									style={getStyles(code, selected, theme)}
								>
									{name}
								</MenuItem>
							);
						})}
				</Select>
			</FormControl>
		</div>
	);
};

InputSelectSingle.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	options: PropTypes.array.isRequired,
	tight: PropTypes.bool,
	value: PropTypes.string,
	onChange: PropTypes.func,
	isSite: PropTypes.bool,
	noNone: PropTypes.bool,
	showError: PropTypes.bool,
};

export default InputSelectSingle;
