import siteModel from "./site-model";

export default {
	currentSite: siteModel,
	isAddModalShowing: false,
	isEditModalShowing: false,
	isNew: false,
	isAddHazardToSiteModalShowing: false,
	isEditHazardToSiteModalShowing: false,
	isAddHazardousSubstanceToSiteModalShowing: false,
	isEditHazardousSubstanceToSiteModalShowing: false,
	searchFilter: "",
};
