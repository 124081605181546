import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardContent,
	Typography,
	Grid,
	CircularProgress,
	Button,
} from "@material-ui/core";
import { ScrollToTopOnMount, AddButton, PopupSnackbar } from "frame/components";
import {
	formatDate,
	useLoadedState,
	isStaffUser,
	useRouter,
	useCurrentUser,
	useCurrentOrganisation,
	dayDifference,
} from "utils";
import * as fromRoutes from "../../actions/types";
import { getWebsiteBaseUrl } from "frame";
import { Elements, StripeProvider } from "react-stripe-elements";
import AddPaymentModal from "./add-payment-form";
import SubscriptionModal from "./subscription-modal";
import { stripeHelper } from "utils/stripeHelper";
import { safetyWiseApi } from "utils/safetyWiseApi";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		backgroundColor: theme.palette.white,
		marginBottom: theme.spacing(2),
		position: "relative",
	},
	cardRoot: {
		position: "relative",
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	content: {
		padding: theme.spacing(1, 2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	label: {
		padding: theme.spacing(0, 0, 0.5, 0),
		color: "#999999",
	},
	value: {
		padding: theme.spacing(0, 0, 0.5, 0),
	},
	body: {
		margin: theme.spacing(7, 0, 2, 0),
		maxWidth: 800,
	},
	card: {
		margin: theme.spacing(6, 0, 2, 0),
	},
	icon: {
		width: 20,
		height: 20,
	},
	supportTitle: {
		marginTop: theme.spacing(5),
	},
	supportContact: {
		marginTop: theme.spacing(2),
	},
	link: {
		color: theme.palette.text.link,
	},
	addButton: {
		position: "absolute",
		top: 15,
		left: 25,
		[theme.breakpoints.down("xs")]: {
			position: "relative",
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(3),
			top: 0,
			left: 0,
		},
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	warningText: {
		color: theme.palette.error.main,
	},
	button: {
		padding: theme.spacing(1, 4),
		color: theme.palette.brand,
		background: theme.palette.highlight,
		borderColor: theme.palette.highlight,
		fontWeight: 600,
		"&:hover": {
			background: "#c1c72c",
			borderColor: "#c1c72c",
		},
		[theme.breakpoints.down("xs")]: {
			padding: theme.spacing(1, 2),
		},
	},
}));

const apiKey = stripeHelper.getApiKey();

/* eslint-disable */
const Subscription = ({
	subscription,
	role,
	onFetchSubscription,
	onOpenAddPaymentModal,
	onCloseAddPaymentModal,
	onCancelSubscription,
	currentOrganisationGUID,
}) => {
	const classes = useStyles();
	const currentUser = useCurrentUser();
	const currentOrganisationId = useCurrentOrganisation();
	const [openDeleteModal, setOpenDeleteModal] = useLoadedState(false);
	const { history } = useRouter();
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	if (isStaffUser(role)) {
		history.push(fromRoutes.ACCOUNT_PROFILE_PATH);
	}

	const handleApiError = (error) => {
		if (error.response && error.response.status === "401") {
			setErrorMessage("You are not authorized to perform this action.");
		} else if (error.response && error.response.status === "404") {
			setErrorMessage("The record no longer exists.");
			getDocumentPackList(paginationState);
		} else {
			setErrorMessage(GENERIC_ERROR_MESSAGE);
		}
	};

	useEffect(() => {
		onCloseAddPaymentModal(); // ensure modal is closed on subscription page load
		onFetchSubscription();
	}, []);

	// const daysRemaining = moment(subscription.startDate).add(30, 'days').diff(moment(), 'days')

	const hasValue = (x) => x && x !== "";

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "NZD",
	});

	return (
		<div className={classes.root}>
			<ScrollToTopOnMount />
			{subscription.flags.fetch !== "processing" &&
			currentUser.organisation &&
			subscription ? (
				<>
					<div className={classes.addButton}>
						<AddButton
							noIcon={!!subscription.last4}
							addTabTitle={
								hasValue(subscription.last4)
									? "Update Credit Card"
									: "Add Payment Method"
							}
							onAdd={onOpenAddPaymentModal}
						/>
					</div>

					<div className={classes.body}>
						<Card className={classes.cardRoot}>
							<CardContent className={classes.content}>
								<Grid
									className={classes.container}
									container
									spacing={2}
								>
									<Grid item md={6} sm={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
											Organisation Name
										</Typography>
										<Typography variant="h5">
											{currentUser.organisation}
										</Typography>
									</Grid>

									<Grid item md={6} sm={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
											Number of Active Users
										</Typography>
										<Typography variant="h5">
											{subscription.numberOfUsers ?? "-"}
										</Typography>
									</Grid>

									<Grid item md={6} sm={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
											Next Billing Amount
										</Typography>
										<Typography variant="h5">
											{subscription.nextBillingAmount
												? formatter.format(
														subscription.nextBillingAmount
												  )
												: "-"}
										</Typography>
									</Grid>

									<Grid item md={6} sm={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
											{subscription.isTrial
												? "Free Trial End Date"
												: subscription.cancelAtDate
												? "Subscription Expiry Date"
												: "Next Billing Date"}
										</Typography>
										<Typography
											className={classes.value}
											variant="h5"
										>
											{subscription.status == "canceled"
												? formatDate(
														subscription.canceledAtDate
												  )
												: formatDate(
														subscription.nextBillingDate
												  )}
										</Typography>
									</Grid>

									{/* <Grid item md={6} sm={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
											Next Billing Date
										</Typography>
										<Typography
											className={classes.value}
											variant="h5"
										>
											<span
												className={classes.warningText}
											>
												{`${subscription.daysRemaining} Days Left `}
											</span>
											{subscription.daysRemaining < 0 && (
												<Chip
													className={classes.error}
													size="small"
													label="Expired"
												/>
											)}
										</Typography>
									</Grid> */}

									<Grid item md={6} sm={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
											Credit Card
										</Typography>
										<Typography
											className={classes.value}
											variant="h5"
										>
											{subscription.last4
												? `**** **** **** ${subscription.last4} `
												: "-"}
										</Typography>
									</Grid>

									<Grid item md={6} sm={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
											Credit Card Expiry Date
										</Typography>
										<Typography
											className={classes.value}
											variant="h5"
										>
											{subscription.expiryYear &&
											subscription.expiryMonth
												? `${subscription.expiryMonth} / ${subscription.expiryYear}`
												: "-"}
										</Typography>
									</Grid>

									<Grid item md={6} sm={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
											Credit Card Type
										</Typography>
										<Typography
											className={classes.value}
											variant="h5"
										>
											{subscription.brand ? (
												<>
													{`${subscription.brand} `}
													{/* <svg {...getSvgProps({ type: subscription.brand.toLowerCase(), images })} /> */}
												</>
											) : (
												"-"
											)}
										</Typography>
									</Grid>

									<Grid item md={6} sm={6} xs={12}>
										<Typography
											className={classes.label}
											variant="body2"
										>
											Subscription Status
										</Typography>
										<Typography
											className={classes.value}
											variant="h5"
										>
											{subscription.isTrial
												? "Free Trial"
												: subscription.status ==
												  "canceled"
												? "Canceled"
												: subscription.status ==
												  "active"
												? "Active"
												: subscription.status ==
												  "inactive"
												? "Inactive"
												: "No valid payment method"}
											{subscription.isTrial ||
											(subscription.status == "active" &&
												subscription.cancelAtDate)
												? ` (${dayDifference(
														undefined,
														subscription.nextBillingDate
												  )} Days Remaining)`
												: null}
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</div>
					{subscription.status ==
					"inactive" ? null : subscription.cancelAtDate ||
					  subscription.canceledAtDate ? (
						<>
							{Date.now() <
							Date.parse(
								subscription.cancelAtDate ||
									subscription.canceledAtDate
							) ? (
								<Button
									className={classes.button}
									variant="contained"
									onClick={() => {
										setButtonDisabled(true);
										safetyWiseApi
											.post(
												`update-subscription-cancellation/${currentOrganisationGUID}`,
												{
													cancelAtPeriodEnd: false,
												}
											)
											.then(() => {
												onFetchSubscription();
												setButtonDisabled(false);
												setSuccessMessage(
													"Your subscription has been activated."
												);
											})
											.catch(handleApiError);
									}}
									disabled={buttonDisabled}
								>
									Don't Cancel
								</Button>
							) : (
								<Button
									className={classes.button}
									variant="contained"
									onClick={() => {
										setButtonDisabled(true);
										safetyWiseApi
											.post(
												`re-subscribe/${currentOrganisationGUID}`
											)
											.then(() => {
												onFetchSubscription();
												setButtonDisabled(false);
												setSuccessMessage(
													"Your have sucessfully re-subscribed."
												);
											})
											.catch(handleApiError);
									}}
									disabled={buttonDisabled}
								>
									Re-Subscribe
								</Button>
							)}
						</>
					) : (
						<Button
							className={classes.button}
							variant="contained"
							onClick={() => {
								setButtonDisabled(true);
								safetyWiseApi
									.post(
										`update-subscription-cancellation/${currentOrganisationGUID}`,
										{
											cancelAtPeriodEnd: true,
										}
									)
									.then(() => {
										onFetchSubscription();
										setButtonDisabled(false);
										setSuccessMessage(
											"Your subscription has been terminated."
										);
									})
									.catch(handleApiError);
							}}
							disabled={buttonDisabled}
						>
							Cancel Subscription
						</Button>
					)}
					<PopupSnackbar
						message={errorMessage}
						coloured
						error
						onExited={() => setErrorMessage("")}
					/>
					<PopupSnackbar
						message={successMessage}
						coloured
						success
						onExited={() => setSuccessMessage("")}
					/>
				</>
			) : (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
						minHeight: 320,
					}}
				>
					<CircularProgress />
				</div>
			)}

			<div>
				<Typography className={classes.supportTitle} variant="h4">
					Subscription support <br />
				</Typography>
				<Typography className={classes.supportContact} variant="body1">
					Click{" "}
					<a
						className={classes.link}
						href={`${getWebsiteBaseUrl()}/try-buy/`}
						target="_blank"
					>
						here
					</a>{" "}
					to see how subscription costs are calculated. <br />
					Email the support team at{" "}
					<a
						className={classes.link}
						href="mailto:info@fortherecord.pro"
					>
						info@fortherecord.pro
					</a>{" "}
					for further assistance.
				</Typography>
			</div>

			{/* Confirm delete modal */}
			<SubscriptionModal
				open={openDeleteModal}
				setOpen={setOpenDeleteModal}
				onDelete={() => onCancelSubscription(currentOrganisationId)}
			/>

			{/* Add payment modal */}
			<StripeProvider apiKey={apiKey}>
				<Elements>
					<AddPaymentModal
						setSuccessMessage={(message) =>
							setSuccessMessage(message)
						}
						setErrorMessage={(message) => setErrorMessage(message)}
						isUpdate={!!subscription.last4}
					/>
				</Elements>
			</StripeProvider>
			<PopupSnackbar
				message={errorMessage}
				coloured
				error
				onExited={() => setErrorMessage("")}
			/>
			<PopupSnackbar
				message={successMessage}
				coloured
				success
				onExited={() => setSuccessMessage("")}
			/>
		</div>
	);
};
/* eslint-enable */

Subscription.propTypes = {
	subscription: PropTypes.object.isRequired,
	role: PropTypes.string.isRequired,
	staffList: PropTypes.array.isRequired,
	onOpenAddPaymentModal: PropTypes.func.isRequired,
	onCloseAddPaymentModal: PropTypes.func.isRequired,
	onCancelSubscription: PropTypes.func.isRequired,
};

export default Subscription;
