import { connect } from "react-redux";
import JobList from "./jobs-list";
import {
	onEdit,
	onAdd,
	onDelete,
	onSearchChanged,
	onPagedListRequested,
} from "../actions";

const mapStateToProps = ({ jobs, profile, queue, organisations }) => ({
	isAddModalShowing: jobs.isAddModalShowing,
	currentUserId: profile.id,
	role: profile.role,
	hasNetwork: queue.hasNetwork,
	pagination: jobs.pagination,
	searchFilter: jobs.searchFilter,
	flags: jobs.flags,
	currentOrganisationGUID: organisations.currentOrganisation,
});

const mapDispatchToProps = {
	onAdd,
	onEdit,
	onDelete,
	onSearchChanged,
	onGetJobList: onPagedListRequested,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobList);
