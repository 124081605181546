import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";
import { isNotNullOrEmpty, isDefaultGuid } from "utils";
import StaffMultiSelectDropdown from "./staff-multiselect-dropdown";
import StaffRoleSelectTable from "./staff-role-select-table";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	errorText: {
		color: theme.palette.error.main,
	},
}));

const PeopleForm = ({
	className,
	values,
	onChange,
	customOptions,
	disabled,
	customRoleName,
	defaultStaffRole,
	showInvalidPeopleError,
}) => {
	const classes = useStyles();
	const disabledField = disabled || values.save.saving;

	const staffList = useSelector(({ staff }) => staff.pagination?.results);

	const [selectedStaff, setSelectedStaff] = useState(
		isNotNullOrEmpty(values.people)
			? values.people
					.filter((p) => !isDefaultGuid(p.id) && p.role)
					.map((c) => ({
						label: c.name,
						value: c.id,
						role: c.role,
						checked: true,
					}))
			: []
	);

	const staffOptions =
		staffList &&
		staffList
			.filter((staff) => staff.status === "Active") // show active staff users only
			.map(({ id, fullName, role }) => ({
				label: fullName,
				value: id,
				role: defaultStaffRole ? defaultStaffRole : "", // empty by default for user to select, staff unchecked or without roles are ignored in the post effect
				checked: true,
			}))
			.sort((a, b) => (a.label > b.label ? 1 : -1));

	const onPeopleChange = (
		people // used inside StaffRoleSelectTable & StaffMultiSelectDropdown
	) => onChange({ ...values, people });

	return (
		<>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<StaffMultiSelectDropdown
						data-cy="staff-multi-select-dropdown"
						disabled={disabledField}
						options={staffOptions}
						label="Add staff/user"
						value={selectedStaff}
						setSelectedStaff={setSelectedStaff}
						onChange={onPeopleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					{showInvalidPeopleError && (
						<Typography
							className={classes.errorText}
							variant="body1"
							align="right"
						>
							Please select a role for each checked participant.
						</Typography>
					)}

					<StaffRoleSelectTable
						showInvalidPeopleError={showInvalidPeopleError}
						people={selectedStaff}
						onChange={onPeopleChange}
						customOptions={customOptions}
					/>
				</Grid>
			</Grid>
		</>
	);
};

PeopleForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	customOptions: PropTypes.array,
	disabled: PropTypes.bool,
	customRoleName: PropTypes.string,
	defaultStaffRole: PropTypes.string,
	showInvalidPeopleError: PropTypes.bool,
};

export default PeopleForm;
