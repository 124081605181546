export const INFO_ROUTE = `/info`;
export const TASK_ROUTE = `/tasks`;

// info routes
export const ADD_HAZARD_ROUTE = `${INFO_ROUTE}/add-hazard`;
export const ADD_HAZARD_PATH = `${INFO_ROUTE}/add-hazard`;

export const ADD_HAZARDOUS_SUBSTANCE_ROUTE = `${INFO_ROUTE}/add-hazardous-substance`;
export const ADD_HAZARDOUS_SUBSTANCE_PATH = `${INFO_ROUTE}/add-hazardous-substance`;

export const ADD_STAFF_TRAINING_TOPIC_ROUTE = `${INFO_ROUTE}/add-staff-training-topic`;
export const ADD_STAFF_TRAINING_TOPIC_PATH = `${INFO_ROUTE}/add-staff-training-topic`;

export const ADD_JSA_ROUTE = `${INFO_ROUTE}/add-job-safety-analysis`;
export const ADD_JSA_PATH = `${INFO_ROUTE}/add-job-safety-analysis`;

export const ADD_SAFETY_PLAN_CHECKLIST_ROUTE = `${INFO_ROUTE}/add-safety-plan-checklist`;
export const ADD_SAFETY_PLAN_CHECKLIST_PATH = `${INFO_ROUTE}/add-safety-plan-checklist`;

export const ADD_SAFETY_MINUTES_ROUTE = `${TASK_ROUTE}/add-safety-minutes`;
export const ADD_SAFETY_MINUTES_PATH = `${TASK_ROUTE}/add-safety-minutes`;

export const ADD_INCIDENT_ROUTE = `${TASK_ROUTE}/add-incident`;
export const ADD_INCIDENT_PATH = `${TASK_ROUTE}/add-incident`;

export const ADD_INDUCTION_MINUTES_ROUTE = `${TASK_ROUTE}/add-induction-meeting`;
export const ADD_INDUCTION_MINUTES_PATH = `${TASK_ROUTE}/add-induction-meeting`;
export const INDUCTION_MINUTES_CONFIRM_ATTENDANCE_ROUTE = `${TASK_ROUTE}/induction-minutes/:id/confirm-attendance/:staffid`;

export const ADD_STAFF_TRAINING_ROUTE = `${TASK_ROUTE}/add-staff-training`;
export const ADD_STAFF_TRAINING_PATH = `${TASK_ROUTE}/add-staff-training`;

// **********************************************************************************
// old routes
// **********************************************************************************

export const HEALTH_SAFETY_ROUTE = `/health-safety`;
export const HEALTH_SAFETY_PATH = `/health-safety`;

// Add Accident
export const OLD_ADD_INCIDENT_PATH = `${HEALTH_SAFETY_ROUTE}/add-incident`;
export const OLD_ADD_INCIDENT_ROUTE = `${HEALTH_SAFETY_ROUTE}/add-incident`;

export const ADD_INCIDENT_FORM_VALUE_CHANGE = `ADD_INCIDENT_FORM_VALUE_CHANGE`;
export const ADD_INCIDENT_FORM_RESET = `ADD_INCIDENT_FORM_RESET`;
export const ADD_INCIDENT_FORM_SUBMIT = `ADD_INCIDENT_FORM_SUBMIT`;
export const ADD_INCIDENT_FORM_SUBMIT_FAILED = `ADD_INCIDENT_FORM_SUBMIT_FAILED`;
export const ADD_INCIDENT_FORM_SUBMIT_SUCCESS = `ADD_INCIDENT_FORM_SUBMIT_SUCCESS`;

// Add Hazard
export const OLD_ADD_HAZARD_PATH = `${HEALTH_SAFETY_ROUTE}/add-hazard`;
export const OLD_ADD_HAZARD_ROUTE = `${HEALTH_SAFETY_ROUTE}/add-hazard`;

export const ADD_HAZARD_FORM_VALUE_CHANGE = `ADD_HAZARD_FORM_VALUE_CHANGE`;
export const ADD_HAZARD_FORM_RESET = `ADD_HAZARD_FORM_RESET`;
export const ADD_HAZARD_FORM_SUBMIT = `ADD_HAZARD_FORM_SUBMIT`;
export const ADD_HAZARD_FORM_SUBMIT_FAILED = `ADD_HAZARD_FORM_SUBMIT_FAILED`;
export const ADD_HAZARD_FORM_SUBMIT_SUCCESS = `ADD_HAZARD_FORM_SUBMIT_SUCCESS`;
export const ARCHIVED_HAZARD = `ARCHIVED_HAZARD`;

// Add Hazardous Substance
export const OLD_ADD_HAZARDOUS_SUBSTANCE_PATH = `${HEALTH_SAFETY_ROUTE}/add-hazardous-substance`;
export const OLD_ADD_HAZARDOUS_SUBSTANCE_ROUTE = `${HEALTH_SAFETY_ROUTE}/add-hazardous-substance`;

export const ADD_HAZARDOUS_SUBSTANCE_FORM_VALUE_CHANGE = `ADD_HAZARDOUS_SUBSTANCE_FORM_VALUE_CHANGE`;
export const ADD_HAZARDOUS_SUBSTANCE_FORM_RESET = `ADD_HAZARDOUS_SUBSTANCE_FORM_RESET`;
export const ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT = `ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT`;
export const ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT_FAILED = `ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT_FAILED`;
export const ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT_SUCCESS = `ADD_HAZARDOUS_SUBSTANCE_FORM_SUBMIT_SUCCESS`;
export const ARCHIVED_HAZARDOUS_SUBSTANCE = `ARCHIVED_HAZARDOUS_SUBSTANCE`;

// Add Safety Plan Checklist
export const OLD_ADD_SAFETY_PLAN_CHECKLIST_PATH = `${HEALTH_SAFETY_ROUTE}/add-safety-plan-checklist`;
export const OLD_ADD_SAFETY_PLAN_CHECKLIST_ROUTE = `${HEALTH_SAFETY_ROUTE}/add-safety-plan-checklist`;

export const ADD_SAFETY_PLAN_CHECKLIST_FORM_VALUE_CHANGE = `ADD_SAFETY_PLAN_CHECKLIST_FORM_VALUE_CHANGE`;
export const ADD_SAFETY_PLAN_CHECKLIST_FORM_RESET = `ADD_SAFETY_PLAN_CHECKLIST_FORM_RESET`;
export const ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT = `ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT`;
export const ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT_FAILED = `ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT_FAILED`;
export const ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT_SUCCESS = `ADD_SAFETY_PLAN_CHECKLIST_FORM_SUBMIT_SUCCESS`;

// Add Job Safety Analysis
export const OLD_ADD_JSA_PATH = `${HEALTH_SAFETY_ROUTE}/add-job-safety-analysis`;
export const OLD_ADD_JSA_ROUTE = `${HEALTH_SAFETY_ROUTE}/add-job-safety-analysis`;

export const ADD_JSA_FORM_VALUE_CHANGE = `ADD_JSA_FORM_VALUE_CHANGE`;
export const ADD_JSA_FORM_RESET = `ADD_JSA_FORM_RESET`;
export const ADD_JSA_FORM_SUBMIT = `ADD_JSA_FORM_SUBMIT`;
export const ADD_JSA_FORM_SUBMIT_FAILED = `ADD_JSA_FORM_SUBMIT_FAILED`;
export const ADD_JSA_FORM_SUBMIT_SUCCESS = `ADD_JSA_FORM_SUBMIT_SUCCESS`;

// Add Workplace Induction Meetings
export const OLD_ADD_INDUCTION_MINUTES_PATH = `${HEALTH_SAFETY_ROUTE}/add-induction-meeting`;
export const OLD_ADD_INDUCTION_MINUTES_ROUTE = `${HEALTH_SAFETY_ROUTE}/add-induction-meeting`;

// confirm induction meeting attendance route
export const OLD_INDUCTION_MINUTES_CONFIRM_ATTENDANCE_ROUTE = `${HEALTH_SAFETY_ROUTE}/induction-minutes/:id/confirm-attendance/:staffid`;

export const ADD_INDUCTION_MINUTES_FORM_VALUE_CHANGE = `ADD_INDUCTION_MINUTES_FORM_VALUE_CHANGE`;
export const ADD_INDUCTION_MINUTES_FORM_RESET = `ADD_INDUCTION_MINUTES_FORM_RESET`;
export const ADD_INDUCTION_MINUTES_FORM_SUBMIT = `ADD_INDUCTION_MINUTES_FORM_SUBMIT`;
export const ADD_INDUCTION_MINUTES_FORM_SUBMIT_FAILED = `ADD_INDUCTION_MINUTES_FORM_SUBMIT_FAILED`;
export const ADD_INDUCTION_MINUTES_FORM_SUBMIT_SUCCESS = `ADD_INDUCTION_MINUTES_FORM_SUBMIT_SUCCESS`;

// Add Safety Minutes
export const OLD_ADD_SAFETY_MINUTES_PATH = `${HEALTH_SAFETY_ROUTE}/add-safety-minutes`;
export const OLD_ADD_SAFETY_MINUTES_ROUTE = `${HEALTH_SAFETY_ROUTE}/add-safety-minutes`;

export const ADD_SAFETY_MINUTES_FORM_VALUE_CHANGE = `ADD_SAFETY_MINUTES_FORM_VALUE_CHANGE`;
export const ADD_SAFETY_MINUTES_FORM_RESET = `ADD_SAFETY_MINUTES_FORM_RESET`;
export const ADD_SAFETY_MINUTES_FORM_SUBMIT = `ADD_SAFETY_MINUTES_FORM_SUBMIT`;
export const ADD_SAFETY_MINUTES_FORM_SUBMIT_FAILED = `ADD_SAFETY_MINUTES_FORM_SUBMIT_FAILED`;
export const ADD_SAFETY_MINUTES_FORM_SUBMIT_SUCCESS = `ADD_SAFETY_MINUTES_FORM_SUBMIT_SUCCESS`;

// Add Staff Training
export const OLD_ADD_STAFF_TRAINING_PATH = `${HEALTH_SAFETY_ROUTE}/add-staff-training`;
export const OLD_ADD_STAFF_TRAINING_ROUTE = `${HEALTH_SAFETY_ROUTE}/add-staff-training`;

export const ADD_STAFF_TRAINING_FORM_VALUE_CHANGE = `ADD_STAFF_TRAINING_FORM_VALUE_CHANGE`;
export const ADD_STAFF_TRAINING_FORM_RESET = `ADD_STAFF_TRAINING_FORM_RESET`;
export const ADD_STAFF_TRAINING_FORM_SUBMIT = `ADD_STAFF_TRAINING_FORM_SUBMIT`;
export const ADD_STAFF_TRAINING_FORM_SUBMIT_FAILED = `ADD_STAFF_TRAINING_FORM_SUBMIT_FAILED`;
export const ADD_STAFF_TRAINING_FORM_SUBMIT_SUCCESS = `ADD_STAFF_TRAINING_FORM_SUBMIT_SUCCESS`;

// Add Staff Training Topic
export const OLD_ADD_STAFF_TRAINING_TOPIC_PATH = `${HEALTH_SAFETY_ROUTE}/add-staff-training-topic`;
export const OLD_ADD_STAFF_TRAINING_TOPIC_ROUTE = `${HEALTH_SAFETY_ROUTE}/add-staff-training-topic`;

export const ADD_STAFF_TRAINING_TOPIC_FORM_VALUE_CHANGE = `ADD_STAFF_TRAINING_TOPIC_FORM_VALUE_CHANGE`;
export const ADD_STAFF_TRAINING_TOPIC_FORM_RESET = `ADD_STAFF_TRAINING_TOPIC_FORM_RESET`;
export const ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT = `ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT`;
export const ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT_FAILED = `ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT_FAILED`;
export const ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT_SUCCESS = `ADD_STAFF_TRAINING_TOPIC_FORM_SUBMIT_SUCCESS`;
