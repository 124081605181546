import { connect } from "react-redux";
import Form from "./form";

import {
	onCloseEditHazardToSite,
	onAddHazardToSiteValueChange,
	onAddHazardToSiteSave,
} from "features/sites";

const mapStateToProps = ({ sites }, props) => ({
	siteId: props.siteId,
	values: sites.currentSite,
	isEditHazardToSiteModalShowing: sites.isEditHazardToSiteModalShowing,
});

const mapDispatchToProps = {
	onChange: onAddHazardToSiteValueChange,
	onSave: onAddHazardToSiteSave,
	onClose: onCloseEditHazardToSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
