import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	SelectInput,
} from "frame/components";
import {
	Card,
	CardContent,
	Grid,
	Typography,
	Modal,
} from "@material-ui/core";
import clsx from "clsx";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
} from "frame/components";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
	modalCard: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	modalGridContainer: {
		marginTop: theme.spacing(3),
	},
	actions: {
		marginTop: theme.spacing(5),
		justifyContent: "flex-end",
	},
}));

const TaskTypeModal = ({
	taskType,
	isOpen,
	onSubmit,
	onClose
}) => {
	const classes = useStyles();
	const [taskTypeForm, setTaskTypeForm] = useState();

	useEffect(() => {
		setTaskTypeForm(taskType || {
			taskType: "",
			active: true
		});
	}, [taskType]);

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
		>
			<Card
				className={clsx(classes.modalCard, undefined)}
			>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={() => {
						onSubmit(taskTypeForm);
					}}
				>
					<CardContent>
						<Typography
							align="center"
							gutterBottom
							variant="h3"
						>
							{taskType ? "Edit" : "Add"}{" "}
							Task Type
						</Typography>
						<Grid
							className={
								classes.modalGridContainer
							}
							container
							spacing={3}
						>
							<Grid item md={6} xs={12}>
								<TextValidator
									fullWidth
									label="Task Type *"
									name="taskType"
									value={taskTypeForm?.taskType || ""}
									variant="outlined"
									validators={["required"]}
									errorMessages={[
										"This field is required",
									]}
									onChange={(e) => {
										setTaskTypeForm(
											{
												...taskTypeForm,
												taskType: e.target.value
											}
										);
									}}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<SelectInput
									noNone
									id="status"
									name="status"
									label="Status"
									options={[
										"Active",
										"Inactive",
									]}
									value={
										taskTypeForm?.active || !taskType ? "Active" : "Inactive"
									}
									disabled={
										taskType
											? false
											: true
									}
									onChange={(e) => {
										setTaskTypeForm({
											...taskTypeForm,
											active: e === "Active" ? true : false
										});
									}}
								/>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item md={6} xs={12}>
								<FormActions
									className={classes.actions}
								>
									<FormSubmitButton
										disabled={false}
									>
										Save
									</FormSubmitButton>
									<FormButton
										onClick={() => onClose()}
									>
										Cancel
									</FormButton>
								</FormActions>
							</Grid>
						</Grid>
					</CardContent>
				</ValidatorForm>
			</Card>
		</Modal>
	);
};

TaskTypeModal.propTypes = {
	taskType: PropTypes.object,
	isOpen: PropTypes.bool.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default TaskTypeModal;
