import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import {
	formatDate,
	createRowSelector,
	isStaffUser,
	isNotNullOrEmpty,
} from "utils";
import { CrudOptions } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
		"&:hover": {
			cursor: "pointer",
		},
	},
	container: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(3),
		},
	},
	label: {
		color: "#999999",
	},
	subHeading: {
		fontWeight: "bold",
	},
}));

const JobSafetyAnalysisCard = ({
	jobSafetyAnalysis,
	onEdit,
	onDelete,
	role,
	onClone,
	onEmail,
	onView,
}) => {
	const classes = useStyles();
	const onRowSelect = createRowSelector(onView);

	const id = {
		id: jobSafetyAnalysis.id,
		form: "job-safety-analysis",
	};

	const isValid = (x) => x && x !== "";

	const numberOfTasks = isNotNullOrEmpty(jobSafetyAnalysis.tasks)
		? jobSafetyAnalysis.tasks.filter((j) => isValid(j.name)).length
		: 0;

	return (
		<Card className={classes.root} onClick={onRowSelect}>
			<CardContent className={classes.content}>
				<Grid className={classes.container} container spacing={2}>
					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Name
						</Typography>
						<Typography variant="h5">
							{jobSafetyAnalysis.name || ""}
						</Typography>
					</Grid>

					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Created
						</Typography>
						<Typography variant="h5">
							{formatDate(jobSafetyAnalysis.createdAt)}
						</Typography>
					</Grid>

					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Tasks
						</Typography>
						<Typography variant="h5">
							{numberOfTasks || 0}
						</Typography>
					</Grid>

					<Grid item md={3} xs={6}>
						<Typography variant="body2" className={classes.label}>
							Attachments
						</Typography>
						<Typography variant="h5">
							{jobSafetyAnalysis.attachments &&
								jobSafetyAnalysis.attachments.length}
						</Typography>
					</Grid>

					<div className="ignore">
						{!isStaffUser(role) && (
							<CrudOptions
								onEdit={onEdit}
								onDelete={() => onDelete(id)}
								onClone={onClone}
								onEmail={onEmail}
							/>
						)}
					</div>
				</Grid>
			</CardContent>
		</Card>
	);
};

JobSafetyAnalysisCard.propTypes = {
	jobSafetyAnalysis: PropTypes.object.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired,
	onClone: PropTypes.func.isRequired,
	onEmail: PropTypes.func.isRequired,
	onView: PropTypes.func.isRequired,
};

export default JobSafetyAnalysisCard;
