import * as fromTypes from "../actions/types";
import * as R from "ramda";
import { defaultFetchState, defaultFlagState, updateFlags } from "utils";

export const defaultState = {
	...defaultFetchState,
	currentOrganisation: "",
	data: [],
	filteredOrganisationList: [],
	isEditModalShowing: false,
	editOrganisationForm: {},
	searchFilter: "",
	filter: "All",
	flags: defaultFlagState,
};

const applyFilters = (state) => {
	let listData = state.data;

	if (state.filter === "All") {
		listData = state.data;
	} else {
		let list = state.data;
		listData = list.filter((org) =>
			state.filter === "Active" ? org.status : !org.status
		);
	}

	if (!state.searchFilter || state.searchFilter === "") {
	} else {
		let list = listData;
		list.id = undefined;
		let term = state.searchFilter;
		listData = list.filter((item) =>
			JSON.stringify(item).toLowerCase().includes(term.toLowerCase())
		);
	}
	return listData;
};

const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case fromTypes.ORGANISATION_RESET:
			return defaultState;

		// fetch org list
		case fromTypes.ORGANISATION_FETCH_REQUESTED:
			return updateFlags(R.clone(state), { fetch: "processing" });
		case fromTypes.ORGANISATION_FETCH_FAILED:
			return updateFlags(R.clone(state), { fetch: "error" });
		case fromTypes.ORGANISATION_FETCH_SUCCESS:
			return organisationFetchSuccess(R.clone(state), action);

		// refetch org list
		case fromTypes.ORGANISATION_REFETCH_LIST:
			return organisationRefetch(R.clone(state), action);
		case fromTypes.ORGANISATION_REFETCH_LIST_FAILED:
			return updateFlags(R.clone(state), { fetch: "error" });
		case fromTypes.ORGANISATION_REFETCH_LIST_SUCCESS:
			return organisationRefetchSuccess(R.clone(state), action);

		case fromTypes.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED:
			return currentOrganisationChanged(R.clone(state), action);

		case fromTypes.ORGANISATIONS_EDIT_CLOSE:
			return organisationEditClose(R.clone(state), action);
		case fromTypes.ORGANISATIONS_EDIT_NAME_CHANGED:
			return organisationEditNameChanged(R.clone(state), action);

		// edit org
		case fromTypes.ORGANISATIONS_EDIT:
			return organisationEdit(R.clone(state), action);
		case fromTypes.ORGANISATIONS_EDIT_SAVE_FAILED:
			return updateFlags(R.clone(state), { update: "error" });
		case fromTypes.ORGANISATIONS_EDIT_SAVE_SUCCESS:
			return organisationEditSaveSuccess(R.clone(state), action);

		// search
		case fromTypes.ORGANISATION_SEARCH_CHANGED:
			return searchChanged(R.clone(state), action);

		// filters
		case fromTypes.ORGANISATION_STATUS_FILTER:
			return filterStatus(R.clone(state), action);
		default:
			return state;
	}
};

const organisationFetchSuccess = (state, { payload }) => {
	if (payload && payload.length > 0) {
		const isLastVisited = payload.filter((o) => o.isLastVisited)[0];
		let lastVisitedOrganisation = isLastVisited
			? isLastVisited.id
			: payload[0].id; // fallback to first org

		state.currentOrganisation = lastVisitedOrganisation;
	}
	state.data = payload;
	state.filteredOrganisationList = applyFilters(state);

	return updateFlags(state, { fetch: "done" });
};

const organisationRefetch = (state, { payload }) => {
	state.filter = "All";
	state.searchFilter = "";
	state.filteredOrganisationList = applyFilters(state);
	return updateFlags(state, { fetch: "processing" });
};

const organisationRefetchSuccess = (state, { payload }) => {
	state.data = payload;
	state.filteredOrganisationList = applyFilters(state);
	return updateFlags(state, { fetch: "done" });
};

const currentOrganisationChanged = (state, action) => {
	state.currentOrganisation = action.payload;
	return state;
};

const organisationEdit = (state, action) => {
	state.isEditModalShowing = true;
	state.editOrganisationForm = R.clone(
		R.find(R.propEq("id", action.payload), state.data)
	);
	return updateFlags(state, { update: "processing" });
};

const organisationEditClose = (state, action) => {
	state.isEditModalShowing = false;
	return state;
};

const organisationEditNameChanged = (state, action) => {
	state.editOrganisationForm.name = action.payload;
	return state;
};

const organisationEditSaveSuccess = (state, action) => {
	state.filteredOrganisationList = applyFilters(state);
	return updateFlags(state, { update: "done" });
};

const searchChanged = (state, action) => {
	state.searchFilter = action.payload;
	state.filteredOrganisationList = applyFilters(state);
	return state;
};

const filterStatus = (state, action) => {
	state.filter = action.payload;
	state.filteredOrganisationList = applyFilters(state);
	return state;
};

export default reducer;
