import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Divider, Grid, Typography } from "@material-ui/core";

import {
	Feedback,
	FormActions,
	FormSubmitButton,
	ScrollToTopOnMount,
} from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 3, 10, 3),
		backgroundColor: theme.palette.white,
	},
	body: {
		margin: theme.spacing(3, 0, 2, 0),
		maxWidth: 540,
		"& > *": {
			paddingLeft: "0 !important",
			paddingRight: "0 !important",
		},
	},
	divider: {
		margin: theme.spacing(3, 0, 1, 0),
	},
}));

const defaultValues = {
	password: "",
	newPassword: "",
	confirmPassword: "",
};

const Password = ({ save, onSave, responseMessage }) => {
	const classes = useStyles();
	const [values, setValues] = useState(defaultValues);
	const form = useRef();

	const { done, error, saving } = save;
	const saveFailed = !!(done && error);
	const saveSuccess = !!(done && !error);

	useEffect(() => {
		if (done && !error) {
			setValues(defaultValues);
		}
	}, [done, error]);

	useEffect(() => {
		ValidatorForm.addValidationRule(
			"isPasswordMatch",
			(value) => value === values.newPassword
		);

		return () => {
			ValidatorForm.removeValidationRule("isPasswordMatch");
		};
	}, [values.newPassword]);

	const onSubmit = () => {
		onSave(values);
		setValues(defaultValues);
		form.current.resetValidations();
	};

	const onChange = (key) => (event) => {
		setValues({
			...values,
			[key]: event.target.value,
		});
	};

	const updateSuccess =
		responseMessage && responseMessage.includes("success");

	return (
		<div className={classes.root}>
			<ScrollToTopOnMount />
			<ValidatorForm
				autoComplete="off"
				noValidate
				ref={form}
				onSubmit={onSubmit}
			>
				<Typography variant="h2">Change Password</Typography>
				<Grid className={classes.body} container spacing={2}>
					<Grid item xs={12}>
						<Feedback active={saveFailed} color="error">
							{responseMessage && responseMessage}
						</Feedback>
						<Feedback
							active={saveSuccess}
							color={updateSuccess ? "success" : "error"}
						>
							{responseMessage && responseMessage}
						</Feedback>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							id={"password"}
							disabled={saving}
							fullWidth
							label="Current Password"
							type="password"
							value={values.password || ""}
							variant="outlined"
							validators={["required"]}
							errorMessages={["This field is required"]}
							onChange={onChange("password")}
						/>
						<Divider className={classes.divider} />
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							id={"newPassword"}
							disabled={saving || !values.password}
							fullWidth
							label="New Password"
							type="password"
							value={values.newPassword || ""}
							variant="outlined"
							validators={["required", "minStringLength:8"]}
							errorMessages={[
								"This field is required",
								"Must be at least 8 characters",
							]}
							onChange={onChange("newPassword")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							id={"confirmPassword"}
							disabled={saving || !values.password}
							fullWidth
							label="Confirm Password"
							value={values.confirmPassword || ""}
							type="password"
							variant="outlined"
							validators={["required", "isPasswordMatch"]}
							errorMessages={[
								"This field is required",
								"Password must match",
							]}
							onChange={onChange("confirmPassword")}
						/>
					</Grid>
				</Grid>
				<FormActions>
					<FormSubmitButton loading={saving}>
						Save New Password
					</FormSubmitButton>
				</FormActions>
			</ValidatorForm>
		</div>
	);
};

Password.propTypes = {
	save: PropTypes.exact({
		done: PropTypes.bool,
		error: PropTypes.string,
		saving: PropTypes.bool,
		success: PropTypes.bool,
	}),
	onSave: PropTypes.func.isRequired,
	responseMessage: PropTypes.string,
};

export default Password;
