import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, delay, filter, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import { tokenExists, getOrganisationBaseUrl, authHeaders } from "frame";
import * as fromOrganisations from "features/organisations";
import * as fromActions from "../actions";
import fromTypes from "../actions/types";
import ReactGA from "react-ga";
import { isProd } from "utils";

export const profileHydrationEffect = (action$, state$) =>
	action$.pipe(
		ofType(
			fromOrganisations.ORGANISATION_FETCH_SUCCESS,
			fromOrganisations.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED
		),
		mergeMap(() => of(fromActions.profileFetch()))
	);

export const profileLoginEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.PROFILE_FETCH),
		filter(() => tokenExists()),
		mergeMap(() =>
			ajax
				.get(
					`${getOrganisationBaseUrl(state$.value)}/profile`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							fromActions.profileFetchSuccess(
								ajaxResponse.response
							)
						)
					),
					catchError((error) =>
						of(fromActions.profileFetchFailed(error.message))
					)
				)
		)
	);

export const profileSaveEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.PROFILE_SAVE),
		filter(() => tokenExists()),
		delay(1000),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(state$.value)}/profile/update`,
					{
						id: state$.value.profile.id,
						firstName: state$.value.profile.firstName,
						lastName: state$.value.profile.lastName,
						phone: state$.value.profile.phone,
						email: state$.value.profile.email,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						isProd &&
							ReactGA.event({
								category: "My Account",
								action: "Save Clicked",
								label: "Profile",
							});
						return of(
							fromActions.profileSaveSuccess(
								ajaxResponse.response
							)
						);
					}),
					catchError((error) =>
						of(fromActions.profileSaveFailed(error.message))
					)
				)
		)
	);
