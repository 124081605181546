import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Home } from "./home";
import UncomfirmedAttendance from "./uncomfirmed-attendance";
import { connect } from "react-redux";
import {
	onClearSearch,
	// Info
	onHazardListRequest,
	onHazardousSubstanceListRequest,
	onStaffTrainingTopicListRequest,
	onJobSafetyAnalysisListRequest,
	onSafetyPlanChecklistListRequest,
	// Tasks
	onSafetyMinutesListRequest,
	onIncidentListRequest,
	onInductionMinutesListRequest,
	onStaffTrainingListRequest,
} from "features/health-and-safety/actions";
import { UNCOMFIRMED_ATTENDANCE_ROUTE } from "../home-actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
	onClearSearch: onClearSearch,
	// Info
	onGetHazardsList: onHazardListRequest,
	onGetHazardousSubstanceList: onHazardousSubstanceListRequest,
	onGetStaffTrainingTopicList: onStaffTrainingTopicListRequest,
	onGetJobSafetyAnalysisList: onJobSafetyAnalysisListRequest,
	onGetSafetyPlanChecklistList: onSafetyPlanChecklistListRequest,
	// Tasks
	onGetSafetyMinutesList: onSafetyMinutesListRequest,
	onGetIncidentList: onIncidentListRequest,
	onGetInductionMinutesList: onInductionMinutesListRequest,
	onGetStaffTrainingList: onStaffTrainingListRequest,
};

export const HomeRoutes = connect(mapStateToProps, mapDispatchToProps)((props) => {
	useEffect(() => {
		props.onClearSearch();
		// Info
		props.onGetHazardsList();
		props.onGetHazardousSubstanceList();
		props.onGetStaffTrainingTopicList();
		props.onGetJobSafetyAnalysisList();
		props.onGetSafetyPlanChecklistList();

		// Tasks
		props.onGetSafetyMinutesList();
		props.onGetIncidentList();
		props.onGetInductionMinutesList();
		props.onGetStaffTrainingList();
	}, [props]);

	return (
		<Switch>
			{/* home routes */}
			<Route exact path="/" component={Home} />
			<Route exact path="/home" component={Home} />
			<Route
				exact
				path={UNCOMFIRMED_ATTENDANCE_ROUTE}
				component={UncomfirmedAttendance}
			/>
			<Route>
				<Redirect to="/" />
			</Route>
		</Switch>
	);
});
