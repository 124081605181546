import React from "react";
import LandingPageFrame from "./landing-page-frame";
import ResetPasswordForm from "./forms/reset-password-form";

const ResetPassword = (props) => {
	return (
		<LandingPageFrame
			title={"Reset Password"}
			hideImage={props.token === ""}
			form={<ResetPasswordForm {...props} />}
		/>
	);
};

export default ResetPassword;
