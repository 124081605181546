import moment from "moment";
import { timesheetAdminActionTypes } from "./timesheet-admin-actions";

const defaultState = {
	siteId: "",
	timesheet: {
		staffId: "",
		startDate: "",
		endDate: "",
		lunchTime: "",
		startTime: "",
		endTime: "",
		notes: "",
		toggle: false,
		startLatitude: null,
		startLongitude: null,
		endLatitude: null,
		endLongitude: null,
		organisationTaskTypeId: ""
	},
	isValid: false,
	isEndDateTimeInvalid: false,
	isLoading: false,
	success: false,
	errorMessage: "",
};

export const timesheetAdminFormReducer = (state = defaultState, action) => {
	switch (action.type) {
		case timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_RESET: {
			if (action.payload) {
				return {
					...defaultState,
					...action.payload,
					timesheet: {
						...defaultState.timesheet,
						...action.payload.timesheet,
					},
				};
			}

			return defaultState;
		}
		case timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_VALUE_CHANGE: {
			return isValid({
				...state,
				...action.payload,
			});
		}
		case timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_SUBMIT:
			return setLoading(state);
		case timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_SUBMIT_FAILED:
			return setError(state, action.payload);
		case timesheetAdminActionTypes.ADD_ADMIN_TIMESHEET_FORM_SUBMIT_SUCCESS:
			return setSuccess(state);
		default:
			return state;
	}
};

const setLoading = (state) => {
	return {
		...state,
		isLoading: true,
		success: false,
		errorMessage: "",
	};
};

const setSuccess = (state) => {
	return {
		...state,
		isLoading: false,
		success: true,
		errorMessage: "",
	};
};

const setError = (state, payload) => {
	return {
		...state,
		isLoading: false,
		success: false,
		errorMessage: payload,
	};
};

const isValid = (state) => {
	state.isValid = !!(
		state.siteId &&
		state.timesheet.staffId &&
		state.timesheet.startDate &&
		state.timesheet.endDate &&
		state.timesheet.startTime &&
		state.timesheet.endTime
	);
	state.isEndDateTimeInvalid = !!(
		state.timesheet.startDate &&
		state.timesheet.endDate &&
		state.timesheet.startTime &&
		state.timesheet.endTime &&
		moment(
			state.timesheet.endDate + state.timesheet.endTime,
			"YYYY-MM-DDHH:mm"
		).isSameOrBefore(
			moment(
				state.timesheet.startDate + state.timesheet.startTime,
				"YYYY-MM-DDHH:mm"
			)
		)
	);
	return state;
};
