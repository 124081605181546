import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import {
	delay,
	map,
	mapTo,
	catchError,
	filter,
	mergeMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import { tokenExists, getOrganisationBaseUrl, authHeaders } from "frame";
import fromAuth from "features/authorisation/actions/types";
import fromTypes from "../actions/types";
import * as fromActions from "../actions";

const emailBody = (preferences) => {
	const currentEmails = preferences.alertEmails;
	let addEmails = currentEmails;
	let removeEmails = [];

	if (preferences.data && preferences.data.length > 0) {
		const prevEmails = preferences.data.map((p) => {
			return p.email;
		});

		addEmails = addEmails.filter((x) => !prevEmails.includes(x));
		removeEmails = prevEmails.filter((x) => !currentEmails.includes(x));
	}

	return {
		addEmails,
		removeEmails,
	};
};

export const preferencesSaveEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.PREFERENCES_CHANGE_ALERT_EMAILS),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/notifications/sync`,
					emailBody(state$.value.preferences),
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							fromActions.preferencesSave(ajaxResponse.response),
							fromActions.preferencesFetch()
						)
					),
					catchError((error) =>
						of(fromActions.profileSaveFailed(error, error.message))
					)
				)
		)
	);

export const preferencesSaveSuccessEffect = (action$) =>
	action$.pipe(
		ofType(fromTypes.PREFERENCES_SAVE),
		delay(3000),
		mapTo(fromActions.preferencesSaveSuccess())
	);

export const preferencesFetchSuccessEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.PREFERENCES_FETCH),
		filter(() => tokenExists()),
		mergeMap(() =>
			ajax
				.get(
					`${getOrganisationBaseUrl(state$.value)}/notifications/all`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							fromActions.preferencesFetchSuccess(
								ajaxResponse.response
							)
						)
					),
					catchError((error) =>
						of(fromActions.profileSaveFailed(error.message))
					)
				)
		)
	);

export const preferencesResetEffect = (action$) =>
	action$.pipe(
		ofType(fromAuth.AUTHORISATION_LOGOUT_FULFILLED),
		map((action) => fromActions.preferencesReset())
	);

export const preferencesSaveEmailNotificationsEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.PREFERENCES_SAVE_EMAIL_NOTIFICATIONS),
		mergeMap(({ payload }) =>
			ajax
				.get(
					`${getOrganisationBaseUrl(
						state$.value
					)}/organisation/update-email-nofications?allowExternalEmails=${
						payload || false
					}`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							fromActions.onSaveEmailNotificationsSuccess(
								ajaxResponse.response
							),
							fromActions.profileFetch()
						)
					),
					catchError((error) =>
						of(fromActions.onSaveEmailNotificationsFailed(error))
					)
				)
		)
	);
