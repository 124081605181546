import * as R from "ramda";
import initialStaffState from "../models/staff-state";
import Types from "../actions/types";
import StaffModel from "../models/staff-model";
import uuid from "uuid";
import ReactGA from "react-ga";
import {
	resetFlags,
	updateFlags,
	validateEmailAddress,
	validatePhoneNumber,
	hasValue,
	isProd,
} from "utils";

const setIsValid = (staff) => {
	staff.phoneNumberErrorMessage = validatePhoneNumber(staff.phoneNumber);
	staff.emailErrorMessage = validateEmailAddress(staff.email);
	staff.isValid =
		!staff.emailErrorMessage &&
		!staff.phoneNumberErrorMessage &&
		hasValue(staff.organisation) &&
		hasValue(staff.firstName) &&
		hasValue(staff.lastName) &&
		hasValue(staff.email) &&
		hasValue(staff.phoneNumber);
};

const addClicked = (state, action) => {
	state.apiError = "";
	state.isAddModalShowing = true;
	state.isNew = true;
	state.isEditModalShowing = false;
	state.currentStaff = StaffModel;
	state.currentStaff.id = uuid.v4();
	return state;
};

const editClicked = (state, action) => {
	state.apiError = "";
	state.isAddModalShowing = false;
	state.isNew = false;
	state.isEditModalShowing = true;
	state.currentStaff = R.clone(
		R.find(R.propEq("id", action.payload), state.pagination.results)
	);
	return state;
};

const saveClicked = (state, action) => {
	if (state.isAddModalShowing) {
		state.isAddModalShowing = false;
	} else if (state.isEditModalShowing) {
		state.isEditModalShowing = false;
	}
	state.saving = true;

	if (state.isNew) {
		let results = state.pagination.results;
		results = results.reverse();
		results.push(state.currentStaff);
		results = results.reverse();
		state.pagination.results = results;

		// update pagination state
		state.pagination.totalNumberOfRecords =
			state.pagination.totalNumberOfRecords + 1;
	} else {
		const index = R.findIndex(R.propEq("id", state.currentStaff.id))(
			state.pagination.results
		);
		state.pagination.results[index] = state.currentStaff;
	}

	return resetFlags(
		state,
		state.isEditModalShowing
			? { update: "processing" }
			: { create: "processing" }
	);
};

const addSucceeded = (state, action) => {
	state.currentStaff = StaffModel;
	state.saving = false;
	isProd &&
		ReactGA.event({
			category: "Info",
			action: "Save Clicked",
			label: "Add Staff",
		});
	return updateFlags(
		state,
		state.isNew ? { create: "done" } : { update: "done" }
	);
};

const addFailed = (state, action) => {
	state.saving = false;
	state.apiError =
		action.payload.response && action.payload.response.error
			? action.payload.response.error
			: "";
	return updateFlags(
		state,
		state.isNew ? { create: "error" } : { update: "error" }
	);
};

const closeClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isNew = true;
	state.isEditModalShowing = false;
	state.currentStaff = StaffModel;
	state.currentStaff.id = uuid.v4();
	return state;
};

const listReceived = (state, action) => {
	// state.staffList = []
	state.pagination = action.payload;
	// if (action.payload) {
	//   if(isNotNullOrEmpty(action.payload.results)) {
	//     action.payload.results.map(function(item) {
	//       state.staffList.push({
	//         id: item.id,
	//         organisationId: item.organisationId,
	//         firstName: item.firstName,
	//         lastName: item.lastName,
	//         fullName: String(`${item.firstName} ${item.lastName ? item.lastName : ''}`).trim(),
	//         email: item.emailAddress,
	//         phoneNumber: item.phoneNumber,
	//         status: item.status,
	//         staffIdNumber: item.staffIdNumber,
	//         supervisor: item.supervisor,
	//         organisationRole: item.organisationRole,
	//         attachments: item.attachments
	//       })
	//       return item
	//     })

	//   }
	// }
	return state;
};

const onListLoaded = (state, action) => {
	state.apiError = "";
	return state;
};

const organisationRoleChanged = (state, action) => {
	state.currentStaff.organisationRole = action.payload;
	setIsValid(state.currentStaff);
	return state;
};

const firstNameChanged = (state, action) => {
	state.currentStaff.firstName = action.payload;
	effectFullName(state);
	setIsValid(state.currentStaff);
	return state;
};

const lastNameChanged = (state, action) => {
	state.currentStaff.lastName = action.payload;
	effectFullName(state);
	setIsValid(state.currentStaff);
	return state;
};

const effectFullName = (state) => {
	const { firstName, lastName } = state.currentStaff;
	state.currentStaff.fullName = String(`${firstName} ${lastName}`).trim();
	return state;
};

const phoneChanged = (state, action) => {
	state.currentStaff.phoneNumber = action.payload;
	setIsValid(state.currentStaff);
	return state;
};

const emailAddressChanged = (state, action) => {
	state.currentStaff.email = action.payload;
	setIsValid(state.currentStaff);
	return state;
};

const passwordChanged = (state, action) => {
	state.currentStaff.password = action.payload;
	return state;
};

const emailToggled = (state, action) => {
	state.currentStaff.emailInvitation = !state.currentStaff.emailInvitation;
	return state;
};

const statusChanged = (state, action) => {
	state.currentStaff.status = action.payload;
	setIsValid(state.currentStaff);
	return state;
};

const staffTypeChanged = (state, action) => {
	state.currentStaff.organisationStaffTypeId =
		action.payload.organisationStaffTypeId;
	state.currentStaff.organisationStaffTypesStaffType =
		action.payload.organisationStaffTypesStaffType;
	return state;
};

const staffIdNumberChanged = (state, action) => {
	state.currentStaff.staffIdNumber = action.payload;
	return state;
};

const supervisorChanged = (state, action) => {
	state.currentStaff.supervisor = action.payload;
	return state;
};

const notesChanged = (state, action) => {
	state.currentStaff.notes = action.payload;
	return state;
};

const fileChanged = (state, action) => {
	const attachments = action.payload.attachments;
	state.currentStaff = {
		...state.currentStaff,
		attachments,
	};
	return state;
};

const getStaffList = (state, action) => {
	state.isAddModalShowing = false;
	state.isEditModalShowing = false;
	state.batchCreateFlags = [];
	return state;
};

const removeClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isEditModalShowing = false;
	state.currentStaff = R.find(
		R.propEq("id", action.payload),
		state.pagination.results
	);

	state.pagination.results = state.pagination.results.filter(
		(c) => c.id !== state.currentStaff.id
	);
	state.pagination.totalNumberOfRecords =
		state.pagination.totalNumberOfRecords - 1;
	return resetFlags(state, { delete: "processing" });
};

const removeSucceeded = (state, action) => {
	state.currentStaff = StaffModel;
	return updateFlags(state, { delete: "done" });
};

const impersonateClicked = (state, action) => {
	state.apiError = "";
	state.isAddModalShowing = false;
	state.isEditModalShowing = false;
	state.currentStaff = R.find(
		R.propEq("id", action.payload),
		state.pagination.results
	);
	return state;
};

const impersonateFailed = (state, action) => {
	const status = action.payload.status;

	if (status === 400) {
		state.apiError =
			"The staff member is not a valid user or is not set up properly.";
	} else if (status === 401) {
		state.apiError = "You do not have permission to impersonate this user.";
	} else {
		state.apiError =
			"Something went wrong or network failed. Please try again.";
	}

	return state;
};

const searchChanged = (state, action) => {
	state.searchFilter = action.payload;
	return state;
};

const staffLookupSuccess = (state, { payload }) => {
	state.lookup = payload;
	return state;
};

const searchClear = (state, action) => {
	state.searchFilter = "";
	return state;
};

const staffReducer = (state = initialStaffState, action) => {
	switch (action.type) {
		case Types.STAFF_LOOKUP_SUCCESS:
			return staffLookupSuccess(R.clone(state), action);
		case Types.STAFF_SEARCH_CHANGED:
			return searchChanged(R.clone(state), action);
		case Types.STAFF_SEARCH_CLEAR:
			return searchClear(R.clone(state), action);

		case Types.STAFF_ADD_CLICKED:
			return addClicked(R.clone(state), action);
		case Types.STAFF_EDIT_CLICKED:
			return editClicked(R.clone(state), action);

		// Save staff
		case Types.STAFF_SAVE_CLICKED:
			return saveClicked(R.clone(state), action);
		case Types.STAFF_ADD_SUCCEEDED:
			return addSucceeded(R.clone(state), action);
		case Types.STAFF_ADD_FAILED:
			return addFailed(R.clone(state), action);

		case Types.STAFF_CLOSE_CLICKED:
			return closeClicked(R.clone(state), action);

		// on change fields
		case Types.STAFF_ORGANISATION_ROLE_CHANGED:
			return organisationRoleChanged(R.clone(state), action);
		case Types.STAFF_FIRSTNAME_CHANGED:
			return firstNameChanged(R.clone(state), action);
		case Types.STAFF_LASTNAME_CHANGED:
			return lastNameChanged(R.clone(state), action);
		case Types.STAFF_PASSWORD_CHANGED:
			return passwordChanged(R.clone(state), action);
		case Types.STAFF_PHONE_CHANGED:
			return phoneChanged(R.clone(state), action);
		case Types.STAFF_EMAIL_CHANGED:
			return emailAddressChanged(R.clone(state), action);
		case Types.STAFF_EMAIL_INVITE_TOGGLED:
			return emailToggled(R.clone(state), action);
		case Types.STAFF_STATUS_CHANGED:
			return statusChanged(R.clone(state), action);
		case Types.STAFF_TYPE_CHANGED:
			return staffTypeChanged(R.clone(state), action);
		case Types.STAFF_STAFF_ID_NUMBER_CHANGED:
			return staffIdNumberChanged(R.clone(state), action);
		case Types.STAFF_SUPERVISOR_CHANGED:
			return supervisorChanged(R.clone(state), action);
		case Types.STAFF_NOTES_CHANGED:
			return notesChanged(R.clone(state), action);
		case Types.STAFF_FILE_CHANGED:
			return fileChanged(R.clone(state), action);

		// Fetch staff lists
		case Types.STAFF_LIST_REQUESTED:
			return getStaffList(R.clone(state), action);
		case Types.STAFF_LIST_RECEIVED:
			return listReceived(R.clone(state), action);
		case Types.STAFF_LIST_ONLOADED:
			return onListLoaded(R.clone(state), action);

		// Delete staff
		case Types.STAFF_REMOVE_CLICKED:
			return removeClicked(R.clone(state), action);
		case Types.STAFF_REMOVE_SUCCEEDED:
			return removeSucceeded(R.clone(state), action);
		case Types.STAFF_REMOVE_FAILED:
			return updateFlags(state, { delete: "error" });

		// Impersonate staff
		case Types.STAFF_IMPERSONATE_CLICKED:
			return impersonateClicked(R.clone(state), action);
		case Types.STAFF_IMPERSONATE_FAILED:
			return impersonateFailed(R.clone(state), action);

		// Batch add staff
		case Types.STAFF_BATCH_ADD:
			return batchAddClicked(R.clone(state), action);
		case Types.STAFF_BATCH_ADD_FAILED:
			return batchAddFailed(R.clone(state), action);
		case Types.STAFF_BATCH_ADD_SUCCEEDED:
			return batchAddSucceeded(R.clone(state), action);
		default:
			return state;
	}
};

const batchAddClicked = (state, { payload, index }) => {
	let flags = state.batchCreateFlags ? state.batchCreateFlags : [];

	flags.push({
		id: payload.id,
		status: "processing",
		message: "",
	});

	state.batchCreateFlags = flags;
	return state;
};
const batchAddFailed = (state, { payload }) => {
	const request = JSON.parse(payload.request.body);

	if (request) {
		const updatedFlags = state.batchCreateFlags.map((item) => ({
			id: item.id,
			status: item.id === request.id ? "error" : item.status,
			message: payload,
		}));

		state.batchCreateFlags = updatedFlags;
	}

	return state;
};
const batchAddSucceeded = (state, { payload }) => {
	const request = JSON.parse(payload.request.body);

	if (request) {
		const updatedFlags = state.batchCreateFlags.map((item) => ({
			id: item.id,
			status: item.id === request.id ? "done" : item.status,
			message: payload,
		}));

		state.batchCreateFlags = updatedFlags;
	}
	return state;
};

export default staffReducer;
