import moment from "moment";
import { timesheetActionTypes } from "./timesheet-actions";
import addNotification from "react-push-notification";
import { iconDataUrl } from "utils";
import { ADD_TIMESHEET_ROUTE } from "./timesheet-routes";

export const defaultState = {
	siteId: "",
	organisationTaskTypeId: "",
	timesheet: {
		staffId: "",
		startDate: "",
		endDate: "",
		lunchTime: "",
		startTime: "",
		endTime: "",
		notes: "",
		toggle: false,
		startLatitude: null,
		startLongitude: null,
		endLatitude: null,
		endLongitude: null,
	},
	isValid: false,
	isEndDateTimeInvalid: false,
	isLoading: false,
	success: false,
	errorMessage: ""
};

export const timesheetFormReducer = (state = defaultState, action) => {
	switch (action.type) {
		case timesheetActionTypes.ADD_TIMESHEET_FORM_RESET: {
			if (action.payload) {
				return {
					...defaultState,
					...action.payload,
					timesheet: {
						...defaultState.timesheet,
						...action.payload.timesheet
					}
				};
			}

			return defaultState;
		}
		case timesheetActionTypes.ADD_TIMESHEET_FORM_VALUE_CHANGE: {
			return isValid({
				...state,
				...action.payload,
			});
		}
		case timesheetActionTypes.ADD_TIMESHEET_FORM_SUBMIT:
			return setLoading(state);
		case timesheetActionTypes.ADD_TIMESHEET_FORM_SUBMIT_FAILED:
			return setError(state, action.payload);
		case timesheetActionTypes.ADD_TIMESHEET_FORM_SUBMIT_SUCCESS:
			return setSuccess(state);
		case timesheetActionTypes.ADD_TIMESHEET_FORM_START_TIMER:
			return setLoading(startTimer(state, action));
		case timesheetActionTypes.ADD_TIMESHEET_FORM_START_SUCCESS:
			return setSuccess(startTimerSuccess(state));
		case timesheetActionTypes.ADD_TIMESHEET_FORM_START_FAILED:
			return setError(state, action.payload);
		case timesheetActionTypes.ADD_TIMESHEET_FORM_STOP_TIMER:
			return stopTimer(state, action);
		case timesheetActionTypes.ADD_TIMESHEET_FORM_CONFIRM:
			return setLoading(state);
		case timesheetActionTypes.ADD_TIMESHEET_FORM_CONFIRM_SUCCESS:
			return setSuccess(state);
		case timesheetActionTypes.ADD_TIMESHEET_FORM_CONFIRM_FAILED:
			return setError(state, action.payload);
		case timesheetActionTypes.ADD_TIMESHEET_FORM_DISCARD:
			return setLoading(state);
		case timesheetActionTypes.ADD_TIMESHEET_FORM_DISCARD_SUCCESS:
			return setSuccess(state);
		case timesheetActionTypes.ADD_TIMESHEET_FORM_DISCARD_FAILED:
			return setError(state, action.payload);
		case timesheetActionTypes.UPDATE_TIMESHEET_FORM_SUBMIT:
			return setLoading(state);
		case timesheetActionTypes.ACTIVE_TIMESHEET_RECEIVED:
			return activeTimesheetReceived(state, action.payload);
		default:
			return state;
	}
};

const setLoading = (state) => {
	return {
		...state,
		isLoading: true,
		success: false,
		errorMessage: ""
	};
};

const setSuccess = (state) => {
	return {
		...state,
		isLoading: false,
		success: true,
		errorMessage: ""
	};
};

const setError = (state, payload) => {
	return {
		...state,
		isLoading: false,
		success: false,
		errorMessage: payload
	};
};

const isValid = (state) => {
	state.isValid =
		!!(state.siteId &&
		state.timesheet.staffId &&
		state.timesheet.startDate &&
		state.timesheet.endDate &&
		state.timesheet.startTime &&
		state.timesheet.endTime);
	state.isEndDateTimeInvalid =
		!!(state.timesheet.startDate &&
		state.timesheet.endDate &&
		state.timesheet.startTime &&
		state.timesheet.endTime &&
		moment(
			state.timesheet.endDate + state.timesheet.endTime,
			"YYYY-MM-DDHH:mm"
		).isBefore(
			moment(
				state.timesheet.startDate + state.timesheet.startTime,
				"YYYY-MM-DDHH:mm"
			)
		));
	return state;
};

const startTimer = (state, action) => {
	const newState = {
		...state
	};

	newState.timesheet.staffId = action.payload.staffId;
	newState.timesheet.startLatitude = action.payload.latitude;
	newState.timesheet.startLongitude = action.payload.longitude;
	newState.timesheet.startDate = moment().format("YYYY-MM-DD");
	newState.timesheet.startTime = moment().format("HH:mm");

	return newState;
};

const startTimerSuccess = (state) => {
	addNotification({
		title: "Timesheet Started",
		message: `Timesheet started at ${moment().format("Do MMMM YYYY, h:mm a")}`,
		native: true, // when using native, your OS will handle theming.
		duration: 24 * 60 * 60 * 1000, // 1 day - optional, default: 5000,
		icon: iconDataUrl,
		onClick: () => {
			window.location = `${window.location.origin}${ADD_TIMESHEET_ROUTE}`;
		},
	});

	const newState = {
		...state
	};

	newState.timesheet.toggle = true;
	newState.timerMessage = `Timesheet started at ${moment(state.timesheet.startDate + " " + state.timesheet.startTime, "YYYY-MM-DD HH:mm").format("Do MMMM YYYY, h:mm a")}`;

	return newState;
};

const stopTimer = (state, action) => {
	const newState = {
		...state
	};

	newState.timesheet.endLatitude = action.payload.latitude;
	newState.timesheet.endLongitude = action.payload.longitude;
	newState.timesheet.endDate = moment().format("YYYY-MM-DD");
	newState.timesheet.endTime = moment().format("HH:mm");

	return newState;
};

const activeTimesheetReceived = (state, payload) => {
	if (!payload) {
		return state;
	}

	return {
		...state,
		siteId: payload.siteId,
		organisationTaskTypeId: payload.organisationTaskTypeId,
		timerMessage: `Timesheet started at ${moment(
			payload.startDateTimeUTC).format("Do MMMM YYYY, h:mm a")}`,
		timesheet: {
			...state.timesheet,
			id: payload.id,
			isActive: payload.isActive,
			staffId: payload.staffId,
			startLatitude: payload.startLatitude,
			startLongitude: payload.startLongitude,
			startDate: moment(payload.startDateTimeUTC).format("YYYY-MM-DD"),
			startTime: moment(payload.startDateTimeUTC).format("HH:mm"),
			toggle: true,
		},
	};
};
