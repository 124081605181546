/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Page } from "frame/components";
import { PageHeaderTabs } from "features/health-safety-forms";
import { DatePicker } from "@material-ui/pickers";
import { UploadForm } from "features/forms";
import { JOBS_PATH } from "../../actions/types";
import { TASK_HOME_ROUTE } from "features/home/home-routes";
import { ADD_JOB_PATH } from "../../actions/types";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Card, Grid, Typography, TextField } from "@material-ui/core";
import {
	PageContent,
	FormActions,
	FormButton,
	FormSubmitButton,
	ScrollToTopOnMount,
	SelectInput
} from "frame/components";
import { useRouter, sortByPropertyAlphabetically, isNotNullOrEmpty } from "utils";

const useStyles = makeStyles((theme) => ({
	actions: {
		margin: theme.spacing(3, 0, 8, 0)
	}
}));

const JobAddEditPage = ({
	job,
	customerList,
	staffList,
	siteList,
	// onchange functions
	onTitleChanged,
	onDateChanged,
	onTypeChanged,
	onCustomerIdChanged,
	onSiteIdChanged,
	onStaffIdChanged,
	onNotesChanged,
	onFileChanged,
	// actions
	onSave,
	onClose,
	onUploadReset,
	// flags
	isAddModalShowing,
	isEditModalShowing
}) => {
	const classes = useStyles();
	const { history } = useRouter();

	const [isCompressing, setCompressing] = useState(false);
	const isActive = (node) => String(node.status).toLowerCase() === "active";

	useEffect(() => {
		onUploadReset(); // clear any old uploaded content
	}, []);

	const customerOptions =
		isNotNullOrEmpty(customerList) &&
		customerList
			.filter(isActive)
			.map(({ id, customerName }) => ({
				label: customerName,
				value: id
			}))
			.sort(sortByPropertyAlphabetically("label"));

	const siteOptions =
		isNotNullOrEmpty(siteList) &&
		siteList
			.filter(isActive)
			.map(({ id, siteName }) => ({
				label: siteName,
				value: id
			}))
			.sort(sortByPropertyAlphabetically("label"));

	const staffOptions =
		isNotNullOrEmpty(staffList) &&
		staffList
			.map(({ id, fullName, firstName, lastName }) => ({
				label: fullName || `${firstName} ${lastName}`.trim(),
				value: id
			}))
			.filter((s) => s.label && s.value)
			.sort(sortByPropertyAlphabetically("label"));

	const typeOptions = [
		{
			label: "Customer",
			value: "customer"
		},
		{
			label: "Site",
			value: "site"
		},
		{
			label: "Staff",
			value: "staff"
		}
	];

	const onValueChange = (cb) => (event) => cb(event.target.value);

	const onSelectChange = (cb) => (value) => cb(value);

	const onSelectDateChange = (cb) => (value) => cb(value.utc().format());

	const onCancel = () => {
		onClose();
		if (history.location.pathname === ADD_JOB_PATH) {
			history.push(TASK_HOME_ROUTE);
		}
	};

	const onCompressingUploads = (e) => {
		if (e !== isCompressing) setCompressing(e);
	};

	return (
		<Page title="Add Quick Pic or Note record">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs=""
				title="Add Quick Pic or Note record"
				// addTabTitle="Add quick pic or note"
				// listTabTitle="View library of quick pics"
				listTabPath={JOBS_PATH}
				backActionPath={TASK_HOME_ROUTE}
			/>
			<PageContent padSides padBottom padTop>
				<Grid container spacing={3}>
					<Grid item lg={6} md={12} sm={12} xs={12}>
						<ValidatorForm onSubmit={onSave}>
							<Card>
								<PageContent padSides padBottom padTop>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Typography gutterBottom variant="h3">
												{!isEditModalShowing ? "Add" : "Edit"} Quick Pic or Note
											</Typography>
										</Grid>

										<Grid item xs={12}>
											<TextValidator
												fullWidth
												data-cy="job_title"
												label="Title *"
												name="Title"
												value={job.title || ""}
												variant="outlined"
												validators={["required"]}
												errorMessages={["This field is required"]}
												onChange={onValueChange(onTitleChanged)}
												inputProps={{ maxLength: 100 }}
											/>
										</Grid>

										<Grid item xs={12}>
											<DatePicker
												data-cy="job_date"
												className={classes.field}
												format="Do MMMM YYYY"
												fullWidth
												inputVariant="outlined"
												label="Date"
												value={job.createdAt}
												variant="outlined"
												disableFuture={true}
												onChange={onSelectDateChange(onDateChanged)}
											/>
										</Grid>

										<Grid item xs={12}>
											<SelectInput
												id="type"
												data-cy="job_type"
												label="Type *"
												options={typeOptions}
												value={job.type}
												onChange={onSelectChange(onTypeChanged)}
											/>
										</Grid>

										{job.type === "customer" && (
											<Grid item xs={12}>
												<SelectInput
													id="customer"
													data-cy="customer"
													label="Customer Name *"
													options={customerOptions}
													value={job.customerId}
													onChange={onSelectChange(onCustomerIdChanged)}
												/>
											</Grid>
										)}

										{job.type === "site" && (
											<Grid item xs={12}>
												<SelectInput
													id="site"
													data-cy="site"
													label="Site Name *"
													options={siteOptions}
													value={job.siteId}
													onChange={onSelectChange(onSiteIdChanged)}
												/>
											</Grid>
										)}

										{job.type === "staff" && (
											<Grid item xs={12}>
												<SelectInput
													id="staff"
													data-cy="staff"
													label="Staff Name *"
													options={staffOptions}
													value={job.staffId}
													onChange={onSelectChange(onStaffIdChanged)}
												/>
											</Grid>
										)}

										<Grid item xs={12}>
											<TextField
												fullWidth
												id="job_notes"
												data-cy="job_notes"
												label="Notes"
												multiline
												rows="3"
												name="notes"
												onChange={onValueChange(onNotesChanged)}
												value={job.notes}
												variant="outlined"
											/>
										</Grid>

										<Grid item xs={12}>
											<Typography variant="h6">Attachments</Typography>
											<Typography variant="body2">
												Attach documents, photos and audio files.
											</Typography>
											<UploadForm
												ignoreDataUrl
												values={job}
												onChange={onFileChanged}
												onCompressing={onCompressingUploads}
												// disabled={!job.isValid}
											/>
											<Typography variant="caption">
												Total size of uploaded files cannot exceed 50 MB.
											</Typography>
										</Grid>
									</Grid>
								</PageContent>
							</Card>

							<FormActions className={classes.actions}>
								<FormSubmitButton disabled={(!isEditModalShowing && !job.isValid) || isCompressing}>
									Save
								</FormSubmitButton>
								<FormButton onClick={onCancel}>Cancel</FormButton>
							</FormActions>
						</ValidatorForm>
					</Grid>
				</Grid>
			</PageContent>
		</Page>
	);
};

JobAddEditPage.propTypes = {
	job: PropTypes.object,
	staffList: PropTypes.array.isRequired,
	customerList: PropTypes.array.isRequired,
	siteList: PropTypes.array.isRequired,
	// onchange functions
	onTitleChanged: PropTypes.func.isRequired,
	onDateChanged: PropTypes.func.isRequired,
	onTypeChanged: PropTypes.func.isRequired,
	onCustomerIdChanged: PropTypes.func.isRequired,
	onSiteIdChanged: PropTypes.func.isRequired,
	onStaffIdChanged: PropTypes.func.isRequired,
	onNotesChanged: PropTypes.func.isRequired,
	onFileChanged: PropTypes.func.isRequired,
	// actions
	onSave: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	onUploadReset: PropTypes.func.isRequired,
	// flags
	isEditModalShowing: PropTypes.bool.isRequired,
	isAddModalShowing: PropTypes.bool.isRequired
};

export default JobAddEditPage;
