import { connect } from "react-redux";
import Confirmation from "./confirmation";

import { onConfirmation } from "../actions";

const mapStateToProps = ({ authorisation, router }) => ({
	token: router.location.search,
	responseMessage: authorisation.confirmation.responseMessage,
});

const mapDispatchToProps = {
	onConfirmation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
