import * as idb from "idb";

import { ADD_JOB_PATH, JOBS_PATH } from "features/jobs";
import { STAFF_PATH } from "features/staff";
import * as fromHealthSafetyForms from "features/health-safety-forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";

const DATABASE_NAME = "attachments";
const DATABASE_VERSION = 2;
const db = idb.default;

export const JOBS_TABLE = "jobs";
export const STAFF_TABLE = "staff";
export const HAZARDS_TABLE = "hazards";
export const HAZARDOUS_SUBSTANCES_TABLE = "hazardousSubstances";
export const INCIDENTS_TABLE = "incidents";
export const INDUCTION_MEETINGS_TABLE = "inductionMeetings";
export const SAFETY_MINUTES_TABLE = "safetyMinutes";
export const JOB_SAFETY_ANALYSIS_TABLE = "jobSafetyAnalysis";
export const POLICIES_TABLE = "policies";
export const CHECKLIST_TABLE = "checklist";
export const STAFF_TRAINING_TABLE = "staffTraining";
export const STAFF_TRAINING_TOPIC_TABLE = "staffTrainingTopic";

/**
 * Initialize the IndexedDB.
 */
const dbPromise = db.open(DATABASE_NAME, DATABASE_VERSION, (upgradeDB) => {
	try {
		upgradeDB.createObjectStore(JOBS_TABLE, { keyPath: "id" });
		upgradeDB.createObjectStore(STAFF_TABLE, { keyPath: "id" });
		upgradeDB.createObjectStore(HAZARDS_TABLE, { keyPath: "id" });
		upgradeDB.createObjectStore(HAZARDOUS_SUBSTANCES_TABLE, {
			keyPath: "id",
		});
		upgradeDB.createObjectStore(INCIDENTS_TABLE, { keyPath: "id" });
		upgradeDB.createObjectStore(INDUCTION_MEETINGS_TABLE, {
			keyPath: "id",
		});
		upgradeDB.createObjectStore(SAFETY_MINUTES_TABLE, { keyPath: "id" });
		upgradeDB.createObjectStore(JOB_SAFETY_ANALYSIS_TABLE, {
			keyPath: "id",
		});
		upgradeDB.createObjectStore(POLICIES_TABLE, { keyPath: "id" });
		upgradeDB.createObjectStore(CHECKLIST_TABLE, { keyPath: "id" });
		upgradeDB.createObjectStore(STAFF_TRAINING_TABLE, { keyPath: "id" });
		upgradeDB.createObjectStore(STAFF_TRAINING_TOPIC_TABLE, {
			keyPath: "id",
		});

		// Initialise Data
		// const store = upgradeDB.transaction.objectStore('attachments', 'readwrite')
		// store.put({
		//   id: 'test',
		//   fileName: 'test',
		//   fileExtension: 'test',
		//   dataURL: 'test',
		//   createdAt: 'test',
		// })
	} catch (error) {
		console.log(error);
	}
});

export class DBService {
	getAllKeys(table) {
		return dbPromise
			.then((db) => {
				return db.transaction(table).objectStore(table).getAllKeys();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	get(table, key) {
		return dbPromise
			.then((db) => {
				return db.transaction(table).objectStore(table).get(key);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getAll(table) {
		return dbPromise
			.then((db) => {
				return db.transaction(table).objectStore(table).getAll();
			})
			.catch((error) => {
				console.log(error);
			});
	}

	add(table, object, key = null) {
		return dbPromise
			.then((db) => {
				if (key) {
					return db
						.transaction(table, "readwrite")
						.objectStore(table)
						.add(object, key);
				}
				return db
					.transaction(table, "readwrite")
					.objectStore(table)
					.add(object);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	put(table, object, key = null) {
		return dbPromise
			.then((db) => {
				if (key) {
					return db
						.transaction(table, "readwrite")
						.objectStore(table)
						.put(object, key);
				}
				return db
					.transaction(table, "readwrite")
					.objectStore(table)
					.put(object);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	delete(table, key) {
		return dbPromise
			.then((db) => {
				return db
					.transaction(table, "readwrite")
					.objectStore(table)
					.delete(key);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	deleteAll(table) {
		return dbPromise
			.then((db) => {
				return db
					.transaction(table, "readwrite")
					.objectStore(table)
					.clear();
			})
			.catch((error) => {
				console.log(error);
			});
	}
}

export const getTableName = () => {
	switch (window.location.pathname) {
		case ADD_JOB_PATH:
		case JOBS_PATH:
			return JOBS_TABLE;
		case STAFF_PATH:
			return STAFF_TABLE;
		case fromHealthSafetyForms.ADD_HAZARD_PATH:
		case fromHealthAndSafety.HEALTH_SAFETY_HAZARDS_PATH:
		case fromHealthAndSafety.INFO_HAZARDS_ROUTE:
			return HAZARDS_TABLE;

		case fromHealthSafetyForms.ADD_HAZARDOUS_SUBSTANCE_PATH:
		case fromHealthAndSafety.HEALTH_SAFETY_HAZARDOUS_SUBSTANCES_PATH:
		case fromHealthAndSafety.INFO_HAZARDOUS_SUBSTANCES_ROUTE:
			return HAZARDOUS_SUBSTANCES_TABLE;

		case fromHealthSafetyForms.ADD_INCIDENT_PATH:
		case fromHealthAndSafety.HEALTH_SAFETY_INCIDENTS_PATH:
		case fromHealthAndSafety.TASK_INCIDENTS_ROUTE:
			return INCIDENTS_TABLE;

		case fromHealthSafetyForms.ADD_INDUCTION_MINUTES_PATH:
		case fromHealthAndSafety.HEALTH_SAFETY_INDUCTION_MINUTES_PATH:
		case fromHealthAndSafety.TASK_INDUCTION_MINUTES_ROUTE:
			return INDUCTION_MEETINGS_TABLE;

		case fromHealthSafetyForms.ADD_SAFETY_MINUTES_ROUTE:
		case fromHealthAndSafety.HEALTH_SAFETY_SAFETY_MINUTES_PATH:
		case fromHealthAndSafety.TASK_SAFETY_MINUTES_ROUTE:
			return SAFETY_MINUTES_TABLE;

		case fromHealthSafetyForms.ADD_JSA_PATH:
		case fromHealthAndSafety.HEALTH_SAFETY_JOB_SAFETY_ANALYSIS_PATH:
		case fromHealthAndSafety.INFO_JOB_SAFETY_ANALYSIS_ROUTE:
			return JOB_SAFETY_ANALYSIS_TABLE;

		case fromHealthSafetyForms.ADD_SAFETY_PLAN_CHECKLIST_PATH:
		case fromHealthAndSafety.HEALTH_SAFETY_SAFETY_PLAN_CHECKLISTS_PATH:
		case fromHealthAndSafety.INFO_SAFETY_PLAN_CHECKLISTS_ROUTE:
			return CHECKLIST_TABLE;

		case fromHealthSafetyForms.ADD_STAFF_TRAINING_PATH:
		case fromHealthAndSafety.HEALTH_SAFETY_STAFF_TRAINING_ROUTE:
		case fromHealthAndSafety.TASK_STAFF_TRAINING_ROUTE:
			return STAFF_TRAINING_TABLE;
		default:
			return "";
	}
};

export const replaceDataURLs = (attachments, sourceAttachments) => {
	for (let i in attachments) {
		const dataURL = attachments[i].dataURL || "";
		// ttp://localhost:5100/api/e5639cef-31a6-4822-a135-ceda90c2f322/file/accident/b68adaac-13a0-4596-9ddb-3b8ad9712eab
		if (
			dataURL === "" ||
			(dataURL.includes("/api/") && dataURL.includes("/file/"))
		) {
			//dataURL.includes(';base64'))
			let attachment = sourceAttachments.filter(
				(item) => item.id === attachments[i].id
			)[0];

			if (attachment && attachment.dataURL) {
				attachments[i].dataURL = attachment.dataURL;
			}
		}
	}
	return attachments;
};
