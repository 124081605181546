import React, { forwardRef } from "react";
// import PropTypes from 'prop-types'
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

const spin = `animSpin`;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		margin: theme.spacing(1, 0),
		minWidth: 30,
		minHeight: 30,
	},
	rootModal: {
		width: "100%",
		height: "100%",
		margin: 0,
	},
	loader: {
		display: "inline-block",
		width: 30,
		height: 30,
		"&::after": {
			content: '" "',
			display: "block",
			width: 26,
			height: 26,
			margin: 2,
			borderRadius: "50%",
			border: `2px solid ${theme.palette.text.secondary}`,
			borderColor: `${theme.palette.text.secondary} transparent ${theme.palette.text.secondary} transparent`,
			animation: `$${spin} 1.2s linear infinite`,
		},
	},
	loaderModal: {},
	[`@keyframes ${spin}`]: {
		"0%": {
			transform: "rotate(0deg)",
		},
		"100%": {
			transform: "rotate(360deg)",
		},
	},
}));

const Loader = forwardRef(({ isForModal }, ref) => {
	const classes = useStyles();

	return (
		<div
			className={
				isForModal
					? clsx(classes.rootModal, classes.root)
					: classes.root
			}
		>
			<div
				className={
					isForModal
						? clsx(classes.loaderModal, classes.loader)
						: classes.loader
				}
			/>
		</div>
	);
});

export default Loader;
