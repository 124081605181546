import { connect } from "react-redux";
import * as fromActions from "../../actions";
import Form from "./form";

const mapStateToProps = ({ addSafetyMinutesFormReducer }) => ({
	values: addSafetyMinutesFormReducer,
});

const mapDispatchToProps = {
	onChange: fromActions.onAddSafetyMinutesValueChange,
	onReset: fromActions.onAddSafetyMinutesReset,
	onSave: fromActions.onAddSafetyMinutesSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form);
