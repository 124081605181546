import { combineEpics } from "redux-observable";
import queueSendEffect from "./queue-send-effect";
import {
	onlineStatusPollEffect,
	checkNetworkEffect,
} from "./online-status-poll-effect";

const queueEffects = combineEpics(
	queueSendEffect,
	onlineStatusPollEffect,
	checkNetworkEffect
);

export default queueEffects;

/*

Requirements
------------

* When the app comes online check network status first before everything else fires
* Every x seconds check network status
* When network goes offline disable all list requests
*

*/
