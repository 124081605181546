import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { Card, CardHeader, Divider } from "@material-ui/core";

import SelectHazardsForm from "./select-hazard-form";
import SelectHazardousSubstanceForm from "./select-hazardous-substance-form";
import ReminderNotesForm from "./reminder-notes-form";

const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		marginTop: theme.spacing(-5),
		marginBottom: theme.spacing(-4),
	},
}));

const SelectHazardsFormCard = ({
	values,
	onChange,
	disabled,
	showNotesReminderCheckbox,
	viewOnly,
	editMode,
	siteHazards,
	siteSubstances,
	hazardList,
	hazardousSubstanceList,
	showError,
}) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardHeader
				title="Hazards / Hazardous Substances"
				titleTypographyProps={{ variant: "h4" }}
			/>
			{/* <pre>
        {JSON.stringify({
          hazardList: hazardList ? hazardList.length : null,
          siteHazards: siteHazards ? siteHazards.length : null,
          hazardousSubstanceList: hazardousSubstanceList ? hazardousSubstanceList.length : null,
          siteSubstances: siteSubstances ? siteSubstances.length : null,
        }, null, 2)}
      </pre> */}
			{hazardList && siteHazards && (
				<SelectHazardsForm
					editMode={editMode}
					disabled={disabled}
					values={values}
					onChange={onChange}
					siteHazards={siteHazards}
					hazardList={hazardList}
					showError={showError}
				/>
			)}

			{hazardousSubstanceList && siteSubstances && (
				<SelectHazardousSubstanceForm
					editMode={editMode}
					values={values}
					disabled={disabled}
					onChange={onChange}
					siteSubstances={siteSubstances}
					hazardousSubstanceList={hazardousSubstanceList}
					showError={showError}
				/>
			)}

			<Divider />
			<ReminderNotesForm
				showNotesReminderCheckbox={showNotesReminderCheckbox}
				disabled={disabled || viewOnly}
				values={values}
				onChange={onChange}
			/>
		</Card>
	);
};

SelectHazardsFormCard.propTypes = {
	values: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	showNotesReminderCheckbox: PropTypes.bool,
	viewOnly: PropTypes.bool,
	editMode: PropTypes.bool,
	siteHazards: PropTypes.array,
	siteSubstances: PropTypes.array,
	hazardList: PropTypes.array,
	hazardousSubstanceList: PropTypes.array,
	showError: PropTypes.bool,
};

export default SelectHazardsFormCard;
