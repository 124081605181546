import * as fromRoutes from "../actions/types";
import { isStaffUser, isAccountAdminUser, isSuperAdminUser } from "utils";

export const getTabConfig = (tabType, role) => {
	switch (tabType) {
		case "invite":
			return isStaffUser(role) || isAccountAdminUser(role)
				? getNotFoundConfig()
				: getInviteConfig(role);
		case "notifications":
			return getNotificationsConfig();
		case "organisations":
			return getOrganisationsConfig();
		case "password":
			return getPasswordConfig();
		case "reports":
			return getReportsConfig();
		case "subscription":
			return getSubscriptionConfig();
		default:
			return getProfileConfig();
	}
};

const getNotFoundConfig = () => ({
	label: "Not Found",
	breadcrumbs: [
		{
			label: "Not Found",
			value: "",
		},
	],
});

const getInviteConfig = (role) => ({
	label: isSuperAdminUser(role) ? "Invite Consultant" : "Invite Organisation",
	breadcrumbs: [
		{
			label: isSuperAdminUser(role)
				? "Invite Consultant"
				: "Invite Organisation",
			value: fromRoutes.ACCOUNT_INVITE_PATH,
		},
	],
});

const getNotificationsConfig = () => ({
	label: "Notifications",
	breadcrumbs: [
		{
			label: "Notifications",
			value: fromRoutes.ACCOUNT_NOTIFICATIONS_PATH,
		},
	],
});

const getOrganisationsConfig = () => ({
	label: "Organisations",
	breadcrumbs: [
		{
			label: "Organisations",
			value: fromRoutes.ACCOUNT_ORGANISATIONS_PATH,
		},
	],
});

const getPasswordConfig = () => ({
	label: "Password",
	breadcrumbs: [
		{
			label: "Password",
			value: fromRoutes.ACCOUNT_PASSWORD_PATH,
		},
	],
});

const getProfileConfig = () => ({
	label: "Profile",
	breadcrumbs: [
		{
			label: "Profile",
			value: fromRoutes.ACCOUNT_PROFILE_PATH,
		},
	],
});

const getReportsConfig = () => ({
	label: "Reports",
	breadcrumbs: [
		{
			label: "Reports",
			value: fromRoutes.ACCOUNT_REPORTS_PATH,
		},
	],
});

const getSubscriptionConfig = () => ({
	label: "Subscription",
	breadcrumbs: [
		{
			label: "Subscription",
			value: fromRoutes.ACCOUNT_SUBSCRIPTION_PATH,
		},
	],
});
