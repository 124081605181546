import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SafetyWiseColor } from "./logos/safety-wise-logo-color.svg";
import { ReactComponent as SafetyWiseWhite } from "./logos/safety-wise-logo-white.svg";

const Brand = ({ theme, ...rest }) => {
	if (theme === "light") {
		return <SafetyWiseWhite {...rest} />;
	}
	return <SafetyWiseColor {...rest} />;
};

Brand.propTypes = {
	theme: PropTypes.oneOf(["light", "colored"]),
};

export default Brand;
