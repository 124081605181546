import * as fromTypes from "./types";

export const organisationReset = (payload) => ({
	type: fromTypes.ORGANISATION_RESET,
	payload,
});

export const organisationFetch = (payload) => ({
	type: fromTypes.ORGANISATION_FETCH_REQUESTED,
	payload,
});

export const organisationFetchFailed = (payload) => ({
	type: fromTypes.ORGANISATION_FETCH_FAILED,
	payload,
});

export const organisationFetchSuccess = (payload) => ({
	type: fromTypes.ORGANISATION_FETCH_SUCCESS,
	payload,
});

// onChange Organisation
export const onCurrentOrganisationChange = (payload) => ({
	type: fromTypes.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED,
	payload,
});

export const onCurrentOrganisationChangeFailed = (payload) => ({
	type: fromTypes.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED_FAILED,
	payload,
});

export const onCurrentOrganisationChangeSuccess = (payload) => ({
	type: fromTypes.ORGANISATIONS_CURRENT_ORGANISATION_CHANGED_SUCCESS,
	payload,
});

// Edit Organisation
export const onEdit = (payload) => ({
	type: fromTypes.ORGANISATIONS_EDIT,
	payload,
});

export const onEditClose = (payload) => ({
	type: fromTypes.ORGANISATIONS_EDIT_CLOSE,
	payload,
});

export const onEditSave = (payload) => ({
	type: fromTypes.ORGANISATIONS_EDIT_SAVE,
	payload,
});

export const editSaveSuccess = (payload) => ({
	type: fromTypes.ORGANISATIONS_EDIT_SAVE_SUCCESS,
	payload,
});

export const editSaveFailed = (payload) => ({
	type: fromTypes.ORGANISATIONS_EDIT_SAVE_FAILED,
	payload,
});

export const onEditNameChange = (payload) => ({
	type: fromTypes.ORGANISATIONS_EDIT_NAME_CHANGED,
	payload,
});

// Delete
export const onDelete = (payload) => ({
	type: fromTypes.ORGANISATION_DELETE,
	payload,
});

export const organisationDeleteFailed = (payload) => ({
	type: fromTypes.ORGANISATION_DELETE_FAILED,
	payload,
});

export const organisationDeleteSuccess = (payload) => ({
	type: fromTypes.ORGANISATION_DELETE_SUCCESS,
	payload,
});

export const onSearchChanged = (payload) => ({
	type: fromTypes.ORGANISATION_SEARCH_CHANGED,
	payload,
});

// Update Preference
// for updating last visited organisation on organisation change
export const onOrganisationUpdatePreference = (payload) => ({
	type: fromTypes.ORGANISATION_UPDATE_PREFERENCE,
	payload,
});

export const organisationUpdatePreferenceFailed = (payload) => ({
	type: fromTypes.ORGANISATION_UPDATE_PREFERENCE_FAILED,
	payload,
});

export const organisationUpdatePreferenceSuccess = (payload) => ({
	type: fromTypes.ORGANISATION_UPDATE_PREFERENCE_SUCCESS,
	payload,
});

export const onOrganisationRefetchList = (payload) => ({
	type: fromTypes.ORGANISATION_REFETCH_LIST,
	payload,
});

export const organisationRefetchListFailed = (payload) => ({
	type: fromTypes.ORGANISATION_REFETCH_LIST_FAILED,
	payload,
});

export const organisationRefetchListSuccess = (payload) => ({
	type: fromTypes.ORGANISATION_REFETCH_LIST_SUCCESS,
	payload,
});

export const onOrganisationCleanup = () => ({
	type: fromTypes.ORGANISATION_CLEANUP,
});

export const onOrganisationsStatusFilter = (payload) => ({
	type: fromTypes.ORGANISATION_STATUS_FILTER,
	payload,
});
