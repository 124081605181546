import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRouter } from "utils";
import PageHeaderTabs from "../page-header-tabs";
import { Grid } from "@material-ui/core";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Page,
	PageContent,
	ScrollToTopOnMount,
} from "frame/components";
import {
	strip,
	EmailRecipientsForm,
	TitleSiteForm,
	NotesForm,
	PeopleForm,
	UploadFormCard,
} from "features/forms";
import { types as fromHealthAndSafety } from "features/health-and-safety";

const useStyles = makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(-2),
	},
	actions: {
		paddingBottom: theme.spacing(8),
	},
	menu: {
		padding: theme.spacing(3, 0, 0, 0),
		"& > *": {
			marginRight: theme.spacing(3),
		},
	},
}));

const Form = ({
	values,
	onChange,
	onReset,
	onSave,
	onGetSafetyMinutesList,
}) => {
	const classes = useStyles();
	const { history } = useRouter();

	useEffect(() => {
		onGetSafetyMinutesList();
	}, [onGetSafetyMinutesList]);

	const onSubmit = (event) => {
		event.preventDefault();
		const payload = strip(values, [
			"index",
			"locked",
			"save",
			"fetch",
			"siteId",
		]);
		onSave(payload);
	};

	const onCancel = () => {
		onReset();
		history.push(fromHealthAndSafety.TASK_ROUTE);
	};

	return (
		<Page title="Add Safety Minutes">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Safety Minutes"
				title="Add Safety Minutes"
				// addTabTitle="Add Safety Minutes"
				// listTabTitle="See all Safety Minutes"
				staffUserHide={false}
				listTabPath={fromHealthAndSafety.TASK_SAFETY_MINUTES_ROUTE}
				backActionPath={fromHealthAndSafety.TASK_ROUTE}
			/>
			<PageContent padSides padBottom padTop>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<Grid container spacing={3} className={classes.body}>
						<Grid item lg={6} md={12} sm={12} xs={12}>
							<div className={classes.body}>
								{/* <Typography
                  variant="body1">
                  Please note this form is not site specific
                  and should be used for safety meetings
                  relating to your business operations.
                </Typography> */}
								<TitleSiteForm
									values={values}
									onChange={onChange}
								/>
								<PeopleForm
									recordName="safety minutes"
									defaultStaffRole="Staff"
									values={values}
									onChange={onChange}
								/>
								<NotesForm
									values={values}
									onChange={onChange}
								/>
								<UploadFormCard
									title={"Add Attachments"}
									values={values}
									onChange={onChange}
								/>
								<EmailRecipientsForm
									values={values}
									onChange={onChange}
								/>
							</div>
						</Grid>
					</Grid>

					<FormActions className={classes.actions}>
						<FormSubmitButton
							disabled={values.locked}
							loading={values.save.saving}
						>
							Save
						</FormSubmitButton>
						<FormButton
							disabled={values.save.saving}
							onClick={onCancel}
						>
							Cancel
						</FormButton>
					</FormActions>
				</ValidatorForm>
			</PageContent>
		</Page>
	);
};

Form.propTypes = {
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onGetSafetyMinutesList: PropTypes.func.isRequired,
};

export default Form;
