import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Modal, Card, CardContent, Typography } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
	FormActions,
	FormButton,
	FormSubmitButton,
	Lookup,
} from "frame/components";
import { HazardsForm } from "features/forms";
import { isBornAccount } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	container: {
		marginTop: theme.spacing(3),
	},
	actions: {
		justifyContent: "flex-end",
		marginTop: theme.spacing(3),
	},
	warning: {
		padding: theme.spacing(1, 0),
		color: theme.palette.error.main,
	},
	emptyOption: {
		display: "none",
	},
}));

const AddSiteToHazardForm = ({
	siteId,
	values,
	onChange,
	onSave,
	isAddHazardToSiteModalShowing,
	onClose,
	email,
	preExistingSiteHazards,
}) => {
	const classes = useStyles();

	const onSubmit = (event) => {
		event.preventDefault();
		onSave(siteId);
		onClose();
	};

	const onCancel = () => onClose();

	if (!isAddHazardToSiteModalShowing) {
		return null;
	}

	const disableSave = false;

	return (
		<Modal open={true} onClose={onClose}>
			<Card className={classes.root}>
				<ValidatorForm
					autoComplete="off"
					noValidate
					onSubmit={onSubmit}
				>
					<CardContent>
						<Typography align="center" gutterBottom variant="h3">
							<Lookup
								id={siteId}
								resource="sites"
								attribute="siteName"
							/>
						</Typography>
						<HazardsForm
							values={values}
							onChange={onChange}
							preExistingSiteHazards={preExistingSiteHazards}
						/>

						<FormActions className={classes.actions}>
							<FormSubmitButton
								disabled={disableSave && !isBornAccount(email)}
							>
								Save
							</FormSubmitButton>
							<FormButton onClick={onCancel}>Cancel</FormButton>
						</FormActions>

						{/* <pre>
              {JSON.stringify({
                hazards: values.hazards,
              }, null ,2)}
            </pre> */}
					</CardContent>
				</ValidatorForm>
			</Card>
		</Modal>
	);
};

AddSiteToHazardForm.propTypes = {
	siteId: PropTypes.string.isRequired,
	isAddHazardToSiteModalShowing: PropTypes.bool.isRequired,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	email: PropTypes.string.isRequired,
	preExistingSiteHazards: PropTypes.array.isRequired,
};

export default AddSiteToHazardForm;
