import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import fromTypes from "../actions/types";
import { onBatchAddFailed, onBatchAddSuccess } from "../actions";
import { sanitize } from "utils";

const staffBatchAddEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.STAFF_BATCH_ADD),
		mergeMap(({ payload }) =>
			ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/staff/createorupdate`,
					{
						id: payload.id,
						organisationId:
							state$.value.organisations.currentOrganisation,
						firstName: sanitize(payload.firstName),
						lastName: sanitize(payload.lastName),
						email: sanitize(payload.email),
						phoneNumber: sanitize(payload.phoneNumber),
						emailInvitation:
							payload.status === "Active" ? true : false,
						status: sanitize(payload.status),
						staffIdNumber: sanitize(payload.staffIdNumber),
						organisationRole: sanitize(payload.organisationRole),
						organisationStaffTypeId:
							payload.organisationStaffTypeId,
						organisationStaffTypesStaffType:
							payload.organisationStaffTypesStaffType,
						supervisor: "",
						attachments: [],
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(onBatchAddSuccess(ajaxResponse))
					),
					catchError((error) => of(onBatchAddFailed(error)))
				)
		)
	);

export default staffBatchAddEffect;
