import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { STAFF_PATH } from "../actions/types";
import {
	Button,
	Card,
	CardContent,
	Typography,
	Grid,
	Chip,
} from "@material-ui/core";
import {
	isStaffUser,
	isSuperAdminUser,
	createRowSelector,
	isAccountAdminUser,
	useRouter,
} from "utils";
import { CrudOptions } from "frame/components";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { isInImpersonationMode } from "frame/access-token";
import { connect } from "react-redux";
import { safetyWiseApi } from "utils/safetyWiseApi";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
	},
	clickable: { cursor: "pointer" },
	container: {
		margin: theme.spacing(1, 0.5),
	},
	content: {
		padding: theme.spacing(1, 3),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		[theme.breakpoints.down("xs")]: {
			marginBottom: theme.spacing(4),
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	label: {
		padding: theme.spacing(0, 0, 0.5, 0),
		color: theme.palette.text.tertiary,
	},
	value: {
		padding: theme.spacing(0, 0, 0.5, 0),
	},
	options: {
		padding: theme.spacing(2),
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "flex-start",
		position: "absolute",
		right: 0,
		top: 0,
	},
	sendResetLinkArea: {
		position: "absolute",
		right: 0,
		bottom: 0,
		margin: theme.spacing(2),
		padding: theme.spacing(0.5, 1.25),
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.white,
	},
	success: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.white,
	},
}));

const StaffListing = ({
	staff,
	className,
	onEdit,
	onImpersonate,
	editable,
	onSendResetEmail,
	onSendConfirmationEmail,
	profile,
	staffList,
}) => {
	const classes = useStyles();
	const [sentResetEmail, setsentResetEmail] = useState(false);
	const [sentConfirmationEmail, setsentConfirmationEmail] = useState(false);
	const { history } = useRouter();
	const { id } = staff;

	// current user info
	const currentStaffId = profile.staffId;
	const role = profile.role;

	const onView = () => history.push(`${STAFF_PATH}/${id}`);
	const onRowSelect = createRowSelector(onView);

	const isCurrentUser = id === currentStaffId;

	const sendResetEmailHandler = () => {
		onSendResetEmail(staff.email);
		setsentResetEmail(true);
		setTimeout(() => {
			setsentResetEmail(false);
		}, 5000);
	};

	const sendConfirmationEmailHandler = () => {
		onSendConfirmationEmail(id);
		setsentConfirmationEmail(true);
		setTimeout(() => {
			setsentConfirmationEmail(false);
		}, 5000);
	};

	editable = isSuperAdminUser(role) ? true : editable;

	const isImpersonating = isInImpersonationMode();
	const supervisor =
		staffList && staffList.filter((s) => s.id === staff.supervisor)[0];
	const impersonateOption =
		!isImpersonating &&
		!isCurrentUser &&
		(isSuperAdminUser(role) || isAccountAdminUser(role))
			? [
					{
						label: "Impersonate",
						onClick: () => onImpersonate(id),
						icon: PeopleAltIcon,
					},
			  ]
			: [];

	return (
		<>
			<Card
				data-cy={`staff-${staff.firstName}-${staff.lastName}`}
				className={clsx(classes.root, className)}
			>
				<CardContent className={classes.content}>
					<Grid
						container
						spacing={2}
						className={clsx(classes.container, classes.clickable)}
						onClick={onRowSelect}
					>
						<Grid item lg={3} md={6} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Name
							</Typography>
							<Typography variant="h5">
								{staff.fullName || "-"}
							</Typography>
						</Grid>

						<Grid item lg={3} md={6} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Phone
							</Typography>
							<Typography className={classes.value} variant="h5">
								{staff.phoneNumber || "-"}
							</Typography>
						</Grid>

						<Grid item lg={3} md={6} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Email
							</Typography>
							<Typography className={classes.value} variant="h5">
								{staff.email || "-"}
							</Typography>
						</Grid>

						<Grid item lg={3} md={6} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Organisation Role
							</Typography>
							<Typography className={classes.value} variant="h5">
								{staff.organisationRole || "-"}
							</Typography>
						</Grid>

						<Grid item lg={3} xs={6}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Status
							</Typography>
							<Chip
								className={
									staff.status === "Active"
										? classes.success
										: classes.error
								}
								size="small"
								label={staff.status}
							/>
						</Grid>

						<Grid item lg={3} md={6} xs={6}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Staff ID Number
							</Typography>
							<Typography className={classes.value} variant="h5">
								{staff.staffIdNumber || "-"}
							</Typography>
						</Grid>

						<Grid item lg={3} md={6} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Supervisor
							</Typography>
							<Typography className={classes.value} variant="h5">
								{supervisor
									? `${supervisor.firstName} ${supervisor.lastName}`
									: "-"}
							</Typography>
						</Grid>

						<Grid item lg={3} md={6} xs={6}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Staff Type
							</Typography>
							<Typography className={classes.value} variant="h5">
								{staff.organisationStaffTypesStaffType || "-"}
							</Typography>
						</Grid>

						<Grid item lg={6} md={6} xs={12}>
							<Typography
								className={classes.label}
								variant="body2"
							>
								Notes
							</Typography>
							<Typography className={classes.value} variant="h5">
								{staff.notes}
							</Typography>
						</Grid>

						<Grid
							item
							lg={6}
							xs={12}
							className={"ignore"}
							justify={"flex-end"}
							alignItems={"flex-end"}
							container
						>
							{!isCurrentUser &&
								editable &&
								!staff.emailConfirmed && (
									<Button
										color="primary"
										size="small"
										variant="outlined"
										disabled={sentConfirmationEmail}
										onClick={sendConfirmationEmailHandler}
									>
										{sentConfirmationEmail
											? "Sent"
											: "Resend Confirmation Email"}
									</Button>
								)}

							{!isCurrentUser && editable && (
								<Button
									style={{ marginLeft: 10 }}
									color="primary"
									size="small"
									variant="outlined"
									disabled={sentResetEmail}
									onClick={sendResetEmailHandler}
								>
									{sentResetEmail
										? "Sent"
										: "Send Password Reset Email"}
								</Button>
							)}
						</Grid>
					</Grid>

					<div className="ignore">
						{!isStaffUser(profile.role) && editable && (
							<CrudOptions
								onEdit={() => onEdit(id)}
								customOptions={impersonateOption}
							/>
						)}
					</div>
				</CardContent>
			</Card>
		</>
	);
};

StaffListing.propTypes = {
	role: PropTypes.string,
	className: PropTypes.string,
	staff: PropTypes.object.isRequired,
	staffList: PropTypes.array.isRequired,
	onEdit: PropTypes.func.isRequired,
	editable: PropTypes.bool.isRequired,
	profile: PropTypes.object.isRequired,
	onSendResetEmail: PropTypes.func.isRequired,
	onSendConfirmationEmail: PropTypes.func.isRequired,
};

export default StaffListing;
