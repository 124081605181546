import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	CardContent,
	Checkbox,
	Grid,
	TextField,
	FormControlLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {},
	reminderLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginLeft: theme.spacing(-1.2),
	},
	label: {
		margin: 0,
	},
	formControlLabel: {
		userSelect: "none",
		marginLeft: theme.spacing(1),
	},
}));

const ReminderNotesForm = ({
	className,
	disabled,
	values,
	onChange,
	showNotesReminderCheckbox,
}) => {
	const classes = useStyles();

	const onInputChange =
		(key) =>
		({ target: { value } }) =>
			onChange({
				...values,
				[key]: value,
			});

	const onCheckChange = ({ target: { checked } }) =>
		onChange({
			...values,
			sendReminder: checked,
		});

	return (
		<CardContent>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<TextField
						id="notes"
						disabled={disabled}
						fullWidth
						label="Notes"
						multiline
						rows="3"
						value={values.hazardNotes || ""}
						variant="outlined"
						onChange={onInputChange("hazardNotes")}
					/>
				</Grid>
				{showNotesReminderCheckbox && (
					<Grid item xs={12}>
						<div className={classes.reminderLabel}>
							<FormControlLabel
								className={classes.formControlLabel}
								control={
									<Checkbox
										disabled={disabled}
										onChange={onCheckChange}
										value="yes"
										inputProps={{
											"aria-label": `Email me a reminder to review these Hazard Notes later.`,
										}}
									/>
								}
								// label={`Remind me to create Hazard(s) detailed under the notes field in the Hazards section of this form.`}
								label={`Email me a reminder to review these Hazard Notes later.`}
							/>
						</div>
					</Grid>
				)}
			</Grid>
		</CardContent>
	);
};

ReminderNotesForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	showNotesReminderCheckbox: PropTypes.bool,
};

export default ReminderNotesForm;
