import React from "react";
import LandingPageFrame from "./landing-page-frame";
import LoginForm from "./forms/login-form";

const Login = (props) => {
	return (
		<LandingPageFrame
			title={"Login"}
			form={<LoginForm {...props} />}
			hideSmallPrint
		/>
	);
};

export default Login;
