import { connect } from "react-redux";
import NavBar from "./navbar";

const mapStateToProps = ({ profile, organisations, jobs }) => ({
	role: profile.role,
	currentOrganisationGUID: organisations.currentOrganisation,
	globalMessage: jobs.globalMessage
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
