const environments = {
	localhost: "localhost",
	"safetywiseappuat.azurewebsites.net": "uat",
	"app-uat.safetywise.co.nz": "uat",
	"app-uat.fortherecord.pro": "uat",
	"app.safetywise.co.nz": "production",
	"app-prod.safetywise.co.nz": "production",
	"app.fortherecord.pro": "production",
	"app-prod.fortherecord.pro": "production"
};

const getDeviceName = () => {
	let device = "Unknown";
	const ua = {
		"Generic Linux": /Linux/i,
		Android: /Android/i,
		"Chrome OS": /CrOS/i,
		iPad: /iPad/i,
		iPhone: /iPhone/i,
		iPod: /iPod/i,
		macOS: /Macintosh/i,
		Windows: /IEMobile|Windows/i,
	};
	Object.keys(ua).map(
		(v) => navigator.userAgent.match(ua[v]) && (device = v)
	);
	return device;
};

export const rollbarConfig = {
	accessToken: "1502cf86d28840e7ab90aa4a11593087",
	captureUncaught: true,
	captureUnhandledRejections: true,
	verbose: true,
	payload: {
		environment:
			`${environments[window.location.hostname]} (${getDeviceName()})` ||
			`unknown-environment: ${
				window.location.hostname
			} (${getDeviceName()})`,
	},
};