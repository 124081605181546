const JOBS_ROUTE = "quick-pic";

export const JOBS_PATH = `/${JOBS_ROUTE}/list`;
export const ADD_JOB_PATH = `/${JOBS_ROUTE}/add`;

export const OLD_JOBS_PATH = `/jobs/list`;
export const OLD_ADD_JOB_PATH = `/jobs/add`;

export const JOBS_PAGED_LIST_REQUESTED = "JOBS_PAGED_LIST_REQUESTED";
export const JOBS_LIST_REQUESTED = "JOBS_LIST_REQUESTED";
export const JOBS_LIST_RECEIVED = "JOBS_LIST_RECEIVED";
export const JOBS_LIST_FAILED = "JOBS_LIST_FAILED";

export const JOBS_EDIT_REQUESTED = "JOBS_EDIT_REQUESTED";

export const JOBS_TITLE_CHANGED = "JOBS_TITLE_CHANGED";
export const JOBS_TYPE_CHANGED = "JOBS_TYPE_CHANGED";
export const JOBS_CUSTOMER_ID_CHANGED = "JOBS_CUSTOMER_ID_CHANGED";
export const JOBS_DATE_CHANGED = "JOBS_DATE_CHANGED";
export const JOBS_SITE_ID_CHANGED = "JOBS_SITE_ID_CHANGED";
export const JOBS_STAFF_ID_CHANGED = "JOBS_STAFF_ID_CHANGED";
export const JOBS_NOTES_CHANGED = "JOBS_NOTES_CHANGED";

export const JOBS_ADD_CLICKED = "JOBS_ADD_CLICKED";
export const JOBS_CLOSE_CLICKED = "JOBS_CLOSE_CLICKED";
export const JOBS_SAVE_CLICKED = "JOBS_SAVE_CLICKED";
export const JOBS_EDIT_CLICKED = "JOBS_EDIT_CLICKED";

export const JOBS_REMOVE_CLICKED = "JOBS_REMOVE_CLICKED";
export const JOBS_REMOVE_SUCCEEDED = "JOBS_REMOVE_SUCCEEDED";
export const JOBS_REMOVE_FAILED = "JOBS_REMOVE_FAILED";

export const JOBS_ADD_SUCCEEDED = "JOBS_ADD_SUCCEEDED";
export const JOBS_ADD_FAILED = "JOBS_ADD_FAILED";

export const JOBS_SEARCH_CLEAR = "JOBS_SEARCH_CLEAR";
export const JOBS_SEARCH_CHANGED = "JOBS_SEARCH_CHANGED";
export const FILE_CHANGED = "FILE_CHANGED";

export const JOBS_SAVE_QUEUED = "JOBS_SAVE_QUEUED";

export const SET_GLOBAL_MESSAGE =
	"SET_GLOBAL_MESSAGE";

export default {
	JOBS_PATH,
	ADD_JOB_PATH,

	JOBS_PAGED_LIST_REQUESTED,
	JOBS_LIST_REQUESTED,
	JOBS_LIST_RECEIVED,
	JOBS_EDIT_REQUESTED,

	JOBS_TITLE_CHANGED,
	JOBS_TYPE_CHANGED,
	JOBS_CUSTOMER_ID_CHANGED,
	JOBS_DATE_CHANGED,
	JOBS_SITE_ID_CHANGED,
	JOBS_STAFF_ID_CHANGED,
	JOBS_NOTES_CHANGED,

	JOBS_ADD_CLICKED,
	JOBS_ADD_SUCCEEDED,
	JOBS_CLOSE_CLICKED,
	JOBS_SAVE_CLICKED,
	JOBS_EDIT_CLICKED,
	JOBS_LIST_FAILED,
	JOBS_REMOVE_CLICKED,
	JOBS_REMOVE_SUCCEEDED,
	JOBS_REMOVE_FAILED,
	JOBS_ADD_FAILED,
	JOBS_SEARCH_CLEAR,
	JOBS_SEARCH_CHANGED,
	FILE_CHANGED,
	JOBS_SAVE_QUEUED,

	SET_GLOBAL_MESSAGE
};
