import { connect } from "react-redux";
import CustomerAddEdit from "./customer-add-edit";
import {
	onContactPersonChanged,
	onCustomerNameChanged,
	onEmailAddressChanged,
	onStatusChanged,
	onConsultantChanged,
	onClose,
	onSave,
} from "../actions";

const mapStateToProps = ({ customers }) => ({
	customer: customers.currentCustomer,
	isAddModalShowing: customers.isAddModalShowing,
	isEditModalShowing: customers.isEditModalShowing,
});

const mapDispatchToProps = {
	onContactPersonChanged,
	onCustomerNameChanged,
	onEmailAddressChanged,
	onStatusChanged,
	onConsultantChanged,
	onClose,
	onSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddEdit);
