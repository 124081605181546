import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Divider, Link } from "@material-ui/core";
import { FormSubmitButton } from "frame/components";
import * as fromAuth from "features/authorisation";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

const useStyles = makeStyles((theme) => ({
	divider: {
		margin: theme.spacing(2, 0),
	},
	title: {
		marginBottom: theme.spacing(2),
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	success: {
		fontSize: "16px",
		lineHeight: "24px",
		margin: "24px 0"
	},
}));

const ForgotPasswordForm = ({
	save,
	emailAddress,
	onForgotPassword,
	onChangeEmail,
	errorMessage,
}) => {
	const classes = useStyles();
	const { saving } = save;

	const onSubmit = (event) => {
		event.preventDefault();
		onForgotPassword(emailAddress);
	};

	const getValue = (cb) => (event) => cb(event.target.value);

	return (
		<>
			{
				errorMessage ? (
					<p className={classes.success}>{errorMessage}</p>
				) : (
					<ValidatorForm onSubmit={onSubmit}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextValidator
									fullWidth
									label="Email"
									name="email"
									disabled={saving}
									onChange={getValue(onChangeEmail)}
									value={emailAddress}
									variant="outlined"
									validators={["required", "isEmail"]}
									errorMessages={[
										"This field is required",
										"Must be a valid email",
									]}
								/>
							</Grid>
						</Grid>
						<FormSubmitButton
							className={classes.submitButton}
							disabled={!emailAddress}
							fullWidth
							loading={saving}
						>
							Reset Password
						</FormSubmitButton>
					</ValidatorForm>
				)
			}
			<Divider className={classes.divider} />
			<Link
				align="center"
				color="secondary"
				component={RouterLink}
				to={fromAuth.LOGIN_PATH}
				underline="always"
				variant="subtitle2"
			>
				Take me back to login
			</Link>
		</>
	);
};

ForgotPasswordForm.propTypes = {
	emailAddress: PropTypes.string,
	isValid: PropTypes.bool,
	onForgotPassword: PropTypes.func.isRequired,
	onChangeEmail: PropTypes.func.isRequired,
	errorMessage: PropTypes.string,
	save: PropTypes.exact({
		done: PropTypes.bool,
		error: PropTypes.string,
		saving: PropTypes.bool,
		success: PropTypes.bool,
	}),
};

export default ForgotPasswordForm;
