import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Avatar, Divider, Menu, MenuItem, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getInitials, useRouter, useIsBornAccount } from "utils";
import { isInImpersonationMode } from "frame/access-token";

const useStyles = makeStyles((theme) => ({
	root: {
		width: 300,
		maxWidth: "100%",
	},
	user: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "spaceBetween",
		alignItems: "center",
		padding: theme.spacing(3, 2),
		minWidth: 260,
	},
	avatarIcon: {
		position: "relative",
		paddingTop: 3,
		color: theme.palette.light,
		animation: "0.3s all ease-out",
		"&:hover": {
			color: theme.palette.white,
		},
	},
	userAvatar: {
		marginTop: theme.spacing(-2),
		width: 40,
		height: 40,
	},
	userInfo: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-start",
		paddingLeft: 10,
	},
	error: {
		color: theme.palette.error.main,
	},
	success: {
		color: theme.palette.success.main,
	},
	onlineIndicator: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginBottom: theme.spacing(-5),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(4),
	},
	onlineIndicatorIcon: {
		marginRight: "5px",
		width: "15px",
		height: "auto",
	},
}));

const AccountPopover = ({
	anchor,
	open,
	profile,
	onClose,
	onLogout,
	onAccount,
	onExitImpersonationMode,
	hasNetwork,
}) => {
	const classes = useStyles();
	const { history } = useRouter();
	const isBornAccount = useIsBornAccount();

	if (!anchor.current) {
		return null;
	}

	const hasProfile = !!(profile && Object.keys(profile).length);
	const name = hasProfile
		? String(`${profile.firstName || ""} ${profile.lastName || ""}`).trim()
		: "";

	const isImpersonating = isInImpersonationMode();

	return (
		<Menu
			id="menu-account"
			anchorEl={anchor.current}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={open}
			onClose={onClose}
		>
			<div className={classes.root}>
				{hasProfile && (
					<>
						<div className={classes.user}>
							{name ? (
								<Avatar
									alt="Person"
									className={classes.userAvatar}
									onClick={() =>
										isBornAccount
											? history.push("/secret")
											: null
									}
								>
									{getInitials(name)}
								</Avatar>
							) : (
								<Skeleton
									variant="circle"
									width={40}
									height={40}
								/>
							)}

							<div className={classes.userInfo}>
								{name ? (
									<>
										<Typography
											className={classes.name}
											variant="h6"
										>
											{isImpersonating
												? name + " (Impersonating)"
												: name}
										</Typography>
										<Typography variant="body2">
											{profile.email}
										</Typography>
										<Typography variant="body2">
											{profile.role}
										</Typography>
									</>
								) : (
									<>
										<Skeleton width={75} />
										<Skeleton width={145} />
									</>
								)}
								<div className={classes.onlineIndicator}>
									<FiberManualRecordIcon
										className={clsx(
											classes.onlineIndicatorIcon,
											hasNetwork
												? classes.success
												: classes.error
										)}
									/>
									<Typography variant="body2">
										{hasNetwork ? "ONLINE" : "OFFLINE"}
									</Typography>
								</div>
							</div>
						</div>
						<Divider />
					</>
				)}

				<MenuItem onClick={onAccount}>My account</MenuItem>
				{isImpersonating ? (
					<MenuItem onClick={onExitImpersonationMode}>
						Exit impersonation mode
					</MenuItem>
				) : (
					<MenuItem onClick={onLogout} data-cy={`logout-btn`}>
						Logout
					</MenuItem>
				)}
			</div>
		</Menu>
	);
};

AccountPopover.propTypes = {
	anchor: PropTypes.object,
	open: PropTypes.bool,
	profile: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		email: PropTypes.string,
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	onLogout: PropTypes.func.isRequired,
	onAccount: PropTypes.func.isRequired,
	hasNetwork: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ queue }) => ({
	hasNetwork: queue.hasNetwork,
});

export default connect(mapStateToProps)(AccountPopover);
