import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { formatDate, createRowSelector, isStaffUser } from "utils";
import { CrudOptions, Lookup } from "frame/components";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		position: "relative",
		marginBottom: theme.spacing(2),
		"&:hover": {
			cursor: "pointer",
		},
	},
	container: {
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(1),
	},
	content: {
		padding: theme.spacing(2),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(3),
		},
	},
	label: {
		color: "#999999",
	},
	image: {
		height: "auto",
		width: "100px",
	},
}));

const SafetyMinutesCard = ({
	role,
	safetyMinutes,
	onEdit,
	onView,
	onDelete,
	onEmail,
	readOnly,
}) => {
	const classes = useStyles();

	const onRowSelect = createRowSelector(onView);

	const id = {
		id: safetyMinutes.id,
		form: "safety-minutes",
	};

	return (
		<Card className={classes.root} onClick={onRowSelect}>
			<CardContent className={classes.content}>
				<Grid className={classes.container} container spacing={2}>
					<Grid item md={4} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Title
						</Typography>
						<Typography variant="h5">
							{safetyMinutes.title || "-"}
						</Typography>
					</Grid>

					<Grid item md={4} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Site Name
						</Typography>
						<Typography variant="h5">
							<Lookup
								id={safetyMinutes.siteId}
								resource="sites"
								attribute="siteName"
							/>
						</Typography>
					</Grid>

					<Grid item md={4} sm={6} xs={12}>
						<Typography variant="body2" className={classes.label}>
							Created
						</Typography>
						<Typography variant="h5">
							{formatDate(safetyMinutes.createdAt)}
						</Typography>
					</Grid>

					<div className="ignore">
						{!isStaffUser(role) && !readOnly && (
							<CrudOptions
								onEdit={onEdit}
								onView={onView}
								onEmail={onEmail}
								onDelete={() => onDelete(id)}
							/>
						)}
					</div>
				</Grid>
			</CardContent>
		</Card>
	);
};

SafetyMinutesCard.propTypes = {
	safetyMinutes: PropTypes.object.isRequired,
	onEdit: PropTypes.func.isRequired,
	onView: PropTypes.func.isRequired,
	onEmail: PropTypes.func,
	onDelete: PropTypes.func,
	role: PropTypes.string.isRequired,
	readOnly: PropTypes.bool,
};

export default SafetyMinutesCard;
