import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { ofType } from "redux-observable";
import { mergeMap, catchError } from "rxjs/operators";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import ReactGA from "react-ga";
import { isProd } from "utils";
import {
	onLookup,
	onAddHazardToSiteSaveFailed,
	onAddHazardToSiteSaveSuccess,
	ADD_HAZARD_TO_SITE_FORM_SUBMIT,
} from "features/sites";

const addHazardToSiteEffect = (action$, state$) =>
	action$.pipe(
		ofType(ADD_HAZARD_TO_SITE_FORM_SUBMIT),
		mergeMap(() => {
			return ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/site/createorupdate`,
					{
						id: state$.value.sites.currentSite.id,
						siteName: state$.value.sites.currentSite.siteName,
						customerId: state$.value.sites.currentSite.customerId,
						address: state$.value.sites.currentSite.address,
						suburb: state$.value.sites.currentSite.suburb,
						townCity: state$.value.sites.currentSite.townCity,
						siteContactPerson:
							state$.value.sites.currentSite.siteContactPerson,
						siteContactPhone:
							state$.value.sites.currentSite.siteContactPhone,
						siteContactEmail:
							state$.value.sites.currentSite.siteContactEmail,
						status: state$.value.sites.currentSite.status,
						organisationId:
							state$.value.organisations.currentOrganisation,
						hazards: state$.value.sites.currentSite.hazards,
						substances: state$.value.sites.currentSite.substances,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						isProd &&
							ReactGA.event({
								category: "Info",
								action: "Save Clicked",
								label: "Add Hazard",
							});

						return of(
							onLookup(state$.value.sites.currentSite.id),
							onAddHazardToSiteSaveSuccess()
						);
					}),
					/* eslint-disable */
					catchError((error) =>
						of(onAddHazardToSiteSaveFailed(error.message))
					)
					/* eslint-enable */
				);
		})
	);

export default addHazardToSiteEffect;
