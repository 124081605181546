import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { RemoveWarning, StyledIconButton } from "frame/components";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useRemover, useRouter } from "utils";
import { SITES_PATH } from "features/sites";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		marginBottom: theme.spacing(2),
		cursor: "pointer",
	},
	inactive: {
		// background: theme.palette.background.tertiary,
	},
	content: {
		padding: theme.spacing(2, 4),
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			flexWrap: "wrap",
		},
		"&:last-child": {
			paddingBottom: theme.spacing(2),
		},
	},
	label: {
		padding: theme.spacing(0, 0, 0.5, 0),
		color: theme.palette.text.dark,
	},
	value: {
		padding: theme.spacing(0, 0, 0.5, 0),
	},
	valueInactive: {
		color: theme.palette.text.tertiary,
	},
	sub: {
		fontSize: "85%",
		fontStyle: "italic",
	},
	actions: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "flex-start",
	},
	grid: {
		display: "grid",
		gridTemplateColumns: "auto 100px",
		gridGap: 0,
		width: "100%",
	},
	body: {
		display: "grid",
		gridTemplateColumns: "100%",
		gridGap: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			gridTemplateColumns: "50% 50%",
		},
		[theme.breakpoints.up("lg")]: {
			gridTemplateColumns: "20% 30% 30% 10%",
		},
		"& > div": {
			padding: theme.spacing(1, 0),
		},
	},
	options: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "flex-start",
		paddingTop: theme.spacing(1),
	},
}));

const SiteListing = ({ site, className, onEdit, onDelete, ...rest }) => {
	const classes = useStyles();
	const { history } = useRouter();
	const { remove, onPossiblyRemove, onCancelRemove } = useRemover();
	const { id } = site;

	const inactive = site && String(site.status).toLowerCase() === "inactive";

	const onSelect = (event) => {
		if (event.target.type !== "button") {
			history.push(`${SITES_PATH}/${id}`); // todo: dispatch push()
		}
	};

	const rootStyle = clsx(
		classes.root,
		inactive ? classes.inactive : "",
		className
	);

	const valueStyle = clsx(
		classes.value,
		inactive ? classes.valueInactive : ""
	);

	return (
		<>
			<RemoveWarning
				open={remove}
				onCancel={onCancelRemove}
				onConfirm={() => onDelete(id)}
			>
				Warning, deleting the site {'"'}
				{site.siteName}
				{'"'} is permanent and cannot be undone.
			</RemoveWarning>
			<Card {...rest} className={rootStyle} onClick={onSelect}>
				<CardContent className={classes.content}>
					<div className={classes.grid}>
						<div className={classes.body}>
							<div>
								<Typography
									className={classes.label}
									variant="body2"
								>
									Site Name
								</Typography>
								<Typography className={valueStyle} variant="h5">
									{site.siteName}
								</Typography>
							</div>
							<div>
								<Typography
									className={classes.label}
									variant="body2"
								>
									Address
								</Typography>
								{site.address && (
									<Typography
										className={valueStyle}
										variant="h5"
									>
										{site.address}
									</Typography>
								)}
								{site.suburb && (
									<Typography
										className={valueStyle}
										variant="h5"
									>
										{site.suburb}
									</Typography>
								)}
								{site.townCity && (
									<Typography
										className={valueStyle}
										variant="h5"
									>
										{site.townCity}
									</Typography>
								)}
								{!site.address &&
									!site.suburb &&
									!site.townCity &&
									"-"}
							</div>
							<div>
								<Typography
									className={classes.label}
									variant="body2"
								>
									Contact Person
								</Typography>
								{site.siteContactPerson && (
									<Typography
										className={valueStyle}
										variant="h5"
									>
										{site.siteContactPerson}
									</Typography>
								)}
								{site.siteContactPhone && (
									<Typography
										className={valueStyle}
										variant="h5"
									>
										{site.siteContactPhone}
									</Typography>
								)}
								{site.siteContactEmail && (
									<Typography
										className={valueStyle}
										variant="h5"
									>
										{site.siteContactEmail}
									</Typography>
								)}
								{!site.siteContactPerson &&
									!site.siteContactPhone &&
									!site.siteContactEmail &&
									"-"}
							</div>
							<div>
								<Typography
									className={classes.label}
									variant="body2"
								>
									Status
								</Typography>
								<Typography className={valueStyle} variant="h5">
									{site.status || "-"}
								</Typography>
							</div>
						</div>
						<div className={classes.options}>
							{!inactive && (
								<>
									<StyledIconButton
										onClick={() => onEdit(id)}
									>
										<EditIcon />
									</StyledIconButton>
									<StyledIconButton
										onClick={onPossiblyRemove}
									>
										<DeleteIcon />
									</StyledIconButton>
								</>
							)}
						</div>
					</div>
				</CardContent>
			</Card>
		</>
	);
};

SiteListing.propTypes = {
	className: PropTypes.string,
	site: PropTypes.object.isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default SiteListing;
