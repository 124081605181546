import React from "react";
import {
	SectionCard,
	AttachmentsSectionCard,
	ValueText,
	Dialog,
} from "frame/components";
import { Grid } from "@material-ui/core";

const DocumentPackViewModal = ({ documentPack, onClose, isOpen }) => {
	return (
		<Dialog
			title={"Document: " + documentPack.name}
			open={isOpen}
			onClose={onClose}
		>
			<SectionCard>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Name"
							value={documentPack.name || "-"}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Status"
							value={
								documentPack.isArchived ? "Archived" : "Active"
							}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<ValueText
							label="Notes"
							value={documentPack.notes || "-"}
							options={{ bold: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>
			<AttachmentsSectionCard values={documentPack} />
		</Dialog>
	);
};

export default DocumentPackViewModal;
