export const LOGIN_ROUTE = `/login`;
export const LOGOUT_ROUTE = `/logout`;
export const REGISTER_ROUTE = `/register`;
export const FORGOT_PASSWORD_ROUTE = `/forgot-password`;
export const SET_PASSWORD_ROUTE = `/set-password`;
export const RESET_PASSWORD_ROUTE = `/reset-password`;
export const CONFIRMATION_ROUTE = `/confirmation`;

export const LOGIN_PATH = `/login`;
export const LOGOUT_PATH = `/logout`;
export const REGISTER_PATH = `/register`;
export const FORGOT_PASSWORD_PATH = `/forgot-password`;
export const SET_PASSWORD_PATH = `/set-password`;
export const RESET_PASSWORD_PATH = `/reset-password`;
export const CONFIRMATION_PATH = `/confirmation`;

export const AUTHORISATION_LOGIN_REQUESTED = "AUTHORISATION_LOGIN_REQUESTED";
export const AUTHORISATION_LOGIN_FAILED = "AUTHORISATION_LOGIN_FAILED";
export const AUTHORISATION_LOGIN_FULFILLED = "AUTHORISATION_LOGIN_FULFILLED";

export const AUTHORISATION_LOGOUT_REQUESTED = "AUTHORISATION_LOGOUT_REQUESTED";
export const AUTHORISATION_LOGOUT_FULFILLED = "AUTHORISATION_LOGOUT_FULFILLED";

export const AUTHORISATION_REGISTER_CLEAR = "AUTHORISATION_REGISTER_CLEAR";
export const AUTHORISATION_REGISTER_ERROR_CLEAR =
	"AUTHORISATION_REGISTER_ERROR_CLEAR";
export const AUTHORISATION_REGISTER_REQUESTED =
	"AUTHORISATION_REGISTER_REQUESTED";
export const AUTHORISATION_REGISTER_FAILED = "AUTHORISATION_REGISTER_FAILED";
export const AUTHORISATION_REGISTER_FULFILLED =
	"AUTHORISATION_REGISTER_FULFILLED";

export const AUTHORISATION_TOKEN_RECEIVED = "AUTHORISATION_TOKEN_RECEIVED";
export const AUTHORISATION_EMAIL_ADDRESS_CHANGED =
	"AUTHORISATION_EMAIL_ADDRESS_CHANGED";
export const AUTHORISATION_PASSWORD_CHANGED = "AUTHORISATION_PASSWORD_CHANGED";
export const AUTHORISATION_LOGIN_CLICKED = "AUTHORISATION_LOGIN_CLICKED";

export const AUTHORISATION_ORGANISATION_CHANGED =
	"AUTHORISATION_ORGANISATION_CHANGED";
export const AUTHORISATION_FIRST_NAME_CHANGED =
	"AUTHORISATION_FIRST_NAME_CHANGED";
export const AUTHORISATION_LAST_NAME_CHANGED =
	"AUTHORISATION_LAST_NAME_CHANGED";
export const AUTHORISATION_PHONE_CHANGED = "AUTHORISATION_PHONE_CHANGED";
export const AUTHORISATION_CREDITCARD_CHANGED = "AUTHORISATION_CREDITCARD_CHANGED";
export const AUTHORISATION_REMEMBER_ME_CHANGED =
	"AUTHORISATION_REMEMBER_ME_CHANGED";

export const AUTHORISATION_FORGOT_PASSWORD_REQUESTED =
	"AUTHORISATION_FORGOT_PASSWORD_REQUESTED";
export const AUTHORISATION_FORGOT_PASSWORD_CHANGE =
	"AUTHORISATION_FORGOT_PASSWORD_CHANGE";
export const AUTHORISATION_FORGOT_PASSWORD_FULFILLED =
	"AUTHORISATION_FORGOT_PASSWORD_FULFILLED";
export const AUTHORISATION_FORGOT_PASSWORD_FAILED =
	"AUTHORISATION_FORGOT_PASSWORD_FAILED";

export const AUTHORISATION_SET_PASSWORD_REQUESTED =
	"AUTHORISATION_SET_PASSWORD_REQUESTED";
export const AUTHORISATION_SET_PASSWORD_PASSWORD_CHANGED =
	"AUTHORISATION_SET_PASSWORD_PASSWORD_CHANGED";
export const AUTHORISATION_SET_PASSWORD_CONFIRM_PASSWORD_CHANGED =
	"AUTHORISATION_SET_PASSWORD_CONFIRM_PASSWORD_CHANGED";
export const AUTHORISATION_SET_PASSWORD_FULFILLED =
	"AUTHORISATION_SET_PASSWORD_FULFILLED";
export const AUTHORISATION_SET_PASSWORD_FAILED =
	"AUTHORISATION_SET_PASSWORD_FAILED";

export const AUTHORISATION_RESET_PASSWORD_REQUESTED =
	"AUTHORISATION_RESET_PASSWORD_REQUESTED";
export const AUTHORISATION_RESET_PASSWORD_PASSWORD_CHANGED =
	"AUTHORISATION_RESET_PASSWORD_PASSWORD_CHANGED";
export const AUTHORISATION_RESET_PASSWORD_CONFIRM_PASSWORD_CHANGED =
	"AUTHORISATION_RESET_PASSWORD_CONFIRM_PASSWORD_CHANGED";
export const AUTHORISATION_RESET_PASSWORD_FULFILLED =
	"AUTHORISATION_RESET_PASSWORD_FULFILLED";
export const AUTHORISATION_RESET_PASSWORD_FAILED =
	"AUTHORISATION_RESET_PASSWORD_FAILED";

export const AUTHORISATION_CONFIRMATION = "AUTHORISATION_CONFIRMATION";
export const AUTHORISATION_CONFIRMATION_FULFILLED =
	"AUTHORISATION_CONFIRMATION_FULFILLED";
export const AUTHORISATION_CONFIRMATION_FAILED =
	"AUTHORISATION_CONFIRMATION_FAILED";

export const AUTHORISATION_SEND_RESET_EMAIL = "AUTHORISATION_SEND_RESET_EMAIL";
export const AUTHORISATION_SEND_RESET_EMAIL_FULFILLED =
	"AUTHORISATION_SEND_RESET_EMAIL_FULFILLED";
export const AUTHORISATION_SEND_RESET_EMAIL_FAILED =
	"AUTHORISATION_SEND_RESET_EMAIL_FAILED";

export const AUTHORISATION_SEND_CONFIRMATION_EMAIL =
	"AUTHORISATION_SEND_CONFIRMATION_EMAIL";
export const AUTHORISATION_SEND_CONFIRMATION_EMAIL_FULFILLED =
	"AUTHORISATION_SEND_CONFIRMATION_EMAIL_FULFILLED";
export const AUTHORISATION_SEND_CONFIRMATION_EMAIL_FAILED =
	"AUTHORISATION_SEND_CONFIRMATION_EMAIL_FAILED";

export const RESET = "RESET";

export default {
	LOGIN_ROUTE,
	LOGOUT_ROUTE,
	REGISTER_ROUTE,
	SET_PASSWORD_ROUTE,
	CONFIRMATION_ROUTE,
	LOGIN_PATH,
	LOGOUT_PATH,
	REGISTER_PATH,
	SET_PASSWORD_PATH,
	CONFIRMATION_PATH,

	AUTHORISATION_LOGIN_REQUESTED,
	AUTHORISATION_LOGIN_FAILED,
	AUTHORISATION_LOGIN_FULFILLED,

	AUTHORISATION_LOGOUT_REQUESTED,
	AUTHORISATION_LOGOUT_FULFILLED,

	AUTHORISATION_TOKEN_RECEIVED,
	AUTHORISATION_EMAIL_ADDRESS_CHANGED,
	AUTHORISATION_PASSWORD_CHANGED,
	AUTHORISATION_LOGIN_CLICKED,

	AUTHORISATION_REGISTER_CLEAR,
	AUTHORISATION_REGISTER_ERROR_CLEAR,
	AUTHORISATION_REGISTER_REQUESTED,
	AUTHORISATION_REGISTER_FAILED,
	AUTHORISATION_REGISTER_FULFILLED,

	AUTHORISATION_ORGANISATION_CHANGED,
	AUTHORISATION_FIRST_NAME_CHANGED,
	AUTHORISATION_LAST_NAME_CHANGED,
	AUTHORISATION_PHONE_CHANGED,
	AUTHORISATION_CREDITCARD_CHANGED,
	AUTHORISATION_REMEMBER_ME_CHANGED,

	AUTHORISATION_FORGOT_PASSWORD_REQUESTED,
	AUTHORISATION_FORGOT_PASSWORD_CHANGE,
	AUTHORISATION_FORGOT_PASSWORD_FULFILLED,
	AUTHORISATION_FORGOT_PASSWORD_FAILED,

	AUTHORISATION_SET_PASSWORD_REQUESTED,
	AUTHORISATION_SET_PASSWORD_PASSWORD_CHANGED,
	AUTHORISATION_SET_PASSWORD_CONFIRM_PASSWORD_CHANGED,
	AUTHORISATION_SET_PASSWORD_FULFILLED,
	AUTHORISATION_SET_PASSWORD_FAILED,

	AUTHORISATION_RESET_PASSWORD_REQUESTED,
	AUTHORISATION_RESET_PASSWORD_PASSWORD_CHANGED,
	AUTHORISATION_RESET_PASSWORD_CONFIRM_PASSWORD_CHANGED,
	AUTHORISATION_RESET_PASSWORD_FULFILLED,
	AUTHORISATION_RESET_PASSWORD_FAILED,

	AUTHORISATION_CONFIRMATION,
	AUTHORISATION_CONFIRMATION_FULFILLED,
	AUTHORISATION_CONFIRMATION_FAILED,

	AUTHORISATION_SEND_RESET_EMAIL,
	AUTHORISATION_SEND_RESET_EMAIL_FULFILLED,
	AUTHORISATION_SEND_RESET_EMAIL_FAILED,

	AUTHORISATION_SEND_CONFIRMATION_EMAIL,
	AUTHORISATION_SEND_CONFIRMATION_EMAIL_FULFILLED,
	AUTHORISATION_SEND_CONFIRMATION_EMAIL_FAILED,

	RESET,
};
