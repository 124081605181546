import * as R from "ramda";
import {
	defaultSaveState,
	saveRequested,
	saveFailed,
	saveSuccess,
} from "utils";

import * as fromTypes from "../actions/types";

const defaultState = {
	...defaultSaveState,
	responseMessage: "",
};

const report = (state = defaultState, action) => {
	switch (action.type) {
		case fromTypes.REPORT_DOWNLOAD: {
			return saveRequested(state);
		}
		case fromTypes.REPORT_DOWNLOAD_FAILED: {
			const nextState = saveFailed(state);
			return errorResponseMessage(R.clone(nextState), action);
		}
		case fromTypes.REPORT_DOWNLOAD_SUCCESS: {
			const nextState = saveSuccess(state);
			return successResponseMessage(R.clone(nextState), action);
		}
		case fromTypes.REPORT_DOWNLOAD_CLEAR_MESSAGE:
			return clearResponseMessage(R.clone(state), action);
		default:
			return state;
	}
};

const successResponseMessage = (state, action) => {
	state.responseMessage = "Report download successfully";
	return state;
};

const errorResponseMessage = (state, action) => {
	state.responseMessage = `Report download failed. ${action.payload}`;
	return state;
};
const clearResponseMessage = (state, action) => {
	state.responseMessage = "";
	return state;
};

export default report;
