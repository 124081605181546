import Types from "../actions/types";
import { ofType } from "redux-observable";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import { mergeMap, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { onAddSuccess, onAddFailed } from "../actions";
import ReactGA from "react-ga";
import { isProd } from "utils";

const customerAddEffect = (action$, state$) =>
	action$.pipe(
		ofType(Types.CUSTOMERS_SAVE_CLICKED),
		mergeMap(() =>
			ajax
				.post(
					`${getOrganisationBaseUrl(
						state$.value
					)}/customer/createorupdate`,
					{
						id: state$.value.customers.currentCustomer.id,
						customerName:
							state$.value.customers.currentCustomer.customerName,
						contactPerson:
							state$.value.customers.currentCustomer
								.contactPerson,
						emailAddress:
							state$.value.customers.currentCustomer.emailAddress,
						status: state$.value.customers.currentCustomer.status,
						consultant:
							state$.value.customers.currentCustomer.consultant,
						organisationId:
							state$.value.organisations.currentOrganisation,
					},
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) => {
						isProd &&
							ReactGA.event({
								category: "Info",
								action: "Save Clicked",
								label: "Add Customer",
							});
						return of(onAddSuccess(ajaxResponse.response));
					}),
					catchError((error) =>
						of(onAddFailed(error, "Customer was not saved"))
					)
				)
		)
	);

export default customerAddEffect;
