import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { TextValidator } from "react-material-ui-form-validator";
import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import { SelectInput, TitleWithHelperText } from "frame/components";
import MUIRichTextEditor from "mui-rte";
import { convertToRaw } from "draft-js";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	list: {
		padding: theme.spacing(0, 4),
		borderRadius: "5px",
		border: "1px solid",
		borderColor: "#D9D9D9",
		color: (props) => (props.disabled ? "#BDBDBD" : "#1C2B4A"),
		"&:hover": {
			borderColor: (props) => (props.disabled ? "#D9D9D9" : "#1C2B4A"),
			cursor: "auto",
		},
		"& #training_guidelines-toolbar": {
			marginLeft: theme.spacing(-3),
		},
	},
	errorText: {
		color: theme.palette.error.main,
		position: "absolute",
		zIndex: 99,
		marginTop: theme.spacing(-1),
	},
}));

const TrainingForm = (props) => {
	const classes = useStyles(props);
	const { values, onChange, disabled } = props;

	const training = values.training || {};
	const disabledField = values.save.saving || disabled;

	// used for rich text editer onload edit data
	const [initialGuidelines] = useState(training.guidelines || "");

	// used for rich text editer
	const [guidelines, setGuidelines] = useState(training.guidelines || "");

	const onInputChange =
		(key) =>
		({ target: { value } }) =>
			onChange({
				...values,
				training: {
					...training,
					[key]: value,
				},
			});

	const onSelectOption = (key) => (value) =>
		onChange({
			...values,
			training: {
				...training,
				[key]: value,
			},
		});

	const disableValidDuration =
		training.validMeasurement !== "Unlimited/Indefinite";

	const topicNameIsBlank = training.name && training.name.trim() === "";

	useEffect(() => {
		onChange({
			...values,
			training: {
				...training,
				guidelines: guidelines || "",
			},
		}); // eslint-disable-next-line
	}, [guidelines]);

	const onRTEChange = (key) => (event) => {
		const content =
			event.getCurrentContent() &&
			JSON.stringify(convertToRaw(event.getCurrentContent()));
		content && setGuidelines(content);
	};

	const toLower = (str) => str && str.toLowerCase();

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Training Topic"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextValidator
							id="training_topic_name"
							disabled={disabledField}
							fullWidth
							label="Training Topic Name *"
							value={training.name || ""}
							variant="outlined"
							validators={["required"]}
							errorMessages={["This field is required"]}
							onChange={onInputChange("name")}
						/>
					</Grid>
					<Grid item xs={12}>
						<span className={classes.errorText}>
							{topicNameIsBlank &&
								"Topic name cannot be white spaces"}
						</span>
					</Grid>
					<Grid item sm={disableValidDuration ? 8 : 12} xs={12}>
						<SelectInput
							id="training_validMeasurement"
							disabled={disabledField}
							label="Valid for *"
							options={[
								"Weeks",
								"Months",
								"Years",
								"Unlimited/Indefinite",
							]}
							value={training.validMeasurement || ""}
							onChange={onSelectOption("validMeasurement")}
						/>
					</Grid>
					{disableValidDuration && (
						<Grid item sm={4} xs={12}>
							<TextValidator
								id="training_validDuration"
								disabled={
									training.validMeasurement === "" ||
									disabledField
								}
								fullWidth
								label={
									!training.validMeasurement ||
									training.validMeasurement === ""
										? "Duration"
										: `Number of ${toLower(
												training.validMeasurement
										  )} *`
								}
								inputProps={{ maxLength: 6 }}
								value={training.validDuration || ""}
								variant="outlined"
								validators={["isNumber"]}
								errorMessages={["This must be a number"]}
								onChange={onInputChange("validDuration")}
							/>
						</Grid>
					)}

					<Grid item xs={12}>
						<TitleWithHelperText title="Training Guidelines" />

						<div className={classes.list}>
							<MUIRichTextEditor
								readOnly={disabledField}
								id="training_guidelines"
								label="Training Guidelines"
								controls={["bulletList"]}
								value={initialGuidelines}
								onChange={onRTEChange("guidelines")}
							/>
							<br />
							<br />
							<br />
						</div>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

TrainingForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	editMode: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default TrainingForm;
