import React from "react";
import LabelBottomNavigation from "./label-bottom-navigation";

export default function Footer() {
	return (
		<React.Fragment>
			<LabelBottomNavigation />
		</React.Fragment>
	);
}
