import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	Typography,
	Divider,
} from "@material-ui/core";
import { SelectInput, ValueText } from "frame/components";
import MUIRichTextEditor from "mui-rte";
import { isNotNullOrEmpty, formatDate, isValidContentState } from "utils";
import { useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
	},
	body: {
		marginTop: theme.spacing(-1),
	},
	errorText: {
		color: theme.palette.error.main,
		display: "absolute",
		zIndex: 99,
	},
	guidelines: {
		margin: theme.spacing(0, 3),
	},
}));

const TrainingForm = (props) => {
	const classes = useStyles(props);
	const { values, onChange, disabled, showError } = props;

	const training = values.training || {};
	const disabledField = values.save.saving || disabled;

	// used for rich text editer
	const [guidelines, setGuidelines] = useState(training.guidelines || "");
	const topicList = useSelector(
		({ healthSafety }) => healthSafety.staffTrainingTopics?.results
	);

	const onSelectOption = (key) => (value) =>
		onChange({
			...values,
			training: {
				...training,
				[key]: value,
			},
		});

	useEffect(() => {
		// display topic guidelines on topic dropdown change
		if (
			training.staffTrainingTopicId &&
			training.staffTrainingTopicId !== ""
		) {
			const topic = topicList.filter(
				(node) => node.id === training.staffTrainingTopicId
			)[0];
			setGuidelines(topic && topic.guidelines ? topic.guidelines : null);
		} else {
			setGuidelines(null);
		}
		// eslint-disable-next-line
	}, [training.staffTrainingTopicId]);

	const topicOptions = isNotNullOrEmpty(topicList)
		? topicList
				.map(({ id, name }) => ({
					label: name,
					value: id,
				}))
				.filter((x) => x.label && x.value)
		: [];

	const trainingTopicExpiryDate = (topicId) => {
		const topic = topicList.filter((node) => node.id === topicId)[0];
		if (topic) {
			if (topic.validMeasurement === "Unlimited/Indefinite") {
				return topic.validMeasurement;
			}
			const today = moment();
			const duration = parseInt(topic.validDuration, 10);
			const measurement = topic.validMeasurement.toLowerCase();
			const expiryDate = today.add(duration, measurement);
			return formatDate(expiryDate);
		}
	};

	return (
		<Card className={classes.root}>
			<CardHeader
				title="Training Topic"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent className={classes.body}>
				<Grid container spacing={2}>
					{showError && (
						<Grid item xs={12}>
							<Typography
								className={classes.errorText}
								variant="body1"
								align="left"
							>
								Please select a topic.
							</Typography>
						</Grid>
					)}
					<Grid item xs={12}>
						<SelectInput
							showError={showError}
							id="select_training_topic_name"
							disabled={disabledField}
							label="Training Topic Name *"
							options={topicOptions}
							value={training.staffTrainingTopicId || ""}
							onChange={onSelectOption("staffTrainingTopicId")}
						/>
					</Grid>

					<Grid item xs={12}>
						<ValueText
							label="Valid until"
							value={
								training.staffTrainingTopicId
									? trainingTopicExpiryDate(
											training.staffTrainingTopicId
									  )
									: "-"
							}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography align="left" gutterBottom variant="body1">
							<strong>Training Guidelines:</strong>{" "}
						</Typography>
						{guidelines && isValidContentState(guidelines) ? (
							<div className={classes.guidelines}>
								<MUIRichTextEditor
									readOnly
									controls={[]}
									value={guidelines}
								/>
							</div>
						) : (
							<Typography
								variant="body1"
								align="right"
								style={{ paddingRight: "5px" }}
							>
								{"-"}
							</Typography>
						)}
						<Divider style={{ marginTop: "5px" }} />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

TrainingForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	editMode: PropTypes.bool,
	disabled: PropTypes.bool,
	showError: PropTypes.bool,
};

export default TrainingForm;
