import { ofType } from "redux-observable";
import fromTypes from "../actions/types";
import {
	onUploadCompressionFailed,
	onUploadCompressionFinished,
} from "../actions";
import { mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { isUnsupportedFileExtension, isImageFile } from "utils";
import imageCompression from "browser-image-compression";
import { DBService, getTableName } from "frame/indexedDBService";

const formatImageForState = (file, dataURL, ignoreDataUrl) => {
	const db = new DBService();

	db.put(getTableName(), {
		id: file.id,
		fileName: file.name,
		fileSize: file.size,
		fileExtension: file.fileExtension,
		dataURL,
		createdAt: file.createdAt,
	});

	return {
		id: file.id,
		fileName: file.name,
		fileSize: file.size,
		fileExtension: file.fileExtension,
		dataURL: ignoreDataUrl ? "" : dataURL, // only quick is passing ignoreDataUrl param
		// other forms need to post dataUrl(base64 string) to api to store it, then returns file api url
		createdAt: file.createdAt,
	};
};

const uploadCompress = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.UPLOAD_COMPRESSION_REQUESTED),
		mergeMap(({ payload }) => {
			const file = payload.file;
			const MAX_SIZE = 50 * 1024 * 1024; // 50 MB

			if (isUnsupportedFileExtension(file.fileExtension))
				return of(
					onUploadCompressionFailed(
						`${file.fileExtension} files are not allowed.`
					)
				);

			if (file.size >= MAX_SIZE)
				return of(
					onUploadCompressionFailed(
						"Upload size exceeded (Limit: 50 MB). Please try again with smaller file."
					)
				);

			return isImageFile(file.fileExtension)
				? from(
						imageCompression(file, {
							maxSizeMB: 1,
							useWebWorker: true,
						})
				  ).pipe(
						mergeMap((compressedImage) => {
							return from(
								imageCompression.getDataUrlFromFile(
									compressedImage
								)
							).pipe(
								mergeMap((base64) => {
									return of(
										onUploadCompressionFinished(
											formatImageForState(
												file,
												base64,
												payload.ignoreDataUrl
											)
										)
									);
								})
							);
						})
				  )
				: of(
						onUploadCompressionFinished(
							formatImageForState(
								file,
								file.dataURL,
								payload.ignoreDataUrl
							)
						)
				  );
		})
	);

export default uploadCompress;
