import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { authHeaders, getOrganisationBaseUrl } from "frame";
import fromTypes from "../actions/types";

import {
	onSendConfirmationEmailFulfilled,
	onSendConfirmationEmailFailed,
} from "../actions";

const resendConfirmationEmailEffect = (action$, state$) =>
	action$.pipe(
		ofType(fromTypes.AUTHORISATION_SEND_CONFIRMATION_EMAIL),
		mergeMap(({ payload }) =>
			ajax
				.get(
					`${getOrganisationBaseUrl(
						state$.value
					)}/staff/${encodeURIComponent(
						payload
					)}/send-confirmation-email`,
					authHeaders()
				)
				.pipe(
					mergeMap((ajaxResponse) =>
						of(
							onSendConfirmationEmailFulfilled(
								ajaxResponse.response
							)
						)
					),
					catchError((error) => onSendConfirmationEmailFailed(error))
				)
		)
	);

export default resendConfirmationEmailEffect;
