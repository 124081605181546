import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import {
	Page,
	PageContent,
	ScrollToTopOnMount,
	SearchBar,
	Pagination,
	PaginationHeader,
	ListFilterOptions,
} from "frame/components";
import HazardousSubstanceCard from "../cards/hazardous-substance-card";
import {
	EditHazardousSubstanceForm,
	PageHeaderTabs,
} from "features/health-safety-forms";
import { getPageResults } from "utils";
import * as fromHealthSafetyForm from "features/health-safety-forms";

const useStyles = makeStyles((theme) => ({
	root: {},
	filter: { display: "flex" },
}));

const HazardousSubstanceList = ({
	role,
	hazardousSubstances,
	onDelete,
	onGetHazardousSubstanceList,
}) => {
	const classes = useStyles();
	const [viewId, setViewId] = useState();
	const [editId, setEditId] = useState();
	const [searchFilter, setSearchFilter] = useState("");

	const hazardousSubstanceList = getPageResults(hazardousSubstances);
	const empty = hazardousSubstanceList && hazardousSubstanceList.length === 0;

	const onView = (id) => () => setViewId(id);

	const onEdit = (id) => () => setEditId(id);

	const onCancelEdit = () => {
		setEditId(null);
		setViewId(null);
	};

	return (
		<Page className={classes.root} title="Hazardous Substance Library">
			<ScrollToTopOnMount />
			<PageHeaderTabs
				// breadcrumbs="Hazardous Substances"
				title="Hazardous Substance Library"
				// addTabTitle="New"
				// listTabTitle="See All"
				addTabPath={fromHealthSafetyForm.ADD_HAZARDOUS_SUBSTANCE_PATH}
				backActionPath={fromHealthSafetyForm.INFO_ROUTE}
			/>

			<div className={classes.filter}>
				<SearchBar onSearch={setSearchFilter} />
				<ListFilterOptions
					useArchive
					filterKey="status"
					onGetList={onGetHazardousSubstanceList}
					pagination={hazardousSubstances}
					orderBy="createdAt"
					ascending={false}
				/>
			</div>

			<PageContent pad>
				<PaginationHeader
					hidePagination
					pagination={hazardousSubstances}
				/>

				{!empty &&
					hazardousSubstanceList.map((hazardousSubstance, index) => (
						<HazardousSubstanceCard
							key={index}
							hazardousSubstance={hazardousSubstance}
							role={role}
							onView={onView(hazardousSubstance.id)}
							onEdit={onEdit(hazardousSubstance.id)}
							onDelete={onDelete}
						/>
					))}

				{empty && (
					<Typography variant="body1">
						There are currently no Hazardous Substances listed.
					</Typography>
				)}

				<Pagination
					hidePagination
					onGetList={onGetHazardousSubstanceList}
					pagination={hazardousSubstances}
					orderBy="createdAt"
					ascending={false}
					searchKey={searchFilter}
				/>
			</PageContent>

			{viewId && (
				<EditHazardousSubstanceForm
					readOnly
					id={viewId}
					onClose={onCancelEdit}
				/>
			)}
			{editId && (
				<EditHazardousSubstanceForm
					id={editId}
					onClose={onCancelEdit}
				/>
			)}
		</Page>
	);
};

HazardousSubstanceList.propTypes = {
	role: PropTypes.string.isRequired,
	hazardousSubstances: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
	onGetHazardousSubstanceList: PropTypes.func.isRequired,
};

export default HazardousSubstanceList;
