import React from "react";
import PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";

import { SectionCard, ValueText } from "frame/components";

import { formatDate } from "utils";

const ReadOnlyForm = ({ values }) => {
	const training = values.training || {};

	const trainingTopicDuration = (topic) => {
		let duration = "";
		const isIndefinite = topic.validMeasurement === "Unlimited/Indefinite";

		if (isIndefinite) {
			return topic.validMeasurement;
		}

		duration += topic.validDuration ? `${topic.validDuration}` : "";
		duration += topic.validMeasurement ? ` ${topic.validMeasurement}` : "";

		return duration !== "" ? duration : "";
	};

	return (
		<>
			<SectionCard title="Training Topic">
				<Typography align="left" gutterBottom variant="body1">
					<Grid container spacing={2}>
						<Grid item sm={12} xs={12}>
							<ValueText
								label="Date"
								value={formatDate(values.createdAt)}
								options={{ bold: true }}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<ValueText
								label="Topic Name"
								value={training.name}
								options={{ bold: true }}
							/>
						</Grid>
						<Grid item sm={6} xs={12}>
							<ValueText
								label="Topic Duration"
								value={trainingTopicDuration(training)}
								options={{ bold: true }}
							/>
						</Grid>
						<Grid item sm={12} xs={12}>
							<ValueText
								label="Training Guidelines"
								value={training.guidelines}
								options={{ bold: true, contentState: true }}
							/>
						</Grid>
					</Grid>
				</Typography>
			</SectionCard>
		</>
	);
};

ReadOnlyForm.propTypes = {
	values: PropTypes.object.isRequired,
};

export default ReadOnlyForm;
