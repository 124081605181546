import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	root: {},
}));

const RadioList = ({
	className,
	disabled,
	initialValue = "",
	options,
	value,
	onChange,
	dataCy,
}) => {
	const classes = useStyles();

	const onSelect = (event) => onChange(event.target.value);

	return (
		<div className={classes.root}>
			<RadioGroup
				aria-label="position"
				name="position"
				row
				value={value || initialValue}
			>
				{options.map((option, index) => {
					const name = option.label || option;
					const code = option.value || option;

					return (
						<FormControlLabel
							data-cy={dataCy}
							key={index}
							control={
								<Radio disabled={disabled} color="primary" />
							}
							label={name}
							labelPlacement="end"
							value={code}
							onChange={onSelect}
						/>
					);
				})}
			</RadioGroup>
		</div>
	);
};

RadioList.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	dataCy: PropTypes.string,
	options: PropTypes.array.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func.isRequired,
};

export default RadioList;
