import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";

import { RadioListInput } from "frame/components/input";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(1, 0, 3, 0),
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0),
		height: 2,
	},
}));

const TrainingInductionForm = ({
	className,
	disabled,
	values,
	onSelectChange,
}) => {
	const classes = useStyles();

	const options = [
		{
			label: "Yes",
			value: "yes",
		},
		{
			label: "NA",
			value: "na",
		},
	];

	return (
		<CardContent className={classes.root}>
			<Divider className={classes.divider} />
			<CardHeader
				className={classes.heading}
				title="Training/Inductions"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						All persons under our control hold relevant Health and
						Safety qualifications.
					</Typography>
					<RadioListInput
						data-cy={"allHoldHealthSafetyQualifications"}
						disabled={disabled}
						initialValue={values.allHoldHealthSafetyQualifications}
						options={options}
						onChange={onSelectChange(
							"allHoldHealthSafetyQualifications"
						)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						All persons under our control are given a safety
						induction.
					</Typography>
					<RadioListInput
						data-cy={"allGivenSafetyInduction"}
						disabled={disabled}
						initialValue={values.allGivenSafetyInduction}
						options={options}
						onChange={onSelectChange("allGivenSafetyInduction")}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						All persons under our control are apporpriately
						qualified, competent or fully supervised and
						documentation can be provided.
					</Typography>
					<RadioListInput
						data-cy={"allAppropriatelyQualified"}
						disabled={disabled}
						initialValue={values.allAppropriatelyQualified}
						options={options}
						onChange={onSelectChange("allAppropriatelyQualified")}
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

TrainingInductionForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onSelectChange: PropTypes.func.isRequired,
};

export default TrainingInductionForm;
