import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	colors,
	Modal,
	Card,
	CardContent,
	CardActions,
	Typography,
	Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%",
	},
	body: {
		padding: theme.spacing(2, 0),
	},
	actions: {
		marginBottom: theme.spacing(2),
	},
	cancel: {
		padding: theme.spacing(0.6, 3),
		background: colors.grey[300],
		borderColor: colors.grey[300],
		"&:hover": {
			background: colors.grey[400],
			borderColor: colors.grey[400],
		},
	},
	warning: {
		padding: theme.spacing(0.6, 3),
		color: theme.palette.white,
		background: theme.palette.error.main,
		borderColor: theme.palette.error.main,
		"&:hover": {
			background: theme.palette.error.dark,
			borderColor: theme.palette.error.dark,
		},
	},
}));

const RemoveWarning = ({
	children,
	className,
	open,
	title,
	onCancel,
	onConfirm,
	...rest
}) => {
	const classes = useStyles();

	const onContinue = () => {
		onCancel();
		onConfirm();
	};

	return (
		<Modal open={open} onClose={onCancel}>
			<Card {...rest} className={clsx(classes.root, className)}>
				<CardContent>
					<Typography variant="h2">
						{title || "Confirm Delete"}
					</Typography>
					<Typography className={classes.body} variant="body1">
						{children ||
							"Confirm you intend to delete this. It will be removed permanently."}
					</Typography>
				</CardContent>

				<CardActions className={classes.actions}>
					<Button
						data-cy={`delete-btn`}
						className={classes.warning}
						variant="contained"
						onClick={onContinue}
					>
						Yes, Delete
					</Button>
					<Button
						data-cy={`cancel-btn`}
						className={classes.cancel}
						variant="contained"
						onClick={onCancel}
					>
						No, Cancel
					</Button>
				</CardActions>
			</Card>
		</Modal>
	);
};

RemoveWarning.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	open: PropTypes.bool,
	title: PropTypes.string,
	onCancel: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
};

export default RemoveWarning;
