import moment from "moment";
import uuidv4 from "uuid/v4";

export const job = {
	id: uuidv4(),
	syncing: true,
	title: "",
	createdAt: moment().utc().format(),
	type: "",
	customerId: "",
	siteId: "",
	staffId: "",
	notes: "",
	attachments: [],
	syncedAt: null,
	isValid: false,
	creatorId: "",
};

export const createJobDefaults = () => {
	const id = uuidv4();
	return {
		...job,
		id,
	};
};

export default job;
