import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import {
	CardContent,
	CardHeader,
	Divider,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(1, 0, 3, 0),
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0),
		height: 2,
	},
	button: {
		margin: theme.spacing(2, 0),
	},
	small: {
		fontSize: "85%",
	},
}));

const ApprovalToStartWorkForm = ({
	className,
	disabled,
	values,
	onInputChange,
	onSelectChange,
}) => {
	const classes = useStyles();

	const onSelectDate = (key) => (value) =>
		onSelectChange(key)(value.utc().format());

	const isDefaultDate = (date) => moment(date).format("YYYY") === "0001";
	const dateValue = (date) =>
		date ? (!isDefaultDate(date) ? date : null) : null;

	return (
		<CardContent className={classes.root}>
			<Divider className={classes.divider} />
			<CardHeader
				className={classes.heading}
				title="Approval to start work"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						Signed on behalf of PCBU1 (Principle)
						<br />
						To be signed by a representative of PCBU1 when all
						pre-start documentation has been provided and approved.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="safety_plan_approval_name"
						disabled={disabled}
						fullWidth
						label="Name"
						value={values.approvalName || ""}
						variant="outlined"
						onChange={onInputChange("approvalName")}
					/>
				</Grid>
				<Grid item xs={12}>
					<DatePicker
						className={classes.field}
						disabled={disabled}
						disableFuture
						format="Do MMMM YYYY"
						fullWidth
						inputVariant="outlined"
						label="Date"
						value={dateValue(values.approvalDate)}
						variant="outlined"
						onChange={onSelectDate("approvalDate")}
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

ApprovalToStartWorkForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onInputChange: PropTypes.func.isRequired,
	onSelectChange: PropTypes.func.isRequired,
};

export default ApprovalToStartWorkForm;
