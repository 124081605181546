import { connect } from "react-redux";
import HazardSiteList from "./hazard-site-list";
import {
	onAddHazardToSite,
	onEditHazardToSite,
	onRemoveHazardFromSite,
} from "features/sites";

const mapStateToProps = ({ sites, profile }) => ({
	values: sites.currentSite,
	role: profile.role,
});

const mapDispatchToProps = (dispatch) => ({
	onAdd: () => {
		dispatch(onAddHazardToSite());
	},
	onEdit: () => {
		dispatch(onEditHazardToSite());
	},
	onDelete: (payload) => {
		dispatch(onRemoveHazardFromSite(payload));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(HazardSiteList);
