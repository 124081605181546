export const HEALTH_SAFETY_ROUTE = `/health-safety`;

export const INFO_ROUTE = `/info`;
export const TASK_ROUTE = `/tasks`;

// info routes
export const INFO_HAZARDS_ROUTE = `${INFO_ROUTE}/hazards`;
export const INFO_HAZARDOUS_SUBSTANCES_ROUTE = `${INFO_ROUTE}/hazardous-substances`;
export const INFO_STAFF_TRAINING_TOPIC_ROUTE = `${INFO_ROUTE}/staff-training-topics`;
export const INFO_JOB_SAFETY_ANALYSIS_ROUTE = `${INFO_ROUTE}/job-safety-analysis`;
export const INFO_SAFETY_PLAN_CHECKLISTS_ROUTE = `${INFO_ROUTE}/safety-plan-checklists`;

// task routes
export const TASK_SAFETY_MINUTES_ROUTE = `${TASK_ROUTE}/safety-minutes`;
export const TASK_INCIDENTS_ROUTE = `${TASK_ROUTE}/incidents`;
export const TASK_INDUCTION_MINUTES_ROUTE = `${TASK_ROUTE}/induction-meetings`;
export const TASK_STAFF_TRAINING_ROUTE = `${TASK_ROUTE}/staff-trainings`;

// old routes
const HEALTH_SAFETY_LIST_ROUTE = `${HEALTH_SAFETY_ROUTE}/list`;
const HEALTH_SAFETY_TIMESHEETS_ROUTE = `${HEALTH_SAFETY_ROUTE}/timesheets`;
const HEALTH_SAFETY_INCIDENTS_ROUTE = `${HEALTH_SAFETY_ROUTE}/accidents`;
const HEALTH_SAFETY_HAZARDS_ROUTE = `${HEALTH_SAFETY_ROUTE}/hazards`;
const HEALTH_SAFETY_HAZARDOUS_SUBSTANCES_ROUTE = `${HEALTH_SAFETY_ROUTE}/hazardous-substances`;
const HEALTH_SAFETY_INDUCTION_MINUTES_ROUTE = `${HEALTH_SAFETY_ROUTE}/induction-minutes`;
const HEALTH_SAFETY_SAFETY_MINUTES_ROUTE = `${HEALTH_SAFETY_ROUTE}/safety-minutes`;
const HEALTH_SAFETY_JOB_SAFETY_ANALYSIS_ROUTE = `${HEALTH_SAFETY_ROUTE}/job-safety-analysis`;
const HEALTH_SAFETY_SAFETY_PLAN_CHECKLISTS_ROUTE = `${HEALTH_SAFETY_ROUTE}/safety-plan-checklists`;
const HEALTH_SAFETY_STAFF_TRAINING_ROUTE = `${HEALTH_SAFETY_ROUTE}/staff-trainings`;
const HEALTH_SAFETY_STAFF_TRAINING_TOPIC_ROUTE = `${HEALTH_SAFETY_ROUTE}/staff-training-topics`;

export const HEALTH_SAFETY_PATH = `/health-safety`;
export const HEALTH_SAFETY_LIST_PATH = `${HEALTH_SAFETY_ROUTE}/list`;
export const HEALTH_SAFETY_TIMESHEETS_PATH = `${HEALTH_SAFETY_ROUTE}/timesheets`;
export const HEALTH_SAFETY_INCIDENTS_PATH = `${HEALTH_SAFETY_ROUTE}/accidents`;
export const HEALTH_SAFETY_HAZARDS_PATH = `${HEALTH_SAFETY_ROUTE}/hazards`;
export const HEALTH_SAFETY_HAZARDOUS_SUBSTANCES_PATH = `${HEALTH_SAFETY_ROUTE}/hazardous-substances`;
export const HEALTH_SAFETY_INDUCTION_MINUTES_PATH = `${HEALTH_SAFETY_ROUTE}/induction-meetings`;
export const HEALTH_SAFETY_SAFETY_MINUTES_PATH = `${HEALTH_SAFETY_ROUTE}/safety-minutes`;
export const HEALTH_SAFETY_JOB_SAFETY_ANALYSIS_PATH = `${HEALTH_SAFETY_ROUTE}/job-safety-analysis`;
export const HEALTH_SAFETY_SAFETY_PLAN_CHECKLISTS_PATH = `${HEALTH_SAFETY_ROUTE}/safety-plan-checklists`;
export const HEALTH_SAFETY_STAFF_TRAINING_PATH = `${HEALTH_SAFETY_ROUTE}/staff-trainings`;
export const HEALTH_SAFETY_STAFF_TRAINING_TOPIC_PATH = `${HEALTH_SAFETY_ROUTE}/staff-training-topics`;

const HEALTH_SAFETY_CLEAR_SEARCH = "HEALTH_SAFETY_CLEAR_SEARCH";

const HAZARDS_PAGED_LIST_REQUESTED = "HAZARDS_PAGED_LIST_REQUESTED";
const HAZARDS_LIST_REQUESTED = "HAZARDS_LIST_REQUESTED";
const HAZARDS_LIST_RECEIVED = "HAZARDS_LIST_RECEIVED";
const HAZARDS_LIST_FAILED = "HAZARDS_LIST_FAILED";
const HAZARDS_SEARCH_CHANGED = "HAZARDS_SEARCH_CHANGED";
const HAZARDS_LOOKUP = "HAZARDS_LOOKUP";
const HAZARDS_LOOKUP_SUCCESS = "HAZARDS_LOOKUP_SUCCESS";
const HAZARDS_LOOKUP_FAILED = "HAZARDS_LOOKUP_FAILED";

const HAZARDOUS_SUBSTANCES_PAGED_LIST_REQUESTED =
	"HAZARDOUS_SUBSTANCES_PAGED_LIST_REQUESTED";
const HAZARDOUS_SUBSTANCES_LIST_REQUESTED =
	"HAZARDOUS_SUBSTANCES_LIST_REQUESTED";
const HAZARDOUS_SUBSTANCES_LIST_RECEIVED = "HAZARDOUS_SUBSTANCES_LIST_RECEIVED";
const HAZARDOUS_SUBSTANCES_LIST_FAILED = "HAZARDOUS_SUBSTANCES_LIST_FAILED";
const HAZARDOUS_SUBSTANCES_SEARCH_CHANGED =
	"HAZARDOUS_SUBSTANCES_SEARCH_CHANGED";
const HAZARDOUS_SUBSTANCES_LOOKUP = "HAZARDOUS_SUBSTANCES_LOOKUP";
const HAZARDOUS_SUBSTANCES_LOOKUP_SUCCESS =
	"HAZARDOUS_SUBSTANCES_LOOKUP_SUCCESS";
const HAZARDOUS_SUBSTANCES_LOOKUP_FAILED = "HAZARDOUS_SUBSTANCES_LOOKUP_FAILED";

const INCIDENTS_PAGED_LIST_REQUESTED = "INCIDENTS_PAGED_LIST_REQUESTED";
const INCIDENTS_LIST_REQUESTED = "INCIDENTS_LIST_REQUESTED";
const INCIDENTS_LIST_RECEIVED = "INCIDENTS_LIST_RECEIVED";
const INCIDENTS_LIST_FAILED = "INCIDENTS_LIST_FAILED";
const INCIDENTS_SEARCH_CHANGED = "INCIDENTS_SEARCH_CHANGED";
const ACCIDENTS_LOOKUP = "ACCIDENTS_LOOKUP";
const ACCIDENTS_LOOKUP_SUCCESS = "ACCIDENTS_LOOKUP_SUCCESS";
const ACCIDENTS_LOOKUP_FAILED = "ACCIDENTS_LOOKUP_FAILED";

const INDUCTION_MINUTES_PAGED_LIST_REQUESTED =
	"INDUCTION_MINUTES_PAGED_LIST_REQUESTED";
const INDUCTION_MINUTES_LIST_REQUESTED = "INDUCTION_MINUTES_LIST_REQUESTED";
const INDUCTION_MINUTES_LIST_RECEIVED = "INDUCTION_MINUTES_LIST_RECEIVED";
const INDUCTION_MINUTES_LIST_FAILED = "INDUCTION_MINUTES_LIST_FAILED";
const INDUCTION_MINUTES_SEARCH_CHANGED = "INDUCTION_MINUTES_SEARCH_CHANGED";
const INDUCTION_MINUTES_LOOKUP = "INDUCTION_MINUTES_LOOKUP";
const INDUCTION_MINUTES_LOOKUP_SUCCESS = "INDUCTION_MINUTES_LOOKUP_SUCCESS";
const INDUCTION_MINUTES_LOOKUP_FAILED = "INDUCTION_MINUTES_LOOKUP_FAILED";

const SAFETY_MINUTES_PAGED_LIST_REQUESTED =
	"SAFETY_MINUTES_PAGED_LIST_REQUESTED";
const SAFETY_MINUTES_LIST_REQUESTED = "SAFETY_MINUTES_LIST_REQUESTED";
const SAFETY_MINUTES_LIST_RECEIVED = "SAFETY_MINUTES_LIST_RECEIVED";
const SAFETY_MINUTES_LIST_FAILED = "SAFETY_MINUTES_LIST_FAILED";
const SAFETY_MINUTES_SEARCH_CHANGED = "SAFETY_MINUTES_SEARCH_CHANGED";
const SAFETY_MINUTES_LOOKUP = "SAFETY_MINUTES_LOOKUP";
const SAFETY_MINUTES_LOOKUP_SUCCESS = "SAFETY_MINUTES_LOOKUP_SUCCESS";
const SAFETY_MINUTES_LOOKUP_FAILED = "SAFETY_MINUTES_LOOKUP_FAILED";

const JOB_SAFETY_ANALYSIS_PAGED_LIST_REQUESTED =
	"JOB_SAFETY_ANALYSIS_PAGED_LIST_REQUESTED";
const JOB_SAFETY_ANALYSIS_LIST_REQUESTED = "JOB_SAFETY_ANALYSIS_LIST_REQUESTED";
const JOB_SAFETY_ANALYSIS_LIST_RECEIVED = "JOB_SAFETY_ANALYSIS_LIST_RECEIVED";
const JOB_SAFETY_ANALYSIS_LIST_FAILED = "JOB_SAFETY_ANALYSIS_LIST_FAILED";
const JOB_SAFETY_ANALYSIS_SEARCH_CHANGED = "JOB_SAFETY_ANALYSIS_SEARCH_CHANGED";
const JOB_SAFETY_ANALYSIS_LOOKUP = "JOB_SAFETY_ANALYSIS_LOOKUP";
const JOB_SAFETY_ANALYSIS_LOOKUP_SUCCESS = "JOB_SAFETY_ANALYSIS_LOOKUP_SUCCESS";
const JOB_SAFETY_ANALYSIS_LOOKUP_FAILED = "JOB_SAFETY_ANALYSIS_LOOKUP_FAILED";

const SAFETY_PLAN_CHECKLIST_PAGED_LIST_REQUESTED =
	"SAFETY_PLAN_CHECKLIST_PAGED_LIST_REQUESTED";
const SAFETY_PLAN_CHECKLIST_LIST_REQUESTED =
	"SAFETY_PLAN_CHECKLIST_LIST_REQUESTED";
const SAFETY_PLAN_CHECKLIST_LIST_RECEIVED =
	"SAFETY_PLAN_CHECKLIST_LIST_RECEIVED";
const SAFETY_PLAN_CHECKLIST_LIST_FAILED = "SAFETY_PLAN_CHECKLIST_LIST_FAILED";
const SAFETY_PLAN_CHECKLIST_SEARCH_CHANGED =
	"SAFETY_PLAN_CHECKLIST_SEARCH_CHANGED";
const SAFETY_PLAN_CHECKLIST_LOOKUP = "SAFETY_PLAN_CHECKLIST_LOOKUP";
const SAFETY_PLAN_CHECKLIST_LOOKUP_SUCCESS =
	"SAFETY_PLAN_CHECKLIST_LOOKUP_SUCCESS";
const SAFETY_PLAN_CHECKLIST_LOOKUP_FAILED =
	"SAFETY_PLAN_CHECKLIST_LOOKUP_FAILED";

const STAFF_TRAINING_PAGED_LIST_REQUESTED =
	"STAFF_TRAINING_PAGED_LIST_REQUESTED";
const STAFF_TRAINING_LIST_REQUESTED = "STAFF_TRAINING_LIST_REQUESTED";
const STAFF_TRAINING_LIST_RECEIVED = "STAFF_TRAINING_LIST_RECEIVED";
const STAFF_TRAINING_LIST_FAILED = "STAFF_TRAINING_LIST_FAILED";
const STAFF_TRAINING_SEARCH_CHANGED = "STAFF_TRAINING_SEARCH_CHANGED";
const STAFF_TRAINING_LOOKUP = "STAFF_TRAINING_LOOKUP";
const STAFF_TRAINING_LOOKUP_SUCCESS = "STAFF_TRAINING_LOOKUP_SUCCESS";
const STAFF_TRAINING_LOOKUP_FAILED = "STAFF_TRAINING_LOOKUP_FAILED";

const STAFF_TRAINING_TOPIC_PAGED_LIST_REQUESTED =
	"STAFF_TRAINING_TOPIC_PAGED_LIST_REQUESTED";
const STAFF_TRAINING_TOPIC_LIST_REQUESTED =
	"STAFF_TRAINING_TOPIC_LIST_REQUESTED";
const STAFF_TRAINING_TOPIC_LIST_RECEIVED = "STAFF_TRAINING_TOPIC_LIST_RECEIVED";
const STAFF_TRAINING_TOPIC_LIST_FAILED = "STAFF_TRAINING_TOPIC_LIST_FAILED";
const STAFF_TRAINING_TOPIC_SEARCH_CHANGED =
	"STAFF_TRAINING_TOPIC_SEARCH_CHANGED";
const STAFF_TRAINING_TOPIC_LOOKUP = "STAFF_TRAINING_TOPIC_LOOKUP";
const STAFF_TRAINING_TOPIC_LOOKUP_SUCCESS =
	"STAFF_TRAINING_TOPIC_LOOKUP_SUCCESS";
const STAFF_TRAINING_TOPIC_LOOKUP_FAILED = "STAFF_TRAINING_TOPIC_LOOKUP_FAILED";

// for generic delete action
const HEALTH_SAFETY_REMOVE_REQUESTED = "HEALTH_SAFETY_REMOVE_REQUESTED";
const HEALTH_SAFETY_REMOVE_SUCCEEDED = "HEALTH_SAFETY_REMOVE_SUCCEEDED";
const HEALTH_SAFETY_REMOVE_FAILED = "HEALTH_SAFETY_REMOVE_FAILED";

const INDUCTION_MINUTES_CONFIRM_CLICKED = "INDUCTION_MINUTES_CONFIRM_CLICKED";
const INDUCTION_MINUTES_CONFIRM_ATTENDANCE_SUCCEEDED =
	"INDUCTION_MINUTES_CONFIRM_ATTENDANCE_SUCCEEDED";
const INDUCTION_MINUTES_CONFIRM_ATTENDANCE_FAILED =
	"INDUCTION_MINUTES_CONFIRM_ATTENDANCE_FAILED";

const LIST_FAILED = "LIST_FAILED";

export default {
	LIST_FAILED,
	INDUCTION_MINUTES_CONFIRM_CLICKED,
	INDUCTION_MINUTES_CONFIRM_ATTENDANCE_SUCCEEDED,
	INDUCTION_MINUTES_CONFIRM_ATTENDANCE_FAILED,
	HEALTH_SAFETY_ROUTE,
	HEALTH_SAFETY_LIST_ROUTE,
	HEALTH_SAFETY_TIMESHEETS_ROUTE,
	HEALTH_SAFETY_INCIDENTS_ROUTE,
	HEALTH_SAFETY_HAZARDS_ROUTE,
	HEALTH_SAFETY_HAZARDOUS_SUBSTANCES_ROUTE,
	HEALTH_SAFETY_INDUCTION_MINUTES_ROUTE,
	HEALTH_SAFETY_SAFETY_MINUTES_ROUTE,
	HEALTH_SAFETY_JOB_SAFETY_ANALYSIS_ROUTE,
	HEALTH_SAFETY_SAFETY_PLAN_CHECKLISTS_ROUTE,
	HEALTH_SAFETY_STAFF_TRAINING_ROUTE,
	HEALTH_SAFETY_STAFF_TRAINING_TOPIC_ROUTE,
	HEALTH_SAFETY_CLEAR_SEARCH,

	HEALTH_SAFETY_PATH,
	HEALTH_SAFETY_LIST_PATH,
	HEALTH_SAFETY_TIMESHEETS_PATH,
	HEALTH_SAFETY_INCIDENTS_PATH,
	HEALTH_SAFETY_HAZARDS_PATH,
	HEALTH_SAFETY_HAZARDOUS_SUBSTANCES_PATH,
	HEALTH_SAFETY_INDUCTION_MINUTES_PATH,
	HEALTH_SAFETY_SAFETY_MINUTES_PATH,
	HEALTH_SAFETY_JOB_SAFETY_ANALYSIS_PATH,
	HEALTH_SAFETY_SAFETY_PLAN_CHECKLISTS_PATH,
	HEALTH_SAFETY_STAFF_TRAINING_PATH,
	HEALTH_SAFETY_STAFF_TRAINING_TOPIC_PATH,

	HAZARDS_PAGED_LIST_REQUESTED,
	HAZARDS_LIST_REQUESTED,
	HAZARDS_LIST_RECEIVED,
	HAZARDS_LIST_FAILED,
	HAZARDS_SEARCH_CHANGED,
	HAZARDS_LOOKUP,
	HAZARDS_LOOKUP_SUCCESS,
	HAZARDS_LOOKUP_FAILED,

	HAZARDOUS_SUBSTANCES_PAGED_LIST_REQUESTED,
	HAZARDOUS_SUBSTANCES_LIST_REQUESTED,
	HAZARDOUS_SUBSTANCES_LIST_RECEIVED,
	HAZARDOUS_SUBSTANCES_LIST_FAILED,
	HAZARDOUS_SUBSTANCES_SEARCH_CHANGED,
	HAZARDOUS_SUBSTANCES_LOOKUP,
	HAZARDOUS_SUBSTANCES_LOOKUP_SUCCESS,
	HAZARDOUS_SUBSTANCES_LOOKUP_FAILED,

	INCIDENTS_PAGED_LIST_REQUESTED,
	INCIDENTS_LIST_REQUESTED,
	INCIDENTS_LIST_RECEIVED,
	INCIDENTS_LIST_FAILED,
	INCIDENTS_SEARCH_CHANGED,
	ACCIDENTS_LOOKUP,
	ACCIDENTS_LOOKUP_SUCCESS,
	ACCIDENTS_LOOKUP_FAILED,

	INDUCTION_MINUTES_PAGED_LIST_REQUESTED,
	INDUCTION_MINUTES_LIST_REQUESTED,
	INDUCTION_MINUTES_LIST_RECEIVED,
	INDUCTION_MINUTES_LIST_FAILED,
	INDUCTION_MINUTES_SEARCH_CHANGED,
	INDUCTION_MINUTES_LOOKUP,
	INDUCTION_MINUTES_LOOKUP_SUCCESS,
	INDUCTION_MINUTES_LOOKUP_FAILED,

	SAFETY_MINUTES_PAGED_LIST_REQUESTED,
	SAFETY_MINUTES_LIST_REQUESTED,
	SAFETY_MINUTES_LIST_RECEIVED,
	SAFETY_MINUTES_LIST_FAILED,
	SAFETY_MINUTES_SEARCH_CHANGED,
	SAFETY_MINUTES_LOOKUP,
	SAFETY_MINUTES_LOOKUP_SUCCESS,
	SAFETY_MINUTES_LOOKUP_FAILED,

	JOB_SAFETY_ANALYSIS_PAGED_LIST_REQUESTED,
	JOB_SAFETY_ANALYSIS_LIST_REQUESTED,
	JOB_SAFETY_ANALYSIS_LIST_RECEIVED,
	JOB_SAFETY_ANALYSIS_LIST_FAILED,
	JOB_SAFETY_ANALYSIS_SEARCH_CHANGED,
	JOB_SAFETY_ANALYSIS_LOOKUP,
	JOB_SAFETY_ANALYSIS_LOOKUP_SUCCESS,
	JOB_SAFETY_ANALYSIS_LOOKUP_FAILED,

	SAFETY_PLAN_CHECKLIST_PAGED_LIST_REQUESTED,
	SAFETY_PLAN_CHECKLIST_LIST_REQUESTED,
	SAFETY_PLAN_CHECKLIST_LIST_FAILED,
	SAFETY_PLAN_CHECKLIST_LIST_RECEIVED,
	SAFETY_PLAN_CHECKLIST_SEARCH_CHANGED,
	SAFETY_PLAN_CHECKLIST_LOOKUP,
	SAFETY_PLAN_CHECKLIST_LOOKUP_SUCCESS,
	SAFETY_PLAN_CHECKLIST_LOOKUP_FAILED,

	STAFF_TRAINING_PAGED_LIST_REQUESTED,
	STAFF_TRAINING_LIST_REQUESTED,
	STAFF_TRAINING_LIST_RECEIVED,
	STAFF_TRAINING_LIST_FAILED,
	STAFF_TRAINING_SEARCH_CHANGED,
	STAFF_TRAINING_LOOKUP,
	STAFF_TRAINING_LOOKUP_SUCCESS,
	STAFF_TRAINING_LOOKUP_FAILED,

	STAFF_TRAINING_TOPIC_PAGED_LIST_REQUESTED,
	STAFF_TRAINING_TOPIC_LIST_REQUESTED,
	STAFF_TRAINING_TOPIC_LIST_RECEIVED,
	STAFF_TRAINING_TOPIC_LIST_FAILED,
	STAFF_TRAINING_TOPIC_SEARCH_CHANGED,
	STAFF_TRAINING_TOPIC_LOOKUP,
	STAFF_TRAINING_TOPIC_LOOKUP_SUCCESS,
	STAFF_TRAINING_TOPIC_LOOKUP_FAILED,

	// for generic delete action
	HEALTH_SAFETY_REMOVE_REQUESTED,
	HEALTH_SAFETY_REMOVE_SUCCEEDED,
	HEALTH_SAFETY_REMOVE_FAILED,

	// info routes
	INFO_ROUTE,
	INFO_HAZARDS_ROUTE,
	INFO_HAZARDOUS_SUBSTANCES_ROUTE,
	INFO_STAFF_TRAINING_TOPIC_ROUTE,
	INFO_JOB_SAFETY_ANALYSIS_ROUTE,
	INFO_SAFETY_PLAN_CHECKLISTS_ROUTE,

	// task routes
	TASK_ROUTE,
	TASK_SAFETY_MINUTES_ROUTE,
	TASK_INCIDENTS_ROUTE,
	TASK_INDUCTION_MINUTES_ROUTE,
	TASK_STAFF_TRAINING_ROUTE,
};
