import * as R from "ramda";
import {
	defaultSaveState,
	saveRequested,
	saveFailed,
	saveSuccess,
} from "utils";
import * as fromTypes from "../actions/types";

const defaultState = {
	...defaultSaveState,
};

const password = (state = defaultState, action) => {
	switch (action.type) {
		case fromTypes.PASSWORD_SAVE:
			return saveRequested(R.clone(state), action);
		case fromTypes.PASSWORD_SAVE_SUCCESS: {
			const nextState = saveResponseMessage(R.clone(state), action);
			return saveSuccess(nextState);
		}
		case fromTypes.PASSWORD_SAVE_FAILED: {
			const nextState = saveResponseMessage(R.clone(state), action);
			return saveFailed(nextState);
		}
		default:
			return state;
	}
};

const saveResponseMessage = (state, action) => {
	state.responseMessage = action.payload;
	return state;
};

export default password;
