import staffModel from "./staff-model";
import { defaultPaginationState } from "utils";

export default {
	currentStaff: staffModel,
	isAddModalShowing: false,
	isEditModalShowing: false,
	isNew: false,
	saving: false,
	searchFilter: "",
	pagination: defaultPaginationState,
	flags: {
		create: "none",
		update: "none",
		delete: "none",
	},
};
