import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Menu, MenuItem, Badge } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Tab, Tabs, SelectInputNested } from "frame/components";
import { useRouter } from "utils";
import * as fromRoutes from "../actions/types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ADD_TIMESHEET_ROUTE, LIST_SITE_TIMESHEETS_ROUTE, LIST_TIMESHEETS_ROUTE } from "features/timesheet/timesheet-routes";

const useStyles = makeStyles((theme) => ({
	root: {},
	dropdownLabel: {
		minWidth: 100
	},
	dropdown: {
		marginTop: theme.spacing(7)
	},
	expandIcon: {
		marginBottom: -10,
		height: 30,
		width: 30
	},
	badge: {
		position: "absolute",
		right: "-5px"
	},
	mobileMenu: {
		padding: theme.spacing(2)
	}
}));



const MenuTabs = ({ numberOfRecords }) => {
	const theme = useTheme();
	const classes = useStyles();
	const anchorEl = useRef(null);
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
	const { history, match } = useRouter();
	const { siteId, siteTab } = match.params;

	const [openMenu, setOpenMenu] = useState(false);
	const [activeTab, setActiveTab] = useState(fromRoutes.SITE_SUMMARY_PATH);

	const tabOptions = [
		{
			label: "Details",
			path: fromRoutes.SITE_SUMMARY_PATH,
			nested: false
		},
		{
			label: "Records",
			path: "#",
			nested: false
		},
		// {
		// 	label: "Add Timesheet",
		// 	path: ADD_TIMESHEET_ROUTE,
		// 	nested: true
		// },
		{
			label: "Timesheets",
			path: LIST_TIMESHEETS_ROUTE,
			nested: true
		},
		{
			label: "Quickpics",
			path: fromRoutes.SITE_NOTES_PATH,
			nested: true
		},
		{
			label: "Incidents",
			path: fromRoutes.SITE_INCIDENTS_PATH,
			nested: true
		},
		{
			label: "Inductions",
			path: fromRoutes.SITE_INDUCTION_MINUTES_PATH,
			nested: true
		},
		{
			label: "Safety Minutes",
			path: fromRoutes.SITE_SAFETY_MEETING_MINUTES_PATH,
			nested: true
		},
		{
			label: "Site Safety Plans (SSPs)",
			path: fromRoutes.SITE_SAFETY_PLAN_CHECKLIST_PATH,
			nested: true
		},
		{
			label: "Hazards",
			path: fromRoutes.SITE_HAZARDS_PATH,
			nested: true
		},
		{
			label: "Hazardous Substances",
			path: fromRoutes.SITE_HAZARDOUS_SUBSTANCES_PATH,
			nested: true
		},
	];

	const getTabPath = (path) =>
		path === ADD_TIMESHEET_ROUTE || path === LIST_TIMESHEETS_ROUTE
			? `${path}/${siteId}`
			: `${fromRoutes.SITES_ROUTE}/${siteId}/${path}`;

	const onMenuSelect = (value) => () => {
		setOpenMenu(false);
		if (value) {
			history.push(getTabPath(value)); // todo: dispatch push()
		}
	};

	const mobileOptions = tabOptions.map((tab) => ({
		label: tab.label,
		value: tab.path,
		nested: tab.nested
	}));

	const onChangeMobileTab = (value) => {
		onMenuSelect(value)();
		setActiveTab(value);
	};
	

	return (
		<div className={classes.root}>
			{mobileView && (
				<div className={classes.mobileMenu}>
					<SelectInputNested
						noNone
						id="site_mobile_menu"
						options={mobileOptions}
						value={activeTab}
						onChange={onChangeMobileTab}
					/>
				</div>
			)}
		</div>
	);
};

MenuTabs.propTypes = {
	numberOfRecords: PropTypes.object.isRequired
};

const mapStateToProps = ({ profile }) => ({
	numberOfRecords: profile.siteNumberOfRecords
});

export default connect(mapStateToProps)(MenuTabs);
