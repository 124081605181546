import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import WarningIcon from "@material-ui/icons/Warning";
import { Typography, MuiThemeProvider } from "@material-ui/core";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	cornerRibbon: {
		width: "200px",
		background: "#2f2f2f",
		color: theme.palette.white,
		position: "fixed",
		textAlign: "center",
		top: "25px",
		lineHeight: "40px",
		zIndex: "999",
		// left 'side,
		left: "-50px",
		"-ms-transform": "rotate(-45deg)",
		"-webkit-transform": "rotate(-45deg)",
		transform: "rotate(-45deg)",

		// right side
		// right: '-50px',
		// left: 'auto',
		// '-ms-transform': 'rotate(45deg)',
		// '-webkit-transform': 'rotate(45deg)',
		// 'transform': 'rotate(45deg)',
	},
	horizontalRibbon: {
		fontSize: "1em",
		letterSpacing: 0.1,
		padding: theme.spacing(1, 3),
		width: "100%",
		background: "#2f2f2f",
		color: theme.palette.white,
		// position: '',
		textAlign: "center",
		top: "0",
		lineHeight: "40px",
	},
	link: {
		color: theme.palette.white,
		textDecoration: "underline",
	},
	icon: {
		paddingTop: theme.spacing(0.8),
		marginRight: "2px",
		marginBottom: "-4px",
	},
	text: {
		paddingTop: theme.spacing(1),
	},
}));
/* eslint-disable */

const Ribbon = ({ text }) => {
	const classes = useStyles();

	let theme = createMuiTheme();
	theme = responsiveFontSizes(theme);

	return (
		<>
			{/* <div className={classes.cornerRibbon}>{text}</div> */}
			<div className={classes.horizontalRibbon}>
				<MuiThemeProvider theme={theme}>
					<Typography variant="body2">
						<WarningIcon className={classes.icon} />
						{text}.
					</Typography>
					<Typography variant="body2" className={classes.text}>
						Please note geolocation tracking for ForTheRecord is
						necessary for the accuracy of your records.
					</Typography>
					<Typography variant="body2" className={classes.text}>
						Please follow this{" "}
						<a
							className={classes.link}
							href="https://support.google.com/chrome/answer/142065"
							target="_blank"
						>
							guide
						</a>{" "}
						to enable geolocation.
					</Typography>
				</MuiThemeProvider>
			</div>
		</>
	);
};
/* eslint-enable */

Ribbon.propTypes = {
	text: PropTypes.string,
};

export default Ribbon;
