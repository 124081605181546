import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Typography,
} from "@material-ui/core";

import { RadioListInput } from "frame/components/input";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 0,
	},
	heading: {
		padding: theme.spacing(1, 0, 3, 0),
	},
	divider: {
		margin: theme.spacing(1, 0, 2, 0),
		height: 2,
	},
}));

const IncidentForm = ({ className, disabled, values, onSelectChange }) => {
	const classes = useStyles();

	const options = [
		{
			label: "Yes",
			value: "yes",
		},
		{
			label: "NA",
			value: "na",
		},
	];

	return (
		<CardContent className={classes.root}>
			<Divider className={classes.divider} />
			<CardHeader
				className={classes.heading}
				title="Incident"
				titleTypographyProps={{ variant: "h5" }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						We have an incident reporting / investigation system in
						place and keep an incident register.
					</Typography>
					<RadioListInput
						data-cy={"haveAccidentSystemInPlace"}
						disabled={disabled}
						initialValue={values.haveAccidentSystemInPlace}
						options={options}
						onChange={onSelectChange("haveAccidentSystemInPlace")}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography align="left" gutterBottom variant="body1">
						We will immediately notify all serious harm Incidents to
						management and follow up in 7 days with a completed copy
						of the incident investigation report.
					</Typography>
					<RadioListInput
						data-cy={"willImmediatelyNotifyAllAccidents"}
						disabled={disabled}
						initialValue={values.willImmediatelyNotifyAllAccidents}
						options={options}
						onChange={onSelectChange(
							"willImmediatelyNotifyAllAccidents"
						)}
					/>
				</Grid>
			</Grid>
		</CardContent>
	);
};

IncidentForm.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.object.isRequired,
	onSelectChange: PropTypes.func.isRequired,
};

export default IncidentForm;
