import React from "react";
import PropTypes from "prop-types";
import {
	SectionCard,
	PeopleSection,
	AttachmentsSectionCard,
	ValueText,
	Lookup,
} from "frame/components";
import { Grid } from "@material-ui/core";
import { formatDate } from "utils";

const ReadOnlyForm = ({ values }) => {
	const notes = values.notes || {};

	return (
		<>
			<SectionCard>
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Title"
							value={values.title}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Site"
							value={
								<Lookup
									id={values.siteId}
									resource="sites"
									attribute="siteName"
								/>
							}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Date"
							value={formatDate(values.createdAt)}
							options={{ bold: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			<SectionCard title="People">
				<PeopleSection
					people={values.people}
					otherPeople={values.otherPeople}
				/>
			</SectionCard>

			<SectionCard title="Meeting Notes">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ValueText
							label="Operational Issues"
							value={notes.operationalIssues}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<ValueText
							label="General Issues Discussed"
							value={notes.generalIssuesDiscussed}
							options={{ bold: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			<AttachmentsSectionCard values={values} />
		</>
	);
};

ReadOnlyForm.propTypes = {
	values: PropTypes.object.isRequired,
};

export default ReadOnlyForm;
