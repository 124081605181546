import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Modal, Card, Grid, Typography, TextField, ClickAwayListener } from "@material-ui/core";
import {
	PageContent,
	FormActions,
	FormButton,
	FormSubmitButton,
	ScrollToTopOnMount,
	SelectInput,
	Dialog
} from "frame/components";
import { useRouter, sortByPropertyAlphabetically, isNotNullOrEmpty } from "utils";
import { DatePicker } from "@material-ui/pickers";
import { UploadForm } from "features/forms";
import { defaultState } from "../../reducers/jobs-reducer";
import { connect } from "react-redux";
import { safetyWiseApi } from "utils/safetyWiseApi";

import {
	onTitleChanged,
	onDateChanged,
	onTypeChanged,
	onCustomerIdChanged,
	onSiteIdChanged,
	onStaffIdChanged,
	onNotesChanged,
	onFileChanged,
	onSave
	// onClose
} from "../../actions";

const mapStateToProps = ({ customers, sites, staff, organisations }) => ({
	customerList: customers.pagination?.results || [],
	siteList: sites.pagination?.results || [],
	staffList: staff.pagination?.results || [],
	currentOrganisationGUID: organisations.currentOrganisation
});

const mapDispatchToProps = {
	onTitleChanged,
	onDateChanged,
	onTypeChanged,
	onCustomerIdChanged,
	onSiteIdChanged,
	onStaffIdChanged,
	onNotesChanged,
	onFileChanged,
	onSave
};

const useStyles = makeStyles((theme) => ({
	root: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		outline: "none",
		boxShadow: theme.shadows[20],
		width: 700,
		maxHeight: "100%",
		overflowY: "auto",
		maxWidth: "100%"
	},
	actions: {
		justifyContent: "flex-end",
		marginBottom: theme.spacing(3)
	}
}));
const EditJobModal = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ customerList, staffList, siteList, onClosed, selectedJob, currentOrganisationGUID, open }) => {
	const [isCompressing, setCompressing] = useState(false);
	const [jobState, setJobState] = useState(defaultState);
	const classes = useStyles();
	const { history } = useRouter();
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		console.log("job", selectedJob);
		setJobState({
			...selectedJob
		});
	}, [selectedJob, open]);

	const isActive = (node) => {
		return String(node.status).toLowerCase() === "active";
	};
	const customerOptions =
		isNotNullOrEmpty(customerList) &&
		customerList
			.filter(isActive)
			.map(({ id, customerName }) => ({
				label: customerName,
				value: id
			}))
			.sort(sortByPropertyAlphabetically("label"));

	const siteOptions =
		isNotNullOrEmpty(siteList) &&
		siteList
			.filter(isActive)
			.map(({ id, siteName }) => ({
				label: siteName,
				value: id
			}))
			.sort(sortByPropertyAlphabetically("label"));

	const staffOptions =
		isNotNullOrEmpty(staffList) &&
		staffList
			.map(({ id, fullName, firstName, lastName }) => ({
				label: fullName || `${firstName} ${lastName}`.trim(),
				value: id
			}))
			.filter((s) => s.label && s.value)
			.sort(sortByPropertyAlphabetically("label"));

	const typeOptions = [
		{
			label: "Customer",
			value: "customer"
		},
		{
			label: "Site",
			value: "site"
		},
		{
			label: "Staff",
			value: "staff"
		}
	];

	const onCompressingUploads = (e) => {
		if (e !== isCompressing) setCompressing(e);
	};

	const onSubmit = () => {
		setSaving(true);

		safetyWiseApi
			.post(currentOrganisationGUID + "/job/createorupdate/", jobState)
			.then((response) => {
				onClosed("saved");
			});
	};

	return (
		<Modal onClose={() => onClosed()} open={true}>
			<Card className={classes.root}>
				<ValidatorForm onSubmit={onSubmit}>
					<ScrollToTopOnMount />

					<PageContent padSides padBottom padTop>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Typography gutterBottom variant="h3">
									Edit Quick Pic or Note
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<TextValidator
									fullWidth
									data-cy="job_title"
									label="Title *"
									name="Title"
									value={jobState.title || ""}
									variant="outlined"
									validators={["required"]}
									errorMessages={["This field is required"]}
									onChange={(title) => setJobState({ ...jobState, title: title.target.value })}
									inputProps={{ maxLength: 100 }}
								/>
							</Grid>

							<Grid item xs={12}>
								<DatePicker
									data-cy="job_date"
									className={classes.field}
									format="Do MMMM YYYY"
									fullWidth
									inputVariant="outlined"
									label="Date"
									value={jobState.createdAt}
									variant="outlined"
									disableFuture={true}
									onChange={(createdAt) => {
										setJobState({ ...jobState, createdAt: createdAt.toISOString() });
									}}
								/>
							</Grid>

							<Grid item xs={12}>
								<SelectInput
									id="type"
									data-cy="job_type"
									label="Type *"
									options={typeOptions}
									value={jobState.type}
									onChange={(type) => setJobState({ ...jobState, type: type })}
								/>
							</Grid>

							{jobState.type === "customer" && (
								<Grid item xs={12}>
									<SelectInput
										id="customer"
										data-cy="customer"
										label="Customer Name *"
										options={customerOptions}
										value={jobState.customerId}
										onChange={(customerId) =>
											setJobState({
												...jobState,
												customerId: customerId,
												siteId: "",
												staffId: ""
											})
										}
									/>
								</Grid>
							)}

							{jobState.type === "site" && (
								<Grid item xs={12}>
									<SelectInput
										id="site"
										data-cy="site"
										label="Site Name *"
										options={siteOptions}
										value={jobState.siteId}
										onChange={(siteId) =>
											setJobState({
												...jobState,
												siteId: siteId,
												customerId: "",
												staffId: ""
											})
										}
									/>
								</Grid>
							)}

							{jobState.type === "staff" && (
								<Grid item xs={12}>
									<SelectInput
										id="staff"
										data-cy="staff"
										label="Staff Name *"
										options={staffOptions}
										value={jobState.staffId || ""}
										onChange={(staffId) =>
											setJobState({
												...jobState,
												staffId: staffId,
												siteId: "",
												customerId: ""
											})
										}
									/>
								</Grid>
							)}

							<Grid item xs={12}>
								<TextField
									fullWidth
									id="job_notes"
									data-cy="job_notes"
									label="Notes"
									multiline
									rows="3"
									name="notes"
									onChange={(notes) => setJobState({ ...jobState, notes: notes.target.value })}
									value={jobState.notes}
									variant="outlined"
								/>
							</Grid>

							<Grid item xs={12}>
								<Typography variant="h6">Attachments</Typography>
								<Typography variant="body2">Attach documents, photos and audio files.</Typography>
								<UploadForm
									ignoreDataUrl
									values={jobState}
									onChange={
										(a) => console.log(a)
										// setJobState({ ...jobState, ...a });
									}
									onCompressing={onCompressingUploads}
									// disabled={!job.isValid}
								/>
							</Grid>
						</Grid>
					</PageContent>

					<FormActions className={classes.actions}>
						<FormSubmitButton disabled={isCompressing || saving}>Save</FormSubmitButton>
						<FormButton onClick={onClosed}>Cancel</FormButton>
					</FormActions>
				</ValidatorForm>
			</Card>
		</Modal>
	);
});

export default EditJobModal;
