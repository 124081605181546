import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
	// Style the accordion section
	root: {
		overflowX: "hidden",
		display: "flex",
		flexDirection: "column",
	},
	/* Style the buttons that are used to open and close the accordion panel */
	accordion: {
		backgroundColor: "#eee",
		color: "#444",
		cursor: "pointer",
		padding: "18px",
		display: "flex",
		alignItems: "center",
		border: "none",
		outline: "none",
		transition: "backgroundColor 0.6s ease",
		"&:hover": {
			backgroundColor: "#ccc",
		},
	},
	/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
	active: {
		backgroundColor: "#ccc",
	},
	/* Style the accordion content title */
	accordionTitle: {
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: "600",
		fontSize: "14px",
		textAlign: "left",
	},
	/* Style the accordion chevron icon */
	accordionIcon: {
		marginLeft: "auto",
		transition: "transform 0.3s ease",
	},
	/* Style to rotate icon when state is active */
	rotate: {
		transform: "rotate(180deg)",
	},
	/* Style the accordion content panel. Note: hidden by default */
	accordionContent: {
		backgroundColor: "white",
		overflow: "auto",
		transition: "max-height 0.3s ease",
	},
	/* Style the accordion content text */
	accordionText: {
		fontFamily: '"Open Sans", sans-serif',
		fontWeight: "400",
		fontSize: "14px",
		padding: "18px",
	},
}));

const Accordion = ({ title, children, isNested }) => {
	const classes = useStyles();

	const [setActive, setActiveState] = useState("");
	const [setHeight, setHeightState] = useState("0px");
	const [setRotate, setRotateState] = useState(classes.accordionIcon);

	const contentRef = useRef(null);

	function toggleAccordion() {
		setActiveState(setActive === "" ? classes.active : "");
		setHeightState(
			setActive === classes.active
				? "0px"
				: `${contentRef.current.scrollHeight}px`
		);
		setRotateState(
			setActive === classes.active
				? classes.accordionIcon
				: clsx(classes.accordionIcon, classes.rotate)
		);
	}

	return (
		<div className={classes.root}>
			<button
				className={clsx(classes.accordion, setActive)}
				onClick={toggleAccordion}
			>
				<p className={classes.accordionTitle}>{title}</p>
				{isNested && <ExpandLessIcon className={setRotate} />}
			</button>
			<div
				ref={contentRef}
				style={{ maxHeight: `${setHeight}` }}
				className={classes.accordionContent}
			>
				<div className={classes.accordionText}>{children}</div>
			</div>
		</div>
	);
};

Accordion.propTypes = {
	name: PropTypes.string,
	children: PropTypes.any,
	isNested: PropTypes.bool,
};

export default Accordion;
