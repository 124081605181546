import React from "react";
import PropTypes from "prop-types";
import {
	SectionCard,
	AppendNotesListing,
	AttachmentsSectionCard,
	RelatedHazardSubstanceListing,
	ValueText,
	PeopleSection,
	Lookup,
} from "frame/components";
import { Typography, Grid, Divider } from "@material-ui/core";
import { formatDate } from "utils";

const ReadOnlyForm = ({ values }) => {
	const accident = values.accident || {};

	return (
		<>
			<SectionCard title="Incident Details">
				<Grid container spacing={2}>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Date"
							value={formatDate(values.createdAt)}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item sm={6} xs={12}>
						<ValueText
							label="Site"
							value={
								<Lookup
									id={accident.siteId}
									resource="sites"
									attribute="siteName"
								/>
							}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<ValueText
							label="Incident Details"
							value={accident.incidentDetails}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<ValueText
							label="Action"
							value={accident.action}
							options={{ bold: true }}
						/>
					</Grid>

					<Grid item sm={6} xs={12}>
						<ValueText
							label="Category"
							value={accident.category}
							options={{ bold: true }}
						/>
					</Grid>

					<Grid item sm={6} xs={12}>
						<ValueText
							label="Serious Harm"
							value={accident.seriousHarm}
							options={{ bold: true }}
						/>
					</Grid>

					<Grid item sm={12} xs={12}>
						<Grid container>
							<Grid item xs={12} style={{ display: "flex" }}>
								<Typography variant="h6">
									<strong>Related Hazard/Substances</strong>
								</Typography>

								<div
									style={{
										justifyContent: "flex-end",
										marginLeft: "auto",
									}}
								>
									<RelatedHazardSubstanceListing
										noTitle
										useBulletPoints
										relatedHazardOrSubstanceList={
											accident.relatedHazardOrSubstance
										}
									/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<br />
								<Divider />
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12}>
						<ValueText
							label="Investigation Outcomes"
							value={accident.investigationOutcomes}
							options={{ bold: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			<SectionCard title="Participants">
				<div style={{ marginTop: "-10px" }}>
					<PeopleSection
						people={values.people}
						otherPeople={values.otherPeople}
					/>
				</div>
			</SectionCard>

			<AttachmentsSectionCard values={values} />

			<SectionCard title="Appended Notes">
				<AppendNotesListing noTitle appendNotes={values.appendNotes} />
			</SectionCard>
		</>
	);
};

ReadOnlyForm.propTypes = {
	values: PropTypes.object.isRequired,
};

export default ReadOnlyForm;
