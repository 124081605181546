import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Grid,
	Checkbox,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	FormControlLabel,
} from "@material-ui/core";

import { Dialog, FormButton, ValueText } from "frame/components";
import { capitalize } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {},
	review: {
		marginBottom: theme.spacing(5),
	},
	fieldLabel: {
		display: "flex",
		flex: 1,
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(-1.2),
	},
	label: {
		margin: 0,
	},
	formControlLabel: {
		userSelect: "none",
		marginLeft: theme.spacing(1),
	},
}));

const HazardDetailsDialog = ({ data, onSelect, onClose, readOnly }) => {
	const classes = useStyles();
	const [agreed, setAgreed] = useState(false);

	const onToggleAgreed = () => setAgreed(!agreed);

	return (
		<Dialog
			open
			modalSize="sm"
			aria-labelledby="Hazard"
			breadcrumbs="Induction Meeting"
			title="Hazard Review"
			onClose={onClose}
		>
			<Card className={classes.review}>
				<CardHeader
					title={data.name}
					titleTypographyProps={{ variant: "h4" }}
				/>
				<CardContent>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<ValueText
								label="Initial risk assessment"
								value={
									data.initialRiskAssessment
										? data.initialRiskAssessment
										: "-"
								}
								options={{ large: true, bold: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ValueText
								label="Risidual risk assessment"
								value={
									data.residualRiskAssessment
										? capitalize(
												data.residualRiskAssessment
										  )
										: "-"
								}
								options={{ large: true, bold: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ValueText
								label="Significant risk"
								value={
									data.significantRisk
										? capitalize(data.significantRisk)
										: "-"
								}
								options={{ large: true, bold: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ValueText
								label="Training required"
								value={
									data.trainingRequired
										? capitalize(data.trainingRequired)
										: "-"
								}
								options={{ large: true, bold: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ValueText
								label="Hazard controls"
								value={
									data.hazardControls
										? data.hazardControls
										: "-"
								}
								options={{
									large: true,
									bold: true,
									inLineContentState: true,
								}}
							/>
						</Grid>

						{!readOnly && (
							<Grid item xs={12}>
								<div className={classes.fieldLabel}>
									<FormControlLabel
										className={classes.formControlLabel}
										control={
											<Checkbox
												checked={agreed}
												onChange={onToggleAgreed}
												value="yes"
												inputProps={{
													"aria-label": `I've read and understood the hazard checkbox`,
												}}
											/>
										} // eslint-disable-next-line
										label={`I\'ve read and understood the hazard.`}
									/>
								</div>
							</Grid>
						)}
					</Grid>
				</CardContent>
				<CardActions>
					<FormButton
						color="brand"
						disabled={!agreed && !readOnly}
						onClick={onSelect(data.id)}
					>
						Ok
					</FormButton>
					<FormButton onClick={onClose}>Cancel</FormButton>
				</CardActions>
			</Card>
		</Dialog>
	);
};

HazardDetailsDialog.propTypes = {
	data: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
};

export default HazardDetailsDialog;
