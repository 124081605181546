import { connect } from "react-redux";
import { invitationSave } from "../actions";
import Invite from "./invite";

const mapStateToProps = ({ invitations, profile }) => ({
	save: invitations.save,
	role: profile.role,
	consultantId: profile.staffId,
});

const mapDispatchToProps = {
	onInvite: invitationSave,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
