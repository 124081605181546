import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./frame/components/app";
import * as serviceWorker from "./service-worker";
import "typeface-roboto";
import "./index.css";

const render = (Component) => {
	ReactDOM.render(<Component />, document.getElementById("root"));
};

render(App);

if (module.hot) {
	module.hot.accept("./frame/components/app", () => {
		const NextApp = require("./frame/components/app").default;
		render(NextApp);
	});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
	onUpdate: (registration) => {
		if (registration && registration.waiting) {
			registration.waiting.postMessage({ type: "SKIP_WAITING" });
		}
		window.location.reload();
	},
});
