import { useState } from "react";

export const useRemover = () => {
	const [remove, setRemove] = useState(false);

	const onPossiblyRemove = () => setRemove(true);

	const onCancelRemove = () => setRemove(false);

	return {
		remove,
		onPossiblyRemove,
		onCancelRemove,
	};
};
