import { connect } from "react-redux";
import Summary from "./summary";
import { onEdit } from "../actions";
import { getCustomerById } from "../utils";

const mapStateToProps = (state) => ({
	customer: getCustomerById(state),
	role: state.profile.role,
});

const mapDispatchToProps = {
	onEdit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
