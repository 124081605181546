import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { TextValidator } from "react-material-ui-form-validator";
import { RadioListInput, SelectInput } from "frame/components";

import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	heading: {
		marginBottom: theme.spacing(-3),
	},
}));

const HazardousSubstanceForm = ({ className, values, onChange }) => {
	const classes = useStyles();
	const substances = values.substances || {};
	const disabled = values.locked;

	const onInputChange =
		(key) =>
		({ target: { value } }) =>
			onChange({
				...values,
				substances: {
					...substances,
					[key]: value,
				},
			});

	const onSelectChange = (key) => (value) =>
		onChange({
			...values,
			substances: {
				...substances,
				[key]: value,
			},
		});

	const yesNo = [
		{
			label: "Yes",
			value: "yes",
		},
		{
			label: "No",
			value: "no",
		},
	];

	return (
		<Card className={classes.root}>
			<CardHeader
				className={classes.heading}
				title="Hazardous Substances"
				titleTypographyProps={{ variant: "h4" }}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextValidator
							id="substances_name"
							disabled={disabled}
							fullWidth
							label="Hazardous Substance Name *"
							value={substances.name || ""}
							variant="outlined"
							validators={["required"]}
							errorMessages={["This field is required"]}
							onChange={onInputChange("name")}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							id="substances_substance"
							disabled={disabled}
							fullWidth
							label="Substance, Chemical Material or Solvent *"
							multiline
							rows="3"
							value={substances.substanceType || ""}
							variant="outlined"
							validators={["required"]}
							errorMessages={["This field is required"]}
							onChange={onInputChange("substanceType")}
						/>
					</Grid>
					<Grid item xs={12}>
						<SelectInput
							noNone
							id="volumeMeasurement"
							data-cy="volumeMeasurement"
							disabled={disabled}
							label="Volume Measurement *"
							options={["Litres", "Grams"]}
							value={substances.volumeMeasurement || ""}
							onChange={onSelectChange("volumeMeasurement")}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography align="left" variant="h5" gutterBottom>
							Supplier SDS Report Held
						</Typography>
						<RadioListInput
							data-cy={"supplierSDSReportHeld"}
							disabled={disabled}
							initialValue={substances.supplierSDSReportHeld}
							options={yesNo}
							onChange={onSelectChange("supplierSDSReportHeld")}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography align="left" variant="h5" gutterBottom>
							Protective Clothing Required
						</Typography>
						<RadioListInput
							data-cy={"protectiveClothingRequired"}
							disabled={disabled}
							initialValue={substances.protectiveClothingRequired}
							options={yesNo}
							onChange={onSelectChange(
								"protectiveClothingRequired"
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextValidator
							id="substances_action"
							disabled={disabled}
							fullWidth
							label="Action Recommended *"
							multiline
							rows="3"
							value={substances.actionRecommended || ""}
							variant="outlined"
							validators={["required"]}
							errorMessages={["This field is required"]}
							onChange={onInputChange("actionRecommended")}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

HazardousSubstanceForm.propTypes = {
	className: PropTypes.string,
	values: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default HazardousSubstanceForm;
