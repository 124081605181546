import { connect } from "react-redux";
import SafetyMinutesSiteList from "./safety-minutes-site-list";
import { onRemove } from "../../actions";

const mapStateToProps = ({ profile }) => ({
	role: profile.role,
});

const mapDispatchToProps = {
	onDelete: onRemove,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SafetyMinutesSiteList);
