import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	SectionCard,
	AttachmentsSectionCard,
	ValueText,
} from "frame/components";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Grid, Typography } from "@material-ui/core";
import { sortByPropertyNumber } from "utils";
import { formatDate } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {},
}));

const ReadOnlyForm = ({ values }) => {
	const classes = useStyles();

	const formatSteps = (steps) =>
		steps.sort(sortByPropertyNumber("index")).map((step, stepKey) => {
			return (
				<Accordion key={stepKey}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1c-content"
						id={`jsa-step-${stepKey + 1}`}
					>
						<Typography variant="h5">{`Step ${
							stepKey + 1
						}`}</Typography>
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<Grid container spacing={1}>
							<Grid item sm={12} xs={12}>
								<ValueText
									label={`Step Name`}
									value={step.name || "-"}
									options={{ bold: true }}
								/>
							</Grid>

							<Grid item sm={12} xs={12}>
								<ValueText
									label={`Hazard`}
									value={step.hazardList}
									options={{
										greyOutArchived: true,
										bold: true,
										isList: true,
										listAttribute: "name",
									}}
								/>
							</Grid>

							<Grid item sm={12} xs={12}>
								<ValueText
									label={`Initial Risk Assessment`}
									value={step.initialRiskAssessment || "-"}
									options={{ bold: true, isLongLabel: true }}
								/>
							</Grid>

							<Grid item sm={12} xs={12}>
								<ValueText
									label={`Residual Risk Assessment`}
									value={step.residualRiskAssessment || "-"}
									options={{ bold: true, isLongLabel: true }}
								/>
							</Grid>

							<Grid item xs={12}>
								<ValueText
									label={`Level Of Control Methods`}
									value={step.levelOfControlMethods || "-"}
									options={{ bold: true, isLongLabel: true }}
								/>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			);
		});

	return (
		<>
			<SectionCard>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ValueText
							label="Name"
							value={values.name}
							options={{ bold: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<ValueText
							label="Date"
							value={formatDate(values.createdAt)}
							options={{ bold: true }}
						/>
					</Grid>
				</Grid>
			</SectionCard>

			<SectionCard title="Tasks">
				<Grid container spacing={2}>
					{values.tasks && values.tasks.length === 0 && (
						<Grid item xs={12}>
							<Typography
								variant="body1"
								className={classes.label}
							>
								No task available.
							</Typography>
						</Grid>
					)}

					{values.tasks &&
						values.tasks
							.sort(sortByPropertyNumber("index"))
							.map((task, key) => (
								<>
									<Grid item xs={12}>
										<ValueText
											label={`Task ${key + 1} `}
											value={task.name}
											options={{ bold: true }}
										/>
									</Grid>
									<Grid item xs={12}>
										<ValueText
											label="Task Description"
											value={task.description}
											options={{
												bold: true,
												useTextClip: true,
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										{formatSteps(task.steps)}
									</Grid>
								</>
							))}
				</Grid>
			</SectionCard>

			<AttachmentsSectionCard values={values} />
		</>
	);
};

ReadOnlyForm.propTypes = {
	values: PropTypes.object.isRequired,
};

export default ReadOnlyForm;
