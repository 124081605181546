import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Brand from "../../../frame/components/brand";
import { useRouter } from "utils";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import * as fromAuth from "features/authorisation";
import { Page } from "frame/components";

const useStyles = makeStyles((theme) => ({
	topMargin: {
		[theme.breakpoints.down("xs")]: {
			margin: theme.spacing(6, 0),
		},
	},
	root: {
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: theme.spacing(6, 2),
	},
	card: {
		width: theme.breakpoints.values.md,
		maxWidth: "100%",
		overflow: "unset",
		display: "flex",
		position: "relative",
		"& > *": {
			flexGrow: 1,
			flexBasis: "50%",
			width: "50%",
		},
	},
	content: {
		padding: theme.spacing(8, 4, 3, 4),
	},
	media: {
		position: "relative",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
		padding: theme.spacing(3),
		color: theme.palette.white,
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	icon: {
		border: "solid rgb(225, 227, 229) 1px",
		backgroundColor: theme.palette.white,
		color: theme.palette.white,
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(1),
		position: "absolute",
		top: -32,
		left: theme.spacing(3),
		height: 64,
		fontSize: 32,
	},
	imageOverlay: {
		position: "absolute",
		top: "0",
		left: "0",
		width: "100%",
		height: "100%",
		background: "rgba(25, 25, 25, 0.4)",
	},
	imageOverlayText: {
		position: "absolute",
		bottom: "25px",
		left: "25px",
		maxWidth: "80%",
	},
	brand: {
		display: "flex",
	},
	svgLogoWrap: {
		top: "-32px",
		left: "24px",
		color: "#FFFFFF",
		border: "solid rgb(225, 227, 229) 1px",
		height: "64px",
		padding: "8px",
		position: "absolute",
		fontSize: "32px",
		borderRadius: "4px",
		backgroundColor: "#FFFFFF",
	},
}));

const LandingPageFrame = ({ title, form, hideSmallPrint, hideImage }) => {
	const classes = useStyles();
	const { location } = useRouter();

	const className =
		location.pathname === fromAuth.REGISTER_PATH ? classes.topMargin : "";

	return (
		<Page className={clsx(classes.root, className)} title={null}>
			<Card className={classes.card}>
				<CardContent className={classes.content}>
					<div className={classes.svgLogoWrap}>
						<Brand theme="colored" height={50} width={220} />
					</div>
					<Typography gutterBottom variant="h3">
						{title}
					</Typography>
					{form}
				</CardContent>
				{!hideImage && (
					<CardMedia
						className={classes.media}
						image="/images/login-image.jpg"
						title="Cover"
					>
						<div className={classes.imageOverlay}>
							<div className={classes.imageOverlayText}>
								<Typography
									color="inherit"
									gutterBottom
									variant="h3"
								>
									The easy way to keep great records of important job pics, notes, and more.
								</Typography>
								{!hideSmallPrint && (
									<Typography
										color="inherit"
										variant="subtitle1"
									>
										Getting started is quick and easy, no
										contracts - cancel any time.
									</Typography>
								)}
							</div>
						</div>
					</CardMedia>
				)}
			</Card>
		</Page>
	);
};

LandingPageFrame.propTypes = {
	title: PropTypes.string,
	form: PropTypes.object,
	hideImage: PropTypes.bool,
};

export default LandingPageFrame;
