import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, Snackbar, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		maxWidth: "280px",
	},
}));

const PopupSnackbar = ({
	message,
	coloured = false,
	success = false,
	error = false,
	onExited,
}) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);

	const handleClose = (reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	useEffect(() => {
		if (message) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [message]);

	return (
		<>
			{coloured && (success || error) ? (
				<Snackbar
					open={open}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					key={message}
					autoHideDuration={3000}
					onClose={handleClose}
					onExited={onExited || (() => {})}
				>
					<Alert
						onClose={handleClose}
						variant="filled"
						severity={success ? "success" : "error"}
					>
						<p className={classes.content}>{message}</p>
					</Alert>
				</Snackbar>
			) : (
				// Plain snackbar, black background
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					key={message}
					open={open}
					autoHideDuration={5000}
					onClose={handleClose}
					message={message}
					action={
						<React.Fragment>
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={handleClose}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
						</React.Fragment>
					}
					onExited={onExited || (() => {})}
				/>
			)}
		</>
	);
};

PopupSnackbar.propTypes = {
	message: PropTypes.string.isRequired,
	coloured: PropTypes.bool,
	success: PropTypes.bool,
	error: PropTypes.bool,
};

export default PopupSnackbar;
