import Types from "../actions/types";

export const defaultState = {
	data: [],
	isLoadingList: false
};

const reducer = (state = defaultState, action) => {
	switch (action.type) {
		case Types.TASK_TYPE_LIST_REQUESTED:
			return {
				...state,
				isLoadingList: true
			};
		case Types.TASK_TYPE_LIST_SUCCEEDED:
			return {
				...state,
				data: action.payload,
				isLoadingList: false
			};
		default:
			return state;
	}
};

export default reducer;
